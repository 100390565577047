import { LoadingButton } from "@mui/lab";
import { Box, Button, Select, TextField } from "@mui/material";

import { styled } from "@mui/material/styles";

export const Paper = styled(Box)({
  backgroundColor: "#fff",
  minWidth: "315px",
  borderRadius: "8px",
  boxShadow: "0 3px 3px #00000038",
  maxWidth: "600px",
  padding: "20px",
  boxSizing: "border-box",
  width: "100%",
});

export const CustomButton = styled(Button)({
  font: "600 18px Pretendard",
  padding: "10px 0",
  width: "100px",
  backgroundColor: "#4a90e2",
  "&:hover": { backgroundColor: "#4a90e2" },
});

export const CustomLoadingButton = styled(LoadingButton)({
  font: "600 18px Pretendard",
  padding: "10px 0",
  width: "100px",
  backgroundColor: "#4a90e2",
  "&:hover": { backgroundColor: "#4a90e2" },
  "&.Mui-disabled": { backgroundColor: "#cdcdcd" },
  color: "#fff",
});

export const CustomTextField = styled(TextField)({
  border: "2px solid silver",
  borderRadius: "10px",
  marginBottom: "10px",

  "&:has(.Mui-focused)": {
    border: "2px solid #4a90e2",
  },
});

export const CustomSelect = styled(Select)({
  border: "2px solid silver",
  borderRadius: "10px",
  marginBottom: "10px",
  "> div": {
    padding: "5px 10px",
  },
});

CustomButton.defaultProps = {
  variant: "contained",
};

CustomTextField.defaultProps = {
  size: "small",
  inputProps: {
    style: { padding: "5px 10px", font: "500 16px Pretendard" },
  },
  autoComplete: "off",
};
