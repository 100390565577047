export const data = {
  labels: ["화끈이\n(CP)", "포용이\n(NP)", "침착이\n(A)", "솔직이\n(FC)", "끄덕이\n(AC)"],
  type: "line",
  datasets: [
    {
      label: "자아 분석",
      data: ["cp", "np", "a", "fc", "ac"],
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)", // 빨간색 계열
        "rgba(75, 192, 192, 0.5)", // 청록색 계열
        "rgba(153, 102, 255, 0.5)", // 보라색 계열
        "rgba(255, 159, 64, 0.5)", // 주황색 계열
        "rgba(54, 162, 235, 0.5)", // 파란색 계열
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(54, 162, 235, 1)",
      ],
      borderWidth: 1,
      tension: 0.0,
      fill: true,
    },
  ],
};

export const options = {
  scales: {
    y: {
      beginAtZero: true,
      max: 20,
    },
    x: {
      ticks: {
        callback: function (value) {
          const label = this.getLabelForValue(value);
          return label.split("\n");
        },
      },
    },
  },
  plugins: {
    datalabels: {
      display: false, // 데이터 레이블 숨기기
    },
    legend: {
      display: false,
    },
  },
};
