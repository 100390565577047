import React, { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { Box, Divider, MenuItem, /*Button,*/ Typography } from "@mui/material";

import mainImg from "assets/images/egoteenImg.webp";
import questions from "assets/data/Egoteen/egoteenQuestions.json";
import { data, options } from "assets/data/Egoteen/graph";
import results from "assets/data/Egoteen/results";
import wliveLogo from "assets/images/wLiveLogo(Red).png";

import {
  Paper,
  CustomButton as Button,
  CustomTextField as TextField,
  CustomSelect as Select,
  CustomLoadingButton as LoadingButton,
} from "./Components";

import { Context, Provider } from "./Context";

export default () => {
  return (
    <Provider>
      <UserPage />
    </Provider>
  );
};

const UserPage = () => {
  const { socket, currentPage } = useContext(Context);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ bgcolor: "#4a90e2", p: "20px", color: "#fff" }}>
        <Typography sx={{ font: "900 32px Pretendard", textAlign: "center", py: "15px" }}>
          청소년 에고그램
        </Typography>
      </Box>

      <Box
        sx={{
          flex: 1,
          bgcolor: "#f5f5f5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "20px",
          boxSizing: "border-box",
        }}
      >
        {(() => {
          switch (currentPage) {
            case "intro":
              return <Intro />;
            case "userInfo":
              return <UserInfo />;
            case "survey":
              return <Survey />;
            case "result":
              return <Result />;
            default:
              return <Intro />;
          }
        })()}
      </Box>

      <Box
        sx={{
          bgcolor: "#4a90e2",
          height: "60px",
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ font: "600 16px Pretendard" }}>
          Copyright © Withplus. All Right Reserved
        </Typography>
      </Box>
    </Box>
  );
};

const Intro = () => {
  const { setCurrentPage } = useContext(Context);

  const handleNext = () => {
    setCurrentPage("userInfo");
  };

  return (
    <Paper>
      <Typography
        sx={{ font: "600 18px Pretendard", textAlign: "center", py: "15px", wordBreak: "keep-all" }}
      >
        청소년 에고그램 테스트에 오신 것을 환영합니다.
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center", pb: "20px", width: "100%" }}>
        <img src={mainImg} alt="mainImg" style={{ width: "80%" }} />
      </Box>

      <Typography
        sx={{ font: "600 18px Pretendard", textAlign: "center", py: "15px", wordBreak: "keep-all" }}
      >
        준비가 되면
      </Typography>

      <Typography
        sx={{ font: "600 18px Pretendard", textAlign: "center", py: "15px", wordBreak: "keep-all" }}
      >
        아래 버튼을 눌러 시작해주세요
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center", pb: "20px" }}>
        <Button onClick={handleNext}>다음</Button>
      </Box>
    </Paper>
  );
};

const UserInfo = () => {
  const { gameId } = useParams();
  const [cookies] = useCookies();
  const {
    name,
    setName,
    age,
    setAge,
    gender,
    setGender,
    student,
    setStudent,
    setCurrentPage,
    socket,
  } = useContext(Context);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleAge = (e) => {
    if (isNaN(e.target.value)) return;
    setAge(e.target.value);
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handleStudent = (e) => {
    setStudent(e.target.value);
  };

  const handleNext = () => {
    if (name === "" || age === "" || gender === "default" || student === "default") {
      alert("모든 정보를 입력해주세요");
      return;
    }

    socket.emit(
      "startEgoteen",
      { gameId, uid: cookies.uid, name, age, gender, student },
      ({ success }) => {
        if (success) {
          setCurrentPage("survey");
        } else {
          alert("정원이 초과되었습니다. 진행자에게 문의해주세요.");
        }
      }
    );
  };

  return (
    <Paper>
      <Typography sx={{ textAlign: "center", font: "500 16px Pretendard" }}>
        간단한 정보를 기입해주세요
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          py: "50px",
          gap: "10px",
          width: "100%",
          maxWidth: "315px",
          margin: "auto",
        }}
      >
        <TextField placeholder="이름을 입력해주세요" value={name} onChange={handleName} />

        <TextField placeholder="만 나이를 입력해주세요" value={age} onChange={handleAge} />

        <Select
          value={gender}
          sx={{ color: gender === "default" ? "#a5a5a5" : "#000", fontWeight: "500" }}
          onChange={handleGender}
          MenuProps={{
            PaperProps: {
              style: { backgroundColor: "#f0f0f0" },
            },
          }}
        >
          <MenuItem value="default" sx={{ height: "0px", overflow: "hidden", p: 0, minHeight: 0 }}>
            성별을 선택해주세요
          </MenuItem>
          <MenuItem value="남">남</MenuItem>
          <MenuItem value="여">여</MenuItem>
          <MenuItem value="기타">기타</MenuItem>
        </Select>

        <Select
          value={student}
          sx={{ color: student === "default" ? "#a5a5a5" : "#000", fontWeight: "500" }}
          onChange={handleStudent}
          MenuProps={{
            PaperProps: {
              style: { backgroundColor: "#f0f0f0" },
            },
          }}
        >
          <MenuItem value="default" sx={{ height: "0px", overflow: "hidden", p: 0, minHeight: 0 }}>
            소속을 선택해주세요
          </MenuItem>
          <MenuItem value="대학생/대학원생">대학생/대학원생</MenuItem>
          <MenuItem value="고등학생">고등학생</MenuItem>
          <MenuItem value="중학생">중학생</MenuItem>
          <MenuItem value="초등학생">초등학생</MenuItem>
          <MenuItem value="기타">기타</MenuItem>
        </Select>
      </Box>

      <Typography sx={{ textAlign: "center", font: "500 16px Pretendard", py: "20px" }}>
        아래 버튼을 눌러 테스트를 시작합니다
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleNext}>시작</Button>
      </Box>
    </Paper>
  );
};

const Survey = () => {
  const { gameId } = useParams();
  const { total, currentIndex, setTotal, setResult, setCurrentPage, setCurrentIndex, socket } =
    useContext(Context);
  const [cookies] = useCookies();

  // const [currentIndex, setCurrentIndex] = useState(0);
  const [progess, setProgess] = useState(0);
  // const [result, setResult] = useState([0, 0, 0, 0, 0]);
  // const [total, setTotal] = useState([0, 0, 0, 0, 0]);
  const [isFinished, setIsFinished] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButton = (e) => {
    switch (e.target.id) {
      case "no":
        btnNo();
        break;
      case "soso":
        btnSoSo();
        break;
      case "yes":
        btnYes();
        break;
      default:
        break;
    }

    if (currentIndex === questions.length - 1) {
      setIsFinished(true);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const btnNo = () => {};

  const btnSoSo = () => {
    let newTotal = [...total];

    newTotal[questions[currentIndex].a] += 1;

    setTotal(newTotal);
  };

  const btnYes = () => {
    let newTotal = [...total];

    newTotal[questions[currentIndex].a] += 2;

    setTotal(newTotal);
  };

  const goToResult = () => {
    setLoading(true);

    const result = total.map((v, i) => {
      if (v >= 14) return "A";
      else if (v >= 8) return "B";
      else return "C";
    });

    setResult(result);

    const uid = cookies.uid;

    socket.emit("endEgoteen", { gameId, uid, total, result });

    setTimeout(() => {
      setCurrentPage("result");
    }, 500);
  };

  useEffect(() => {
    setProgess(((currentIndex + 1) / questions.length) * 100);
  }, [currentIndex]);

  useEffect(() => {
    console.log(total);
  }, [total]);

  return (
    <Paper>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "30px", width: "90%", margin: "auto" }}
      >
        <Box
          sx={{
            borderRadius: "12px",
            border: "3px solid #4a90e2",
            height: "20px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ bgcolor: "orange", width: `${progess}%`, height: "100%" }} />
        </Box>

        <Box
          sx={{
            bgcolor: "#f5f5f5",
            boxShadow: "0 3px 3px #00000038",
            borderRadius: "10px",
            p: "10px",
            height: "250px",
          }}
        >
          {!isFinished && (
            <>
              <Typography sx={{ font: "800 24px Pretendard", mb: "20px" }}>
                {currentIndex + 1}.
              </Typography>

              <Typography sx={{ font: "600 20px Pretendard", wordBreak: "keep-all" }}>
                {questions[currentIndex].q}
              </Typography>
            </>
          )}

          {isFinished && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography sx={{ font: "800 24px Pretendard", mb: "20px" }}>테스트 종료!</Typography>

              <Typography
                sx={{ font: "600 20px Pretendard", wordBreak: "keep-all", textAlign: "center" }}
              >
                테스트가 종료되었습니다. <br />
                결과를 확인해주세요.
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            button: { borderRadius: "12px" },
          }}
        >
          {!isFinished && (
            <>
              <Button onClick={handleButton} id="no">
                아니다
              </Button>
              <Button onClick={handleButton} id="soso">
                보통이다
              </Button>
              <Button onClick={handleButton} id="yes">
                그렇다
              </Button>
            </>
          )}

          {isFinished && (
            <LoadingButton onClick={goToResult} loading={loading}>
              결과 확인
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const Result = () => {
  const [cookies, setCookie] = useCookies();
  const { result, total, name, setCurrentPage, setTotal, setResult, setCurrentIndex } =
    useContext(Context);

  const sectionRefs = {
    section1: useRef(),
    section3: useRef(),
    section4: useRef(),
  };

  const [type, setType] = useState("AAAAA");
  const [downloadAction, setDownloadAction] = useState(false);

  const saveAsPdf = () => {
    return new Promise(async (resolve) => {
      const pdf = new jsPDF(); // 기본 설정, 동적으로 크기 조정 예정

      // 각 섹션 캡처 및 PDF 추가
      for (const [key, ref] of Object.entries(sectionRefs)) {
        const element = ref.current;
        if (!element) continue;

        // 각 섹션 캡처
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL("image/webp");

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // 섹션의 비율에 따라 PDF 크기 설정 (단위: mm)
        const pdfWidth = 210; // A4 기준 가로 길이
        const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;

        if (key !== "section1") {
          pdf.addPage([pdfWidth, pdfHeight]); // 새 페이지 추가 및 크기 설정
        } else {
          pdf.setPage(1); // 첫 페이지의 크기 설정
          pdf.internal.pageSize.setWidth(pdfWidth);
          pdf.internal.pageSize.setHeight(pdfHeight);
        }

        // 이미지 추가 (여백 고려)
        pdf.addImage(imgData, "PNG", 10, 10, pdfWidth - 20, pdfHeight - 20);
      }

      pdf.save("webpage.pdf"); // PDF 저장
      resolve();
    });
  };

  const handleDownloadAction = () => {
    setDownloadAction(true);
  };

  const restartEgoteen = () => {
    const uid = uuidv4();

    const maxAge = 60 * 60 * 24 * 365;

    setCookie("uid", uid, { path: "/", maxAge });

    setTotal([0, 0, 0, 0, 0]);
    setResult(["", "", "", "", ""]);
    setCurrentPage("intro");
    setCurrentIndex(0);

    window.location.reload();
  };

  useEffect(() => {
    if (downloadAction) {
      saveAsPdf().then(() => {
        setDownloadAction(false);
      });
    }
  }, [downloadAction]);

  useEffect(() => {
    setType(result.join(""));
  }, []);

  return (
    <Paper sx={{ boxShadow: downloadAction ? "none" : "" }}>
      <Box
        sx={{
          py: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          td: {
            border: "1px solid #cdcdcd",
            font: "500 16px Pretendard",
            textAlign: "center",
            py: "5px",
          },
          table: { borderCollapse: "collapse", width: "100%", tableLayout: "fixed" },
          ".header": { backgroundColor: "#f5f5f5", fontWeight: "700" },
          ".cp": { bgcolor: "rgba(255, 99, 132, 0.5)" },
          ".np": { bgcolor: "rgba(75, 192, 192, 0.5)" },
          ".a": { bgcolor: "rgba(153, 102, 255, 0.5)" },
          ".fc": { bgcolor: "rgba(255, 159, 64, 0.5)" },
          ".ac": { bgcolor: "rgba(54, 162, 235, 0.5)" },
        }}
      >
        <Box
          id="section1"
          sx={{ display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}
          ref={sectionRefs.section1}
        >
          {downloadAction && (
            <Box
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}
            >
              <img src={wliveLogo} alt="wliveLogo" style={{ width: "25px" }} />

              <Typography sx={{ font: "700 16px Pretendard" }}>
                Withplus Live - 청소년 에고그램 결과
              </Typography>
            </Box>
          )}

          <Typography sx={{ mb: "-10px", font: "500 16px Pretendard" }}>
            <strong>{name}</strong>님의 청소년 에고그램 결과는,
          </Typography>

          <Typography sx={{ font: "800 24px Pretendard", textAlign: "center" }}>
            {results[type].category}
          </Typography>

          <table>
            <tbody>
              <tr>
                <td className="header">자아</td>
                <td className="cp">CP</td>
                <td className="np">NP</td>
                <td className="a">A</td>
                <td className="fc">FC</td>
                <td className="ac">AC</td>
              </tr>
              <tr>
                <td className="header">판정</td>
                <td className="res-type">{total[0]}</td>
                <td className="res-type">{total[1]}</td>
                <td className="res-type">{total[2]}</td>
                <td className="res-type">{total[3]}</td>
                <td className="res-type">{total[4]}</td>
              </tr>
            </tbody>
          </table>

          <Typography
            sx={{
              font: "700 20px Pretendard",
              textAlign: "center",
              color: "#4a90e2",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            {results[type].desc}
          </Typography>

          <Typography sx={{ font: "500 16px Pretendard", textAlign: "center" }}>
            {results[type].sub}
          </Typography>

          <Line
            data={{ ...data, datasets: [{ ...data.datasets[0], data: total }] }}
            options={options}
            height={300}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(255, 99, 132, 1)" }}>
                CP (통제적인 부모자아, Controlling Parent)
              </strong>
              : 규칙을 중요시하고, 타인을 비판하거나 통제하려는 태도입니다. 책임감 있고 엄격한
              모습을 보이며, 규율을 지키도록 강하게 요구할 때 나타나는 자아 상태입니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(75, 192, 192, 1)" }}>
                NP (양육적 부모자아, Nurturing Parent)
              </strong>
              : 타인을 돌보고 배려하며, 따뜻하게 격려해주는 태도입니다. 상대방을 이해하고 보호하려는
              모습으로, 누군가에게 도움을 주거나 지지할 때 발현되는 자아입니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(153, 102, 255, 1)" }}>
                A (이성적인 어른자아, Adult)
              </strong>
              : 현실적이고 논리적인 태도로, 현재 상황을 객관적으로 분석하고 판단하는 자아입니다.
              감정에 휘둘리지 않고 이성적으로 문제를 해결할 때 이 자아가 활성화됩니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(255, 159, 64, 1)" }}>
                FC (자유로운 아이자아, Free Child)
              </strong>
              : 자기 표현이 자유롭고 창의적이며, 감정을 솔직하게 드러내는 자아입니다. 즐거움과
              호기심이 넘치는 어린아이 같은 모습으로, 자발적으로 행동하거나 자신이 원하는 것을
              표현할 때 활성화됩니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(54, 162, 235, 1)" }}>
                AC (순응하는 아이자아, Adapted Child)
              </strong>
              : 타인의 기대에 맞추어 행동하거나 규칙에 따르려는 자아입니다. 순종적이거나 타인의
              지시에 따르는 태도를 보이며, 규칙을 지키거나 반항적인 모습을 보일 때도 이 자아가
              드러날 수 있습니다. 여기에는 때로는 억눌림이나 반항의 감정도 포함됩니다.
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          id="section3"
          ref={sectionRefs.section3}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography sx={{ font: "700 20px Pretendard", color: "#4a90e2" }}>성격</Typography>

            {results[type].char.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>

          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography sx={{ font: "700 20px Pretendard", color: "#4a90e2" }}>학교생활</Typography>

            {results[type].school.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}
          id="section4"
          ref={sectionRefs.section4}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography
              sx={{ font: "700 20px Pretendard", color: "#4a90e2", wordBreak: "keep-all" }}
            >
              인간관계 (상대방이 이 타입일 경우 어떻게 하면 좋을까?)
            </Typography>

            {results[type].human.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>

          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography
              sx={{ font: "700 20px Pretendard", color: "#4a90e2", wordBreak: "keep-all" }}
            >
              결론
            </Typography>

            {results[type].result.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>

          {downloadAction && (
            <Box
              sx={{ textAlign: "center", font: "500 14px Pretendard", color: "#999", pb: "5px" }}
            >
              Copyright © Withplus. All Right Reserved
            </Box>
          )}
        </Box>

        <Divider />

        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Button onClick={handleDownloadAction} disabled={downloadAction}>
            PDF 저장
          </Button>
          <Button onClick={restartEgoteen} disabled={downloadAction}>
            다시하기
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export const ResultOnManager = ({ result, name, total }) => {
  const sectionRefs = {
    section1: useRef(),
    section3: useRef(),
    section4: useRef(),
  };

  const [type, setType] = useState("AAAAA");
  const [downloadAction, setDownloadAction] = useState(false);

  const saveAsPdf = () => {
    return new Promise(async (resolve) => {
      const pdf = new jsPDF(); // 기본 설정, 동적으로 크기 조정 예정

      // 각 섹션 캡처 및 PDF 추가
      for (const [key, ref] of Object.entries(sectionRefs)) {
        const element = ref.current;
        if (!element) continue;

        // 각 섹션 캡처
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL("image/webp");

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // 섹션의 비율에 따라 PDF 크기 설정 (단위: mm)
        const pdfWidth = 210; // A4 기준 가로 길이
        const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;

        if (key !== "section1") {
          pdf.addPage([pdfWidth, pdfHeight]); // 새 페이지 추가 및 크기 설정
        } else {
          pdf.setPage(1); // 첫 페이지의 크기 설정
          pdf.internal.pageSize.setWidth(pdfWidth);
          pdf.internal.pageSize.setHeight(pdfHeight);
        }

        // 이미지 추가 (여백 고려)
        pdf.addImage(imgData, "PNG", 10, 10, pdfWidth - 20, pdfHeight - 20);
      }

      pdf.save(`라이브_에고틴_${name}의 결과지.pdf`); // PDF 저장
      resolve();
    });
  };

  const handleDownloadAction = () => {
    setDownloadAction(true);
  };

  useEffect(() => {
    if (downloadAction) {
      saveAsPdf().then(() => {
        setDownloadAction(false);
      });
    }
  }, [downloadAction]);

  useEffect(() => {
    setType(result.join(""));
  }, []);

  return (
    <Paper sx={{ boxShadow: downloadAction ? "none" : "" }}>
      <Box
        sx={{
          py: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          td: {
            border: "1px solid #cdcdcd",
            font: "500 16px Pretendard",
            textAlign: "center",
            py: "5px",
          },
          table: { borderCollapse: "collapse", width: "100%", tableLayout: "fixed" },
          ".header": { backgroundColor: "#f5f5f5", fontWeight: "700" },
          ".cp": { bgcolor: "rgba(255, 99, 132, 0.5)" },
          ".np": { bgcolor: "rgba(75, 192, 192, 0.5)" },
          ".a": { bgcolor: "rgba(153, 102, 255, 0.5)" },
          ".fc": { bgcolor: "rgba(255, 159, 64, 0.5)" },
          ".ac": { bgcolor: "rgba(54, 162, 235, 0.5)" },
        }}
      >
        <Box
          id="section1"
          sx={{ display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}
          ref={sectionRefs.section1}
        >
          {downloadAction && (
            <Box
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}
            >
              <img src={wliveLogo} alt="wliveLogo" style={{ width: "25px" }} />

              <Typography sx={{ font: "700 16px Pretendard" }}>
                Withplus Live - 청소년 에고그램 결과
              </Typography>
            </Box>
          )}

          <Typography sx={{ mb: "-10px", font: "500 16px Pretendard" }}>
            <strong>{name}</strong>님의 청소년 에고그램 결과는,
          </Typography>

          <Typography sx={{ font: "800 24px Pretendard", textAlign: "center" }}>
            {results[type].category}
          </Typography>

          <table>
            <tbody>
              <tr>
                <td className="header">자아</td>
                <td className="cp">CP</td>
                <td className="np">NP</td>
                <td className="a">A</td>
                <td className="fc">FC</td>
                <td className="ac">AC</td>
              </tr>
              <tr>
                <td className="header">판정</td>
                <td className="res-type">{total[0]}</td>
                <td className="res-type">{total[1]}</td>
                <td className="res-type">{total[2]}</td>
                <td className="res-type">{total[3]}</td>
                <td className="res-type">{total[4]}</td>
              </tr>
            </tbody>
          </table>

          <Typography
            sx={{
              font: "700 20px Pretendard",
              textAlign: "center",
              color: "#4a90e2",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            {results[type].desc}
          </Typography>

          <Typography sx={{ font: "500 16px Pretendard", textAlign: "center" }}>
            {results[type].sub}
          </Typography>

          <Line
            data={{ ...data, datasets: [{ ...data.datasets[0], data: total }] }}
            options={options}
            height={300}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(255, 99, 132, 1)" }}>
                CP (통제적인 부모자아, Controlling Parent)
              </strong>
              : 규칙을 중요시하고, 타인을 비판하거나 통제하려는 태도입니다. 책임감 있고 엄격한
              모습을 보이며, 규율을 지키도록 강하게 요구할 때 나타나는 자아 상태입니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(75, 192, 192, 1)" }}>
                NP (양육적 부모자아, Nurturing Parent)
              </strong>
              : 타인을 돌보고 배려하며, 따뜻하게 격려해주는 태도입니다. 상대방을 이해하고 보호하려는
              모습으로, 누군가에게 도움을 주거나 지지할 때 발현되는 자아입니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(153, 102, 255, 1)" }}>
                A (이성적인 어른자아, Adult)
              </strong>
              : 현실적이고 논리적인 태도로, 현재 상황을 객관적으로 분석하고 판단하는 자아입니다.
              감정에 휘둘리지 않고 이성적으로 문제를 해결할 때 이 자아가 활성화됩니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(255, 159, 64, 1)" }}>
                FC (자유로운 아이자아, Free Child)
              </strong>
              : 자기 표현이 자유롭고 창의적이며, 감정을 솔직하게 드러내는 자아입니다. 즐거움과
              호기심이 넘치는 어린아이 같은 모습으로, 자발적으로 행동하거나 자신이 원하는 것을
              표현할 때 활성화됩니다.
            </Typography>

            <Typography
              sx={{ font: "500 13px Pretendard", lineHeight: "1.5", wordBreak: "keep-all" }}
            >
              <strong style={{ color: "rgba(54, 162, 235, 1)" }}>
                AC (순응하는 아이자아, Adapted Child)
              </strong>
              : 타인의 기대에 맞추어 행동하거나 규칙에 따르려는 자아입니다. 순종적이거나 타인의
              지시에 따르는 태도를 보이며, 규칙을 지키거나 반항적인 모습을 보일 때도 이 자아가
              드러날 수 있습니다. 여기에는 때로는 억눌림이나 반항의 감정도 포함됩니다.
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          id="section3"
          ref={sectionRefs.section3}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography sx={{ font: "700 20px Pretendard", color: "#4a90e2" }}>성격</Typography>

            {results[type].char.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>

          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography sx={{ font: "700 20px Pretendard", color: "#4a90e2" }}>학교생활</Typography>

            {results[type].school.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}
          id="section4"
          ref={sectionRefs.section4}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography
              sx={{ font: "700 20px Pretendard", color: "#4a90e2", wordBreak: "keep-all" }}
            >
              인간관계 (상대방이 이 타입일 경우 어떻게 하면 좋을까?)
            </Typography>

            {results[type].human.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>

          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography
              sx={{ font: "700 20px Pretendard", color: "#4a90e2", wordBreak: "keep-all" }}
            >
              결론
            </Typography>

            {results[type].result.split("\n").map((v, i) => (
              <Typography
                key={i}
                sx={{ font: "500 16px Pretendard", wordBreak: "keep-all", lineHeight: 1.5 }}
              >
                {v}
              </Typography>
            ))}
          </Box>

          {downloadAction && (
            <Box
              sx={{ textAlign: "center", font: "500 14px Pretendard", color: "#999", pb: "5px" }}
            >
              Copyright © Withplus. All Right Reserved
            </Box>
          )}
        </Box>

        <Divider />

        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Button onClick={handleDownloadAction} disabled={downloadAction}>
            PDF 저장
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
