const dictType = {
  AAAAA: {
    type: "AAAAA",
    category: "슈퍼맨 유형",
    desc: `'인생 축제 메이커' 타입`,
    sub: `학교의 에너지 뱅크를 찾아라!`,
    char: `여러분은 완벽한 '인생 축제 메이커' 타입이에요! 마치 인간 멀티탭처럼 주변 사람들에게 긍정 에너지를 나눠주는 스타일이죠.
이 타입은 정신적으로나 육체적으로나 '배터리 200% 풀충전' 상태예요. 공부나 친구 관계 어느 것 하나 빠지지 않고 모든 걸 즐기는 '올라운더' 같아요. 주변 사람들이 보기에는 '인간 비타민'처럼 밝고 쾌활해서, 함께 있으면 기분이 좋아지는 매력쟁이예요.
자신의 꿈과 목표가 뚜렷하고, 친구들에 대한 관심과 애정도 넘쳐요. 머리도 좋고 상황 파악력도 뛰어나서 미래의 성공한 사업가 느낌이 물씬 나요!
하지만 가끔은 '에너지 과다 방출'로 지칠 수 있어요. 그럴 때는 잠깐의 '충전 타임'이 필요해요. 평소에 운동이나 건강관리가 필수! 이 부분을 잘 챙기지 않으면 어느 순간 '에너지 고갈'로 스트레스 받을 수 있으니 주의해야 해요. 짧은 산책이나 명상으로 마음의 여유를 가져보는 것도 좋아요.`,
    result: `기억하세요! 이 타입은 '인생 축제 메이커' 스타일이지만, '에너지 관리'와 '시간 분배 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 넘치는 열정과 재능을 믿어요. 다만 가끔은 '슬로우 모드'로 전환해서 자신을 돌아보는 시간도 가져보세요.
    그러면 더 오래, 더 멋지게 빛날 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '에너지 관리 마스터'가 되어보세요!
- '꿈 로드맵 그리기': 자신의 목표를 구체적으로 세워보세요. 비전보드를 만들어보는 것도 좋아요.
- '에너지 충전소 찾기': 학교 안에서 자신만의 휴식 공간을 찾아보세요. 도서관의 조용한 구석이나 나무 그늘 아래 벤치 등이 좋아요.
- '재능 나눔 프로젝트': 자신의 장점을 살려 친구들을 돕는 활동을 해보세요. 공부를 잘한다면 스터디 그룹을 만들어 보는 건 어떨까요?
- '다재다능 챌린지': 다양한 교내 활동에 참여해보세요. 하지만 무리하지 않도록 주의하세요!
- '마인드풀니스 시간': 하루에 5분이라도 명상이나 깊은 호흡으로 마음을 진정시키는 시간을 가져보세요.`,
    human: `- 베프, 이성친구
이 타입과 함께라면 매일이 '인생 축제'예요! 하루하루가 새롭고 즐거울 거예요. 하지만 만약 여러분이 조용한 성격이라면 이 활발한 에너지가 좀 부담스러울 수 있어요. 서로의 에너지 레벨을 맞춰가는 노력이 필요해요.
- 선생님
이런 타입의 선생님이라면, 수업이 항상 즐겁고 활기찰 거예요. 선생님의 열정에 맞춰 적극적으로 참여하면 좋아하실 거예요. 하지만 선생님의 페이스에 너무 휘둘리지 않도록 주의하세요.
- 선배
이런 타입의 선배를 만났다면 정말 '대박'이에요! 여러분의 잠재력을 끌어내주고 멋진 조언도 해줄 거예요. 하지만 선배의 열정을 따라가느라 지치지 않도록 주의하세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '함께 성장하는 파트너'로 여기세요. 경쟁보다는 협력에 focus! 그들의 아이디어를 존중하고, 함께 프로젝트를 진행해보는 것도 좋아요.`,
  },

  AAAAB: {
    type: "AAAAB",
    category: "에너지 과잉 유형",
    desc: `'만능 에너자이저' 타입`,
    sub: `학교의 핵인싸를 찾아라!`,
    char: `여러분은 완벽한 '만능 에너자이저' 타입이에요! 마치 24시간 돌아가는 편의점처럼 언제나 에너지 넘치는 스타일이죠.
이 타입은 꿈도 크고 친구들에게 아주 친절한 친구예요. 공부할 때는 논리적이면서도 호기심이 많고, 새로운 것에 도전하고 싶은 열정이 넘쳐서 취미, 게임, 공부 등 모든 걸 다 해보고 싶어 해요.
하지만 에너지가 너무 넘치다 보니 모든 것에 다 집중하다 보면 체력이 소진될 수 있어요. 사람은 완벽할 수 없으니까요. 재능은 많지만 여러 가지를 조금씩만 하다 보면 '다방면에서 잘 알지만 깊이는 부족한 사람'이 될 수 있어요. 다양한 걸 즐기는 것도 좋지만, 한 가지에 집중해서 성과를 내보는 것도 좋은 방법일 거예요.`,
    result: `기억하세요! 이 타입은 '만능 에너자이저' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 넘치는 에너지와 다재다능함을 믿어요. 다만 가끔은 '슬로우 모드'로 전환해서 자신을 돌아보는 시간도 가져보세요.
    그러면 더 오래, 더 멋지게 빛날 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '에너지 관리의 달인'이 되어보세요!
- '관심사 TOP3 선정하기': 좋아하는 것 중 가장 중요한 세 가지를 골라 집중해보세요.
- '시간 관리 마스터 되기': 플래너를 사용해 효율적으로 시간을 관리해보세요.
- '에너지 충전 시간 갖기': 하루 중 잠깐이라도 혼자만의 시간을 가져보세요.
- '목표 달성 프로젝트': 한 학기에 하나씩 큰 목표를 세우고 달성해보세요.
- '멘토 찾기 챌린지': 존경하는 선생님이나 선배를 멘토로 삼고 조언을 구해보세요.`,
    human: `- 베프, 이성친구
이 타입과 함께라면 매일이 '롤러코스터'예요! 편하진 않지만 늘 재미있고 설레는 관계가 될 거예요. 문제가 생기면 현실적으로 해결하려고 노력해요. 다행히 상대방을 이해하려 노력하고 애정 표현도 잘해서 즐거운 시간을 보낼 수 있어요.
- 선생님
이런 타입의 선생님이라면, 수업이 항상 흥미진진할 거예요! 특별히 큰 문제는 없지만, 선생님의 높은 에너지를 따라가느라 지치지 않도록 주의하세요.
- 선배
이런 타입의 선배는 공부와 교우 관계 모두에서 기대치가 높아요. 하지만 그만큼 여러분의 잠재력을 끌어내주는 멋진 선배가 될 거예요. 학업과 인간관계 모두에서 인정받도록 노력해보세요.
- 친구, 후배
이 타입의 친구나 후배는 공부도 잘하고 사교성도 좋아요. 가끔은 계산적으로 보일 수 있지만, 함께 있으면 많은 걸 배울 수 있어요. 이 친구의 성과는 어떤 선배나 선생님을 만나느냐에 따라 크게 달라질 수 있어요.`,
  },

  AAAAC: {
    type: "AAAAC",
    category: "다재다능한 유형",
    desc: `🌟'인생 메인 캐릭터' 타입`,
    sub: `학교의 인기스타를 찾아라!`,
    char: `여러분은 완벽한 '인생 메인 캐릭터' 타입이에요! 마치 드라마의 주인공처럼 모든 면에서 돋보이는 스타일이죠.
이 타입은 정신력이 매우 강하고 책임감과 목표의식이 아주 높은 친구예요. 친구들에 대한 관심도 많아서 의리도 넘치고, 정이 많은 '인간 비타민' 같은 존재죠. 자기 표현력도 뛰어나서 언제나 주목받는 편이에요. 호기심이 많고 자유로운 영혼이지만, 자신만의 원칙도 확고해요. 주변 사람들에게는 정말 인정받을 만한 성격이에요.
하지만 가끔은 여러 가지 일에 에너지를 분산시키는 경향이 있어요. 한 가지에 집중해서 끝까지 깊이 있게 완수해보는 건 어떨까요?`,
    result: `기억하세요! 이 타입은 '인생 메인 캐릭터' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 무한한 잠재력과 매력을 믿어요. 다만 가끔은 '서브 캐릭터'의 마음도 이해하며 주변을 돌아보는 시간도 가져보세요.
    그러면 더 멋진 '인생 드라마'의 주인공이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '진정한 주인공'이 되어보세요!
- '관심사 맵핑하기': 좋아하는 과목이나 활동을 리스트업 해보세요. 그 중에서 가장 흥미로운 것 하나를 골라 깊이 파고들어보는 거예요.
- '목표 달성 프로젝트': 단기, 중기, 장기 목표를 세우고 하나씩 이뤄나가보세요. 작은 성취감이 큰 동기부여가 될 거예요.
- '리더십 키우기': 학급 임원이나 동아리 장을 맡아보는 건 어떨까요? 여러분의 카리스마를 발휘할 수 있는 좋은 기회예요.
- '멘토링 프로그램': 후배들을 도와주는 멘토가 되어보세요. 여러분의 재능을 나누면서 더 성장할 수 있을 거예요.
- '시간 관리의 신': 하루 일과를 계획하고 지키는 습관을 들여보세요. '뽀모도로 기법(집중과 휴식의 완벽한 조화) '같은 집중력 향상 테크닉을 활용해보는 것도 좋아요.`,
    human: `- 베프, 이성친구
이 타입과 함께라면 매일이 '드라마 주인공' 같은 느낌이에요! 하지만 가끔은 그들의 강한 개성에 압도될 수 있어요. 솔직하게 소통하고, 서로의 개성을 존중하는 게 중요해요.
- 선생님
이런 타입의 선생님이라면, 수업이 항상 재미있고 인상적일 거예요. 하지만 선생님의 페이스에 너무 휘둘리지 않도록 주의하세요. 때로는 '아니오'라고 말할 줄 아는 것도 중요해요.
- 선배
이런 타입의 선배를 만났다면 '대박'이에요! 카리스마 넘치고 멋진 조언을 해줄 거예요. 하지만 선배의 모든 제안을 무조건 따르기보다는, 자신에게 정말 필요한 것을 선별해 받아들이세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '드림팀'을 만들어보세요. 그들의 열정과 아이디어를 존중하면서도, 때로는 현실적인 조언을 해주는 것도 좋아요. 함께 성장하는 파트너가 되어보세요!`,
  },

  AAABA: {
    type: "AAABA",
    category: "모범생 유형",
    desc: `'인생 만점왕' 타입`,
    sub: `학교의 슈퍼 모범생을 찾아라!`,
    char: `여러분은 완벽한 '인생 만점왕' 타입이에요! 마치 학교의 살아있는 전설처럼 모든 면에서 빛나는 스타일이죠.
이 타입은 언제나 최선을 다하며 '올인' 정신으로 임하는 친구예요. 누구도 흠잡을 수 없는 성실한 생활 태도를 지녔고, 어디를 가든 "쟤처럼만 하면 돼!"라는 말을 들을 만큼 주변의 기대도 크죠. 도덕성과 규칙 준수 면에서도 최고이고, 친구들에게는 항상 '천사' 같은 존재예요. 힘든 일도 마다하지 않고, 갈등이 생기면 '평화의 중재자'로서 양보와 타협을 잘 이끌어내요.
하지만 가끔은 자신의 진짜 감정을 숨기는 경향이 있어요. 남을 너무 배려하다 보니 스스로 '에너지 방전'이 되는 경우가 많죠. 정신력은 강하지만, 몸도 잘 따라갈 수 있도록 스트레스 관리와 휴식이 필요해요.`,
    result: `기억하세요! 이 타입은 '인생 만점왕' 스타일이지만, '자기 관리'와 '휴식의 중요성'을 깨닫는다면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 완벽함과 선한 마음을 믿어요. 다만 가끔은 '엄격한 자신'에게 휴식을 주는 것도 잊지 마세요.
    그러면 더 오래, 더 멋지게 빛날 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '진정한 리더'가 되어보세요!
- '셀프케어 타임': 하루에 10분이라도 자신만을 위한 시간을 가져보세요. 명상이나 좋아하는 음악 듣기 등으로 '힐링'하는 거예요.
- '감정 일기 쓰기': 늘 참았던 자신의 감정을 솔직하게 표현해보는 거예요. 누구에게도 보여줄 필요 없어요.
- '건강한 '노(No)' 말하기 연습': 가끔은 부탁을 거절하는 연습을 해보세요. 모든 걸 다 들어줄 필요는 없어요.
- '취미 개발하기': 공부나 봉사 외에도 자신만의 즐거움을 찾아보세요. 스트레스 해소에 좋아요.
- '멘토링 프로그램': 여러분의 노하우를 후배들과 나눠보세요. 가르치면서 배우는 것도 많을 거예요.`,
    human: `- 베프, 이성친구
이 타입과 함께라면 매일이 '드라마 속 주인공' 같은 느낌이에요! 하지만 가끔은 그들의 완벽함에 압도될 수 있어요. 그들도 실수할 수 있다는 걸 이해하고, 때로는 편하게 쉴 수 있게 해주세요.
- 선생님
이런 타입의 선생님이라면, 수업이 항상 알찰 거예요. 선생님을 본받되, 자신만의 스타일도 잃지 않도록 해요. 가끔은 선생님께 도움을 요청하는 것도 좋아요.
- 선배
이런 타입의 선배를 만났다면 '대박'이에요! 많은 것을 배울 수 있을 거예요. 하지만 선배의 모든 조언을 무조건 따르기보다는, 자신에게 정말 필요한 것을 선별해 받아들이세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '천생연분'이에요! 하지만 그들도 쉴 틈이 필요하다는 걸 잊지 마세요. 가끔은 그냥 아무것도 하지 않고 함께 있어주는 것만으로도 큰 위로가 될 수 있어요.`,
  },

  AAABB: {
    type: "AAABB",
    category: "좋은 관리자 유형",
    desc: `'리더십 만렙 꿈나무' 타입`,
    sub: `학교의 숨은 엄친아!`,
    char: `여러분은 진정한 '리더십 만렙 꿈나무' 타입이에요! 동아리 회장이나 반장 자리에 완전 찰떡이죠. 목표의식이 뚜렷하고 신념도 강해요. 친구들을 잘 리드하고 응원해주는 타입으로, 학교나 동아리에 질서를 만드는 데 도사예요.
문제는 자기 자신을 위한 재미있는 일이 부족하다는 점이에요. 너무 공부나 활동에만 집중하다 보니 삶이 조금 지루하게 느껴질 때가 있을 수 있어요. 목표만 보고 달리다 보면 여유나 만족감을 놓칠 수 있으니 주의해야 해요. 혹시 목표를 이루고 나서 허탈감이 찾아올 수도 있으니, 가끔은 '힐링 타임'을 갖고 취미 생활도 즐겨보는 게 좋겠어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 리더십 잠재력을 믿어요. 하지만 가끔은 '꼼반' (꼼짝 마 반성하자)의 시간도 가져보세요. 
    균형을 잘 잡으면 진짜 '찐 대박' 리더가 될 수 있어요! 파이팅!`,
    school: `여러분은 학교에서 '만능 엔터테이너' 같은 존재예요! 많은 동아리와 그룹이 여러분을 원할 거예요.
- '학생회 활동': 여러분의 리더십 스킬을 발휘할 수 있는 완벽한 장소예요. 학생회장 선거에 도전해보는 건 어떨까요?
- '동아리 활동': 다양한 동아리에 참여해보세요. 체육부, 학술부, 봉사부 등 여러 분야를 경험해보면 좋아요.
- '학교 행사': 축제나 체육대회 같은 학교 행사의 기획팀에 참여해보세요. 여러분의 조직력을 발휘할 수 있을 거예요.
- '멘토링 프로그램': 후배들을 위한 멘토가 되어보는 것도 좋아요. 리더십을 키우면서 다른 사람을 도울 수 있어요.
- '그룹 프로젝트': 수업 시간의 그룹 프로젝트에서 리더 역할을 맡아보세요. 팀워크를 이끄는 연습이 될 거예요.
부족한 부분이 있더라도 여러 활동에 지원해보면서 작은 경험들을 쌓아보세요. 그리고 가끔은 '힐링 타임'도 가져보세요. 친구들과 편하게 수다 떠는 시간이나, 혼자만의 취미 생활을 즐기는 것도 중요해요.`,
    human: `- 베프, 이성친구
학교에서 '엄친아'라고 해서 친구 관계에서도 완벽한 건 아니에요. 밖에서만 에너지를 쓰기보다는, 친구들이나 이성친구에게도 그 에너지를 나눠주는 게 좋아요. 이런 친구들과 함께 있을 때는 그들의 감정도 잘 신경 써주면 더 좋을 거예요. 가끔은 목표나 성과에 대한 이야기보다는 그냥 재미있게 놀아주는 것도 필요해요.
- 선생님
과제나 프로젝트 파트너로 믿을 만한 학생이에요. 이런 학생과 함께 일할 때는 명확한 목표와 기대치를 설정해주세요. 그들의 리더십을 인정해주되, 다른 학생들과의 협력도 중요하다는 점을 상기시켜주세요.
- 선배
이런 선배는 좀 계산적이거나 로봇 같아 보일 수 있지만 괜찮은 선배예요. 때에 따라 남의 마음도 잘 헤아려주거든요. 이런 선배와 함께 일할 때는 그들의 조언을 경청하되, 자신의 의견도 자신 있게 표현해보세요.
- 친구, 후배
옳고 그름이 확실한 타입이에요. 현실에 안 맞거나 조정이 필요하면 솔직히 말하는 스타일이죠. 타협할 줄 아는 리더라면 잘 지낼 수 있어요. 이런 친구나 후배와 함께 있을 때는 그들의 의견을 존중하면서도, 때로는 유연성이 필요하다는 점을 상기시켜주세요.`,
  },

  AAABC: {
    type: "AAABC",
    category: "자주적인 인재 유형",
    desc: `'올라운더 신과함께' 타입`,
    sub: `학교의 숨은 엄친아 끝판왕!`,
    char: `여러분은 '올라운더 신과함께' 타입이에요. 이 타입의 친구들은 정말 대단해요. 판단력이 좋아서 어려운 상황에서도 현명한 선택을 하고, 남의 마음을 잘 이해하며 팀에서 문제를 해결하는 데 탁월해요. 자기만의 스타일이 있어 독특한 아이디어를 내곤 하죠. 또 혼자서도 잘 해내는 편이라 다른 사람에게 의지하지 않아요. 이런 친구들이 반장이나 동아리 회장으로 뽑히면 새로운 변화를 만들어내고, 열정적으로 일하며 친구들에게 좋은 영향을 줘요. 하지만 주의할 점도 있어요. 가끔 고집이 세 보일 수 있고, 자기 방식만 고집하면 '독재자'처럼 보일 수 있죠. 완벽을 추구하다 친구들에게 부담을 줄 수도 있고요. 그래서 더 좋은 리더가 되려면 다른 친구들의 의견도 잘 들어보고, 비판을 들었을 때 화내지 말고 생각해보는 게 중요해요. 그리고 친구들과 협력하는 것의 중요성을 항상 기억하세요. 이렇게 하면 여러분의 장점을 잘 살리면서, 더 많은 친구들과 어울릴 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 만렙' 스타일이지만, '여유'와 '균형 잡힌 생활'을 추구하면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 다재다능함을 믿어요. 하지만 가끔은 '힐링 타임'도 가져보세요.
    균형을 잘 잡으면 진짜 '인생 승자'가 될 수 있어요! 파이팅!`,
    school: `자기만의 색깔이 확실한 여러분은 유명한 동아리보다는 정말 원하는 활동을 만났을 때 더 만족하는 타입이에요.
- '학생회 활동': 여러분의 리더십과 혁신 능력을 마음껏 발휘할 수 있어요. 학생회 임원에 도전해보는 건 어떨까요?
- '동아리 만들기': 기존 동아리에 만족하지 못한다면, 직접 새로운 동아리를 만들어보세요. 여러분의 아이디어를 실현할 수 있는 좋은 기회예요.
- '학교 행사 기획': 축제나 체육대회 같은 학교 행사의 총책임자로 나서보세요. 여러분의 조직력과 창의력을 보여줄 수 있을 거예요.
- '교내 대회 참가': 토론 대회, 과학 경진 대회 등 다양한 교내 대회에 참가해보세요. 여러분의 다재다능함을 뽐낼 수 있어요.
- '진로 탐구': 다양한 진로 체험 활동에 참여해보세요. 여러분의 폭넓은 관심사를 탐구할 수 있는 좋은 기회가 될 거예요.
그 동아리나 그룹이 어떤 사람을 찾는지 잘 확인하고, 자기 PR을 최대한 해보면 좋은 결과를 얻을 수 있어요. 하지만 가끔은 '꿀잼' 시간도 가져보세요. 친구들과 그냥 노는 시간도 중요해요!`,
    human: `- 베프, 이성친구
이런 타입의 친구나 여자친구은 스스로 리드하고 싶어 하는 경향이 많아요. 리드를 맡기는 게 편한 당신이라면 '찰떡궁합'이 될 수 있어요. 하지만 가끔은 그들의 의견을 존중하면서도 자신의 생각도 표현해보세요.
- 선생님
한번 결정한 건 절대 안 바꾸는 타입이에요. 과제나 활동 계획을 제출하기 전에 꼼꼼히 검토해보는 게 좋아요. 이런 선생님과 소통할 때는 명확하고 논리적으로 의견을 제시하세요. 그들은 잘 정리된 아이디어를 높이 평가할 거예요.
- 선배
이상적인 타입의 선배예요. 자기가 한 말에 책임을 지는 선배로, 겉과 속이 같아요. 다만 '나를 따르라' 스타일의 리더십을 발휘할 때가 있어요. 이런 선배와 함께 일할 때는 그들의 경험을 존중하면서도, 자신의 아이디어도 자신 있게 제안해보세요.
- 친구, 후배
일을 완벽하게 해야 한다는 생각에 앞으로만 달려갈 때가 있어요. 좋은 친구들을 놓치지 않게 '꿀잼' 시간도 함께 가져보세요. 이런 친구나 후배와 함께 있을 때는 그들의 페이스를 존중해주고, 가끔은 그냥 편하게 놀아주는 것도 좋아요.`,
  },

  AAACA: {
    type: "AAACA",
    category: "감정 자제 유형",
    desc: `'속 깊은 츤데레' 타입`,
    sub: `학교의 숨은 강자!`,
    char: `여러분, 이 타입의 친구들은 겉으로 보면 거의 완벽해 보여요. 공부도 잘하고 친구 관계도 좋죠. 책임감 있고 의리 있는 모습이 멋져요. 하지만 가끔 자기 생각을 잘 전달 못할 때가 있어요. 남을 배려하는 마음이 커도, 표현이 서툴러 상대방이 알아채지 못할 수 있죠.
이성적인 판단을 중요하게 여기지만, 마음을 쉬게 할 시간이 부족해요. 여러 일을 완벽하게 해내려다 보면 자주 지치고 힘들어질 수 있어요. 이걸 '번아웃'이라고 해요.
학교에서 인정받는 것도 좋지만, 자신을 돌보는 '셀프 케어'도 필요해요. 가끔은 다른 사람보다 자신을 위한 시간에 더 에너지를 써보세요. 휴식을 취하거나 취미 활동을 즐기는 것도 좋아요. 자신을 사랑하고 아끼는 마음을 가지면, 다른 사람들에게도 더 잘 할 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '진국' 같은 잠재력이 있지만, 가끔은 '힐링 타임'도 필요해요.
    '자기 표현'을 연습하고 균형을 잘 잡으면 진짜 '인싸'가 될 수 있어요!`,
    school: `이 타입의 학생들은 학교생활에서 다음과 같은 점들에 주의를 기울이면 좋아요. 1:1 면접에서는 강점을 보이지만, 여러 사람 앞에서 발표하거나 집단 면접 상황에서는 어려움을 느낄 수 있어요. 이런 상황에 익숙해지려면 연습이 필요해요. 학교 발표 기회를 적극적으로 활용하고, 동아리 활동에서 리더십 역할을 맡아보는 것도 좋아요.
자신의 생각과 능력을 더 잘 표현하는 방법을 배워보세요. 예를 들어, 말하기 동아리나 토론 대회에 참여해보는 건 어떨까요? 이런 활동들이 자신감을 키우는 데 도움이 될 거예요.
또한, 팀 프로젝트에 적극적으로 참여하세요. 여러분의 뛰어난 능력을 팀원들과 나누면서 협동심도 기를 수 있어요. 하지만 너무 완벽주의에 빠지지 말고, 다른 친구들의 의견도 존중하는 자세가 중요해요.
마지막으로, 학업에만 집중하지 말고 다양한 경험을 쌓으세요. 봉사활동이나 교내 행사 참여는 여러분의 성장에 큰 도움이 될 거예요. 이런 균형 잡힌 학교생활이 미래의 더 큰 성공으로 이어질 수 있답니다.`,
    human: `- 베프, 이성친구 
이런 타입은 애정 표현이 많지 않아요. 그렇다고 정이 없는 건 아니에요. 감정 표현이 서툴러서 상대방이 오해할 수 있어요. 당신이 애교 넘치는 타입을 좋아하지 않는다면, 무뚝뚝해도 진심 가득한 이 사람과 '찰떡궁합'일 수 있어요.
- 선생님
별 문제없이 잘 지낼 수 있는 '무난' 타입이에요.
- 선배
최고의 선배는 아니지만 가치관이 뚜렷하고 양심적인 사람이에요. 정의감도 있고 남을 잘 헤아려 주기도 해요. 단, 융통성이 부족해서 '꼰대'같다는 인상을 줄 수 있으니 주의해야 해요.
- 친구, 후배
이 타입은 남의 눈치를 많이 봐요. 정신적으로 위축될 때가 많아서 자신감을 많이 북돋아 주는 게 필요해요.`,
  },

  AAACB: {
    type: "AAACB",
    category: "안정 추구 유형",
    desc: `'츤데레 엄친아' 타입`,
    sub: `학교의 숨은 속깊이 챔피언!`,
    char: `여러분은 진정한 '츤데레 엄친아' 타입이에요! 겉으로 보면 완벽해 보이는 스타일이죠. 공부나 친구 관계에 에너지를 많이 쓰는 스타일이라 인복도 많아요. 책임감과 의리도 좋지만, 가끔 자기 생각이 상대방에게 잘 전달 안 될 때가 있어요.
특히 표현이 서툴러서 남을 생각하고 배려하는 만큼 상대가 알아주지 못할 때가 있어요. 또 철저한 '이성주의자'지만 마음을 쉬게 할 공간이 없어요. 그래서 자주 '번아웃'될 수 있어요. 학교에서 인정받는 것도 중요하지만 '셀프 케어'도 필요해요. 남을 위한 시간보다 자신을 위한 시간에 더 많은 에너지를 써보세요.`,
    result: `기억하세요! 이 타입은 '츤데레 엄친아' 스타일이지만, '자기표현 능력'과 '자신감'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 '힐링 타임'도 가져보세요.
    자신의 감정을 표현하는 연습을 하고 균형을 잡으면 진짜 '인싸'가 될 수 있어요! 파이팅!`,
    school: `이 타입은 1:1 발표에서는 강하지만 단체 발표나 반 전체 앞 발표에서는 약할 수 있어요.
- '수업 시간': 선생님과 1:1로 질문할 때는 자신 있게 대답하지만, 전체 발표 때는 긴장할 수 있어요. 작은 발표부터 시작해서 점점 큰 발표로 연습해보세요.
- '동아리 활동': 소규모 모임에서 리더 역할을 맡아보세요. 점점 더 큰 그룹을 이끄는 연습을 할 수 있어요.
- '학교 축제': 무대 뒤에서 기획이나 준비를 맡아보는 건 어떨까요? 점차 무대 앞으로 나오는 연습을 해보세요.
- '교내 대회': 글짓기나 포스터 대회 같은 개인 대회부터 시작해서, 점점 토론 대회 같은 단체 대회로 도전해보세요.
- '진로 상담': 진로 선생님과 1:1 상담을 자주 해보세요. 여러분의 생각을 표현하는 연습이 될 거예요.
뭐가 당신을 막고 있는지 모르겠지만, 여러 사람 앞에서 당당하게 자기 PR할 수 있는 방법을 익혀야 해요. 작은 도전부터 시작해보세요!`,
    human: `- 베프, 이성친구
이런 타입은 애정 표현이 많지 않아요. 그렇다고 정이 없는 건 아니에요. 감정 표현이 서툴러서 상대방이 오해할 수 있어요. 당신이 애교 넘치는 타입을 좋아하지 않는다면, 무뚝뚝해도 진심 가득한 이 사람과 '찰떡궁합'일 수 있어요. 그들의 행동 속에 숨겨진 진심을 찾아보세요.
- 선생님
별 문제없이 잘 지낼 수 있는 '무난' 타입이에요. 이런 선생님과 소통할 때는 직접적으로 물어보는 게 좋아요. 그들은 표현을 잘 못해도 학생들을 진심으로 생각하고 있어요. 수업 후 개인적으로 질문하러 가보세요.
- 선배
최고의 선배는 아니지만 가치관이 뚜렷하고 양심적인 사람이에요. 정의감도 있고 남을 잘 헤아려 주기도 해요. 단, 융통성이 부족해서 '꼰대'같다는 인상을 줄 수 있으니 주의해야 해요. 이런 선배와 이야기할 때는 그들의 조언을 존중하면서도, 자신의 의견도 조심스럽게 표현해보세요.
- 친구, 후배
이 타입은 남의 눈치를 많이 봐요. 정신적으로 위축될 때가 많아서 자신감을 많이 북돋아 주는 게 필요해요. 그들의 작은 노력도 인정해주고 칭찬해주세요. 함께 있을 때 편안한 분위기를 만들어주는 것도 좋아요.`,
  },

  AAACC: {
    type: "AAACC",
    category: "교과서 유형",
    desc: `'완벽 모범생 엄친아' 타입`,
    sub: `학교의 숨은 교과서 인간!`,
    char: `여러분은 진정한 '완벽 모범생 엄친아' 타입이에요! 공과 사를 확실히 구분하고 책임감이 '갓벽'해요. 주어진 과제는 정확하게 '찰떡'같이 해내는 타입이에요. 학교 규칙이나 사람의 기본을 중요하게 여겨서 좀 '교과서' 같은 사람으로 보일 수 있어요.
취미나 놀이에 시간 쓰는 걸 '시간 낭비'라고 생각하는 경우가 많고, 개인적인 일에 시간을 많이 안 써요. 그래서 친구 관계가 좀 좁아요. 요즘 친구들이랑은 '세대 차이' 느낄 수 있고, '꼰대'라는 소리 들을 수 있어요. 시대가 바뀌면 자기 생각도 같이 바꿔야 좋은 관계 맺을 수 있다는 걸 꼭 기억하세요.`,
    result: `기억하세요! 이 타입은 '완벽 모범생 스탠바이' 스타일이지만, '유연성'과 '균형 잡힌 생활'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 책임감과 성실함을 믿어요. 하지만 가끔은 '꿀잼' 시간도 가져보세요.
    시대의 변화에 맞춰 유연하게 대처하면 진짜 '인생 승자'가 될 수 있어요! 파이팅!`,
    school: `자기 고집 좀 내려놓고 주변 조언 잘 들어야 해요.
- '수업 시간': 항상 완벽한 답변을 하려고 하지 말고, 가끔은 친구들의 의견도 들어보세요.
- '동아리 활동': 학습 동아리뿐만 아니라 예술이나 스포츠 동아리도 참여해보세요. 새로운 경험이 여러분을 더 유연하게 만들 거예요.
- '학교 행사': 축제나 체육대회에서 친구들과 함께 즐기는 시간을 가져보세요. 모든 게 완벽할 필요는 없어요.
- '진로 탐색': 다양한 진로 체험 활동에 참여해보세요. 꼭 성적과 관련된 것이 아니어도 괜찮아요.
- '봉사 활동': 다양한 사람들을 만나고 소통하는 봉사 활동을 해보세요. 세상을 바라보는 시각이 넓어질 거예요.
졸업한 선배들 이야기 많이 듣고, 완벽하고 정확한 자기 성격을 입학사정관 선생님께 반만 보여주세요. 그리고 열정과 인간미를 많이 어필하면 좋은 결과 있을 거예요.`,
    human: `- 베프, 이성친구
상대방이 엄청 도덕적이라 약속 어기는 일 절대 없어요. 근데 너무 '교과서' 같아서 답답할 수 있고, 놀이 시간을 안 좋아해서 '꿀잼' 우정이나 연애는 좀 힘들 수 있어요. 가끔은 즉흥적인 활동을 제안해보세요. 그들도 새로운 경험을 통해 성장할 수 있어요.
- 선생님
객관적이고 현실적으로 대하는 게 좋아요. 빈틈없는 사람이라 공적으로만 관계 유지하는 게 편해요. 이런 선생님과 소통할 때는 정확하고 논리적으로 말하세요. 그들은 잘 정리된 생각을 높이 평가할 거예요.
- 선배
인간미보다는 실력으로 먼저 인정받는 게 좋아요. 목표의식 확실해야 하고, '흑백논리'를 좋아하는 타입이에요. 결정된 일은 꼭 완수하세요. 이런 선배와 함께 일할 때는 정확하고 책임감 있게 행동하되, 가끔은 유연한 사고도 제안해보세요.
- 친구, 후배
자기표현은 좀 약하지만 독선적인 면도 있어요. 팀워크에서 약간 문제 생길 수 있어 보여요. 이런 친구나 후배와 함께 있을 때는 그들의 의견을 존중하면서도, 다양한 관점이 있다는 걸 상기시켜주세요. 함께 '꿀잼' 시간을 보내면서 유연성을 길러보는 것도 좋아요.`,
  },

  AABAA: {
    type: "AABAA",
    category: "잠재적 거물 유형",
    desc: `'총체적 카리스마 귀요미' 타입`,
    sub: `학교의 숨은 에너지 뱅크!`,
    char: `여러분은 진정한 '총체적 카리스마 귀요미' 타입이에요! 책임감과 정의감이 '불타오르고', 친구들에 대한 애정도 깊고, 어린아이 같은 자유로움을 가진 타입이에요. 에너지가 넘치는 타입이라 가끔 좀 '정신없어' 보일 수 있어요. 하지만 속으로는 엄청난 '내공'을 가진 사람이에요.
좋은 친구들을 만나면 진짜 '대박' 인재가 될 수 있어요. 이미 엄청난 잠재력이 있고, 친구 관계의 '희로애락'을 잘 아는 타입이에요. 이런 사람이 주변에 있으면 즐거움을 주고, 시간 가는 줄 모르고 빠져들게 돼요. 어디 가도 '인기쟁이'예요. 하지만 이성적인 면을 좀 더 키울 필요가 있어요. 객관적이고 냉정하게 일 처리 못 하는 게 단점이 될 수 있어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 카리스마와 창의력을 믿어요. 하지만 가끔은 '집중' 시간도 가져보세요.
    '열정'과 '체계성'의 밸런스를 잘 잡으면 진짜 '인생 승자'가 될 수 있어요! 파이팅!`,
    school: `당신 안에는 정말 많은 재능이 있어요.
- '수업 시간': 당신의 창의력을 발휘해보세요. 독특한 아이디어로 발표를 해보는 건 어떨까요?
- '동아리 활동': 리더십을 발휘할 수 있는 동아리를 찾아보세요. 연극부나 학생회 같은 곳에서 빛날 수 있어요.
- '학교 행사': 축제나 체육대회 기획팀에 들어가보세요. 당신의 아이디어로 학교를 들썩이게 만들 수 있어요!
- '교우 관계': 다양한 친구들과 어울려보세요. 당신의 에너지는 많은 친구들을 끌어들일 거예요.
- '진로 탐색': 다양한 분야를 경험해보세요. 당신의 다재다능함을 살릴 수 있는 진로를 찾을 수 있을 거예요.
그 중에서 가장 '찐'한 걸 꺼내서 선생님께 보여주세요. 당신의 최고 장점은 상대방에게 에너지를 불어넣는 힘을 이미 갖고 있다는 거예요. 위축되지 말고 더 멋지게 자신을 'PR'하세요.`,
    human: `- 베프, 이성친구
진짜 좋은 친구나 연인 타입이에요. 재미있는 학창 시절 보내기 위해 노력 많이 하는 타입이에요. 솔직한 타입이라 문제될 만한 건 거의 없어요. 이런 친구나 이성친구와 함께 있을 때는 그들의 에너지를 즐기되, 가끔은 차분한 시간도 가져보세요.
- 선생님
별 문제없이 잘 지낼 수 있는 '무난' 타입이에요. 이런 선생님과 소통할 때는 그들의 열정을 존중하면서도, 체계적인 학습 방법을 함께 찾아보세요. 선생님의 창의적인 아이디어를 잘 따라가되, 필요할 때는 정리하는 시간을 가져보세요.
- 선배
이 타입은 언제나 '한 걸음 앞서' 갈 수 있는 타입이에요. 상상력이 풍부하고 직감이 발달했어요. 가끔 일을 체계적으로 못 할 수도 있지만, 단체에 활력을 불어넣는 사람이에요. 이런 선배와 함께 활동할 때는 그들의 아이디어를 존중하면서도, 실현 가능한 계획으로 만들어가는 역할을 해보세요.
- 친구, 후배
어떻게 행동할지 예측하기 어려운 사람이라 잘 관찰해야 해요. 하지만 그의 장점을 잘 키워주면 절대 배신하거나 이용하지 않아요. 이런 친구나 후배와 함께 있을 때는 그들의 창의성을 인정해주고, 가끔은 현실적인 조언도 해주세요.`,
  },

  AABAB: {
    type: "AABAB",
    category: "워라밸 유형",
    desc: `'찐 비타민 올라운더' 타입`,
    sub: `학교의 숨은 엄친아 on 불꽃 모드!`,
    char: `여러분은 진정한 '찐 비타민 올라운더' 타입이에요! 공부와 취미 생활 둘 다 '찐'으로 잘하는 타입이에요. 공부에 대한 열정도 있고, 학교 생활을 즐기면서 자기 계발에도 투자를 아끼지 않아요. 다른 친구들이 보기에는 정말 '갓벽'한 사람으로 보일 수 있어요. 공부와 여가 생활 균형 잡기가 보통 어렵지만, 이 타입은 성적에만 집착하지도 않고, 그렇다고 놀기만 하지도 않아요. 학교 생활을 좀 더 '여유롭게' 바라보는 스타일이에요.
또 이 타입은 친구들 대할 때 너무 차갑지도 않고 계산적이지도 않아요. 가끔 친구 생각하는 마음이 커서 '잔소리'나 과보호할 때가 있지만, 정말 인간미 넘치는 사람이에요. 애정 표현도 잘하고, 자기랑 남한테 솔직한 타입이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 만렙' 스타일이지만, '시간 관리 능력'과 '스트레스 관리'를 더 잘하면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 다재다능함을 믿어요. 하지만 가끔은 '힐링 타임'도 가져보세요.
    '열정'과 '여유'의 밸런스를 잘 잡으면 진짜 '인생 승자'가 될 수 있어요! 파이팅!`,
    school: `아직 원하는 동아리나 그룹에 못 들어갔다면, 노력이 부족하거나 너무 쉽게 생각해서일 수 있어요.
- '동아리 활동': 다양한 동아리에 관심을 가져보세요. 학술 동아리부터 예술, 체육 동아리까지 폭넓게 경험해보는 게 좋아요.
- '학생회 활동': 리더십을 발휘할 수 있는 학생회에 도전해보세요. 여러분의 균형 잡힌 시각이 큰 도움이 될 거예요.
- '봉사 활동': 다양한 봉사 활동에 참여해보세요. 여러분의 따뜻한 마음을 나누면서 새로운 경험을 쌓을 수 있어요.
- '교내 대회': 학업 관련 대회뿐만 아니라 예술, 체육 대회에도 참가해보세요. 여러분의 다재다능함을 뽐낼 수 있을 거예요.
- '멘토링 프로그램': 후배들을 위한 멘토가 되어보는 것도 좋아요. 여러분의 균형 잡힌 학교 생활 노하우를 나눠주세요.
동아리나 그룹 면접 준비는 정말 냉정하고 신중하게 체계적으로 해야 해요. 인맥 좋은 당신은 주변 친구들부터 공략해야 해요. 많은 사람들한테 도움 요청해보세요. 분명 책임감 강하고 목표 뚜렷한 당신을 기다리는 곳이 있을 거예요.`,
    human: `- 베프, 이성친구
진짜 '꿀잼' 친구나 이성친구가 될 수 있어요. 상대방 배려하고 맞춰주는 게 이 타입의 장점이라 별 문제 없어요. 이런 친구나 이성친구와 함께 있을 때는 그들의 다양한 관심사를 존중해주고, 함께 새로운 경험을 해보세요.
- 선생님
멘토로서 무난한 타입이에요. 이런 선생님과 소통할 때는 학업뿐만 아니라 다양한 주제로 대화를 나눠보세요. 그들의 균형 잡힌 시각에서 많은 것을 배울 수 있을 거예요.
- 선배
공부보다는 사람 중심적인 사람이에요. 실력보다 인간성으로 먼저 인정받도록 해야 해요. 리더십도 충분해서 함께 활동하기 좋은 선배예요. 이런 선배와 함께 활동할 때는 그들의 조언을 귀담아 들으면서, 자신만의 스타일도 발전시켜 나가세요.
- 친구, 후배
나중에 당신의 '최강 파트너'가 될 수 있게 잘 이끌어주세요. 분명 당신에게 좋은 영향 줄 사람이에요. 이런 친구나 후배와 함께 있을 때는 서로의 장점을 인정하고 격려해주세요. 함께 성장하는 관계를 만들어갈 수 있을 거예요.`,
  },

  AABAC: {
    type: "AABAC",
    category: "의리파 아저씨 유형",
    desc: `'의리 만렙 학교 축제의 왕' 타입`,
    sub: `교실의 숨은 열정 에너자이저!`,
    char: `여러분은 진정한 '의리 만렙 학교 축제의 왕' 타입이에요! 알면 알수록 진정으로 빠져들게 되는 스타일이죠. 의리, 인정, 책임감이 뛰어난 이 유형은 가끔 고집이 세게 보일 수 있지만, 사실 정말 대단한 인재예요.
학교 생활을 즐기는 재미있는 면도 많아요. 보수적인 면이나 무뚝뚝한 면이 있긴 하지만, 동시에 순수하고 애교 있는 면도 있어요. 열정이 넘치는 사람이라 함께 있으면 에너지가 충전되는 느낌을 줘요. 가끔 정의감에 불타서 스스로를 희생하려는 경우도 있지만, 누군가를 책임지고 보호하는 성격 덕분에 어디서든 리더가 될 가능성이 커요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 의리와 열정을 믿어요. 하지만 가끔은 '쉬어가는 타임'도 가져보세요.
    '책임감'과 '재미'의 밸런스를 잘 잡으면 진짜 '학교의 아이콘'이 될 수 있어요! 파이팅!`,
    school: `면담이나 발표할 때 개성이 확 드러나는 스타일이에요.
- '학생회 활동': 여러분의 리더십을 마음껏 발휘할 수 있는 곳이에요. 학생회장 선거에 도전해보는 건 어떨까요?
- '동아리 활동': 봉사 동아리나 체육 동아리에서 여러분의 열정을 보여주세요. 팀워크가 중요한 활동에서 빛날 수 있어요.
- '학교 축제': 축제 기획단에 참여해보세요. 여러분의 아이디어로 학교를 들썩이게 만들 수 있을 거예요.
- '또래 상담': 친구들의 고민을 들어주고 조언해주는 또래 상담자로 활동해보세요. 여러분의 따뜻한 마음이 큰 도움이 될 거예요.
- '체육대회': 반 대표로 나가 여러분의 열정과 팀워크를 보여주세요.
좀 더 신중하고 진지한 마음으로 임하면 좋은 점수 받을 수 있어요. 발표나 면접 전에 깊이 있게 준비하고, 때로는 감정을 조절하는 연습도 해보세요.`,
    human: `- 베프, 이성친구
만약 베프나 이성친구가 이 타입이면 통제하기 힘들어요. '걸크러시' 스타일이라고 생각하면 편해질 거예요. 베프나 남자친구인 경우는 카리스마 넘치는 '매력쟁이'로 인정하면 좋아요. 단, 남의 일에 대해 본인 생각과 다르면 화낼 때가 있으니 조심해야 해요. 이런 친구나 이성친구와 함께 있을 때는 그들의 열정을 인정해주되, 가끔은 차분하게 대화를 나눠보세요.
- 선생님
이런 선생님과 잘 지내려면 당신의 실력을 좀 더 키워야 해요. 선생님에게 끌려 갈 수 있어요. 이런 선생님의 수업에서는 적극적으로 참여하고, 때로는 선생님의 열정에 맞춰 자신의 의견을 제시해보세요.
- 선배
후배들에게는 엄청 이성적이고 신중한 선배예요. 후배들 일 처리에 대해 공정하게 평가하고 권한도 주는 선배예요. 다만, 감정이 격해질 때가 있으니 주의해야 해요. 이런 선배와 함께 활동할 때는 그들의 열정을 존중하면서도, 때로는 차분하게 의견을 제시해보세요.
- 친구, 후배
선배나 친구로는 정말 좋은 타입이지만 후배로 있으면 가끔 골치 아플 수 있어요. 의욕 넘치고 행동파라서 극단적으로 일 처리할 때가 있거든요. 이런 친구나 후배와 함께 일할 때는 그들의 열정을 인정해주되, 때로는 좀 더 신중하게 생각해보자고 제안해보세요.`,
  },

  AABBA: {
    type: "AABBA",
    category: "범생이 유형",
    desc: `'완벽주의 힙스터 모범생' 타입`,
    sub: `교실의 숨은 트렌드세터!`,
    char: `여러분은 진정한 '완벽주의 힙스터 모범생' 타입이에요! 항상 친구들 입장에서 생각하고 다른 사람 말을 잘 들어줘요. 주변 친구들을 배려하고 남의 시선을 의식하면서 행동하는 타입이에요. 또 친구들한테 칭찬받을 만한 결과물도 많이 만들어내요. 그렇다고 스스로 만족하거나 '힐링'하는 스타일은 아니에요.
왜냐하면 주변 시선을 너무 의식해서 '모범생' 같은 행동만 하려고 해요. 그래서 자기를 어떤 틀 안에 가두려는 경향이 있어요. 높은 신념, 강한 책임감, 무조건적인 헌신과 애정은 결국 '멘붕'으로 이어질 때가 많아요. 가끔은 당신의 높은 에너지가 큰 부담이 돼서 몸과 마음을 힘들게 할 수 있으니 조심하세요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 만렙' 스타일이지만, '자기 관리'와 '스트레스 해소 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 완벽주의 성향을 인정하되, 가끔은 '힐링'과 '자유'도 필요해요.
    '책임감'과 '자기 케어'의 밸런스를 잘 잡으면 진짜 '학교의 아이콘'이 될 수 있어요! 파이팅!`,
    school: `선생님들이나 학교에서는 이런 타입을 좋아해요. 있는 그대로의 자신을 'PR'하세요.
- '수업 시간': 개인 발표에서 빛을 발할 수 있어요. 자신의 생각을 잘 정리해서 발표해보세요.
- '동아리 활동': 학습 동아리나 봉사 동아리에서 활약해보세요. 책임감 있는 모습으로 주목받을 수 있어요.
- '학급 임원': 반장이나 부반장으로 활동하면서 리더십을 발휘해보세요.
- '교내 대회': 글짓기 대회나 학술 대회에 참가해보세요. 여러분의 꼼꼼함이 빛을 발할 거예요.
- '진로 탐색': 다양한 진로 체험 활동에 참여해보세요. 새로운 경험을 통해 자신의 관심사를 넓혀갈 수 있어요.
같은 반에서 다른 친구들이랑 스펙이 비슷하다면 당신이 그 기회를 잡을 수 있어요. 하지만 가끔은 '힐링 타임'도 가져보세요. 완벽함을 추구하다 보면 스트레스받을 수 있으니까요.`,
    human: `- 베프, 이성친구
정말 성실하고 노력 많이 하는 타입이에요. 학교에서나 집에서나 행동이 똑같아요. 적당히 자제하면서 친구나 여자친구를 위해 희생하는 모습도 많이 보여요. 이런 친구나 이성친구와 함께 있을 때는 그들의 노력을 인정해주되, 가끔은 편하게 쉬어도 된다고 말해주세요.
- 선생님
융통성은 좀 부족하지만 공부나 과제는 확실히 하는 사람이에요. 무리한 요구도 안 하고 편견을 갖고 보지도 않아요. 이런 선생님과 소통할 때는 정확하고 성실하게 대하되, 가끔은 창의적인 아이디어도 제안해보세요.
- 선배
다른 사람 평가를 확실하게 하는 믿음직한 선배예요. 하지만 후배들한테 속으로 불만 있어도 말 안 하고 혼자 스트레스 받는 경우가 종종 있어요. 이런 선배와 함께 활동할 때는 그들의 기대에 부응하되, 때로는 솔직한 대화를 나눠보세요.
- 친구, 후배
축제나 수학여행에서 '분위기 메이커'는 아니에요. 단체 활동에서 적당히 분위기 맞추면서 문제 일으킬 만한 행동은 안 하는 평범한 친구라고 보면 돼요. 이런 친구나 후배와 함께 있을 때는 그들의 성실함을 인정해주고, 가끔은 함께 즐거운 시간을 보내보세요.`,
  },

  AABBB: {
    type: "AABBB",
    category: "노력파 어버이 유형",
    desc: `'노력왕 자기관리 만렙' 타입`,
    sub: `교실의 숨은 성장 아이콘!`,
    char: `여러분은 진정한 '노력왕 자기관리 만렙' 타입이에요! 인생에서 '대박'을 그냥 기다리기보다는 '대박'을 만들기 위해 노력하는 타입이에요. 어떤 상황, 어떤 위기가 와도 정면으로 맞서는 스타일이죠. 최선을 다했는데 결과가 별로여도 너무 '멘붕'하거나 불평하지 않아요. 가끔은 안 좋은 결과도 '쿨'하게 넘기는 여유도 있어요.
친구 관계에서 이 타입은 적당히 자기 의견도 말하고 타협도 할 줄 아는 똑똑한 사람이에요. 사람을 편견 없이 보고, 주변 친구들을 잘 돌보고 이끌어요. 단점이 있다면 공부나 활동에만 너무 빠져서 학교 생활의 '꿀잼'을 놓칠 수 있으니 조심해야 해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 만렙' 스타일이지만, '균형 잡힌 생활'과 '자기 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 노력하는 모습을 믿어요. 하지만 가끔은 '꿀잼' 시간도 가져보세요.
    '열정'과 '즐거움'의 밸런스를 잘 잡으면 진짜 '인생 승자'가 될 수 있어요! 파이팅!`,
    school: `열심히 한 만큼 성적이 안 나오는 건 아직 기회가 안 왔다는 거예요.
- '수업 시간': 적극적으로 발표하고 질문해보세요. 작은 참여도 큰 변화를 만들 수 있어요.
- '동아리 활동': 다양한 동아리에 도전해보세요. 여러분의 노력이 빛을 발할 거예요.
- '학생회 활동': 학급 임원이나 학생회에 도전해보세요. 리더십을 발휘할 좋은 기회예요.
- '교내 대회': 다양한 대회에 참가해보세요. 준비 과정에서 많은 것을 배울 수 있어요.
- '자기 개발': 독서나 온라인 강의를 통해 꾸준히 자기 개발을 해보세요.
만약 발표나 면담 기회가 있다면 작은 장점까지 자세히 어필해야 해요. 이미 당신은 '인재'의 조건을 갖고 있으니 좀 당당하게 자랑할 줄 알아야 해요. 겸손만으로는 성공할 수 없어요.`,
    human: `- 베프, 이성친구
친구나 이성친구로 별로 흠 없는 타입이에요. 좀 '꼰대'스러운 부분도 있어서 잔소리도 많이 하지만 늘 남을 챙겨주고 마음이 넓은 사람이에요. 이런 친구나 이성친구와 함께 있을 때는 그들의 노력을 인정해주고, 가끔은 함께 '꿀잼' 시간을 보내보세요.
- 선생님
적당한 관계를 유지해야 해요. 너무 친한 모습이나 너무 공적인 모습을 보여주는 건 좋지 않아요. 이런 선생님과 소통할 때는 성실하고 열심히 하는 모습을 보여주되, 때로는 자신의 의견도 조심스럽게 표현해보세요.
- 선배
결과보다는 과정을 중요하게 여기는 선배예요. 그렇다고 목표의식이 없어선 안 돼요. 열심히 노력하는 후배를 아낌없이 응원하고 밀어주는 타입이에요. 이런 선배와 함께 활동할 때는 열심히 노력하는 모습을 보여주고, 때로는 선배의 조언을 구해보세요.
- 친구, 후배
별로 문제 일으키지 않는 타입이에요. 자기가 튀기보다는 남이랑 협력해서 시너지 내는 일을 잘해요. 단, 책임 맡기고 칭찬하면 약해지는 타입이에요. 이런 친구나 후배와 함께 있을 때는 그들의 노력을 인정해주고, 함께 목표를 향해 나아가보세요.`,
  },

  AABBC: {
    type: "AABBC",
    category: "굳은 신념주의자 유형",
    desc: `'마이웨이 올라운더 꿈나무' 타입`,
    sub: `교실의 숨은 신념 챔피언!`,
    char: `여러분은 진정한 '마이웨이 올라운더 꿈나무' 타입이에요! 한마디로 '본투비 고집불통'에 자기만의 신념이 뚜렷한 타입이에요. 뭐든 대충 하는 법이 없어요. 친구들 눈치 보면서 적당히 처세하는 게 때론 필요하지만, 이 타입은 자기가 생각하는 대로 그냥 밀어붙이는 경우가 많아요. '꼰대 예약' 스타일이라고 볼 수 있죠.
그렇다고 너무 자기중심적으로만 보거나 자기 이익만 추구하는 건 아니에요. 그냥 친구 관계에서 융통성이 좀 부족할 뿐이에요. 가끔은 남이 정한 걸 따라갈 필요도 있고, 친구들 의견을 있는 그대로 받아들일 때도 있어요. 친구들 눈치 보는 게 어렵겠지만, 이 타입에겐 그게 제일 필요해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '융통성'과 '팀워크 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 강한 신념을 믿어요. 하지만 가끔은 다른 사람의 의견도 들어보세요.
    '신념'과 '융통성'의 밸런스를 잘 잡으면 진짜 '학교의 아이콘'이 될 수 있어요! 파이팅!`,
    school: `때론 당신의 고집 때문에 좋은 기회를 놓칠 수 있어요.
- '수업 시간': 자기 의견을 강하게 주장하기 전에, 다른 친구들의 의견도 들어보세요.
- '동아리 활동': 팀워크가 중요한 동아리에 참여해보세요. 협력하는 법을 배울 수 있어요.
- '그룹 프로젝트': 리더 역할보다는 팀원으로 참여해보세요. 다른 사람의 리드를 따라가는 연습을 할 수 있어요.
- '학급 활동': 학급 회의에 적극 참여하되, 다른 친구들의 의견도 존중해보세요.
- '봉사 활동': 다양한 사람들과 함께 일하는 봉사 활동에 참여해보세요. 타인을 이해하는 능력을 기를 수 있어요.
학교나 동아리는 자기 색깔 강한 사람보다 팀워크 잘 맞추는 사람을 더 원해요. 아무리 실력이 좋아도 동기애가 없으면 인기 있는 동아리나 그룹에 들어가기 힘들어요.`,
    human: `- 베프, 이성친구
이 타입에 맞출 수 있다면 같이 지내도 좋아요. 하지만 사람은 쉽게 안 변한다는 걸 알고 깊은 관계 시작해야 해요. 이런 친구나 이성친구와 함께 있을 때는 그들의 의견을 존중해주되, 가끔은 타협점을 찾아보세요.
- 선생님
한번 한 약속은 절대 지키는 타입이에요. 주변 의식하고 예의를 중요하게 생각해요. 이런 선생님과 소통할 때는 약속을 잘 지키고, 예의를 지키되 자신의 의견도 조심스럽게 표현해보세요.
- 선배
자기 색깔 강한 선배예요. 적당히 맞춰주지 않으면 관계가 힘들 때가 많아요. 일단 그 사람이 결정한 대로 따라가세요. 그러지 않으면 항상 '불화' 생겨요. 이런 선배와 함께 활동할 때는 그들의 리더십을 인정하면서도, 때로는 자신의 의견을 조심스럽게 제안해보세요.
- 친구, 후배
자기 의견 절대 안 꺾는 타입이에요. 그래도 이 타입은 칭찬에 약하니까 적절한 '당근'을 줘서 문제 안 생기게 조심해야 해요. 이런 친구나 후배와 함께 있을 때는 그들의 의견을 존중해주되, 때로는 다른 시각도 있다는 것을 부드럽게 알려주세요.`,
  },

  AABCA: {
    type: "AABCA",
    category: "사회봉사 유형",
    desc: `'겉인싸 속은둔 하이브리드' 타입`,
    sub: `학교의 숨은 반전 매력왕!`,
    char: `여러분은 진정한 '겉인싸 속은둔 하이브리드' 타입이에요! 겉으로는 '찐 인싸'처럼 보이지만 실제로는 남의 눈치를 엄청 보는 타입이에요. 밖으로는 '핫'해 보이지만 속으로는 '집돌이/집순이' 성향이 강해요.
사회나 다른 사람에 대한 배려가 최우선이고, 의리와 인정을 엄청 중요하게 여겨요. 항상 의무감과 도덕의식에 둘러싸여 있는 타입이라고 볼 수 있어요. 누군가한테 '좋아요'를 받을 순 있지만, 정작 자기를 위해 '힐링'하는 타입은 아니에요.
이 타입의 여러분은 친구들 사이에서 인기가 많지만, 실제로는 혼자만의 시간을 더 즐기는 편이에요. 단체 카톡방에서는 항상 활발하게 대화에 참여하지만, 실제 만남에서는 조용한 편이죠. 친구들의 고민을 잘 들어주고 조언해주는 것을 좋아하지만, 정작 자신의 고민은 잘 털어놓지 않아요.
학교에서는 모든 활동에 열심히 참여하는 모습을 보이지만, 집에 오면 완전히 다른 모습으로 변해요. 넷플릭스 보는 걸 좋아하고, 온라인 게임을 즐기는 등 혼자만의 시간을 소중히 여겨요. 이런 반전 매력이 여러분의 특징이에요!`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 배려심과 숨겨진 실력은 정말 멋져요. 하지만 가끔은 자신의 생각을 더 적극적으로 표현하고, 시간을 효율적으로 관리하는 법을 배워보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `엄청 좋은 '실력'을 가졌는데도 이걸 보여주지 못하고 있어요. 특히 단체 발표 같은 경우엔 '존재감 제로'가 될 수 있어요. 좀 더 '열정 넘치는' 모습으로 바꿔보세요. 때론 열정이 스펙보다 더 중요할 수 있어요.
수업 시간에 발표할 때, 자신감 있게 손을 들어보세요. "선생님, 제가 한번 발표해볼게요!"라고 말하는 연습을 해보는 건 어때요?
조별 과제에서는 리더 역할에 도전해보세요. "우리 이렇게 역할을 나눠서 해볼까?"라고 제안해보는 건 어떨까요?
동아리 활동에서도 적극적으로 아이디어를 내보세요. "이번 축제 때 이런 부스를 열면 어떨까요?"라고 말해보세요.
쉬는 시간에도 친구들과 더 많이 어울려보세요. "점심 먹고 운동장에서 축구할 사람?"이라고 물어보는 건 어때요?
방과 후에는 스터디 그룹을 만들어보세요. "우리 같이 공부하면서 서로 모르는 거 물어보면 좋을 것 같아!"라고 제안해보세요.`,
    human: `- 베프, 이성친구
당신을 많이 맞춰주는 스타일이에요. "네 생각도 중요해. 솔직하게 말해줘!"라고 격려해주세요.
- 선생님
항상 성실한 사람이에요. "선생님, 제 의견을 말씀드려도 될까요?"라고 조심스럽게 물어보세요.
- 선배
카리스마는 없지만 후배들 기분 잘 맞춰줄 수 있는 사람이에요. "선배, 이거 어떻게 생각하세요?"라고 의견을 물어보세요.
- 친구, 후배
눈에 안 띄고 조용한 타입이라서 관심 갖고 그 친구의 자존감을 많이 높여주면 더 큰 '성과'를 낼 수 있어요. "네 아이디어 정말 좋다! 더 자세히 얘기해줘!"라고 말해주세요.`,
  },

  AABCB: {
    type: "AABCB",
    category: "조숙한 맏언니 유형",
    desc: `'겉인싸 속고수 댄서' 타입`,
    sub: `교실의 숨은 카멜레온 챔피언!`,
    char: `여러분은 진정한 '겉인싸 속고수 댄서' 타입이에요! 겉으로는 '찐 인싸'처럼 보이지만 실제로는 친구들 눈치를 엄청 보는 타입이에요. 밖으로는 '핫'해 보이지만 속으로는 '집돌이/집순이' 성향이 강해요. 학교와 친구들에 대한 배려가 최우선이고, 의리와 인정을 엄청 중요하게 여겨요. 항상 의무감과 도덕의식에 둘러싸여 있는 타입이라고 볼 수 있어요.
누군가한테 '좋아요'를 받을 순 있지만, 정작 자기를 위해 '힐링'하는 타입은 아니에요. 자기 생각을 좀 더 표현하는 법을 익혀야 해요. 순응하는 것도 중요하지만 친구들이 보기엔 너무 '찌질이'처럼 보일 수 있어요. 자기 감정을 좀 더 드러내고 주변 신경 쓰는 모습은 버리는 게 좋아요. 친구들 비위 맞추다가 정작 본인이 '손해'보는 경우도 많아요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '자신감'과 '자기표현 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 숨겨진 잠재력을 믿어요. 하지만 가끔은 자신의 목소리를 내는 연습도 해보세요.
    '배려'와 '자기 주장'의 밸런스를 잘 잡으면 진짜 '학교의 숨은 보석'이 될 수 있어요! 파이팅!`,
    school: `엄청 좋은 '실력'을 가졌는데도 이걸 보여주지 못하고 있어요.
- '수업 시간': 발표를 자주 해보세요. 처음엔 어렵겠지만, 점점 자신감이 생길 거예요.
- '동아리 활동': 리더십을 발휘할 수 있는 동아리에 참여해보세요. 숨겨진 잠재력을 발견할 수 있어요.
- '학급 활동': 학급 임원에 도전해보세요. 책임감을 가지고 친구들을 이끄는 경험을 할 수 있어요.
- '교내 대회': 말하기 대회나 토론 대회에 참가해보세요. 자기 의견을 표현하는 연습이 될 거예요.
- '멘토링 프로그램': 후배들의 멘토가 되어보세요. 누군가를 가르치면서 자신감을 기를 수 있어요.
특히 단체 발표 같은 경우엔 '존재감 제로'가 될 수 있어요. 좀 더 '열정 넘치는' 모습으로 바꿔보세요. 때론 열정이 스펙보다 더 중요할 수 있어요.`,
    human: `- 베프, 이성친구
당신을 많이 맞춰주는 스타일이에요. 큰 싸움이나 감정 조절 못 해서 주변 사람 힘들게 할 일이 거의 없어요. 아주 '무난'해요. 이런 친구나 이성친구와 함께 있을 때는 그들의 의견도 물어보고, 가끔은 그들이 주도권을 가질 수 있게 해주세요.
- 선생님
항상 성실한 사람이에요. 특히 학생을 존중하고 의견 많이 듣고 결정 내리는 사람이라 꾸준히 관계 유지하면 좋은 멘토가 될 수 있어요. 이런 선생님과 소통할 때는 적극적으로 자신의 의견을 표현해보세요. 선생님은 여러분의 생각을 존중해줄 거예요.
- 선배
카리스마는 없지만 후배들 기분 잘 맞춰줄 수 있는 사람이에요. 이런 선배와 함께 활동할 때는 그들의 친절함을 인정하면서도, 때로는 자신의 의견을 제안해보세요.
- 친구, 후배
눈에 안 띄고 조용한 타입이라서 관심 갖고 그 친구의 자존감을 많이 높여주면 더 큰 '성과'를 낼 수 있어요. 이런 친구나 후배와 함께 있을 때는 그들의 장점을 자주 칭찬해주고, 의견을 물어보세요. 그들의 숨겨진 재능을 발견할 수 있을 거예요.`,
  },

  AABCC: {
    type: "AABCC",
    category: "상식 중심 유형",
    desc: `'꼰대 아님 원칙주의자' 타입`,
    sub: `교실의 숨은 룰 지키기 챔피언!`,
    char: `여러분은 진정한 '꼰대 아님 원칙주의자' 타입이에요! 엄청 '꼰대'같이 상식의 틀에서 벗어나는 일이 거의 없어요. 다른 친구들에게 피해 주는 일은 절대 안 하지만 생각의 폭이 너무 좁을 때가 많아요. 정해진 자기만의 세계에서 정해진 방식대로 생활하고 행동해요.
흑백을 확실히 구분하고 친구들 평가도 잘해요. 물론 친구들을 챙겨주려고 관심 갖고 얘기하지만, 친구들은 '잔소리'로 받아들일 가능성이 커요. 한번 마음먹은 일은 쉽게 안 바꿔서 친구 관계에서 가끔 부딪힐 수 있어요. 하지만 주변 친구들은 당신과 같을 수 없어요. 좀 더 '오픈 마인드'로 친구들 입장에서 관계를 이어가세요. 그러지 않으면 보수적이고 완고하며 융통성 없는 사람으로 '찍힐' 수 있어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 만렙' 스타일이지만, '유연성'과 '오픈 마인드'를 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 원칙을 지키는 모습을 믿어요. 하지만 가끔은 새로운 것에 도전하는 용기도 가져보세요.
    '신념'과 '유연함'의 밸런스를 잘 잡으면 진짜 '학교의 든든한 기둥'이 될 수 있어요! 파이팅!`,
    school: `선생님들이 쉽게 접하기 힘든 타입이에요.
- '수업 시간': 규칙을 잘 지키고 성실하게 수업에 참여하세요. 하지만 가끔은 창의적인 의견도 내보세요.
- '동아리 활동': 규칙이 명확한 동아리를 찾아보세요. 하지만 새로운 활동에도 도전해보세요.
- '학급 임원': 학급 규칙을 만들고 지키는 데 도움을 줄 수 있어요. 하지만 친구들의 의견도 듣는 연습을 해보세요.
- '교내 대회': 룰이 명확한 대회에 참가해보세요. 동시에 창의력을 요구하는 대회도 도전해보세요.
- '봉사 활동': 정기적이고 체계적인 봉사 활동을 찾아보세요. 다양한 사람들과 소통하는 기회로 삼으세요.
원하는 동아리나 그룹이 있다면 거기에 자신을 맞출 수 있도록 최대한 어필하세요. 당신의 신념이 때론 쓸데없는 고집으로 보일 수 있어요.`,
    human: `- 베프, 여자친구
당신도 보수적인 사람이면 이 타입이랑 잘 맞아요. 하지만 '핫'하고 외향적이며 긍정적인 사람이면 트러블 많이 생길 것 같아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 원칙을 존중해주되, 가끔은 새로운 경험을 함께 해보세요.
- 선생님
이런 선생님과 소통할 때는 규칙을 잘 지키면서도, 때로는 창의적인 아이디어를 조심스럽게 제안해보세요.
- 선배
'눈치 게임' 하는 걸 안 좋아해요. 공부 중독인 사람이니까 실력을 먼저 보여주세요. 이런 선배와 함께 활동할 때는 정해진 규칙을 잘 따르면서도, 가끔은 새로운 방식을 제안해보세요.
- 친구, 후배
좀 구식(?)한 면도 있지만 성실하고 도덕적인 사람으로, 맡은 일에 '꿀 빨지' 않고 열심히 하는 사람이에요. 이런 친구나 후배와 함께 있을 때는 그들의 성실함을 인정해주고, 때로는 유연한 사고를 할 수 있도록 도와주세요.`,
  },

  AACAA: {
    type: "AACAA",
    category: "현실 무시 유형",
    desc: `'4차원 긍정왕 쿠키' 타입`,
    sub: `교실의 숨은 에너지 뱅크!`,
    char: `여러분은 진정한 '4차원 긍정왕 쿠키' 타입이에요! 항상 적극적이고 밝으며 '핫'해요. 호기심도 엄청 많고 눈치도 빨라요. 친구들 비위를 잘 맞추고 배려심도 있어요. 문제는 '냉철한' 사고가 좀 부족하다는 거예요.
어떤 문제가 생겼을 때 계획 세워서 체계적으로 처리하는 게 많이 부족해요. 상황을 명확하게 판단하고 이성적으로 접근해야 할 때도 감성에 치우칠 때가 많아요. 친구 관계, 넘치는 열정도 중요하지만 현실감각을 높일 수 있도록 당신의 에너지를 잘 조절해야 해요. 주관적인 것보다 객관적으로 생각하는 걸로 시작해서 변화에 도전해보세요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 창의력과 긍정 에너지를 믿어요. 하지만 가끔은 현실적으로 생각하고 계획을 세우는 연습도 해보세요.
    '열정'과 '현실감각'의 밸런스를 잘 잡으면 진짜 '학교의 아이디어 뱅크'가 될 수 있어요! 파이팅!`,
    school: `당신은 연극, 예술이나 창의력이 필요한 활동이 잘 맞아요.
- '동아리 활동': 연극부, 미술부, 밴드부 같은 창의적인 동아리에 참여해보세요.
- '학교 축제': 공연이나 전시 기획에 참여해보세요. 당신의 아이디어로 학교를 들썩이게 만들 수 있어요!
- '수업 시간': 발표나 토론 시간에 적극적으로 참여하세요. 하지만 가끔은 다른 친구들의 의견도 경청해보세요.
- '과제': 창의적인 아이디어를 내는 건 좋지만, 계획을 세우고 체계적으로 진행하는 연습도 해보세요.
- '봉사 활동': 다양한 사람들과 소통하는 봉사 활동에 참여해보세요. 당신의 긍정 에너지가 빛을 발할 거예요.
혹시 회계나 사무 관리를 생각했다면 다시 한 번 고민해보세요. 원하는 동아리나 그룹이 있으면, 거기의 활동	방향이 뭔지 확인한 후 꼼꼼하게 준비해야 해요.`,
    human: `- 베프, 여자친구
당신이 이성적이고 논리적인 사람이면 함께 좋은 관계 만들어갈 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 긍정 에너지를 즐기되, 가끔은 현실적인 조언도 해주세요.
- 선생님
논리적으로 남을 설득하는 기술은 약하지만, 변화와 다양성으로 새로운 아이디어 얻을 수 있는 멘토예요. 이런 선생님과 소통할 때는 창의적인 아이디어를 마음껏 나누되, 때로는 체계적으로 정리해서 발표해보세요.
- 선배
인간적으로는 너무 좋지만 공부나 활동할 때 실수 많은 선배예요. 이런 선배와 함께 활동할 때는 그들의 아이디어를 존중하면서도, 실행 계획을 함께 세워보세요.
- 친구, 후배
에너지 '만렙'인 사람이라 상대방한테 좋은 기운 줄 수 있지만, 일을 처음부터 끝까지 체계적으로 가르쳐서 이끌어가야 해요. 이런 친구나 후배와 함께 있을 때는 그들의 열정을 인정해주고, 함께 계획을 세우고 실천하는 연습을 해보세요.`,
  },

  AACAB: {
    type: "AACAB",
    category: "인간 럭비공 유형",
    desc: `'불꽃 튀는 인간 포켓몬' 타입`,
    sub: `교실의 숨은 에너지 볼!`,
    char: `여러분은 진정한 '불꽃 튀는 인간 포켓몬' 타입이에요! 어디로 튈지 모르는 '핵인싸'예요. 의욕은 '풀충전'이고 에너지는 '만렙'이에요. 감정도 있는 그대로 다 보여주는 '솔직 담백' 스타일이에요. 하고 싶은 건 다 해야 하고 목표의식도 강해요. 단, 넘치는 욕구를 다 채울 수 있는 '냉철함'이 좀 부족해요.
하고 싶은 게 많은데 결과가 약한 건 이걸 조절하는 이성이 받쳐주지 않아서예요. 의욕 없는 사람보다는 낫지만 뭐든 깊이 생각하는 자세가 필요해요. 좋은 재능 많이 가졌지만 이걸 유지할 수 있는 능력을 키워야 해요. 항상 계획적으로 생각하고 냉정하게 판단하는 습관을 들이세요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 넘치는 열정과 에너지를 믿어요. 하지만 가끔은 차분히 생각하고 계획을 세우는 시간도 가져보세요.
    '활력'과 '계획성'의 밸런스를 잘 잡으면 진짜 '학교의 슈퍼스타'가 될 수 있어요! 파이팅!`,
    school: `학교는 실력을 키우는 곳이에요.
- '수업 시간': 흥미로운 주제에 열정적으로 참여하되, 모든 과목에 균형 있게 집중해보세요.
- '동아리 활동': 다양한 동아리에 도전해보세요. 하지만 한 가지를 끝까지 해내는 연습도 필요해요.
- '학교 행사': 축제나 체육대회 기획에 참여해보세요. 아이디어를 내는 것뿐만 아니라 실행하는 것도 중요해요.
- '자기 주도 학습': 공부 계획을 세우고 꾸준히 실천해보세요. 작은 목표부터 시작해 성취감을 느껴보세요.
- '진로 탐색': 다양한 진로에 관심을 가져보되, 각각에 대해 깊이 있게 탐구해보세요.
당신이 얼마나 가치 있는 사람인지 스스로 냉정하게 분석해봐야 해요. 꿈만 크게 갖지 말고 현실적으로 이룰 수 있는 작은 것들부터 도전해보세요.`,
    human: `- 베프, 여자친구
행복과 불행 모두 경험할 수 있는 '꿀잼' 친구/여자친구에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 열정을 즐기되, 가끔은 함께 차분히 계획을 세워보세요.
- 선생님
극단적으로 일 처리할 때가 가끔 있어요. 세세한 것보다 큰 그림을 중요하게 여기는 사람이에요. 이런 선생님과 소통할 때는 열정적으로 참여하되, 과제의 세부사항도 꼼꼼히 확인해보세요.
- 선배
새로운 일에 도전 잘 하는 선배예요. 동아리나 반에서 변화와 혁신 역할 하지만, 구체적인 계획은 부족할 때가 많아요. 이런 선배와 함께 활동할 때는 그들의 아이디어를 존중하면서도, 실행 계획을 함께 세워보세요.
- 친구, 후배
세세한 것에 신경 쓰는 일을 어려워하는 타입이라 꼼꼼하게 관리해줘야 해요. 이런 친구나 후배와 함께 있을 때는 그들의 창의성을 인정해주고, 함께 계획을 세우고 실천하는 연습을 해보세요.`,
  },

  AACAC: {
    type: "AACAC",
    category: "자유분방 유형",
    desc: `'4차원 엉뚱 매력 요정' 타입`,
    sub: `교실의 숨은 반전 매력 아이돌!`,
    char: `여러분은 진정한 '4차원 엉뚱 매력 요정' 타입이에요! 어떤 일이든 '이성'보다는 '감성'이 앞서서 실수할 확률이 높은 타입이에요. '철부지 막내'라고 하면 딱 맞아요. 친구들 생각보다 자기 자신이 먼저고, 솔직한 감정 표현을 잘해요.
학교 규칙이나 인정받는 걸 엄청 중요하게 여기지만, 가끔 '뜬금없는' 행동으로 친구들을 '멘붕'에 빠뜨릴 때가 있어요. 뭘 할지 몰라서 눈을 뗄 수 없는 타입이에요. 그래도 다행인 건 정 많은 사람이라 쉽게 거절 못 하고 친구들 잘 도와줘요. 엄청 솔직한 이 타입은 남 이용하려고 '꼼수' 부리거나 일부러 '립서비스' 하지 않아요. 오히려 '뒤끝' 없이 깔끔한 사람이에요. 단지 경솔한 행동으로 주변 친구들 당황하게 할 때가 있으니 항상 마음 진정시키고 행동해야 해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 매력을 믿어요. 하지만 가끔은 신중하게 생각하고 행동하는 연습도 해보세요.
    '솔직함'과 '신중함'의 밸런스를 잘 잡으면 진짜 '학교의 히든 챔피언'이 될 수 있어요! 파이팅!`,
    school: `뭘 준비하든 결과보다 과정을 먼저 생각해야 해요.
- '수업 시간': 질문하고 싶은 게 있으면 참지 말고 물어보세요. 하지만 질문 전에 잠깐 생각하는 시간을 가져보세요.
- '동아리 활동': 흥미로운 동아리를 찾아보세요. 하지만 한 번 시작했다면 끝까지 해보는 것도 중요해요.
- '학교 행사': 독특한 아이디어로 반 장기자랑이나 축제를 빛내보세요. 하지만 실현 가능한지 꼭 확인해보세요.
- '과제': 창의적인 방식으로 과제를 해결해보세요. 하지만 선생님의 요구사항도 꼼꼼히 체크해야 해요.
- '시험 준비': 자신만의 재미있는 암기법을 만들어보세요. 그러나 기본적인 공부 방법도 함께 사용하는 게 좋아요.
가고 싶은 동아리나 그룹이 있다면 '스텝 바이 스텝'을 꼭 기억하세요. '대박'은 기회와 준비가 만날 때 온다는 걸 잊지 마세요.`,
    human: `- 베프, 여자친구
누군가를 맞추는 게 어려운 상대예요. 자유로운 친구 관계를 원해요. 구속하기보다는 '프리스타일'이 서로에게 더 좋을 것 같아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 독특한 매력을 인정해주고, 가끔은 함께 계획을 세워보세요.
- 선생님
이런 타입의 선생님이랑 공부할 때는 항상 정해진 틀을 만들거나 말로 한 것도 메모해서 꼭 실천해야 해요. 정해진 틀 없으면 공부하기 어려울 수 있어요. 이런 선생님의 수업을 들을 때는 핵심 내용을 정리하고, 질문할 내용을 미리 준비해보세요.
- 선배
공부할 때 분석적이거나 체계적인 면이 부족해서 어려울 때가 있어요. 하지만 인간적으로는 순수하고 솔직한 사람이에요. 이런 선배와 함께 활동할 때는 그들의 창의적인 아이디어를 존중하면서도, 실행 계획을 함께 세워보세요.
- 친구, 후배
공부할 때 정확한 설명과 지시가 필요해요. 처음부터 끝까지 하나하나 순서대로 가르쳐야 잘할 수 있으니 가르치는 사람 입장에서는 좀 힘들 수 있어요. 이런 친구나 후배와 함께 공부할 때는 차근차근 설명해주고, 시각적인 자료를 활용해보세요.`,
  },

  AACBA: {
    type: "AACBA",
    category: "책임감 과다 유형",
    desc: `'책임감 만렙 츤데레 덕후' 타입`,
    sub: `교실의 숨은 짱구 리더!`,
    char: `여러분은 진정한 '책임감 만렙, 츤데레 덕후' 타입이에요! 이 타입은 스스로에게 엄격하고 책임감이 아주 뛰어나요. 친구들을 배려하고 사람도 좋아하며, 옳고 그름에 대해 확고한 태도를 가지고 있지만, 그게 겉으로는 잘 드러나지 않아서 문제일 때가 있어요.
친구들이 보기엔 가끔 고집이 세고 현실을 무시하는 것처럼 보이기도 해요. 왜냐하면 친구들 일에 너무 적극적으로 나서거나, 남들의 시선을 지나치게 신경 쓸 때가 있기 때문이에요. 또, 자기 능력으로는 힘든 일도 거절하지 못하고 다 받아들이려다 보니 혼자 해결하려다가 힘들어지는 경우가 많아요. 자기 능력을 넘는 일은 가끔 친구들의 도움을 받는 것도 필요해요. 책임감이 자기 이성이나 지성보다 앞서가 현실을 무시하고 행동하게 될 수 있으니 조심하는 게 좋겠어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 책임감과 배려심을 믿어요. 하지만 가끔은 자신을 돌보는 시간도 가져보세요.
    '배려'와 '자기 관리'의 밸런스를 잘 잡으면 진짜 '학교의 숨은 영웅'이 될 수 있어요! 파이팅!`,
    school: `이런 타입은 봉사 활동이나 복지 관련 일 하면 잘할 수 있어요.
- '학생회 활동': 학급 임원이나 학생회 활동을 해보세요. 책임감을 발휘할 좋은 기회예요.
- '봉사 동아리': 교내외 봉사 활동에 참여해보세요. 여러분의 배려심이 빛을 발할 거예요.
- '멘토링 프로그램': 후배나 친구들의 학습을 도와주는 멘토 역할을 해보세요.
- '팀 프로젝트': 그룹 과제에서 리더 역할을 맡아보세요. 하지만 모든 걸 혼자 하려고 하지 말고 역할 분담을 해보세요.
- '진로 탐색': 사회복지사, 상담사 같은 직업에 관심을 가져보세요.
혹시 논리적이고 이성적 사고가 필요한 활동 선택했다면 다시 한 번 고민해봐야 할 것 같아요. 기계나 시스템 다루는 일보다는 사람 상대하는 일이 더 잘 맞을 수 있어요.`,
    human: `- 베프, 여자친구
상대 말 잘 들어주고 져주는 것도 마다하지 않아요. 가끔 계획적인 면이 부족해서 상대를 답답하게 할 순 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 배려심을 인정해주고, 가끔은 함께 계획을 세워보세요.
- 선생님
이 타입은 감정에 호소하면 거절 못 하는 타입이에요. 그렇다고 상대 좋은 점 이용하면 안 되겠죠? 이런 선생님과 소통할 때는 진심을 담아 이야기하되, 선생님의 시간과 에너지도 존중해주세요.
- 선배
한 가지를 깊게 파기보다는 여러 가지 일 벌이는 스타일이에요. 좀 따라가기 힘들 수 있지만 책임감이 누구보다 강해서 후배를 버리는 일은 없어요. 이런 선배와 함께 활동할 때는 그들의 다양한 관심사를 존중하면서도, 가끔은 하나에 집중하자고 제안해보세요.
- 친구, 후배
작은 일이나 추가 과제 때문에 시간 관리가 잘 안 될 수 있어요. 적절한 관리와 통제가 필요해요. 이런 친구나 후배와 함께 있을 때는 그들의 노력을 인정해주고, 시간 관리 스킬을 함께 배워보세요.`,
  },

  AACBB: {
    type: "AACBB",
    category: "사회질서 유지 유형",
    desc: `'이상주의 드림캐쳐 히어로' 타입`,
    sub: `교실의 숨은 정의의 용사!`,
    char: ` 여러분은 진정한 '이상주의 드림캐쳐 히어로' 타입이에요! 도덕적이고 이상과 신념이 높은 사람이에요. 친구와의 의리를 중요하게 여기고, 정이 많으며 남을 배려하는 마음도 깊어요. 인생관이 뚜렷하지만, 그에 비해 문제 해결력은 조금 부족한 편이에요.
현실적이거나 객관적이지 못해서 꿈은 크지만 실행력이 부족해 엉뚱한 방향으로 갈 때가 있어요. 친구에 대한 관심과 의리도 중요하지만, 학교에서 성공하려면 상황을 더 정확히 파악하고 문제 해결 능력을 기르는 것이 필요해요.
자신의 감정을 표현하고 겸손한 모습을 가진 당신은, 조금 더 냉정하고 계산적으로 행동할 필요가 있어요. 이성적으로 판단하는 능력을 키우도록 노력해보세요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 높은 이상과 도덕성을 믿어요. 하지만 가끔은 현실을 직시하고 실행 계획을 세우는 연습도 해보세요.
    '높은 이상'과 '현실적 실행력'의 밸런스를 잘 잡으면 진짜 '학교의 정의로운 영웅'이 될 수 있어요! 파이팅!`,
    school: `나중에 군인, 경찰, 판사, 선생님 같은 직업은 잘 어울리지만 설계사, 회계사, 비즈니스맨의 요소는 좀 부족해요.
- '학생회 활동': 정의로운 학교를 만들기 위한 활동에 참여해보세요.
- '봉사 동아리': 지역 사회를 위한 봉사 활동에 적극 참여해보세요.
- '토론 대회': 사회 문제에 대한 여러분의 이상을 표현해보세요.
- '진로 탐색': 사회 정의를 실현할 수 있는 다양한 직업을 알아보세요.
- '독서 활동': 다양한 사회 문제를 다룬 책을 읽고 토론해보세요.
혹시 논리적이고 이성적 사고가 필요한 활동이나 진로를 선택했다면 다시 한 번 고민해봐야 할 것 같아요. 기계나 시스템 다루는 일보다는 사람 상대하는 일이 더 잘 맞을 수 있어요.`,
    human: `- 베프, 여자친구
이것저것 따지지 않는 타입으로, 친구나 여자친구로 '찐'으로 좋은 타입이에요. 자기 실속 차리기보다는 남을 위한 마음이 큰 사람이에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 이상을 존중해주되, 가끔은 현실적인 조언도 해주세요.
- 선생님
믿고 갈 수 있는 '노캡' 멘토예요. 이런 선생님과 소통할 때는 여러분의 꿈과 이상을 자유롭게 이야기해보세요. 하지만 실현 가능한 계획도 함께 세워보는 것이 좋아요.
- 선배
후배를 힘들게 하거나 까칠한 선배는 절대 아니에요. 인간미 있고 친절한 선배라고 볼 수 있어요. 이런 선배와 함께 활동할 때는 그들의 조언을 귀담아 듣되, 현실적인 부분도 함께 고려해보세요.
- 친구, 후배
공부할 때 실수가 좀 있긴 하지만, 좀 더 주의를 기울이고 계획을 직접 세워주면 잘 따라올 수 있는 타입이에요. 이런 친구나 후배와 함께 있을 때는 그들의 이상을 응원해주고, 함께 실현 가능한 계획을 세워보세요.`,
  },

  AACBC: {
    type: "AACBC",
    category: "사회질서 고집 유형",
    desc: `'룰 세팅 마스터 학생회장' 타입`,
    sub: `교실의 숨은 규칙 지키미!`,
    char: `여러분은 진정한 '룰 세팅 마스터 학생회장' 타입이에요! 이 타입은 '올드스쿨' 느낌이 강해요. 학교 규칙이나 약속에 대해서는 책임감 갖고 행동해요. 주어진 과제는 목표 갖고 확실하게 하려고 하는데, 가끔 친구들이 원하는 만큼의 결과가 안 나올 때가 있어요.
이건 자기를 '절대 갓'으로 여기는 약간의 고집쟁이 같은 행동이 있어서 융통성이 부족해요. 학교생활에서 좀 더 '밸런스' 잡고 마찰 피할 수 있는 '처세술'을 익혀야 해요. 때론 누군가를 챙기고 관심 갖는 넓은 마음이 잘 안 보일 수 있으니 주의해야 해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 규칙을 지키는 모습을 믿어요. 하지만 가끔은 유연하게 생각하는 연습도 해보세요.
    '원칙'과 '유연함'의 밸런스를 잘 잡으면 진짜 '학교의 든든한 기둥'이 될 수 있어요! 파이팅!`,
    school: `자기 개성이 뚜렷한 사람이에요.
- '학생회 활동': 학급 임원이나 학생회 활동을 해보세요. 규칙을 만들고 지키는 데 탁월할 거예요.
- '동아리 활동': 체계적인 활동이 필요한 동아리를 찾아보세요. 하지만 다른 의견도 듣는 연습을 해보세요.
- '수업 태도': 규칙을 잘 지키는 모범생이 될 수 있어요. 하지만 가끔은 창의적인 아이디어도 내보세요.
- '프로젝트 학습': 계획을 세우고 실행하는 데 강해요. 하지만 팀원들의 의견도 존중해보세요.
- '진로 탐색': 법률, 행정 관련 직업에 관심을 가져보세요. 하지만 다양한 분야도 열린 마음으로 알아보세요.
가끔 보면 주변 신경 안 쓰고 '돌진'할 것 같은 사람으로 보일 수 있어요. 자기 PR할 때 최대한 겸손하고 현실적이며 합리적인 사람으로 보일 수 있는 내용들을 준비하세요. 자기 고집 내려놓고 주변 조언들에 귀 기울여야 해요.`,
    human: `- 베프, 여자친구
친구나 여자친구가 '하지마'라고 해도 자기가 꼭 해야 하는 책임 있는 일이면 하고 말아요. 상대방이 좀 답답할 수 있지만 도덕적이고 의리 있는 사람이에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 원칙을 존중해주되, 가끔은 유연하게 생각할 수 있도록 도와주세요.
- 선생님
 유연성이 좀 떨어지는 타입이에요. 이런 선생님의 수업을 들을 때는 규칙을 잘 지키되, 때로는 창의적인 아이디어를 조심스럽게 제안해보세요.
- 선배
목표는 크지만 이를 이룰 수 있는 일의 체계와 시스템은 좀 부족해요. 가끔 자기 주장을 크게 내세우는 경우가 있으니 적당히 맞춰줘야 해요. 이런 선배와 함께 활동할 때는 그들의 원칙을 존중하면서도, 다양한 방법을 함께 고민해보세요.
- 친구, 후배
공부나 활동할 때 '누가, 어디서, 언제, 무엇을, 어떻게, 왜'를 항상 생각해야 하고, 상황을 정확히 판단할 수 있도록 지성과 이성을 키워줘야 해요. 이런 친구나 후배와 함께 있을 때는 그들의 체계적인 면을 인정해주고, 때로는 유연한 사고를 할 수 있도록 도와주세요.`,
  },

  AACCA: {
    type: "AACCA",
    category: "감정 노동자 유형",
    desc: `'속앓이 인싸 겉멋쟁이' 타입`,
    sub: `교실의 숨은 배려왕!`,
    char: `여러분은 진정한 '속앓이 인싸 겉멋쟁이' 타입이에요! 이 타입은 친구들 의견을 잘 받아주고, 속으로 불만 있어도 겉으로 안 티 내요. 얼굴엔 미소 지어도 속으론 '멘붕' 오는 타입이에요. 만족 못해도 항상 주변 신경 쓰느라 남이 정한 거에 그냥 따라가는 경우 많아요.
근데 너무 친구들 위해 양보하다 스스로 위축되거나 문제해결 잘 안 될 때가 많이 생겨요. 또 자기표현 잘 못해서 오해 사는 경우도 종종 있어요. 적극적으로 행동하고 밝은 성격 되도록 노력 많이 해야 해요. 남에게 순응하는 타입으로 보이는 건 자기가 '착한 애'로 보이고 싶은 마음 커서예요. 진짜 '착한 애'로 보이고 싶다면 마음에 여유 갖고 자기 태도와 감정 솔직하게 보여줄 수 있게 노력하세요. 자기 의견 적극적으로 말할 때 분명 '길 생김'!`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 배려심을 믿어요. 하지만 가끔은 자신의 의견을 당당하게 말하는 연습도 해보세요.
    '배려'와 '자기주장'의 밸런스를 잘 잡으면 진짜 '학교의 인기스타'가 될 수 있어요! 파이팅!`,
    school: `당신은 협상하고 싶은 게 열 개면 그 중에 한두 개밖에 못 얻어요.
- '수업 시간': 발표 기회가 와도 자주 포기하지 말고 용기 내서 의견을 말해보세요.
- '동아리 활동': 리더 역할에 도전해보세요. 다른 친구들을 배려하면서도 자신의 아이디어를 제안해보세요.
- '팀 프로젝트': 의견 조율할 때 자신의 생각도 분명히 말해보세요.
- '진로 상담': 선생님께 자신의 꿈과 고민을 솔직하게 말해보세요.
- '학급 회의': 반 친구들을 위한 아이디어가 있다면 적극적으로 제안해보세요.
그래서 실제 당신 실력만큼 좋은 평가 받기 어려울 수 있어요. 좀 더 자기 이익 위해 확실한 계획이랑 설득 방법 연구해야 할 거예요.`,
    human: `- 베프, 여자친구
당신이 합리적이고 이성적인 사람이면 이 타입이랑 잘 맞을 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 마음을 잘 읽어주고, 솔직한 대화를 나누도록 격려해주세요.
- 선생님
무난한 선생님이에요. 근데 이 타입은 속마음을 겉으로 안 보이는 타입이라 많은 대화와 시간 필요해요. 이런 선생님과 소통할 때는 적극적으로 질문하고 의견을 말해보세요.
- 선배
후배에 대한 관심 많고 배려해주는 선배예요. 표현이 서툴 뿐이지 사람은 굉장히 따뜻해요. 이런 선배와 함께 활동할 때는 그들의 따뜻한 마음을 인정하면서도, 자신의 의견도 조심스럽게 말해보세요.
- 친구, 후배
가끔은 '팩트 폭격'이 약이 될 수 있는 타입이에요. 일을 좀 더 신중하고 현실적으로 처리할 수 있게 관심 보여주세요. 이런 친구나 후배와 함께 있을 때는 그들의 의견을 존중하면서도, 솔직한 감정 표현을 할 수 있도록 격려해주세요.`,
  },

  AACCB: {
    type: "AACCB",
    category: "지성과 실행력이 필요한 유형",
    desc: `'숨은 모범생 글로우업 챌린지' 타입`,
    sub: `교실의 숨은 보석!`,
    char: `여러분은 진정한 '숨은 모범생 글로우업 챌린지' 타입이에요! 어떻게 보면 이 타입은 '모범생'으로 보이고 싶어 해요. 매사에 책임감 '갓벽'하고 맡은 일은 확실히 하려고 노력하죠. 또 친구들 입장에서 생각하고 챙겨주는 것도 잘해요. '인간 보물'처럼 친구들한테 베푸는 걸 아끼지 않는 타입이에요.
하지만 문제는 세상이 '모범생'으로 안 봐줄 때가 있어요. 가장 큰 문제는 뭔가를 해결할 때 '지능'과 '적극성'이 좀 부족해요. 쉽게 말해서 계획 세워서 행동한다거나 객관적으로 일 처리하는 능력이 부족해요. 또 긍정적으로 생각하거나 적극적으로 행동하는 걸 잘 못해서 성격이 좀 '다크'해 보일 때가 있어요.
좀 더 자기 감정과 태도를 솔직하게 보여줄 수 있도록 노력해야 해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 책임감을 믿어요. 하지만 가끔은 적극적으로 행동하고 자신의 생각을 표현하는 연습도 해보세요.
    '지성'과 '적극성'을 키우면 진짜 '학교의 숨은 영웅'으로 '글로우업' 할 수 있어요! 파이팅!`,
    school: `가고 싶은 동아리나 그룹에서 추구하는 것이 뭔지 잘 알고 꼼꼼하게 준비해야 해요. 
- '수업 시간': 적극적으로 발표하고 질문하는 연습을 해보세요. 처음엔 어렵겠지만 점점 나아질 거예요.
- '동아리 활동': 관심 있는 동아리에 가입해 적극적으로 참여해보세요. 숨은 재능을 발견할 수 있어요.
- '팀 프로젝트': 계획을 세우고 실행하는 연습을 해보세요. 친구들과 협력하면 더 좋은 결과를 얻을 수 있어요.
- '봉사 활동': 여러분의 따뜻한 마음을 살려 다양한 봉사 활동에 참여해보세요.
- '진로 탐색': 자신의 장점을 살릴 수 있는 다양한 직업을 알아보세요.
면담이나 발표할 때 자기표현력이 좀 떨어져서 좋은 장점이 쉽게 안 드러난다는 게 아쉬워요.`,
    human: `- 베프, 여자친구
당신이 적극적이고 활동적인 사람이라면 가끔 답답함 느낄 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 따뜻한 마음을 인정해주고, 가끔은 새로운 활동에 함께 도전해보세요.
- 선생님
멘토로서는 무난해요. 이런 선생님과 소통할 때는 적극적으로 질문하고 의견을 말해보세요. 선생님은 여러분의 노력을 알아줄 거예요.
- 선배
목표와 이상은 크지만 그에 맞는 체계적인 일 처리와 열정은 좀 부족한 선배예요. 이런 선배와 함께 활동할 때는 그들의 아이디어를 존중하면서도, 실행 계획을 함께 세워보세요.
- 친구, 후배
큰 문제 일으키는 일은 없지만, '꿀잼'이거나 일을 깔끔하게 처리하는 능력은 좀 부족해요. 이런 친구나 후배와 함께 있을 때는 그들의 책임감을 인정해주고, 함께 재미있는 활동을 계획해보세요.`,
  },

  AACCC: {
    type: "AACCC",
    category: "엄부 자모 유형",
    desc: `'츤데레 엄친아/엄친딸 콘셉트' 타입`,
    sub: `교실의 숨은 완벽 아이돌!`,
    char: `여러분은 진정한 '츤데레 엄친아/엄친딸 콘셉트' 타입이에요! '엄친아/엄친딸' 타입은 엄격하고 책임감이 강하면서도 친구들을 잘 챙기고 베푸는 마음이 큰 사람을 의미해요. 친구들에게 늘 베풀며, 함께 있을 때 따뜻함을 전해주는 타입이죠.
하지만 학교생활의 재미를 만드는 데는 관심이 적은 편이에요. 공부할 때는 상황파악이나 환경 변화를 무시하고 행동에만 집중하는 경향이 있어요. 이럴 때는 실속을 챙기며 내실을 다지는 것이 중요해요. 상황을 잘 분석하고, 목표를 향해 좀 더 효율적으로 접근하는 방법도 고민해보세요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 책임감과 배려심을 믿어요. 하지만 가끔은 유연하게 생각하고 행동하는 연습도 해보세요.
    '책임감'과 '유연함'의 밸런스를 잘 잡으면 진짜 '학교의 완벽 아이돌'이 될 수 있어요! 파이팅!`,
    school: `당신은 선생님이랑 상담할 때 하고 싶은 말 열 개 중에 한두 개밖에 못 해요.
- '수업 시간': 적극적으로 질문하고 발표하는 연습을 해보세요. 선생님들이 여러분의 능력을 알아볼 거예요.
- '동아리 활동': 리더 역할에 도전해보세요. 책임감 있게 일을 처리하는 모습을 보여줄 수 있어요.
- '학급 임원': 반장이나 부반장에 도전해보세요. 여러분의 책임감과 배려심을 발휘할 좋은 기회예요.
- '그룹 프로젝트': 계획을 세우고 팀원들과 소통하는 연습을 해보세요. 융통성도 기를 수 있어요.
- '진로 탐색': 다양한 직업에 대해 알아보고, 자신의 장점을 살릴 수 있는 분야를 찾아보세요.
그래서 실제 당신 실력만큼 좋은 성적이나 평가를 받기 어려울 수 있어요. 좀 더 자기 이익을 위해 확실한 계획이랑 설득 방법을 연구해야 할 거예요. 예를 들어, 발표 기회나 프로젝트 리더 역할을 맡아서 자기 능력을 보여주는 것도 좋아요.`,
    human: `- 베프, 여자친구
좀 '꼰대'스러운 타입이라 가끔 부딪힐 수 있어요. 하지만 넓은 마음으로 이해해주면 조금씩 변할 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 책임감을 인정해주고, 가끔은 즉흥적인 활동도 제안해보세요.
- 선생님
이 타입이랑 공부할 때는 항상 정해진 틀을 만들거나 말로 한 것도 메모해서 꼭 실천해야 해요. 정해진 틀 없으면 공부하기 어려울 수 있어요. 이런 선생님의 수업을 들을 때는 체계적으로 노트 정리를 하고, 질문 목록을 만들어보세요.
- 선배
남 평가 확실하게 하는 선배예요. 흑백논리 확실한 사람이라 융통성이 좀 부족해요. 이런 선배와 함께 활동할 때는 그들의 원칙을 존중하면서도, 때로는 다양한 관점을 제안해보세요.
- 친구, 후배
한 번에 하나씩 주제 주고 피드백하면서 이끌어가야 해요. 처음부터 너무 많은 걸 기대하지 마세요. 이런 친구나 후배와 함께 공부할 때는 단계별로 목표를 설정하고, 작은 성취에도 격려해주세요.`,
  },

  ABAAA: {
    type: "ABAAA",
    category: "깔끔한 리더 유형",
    desc: `'깔끔 브리아니 학교의 아이돌' 타입`,
    sub: `교실의 숨은 완벽주의자!`,
    char: `여러분은 진정한 '깔끔 브리아니 학교의 아이돌' 타입이에요! 당신은 도덕적이고 규칙을 중요하게 여기는 타입으로, 공과 사를 구분해서 행동을 잘 해요.
공부할 때도 감정적이지 않고 합리적이며 현실적이어서 실수도 적은 편이에요. 쉽게 말해 모든 면에서 '깔끔' 그 자체라 주변에 친구들이 많고 항상 '리더' 같다는 말을 들어요.
목표의식이 강한 당신은 가끔 스트레스를 많이 받기도 하지만, 일상에서 '힐링' 시간을 갖고 마음을 비우면 큰 문제 없어요. 애정 표현도 풍부한 당신은 항상 친구들에게 긍정 에너지를 주기 때문에 매일매일이 새롭고 활기차요. 약간의 조바심과 열등감만 내려놓으면 돼요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 깔끔함과 리더십을 믿어요. 하지만 가끔은 완벽주의를 내려놓고 여유를 즐기는 시간도 가져보세요.
    '완벽주의'와 '여유'의 밸런스를 잘 잡으면 진짜 '학교의 완벽 아이돌'이 될 수 있어요! 파이팅!`,
    school: `아직 원하는 동아리나 그룹에 못 들어갔다면, 눈높이가 너무 높아서일 수 있어요.
- '학급 임원': 반장이나 부반장으로 시작해보세요. 리더십을 발휘할 좋은 기회예요.
- '작은 동아리': 관심 있는 작은 동아리부터 시작해보세요. 점차 더 큰 역할을 맡아갈 수 있어요.
- '학교 행사': 축제나 체육대회 기획단에 참여해보세요. 여러분의 깔끔한 일 처리 능력이 빛을 발할 거예요.
- '학습 그룹': 친구들과 스터디 그룹을 만들어보세요. 여러분의 체계적인 학습 방법을 나눌 수 있어요.
- '진로 탐색': 다양한 진로 체험 활동에 참여해보세요. 여러분의 다재다능함을 발견할 수 있을 거예요.
당신의 능력을 필요로 하는 곳은 많으니, 그런 곳에서 먼저 실력을 보여주면서 경험을 쌓는 게 좋아요.`,
    human: `- 베프, 여자친구
하고 싶은 건 상대를 설득해서라도 하고 마는 성격이에요. 또 꿈과 이상이 커서 믿고 따라가도 좋아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 열정을 존중해주고, 가끔은 함께 휴식을 취하는 것도 좋아요.
- 선생님
신뢰감 있는 선생님이에요. 이런 선생님과 소통할 때는 정직하고 성실한 모습을 보여주세요. 선생님의 조언을 귀담아 들으면 많은 도움을 받을 수 있어요.
- 선배
리드하고 조언을 잘 하지만, 잔소리보다는 애정으로 받아들이면 관계가 더 편해질 수 있어요. 이런 선배와 함께 활동할 때는 그들의 경험을 존중하면서도, 자신의 아이디어도 조심스럽게 제안해보세요.
- 친구, 후배
감정 기복이 좀 있을 수 있지만, 그래도 목표의식 갖고 열심히 공부하는 타입이라 성적도 잘 나와요. 이런 친구나 후배와 함께 있을 때는 그들의 열정을 인정해주고, 때로는 함께 휴식을 취하는 것도 좋아요.`,
  },

  ABAAB: {
    type: "ABAAB",
    category: "완벽 지향 유형",
    desc: `'목표 달성 학교생활 메인 캐릭터' 타입`,
    sub: `교실의 숨은 주인공!`,
    char: `여러분은 진정한 '목표 달성 학교생활 메인 캐릭터' 타입이에요! 이상이 높고 책임감이 강하며 현실적인 성향을 가지고 있어요. 항상 큰 목표를 가지고 있어서 작은 것에는 쉽게 도전하지 않아요. 공부할 때는 철저히 계획을 세우고 주변 상황을 잘 파악해서 실수가 적은 편이에요. 감정에 휘둘리기보다는 공평하게 보려고 해서 갈등에 휘말리는 경우도 드물어요.
다만 좋고 싫음이 확실해서 스스로 '선을 긋고' 친구를 사귀는 경우도 많아요. 조금 더 친구들에게 관심과 애정을 보여준다면 좋은 친구들이 더 많아질 수 있을 거예요. 공부와 여가 시간을 균형 있게 중요하게 생각하는 당신은 다른 사람들보다 더 즐겁고 여유로운 학교생활을 하고 있는 편이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 큰 꿈과 현실적인 접근 방식을 믿어요. 하지만 가끔은 다른 친구들의 의견도 듣고, 함께 성장하는 법을 배워보세요.
    '큰 꿈'과 '현실적 접근'의 밸런스를 잘 잡으면 진짜 '학교생활의 주인공'이 될 수 있어요! 파이팅!`,
    school: `발표나 면담 때 가장 좋은 인상 줄 수 있는 타입이에요.
- '수업 시간': 적극적으로 발표하고 질문하세요. 여러분의 자신감이 빛을 발할 거예요.
- '학생회 활동': 학생회장 선거에 도전해보세요. 리더십을 발휘할 좋은 기회예요.
- '동아리 활동': 관심 있는 동아리에 적극적으로 참여하세요. 여러분의 열정이 동아리를 빛나게 할 거예요.
- '학교 행사': 축제나 체육대회 기획단에 참여해보세요. 여러분의 아이디어로 학교를 들썩이게 만들 수 있어요.
- '진로 탐색': 다양한 진로 체험 활동에 참여해보세요. 큰 꿈을 향한 첫걸음이 될 수 있어요.
다른 친구들에게 '묻어가기'보다는 약간 튀는 편이라 첫인상에서 점수 딸 수 있어요. 다만, 너무 큰 자신감이 때론 '나만 최고'처럼 보일 수 있으니 조심하세요. 예를 들어, 학생회장 선거나 동아리 면접 때 자신의 강점을 어필하되, 겸손함도 잃지 않도록 해요.`,
    human: `- 베프, 여자친구
미래 성공에 대한 욕심 있는 사람이에요. 함께 있으면 즐거운 일들과 '꿀잼' 이벤트 많이 있을 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 목표를 응원해주고, 함께 꿈을 키워나가세요.
- 선생님
융통성 있고 '주고받기'가 통하는 상대예요. 이런 선생님과 소통할 때는 적극적으로 의견을 나누고, 선생님의 조언을 귀담아 들으세요.
- 선배
이 선배는 결정한 일은 꼭 해내는 경향이 있어요. 후배의 공을 가로채거나 비도덕적인 행동은 절대 안 해요. 이런 선배와 함께 활동할 때는 그들의 결심을 존중하고, 함께 목표를 향해 나아가세요.
- 친구, 후배
무난한 친구라고 볼 수 있어요. 이런 친구나 후배와 함께 있을 때는 그들의 장점을 인정해주고, 함께 성장할 수 있는 기회를 만들어보세요.`,
  },

  ABAAC: {
    type: "ABAAC",
    category: "자주 독립적인 유형",
    desc: `'마이웨이 여포 스웨그' 타입`,
    sub: `교실의 숨은 반항아!`,
    char: `여러분은 진정한 '마이웨이 여포 스웨그' 타입이에요! 이 타입은 새장 안에 갇힌 새처럼 되는 걸 싫어하는 타입이에요. 자기표현 욕구도 강하고 '프리함'을 원해요. 목표의식도 뚜렷하고 꿈도 커요. 가끔 남한테 고개 숙이는 걸 잘 못해서 '고집불통'이란 소리 좀 들어요.
의리가 강하고 엄청 솔직해서 돌려 말하는 경우는 거의 없어요. 상황판단도 매우 정확하고 빠른 사람이라 어디 가도 '핵인싸'가 될 수 있어요. 다만, 이 타입에겐 적당히 자신을 조절하고 친구들이랑 함께 살아갈 수 있는 마음의 여유가 필요해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독립심과 창의력을 믿어요. 하지만 가끔은 다른 사람들과 협력하는 법도 배워보세요.
    '독립심'과 '협력'의 밸런스를 잘 잡으면 진짜 '학교의 히든 보스'가 될 수 있어요! 파이팅!`,
    school: `언젠가는 남 따라가기보다 자기만의 길을 가고 싶을 때가 와요.
- '동아리 활동': 기존 동아리 대신 새로운 동아리를 만들어보는 건 어때요? 여러분의 독특한 아이디어로 학교를 깜짝 놀라게 해보세요.
- '학교 프로젝트': 자신만의 프로젝트를 시작해보세요. 예를 들어, 학교 환경 개선 프로젝트나 새로운 학교 행사를 제안해볼 수 있어요.
- '수업 시간': 창의적인 아이디어를 마음껏 표현해보세요. 하지만 다른 친구들의 의견도 존중하는 법을 배워야 해요.
- '진로 탐색': 남들이 가지 않은 길을 탐험해보세요. 독특한 직업이나 새로운 분야에 도전해보는 것도 좋아요.
- '학급 활동': 반장이나 부반장으로 활동하면서 여러분의 리더십을 발휘해보세요. 하지만 친구들의 의견도 듣는 법을 배워야 해요.
유명한 동아리도 좋지만 자기 능력을 발휘하고 인정받을 수 있는 곳이 더 좋을 거예요.`,
    human: `- 베프, 여자친구
당신이 상대 의견 잘 들어주고 적당히 자신을 낮출 줄 아는 사람이면 '찰떡궁합'이에요. 근데 고집 있는 사람이면 서로 '티키타카' 안 될 가능성 높아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 독특한 생각을 존중해주되, 가끔은 타협점을 찾아보세요.
- 선생님
철저한 현실주의자예요. 공부할 때 시작과 끝이 분명한 사람이에요. 이런 선생님과 소통할 때는 여러분의 창의적인 아이디어를 명확하게 설명하고, 실현 가능한 계획을 함께 세워보세요.
- 선배
잘 할 수 없는 일이라도 이런 선배 만났다면 무조건 "도전하겠다"고 해야 해요. 도전과 용기를 높게 평가하는 선배예요. 이런 선배와 함께 활동할 때는 여러분의 독특한 아이디어를 자신 있게 제안해보세요.
- 친구, 후배
어려운 일도 어떻게든 잘 끝내려는 의지 있는 친구예요. 다만 선배나 선생님의 이해 안 되는 행동엔 '팩트 폭격'을 날리기도 해요. 이런 친구나 후배와 함께 있을 때는 그들의 열정을 인정해주고, 함께 창의적인 해결책을 찾아보세요.`,
  },

  ABABA: {
    type: "ABABA",
    category: "완벽주의 지향 유형",
    desc: `'만능 만렙 학교생활 도전자' 타입`,
    sub: `교실의 숨은 올라운더!`,
    char: `여러분은 진정한 '만능 만렙 학교생활 도전자' 타입이에요! 의지 '갓벽'한 합리주의자로, 마음먹은 대로 행동하는 부분까지는 모든 게 깔끔한 성격이에요. 뭐든 '완벽 그 자체'로 해내겠다는 의지는 넘치지만, 성격의 한 구석에 양보와 친구들 배려하는 마음 때문에 그런 의지에 '브레이크' 걸 때가 있어요.
성공에 대한 욕심은 크지만 크고 작은 갈등이 당신을 힘들게 할 때가 많아요. 욕심을 반으로 줄이고 '공부 중독'에서 빠져나와야 해요. 학교생활 전반에 있어서 '힐링'하는 마음을 갖고 편견과 선입견을 버릴 필요가 있어요. 자신에게 엄격한 당신은 어쩌면 스스로 '족쇄' 채워서 힘들게 할 때가 있으니 주의하세요. 만능맨이 되려 하지 않아도 이미 당신은 멋지고 '찐'한 사람이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 완벽주의적 성향과 다재다능함을 믿어요. 하지만 가끔은 여유를 가지고 자신을 돌보는 시간도 가져보세요.
    '완벽주의'와 '여유'의 밸런스를 잘 잡으면 진짜 '학교의 슈퍼스타'가 될 수 있어요! 파이팅!`,
    school: `아직 원하는 동아리나 그룹에 못 들어갔다면, 눈높이가 너무 높아서일 수 있어요.
- '학급 임원': 반장이나 부반장으로 시작해보세요. 리더십을 발휘할 좋은 기회예요.
- '작은 동아리': 관심 있는 작은 동아리부터 시작해보세요. 점차 더 큰 역할을 맡아갈 수 있어요.
- '학교 행사': 축제나 체육대회 기획단에 참여해보세요. 여러분의 다재다능함이 빛을 발할 거예요.
- '학습 그룹': 친구들과 스터디 그룹을 만들어보세요. 서로 도우면서 함께 성장할 수 있어요.
- '봉사 활동': 다양한 봉사 활동에 참여해보세요. 새로운 경험을 쌓으면서 균형 잡힌 성장을 할 수 있어요.
당신의 능력을 필요로 하는 곳은 많으니, 그런 곳에서 먼저 실력을 보여주면서 경험을 쌓는 게 좋아요.`,
    human: `- 베프, 여자친구
'꿀잼' 넘치는 타입은 아니지만 상대의 세세한 것까지 신경 써주는 따뜻한 마음을 가진 사람이에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 세심함을 인정해주고, 가끔은 함께 즐거운 시간을 보내보세요.
- 선생님
'갓벽'한 멘토예요. 이런 선생님과 소통할 때는 열심히 하는 모습을 보여주되, 때로는 자신의 한계도 인정하고 도움을 요청해보세요.
- 선배
공부할 때는 '찐'으로 완벽하게 해요. 중간보고를 중요하게 생각하니 절대 말한 내용 빠뜨리는 일 없도록 잘 체크해야 해요. 이런 선배와 함께 활동할 때는 꼼꼼하게 준비하고, 정기적으로 진행 상황을 공유해보세요.
- 친구, 후배
선배나 선생님을 잘 도와주는 사람이에요. 강점을 잘 살려서 키우면 반드시 '성장 로켓' 탈 수 있어요. 이런 친구나 후배와 함께 있을 때는 그들의 노력을 인정해주고, 함께 성장할 수 있는 기회를 만들어보세요.`,
  },

  ABABB: {
    type: "ABABB",
    category: "목표 지향 유형",
    desc: `'스펙 올킬 학교생활 레전드' 타입`,
    sub: `교실의 숨은 최강자!`,
    char: `여러분은 진정한 '스펙 올킬 학교생활 레전드' 타입이에요! 매사에 책임감이 뛰어나고 맡은 일은 끝까지 최선을 다해요. 친구들의 입장에서 생각하고 잘 챙겨주며, 베푸는 걸 아끼지 않는 따뜻한 성격이에요. 하지만 '탑티어'가 되고 싶은 욕구가 강한 편이죠. 그래서 공부할 때도 자신의 지성과 이성을 최대한 활용해요.
목표 지향적이고 머리 회전이 빠르며 정확한 분석과 상황 파악을 잘해요. 자유로운 감정 표현이나 배려심이 부족할 수 있지만, 큰 문제는 되지 않아요. 언젠가는 자신이 원하는 자리에서 분명 큰 성과를 이룰 수 있는 사람이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 높은 목표의식과 실력을 믿어요. 하지만 가끔은 친구들과의 관계도 소중히 여기는 연습을 해보세요.
    '성공'과 '인간관계'의 밸런스를 잘 잡으면 진짜 '학교의 슈퍼스타'가 될 수 있어요! 파이팅!`,
    school: `큰 학교나 유명한 동아리에서 '에이스'가 되고 싶어 하는 타입이에요.
- '학생회 활동': 학생회장 선거에 도전해보세요. 여러분의 리더십을 발휘할 좋은 기회예요.
- '동아리 활동': 관심 있는 동아리의 리더 역할에 지원해보세요. 자신의 목표와 비전을 명확하게 제시하세요.
- '학업': 성적 관리에 집중하되, 다양한 대회나 경시대회에도 참가해보세요.
- '프로젝트 학습': 자신만의 프로젝트를 기획하고 실행해보세요. 학교에 새로운 아이디어를 제안할 수 있어요.
- '진로 탐색': 다양한 직업 체험 활동에 참여하고, 자신의 진로를 구체화해보세요.
아니면 언젠가는 자기만의 프로젝트를 할 수 있는 리더십이 강한 타입이니까 자기 강점을 확실하게 어필해야 면접에서 성공할 수 있어요.`,
    human: `- 베프, 여자친구
이 타입이 여학생이면 '엄친딸' 스타일이 많아요. 친구나 여자친구에 대한 관심보다 자기 성장욕구가 큰 타입이라고 보면 돼요. 이런 친구나 여자친구와 함께 있을 때는 그들의 목표를 응원해주되, 가끔은 함께 힐링하는 시간도 가져보세요.
- 선생님
함께 하면 큰 시너지 낼 수 있어요. 공부에 대한 열정이 넘쳐요. 이런 선생님과 소통할 때는 적극적으로 질문하고, 추가 학습 자료를 요청해보세요.
- 선배
목표를 못 이루면 크게 실망할 수 있는 타입이에요. 과정도 중요하지만 결과에 집착하는 선배라고 보면 돼요. 이런 선배와 함께 활동할 때는 목표를 명확히 하고, 중간 점검을 자주 해보세요.
- 친구, 후배
크게 문제 되진 않아요. 항상 주어진 일에 최선 다하고 '꿀 빠는' 스타일 아닌 사람이에요. 이런 친구나 후배와 함께 있을 때는 그들의 노력을 인정해주고, 함께 성장하는 방법을 찾아보세요.`,
  },

  ABABC: {
    type: "ABABC",
    category: "업무 몰입 유형",
    desc: `'공부 '완'차게 하는 학업 열정 루키' 타입`,
    sub: `교실의 숨은 학구왕!`,
    char: `여러분은 진정한 '공부 '완'차게 하는 학업 열정 루키' 타입이에요! '맡은 일은 끝장내야 한다'는 신념이 강한 타입이에요. 주어진 과제는 '찐'으로 하려고 노력하죠. 또 친구들 입장에서 생각하고 챙겨주는 것도 잘해요. 강한 목표 지향성, 공부에 대한 자기 스타일, 사람에 대한 자유로운 감정 등을 가지고 있어요.
근데 문제는 '공부 중독'이라는 거예요. 물론 무작정 덤비진 않아요. 공부 순서를 잘 알고 있고 꼼꼼한 분석과 정확한 대응 능력도 있어요. 공부에 있어 '올킬'을 추구하는 당신은 좀 더 자기 주장을 줄일 필요가 있어요. 자기 방식대로만 하다가 좋은 친구를 놓치는 경우가 있거든요. 좀 더 부드럽고 따뜻한 성격이 더해지면 당신의 추진력에 날개를 달 수 있어요. 꼭 기억하세요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 학업에 대한 열정과 추진력을 믿어요. 하지만 가끔은 친구들과 어울리는 시간도 가져보세요.
    '열정'과 '균형'의 밸런스를 잘 잡으면 진짜 '학교의 에이스'가 될 수 있어요! 파이팅!`,
    school: `아직 원하는 성적이나 동아리에 들어가지 못했다면, 노력이 부족하거나 너무 쉽게 생각하고 있어서일 수 있어요.
- '시험 준비': 체계적인 학습 계획을 세우고 꾸준히 실천해보세요.
- '스터디 그룹': 친구들과 함께 공부하는 그룹을 만들어보세요. 서로의 장점을 배울 수 있어요.
- '동아리 활동': 학습 관련 동아리에 가입해 보세요. 여러분의 열정을 나눌 수 있는 친구들을 만날 수 있어요.
- '선생님과의 상담': 정기적으로 선생님과 상담하며 학습 방향을 점검해보세요.
- '봉사 활동': 공부만이 아닌 다양한 경험을 쌓아보세요. 균형 잡힌 성장에 도움이 될 거예요.
인맥 좋은 당신은 주변 친구들부터 도움을 요청해 봐요. 많은 사람들에게 도움을 요청해 보세요. 분명 책임감 강하고 목표 뚜렷한 당신을 기다리는 곳이 있을 거예요.`,
    human: `- 베프, 여자친구
자기 고집이 있어서 상대를 좀 피곤하게 할 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 열정을 인정해주되, 가끔은 함께 휴식을 취하는 시간도 가져보세요.
- 선생님
까다롭게 이것저것 물어보는 타입이에요. 이 부분만 잘 맞춰지면 크게 문제 없어요. 이런 선생님과 소통할 때는 질문 전에 충분히 고민해보고, 정리된 형태로 질문을 해보세요.
- 선배
실력으로 인정받아야 해요. 능력이 갖춰질 때 믿고 더 큰일을 맡길 수 있는 타입이에요. 이런 선배와 함께 활동할 때는 자신의 능력을 꾸준히 보여주되, 겸손한 태도도 잊지 마세요.
- 친구, 후배
추진력 강한 타입이라 적절하게 일을 맡기면 바로 '완'벽한 결과 보여줄 수 있어요. 그에 따른 응원과 격려는 꼭 필요해요. 이런 친구나 후배와 함께 있을 때는 그들의 노력을 인정해주고, 함께 성장하는 방법을 찾아보세요.`,
  },

  ABACA: {
    type: "ABACA",
    category: "현실 지향 유형",
    desc: `'스텔스 모드 배려왕 꿀벌' 타입`,
    sub: `교실의 숨은 공헌자!`,
    char: `여러분은 진정한 '스텔스 모드 배려왕 꿀벌' 타입이에요! 이 타입은 양심적이고, 사명감과 정의감이 뛰어난 사람이에요. 이성적이고 객관적이며 현실적인 성향이 강해, 주위 사람들에게는 늘 차분하고 사무적인 사람으로 보여요.
문제는 정신적으로 위축되어 하고 싶은 걸 잘 못하거나, 에너지가 부족해 보일 때가 있다는 거예요. 자기 표현을 잘 하지 않고 감정을 드러내지 않다 보니, 다른 사람들 눈에는 지루해 보일 수 있어요. 하지만 친구들을 잘 배려하고 맞춰가는 능력이 뛰어나죠. 그래서 쉽게 지치고, 친구들 눈치나 말에 지나치게 신경 쓰면서 걱정이나 불안감이 커질 수 있어요.
무엇보다도 자기만의 '즐거움'을 찾고, 취미 생활을 늘려가는 게 중요해요. 그렇게 하면 더 균형 잡힌 삶을 살 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 배려심과 성실함을 믿어요. 하지만 가끔은 자신의 생각을 표현하는 연습도 해보세요.
    '배려심'과 '자기표현'의 밸런스를 잘 잡으면 진짜 '학교의 숨은 영웅'이 될 수 있어요! 파이팅!`,
    school: `빈틈없고 정확한 자기 성격을 선생님이나 면접관에게 자신감 갖고 충분히 보여주세요.
- '수업 시간': 조용히 집중하는 건 좋지만, 가끔은 질문도 해보세요.
- '동아리 활동': 소규모 동아리에서 시작해 점차 활동 범위를 넓혀보세요.
- '봉사 활동': 여러분의 배려심을 발휘할 수 있는 좋은 기회예요.
- '그룹 프로젝트': 팀원들을 도와주면서 자신의 역할도 확실히 해내세요.
- '학급 임원': 반장이나 부반장은 아니더라도 학급 일을 돕는 역할을 맡아보세요.
열정이 부족할 수 있으니 이 부분을 다른 방법으로 채워서 '어필'해야 해요. 예를 들어, 조용하지만 꾸준히 노력하는 모습을 보여주거나, 맡은 일을 책임감 있게 완수하는 사례를 강조할 수 있어요.`,
    human: `- 베프, 여자친구
성실하고 근면한 사람이지만 일상생활에서의 '꿀잼'은 좀 부족해요. 이런 친구나 여자친구와 함께 있을 때는 그들의 배려심을 인정해주고, 가끔은 새로운 활동을 제안해보세요.
- 선생님
공부할 때는 수업 내용에만 집중하는 게 좋아요. 인간적으로 친해지기엔 시간이 좀 걸려요. 이런 선생님과 소통할 때는 정확하고 성실한 모습을 보여주되, 가끔은 자신의 생각도 표현해보세요.
- 선배
이런 타입의 선배는 후배들이랑 소통을 많이 하진 않지만 공부나 활동에서는 배울 게 많은 타입이에요. 이런 선배와 함께 활동할 때는 그들의 행동을 관찰하고 배우되, 때로는 먼저 다가가 대화를 시도해보세요.
- 친구, 후배
너무 압박 주기보다는 적당한 관심과 애정이 필요한 사람이에요. 이런 친구나 후배와 함께 있을 때는 그들의 노력을 인정해주고, 편안한 분위기를 만들어주세요.`,
  },

  ABACB: {
    type: "ABACB",
    category: "업무 편중 유형",
    desc: `'차갑네 핫하네 반전 매력 학습덕후' 타입`,
    sub: `교실의 숨은 아이스핫팩!`,
    char: `여러분은 진정한 '차갑네 핫하네 반전 매력 학습덕후' 타입이에요! 인생에서 놀이와 취미보다 공부가 먼저인 사람이에요. 공부할 때는 함께하면 좋은 파트너가 될 수 있지만, 인간적으로는 친해지기 어려운 타입이에요.
이 타입에게 가장 필요한 건 '상큼 발랄'한 생활감이에요. 적당히 자기 감정 표현하면서 친구들이랑 관계 잘 가져가야 하는데, 이게 이 타입에겐 좀 어려워요. 정신적으로 위축돼 있어서 관계보다는 공부에만 올인하는 타입이에요. 공부 결과를 더 크게 내려면 이미 있는 훌륭한 '지능'에 '감성'이 더해져야 한다는 걸 잊지 마세요. 신중하고 매사 이성적이며 깔끔한 당신에게 가장 필요한 건 바로 따뜻한 감성이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 지적 능력을 믿어요. 하지만 가끔은 감성적인 면도 키워보세요.
    '냉철함'과 '따뜻함'의 밸런스를 잘 잡으면 진짜 '학교의 히든 챔피언'이 될 수 있어요! 파이팅!`,
    school: `자기 고집 내려놓고 주변 조언들에 귀 기울여야 해요.
- '수업 시간': 논리적인 과목에서 실력을 보여주되, 예체능 수업에도 적극 참여해보세요.
- '동아리 활동': 컴퓨터 프로그래밍이나 과학 동아리에서 활약해보세요. 하지만 문학이나 예술 동아리도 도전해보는 건 어떨까요?
- '학급 활동': 회계나 서기 같은 정확성이 필요한 역할을 맡아보세요.
- '교내 대회': 수학 경시대회뿐만 아니라 글짓기 대회에도 참가해보세요. 논리적인 글쓰기로 승부해보세요.
- '멘토링': 친구들에게 공부를 가르쳐주면서 소통 능력도 키워보세요.
성적 잘 받는 친구들 얘기 많이 듣고, 빈틈없고 정확한 자기 성격을 선생님이나 친구들에게 반만 보여주세요. 그리고 열정이 부족할 수 있으니 이 부분을 다른 방법으로 채워서 어필해야 해요.`,
    human: `- 베프, 여자친구
무뚝뚝한 게 이 타입의 '반전 매력'이에요. 이런 친구와 함께 있을 때는 그들의 지적인 면을 존중해주되, 가끔은 감성적인 활동을 함께 해보세요.
- 선생님
공부할 때는 아주 냉정하고 현실적인 사람이에요. 이런 선생님과 소통할 때는 논리적으로 대화하되, 가끔은 개인적인 이야기도 나눠보세요.
- 선배
공부 실력과 인간미 중에 하나 고르자면 공부 실력이 뛰어난 선배예요. 후배 마음 사는 건 좀 부족해요. 이런 선배와 함께 활동할 때는 그들의 지식을 배우되, 인간적인 교류도 시도해보세요.
- 친구, 후배
맡은 일은 철저하게 하는 타입으로, 하나의 일을 주면 그 일만 '찐'하게 하는 타입이에요. 이런 친구나 후배와 함께 있을 때는 그들의 성실함을 인정해주고, 함께 균형 잡힌 학교생활을 해나가세요.`,
  },

  ABACC: {
    type: "ABACC",
    category: "올곧은 나무 유형",
    desc: `'꼿꼿한 대나무 학교생활 모범생' 타입`,
    sub: `교실의 숨은 규칙 지킴이!`,
    char: `여러분은 진정한 '꼿꼿한 대나무 학교생활 모범생' 타입이에요! 이 타입은 마치 대나무처럼 양심적이고 도덕적이며 책임감이 뛰어나, 항상 신뢰할 수 있는 인상을 줘요. 규칙을 엄격히 지키고 자신만의 원칙이 확실한 '진짜' 사람이에요.
하지만 조금 재미가 부족한 면도 있어요. 감정을 억누르고, 즐거움을 잘 느끼지 못하는 경향이 있죠. '힙한' 친구들이나 눈에 띄는 것을 좋아하지 않다 보니, 주변에서는 학교생활을 너무 무미건조하게 하는 것처럼 보일 수 있어요. 취미나 여가에 시간을 잘 쓰지 않아서 친구가 많지 않을 때도 있죠.
좀 더 자기 존재감을 드러내고, 자기만의 색깔을 표현하는 게 필요해요. 꼿꼿한 대나무에도 많은 열매가 열릴 수 있도록, 고집을 조금 내려놓고 융통성을 발휘해보는 것도 좋을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 성실함과 책임감을 믿어요. 하지만 가끔은 유연하게 생각하고 행동하는 연습도 해보세요.
    '원칙'과 '유연함'의 밸런스를 잘 잡으면 진짜 '학교의 든든한 기둥'이 될 수 있어요! 파이팅!`,
    school: `자기 고집 내려놓고 주변 조언들에 귀 기울여야 해요.
- '학급 임원': 학급 회장이나 부회장으로 나서보세요. 규칙을 잘 지키면서도 친구들의 의견을 수렴하는 모습을 보여주세요.
- '동아리 활동': 도서부나 학생회 같은 규율이 필요한 동아리에서 활약해보세요. 하지만 가끔은 예술 동아리도 체험해보세요.
- '학교 행사': 축제나 체육대회에서 정확하고 믿음직한 역할을 맡아 완벽하게 수행해보세요.
- '학업': 수학이나 과학 같은 논리적인 과목에서 뛰어난 성적을 보여주세요.
- '창의적 활동': 글짓기 대회나 미술 시간에도 도전해보세요. 자기만의 스타일을 찾아보는 거예요.
성적 잘 받는 친구들 얘기 많이 듣고, 빈틈없고 정확한 자기 성격을 선생님이나 친구들에게 반만 보여주세요. 그리고 열정이 부족할 수 있으니 이 부분을 다른 방법으로 채워서 어필해야 해요.`,
    human: `- 베프, 여자친구
이런 타입에게 '꿀잼'을 기대하기보다는 함께 취미생활 가지면서 많은 대화와 공감대 만드는 게 우선이에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 성실함을 인정해주고, 가끔은 새로운 활동을 제안해보세요.
- 선생님
공부 정확하게 분석하고, 상황 변화 가능성 잘 예측하지만 유연성이 떨어져요. 이런 선생님과 소통할 때는 규칙을 잘 지키되, 가끔은 창의적인 아이디어를 조심스럽게 제안해보세요.
- 선배
농담이나 칭찬은 잘 안 하지만 공부 열심히 하는 성실한 선배예요. 이런 선배와 함께 활동할 때는 그들의 성실함을 배우되, 때로는 유연한 사고방식도 제안해보세요.
- 친구, 후배
자발적으로 나서서 뭘 하진 않지만 책임감은 누구보다 강한 사람이에요. 이런 친구나 후배와 함께 있을 때는 그들의 책임감을 인정해주고, 함께 즐거운 추억을 만들어보세요.`,
  },

  ABBAA: {
    type: "ABBAA",
    category: "권력과 본능 양립 유형",
    desc: `'욕망의 불꽃 학교생활 만렙러' 타입`,
    sub: `교실의 숨은 열정 챔피언!`,
    char: `여러분은 진정한 '욕망의 불꽃 학교생활 만렙러' 타입이에요! '완벽한 삶'에 대한 욕구가 강한 편이에요. 목표와 이상, 사명감이 뚜렷하고, 학교생활의 규칙, 도덕, 가치관을 중요하게 생각해요. 감정을 솔직하게 표현하며, 직감이 발달하고 상상과 공상을 즐겨요. 놀이와 여가 생활을 중요하게 여겨 에너지를 활발하게 발산하는 타입이에요.
문제는 상황이나 일에 대해 비판적으로 바라볼 때가 있다는 점이에요. 아마도 자신이 정해 놓은 기준에 따라 평가하기 때문일 가능성이 커요. 친구들에게 인정받고 칭찬받는 것을 좋아하는 것처럼, 친구들도 마찬가지로 그런 욕구를 가지고 있다는 점을 인정하고, 좀 더 이해해주고 감싸주는 태도가 필요해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 열정과 창의력을 믿어요. 하지만 가끔은 다른 사람의 입장도 생각해보는 연습을 해보세요.
    '욕구'와 '배려'의 밸런스를 잘 잡으면 진짜 '학교의 슈퍼스타'가 될 수 있어요! 파이팅!`,
    school: `욕심이 많아서 자기가 원하는 건 꼭 이뤄야 '풀린다' 싶은 타입이라고 할 수 있어요.
- '학생회 활동': 새로운 행사를 기획해보세요. 여러분의 창의력이 빛을 발할 거예요.
- '축제 기획': 독특한 부스를 운영해보세요. 모두가 놀랄 만한 아이디어를 내보는 거예요.
- '동아리 활동': 학교 신문부나 방송부에서 창의적인 콘텐츠를 만들어보세요.
- '학업': 자기만의 독특한 공부법을 개발해보세요. 암기과목은 재미있는 스토리를 만들어 외워보는 건 어떨까요?
- '대회 참가': 토론대회, 과학경진대회, 글짓기 대회 등에 나가 여러분의 능력을 보여주세요.
당신의 끼와 재능을 발휘할 수 있는 활동을 고르는 게 좋아요. 그리고 창의력이 있어서 기획이나 개발, 광고, 홍보 관련 활동도 잘 맞아요.`,
    human: `- 베프, 여자친구
절친이나 여자친구로 문제 없어요. 당신을 즐겁게 해주는 '꿀잼' 이벤트도 있고 인생의 꿈이 큰 사람이라 믿고 따라가도 좋아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 열정을 존중해주고, 함께 새로운 도전을 해보세요.
- 선생님
열정이 많아 여러 가지를 요구해요. 신뢰관계는 높은 편이에요. 이런 선생님과 소통할 때는 적극적으로 의견을 내되, 때로는 선생님의 조언도 귀담아 들어보세요.
- 선배
어느 정도 리더십 갖춘 타입이에요. '찍힐' 일 없게 평소 행동 조심할 필요가 있어요. 이런 선배와 함께 활동할 때는 그들의 리더십을 인정하면서도, 자신의 아이디어도 조심스럽게 제안해보세요.
- 친구, 후배
가끔 자기주장이 강해서 적절하게 '토스' 해줘야 해요. 하지만 믿을 수 있는 친구로서 잠재능력 키워주면 절대 배신하거나 이용하지 않아요. 이런 친구나 후배와 함께 있을 때는 그들의 열정을 인정해주고, 함께 성장할 수 있는 방법을 찾아보세요.`,
  },

  ABBAB: {
    type: "ABBAB",
    category: "보스가 되고 싶은 유형",
    desc: `'학교생활 주연 꿈꾸는 엑스트라' 타입`,
    sub: `교실의 숨은 스포트라이트 탐색기!`,
    char: `여러분은 진정한 '학교생활 주연 꿈꾸는 엑스트라' 타입이에요! 에너지 '만렙'인 당신은 어디 가든 눈에 '팡팡' 띄어요. 새장에 가둬도 '프리덤'을 추구해서 세상 밖으로 나오려고 해요. 그만큼 활발하고 밝은 성격이고 친구 관계를 중요하게 여겨요. 근데 이 학교에서 '주연'이 되고 싶어 하는 욕심은 누가 봐도 '티 나요'.
당신의 말과 행동이 너무 솔직해서 가끔은 '내 맘대로'처럼 보이거나 비판적으로 느껴질 때가 있어요. 그래도 늘 적극적이고 상상하는 걸 좋아하는 당신은 '직감 만렙'이라 남들이 못 보는 걸 볼 수 있는 '안목'이 있어요. 그래서 조금만 노력해도 다른 친구들보다 '대박' 결과를 낼 수 있죠.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 열정과 적극성을 믿어요. 하지만 가끔은 차분히 계획을 세우고 실력을 쌓는 시간도 가져보세요.
    '열정'과 '실력'의 밸런스를 잘 잡으면 진짜 '학교의 주인공'이 될 수 있어요! 파이팅!`,
    school: `당신은 첫인상에서도 열정과 적극성을 보여줄 수 있어서 다른 친구들보다 발표나 면담에서 좋은 점수 받을 수 있어요.
- '학생회 활동': 학생회장 선거에 도전해보세요. 하지만 인기만으로는 부족해요. 학교를 위한 구체적인 계획을 세워보세요.
- '동아리 활동': 여러 동아리에 가입하기보다는, 한두 개에서 리더 역할을 맡아보세요.
- '수업 참여': 적극적으로 발표하고 질문하세요. 하지만 그 전에 충분한 예습과 복습은 필수예요!
- '학교 행사': 축제나 체육대회에서 주도적인 역할을 맡아보세요. 하지만 팀워크도 잊지 마세요.
- '대회 참가': 교내외 대회에 참가할 때는 수상을 목표로 철저히 준비해보세요.
문제는 당신이 가고 싶은 곳에 맞는 '스펙'을 갖췄느냐는 거예요. 욕심이 큰 만큼 그에 맞는 체계적인 준비가 함께 따라야 해요.`,
    human: `- 베프, 여자친구
듣기보다는 자기 얘기 펼치는 타입이에요. 이런 타입이랑 잘 지내려면 한 발짝 물러서고 한쪽 눈 '윙크'하면 별 문제 없을 거예요. 이런 친구나 여자친구와 함께 있을 때는 그들의 이야기를 들어주되, 가끔은 당신의 생각도 공유해보세요.
- 선생님
감정 기복 있는 사람이에요. 질문하거나 상담할 때는 선생님 기분과 상황 잘 파악해야 해요. 이런 선생님과 소통할 때는 선생님의 기분을 잘 읽고, 적절한 타이밍에 대화를 시도해보세요.
- 선배
목표의식 뚜렷한 사람이면서 친목도 중요하게 여기는 사람이에요. 공부할 때는 열심히, 놀 때는 '핵인싸'처럼 놀아야 별 탈 없어요. 이런 선배와 함께 활동할 때는 그들의 목표를 존중하면서도, 함께 즐거운 시간도 가져보세요.
- 친구, 후배
좀 '날라리'처럼 보일 수 있지만 칭찬과 응원으로 동기부여 해주면 누구보다 열심히 할 수 있는 타입이에요. 이런 친구나 후배와 함께 있을 때는 그들의 장점을 인정해주고, 함께 성장할 수 있는 기회를 만들어보세요.`,
  },

  ABBAC: {
    type: "ABBAC",
    category: "독불장군 유형",
    desc: `'4차원 반골 천재 학교생활 개척자' 타입`,
    sub: `교실의 숨은 미래 혁신가!`,
    char: `여러분은 진정한 '4차원 반골 천재 학교생활 개척자' 타입이에요! 에너지 '풀충전'인 이 타입을 따라갈 수 있는 친구들 많지 않아요. 활기 '만렙'이고 자기표현력 있어서 할 말은 꼭 하는 스타일이에요. 잘못 보면 '내 맘대로'인 것처럼 느껴질 때가 있어요. 왜냐면 남의 말에 무조건 따르기보다는 자기 주장 내세우는 걸 잘하거든요.
자존심 높으면서 '순발력 갓'이고 창의력도 있어서 경쟁에서 쉽게 안 져요. 그래서 평범한 학생보다는 승부욕 발휘할 수 있는 분야에 딱이에요. 의리 있어서 '내 사람'이라고 생각하면 무슨 일 있어도 끝까지 함께 가는 경우 많아요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 창의성과 열정을 믿어요. 하지만 가끔은 다른 사람들과 협력하는 연습도 해보세요.
    '창의성'과 '협력'의 밸런스를 잘 잡으면 진짜 '학교의 혁신가'가 될 수 있어요! 파이팅!`,
    school: `당신의 적극성, 민첩성, 책임감 등을 장점으로 내세우면 좋아요.
- '학생회 활동': 새로운 아이디어로 학교 행사를 기획해보세요. 여러분의 창의력이 빛을 발할 거예요.
- '과학 경진대회': 독특한 실험을 제안해보세요. 남들과 다른 시각으로 문제를 해결해보는 거예요.
- '학교 신문부': 평범하지 않은 기사를 써보세요. 여러분의 독특한 시각이 학교를 변화시킬 수 있어요.
- '연극부': 창의적인 각본을 써보세요. 여러분의 상상력이 무대 위에서 펼쳐질 거예요.
- '체육 활동': 승부욕과 순발력을 발휘해보세요. 학교 대표로 각종 대회에 나가 메달을 따오는 것도 좋아요.
평범한 공부보다는 토론, 발표, 프로젝트 같이 경쟁할 수 있는 활동이나 창의력 필요한 기획, 홍보, 연구 활동이 잘 맞아요.`,
    human: `- 베프, 여자친구
이 타입은 자기가 원하는 방향으로 꼭 가요. 절대 이길 수 있는 타입 아니니까 맞춰주는 게 서로에게 '윈윈'일 것 같아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 독특한 아이디어를 존중해주되, 가끔은 현실적인 조언도 해주세요.
- 선생님
'갑을 관계'에서 '갑'일 때가 많아요. '을'이라고 해도 절대 '을'처럼 행동하는 타입은 아니에요. 이런 선생님과 소통할 때는 존중을 표하되, 여러분의 의견도 조심스럽게 제안해보세요.
- 선배
'카리스마 甲' 리더에 가까워요. 후배 배려하거나 이해하는 부분은 좀 부족해요. 이런 선배와 함께 활동할 때는 그들의 리더십을 인정하면서도, 때로는 새로운 아이디어를 제안해보세요.
- 친구, 후배
잘 관리하면 이 타입은 혼자서 열 명 몫도 해낼 수 있어요. 이들은 '인정'받으면 더 동기부여 돼요. 이런 친구나 후배와 함께 있을 때는 그들의 능력을 인정해주고, 함께 새로운 도전을 해보세요.`,
  },

  ABBBA: {
    type: "ABBBA",
    category: "권위와 배려 양립 유형",
    desc: `'카리스마 숨긴 츤데레 학급장' 타입`,
    sub: `교실의 숨은 리더십 보석!`,
    char: `여러분은 진정한 '카리스마 숨긴 츤데레 학급장' 타입이에요! 이 타입은 '반전 매력'의 소유자예요. 쉽게 말해 '찐 카리스마'이면서도 친구 배려 '만렙'이에요. 그래서 주변 친구들이 볼 때는 진짜 매력적인 성격이에요. 하고 싶은 말 다 하는 편이지만 주변에서는 잘 모르고 순하다고 볼 때도 많아요.
한편으로는 친구들의 단점을 잘 찾아내 조언해주는 면도 있지만, 약간의 소심한 부분이 이 타입의 발목을 잡을 때도 많아요. 예를 들어 말하고 나서 '앗 망함'하거나 '이렇게 말하면 쟤가 상처받겠지?' 같은 고민을 해요. 지나치게 친구 배려하면서도 잘못된 건 고쳐주고 싶은 당신의 '도덕 지수'가 상대한테 긍정적으로 보일 수 있어요. 학교에서 전교 회장보다는 학급 반장으로서 역할을 잘할 수 있는 타입이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 숨겨진 카리스마와 배려심을 믿어요. 하지만 가끔은 자신의 생각을 더 당당하게 표현하는 연습도 해보세요.
    '리더십'과 '배려심'의 밸런스를 잘 잡으면 진짜 '학교의 숨은 리더'가 될 수 있어요! 파이팅!`,
    school: `노력한 만큼 성적이 안 나오는 건 아직 기회가 안 왔다는 거예요.
- '학급 토론': 자신의 의견을 명확하게 제시하되, 친구들의 의견도 존중하는 모습을 보여주세요.
- '그룹 프로젝트': 리더십을 발휘하되, 팀원들의 의견을 잘 수렴하는 모습을 보여주세요.
- '학교 행사 기획': 창의적인 아이디어를 제안하면서도, 실현 가능성을 꼼꼼히 따져보세요.
- '학업 공유': 자신의 공부 방법을 친구들과 공유하면서, 함께 성장하는 모습을 보여주세요.
- '동아리 활동': 자신의 관심사를 살려 동아리에서 적극적으로 활동하되, 다른 멤버들과 협력하는 모습을 보여주세요.
만약 발표나 면접 기회가 있다면 작은 장점까지 자세히 어필해야 해요. 이미 당신은 '인재'의 조건을 갖고 있으니 좀 당당하게 자랑할 줄 알아야 해요. 겸손만으로는 성공할 수 없어요.`,
    human: `- 베프, 여자친구
친구나 여자친구보다 부모로서 더 좋은 타입이에요. 하지만 별 문제 없는 성격이라 'drama' 일으키는 일은 잘 안 해요. 이런 친구나 여자친구와 함께 있을 때는 그들의 배려심을 인정해주고, 가끔은 솔직한 감정 표현을 격려해주세요.
- 선생님
비교적 '평범한 학생'으로 관계 맺기 어렵지 않은 타입이에요. 이런 선생님과 소통할 때는 존중을 표하면서도, 자신의 의견을 조심스럽게 제안해보세요.
- 선배
가끔 어떤 성격인지 '미스터리'할 때 있지만 절대 남 이용하거나 실력 없는 선배는 아니에요. 이런 선배와 함께 활동할 때는 그들의 능력을 인정하면서도, 때로는 솔직한 의견 교환을 시도해보세요.
- 친구, 후배
공부는 성실하게 잘 하지만 '꿀잼' 측면은 좀 부족해요. 좀 더 재미있게 공부할 수 있는 방법 제안해주면 '찐' 실력 발휘할 수 있어요. 이런 친구나 후배와 함께 있을 때는 그들의 성실함을 인정해주고, 함께 즐거운 학교생활을 만들어가세요.`,
  },

  ABBBB: {
    type: "ABBBB",
    category: "책임감 만땅 유형",
    desc: `'책임감 만렙 학교생활 정의의 용사' 타입`,
    sub: `교실의 숨은 히어로!`,
    char: `여러분은 진정한 '책임감 만렙 학교생활 정의의 용사' 타입이에요! 이 타입은 공부할 때 꼭 성적이 오르지 않아도 친구가 부탁한 일이라면 책임감에 불타서 자기 일처럼 열심히 하는 타입이에요. 엄청 도덕적이고 정의감 '풀충전'이며 가치관이 확실해요.
근데 그 힘 뒤에는 적당한 '츤데레'도 있어요. 마음에 안 들면 '팩트 폭격'을 그대로 하는 스타일이에요. 그래도 속으론 친구들을 생각하는 마음이 크고 눈치도 빠르고 센스도 있어서 큰 'drama'는 안 만들어요. 주변 친구들을 좀 더 높여주고 감성으로 다가가면 당신의 '찐 매력'은 꼭 빛을 볼 수 있어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 책임감과 정의감을 믿어요. 하지만 가끔은 유연하게 생각하고 행동하는 연습도 해보세요.
    '정의감'과 '유연성'의 밸런스를 잘 잡으면 진짜 '학교의 히어로'가 될 수 있어요! 파이팅!`,
    school: `당신 안에는 엄청난 재능이 많이 있어요.
- '학급 임원': 반장이나 부반장으로 나서보세요. 책임감과 정의감을 발휘할 좋은 기회예요.
- '동아리 활동': 리더 역할을 맡아 공정하고 효율적으로 일을 처리해보세요.
- '수업 참여': 적극적으로 발표하고 질문하세요. 솔선수범하는 모습을 보여줄 수 있어요.
- '그룹 프로젝트': 팀원들의 의견을 공정하게 듣고, 최선의 아이디어를 선택하세요.
- '학교 규칙': 규칙을 지키는 데 앞장서고, 부당한 상황에 대해 용기 있게 의견을 내세요.
- '봉사활동': 적극 참여하여 사회적 책임감을 실천하세요.
그중에서 가장 값진 걸 꺼내서 선생님이나 친구들한테 보여주세요. 당신의 최고 장점은 책임감, 솔선수범, 정의감, 뚜렷한 소신 등이에요. 위축되지 말고 더 멋지게 자신을 '어필'하세요.`,
    human: `- 베프, 여자친구
잔소리 좀 있긴 하지만 적당히 피해 갈 수 있는 '평타' 타입이에요. 이런 친구나 여자친구 함께 있을 때는 그들의 정의감을 인정해주되, 가끔은 유연한 사고도 필요하다고 조심스럽게 제안해보세요.
- 선생님
요구사항은 많은 타입이지만 한번 관계 맺고 나면 쉽게 선생님 마음 안 바꾸는 타입이에요. 이런 선생님과 소통할 때는 성실하게 임하되, 때로는 창의적인 아이디어도 제안해보세요.
- 선배
위아래가 분명한 사람이고 '매너' 중요시해요. 특히 공과 사 확실히 구분해서 지내면 별 탈 없이 잘 지낼 수 있어요. 이런 선배와 함께 활동할 때는 예의를 지키면서도, 자신의 의견도 조심스럽게 표현해보세요.
- 친구, 후배
한번 하겠다고 한 일은 꼭 해야 '찐 만족'하는 타입이에요. 적당히 일 맡겨주면 빠르게 성장할 수 있는 사람이에요. 이런 친구나 후배와 함께 있을 때는 그들의 책임감을 인정해주고, 함께 성장할 수 있는 기회를 만들어보세요.`,
  },

  ABBBC: {
    type: "ABBBC",
    category: "자기신념 고집 유형",
    desc: `'마이웨이 학교생활 주인공' 타입`,
    sub: `교실의 숨은 솔로 플레이어!`,
    char: `여러분은 진정한 '마이웨이 학교생활 주인공' 타입이에요! 세상에는 정말 다양한 사람이 있지만, 이 유형은 자신이 정한 '룰'에 다른 친구들이 맞춰주길 원하는 타입이에요. 그렇지 않으면 쉽게 스트레스를 받게 되죠. 친구들을 배려하긴 하지만 결국 자기 생각대로 마무리되는 경우가 많아요.
그렇다고 부족한 건 아니에요. 어떤 상황에서도 합리적이고 효율적인 결과를 얻기 위해 객관적으로 접근하려고 노력하는 타입이에요. 하지만 특유의 츤데레 성향과 반항적인 면 때문에 가끔 과하게 나설 때도 있어요. 좀 더 열린 마음으로 다른 의견도 받아들이고, 서로 이해하려는 '힐링'의 마음을 가져보면 좋겠어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독창성과 추진력을 믿어요. 하지만 가끔은 다른 사람들과 협력하는 연습도 해보세요.
    '독창성'과 '협력'의 밸런스를 잘 잡으면 진짜 '학교의 혁신가'가 될 수 있어요! 파이팅!`,
    school: `고집 있는 당신은 아니라고 생각되는 것도 꼭 밀어붙여요.
- '발표 수업': 자신만의 독특한 관점으로 주제를 해석해보세요.
- '개인 프로젝트': 과학 실험이나 미술 작품 제작에서 자신만의 방식을 보여주세요.
- '토론 수업': 자신의 의견을 논리적으로 펼치되, 다른 사람의 의견도 경청하세요.
- '학생회/동아리': 새로운 아이디어를 제안하고 실행해보세요.
- '자율 학습': 자신만의 독특한 학습 방법을 개발해보세요.
만약 여러 번 성적이 안 오르거나 원하는 결과를 못 얻고 있다면 다른 방법이나 다른 과목에도 관심 가져보는 게 필요해요. 특히, 팀 프로젝트보다 개인 과제가 더 잘 맞을 거예요.`,
    human: `- 베프, 여자친구
이 타입은 언제 어떻게 '급발진'할지 모르는 타입이에요. 그래서 항상 '아슬아슬'할 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 독특한 관점을 존중해주되, 가끔은 타협점을 찾아보세요.
- 선생님
'갑을 관계'에서 '갑'이 되고 싶어 하는 타입이에요. 적당한 양보와 타협이 필요해요. 이런 선생님과 소통할 때는 예의를 지키면서도, 자신의 의견을 조심스럽게 제안해보세요.
- 선배
선배가 이 타입이면 시키는 일은 절대 따르는 게 좋아요. 그리고 친해진 후에 그동안 있었던 불만사항을 차근차근 말하는 게 상책이에요. '도전'하면 큰 '위기'올 수 있어요.
- 친구, 후배
절대 말 잘 듣는 친구는 아니에요. 하지만 그 친구 주장에도 분명 '꿀팁'은 있을 수 있으니 어느 정도 존중하면서 의견 조율하세요.`,
  },

  ABBCA: {
    type: "ABBCA",
    category: "나보다 상대방 중심 유형",
    desc: `'인내의 숨은 학교생활 주인공' 타입`,
    sub: `교실의 숨은 천사 챔피언!`,
    char: `여러분은 진정한 '인내의 숨은 학교생활 주인공' 타입이에요! 책임감이 뛰어나고 맡은 일은 끝까지 최선을 다하려고 해요. 또 친구들의 입장에서 생각하고 잘 챙기며, 베푸는 것도 아끼지 않는 따뜻한 사람이에요. 하지만 이런 '천사' 같은 면모를 누군가가 이용하려고 할 수도 있어요.
당신의 가장 큰 단점은 정신적으로 너무 위축되어 하고 싶은 것도 잘 못하고, 말하고 싶은 것도 잘 못한다는 점이에요. 자기 자신을 스스로 '가둬두고' 있어서 고민이나 조바심이 많고, 눈치를 보며 공부하는 경우가 많아요. 아무리 공부를 잘해도 가끔은 좀 여유를 가지고 적당히 스트레스를 날려보는 '꼼수'도 필요해요. 인내심으로 버티기보다는, 스스로에게 값진 선물을 주며 자신을 '주인공'으로 만들어야 해요. 남을 위한 '봉사'도 중요하지만, 그보다 더 필요한 건 자신을 위한 '셀프케어'예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 인내심과 책임감을 믿어요. 하지만 가끔은 자신을 위한 시간도 가져보세요.
    '인내심'과 '자기표현'의 밸런스를 잘 잡으면 진짜 '학교의 숨은 영웅'이 될 수 있어요! 파이팅!`,
    school: `자신감이 많이 부족해요. 그래서 시험보다 발표나 면접에서 떨어질 확률이 높아요.
- '수업 시간': 발표를 자주 해보세요. 처음엔 떨리겠지만, 점점 자신감이 붙을 거예요.
- '동아리 활동': 작은 역할부터 시작해 점점 더 중요한 역할을 맡아보세요.
- '공부 방법': 친구들에게 가르치는 식으로 공부해보세요. 이해도도 높아지고 자신감도 생길 거예요.
- '학교 행사': 행사나 대회에 참가해보세요. 이런 경험들이 당신을 더 강하게 만들어줄 거예요.
- '선생님과의 소통': 모르는 걸 물어보는 습관을 들이세요. 당신의 학업 능력과 자신감을 키울 수 있어요.
먼저 잃어버린 자존감을 회복해야 해요. 그래야 원하는 성적이나 결과를 얻을 수 있어요.`,
    human: `- 베프, 여자친구
'핵인싸'나 '꿀잼' 타입은 아니에요. 늘 성실하고 묵묵히 공부하며 친구들 맞춰주는 스타일이에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 노력을 인정해주고, 가끔은 재미있는 활동을 함께 해보세요.
- 선생님
욕심 없는 선생님이라 안심하고 믿을 수 있어요. 이런 선생님과 소통할 때는 성실한 모습을 보여주되, 때로는 자신의 의견도 조심스럽게 표현해보세요.
- 선배
싫은 소리 잘 안 하지만 그렇다고 '눈감아주는' 타입은 아니에요. 그저 인내하며 기다려줄 뿐이에요. 이런 선배와 함께 활동할 때는 책임감 있게 행동하되, 때로는 자신의 아이디어도 제안해보세요.
- 친구, 후배
꾸준히 하는 일은 아주 잘해내요. 하지만 창의력 필요한 일은 부담스러울 수 있으니 그의 장점이 잘 드러날 수 있는 일을 맡겨야 해요. 이런 친구나 후배와 함께 있을 때는 그들의 성실함을 인정해주고, 함께 새로운 도전을 해보세요.`,
  },

  ABBCB: {
    type: "ABBCB",
    category: "긍정과 부정 양면 유형",
    desc: `'반전 매력 학교생활 히든 피스' 타입`,
    sub: `교실의 숨은 열정 부자!`,
    char: `여러분은 진정한 '반전 매력 학교생활 히든 피스' 타입이에요! 공부할 때만큼은 책임감 있게 최선을 다하고, 대충하지 않는 타입이에요. 하지만 취미생활을 잘 즐기지 못해서 학교생활이 좀 지루해질 때가 있어요.
이 타입은 공부할 때 '긍정적인 면'과 '부정적인 면'이 모두 나타나요. 자신이 열심히 하는 만큼 친구들에게도 기대가 크기 때문에, 대충 하는 친구들을 보면 당황스러울 때도 있어요. 이런 공부에 충실한 모습도 좋지만, 좀 더 학교생활의 즐거움을 찾아보는 게 필요해요. 감정을 솔직하게 표현하는 것이 학교생활을 더 즐기는 첫걸음이에요.
자기만의 사명감과 가치관을 지키는 것도 중요하지만, 삶에 활기를 불어넣어 줄 수 있는 취미나 동아리 활동을 해보는 건 어때요? 친구들이 더 가까이 다가올 수 있도록 마음을 여는 것이 당신에게 필요한 급선무일 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 성실함과 잠재력을 믿어요. 하지만 가끔은 학교생활을 즐기는 시간도 가져보세요.
    '성실함'과 '즐거움'의 밸런스를 잘 잡으면 진짜 '학교의 숨은 열정 부자'가 될 수 있어요! 파이팅!`,
    school: `어쩌면 보수적이고 변화를 두려워하는 당신은 지금 공부가 안 맞아도 쉽게 포기하고 다른 과목을 택하지 않는 타입이에요.
- '새로운 과목 도전': 평소 잘하지 못했던 과목에 새로운 학습 방법을 시도해보세요.
- '학교 행사 참가': 축제나 체육대회에 적극 참여해보세요. 새로운 경험이 될 거예요.
- '동아리 활동': 다양한 동아리에 가입해 새로운 친구들을 사귀고 경험을 쌓아보세요.
- '수업 참여': 적극적으로 발표하고 질문해보세요. 자신감을 키울 수 있어요.
- '예체능 활동': 운동이나 예술 활동에 참여해보세요. 스트레스 해소와 새로운 재능 발견의 기회가 될 거예요.
좀 더 자신감 갖고 잃어버린 자존감 회복해야 어떤 공부든 만족스러운 결과 낼 수 있어요.`,
    human: `- 베프, 여자친구
'개그코드' 없지만 든든한 사람이에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 성실함을 인정해주고, 가끔은 재미있는 활동을 함께 해보세요.
- 선생님
약속 철저히 지키고 주고받는 건 확실한 사람이라 믿고 맡길 수 있어요. 이런 선생님과 소통할 때는 성실한 모습을 보여주되, 때로는 창의적인 아이디어도 제안해보세요.
- 선배
공부 성실하게 하는 후배들 좋아해요. 결과보다 과정 중요시하니까 평소 태도가 평가의 중요 포인트가 돼요. 이런 선배와 함께 활동할 때는 성실한 모습을 보여주되, 가끔은 새로운 아이디어도 제안해보세요.
- 친구, 후배
꾸준히 하는 일은 아주 잘해내요. 하지만 창의력 필요한 일은 부담스러울 수 있으니 그의 장점이 잘 드러날 수 있는 일을 맡겨야 해요. 이런 친구나 후배와 함께 있을 때는 그들의 성실함을 인정해주고, 함께 새로운 도전을 해보세요.`,
  },

  ABBCC: {
    type: "ABBCC",
    category: "독선적 보스 유형",
    desc: `'갓생 강요하는 학교생활 대장' 타입`,
    sub: `교실의 숨은 보스 베이비!`,
    char: `여러분은 진정한 '갓생 강요하는 학교생활 대장' 타입이에요!
이 타입은 친구들에게 권위 부리면서 자기 뜻을 강요하는 게 특징이고, 반대 의견은 거의 무시해요.
이들은 다양성 인정하는 걸 어려워해요. 그래서 친구들한테 '획일화'를 강요하기 쉬워요. 또 목표 달성할 때 모두가 '희생'돼도 괜찮다고 생각하기 쉬워요.
이 타입은 자기 실력이나 능력이 클수록 스스로 자제하고 조심하지 않으면 주변에 안 좋은 영향 줄 수 있어요. 성격이 고집스럽고, 정에 얽매이지 않는 타입이라 평소 친구들을 배려하고 다른 사람과 즐거움 찾을 수 있게 융통성 기르는 게 필요해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 리더십과 추진력을 믿어요. 하지만 가끔은 다른 사람의 의견도 듣고 존중하는 연습을 해보세요.
    '리더십'과 '존중'의 밸런스를 잘 잡으면 진짜 '학교의 진정한 리더'가 될 수 있어요! 파이팅!`,
    school: `이 타입은 발표나 면접 때 표현이 서툴러 '꼰대'같고 고집 센 사람처럼 보일 수 있어요.
- '조별 과제': 리더 역할을 맡았을 때, 팀원들의 의견도 경청하고 반영해보세요.
- '수업 발표': 주장을 강하게 밀어붙이기보다는, 근거를 제시하며 설득력 있게 말하세요.
- '동아리 활동': 다양한 의견과 아이디어를 존중하는 자세를 보여주세요.
- '학교 행사': 다른 친구들의 장점을 인정하고 칭찬하는 습관을 들이세요.
- '학급 회의': 의견을 제시할 때 다른 친구들의 감정도 고려해보세요.
감정 표현 잘 못해서 자신감 없어 보이는 손해도 볼 수 있으니 평소 자기 감정을 솔직하게, 하지만 부드럽게 표현하는 연습하는 게 좋아요.`,
    human: `- 베프, 여자친구
이 사람은 좋은 친구나 여자친구감은 아닐 수 있어요. '보스' 같은 성격이 강하다는 걸 이해하고 받아들일 수 있다면 친해져도 괜찮아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 의견을 존중하되, 자신의 생각도 조심스럽게 표현해보세요.
- 선생님
약속 안 지키는 걸 싫어해요. 친절하고 재밌는 부분은 기대하기 어려운 선생님이에요. 하지만 말도 안 되는 핑계로 시험이나 과제를 무효화하는 일은 절대 없어요. 이런 선생님과 소통할 때는 약속을 잘 지키고, 성실한 모습을 보여주세요.
- 선배
이런 선배는 권위적으로 일 시키는 경우가 많아요. 하지만 감정 표현이 서툰 사람이니 그 사람 감정도 잘 이해하고 공감해주면 인정받는 후배가 될 수 있어요. 이런 선배와 함께 활동할 때는 그들의 지시를 잘 따르되, 때로는 자신의 의견도 조심스럽게 제안해보세요.
- 친구, 후배
이 타입은 자기 감정 표현이 많이 부족해요. 그래서 당신이 그 사람 마음을 잘 이해해주고 표현할 수 있게 도와주면 좋은 조력자가 되어줄 거예요. 다만 자기가 맞다고 생각하면 강하게 밀고 나갈 수 있으니 주변이랑 타협할 수 있게 신경 써줘야 해요.`,
  },

  ABCAA: {
    type: "ABCAA",
    category: "명예를 추구하는 예술가 유형",
    desc: `'SNS 스타 꿈꾸는 학교생활 아티스트' 타입`,
    sub: `교실의 숨은 인플루언서!`,
    char: `여러분은 진정한 'SNS 스타 꿈꾸는 학교생활 아티스트' 타입이에요! '카리스마'와 '아티스트' 기질을 동시에 가졌어요. 그래서 이 유형의 친구들은 '인플루언서'나 '셀럽'이 되길 꿈꿔요.
높은 지위의 직업을 좋아하지만 '프리함' 때문에 정치인이나 군인보다는 예술이나 연예 쪽으로 가고 싶어 해요. 특히 '파워'에 대한 욕구, 넘치는 호기심을 가지고 있고, 늘 '이상적인 것'을 추구해요. 또 친구들에게 '특별해' 보이고 싶어 하고 '인정'받고 싶어 하는 마음이 강해요.
이 모든 욕구를 채우기 위해 많은 '팔로워' 위에 군림할 수 있는 '레전드' 아티스트나 '메가 스타'가 되고 싶어 해요. 하지만 평범한 학교생활을 하는 이 타입은 '꿈'과 '현실'의 차이에서 고민에 빠지기 쉬워요. 이런 고민에서 벗어나려면 늘 '이성적 사고'를 키우고 모든 일을 간단하게 생각하는 습관을 길러야 해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 창의성과 열정을 믿어요. 하지만 가끔은 현실을 직시하고 계획을 세우는 연습도 해보세요.
    '창의성'과 '현실감각'의 밸런스를 잘 잡으면 진짜 '학교의 셀럽'이 될 수 있어요! 파이팅!`,
    school: `이 타입의 친구는 발표나 면담 때 자기 감정과 고집에 빠져 상황 파악을 못할 수 있어요.
- '수행평가': 창의적인 아이디어를 내되, 현실성도 고려하세요. 선생님과 친구들의 피드백을 잘 들어보세요.
- '동아리 활동': 자신의 재능을 뽐내면서도 팀워크를 중요하게 여기세요.
- '학교 축제': '인정받고 싶은 마음'을 조절하고, 과정을 즐기는 자세를 가져보세요.
- '학업': 꿈을 위해 공부를 소홀히 하지 마세요. 균형 잡힌 성장이 중요해요.
- 'SNS 활동': 학교생활과 SNS 활동의 균형을 잘 맞추세요. 과도한 SNS 사용은 학업에 방해될 수 있어요.
발표 전에 주제에 대한 정보를 잘 알아두고, 당황하더라도 차분하게 표현할 수 있도록 준비해야 해요.`,
    human: `- 베프, 여자친구
감정 표현 잘하는 타입이라 함께 있으면 '꿀잼' 상대예요. 하지만 감정 기복이 심할 수 있어 관계 유지가 힘들 수 있어요. 이런 친구나 여자친구와 함께 있을 때는 그들의 감정 변화를 이해하고, 때로는 현실적인 조언도 해주세요.
- 선생님
선생님의 감정을 잘 살피고, 이성적으로 대화를 나누는 게 좋아요. 이런 선생님과 소통할 때는 예의를 지키면서도, 창의적인 아이디어를 조심스럽게 제안해보세요.
- 선배
'권위'를 중요하게 여기고, 감정 변화가 많아서 대하기 어려운 선배예요. 합리적인 판단보다 감정의 판단이 먼저 일어날 수 있으니, 감정을 잘 살피고 이성적으로 접근하는 게 좋아요. 이런 선배와 함께 활동할 때는 그들의 감정을 존중하면서도, 때로는 현실적인 의견을 제시해보세요.
- 친구, 후배
이 친구가 꼭 필요한 '인재'라고 생각되면, 감정을 잘 조절하고 차분하게 지낼 수 있도록 도와주세요. 모든 일을 할 때 메모하게 하고 중간중간 확인해주면 일을 잘 진행할 수 있을 거예요. 이런 친구나 후배와 함께 있을 때는 그들의 창의성을 인정해주고, 함께 현실적인 계획을 세워보세요.`,
  },

  ABCAB: {
    type: "ABCAB",
    category: "불도저 유형",
    desc: `'돌진 본능 만렙 학교생활 불도저' 타입`,
    sub: `교실의 숨은 돌격대장!`,
    char: `여러분은 진정한 '돌진 본능 만렙 학교생활 불도저' 타입이에요! 뭔가에 꽂히면 '브레이크 고장난 차'처럼 달려요. 의지는 강하지만 편협한 생각에 갇히기도 하고, 이성적 판단 못하고 '야생마'처럼 충동적 감정에 휘둘리기도 해요.
친구들을 '깔봄'과 동시에 자기 욕심 채우고 싶은 마음이 항상 있어요. 이 타입은 일이 잘못됐을 때 자기 감정과 행동 때문이란 걸 인정 못해요. 또 좋은 결과에 대해선 이성적 판단 없이 '오버'하고 '자만'에 빠지기도 해요. 이런 행동은 결국 아무도 가까이 안 하는 '아싸'로 만들어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 열정과 추진력을 믿어요. 하지만 가끔은 멈춰서서 차분히 생각하는 시간도 가져보세요.
    '열정'과 '이성'의 밸런스를 잘 잡으면 진짜 '학교의 긍정 에너지 충전소'가 될 수 있어요! 파이팅!`,
    school: `이 타입은 감정 조절 못하고 직설적으로 표현해서 발표나 면담 때 '아무도 말리지 마' 스타일로 보일 수 있어요.
- '조별 과제': 자기 의견만 고집하지 말고 친구들 의견도 들어보세요.
- '수행평가': '벼락치기'보다는 계획적으로 공부하는 습관을 들이세요.
- '동아리 활동': 리더십을 발휘하되, 독단적으로 결정하지 말고 팀원들과 상의하세요.
- '선생님과의 대화': 예의를 갖추고 차분하게 말하는 연습을 해보세요.
- '학교 행사': 열정을 보여주되, 다른 친구들의 의견도 존중하세요.
성공하고 싶다면 이성적이고 합리적인 판단력과 자제력 보여주는 게 필요해요.`,
    human: `- 베프, 여자친구
이런 타입은 자기 감정에만 충실해서 이성적 판단이나 배려 기대하기 어려워요. 마음 충분히 들어주고 이성적으로 생각할 수 있게 도와주세요.
- 선생님
즐거운 일에 대해 함께 기뻐해주고 축하해주세요. 하지만 감정에 휘말리지 말고 합리적으로 대하는 게 좋아요. 이런 선생님과 소통할 때는 열정을 인정받되, 때로는 차분히 생각하는 모습도 보여주세요.
- 선배
'불도저'처럼 일 밀고 나가는 타입의 선배지만 합리적 판단으로 하는 게 아니라서 뒷정리가 부족해 당신 일로 돌아올 수 있어요. 당신의 좋은 뜻도 나쁘게 받아들이거나 신중한 태도를 '귀차니즘'으로 오해할 수도 있어요. 이런 선배와 함께 활동할 때는 그들의 열정을 인정하면서도, 때로는 신중한 접근의 필요성을 설명해보세요.
- 친구, 후배
이런 친구라면 감정에 휘말려 행동하지 않는 게 좋아요. 이런 친구나 후배와 함께 있을 때는 그들의 열정을 인정해주되, 함께 차분히 생각하는 시간을 가져보세요.`,
  },

  ABCAC: {
    type: "ABCAC",
    category: `'난 아무것도 몰라요' 유형`,
    desc: `'모르는 게 약이에요 학교생활 무지개' 타입`,
    sub: `교실의 숨은 감정 롤러코스터!`,
    char: `여러분은 진정한 '모르는 게 약이에요 학교생활 무지개' 타입이에요! 이성이나 지성은 찾아보기 힘들고 쉽게 감정에 '급발진'하는 타입이에요.
합리적 판단이나 검증 능력이 많이 부족해서 오해가 생기면 '분노 게이지 풀충전'되어 감정 조절이 어려워요. 뭔가 실패했을 때 자기 책임이어도 쉽게 '발뺌'하거나 친구 탓하고 오히려 상대방에게 책임 떠넘기는 타입이에요.
이런 타입은 학교생활을 위해 항상 이성적이고 합리적으로 생각하는 연습을 해야 친구들과 잘 어울릴 수 있어요. 일이 잘못됐을 때 다 자기 탓이라고까진 말 안 해도 항상 자신을 객관적으로 돌아보며 매사를 판단하는 게 좋아요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 감정 표현 능력을 믿어요. 하지만 가끔은 이성적으로 생각하고 책임감 있게 행동하는 연습도 해보세요.
    '책임감'과 '이성적 사고'의 밸런스를 잘 잡으면 진짜 '학교의 성장 아이콘'이 될 수 있어요! 파이팅!`,
    school: `이 타입은 발표나 면담에서 합리적인 태도와 책임감 있는 모습을 보여줘야 해요.
- '조별 과제': 자신의 역할을 끝까지 책임지고 완수한 경험을 만들어보세요.
- '수행평가': 계획을 세우고 꾸준히 실천하는 습관을 들이세요.
- '실수 대처': 변명하지 않고 솔직히 인정하고 개선하려 노력해보세요.
- '동아리 활동': 어려운 상황에서도 포기하지 않고 해결책을 찾아 노력해보세요.
- '친구 관계': 갈등 상황에서 감정적으로 대응하지 않고 대화로 해결하려 노력해보세요.
자기소개서에도 이런 책임감 있는 행동을 보여주는 에피소드를 소개하는 게 좋아요.`,
    human: `- 베프, 여자친구
'나 몰라요' 하는 책임감 없는 태도 보이는 이 사람, '엄마 미소'로 돌봐줄 게 아니라면 다시 한번 생각해보는 게 좋아요. 이런 친구나 여자친구와 함께 있을 때는 그들의 감정을 이해하되, 책임감 있는 행동의 중요성을 알려주세요.
- 선생님
모든 과제나 약속을 꼼꼼히 메모해두는 게 문제 안 만드는 비결이에요. 이런 선생님과 소통할 때는 모든 내용을 정확히 기록하고, 필요할 때 증거로 제시할 수 있도록 준비하세요.
- 선배
인간적인 관계는 어려울 거예요. 공을 뺏기더라도 당신은 합리적인 사고와 행동을 하는 게 좋아요. 이런 선배와 함께 활동할 때는 모든 일을 문서화하고, 자신의 역할을 명확히 하세요.
- 친구, 후배
마음 나누는 친구 관계는 어려울 거예요. 후배라면 할 일의 우선순위를 정하고 논리정연하게 설명해줘야 해요. 이런 친구나 후배와 함께 있을 때는 명확한 기준과 규칙을 정하고, 그에 따라 행동하도록 도와주세요.`,
  },

  ABCBA: {
    type: "ABCBA",
    category: "남의 잘못만 지적하는 유형",
    desc: `"남 탓 만렙 '학교생활 마이웨이' 타입"
    교실의 숨은 자기중심 챔피언!`,
    char: `여러분은 진정한 '남 탓 만렙 학교생활 마이웨이' 타입이에요! '완벽한 삶'을 꿈꾸지만 실천은 잘 안 되는, 전형적인 '말만 앞서는' 타입이에요. 친구들 눈치를 보며 '모범생'이 되고 싶어 하지만, 상황 판단이나 문제 해결 능력은 부족하고, 머릿속은 늘 혼란스러운 상태죠.
친구들의 실수나 실패는 귀신같이 찾아내지만, 정작 자신의 잘못은 전혀 인식하지 못하는 면이 있어요. 이성적인 사고가 부족해서 남을 비판하는 건 잘하지만, 자신이 비판받으면 쉽게 화가 나고, 그걸 고치는 데 오랜 시간이 걸려요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 강한 의지를 믿어요. 하지만 가끔은 다른 사람의 의견도 듣고 자기 행동을 돌아보는 시간을 가져보세요.
    '자기성찰'과 '열린 마음'의 밸런스를 잘 잡으면 진짜 '학교의 성장 아이콘'이 될 수 있어요! 파이팅!`,
    school: `이 타입은 자기 '편견 100%' 사고나 감정 조절하는 게 진짜 중요해요.
- '조별 과제': 다른 친구들 의견도 존중하고 경청하는 연습을 해보세요.
- '수행평가': 결과가 안 좋을 때, 바로 선생님이나 문제 탓하지 말고 자기 공부 방식을 돌아보세요.
- '동아리 활동': 의견 충돌이 있을 때, 감정적으로 대응하지 말고 객관적으로 상황을 파악하려 노력해보세요.
- '친구 관계': 갈등 생겼을 때 '내가 잘못한 건 없나?' 하고 자기 행동을 돌아보는 시간을 가져보세요.
- '수업 태도': 선생님 말씀을 비판적으로 듣기보다는 배우려는 자세로 들어보세요.
친구들 말 귀 기울여 듣고 공감하는 능력 키우는 게 필수예요. 또 상황이나 사건을 '팩트' 기반으로 생각하고 판단하는 연습하는 게 좋아요.`,
    human: `- 베프, 여자친구
'평생 절친'으로 선택하기엔 '노노'예요. 자기가 문제 만들고 자기 잘못인 줄도 모르고 '우는 척'해요. 이런 친구나 애인과 함께 있을 때는 그들의 행동을 객관적으로 지적해주되, 감정적으로 대응하지 않도록 주의하세요.
- 선생님
이 선생님은 당신의 노력에 비해 충분한 반응을 보이지 않는 것 같습니다. 당신이 최선을 다해도 그 분의 반응이 미미하거나 사실에 근거하지 않은 경우가 있어 보입니다. 이런 상황에서는 모든 내용을 잘 기록해두고, 객관적인 자료를 바탕으로 대화를 나누는 것이 도움이 될 수 있습니다. 이렇게 하면 의사소통이 더 원활해질 수 있을 것입니다.
- 선배
학교생활에서 '인기스타' 될 사람은 아니에요. 이런 선배면 당신이 아무리 노력해도 인정 안 해줘서 '멘붕'만 올 거예요. 이런 선배와 함께 활동할 때는 감정적으로 대응하지 말고, 객관적인 성과를 보여주는 데 집중하세요.
- 친구, 후배
중요한 일 맡기기엔 '부담' 백배인 상대예요. 이런 타입 후배면 일의 우선순위 알려주고 중요한 일에 대해 '논리 풀' 설명해주는 게 좋아요. 이런 친구나 후배와 함께 있을 때는 명확한 기준과 규칙을 정하고, 그에 따라 행동하도록 유도하세요.`,
  },

  ABCBB: {
    type: "ABCBB",
    category: "자기과신과 욕심 유형",
    desc: `"자뻑 만렙 '학교생활 천재 아이돌' 타입"
    교실의 숨은 자신감 챔피언!`,
    char: `여러분은 진정한 '자뻑 만렙 학교생활 천재 아이돌' 타입이에요! 자신감이 넘쳐나서 실제 능력보다 더 큰 꿈이나 사명감을 가지는 경우가 많아요. 현실적인 상황이나 자신의 실력을 고려하지 않고 무작정 앞만 보고 달리는 경향이 있죠. 논리력, 분석력, 판단력이 부족해서 일 처리가 잘 안 되고, 가끔 '엉뚱하게' 생각하고 '대충' 처리하는 게 가장 큰 단점이에요.
이 타입이라면, 자기 자신을 제대로 이해하고 업그레이드하는 것이 필요해요. 자신의 행동 패턴을 스스로 점검하고, 부족한 부분을 개선해야 학교와 일상생활을 제대로 해낼 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자신감과 열정을 믿어요. 하지만 가끔은 현실을 직시하고 자기를 돌아보는 시간도 가져보세요.
    '자기 성찰'과 '현실 감각'의 밸런스를 잘 잡으면 진짜 '학교의 슈퍼스타'가 될 수 있어요! 파이팅!`,
    school: `이 타입은 규칙이 확실한 '학급 반장'이나 '도서부장' 같은 역할을 맡으면 잘 맞을 거예요.
- '수행평가': 무리한 계획보다는 현실적으로 달성 가능한 목표를 세우세요.
- '조별 활동': 리더 역할을 맡더라도 팀원들의 의견을 충분히 듣고 반영하세요.
- '시험 공부': '벼락치기'보다 꾸준히 계획적으로 하는 습관을 들이세요.
- '동아리 활동': 새로운 아이디어를 낼 때는 실현 가능성을 꼼꼼히 따져보세요.
- '자기소개': 높은 이상 얘기하기보다는 자신의 '실속'을 보여줄 수 있게 준비하세요.`,
    human: `- 베프, 여자친구
전체적인 걸 보는 눈이 부족해서 갑자기 사소한 문제로 '삐짐'하는 상대예요. 이런 친구나 여자친구와 잘 지내려면 냉철한 판단력과 '엄마 미소' 같은 너그러운 마음을 준비해야 해요.
- 선생님
이 선생님은 때때로 신중한 고려 없이 빠르게 결정을 내리는 경향이 있습니다. 하지만 이에 대해 즉각적으로 반박하기보다는, 선생님께 생각할 시간을 드리는 것이 좋습니다. 그러면 선생님도 자신의 입장을 재고하고 더 나은 의견을 제시할 수 있을 것입니다. 이런 상황에서는 차분하고 존중하는 태도를 유지하면서, 동시에 객관적인 사실과 정보를 바탕으로 대화를 나누는 것이 효과적입니다. 이렇게 하면 서로를 이해하고 건설적인 대화를 이어갈 수 있을 것입니다.
- 선배
문제 생겼을 때 즉흥적으로 생각하거나 충동적으로 남 탓하는 경우가 많아요. 이런 상대에겐 당신도 그때그때 '눈치껏' 넘기는 것도 필요해요. 대신 맞는 말에는 확실히 대처하는 게 좋아요.
- 친구, 후배
상황 판단 없이 앞서가거나 서둘러서 일을 '망치는' 실수를 자주 할 수 있으니 주의해야 해요. 이런 친구나 후배와 함께 있을 때는 그들의 열정을 인정해주되, 함께 현실적인 계획을 세워보세요.`,
  },

  ABCBC: {
    type: "ABCBC",
    category: "자기 색깔이 강한 유형",
    desc: `"나만의 색깔 '찐'하지만 '학교생활 실속 제로' 타입"
    교실의 숨은 마이웨이 챔피언!`,
    char: `여러분은 진정한 '나만의 색깔 찐하지만 학교생활 실속 제로' 타입이에요! 친구 관계가 생각만큼 재미있지 않을 수 있어요. 왜냐하면 당신의 색깔이 너무 뚜렷해서 좋고 싫음이 확실하고, 문제 해결도 '내 방식'으로 하는 경우가 많기 때문이에요. 물론 책임감과 정의감도 있고, 편견 없이 자기 주관대로 결정하는 강한 성향이 있어요.
하지만 이성과 지성이 충분히 충전되지 않아, 현실적 판단보다는 즉흥적으로 행동해 손해를 보는 경우가 종종 있어요. 주변에서는 가끔 '비현실적', '자기 중심적', '고집불통', '꼰대' 같다는 말을 들을 수도 있어요. 친구들에게는 엄격하면서도 자신에게는 관대한 편이라, 다른 사람들의 반응에도 조금 더 신경 쓰고, 친구들을 배려하고 맞춰가는 모습이 필요해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 개성을 믿어요. 하지만 가끔은 다른 사람의 의견도 듣고 현실을 고려하는 연습을 해보세요.
    '열린 마음'과 '현실 감각'의 밸런스를 잘 잡으면 진짜 '학교의 유니크 아이콘'이 될 수 있어요! 파이팅!`,
    school: `아직 진로를 정하지 않았다면, 자신이 가고 싶은 학교나 학과의 인재상을 잘 살펴보고 선택하는 게 좋아요.
- '조별 과제': 자신의 의견만 고집하지 말고 다른 친구들의 아이디어도 존중해보세요.
- '수행평가': 즉흥적으로 하기보다는 계획을 세워 차근차근 준비해보세요.
- '동아리 활동': 리더 역할을 맡았다면, 독단적으로 결정하지 말고 팀원들의 의견을 듣고 반영하세요.
- '대화 방식': 선생님이나 친구들과 대화할 때 자신의 감정을 조절하고 상대방의 입장에서 생각해보세요.
- '진로 탐색': 다양한 진로 체험 활동에 참여해보고, 자신의 적성과 흥미를 객관적으로 파악해보세요.
이미 고등학교나 대학에 다니고 있다면 주변 친구들의 의견을 통해 본인이 부족한 부분을 채워나가는 게 좋아요.`,
    human: `- 베프, 애인
이 타입은 자기가 '리드'하는 경우가 많아요. 어떤 문제가 생겼을 때 현실적으로 생각하고 처리하는 게 '노답'일 수 있으니 이런 부분을 상대가 보완해줄 수 있다면 좋은 친구, 연인이 될 수 있어요.
- 선생님
이 선생님은 매우 정직하고 성실한 분이시지만, 때로는 실용적인 면을 간과할 수 있습니다. 수업이나 과제를 진행할 때, 명확하고 논리적인 approach가 도움이 될 수 있습니다. 이는 서로에게 공정하고 유익한 결과를 가져올 것입니다. 이 선생님과 소통할 때는 논리적으로 설명하되, 동시에 선생님의 의도와 관점을 잘 이해하려 노력하는 것이 중요합니다. 이렇게 하면 상호 이해와 효과적인 협력이 가능할 것입니다.
- 선배
이런 선배는 '카리스마' 리더 스타일로 자기 생각을 그대로 실행하는 사람이에요. 상대를 맞추기보다는 자기를 맞춰주는 후배에게 '찐'으로 믿음을 줄 수 있어요. 이런 선배와 함께 활동할 때는 그들의 리더십을 인정하면서도, 때로는 다른 의견을 조심스럽게 제안해보세요.
- 친구, 후배
시간 두고 천천히 대책 생각하는 게 좋아요. 그러다 보면 상대방이 지치는 경우가 더 많아요. 이런 친구나 후배와 함께 있을 때는 그들의 독특한 성격을 인정해주되, 함께 현실적인 계획을 세워보세요.`,
  },

  ABCCA: {
    type: "ABCCA",
    category: "눈치 보며 순응하는 유형",
    desc: `"눈치 100단 '학교생활 갬성 순응러' 타입"
    교실의 숨은 분위기 메이커!`,
    char: `여러분은 진정한 '눈치 100단 학교생활 갬성 순응러' 타입이에요! 자기 길이 '막막 그 자체'라고 생각해요. 마음 한켠엔 목표도 있고 이상이나 신념도 있지만, 겉으로 보이는 건 친구들 눈치 보며 '눈치플레이'하는 타입이에요. 문제가 생기면 너무 멀리서 답을 찾으려고 해서 현재 문제를 스스로 해결하기보다는 친구들에게 의존하거나 순응하며 따라가는 게 단점이에요.
그렇다고 항상 순응만 하는 건 아니에요. 좀 더 깊이 들여다보면 옳고 그름을 잘 판단하고 비판과 공격 성향도 있는 사람이에요. 이런 모습이 안 보이는 건 마음속 '열등감'이 누르고 있어서예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 눈치 백단 능력을 믿어요. 하지만 가끔은 자신의 의견을 당당히 말하는 연습도 해보세요.
    '자신감'과 '주관'의 밸런스를 잘 잡으면 진짜 '학교의 숨은 리더'가 될 수 있어요! 파이팅!`,
    school: `본인이 가진 '찐 장점'을 잘 살려서 학교생활 전략을 세우는 게 좋아요.
- '수행평가': 남들 따라하기보다는 자신만의 독특한 아이디어를 내보세요.
- '발표 시간': 자신의 의견을 자신 있게 말하는 연습을 해보세요.
- '동아리 활동': 적극적으로 의견을 제시하고 새로운 일에 도전해보세요.
- '친구 관계': 의견 충돌이 있을 때 항상 피하지 말고, 때로는 자신의 입장을 설명해보세요.
- '학급 활동': 반장이나 부반장은 아니더라도 작은 역할부터 맡아보며 책임감을 키워보세요.
노력해서 할 수 있는 일보다 우선 본인이 '찰떡'으로 잘할 수 있는 분야에서 시작해보는 게 좋아요.`,
    human: `- 베프, 여자친구
처음엔 어려운 상대로 보이지만 겸손하고 양보 잘하는 사람이에요. 이런 친구나 여자친구와 함께 있을 때는 그들의 의견을 존중해주되, 가끔은 자신의 생각도 표현하도록 격려해주세요.
- 선생님
이 선생님은 유연하고 협조적인 성향을 가지고 계십니다. 자신의 의견을 강하게 주장하기보다는 상황에 맞춰 조화롭게 대응하시는 편입니다. 이런 선생님과 소통할 때는 먼저 선생님의 의도와 생각을 잘 이해하려 노력하는 것이 중요합니다. 동시에, 여러분 자신의 의견이나 제안도 조심스럽게 표현해보는 것이 좋습니다. 이렇게 하면 서로 존중하는 가운데 건설적인 대화를 나눌 수 있을 것입니다.
- 선배
후배를 맞춰가는 선배지만 마음을 표현 못해서 때로는 답답할 수 있어요. 이런 선배와 함께 활동할 때는 그들의 배려를 인정하면서도, 때로는 선배의 진심을 물어보는 것도 좋아요.
- 친구, 후배
경쟁에 약해요. 목표는 있지만 그 목표 달성하는 실행력은 부족하니 하나부터 열까지 세세한 가이드가 필요해요. 이런 친구나 후배와 함께 있을 때는 그들의 장점을 인정해주고, 함께 목표를 세우고 실천하는 연습을 해보세요.`,
  },

  ABCCB: {
    type: "ABCCB",
    category: "마음은 약하나 권위적인 유형",
    desc: `'겉바속촉 카리스마 부자' 타입`,
    sub: `학교의 숨은 보스!`,
    char: `여러분은 진정한 '겉바속촉 카리스마 부자' 타입이에요! 겉으로는 카리스마 넘치는 보스 같지만, 속은 따뜻하고 이해심 많은 스타일이죠.
사회적 욕구가 '만렙'인 여러분은 종종 '보스 같다'는 말을 듣곤 해요. 하지만 가끔 목표 달성하려다 판단 실수할 때가 있어요. '즉흥 플레이'로 문제 해결하려고 하지만, 에너지가 부족해 힘들어하기도 해요.
감정 표현이 서툴러서 스트레스 받기 쉬운 타입이에요. 하지만 여러분의 도덕적이고 책임감 넘치는 성격은 정말 멋져요! 이런 장점이 더 빛날 수 있도록 밝은 성격을 만들어가는 게 중요해요.`,
    result: `기억하세요! 이 타입은 '겉바속촉 카리스마 부자' 스타일이지만, '감정 표현'과 '유연성'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 리더십과 책임감을 믿어요. 하지만 가끔은 유연하게 대처하고, 감정을 표현하는 법을 배워보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 자유로운 활동보다 체계적인 공부가 잘 맞는 타입이에요. 마음에 드는 친구들과는 공부도 잘 할 수 있지만, 그렇지 않으면 상처받기 쉬워요. 대인관계에 좀 더 신경 쓰면 좋겠어요.
조별 과제할 때 리더 역할을 맡더라도 팀원들 의견을 존중하고 반영하려고 노력해보세요. 예를 들어, "우리 모두의 아이디어를 모아볼까?"라고 제안해보는 건 어떨까요?
수업 시간 발표할 때 자신의 의견만 강하게 주장하기보다는 다른 친구들 의견도 들어보려고 해보세요. "너희들 생각은 어때?"라고 물어보는 습관을 들여보세요.
동아리 활동에서 새로운 아이디어를 낼 때 즉흥적으로 결정하지 말고, 천천히 생각해보고 결정하는 습관을 들여보세요. "이 아이디어에 대해 하루 정도 고민해보고 내일 다시 얘기해볼까?"라고 말해보는 건 어떨까요?
스트레스 받을 때 혼자 참지 말고 친한 친구나 선생님께 상담을 요청해보세요. "나 요즘 좀 힘들어. 얘기 좀 들어줄 수 있어?"라고 말하는 연습을 해보세요. 이런 식으로 감정 표현하는 연습을 하면 도움이 될 거예요.`,
    human: `- 베프, 여자친구
이 타입은 도덕적으로 살려고 노력해요. 적극적인 '연애 고수' 타입은 아니지만 상대방을 보호하고 헤아려주는 마음과 책임감은 강해요. 그들의 따뜻한 마음을 알아주고, 가끔은 그들이 감정을 표현할 수 있게 도와주세요.
- 선생님
이 타입의 선생님은 '꼼수' 부리거나 이익만 따지며 일하는 타입이 아니에요. 학생들을 진심으로 대하고 책임감 있게 가르치려 노력해요. 선생님의 이런 모습을 존중하고, 수업에 적극적으로 참여하면 좋아하실 거예요.
- 선배
일을 꼼꼼하게 처리하거나 체계적으로 하는 게 약할 수 있어요. 하지만 목표의식과 자기 소신은 확실해요. 선배의 강점을 인정하고, 부족한 부분은 이해하며 도와주려고 노력해보세요.
- 친구, 후배
창의적이거나 개방적인 면이 부족한 타입이에요. 상대의 단점을 이해하고 잘할 수 있는 일에 부탁하는 게 좋아요. 예를 들어, 체계적인 계획이 필요한 일을 맡기면 잘할 거예요.`,
  },

  ABCCC: {
    type: "ABCCC",
    category: "고집 있는 선생님 유형",
    desc: `'마이웨이 올드루키' 타입`,
    sub: `학교의 숨은 마이웨이 히어로!`,
    char: `여러분은 진정한 '마이웨이 올드루키' 타입이에요! 남의 말을 듣기보다는 자기 주장을 확실하게 펼치는 스타일이죠. 완고한 면이 있어서, 주변에서 보기에는 가끔 '꼰대 같은' 느낌을 줄 수 있어요. 하지만 긍정적으로 보면, 소신이 뚜렷하고 가치관이 명확한 사람이에요.
문제는 현실 감각과 대처 능력이 부족하다는 점이에요. 자신이 정한 원칙을 너무 중요하게 생각하다 보니 '융통성이 없다'는 이야기를 들을 때도 있어요. 이 타입은 남들에 대한 기대치를 낮추고, 즉흥적인 행동보다는 현실적이고 객관적인 태도를 가지는 것이 필요해요.
무엇보다도, 남에 대한 '오픈 마인드'가 중요해요. 물론 당신은 남 생각을 크게 신경 쓰지 않는 경향이 있지만, 어느 정도는 자기 생활 태도나 사고방식을 남에게 맞추는 유연함이 필요해요.`,
    result: `기억하세요! 이 타입은 '마이웨이 올드루키' 스타일이지만, '유연성'과 '협동심'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뚜렷한 가치관과 소신은 정말 멋져요. 하지만 가끔은 다른 사람의 의견도 들어보고, 함께 협력하는 법을 배워보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 팀플보다는 혼자 하는 공부를 잘 할 수 있는 타입이에요. 그러나 언제나 혼자 하는 공부엔 한계가 있어요. 다른 친구들이랑 어울리는 방법을 배워야 해요.
조별 과제할 때 자기 의견만 고집하지 말고 다른 친구들 의견도 듣고 반영해보세요. "너희 생각은 어때?"라고 물어보는 연습을 해보세요.
수업 시간에 발표할 때 자기 생각만 말하지 말고, 다른 친구들 의견도 존중하는 모습을 보여주세요. "OO이 의견도 좋은 것 같아"라고 말해보는 건 어때요?
동아리 활동에서 새로운 아이디어를 낼 때 즉흥적으로 결정하지 말고, 팀원들과 상의해보세요. "우리 다 같이 브레인스토밍 해볼까?"라고 제안해보세요.
학교 행사나 반 활동에 적극적으로 참여해보세요. 이는 다른 친구들과 어울리는 좋은 기회가 될 거예요. 체육대회나 축제 준비 같은 활동에 참여해보는 건 어떨까요?
시험 기간에는 혼자 공부하는 것도 좋지만, 가끔은 스터디 그룹에 참여해보세요. 다른 친구들의 공부 방법을 배울 수 있는 좋은 기회가 될 거예요.`,
    human: `- 베프, 여자친구
이 타입은 '꿀잼'이나 유머러스한 사람은 아니에요. 상대가 유쾌하고 에너지 넘치는 '오픈 마인드' 소유자라면 이 타입을 보완할 수 있어요. 그들의 고집스러운 면을 이해하고, 가끔은 새로운 경험을 함께 해보는 게 좋아요.
- 선생님
이 선생님은 신중하고 깊이 있는 사고를 하시는 분입니다. 새로운 아이디어를 받아들이는 데 시간이 걸릴 수 있지만, 이는 모든 측면을 충분히 고려하시기 때문입니다. 선생님과 효과적으로 소통하기 위해서는 인내심을 가지고 단계적으로 접근하는 것이 좋습니다.
자신의 의견을 차분히 설명하면서, 동시에 선생님의 관점을 이해하려는 노력을 보여주세요. 이러한 상호 이해의 과정을 통해 서로의 생각을 나누고 공감대를 형성할 수 있습니다. 시간이 걸릴 수 있지만, 이런 방식으로 소통하면 결국 더 깊이 있고 의미 있는 대화를 나눌 수 있을 것입니다.
- 선배
즉흥적이고 독단적으로 일 밀어붙일 때는 맞서지 말고 참고 돌아가야 해요. 선배의 의견을 존중하면서도, 적절한 타이밍에 자신의 생각을 조심스럽게 제안해보세요.
- 친구, 후배
팀원으로 있을 때 가장 문제 될 수 있어요. 팀워크 깨지 않고 맞춰갈 수 있도록 세심한 가이드가 필요해요. 그들의 강점을 인정하고, 부족한 부분은 다른 팀원들이 보완할 수 있도록 해보세요.`,
  },

  ACAAA: {
    type: "ACAAA",
    category: "정체를 알기 어려운 유형",
    desc: `'미스터리 포커페이스 만렙' 타입`,
    sub: `학교의 숨은 감성 챌린저!`,
    char: `여러분은 진정한 '미스터리 포커페이스 만렙' 타입이에요! 깊게 친해지지 않으면 여러분의 진짜 모습을 알아채기 어려워요. 겉으로는 차갑고 합리적이며 '오픈 마인드'인 사람처럼 보여요. 친구들과 협력하기에도 좋은 타입이에요. 특히 감정 교류 없이 목표 달성에만 집중하는 팀 프로젝트에서는 아주 뛰어난 실력을 발휘하죠.
하지만 문제는 여러분이 정확하고 올바른 태도 속에 차가운 면을 숨기고 있다는 거예요. 겉으로는 남들의 시선을 신경 쓰며 티 내지 않지만, 내면에는 냉정하고 계산적인 판단을 하는 자신이 있어요. 그래도 표현을 좋아해 사람들에게 친근하게 다가가려는 편이에요.
좀 더 신경 써야 할 부분은 이성보다는 감성을 키우는 것이에요. 다른 사람을 이해하고 공감하는 모습을 보여준다면, 최고의 리더가 될 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '미스터리 포커페이스 만렙' 스타일이지만, '감성'과 '공감 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 냉철한 판단력과 협력 능력은 정말 멋져요. 하지만 가끔은 감정을 표현하고, 다른 사람의 마음을 이해하려고 노력해보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 팀 프로젝트에 잘 어울리는 타입이에요. 사람들이랑 관계 속에서 빛을 발하는 타입이에요. 하지만 팀 활동에서도 항상 변수가 있을 수 있으니 갈등 상황에서도 자기 감정 조절하려는 노력이 필요해요.
조별 과제할 때 팀원들의 의견을 경청하고 공감하는 연습을 해보세요. "너의 아이디어 정말 좋은데? 어떻게 생각했어?"라고 물어보는 건 어때요?
수업 시간에 발표할 때 논리적인 내용뿐만 아니라 감성적인 표현도 추가해보세요. 예를 들어, 역사 수업에서 "이 사건은 많은 사람들에게 큰 영향을 미쳤어요. 그 당시 사람들의 마음은 어땠을까요?"라고 말해보는 건 어떨까요?
동아리 활동에서 친구들과 감정을 나누는 시간을 가져보세요. "오늘 활동하면서 어떤 느낌이 들었어?"라고 물어보는 것부터 시작해보세요. 이는 당신의 감성 지능을 높이는 데 도움이 될 거예요.
학교 행사나 봉사 활동에 적극 참여해 다양한 사람들과 교류해보세요. 축제 준비나 지역 봉사활동 같은 곳에서 새로운 친구들을 사귀어보세요. 이는 당신의 공감 능력을 키우는 좋은 기회가 될 거예요.
시험 기간에는 혼자 공부하는 것도 좋지만, 가끔은 스터디 그룹에 참여해보세요. 다른 친구들의 고민을 들어주고 함께 해결책을 찾아보는 건 어떨까요?`,
    human: `- 베프, 여자친구
함께 지내면서 손해 볼 일은 거의 없어요. 계산 빠른 사람이라 같이 '대박' 터뜨리는 재미와 함께 즐거운 인생 살 수 있는 파트너예요. 하지만 가끔은 감성적인 대화를 나눠보세요. "오늘 하루 어땠어? 기분은 어때?"라고 물어보는 것부터 시작해보세요.
- 선생님
협상 능력이 '갓벽'한 분이에요. 이런 타입의 선생님과 잘 지내려면, 논리적으로 의견을 제시하되 감정적인 부분도 조금씩 보여주세요. "선생님, 이 방법이 더 효율적일 것 같아요. 그리고 저희 반 친구들도 좋아할 것 같아요."라고 말해보는 건 어떨까요?
- 선배
헌신적이고 후배 보호하는 선배보단 문제해결 잘하고 결과 만들어내는 선배에 가까워요. 이런 선배와 잘 지내려면, 실력을 보여주되 가끔은 인간적인 모습도 보여주세요. "선배, 이 문제 해결했어요. 그런데 과정에서 좀 힘들었는데 조언 좀 해주실 수 있나요?"라고 말해보는 건 어떨까요?
- 친구, 후배
아이디어 뱅크에 잠재능력 '만렙'인 친구예요. 적절한 권한 주고 동기부여 해주면 '찐' 성과 낼 수 있어요. 이런 친구나 후배와 잘 지내려면, 그들의 아이디어를 존중하고 격려해주세요. "네 아이디어 정말 좋다! 어떻게 하면 더 발전시킬 수 있을까?"라고 말해보는 건 어떨까요?`,
  },

  ACAAB: {
    type: "ACAAB",
    category: "강한 파워 보스 유형",
    desc: `'학교의 갓생 플렉스 인플루언서' 타입`,
    sub: `반장의 품격!`,
    char: `여러분은 진정한 '학교의 갓생 플렉스 인플루언서' 타입이에요! 기회와 행운이 찾아오면 해당 분야에서 '대박' 성과를 낼 수 있는 '보스' 타입이에요. 자기가 세운 목표는 꼭 이뤄야 직성이 풀리는 타입으로, '찐' 열정과 에너지를 보여주는 스타일이에요.
가끔은 차갑게 보일 수 있지만, 특유의 '인싸력'으로 그것마저 자기 매력으로 포장하는 능력이 있어요. 학교에서는 좀 '핫한' 스타일로, 어떤 일이든 앞장서서 끌고 가는 타입이에요.
조금만 더 남을 배려하고 이해해주는 '따뜻한 이미지'를 만들 필요가 있어요. 주변 친구들이 당신 의견에 '오케이'하고 따라오는 건, 문제 일으키기 싫어서 그냥 넘어가려는 표현일 수도 있다는 걸 알아야 해요. 관심 어린 '잔소리'나 희생하는 모습을 보여주면 멋진 '리더'가 될 수 있어요.`,
    result: `기억하세요! 이 타입은 '학교의 갓생 플렉스 인플루언서' 스타일이지만, '배려심'과 '따뜻함'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 강한 리더십과 추진력은 정말 멋져요. 하지만 가끔은 주변 사람들의 마음도 살펴보고, 그들을 배려하는 모습을 보여주세요.
    그러면 더 멋진 리더가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 리더십이 강해서 학생회 활동이나 동아리 회장으로 '찰떡'이에요. 큰 그림 보고 돌진하는 추진력이 있어요.
학교 축제나 체육대회 같은 큰 행사를 기획할 때 리더 역할을 맡아보세요. "우리 이번 축제 테마를 '우주'로 하는 거 어때?"라고 제안해보는 건 어떨까요? 당신의 리더십을 발휘할 좋은 기회가 될 거예요.
조별 과제에서는 팀원들의 의견도 들어보고, 그들의 장점을 살릴 수 있는 역할 분담을 해보세요. "민수는 발표를 잘하니까 발표를 맡고, 지은이는 자료 정리를 잘하니까 그 부분을 맡아줄래?"라고 말해보는 건 어때요?
수업 시간에 발표할 때, 자신의 의견을 강하게 주장하되 다른 친구들의 의견도 존중하는 모습을 보여주세요. "내 생각은 이런데, 다른 친구들 생각은 어때?"라고 물어보는 습관을 들여보세요.
봉사 활동이나 멘토링 프로그램에 참여해 보세요. 예를 들어, 저학년 동생들의 학습을 도와주는 멘토링 프로그램에 참여해보는 건 어떨까요? 이는 당신의 따뜻한 면을 보여줄 좋은 기회가 될 거예요.
또한, 학교 신문이나 방송부에 참여해보는 것도 좋아요. 당신의 카리스마와 리더십을 학교 전체에 보여줄 수 있는 좋은 기회가 될 거예요.`,
    human: `
- 베프, 여자친구
주변 사람들을 잘 배려 안 할 수 있어요. 목적이 중심이라 자기 꿈을 위해 혼자 가버릴 수도 있어요. 이런 성향 이해하고 좀 더 따뜻하게 다가가야 해요. "너의 꿈을 응원해. 하지만 가끔은 우리도 생각해줘."라고 말해보는 건 어떨까요?
- 선생님
이 선생님은 전문성과 권위를 중요하게 여기시며, 교육에 대한 책임감이 강하신 분입니다. 학생들과의 관계에서 명확한 기준과 기대치를 가지고 계시죠. 때로는 엄격해 보일 수 있지만, 이는 교육의 질을 높이기 위한 노력의 일환입니다.
선생님은 교육 환경을 개선하기 위해 때로는 과감한 변화를 선택하실 수 있는 분입니다. 이런 선생님과 좋은 관계를 유지하기 위해서는 수업에 성실하게 참여하고 최선을 다하는 모습을 보여주는 것이 중요합니다.
또한, 가끔 선생님의 관심사나 전문 분야에 대해 질문을 드려보는 것도 좋은 방법이 될 수 있습니다. 이를 통해 선생님과 더 깊이 있는 대화를 나누고 상호 이해를 증진시킬 수 있을 것입니다.
- 선배
미운 털 박이지 않는 게 좋아요. 되도록 잘 맞춰 가되, 이런 타입의 선배와 싸우는 건 위험 부담이 커요. "선배, 저도 이렇게 생각하는데 어떠세요?"라고 조심스럽게 의견을 제시해보세요.
- 친구, 후배
열정과 의욕 '풀충전'이고 자부심도 큰 사람이에요. 이 타입은 좀 혼나도 쉽게 상처받거나 '탈주'할 일은 없어요. 그들의 열정을 인정해주되, 가끔은 "잠깐 쉬어가는 건 어때?"라고 제안해보세요.`,
  },

  ACAAC: {
    type: "ACAAC",
    category: "까다로운 개성파 유형",
    desc: `'무지개 빛깔 반짝이는 4차원 아이돌' 타입`,
    sub: `학교의 숨은 오브제!`,
    char: `여러분은 진정한 '무지개 빛깔 반짝이는 4차원 아이돌' 타입이에요! 어디를 가든지 자기만의 색깔로 분위기를 완전히 바꿔 놓는 타입이에요. 강한 개성을 가지고 있으며 열정과 의지가 넘치고, 감정 기복도 큰 편이에요. 좋고 싫음이 분명하고, 쉽게 남에게 의지하거나 순응하지 않는 독립적인 성향이죠.
힘든 일이 있어도 스스로 이겨내는 강한 타입이지만, 그게 항상 좋은 성격이라고만 볼 순 없어요. 마음이 가는 대로 행동하고 자신의 의견을 솔직히 말하는 것도 중요하지만, 그게 남들에게는 부담스럽게 느껴질 수 있어요.
이 타입에게 진짜 필요한 건 친절함과 배려, 그리고 이해심이에요. 감정을 표현하기 전에 먼저 남의 이야기를 친근하게 듣는 태도를 보여주세요. 좋고 싫음을 너무 분명히 하기보다는, 적당히 자제하면서 남이 정한 규칙이나 방식에 맞춰주는 모습도 필요해요.`,
    result: `기억하세요! 이 타입은 '무지개 빛깔 반짝이는 4차원 아이돌' 스타일이지만, '배려심'과 '협동 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 개성과 창의력은 정말 멋져요. 하지만 가끔은 다른 사람들의 의견도 듣고, 함께 협력하는 법을 배워보세요.
    그러면 더 빛나는 무지개가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 예술가의 능력이 '만렙'인 타입이에요. 좋아하는 일이 생기면 '올인'하는 기질이 강해요.
미술이나 음악 수업에서 자신만의 독특한 작품을 만들어보세요. "선생님, 제가 이번에 추상화를 그려봤는데 어떠세요?"라고 말해보는 건 어때요? 당신의 창의력을 마음껏 발휘할 수 있을 거예요.
동아리 활동에서는 자신의 아이디어를 제안하되, 다른 친구들의 의견도 존중하는 연습을 해보세요. "내 생각은 이런데, 너희들 생각은 어때?"라고 물어보는 습관을 들여보세요.
수행평가나 과제를 할 때, 자신만의 스타일을 보여주되 선생님의 기준도 충족시키는 방법을 찾아보세요. 예를 들어, 독후감을 쓸 때 시나 랩으로 표현해보는 건 어떨까요?
학교 축제나 반 발표회 같은 행사에서 주도적인 역할을 맡아보세요. "우리 이번 축제 때 플래시몹 어때?"라고 제안해보는 건 어떨까요? 하지만 팀원들과의 협력도 잊지 마세요.
다양한 교내 대회에 참가해보세요. 글짓기 대회, 과학 경진대회, 또는 예술 대회 등 당신의 재능을 마음껏 뽐낼 수 있는 기회를 찾아보세요. 이는 당신의 재능을 인정받고 발전시킬 좋은 기회가 될 거예요.`,
    human: `- 베프, 여자친구
남 비위 맞추는 건 이 타입한테 '노답'이에요. 1년 만나도 10년 만난 것처럼 크고 작은 일들이 항상 당신 '심장 쫄깃하게' 만들 거예요. 그들의 독특한 매력을 인정하고 즐기되, 가끔은 "잠깐, 우리 좀 천천히 가보자"라고 말해보세요.
- 선생님
이 타입 선생님이랑 지낼 땐 수업 내용에만 집중하는 게 좋아요. 인간적인 기대하다가 마음에 '서운 카드' 받을 때가 종종 있을 수 있어요. 하지만 선생님의 독특한 수업 방식을 즐기고, 열정적으로 참여해보세요.
- 선배
칭찬과 응원보다는 잔소리와 비판이 강한 선배예요. 하지만 공부나 활동에 있어서는 배울 점 많은 사람이에요. "선배, 조언 감사합니다. 제가 더 노력해볼게요."라고 말해보는 건 어떨까요?
- 친구, 후배
완벽한 사람은 없어요. 서로 마음 잘 통하면 또 다른 나의 '절친'이 될 수도 있어요. 그들의 독특한 아이디어를 존중하고, 함께 재미있는 프로젝트를 해보는 건 어떨까요?`,
  },

  ACABA: {
    type: "ACABA",
    category: "합리적인 현실파 유형",
    desc: `'현실 세계 치트키 마스터' 타입`,
    sub: `학교의 숨은 엘리트!`,
    char: `여러분은 진정한 '현실 세계 치트키 마스터' 타입이에요! 이성과 지성이 뛰어난, 합리적이고 현실적인 성향을 가지고 있어요. 어떤 어려운 상황이 와도 감성에 기대기보다는 객관적으로 상황을 분석하고 현실적으로 문제를 해결하죠. 그래서 가끔 주변 친구들로부터 '인간미가 없다'거나 '계산기 같다'는 말을 들을 수 있어요.
정에 얽매이기보다는 현실을 잘 받아들이며, 쉽게 멘붕에 빠지거나 포기하지 않는 강한 타입이에요. 스스로 상처받는 걸 두려워해 미리 방어벽을 쳐두는 성향도 있어서, 인간관계나 공부에서 안전하게 행동하려고 해요. 완전한 '인싸'는 아니고, 감정을 억제하고 타협하려는 모습을 자주 보이죠.
다만, 다른 사람의 장점을 보고 따뜻한 마음으로 다가가는 모습이 필요해요. 가끔은 "괜찮아?"라고 물어보는 것만으로도 큰 변화를 일으킬 수 있어요.`,
    result: `기억하세요! 이 타입은 '현실 세계 치트키 마스터' 스타일이지만, '감성'과 '따뜻함'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 논리적인 사고와 현실적인 판단력은 정말 멋져요. 하지만 가끔은 감정을 표현하고, 다른 사람의 마음을 이해하려고 노력해보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 지적 능력이 'SSS급'이라 공부에서 '찐' 성과 내기 쉬워요. 한번 마음먹으면 뭐든 해낼 수 있는 성취감도 높아요.
수학이나 과학 같은 논리적인 과목에서 뛰어난 성적을 보여줄 수 있어요. "선생님, 이 문제 다른 방법으로 풀어봤는데 확인해주실 수 있나요?"라고 말해보는 건 어때요? 이런 과목의 대회나 경시대회에 도전해보세요.
조별 과제할 때는 팀원들의 감정도 고려하면서 효율적으로 일을 분배하고 진행해보세요. "우리 각자 잘하는 부분을 맡아서 하는 게 어떨까?"라고 제안해보는 건 어떨까요?
학생회 활동에서 재정 관리나 행사 기획 같은 실무적인 일을 맡아보는 것도 좋아요. "이번 축제 예산을 이렇게 짜봤는데 어때요?"라고 말해보세요.
진로 탐색할 때 본인의 적성과 현실적인 조건을 잘 분석해서 결정해보세요. 진로 상담 선생님과 상담을 받아보는 것도 좋은 방법이에요.
감성적인 면을 기르기 위해 문학 동아리나 봉사 활동에도 참여해보는 것을 추천해요. "이번 주 토요일에 같이 양로원 봉사 갈래?"라고 친구들에게 제안해보는 건 어떨까요?`,
    human: `- 베프, 여자친구
공부를 사랑하는 사람이라 친구나 여자친구한테 따뜻한 감성 전달하는 게 좀 부족할 수 있어요. 다만 완벽한 사람은 없으니 그 사람 자체를 이해하려고 노력해보세요. "너의 논리적인 면이 멋져. 근데 가끔은 감성적인 모습도 보여줘."라고 말해보는 건 어때요?
- 선생님
무리하게 요구하거나 즉흥적으로 일처리하는 스타일이 아니라서 수업 듣기에는 무난하고 편한 선생님이에요. 이런 선생님과 잘 지내려면 수업 내용을 잘 따라가고, 논리적인 질문을 해보세요.
- 선배
이런 선배한테는 실력으로 인정받아야 해요. 너무 감성적으로 다가가기보다는 공부나 활동 중심으로 대할 때 더 편해할 거예요. "선배, 이 문제 어떻게 푸는지 설명해주실 수 있나요?"라고 물어보는 건 어떨까요?
- 친구, 후배
남 기분 좋게 하려고 '립서비스'하는 타입은 아니에요. 솔직한 사람이니 그 점 이해하고 장점을 많이 보려고 노력해야 해요. 절대 남 배신하거나 먼저 적으로 만드는 타입은 아니에요. "네 솔직함이 좋아. 그런데 가끔은 조금만 더 부드럽게 말해줄 수 있을까?"라고 말해보세요.`,
  },

  ACABB: {
    type: "ACABB",
    category: "주관을 진리라고 믿는 유형",
    desc: `'팩트 폭격기 신념이 진리!' 타입`,
    sub: `학교의 숨은 지식 왕!`,
    char: `여러분은 진정한 '팩트 폭격기 신념이 진리!' 타입이에요! 이런 타입에게 '갈팡질팡'하는 이야기를 하면 무시당하기 쉽죠. 목표 지향적이고 원하는 것은 꼭 이뤄내는 의지가 강한 타입이지만, 인정과 친절이 부족할 때도 있어요.
가끔은 겉으로 '인싸'처럼 보여서 친해졌다고 생각하지만, 사실 이 타입은 공과 사를 철저히 구분해요. 그래서 쉽게 부탁을 들어주거나 친하다고 해서 편을 들어주는 일은 거의 없죠. 인간관계가 마치 비즈니스처럼 느껴질 때가 있어 상대방이 주눅들거나 어려워할 수도 있어요.
모든 게 당신의 이론대로 해결된다고 생각하면 안 돼요. 양심적이고 도덕적이며 정의로운 모습 대신 차갑고 냉정한 사람으로 오해받을 수 있기 때문이에요. 당신의 신념과 원칙이 긍정적으로 보일 수 있도록, 먼저 다른 사람을 마음에 품고 이해하려는 연습을 해보는 게 중요해요.`,
    result: `기억하세요! 이 타입은 '팩트 폭격기 신념이 진리' 스타일이지만, '공감 능력'과 '유연성'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 논리적 사고와 원칙을 지키는 모습은 정말 멋져요. 하지만 가끔은 다른 사람의 감정도 고려하고, 상황에 따라 유연하게 대처하는 법을 배워보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 언제나 정확한 계산으로 일을 추진하는 타입이에요. 그래서 특정 과목에서는 뛰어난 성과를 낼 수 있지만, 사람들 사이에서는 가끔 거부감을 느끼게 할 수 있어요. 공부와 인간관계 모두 인생에서 중요한 부분이라는 것을 꼭 기억하세요.
수학이나 과학 같은 논리적인 과목에서는 아주 뛰어난 실력을 발휘할 수 있어요. "선생님, 이 공식을 응용해서 다른 문제를 풀어봤는데 확인해주실 수 있나요?"라고 물어보는 것은 좋은 접근이에요. 또한, 이런 과목의 경시대회에 도전해 보는 것도 좋은 경험이 될 거예요.
조별 과제를 할 때는 효율성만을 따지기보다는 팀원들의 감정을 고려하는 것도 중요해요. "이렇게 하면 효율적일 것 같은데, 너희들 생각은 어때?"라고 물어보는 습관을 들여보세요. 학생회 활동에서도 규칙 제정이나 문제 해결 같은 일을 맡아보면 좋을 거예요. "이 규칙을 이렇게 바꾸면 더 공정할 것 같은데, 어떻게 생각하세요?"라고 제안해보세요.
토론 대회에 참가해서 논리적으로 주장을 펼치는 것도 훌륭한 기회가 될 수 있어요. 하지만 상대방의 의견도 존중하는 자세를 잊지 않는 것이 중요해요. "상대팀의 의견에도 일리가 있네요. 하지만 제 생각은..."이라고 말하는 연습을 해보세요.
감성을 키우기 위해서는 문학 동아리나 봉사 활동에도 참여해보는 걸 추천해요. "이번 주 토요일에 같이 양로원 봉사 갈래?"라고 친구들에게 제안해 보는 것도 좋은 방법이에요.`,
    human: `- 베프, 여자친구
친구나 연인을 외롭게 만들 수 있는 타입이에요. 사회적으로는 성공할 수 있는 타입이니 '엄친아/엄친딸' 원한다면 만나도 되는 타입이에요. 하지만 가끔은 "오늘 하루 어땠어?"라고 물어보는 것만으로도 관계가 따뜻해질 수 있어요.
- 선생님
선생님이 '철벽' 같은 사람이란 걸 충분히 이해하고 대응하세요. 논리적이고 체계적으로 질문하고 의견을 제시하면 좋아하실 거예요. "선생님, 이 부분에 대해 추가 자료를 찾아봤는데 의견 들어보고 싶어요."라고 말해보는 건 어떨까요?
- 선배
가능성 없다고 판단되는 후배에겐 시간 투자 안 해요. 실력으로 인정받으려 노력하되, 가끔은 인간적인 모습도 보여주세요. "선배, 이 문제 해결했어요. 혹시 조언해주실 게 더 있나요?"라고 물어보는 건 어떨까요?
- 친구, 후배
논리와 원칙으로 일 처리한다는 걸 잊지 마세요. 하지만 가끔은 감정적인 부분도 고려해주면 좋아요. "네 의견이 논리적으로는 맞아. 하지만 다른 친구들 기분은 어떨까?"라고 말해보세요.`,
  },

  ACABC: {
    type: "ACABC",
    category: "소위 나쁜 남자 유형",
    desc: `'츤데레 플렉스 마스터' 타입`,
    sub: `학교의 숨은 카리스마 킹!`,
    char: `여러분은 진정한 '츤데레 플렉스 마스터' 타입이에요! 웹툰이나 드라마에서 '차도남 신드롬' 불고 있는데 그런 타입이라고 볼 수 있어요.
자기만의 규칙과 원칙을 중요하게 여겨서 상대가 이걸 맞춰줘야 해요. 어떤 일에 자기 규칙 무시하고 감정 개입하는 걸 그냥 못 넘어가는 성격이에요. 또 쉽게 눈물 보이거나 속마음 얘기 안 해요.
'츤데레 플렉스 마스터' 타입의 장점은 매우 이성적이고 가치관 뚜렷하며 '카리스마 갑'이라 쉽게 접근 못 하는, 믿음직한 타입으로 보여요. 다만, '독재자'처럼 보이는 게 문제될 수 있으니 자기 원칙을 때론 '유연하게' 적용할 줄 아는 지혜가 필요해요.
진짜 '츤데레'가 인기 있는 이유는 겉모습과 속마음이 달라서예요. 이 타입은 남 챙기는 따뜻한 마음이 부족하니 관대한 마음과 열린 사고를 꼭 길러야 해요.`,
    result: `기억하세요! 이 타입은 '츤데레 플렉스 마스터' 스타일이지만, '따뜻함'과 '유연성'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 원칙과 카리스마는 정말 멋져요. 하지만 가끔은 마음을 열고 다른 사람의 의견도 들어보세요. 
    그리고 때로는 규칙을 조금 유연하게 적용해보는 것도 좋아요. 그러면 더 멋진 리더가 될 수 있을 거예요.`,
    school: `공부든 뭐든 어떤 일 하더라도 잘 해낼 수 있는 타입이지만 본인 실력만 믿지는 마세요. 모든 일이 계획대로만 되진 않아요.
수학이나 과학 같은 논리적인 과목에서 뛰어난 성적을 낼 수 있어요. "이 공식을 이용해서 새로운 문제를 만들어봤어요. 선생님, 확인해주실 수 있나요?"라고 말해보는 건 어때요? 이런 과목의 대회에 도전해보세요.
조별 과제할 때는 팀원들 의견도 존중하면서 효율적으로 일 분배하는 연습을 해보세요. "너희들 생각은 어때? 이렇게 나누면 우리 강점을 잘 살릴 수 있을 것 같은데."라고 말해보는 건 어떨까요?
학생회 활동에서 규율 제정이나 문제 해결 같은 일을 맡아보는 것도 좋아요. "이 규칙을 이렇게 바꾸면 더 공정할 것 같아요. 어떻게 생각해요?"라고 제안해보세요.
토론 대회에 참가해서 논리적인 주장을 펼쳐보세요. 하지만 상대방 의견도 경청하는 자세를 잊지 마세요. "상대팀 의견도 일리가 있네요. 하지만 제 생각은..."이라고 말하는 연습을 해보세요.
감성을 키우기 위해 문학 동아리나 봉사 활동에도 참여해보는 걸 추천해요. "이번 주 토요일에 같이 유기견 보호소 봉사 갈래?"라고 친구들에게 제안해보는 건 어떨까요?`,
    human: `- 베프, 여자친구
'솔로' 주의자가 많아요. 먼저 다가오지 않는 타입이니까 그 속마음 느낀다면 먼저 다가가보세요. "네가 챙겨준 거 알아. 고마워."라고 말해보는 건 어때요?
- 선생님
매우 까다로운 타입으로 무서운 선생님 같은 느낌 들 때 많을 거예요. 그래도 한번 인정받으면 완벽한 신뢰 얻을 수 있어요. "선생님, 이 부분 더 공부해보고 싶은데 추천해주실 만한 책 있나요?"라고 물어보는 건 어떨까요?
- 선배
실수 줄이려는 노력 필요해요. 계획 없이 일하거나 목적 없이 뭘 하는 걸 제일 싫어하는 타입이에요. "선배, 이 일정대로 준비해봤어요. 혹시 조언해주실 게 있나요?"라고 말해보세요.
- 친구, 후배
인싸 활동보다는 꼼꼼한 일처리가 어울리는 타입이에요. 확실한 계획 세워 행동하거나 정확한 일 맡기는 게 좋아요. "네가 맡은 부분 정말 꼼꼼하게 잘했어. 다음에도 이 부분 맡아줄래?"라고 말해보는 건 어떨까요?`,
  },

  ACACA: {
    type: "ACACA",
    category: "스트레스 억제 유형",
    desc: `'올라운더 지망생의 강제 집중모드' 타입`,
    sub: `탬탬버린처럼 흔들리는 학교생활`,
    char: `겉으로는 쿨한 척하지만, 속으로는 인정받고 싶어 하는 타입이에요.
평소엔 조금 무미건조한 '노잼' 모드지만, 한 번 꽂히면 끝까지 몰두해서 진정한 '고수'가 될 가능성이 커요. 다만 실패하면 쉽게 '멘붕'에 빠져 우울해질 때가 있어요. 그래서 스트레스가 쌓이지 않도록 친구들과 어울리며 노는 시간이 꼭 필요해요.
자기 자신을 즐길 수 있는 방법을 배우는 것이 중요해요. 'YOLO' 정신으로 살면서 사소한 것에 너무 연연하지 말고, 가끔은 '아니오'라고 말하는 연습도 해보세요. 그래야 스트레스로부터 해방될 수 있어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 잠재력을 믿어요.
    한 번 꽂히면 무서운 집중력을 보여주는 여러분, 그 에너지를 잘 관리하면 학교생활의 진정한 주인공이 될 수 있어요! 파이팅!`,
    school: `재미있는 걸 찾아 나서세요! 불안해서 뭐든 시작하기 어려울 수 있지만, '할 수 있다'는 마인드로 도전해보세요.
- '동아리 활동': 좋아하는 과목 동아리에 가입해보세요. 자신감 업그레이드에 딱이에요.
- '수업 참여': 발표? 처음엔 떨리겠지만, 작은 거부터 시작해보세요. 한 번 성공하면 자신감 뿜뿜!
- '친구 관계': 수다 떨면서 소소한 재미 찾아보세요. 우울감 바이바이~
- '상담 활용': 상담 선생님이랑 정기적으로 얘기하는 것도 좋아요.
- '스트레스 해소': 체육 시간이나 학교 명상 프로그램 참여해서 스트레스 날려버리세요.
- '공부 방법': '뽀모도로 기법' 써보세요. 25분 집중, 5분 휴식으로 효율 최고로 올릴 수 있어요!`,
    human: `- 베프, 여자친구
티격태격할 수 있어요. 이 타입은 원칙주의자라 너무 많은 걸 기대하면 안 돼요. 먼저 손 내밀어 이해해주는 게 핵심이에요.
- 선생님
시간 들인 만큼 '내 편'이 되어주는 스타일이에요. 급하게 생각하지 말고 천천히 다가가세요. 과제나 질문으로 소통을 시작해보는 게 어때요?
- 선배
쉬운 상대는 절대 아니에요. 조용해 보여도 고집 있고 계산적이에요. 예의 지키면서 천천히 친해지세요.
- 친구, 후배
친해지기 전까진 예의 잘 지켜야 해요. 한번 마음 열면 진짜 의리 있는 친구가 될 수 있어요.`,
  },

  ACACB: {
    type: "ACACB",
    category: "지나치게 신중한 유형",
    desc: `'머리 쫄깃 생각왕' 타입`,
    sub: `학교의 숨은 오버씽킹 챔피언!`,
    char: `여러분은 진정한 '머리 쫄깃 생각왕' 타입이에요! 사려 깊고 신중한 이 타입은 외로움이 자기를 '압박'하는 스타일이에요. 자신에게 엄격한 타입으로, 매사에 지나치게 생각을 많이 해서 '멘붕'오는 경우가 많아요.
일할 때 자신의 고집스러운 면이 많이 드러나고 원리원칙, 규칙을 중요시해요. 하지만 세상에는 '흑백논리'로 나눌 수 없는 것들이 너무 많다는 걸 알아야 해요. 결단력 있고 의지가 강한 건 좋지만 인생을 즐기는 여유에도 시간 투자해야 해요. 왜냐면 이 타입은 공부 중심적인 사람이 많거든요.
공부는 완벽하게 할 수 있지만 늘 '솔로' 느낌이라 인간관계가 좁을 수 있어요. 자신의 에너지를 쏟을 수 있는 취미를 만들거나 친구를 많이 사귈 수 있도록 노력해야 해요. 좋은 인간관계가 건강한 삶을 만들 수 있으니까요.`,
    result: `기억하세요! 이 타입은 '머리 쫄깃 생각왕' 스타일이지만, '긍정적 마인드'와 '균형 있는 생활'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 깊은 생각과 신중함은 정말 멋져요. 하지만 가끔은 머리를 식히고 친구들과 즐거운 시간을 보내보세요.
    그리고 모든 일에 완벽할 필요는 없다는 걸 기억하세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `너무 쉽게 포기하고 마는 당신... 조금 더 생각해보는 인내가 필요해요.
마음먹기 달렸다는 말이 있죠. '다 잘될 거야'라는 주문을 외워보세요.
어려운 과목이 있다면 바로 포기하지 말고 다양한 학습 방법을 시도해보세요. "이 과목, 유튜브로 공부하니까 좀 더 잘 이해되는 것 같아!"라고 말할 수 있을 때까지 노력해보세요.
학교 행사나 동아리 활동에 적극적으로 참여해보세요. "이번 축제 준비위원회에 지원해볼까 생각 중이야. 너도 같이 할래?"라고 친구들에게 제안해보는 건 어때요? 이는 새로운 친구를 사귀는 좋은 기회가 될 거예요.
스트레스 해소를 위해 운동이나 명상 같은 취미를 가져보는 것도 좋아요. "요즘 10분 명상 앱 쓰는데 꽤 도움 돼. 한번 써볼래?"라고 친구들에게 추천해보세요.
수업 시간에 발표를 꺼려하더라도, 작은 것부터 도전해보세요. "선생님, 이 문제에 대해 제 생각을 말해도 될까요?"라고 말해보는 건 어떨까요? 성공 경험이 자신감을 키워줄 거예요.
학교 상담 선생님과 정기적으로 대화를 나누는 것도 도움이 될 수 있어요. "선생님, 제 고민 좀 들어주실 수 있나요?"라고 말해보세요.`,
    human: `- 베프, 여자친구
내가 상대방을 변화시킬 수 있다는 '긍정 가득' 마인드로 다가서는 게 필요해요. "너의 생각이 깊은 거 알아. 그치만 가끔은 좀 더 가볍게 생각해보는 건 어때?"라고 말해보는 건 어떨까요?
- 선생님
당신의 진심을 알아주지 않는다고 '우울 모드' 켜지 마세요. 시간이 필요해요. "선생님, 제가 이해가 안 되는 부분이 있어요. 조금 더 설명해주실 수 있나요?"라고 꾸준히 물어보세요.
- 선배
쉽게 마음을 얻기 어려운 상대예요. 공부나 활동에서 인정받아야 다른 부분에서도 가까워질 수 있어요. "선배, 이 문제 어떻게 접근하셨어요? 저도 배우고 싶어요."라고 말해보세요.
- 친구, 후배
'우울잉'이 있는 사람일 수 있어요. 평소에 관심 가지고 잘 지켜봐야 하는 사람이며 실수했다고 해도 심한 자극은 '노노'예요. "너 요즘 어때? 힘든 일 있으면 언제든 얘기해."라고 말해주는 건 어떨까요?`,
  },

  ACACC: {
    type: "ACACC",
    category: "진짜 나쁜 남자 유형",
    desc: `'츤데레 플렉스 마스터' 타입`,
    sub: `학교의 숨은 카리스마 킹!`,
    char: `여러분은 진정한 '츤데레 플렉스 마스터' 타입이에요! 웹툰이나 드라마에서 '차도남 신드롬' 불고 있는데 그런 타입이라고 볼 수 있어요.
자기만의 규칙과 원칙을 중요하게 여겨서 상대가 이걸 맞춰줘야 해요. 어떤 일에 자기 규칙 무시하고 감정 개입하는 걸 그냥 못 넘어가는 성격이에요. 또 쉽게 눈물 보이거나 속마음 얘기 안 해요.
'츤데레 플렉스 마스터' 타입의 장점은 매우 이성적이고 가치관 뚜렷하며 '카리스마 갑'이라 쉽게 접근 못 하는, 믿음직한 타입으로 보여요. 다만, '독재자'처럼 보이는 게 문제될 수 있으니 자기 원칙을 때론 '유연하게' 적용할 줄 아는 지혜가 필요해요.
진짜 '츤데레'가 인기 있는 이유는 겉모습과 속마음이 달라서예요. 이 타입은 남 챙기는 따뜻한 마음이 부족하니 관대한 마음과 열린 사고를 꼭 길러야 해요.`,
    result: `기억하세요! 이 타입은 '츤데레 플렉스 마스터' 스타일이지만, '따뜻함'과 '유연성'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 원칙과 카리스마는 정말 멋져요. 하지만 가끔은 마음을 열고 다른 사람의 의견도 들어보세요.
    그리고 때로는 규칙을 조금 유연하게 적용해보는 것도 좋아요. 그러면 더 멋진 리더가 될 수 있을 거예요.`,
    school: `공부든 뭐든 어떤 일 하더라도 잘 해낼 수 있는 타입이지만 본인 실력만 믿지는 마세요. 모든 일이 계획대로만 되진 않아요.
수학이나 과학 같은 논리적인 과목에서 뛰어난 성적을 낼 수 있어요. "이 공식을 이용해서 새로운 문제를 만들어봤어요. 선생님, 확인해주실 수 있나요?"라고 말해보는 건 어때요? 이런 과목의 대회에 도전해보세요.
조별 과제할 때는 팀원들 의견도 존중하면서 효율적으로 일 분배하는 연습을 해보세요. "너희들 생각은 어때? 이렇게 나누면 우리 강점을 잘 살릴 수 있을 것 같은데."라고 말해보는 건 어떨까요?
학생회 활동에서 규율 제정이나 문제 해결 같은 일을 맡아보는 것도 좋아요. "이 규칙을 이렇게 바꾸면 더 공정할 것 같아요. 어떻게 생각해요?"라고 제안해보세요.
토론 대회에 참가해서 논리적인 주장을 펼쳐보세요. 하지만 상대방 의견도 경청하는 자세를 잊지 마세요. "상대팀 의견도 일리가 있네요. 하지만 제 생각은..."이라고 말하는 연습을 해보세요.
감성을 키우기 위해 문학 동아리나 봉사 활동에도 참여해보는 걸 추천해요. "이번 주 토요일에 같이 유기견 보호소 봉사 갈래?"라고 친구들에게 제안해보는 건 어떨까요?`,
    human: `- 베프, 여자친구
'솔로' 주의자가 많아요. 먼저 다가오지 않는 타입이니까 그 속마음 느낀다면 먼저 다가가보세요. "네가 챙겨준 거 알아. 고마워."라고 말해보는 건 어때요?
- 선생님
매우 까다로운 타입으로 무서운 선생님 같은 느낌 들 때 많을 거예요. 그래도 한번 인정받으면 완벽한 신뢰 얻을 수 있어요. "선생님, 이 부분 더 공부해보고 싶은데 추천해주실 만한 책 있나요?"라고 물어보는 건 어떨까요?
- 선배
실수 줄이려는 노력 필요해요. 계획 없이 일하거나 목적 없이 뭘 하는 걸 제일 싫어하는 타입이에요. "선배, 이 일정대로 준비해봤어요. 혹시 조언해주실 게 있나요?"라고 말해보세요.
- 친구, 후배
인싸 활동보다는 꼼꼼한 일처리가 어울리는 타입이에요. 확실한 계획 세워 행동하거나 정확한 일 맡기는 게 좋아요. "네가 맡은 부분 정말 꼼꼼하게 잘했어. 다음에도 이 부분 맡아줄래?"라고 말해보는 건 어떨까요?`,
  },

  ACBAA: {
    type: "ACBAA",
    category: "충동적인 스파크 유형",
    desc: `'꾸러기 번개맨 핵인싸' 타입`,
    sub: `학교의 숨은 에너지 뱅크!`,
    char: `여러분은 진정한 '꾸러기 번개맨 핵인싸' 타입이에요! 공격적인 면과 의존적인 면을 동시에 가진 타입이라 어디로 튈지 모르는 예측 불가의 성향이 있어요.
이성보다는 직감이 뛰어나고 감정 표현도 솔직하게 드러내요. 호기심이 넘치고 행동력도 강해요. 그런데 이런 모습 때문에 주변에서는 '자기중심적'이고 '충동적'으로 오해하며 조금 무서워할 수 있어요. 이는 당신이 이상이나 정의감을 너무 강하게 내세울 때가 있기 때문이에요.
조금 더 남을 이해하고 받아들이는 태도가 필요해요. 자기 신념을 조금만 유연하게 가져본다면, 당신을 무서워하거나 거리를 두던 사람들도 '팬'으로 만들 수 있을 거예요. 상대는 당신의 애정과 관심을 필요로 하니까, 친절을 베풀어보는 건 어떨까요?`,
    result: `기억하세요! 이 타입은 '꾸러기 번개맨 핵인싸' 스타일이지만, '이해심'과 '자제력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 에너지와 열정은 정말 멋져요. 하지만 가끔은 한 박자 쉬어가면서 다른 사람의 마음도 읽어보세요.
    그리고 때로는 충동을 조금 자제해보는 것도 좋아요. 그러면 더 멋진 리더가 될 수 있을 거예요`,
    school: `경쟁에 대해 심한 스트레스 느끼는 당신, 스트레스는 본인이 느끼는 반응에 따라 다르게 다가올 수 있어요. 친구들과의 학교생활에서 경쟁이란 또 다른 흥분되는 자극이 될 수도 있어요.
수행평가나 발표 때 자신의 독특한 아이디어를 표현해보세요. "선생님, 제가 이런 아이디어를 생각해봤는데 어떠세요?"라고 말해보는 건 어때요? 하지만 다른 친구들 의견도 존중하는 걸 잊지 마세요.
동아리 활동에서 리더 역할을 맡아보는 것도 좋아요. "우리 이번에 이런 프로젝트 해보는 건 어때?"라고 제안해보세요. 팀원들의 의견을 듣고 조율하는 연습을 할 수 있어요.
학교 축제나 체육대회 같은 행사에서 적극적으로 참여해보세요. "나 이번 축제 댄스 공연에 나가기로 했어!"라고 말할 수 있을 정도로 열정을 보여주세요. 당신의 에너지를 긍정적으로 발산할 수 있는 좋은 기회예요.
토론 대회에 참가해서 자신의 의견을 논리적으로 펼치는 연습을 해보세요. "제 의견은 이렇습니다. 하지만 상대방 의견도 존중합니다."라고 말하는 연습을 해보세요.`,
    human: `- 베프, 여자친구
상대방 마음을 잘 맞춰줘야 하는 타입이에요. "네 기분 이해해. 내가 뭘 도와줄 수 있을까?"라고 말해보는 건 어때요?
- 선생님
이 선생님은 때로 학생들에게 높은 기대치를 가지고 계실 수 있습니다. 이로 인해 가끔 의견 차이가 생길 수 있지만, 이는 교육적 열정에서 비롯된 것일 수 있습니다.
선생님과 원활한 관계를 유지하기 위해서는 열린 대화가 중요합니다. 자신의 생각이나 어려움을 표현하고 싶을 때는 먼저 '선생님, 제 의견을 말씀드려도 될까요?'와 같이 정중하게 대화의 기회를 요청해보세요.
- 선배
목표가 높고 일에 대해 즉흥적으로 지시 내리는 편이라 따라가기 힘들 때가 있어요. 현 상황을 자세히 보고하고 인내심 갖고 기다릴 수 있게 조율해야 해요. "선배, 이렇게 진행하고 있는데 어떻게 생각하세요?"라고 물어보세요.
- 친구, 후배
반에서 강한 주장 할 때가 많아요. 때론 이성적으로 보이다가도 과한 감정에 빠질 수 있어요. 진짜 마음을 읽으려고 노력해야 해요. "네 의견 정말 멋져. 근데 다른 친구들 생각은 어떨까?"라고 말해보는 건 어떨까요?`,
  },

  ACBAB: {
    type: "ACBAB",
    category: "야심만만한 유형",
    desc: `'드림 챌린저 플렉스 킹' 타입`,
    sub: `학교의 숨은 야망 부자!`,
    char: `여러분은 진정한 '드림 챌린저, 플렉스 킹' 타입이에요! 맡은 일은 최선을 다해 책임감 있게 수행하며, 마음속에는 '보스' 같은 야심을 품고 있어요. 자기 자신에 대한 믿음이 강하고 자존심도 대단한 타입이에요.
기분이 오르면 뭐든지 질러대는, 감정에 따라 행동하는 '기분파'예요. 평소에는 남들에게 친절하거나 관대하지 않지만, 기분이 좋아지면 솔직하고 적극적으로 행동하며 자신의 속마음을 드러내는 타입이에요. 뭔가에 빠지면 끝까지 몰두하며 '덕후'가 되고, 언제나 인생을 즐길 줄 아는 에너지가 넘치는 사람이에요.
하지만 '엄마' 같은 따뜻한 애정이 부족할 수 있어요. 그래서 당신을 좋아하는 사람이 예상보다 적을 수도 있어요. 좀 더 상대방의 입장에서 생각하고 행동하며, 남을 챙겨주는 모습을 보여주는 것이 중요해요. 그렇게 하면 더 많은 사람들이 당신을 따르게 될 거예요.`,
    result: `기억하세요! 이 타입은 '드림 챌린저 플렉스 킹' 스타일이지만, '배려심'과 '꾸준함'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 야망과 에너지는 정말 멋져요. 하지만 가끔은 다른 사람의 마음도 읽어보고, 한 가지 일을 끝까지 해내는 연습을 해보세요.
    그러면 더 멋진 리더가 될 수 있을 거예요. 파이팅!`,
    school: `내가 잘할 수 있는 게 뭔지 '뇌 풀가동'해서 생각해봐야 해요. 이 타입은 자기한테 안 맞는 공부를 참아가면서 할 수 있는 타입이 아니에요. 시간이 걸리더라도 본인에게 맞는 과목이나 진로를 찾는 게 무엇보다 필요해요. 에너지 넘치는 당신은 좋아하는 일에는 '올인'해서 열정을 보여줄 수 있어요.
다양한 동아리 활동을 통해 자신의 적성을 찾아보세요. "이번에 새로 생긴 로봇 동아리에 가입했어. 너무 재밌어!"라고 말할 수 있을 정도로 열정을 쏟아보세요.
수업 시간에 흥미로운 주제를 발견하면 적극적으로 질문하고 토론에 참여해보세요. "선생님, 이 부분에 대해 더 자세히 알고 싶어요. 추천해주실 책이 있나요?"라고 물어보는 건 어때요?
학교 축제나 대회에서 리더 역할을 맡아보는 것도 좋아요. "우리 이번 축제 주제를 '미래 도시'로 해보는 건 어때?"라고 제안해보세요. 당신의 에너지와 아이디어를 마음껏 발산할 수 있을 거예요.
자신만의 독특한 공부 방법을 개발해보세요. "난 수학 공식을 랩으로 만들어서 외워. 진짜 잘 외워져!"라고 말할 수 있을 정도로 재미있게 공부하는 방법을 찾으면 성적도 오를 거예요.`,
    human: `- 베프, 여자친구
어디로 튈지 모르는 상대라 항상 '긴장감 오지는' 관계가 될 수 있어요. 인내심 있고 '엄마' 같은 상대여야 이 타입과 '꿀잼' 관계 가능해요. "네 아이디어 정말 멋져. 그런데 이렇게 하면 어떨까?"라고 조언해주는 건 어때요?
- 선생님
선생님의 면밀한 분석이 필요해요. 이런 타입의 학생과 잘 지내려면 "네 생각이 참신하네. 이걸 어떻게 발전시킬 수 있을까?"라고 물어보며 그들의 아이디어를 존중해주세요.
- 선배
늘 새로운 거에 흥미 느끼니까 도전하는 걸 좋아해요. 정해진 틀 안에서 움직이는 걸 안 좋아하니 이 점 충분히 생각해두세요. "이번에 새로운 프로젝트를 시작하는데, 네 아이디어가 필요해."라고 말해보는 건 어떨까요?
- 친구, 후배
한 가지 일을 꾸준히 하는 능력이 부족해요. 인내심 키워주고 호기심도 중요하지만 감정적이 되지 않게 세심하게 지도해야 해요. 자존심 상하면 '탈주'할지도 모르니까요. "네 아이디어 정말 좋아. 이걸 꾸준히 발전시켜보자."라고 격려해주세요.`,
  },

  ACBAC: {
    type: "ACBAC",
    category: "고성능 에너지 유형",
    desc: `'올라운더 지망생 에너자이저 뿜뿜' 타입`,
    sub: `학교의 숨은 만능 엔터테이너!`,
    char: `여러분은 진정한 '올라운더 지망생, 에너자이저 뿜뿜' 타입이에요! 맡은 일은 책임감 있게 끝까지 해내며, 마음 한구석에는 '보스' 같은 야심을 품고 있어요. 자기 자신에 대한 믿음이 강하고 자존심도 대단한 편이에요. 예술적 감각이 뛰어나 예술이나 연예 쪽에서도 끼가 넘치는 타입이죠.
기분이 오르면 뭐든지 시도하는 '기분파'로, 평소에는 친절하거나 관대하지 않더라도 기분이 좋으면 속마음을 숨기지 않고 적극적으로 행동해요. 무언가에 빠지면 완전히 몰두하는 성향으로, 인생을 즐길 줄 알고 언제나 '에너지 풀충전' 상태예요. 차가우면서도 순수한 면이 매력 포인트로 작용해요.
다만, '엄마' 같은 따뜻한 애정이 부족한 타입이라 사람들에게 의외로 쉽게 다가가진 못할 수 있어요. 상대방의 입장에서 생각하고, 좀 더 남을 챙겨주는 모습을 보이면 많은 사람들에게 더 사랑받을 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 다재다능함과 넘치는 에너지는 정말 멋져요. 하지만 가끔은 한 가지에 집중해서 끝까지 해내는 연습을 해보세요.
    그리고 시간을 잘 관리하는 습관을 들이면, 여러분의 재능을 더욱 빛나게 만들 수 있을 거예요. 파이팅!`,
    school: `내가 잘할 수 있는 게 뭔지 '뇌 풀가동'해서 생각해봐야 해요. 이 타입은 자기한테 안 맞는 공부를 참아가면서 할 수 있는 타입이 아니에요. 시간이 걸리더라도 본인에게 맞는 과목이나 진로를 찾는 게 무엇보다 필요해요. 에너지 넘치는 당신은 좋아하는 일에는 '올인'해서 열정을 보여줄 수 있어요.
예술 관련 동아리나 방송반 같은 활동에 참여해보세요. "난 이번에 연극반에 들어갔어. 대본 쓰고 연기하는 게 너무 재밌어!"라고 말할 수 있을 정도로 당신의 끼를 마음껏 발산해보세요.
수업 시간에 흥미로운 주제를 발견하면 적극적으로 질문하고 토론에 참여해보세요. "선생님, 이 부분에 대해 제 생각을 말씀드려도 될까요?"라고 말해보는 건 어때요?
학교 축제나 대회에서 기획이나 공연 파트를 맡아보는 것도 좋아요. "우리 이번 축제 때 플래시몹 어때? 내가 안무 짜볼게!"라고 제안해보세요. 당신의 창의력을 발휘할 수 있는 좋은 기회예요.
자신만의 독특한 공부 방법을 개발해보세요. "난 역사 연표를 노래로 만들어서 외워. 진짜 잘 외워져!"라고 말할 수 있을 정도로 재미있게 공부하는 방법을 찾으면 성적도 오를 거예요.`,
    human: `- 베프, 여자친구
어디로 튈지 모르는 상대라 항상 '긴장감 오지는' 관계가 될 수 있어요. 인내심 있고 '엄마' 같은 상대여야 이 타입과 '꿀잼' 관계 가능해요. "네 아이디어 진짜 대박이야. 근데 이렇게 하면 더 좋을 것 같아."라고 말해주는 건 어때요?
- 선생님
선생님의 면밀한 분석이 필요해요. 이런 타입의 학생과 잘 지내려면 "네 생각이 참신하네. 이걸 어떻게 발전시킬 수 있을까?"라고 물어보며 그들의 창의성을 인정해주세요.
- 선배
늘 새로운 거에 흥미 느끼니까 도전하는 걸 좋아해요. 정해진 틀 안에서 움직이는 걸 안 좋아하니 이 점 충분히 생각해두세요. 적당히 '호감 표현'하는 것도 좋은 관계 유지에 도움 돼요. "선배 아이디어 진짜 멋져요. 저도 한번 해볼게요!"라고 말해보는 건 어떨까요?
- 친구, 후배
한 가지 일을 꾸준히 하는 능력이 부족해요. 인내심 키워주고 호기심도 중요하지만 감정적이 되지 않게 세심하게 지도해야 해요. 자존심 상하면 '탈주'할지도 모르니까요. "네 아이디어 대박이야. 이거 끝까지 해보자. 내가 도와줄게."라고 격려해주세요.`,
  },

  ACBBA: {
    type: "ACBBA",
    category: "강제와 순응 양면 유형",
    desc: `'겉바속촉 아이스핫팩' 타입`,
    sub: `학교의 숨은 이중인격 챔피언!`,
    char: `여러분은 진정한 '겉바속촉 아이스핫팩' 타입이에요! 이 타입은 한마디로 터트릴 수 없는 '폭탄'을 숨기고 있는 타입이에요. 왜냐하면 공격적인 면과 타협하고 양보하는 두 가지 면이 늘 충돌하거든요.
속으로는 '보스'처럼 밀어붙이고 싶지만 겉으로는 항상 감정 자제하고 상황에 맞춰 타협하는 일이 많아요. 그래서 남 탓하거나 환경 탓하는 경우가 종종 있어요. '폭탄'이 있어도 용기가 없어서 터트리지 못하는 타입이에요.
사람들에게 '착한 애', '칭찬 받는 애', '도덕적인 애'로 보이고 싶지만 실천을 못 해서 스스로 '멘붕'에 빠지기도 해요. 이런 '딜레마'에서 벗어나려면 일단 진짜 '나'를 찾아야 해요. 좋고 싫음의 편견 없이 먼저 남에 대한 관심과 이해를 높여야 해요. 누군가에게 헌신하고 배려할 때 당신의 올바른 신념과 가치관이 빛날 수 있어요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 깊은 생각과 배려심은 정말 멋져요. 하지만 가끔은 자신의 생각을 솔직히 표현하고, 계획한 일을 실천에 옮기는 연습을 해보세요.
    그리고 시간을 잘 관리하는 습관을 들이면, 여러분의 숨겨진 잠재력을 더욱 빛나게 만들 수 있을 거예요. 파이팅!`,
    school: `무슨 일이든 실행력이 좀 부족한 타입이에요. 의지는 강하지만 그걸 표현하는 데 고민이 많아요. 깊이 생각하는 건 좋지만 자칫 '우물쭈물'하다가 좋은 기회를 놓칠 수 있어요.
수업 시간에 발표하고 싶은 생각이 있어도 주저하지 말고 손을 들어보세요. "선생님, 제 생각을 말해도 될까요?"라고 말하는 연습을 해보세요. 작은 용기가 자신감을 키울 수 있어요.
동아리 활동에서 새로운 아이디어가 있다면 적극적으로 제안해보세요. "우리 이번에 이런 프로젝트 해보는 건 어때?"라고 말해보세요. 실패를 두려워하지 마세요.
친구들과 함께 공부할 때 자신의 의견을 분명히 말하는 연습을 해보세요. "내 생각에는 이 문제를 이렇게 풀면 좋을 것 같아."라고 말해보는 건 어떨까요?
학교 상담 선생님과 정기적으로 대화를 나누며 자신의 감정을 표현하는 연습을 해보는 것도 좋아요. "선생님, 제가 요즘 이런 고민이 있어요."라고 말하는 연습을 해보세요.`,
    human: `- 베프, 여자친구
서로 감정 쌓아두지 말고 솔직한 대화 많이 하는 게 필요해요. "너 요즘 뭔가 고민 있어 보이는데, 얘기 좀 해줄래?"라고 물어보는 건 어때요?
- 선생님
억제하고 필요한 걸 제대로 요구 안 할 수 있어요. 관계를 스스로 정리하지 않게 관심 갖고 요구사항이 뭔지 제대로 파악해야 해요. "선생님께서 원하시는 게 뭔지 정확히 말씀해 주시면 좋겠어요."라고 말해보세요.
- 선배
후배들에게 양보하고 타협하는 것 같지만 속마음은 지배적이고 자기가 원하는 대로 따라오길 바라고 있으니 상대의 숨은 마음을 잘 읽어야 해요. "선배, 진짜로 원하시는 게 뭔지 말씀해 주세요."라고 물어보는 건 어떨까요?
- 친구, 후배
스트레스 받고 있을 때 친구들이 함께 풀어줘야 해요. 억압받고 있는 마음을 끌어올려주기만 하면 예상 못 한 '대박' 결과를 낼 수 있어요. "너 요즘 힘들어 보이는데, 같이 놀러 갈래?"라고 제안해보세요.`,
  },

  ACBBB: {
    type: "ACBBB",
    category: "냉정하고 엄격한 유형",
    desc: `'츤데레 아이스 프린세스/프린스' 타입`,
    sub: `학교의 숨은 차가운 열정러!`,
    char: `여러분은 진정한 '츤데레 아이스 프린세스/프린스' 타입이에요! 이런 타입은 차갑고 인간미 없어 보이는 게 특징이에요. 쉽게 말해 '철벽' 같은 이미지가 스스로에게 마이너스가 될 수 있어요.
시간 관리에 '찐'하고 실수나 약속 어기는 걸 절대 용납 못 해요. 칭찬이나 관심, 친절에 좀 서툴러서 주변 사람들이 오해하는 경우가 종종 있어요. '꼰대' 같고 비판적이라는 인상이 결코 자신에게 득이 안 돼요.
하지만 이 타입은 실속 잘 챙기는 타입으로 어느 정도 현실적이고 합리적인 사람이에요. 무조건 자기 이득만 챙기는 건 아니고 남에 대해 적대감 갖거나 폐쇄적인 사람도 아니에요. '눈치백단'이고 타협과 양보도 적절하게 하는 편이에요. 다만, 자기만의 확고한 가치관이 때론 편견이나 선입견으로 인간관계에 문제 될 수 있다는 게 고민이에요. 좀 더 남을 이해하고 원칙보다는 상대를 지지하고 보호하는 넓은 마음이 필요해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 원칙과 시간 관리 능력은 정말 멋져요. 하지만 가끔은 마음을 열고 다른 사람의 의견도 들어보세요.
    그리고 융통성 있게 상황을 판단하는 연습을 해보세요. 그러면 더 멋진 리더가 될 수 있을 거예요. 파이팅!`,
    school: `모든 일이 뜻대로 계획처럼 될 거라 생각할 수 있어요. 마음만 먹으면 뭐든 해냈기 때문에 '고집불통'인 면이 많아요. 모든 게 이치대로 되는 건 아니니 때론 손해 보는 것도 필요해요.
조별 과제할 때 자기 의견만 고집하지 말고 다른 친구들 의견도 들어보세요. "너희 생각은 어때? 내 의견만 고집하지 않을게."라고 말해보는 건 어때요?
수업 시간에 발표할 때 칭찬하는 연습을 해보세요. "OO이 의견 정말 좋은 것 같아."라고 친구들의 좋은 점을 찾아 말해보는 거예요.
동아리 활동에서 규칙을 만들 때 융통성 있게 접근해보세요. "이 규칙, 상황에 따라 조금 바꿔볼 수 있을 것 같아."라고 말해보는 건 어떨까요? 모든 상황에 딱 맞는 규칙은 없다는 걸 기억하세요.
친구들과 약속 잡을 때 여유 시간을 두고 정하는 연습을 해보세요. "혹시 모르니까 30분 정도 여유 두고 만나자."라고 말해보세요.
학교 행사에 적극적으로 참여해 다양한 사람들과 교류하는 것도 좋아요. "이번 축제 준비위원회에 참여해볼까 생각 중이야."라고 말해보는 건 어때요?`,
    human: `- 베프, 여자친구
칭찬하는 법이 드물어서 이런 부분에 상처받지 않는 게 좋아요. "네가 직접 말하지 않아도 날 생각해주는 거 알아."라고 말해주는 건 어때요?
- 선생님
친절한 서비스 기대하기보단 원칙대로 수업 따라가는 게 좋아요. '주고받기'는 확실한 분이에요. "선생님, 규칙대로 하겠습니다. 혹시 더 조언해주실 게 있나요?"라고 물어보세요.
- 선배
후배들에게 '따뜻한 관심'을 주는 선배는 아니지만 공부로는 배울 점 있어요. 인간관계보단 공부 파트너로 생각하고 다가가는 게 좋아요. "선배, 이 부분 어떻게 공부하셨어요?"라고 물어보는 건 어떨까요?
- 친구, 후배
따지고 들거나 시비 거는 일 있으면 피하는 게 좋아요. 서로에게 '노동요' 될 수 있어요. "우리 서로 존중하면서 지내자."라고 말해보세요.`,
  },

  ACBBC: {
    type: "ACBBC",
    category: "냉담하고 독단적인 유형",
    desc: `'고집 만렙 아이스 프린세스/프린스' 타입`,
    sub: `학교의 숨은 얼음왕국 통치자!`,
    char: `여러분은 진정한 '고집 만렙 아이스 프린세스/프린스' 타입이에요! 남들이 보기엔 마치 쉬운 길을 놔두고 일부러 더 어려운 길을 선택하는 사람처럼 보여요. 세상에 대해 차갑고 엄격하며, 마치 '보스' 같은 강한 가치관을 가지고 있기 때문이에요.
자신의 목표를 이루기 위해서는 주변의 조언도 들어야 하는데, 신념이 워낙 강해서 타협하거나 양보하는 게 힘든 타입이에요. 가끔은 마음에 들지 않아도 남이 정한 것을 받아들이고, 불만이 있어도 양보하는 태도가 필요해요.
자기 체면이 중요한 것처럼 남의 체면도 존중하고 배려해야 더 넓은 인간관계를 가질 수 있어요. 또한, 스스로 '우리는 한 팀'이라는 의식을 높일 필요가 있어요. 적당히 자제하면서 상대방의 마음에 들도록 노력해보세요. 그렇게 하면 더 나은 관계와 협력의 기회를 만들 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 강한 의지와 리더십은 정말 멋져요. 하지만 가끔은 다른 사람의 의견도 들어보고, 상황에 따라 유연하게 대처하는 법을 배워보세요.
    그리고 시간을 잘 관리하는 습관을 들이면, 여러분의 능력을 더욱 빛나게 만들 수 있을 거예요. 파이팅!`,
    school: `남을 통제하려는 성향이 강해요. 배려가 더 큰 리더의 '덕목'이 될 수 있어요.
리더가 될 성향이 강하지만 자기 모습을 돌아볼 수 있는 여유를 키우세요.
조별 과제할 때 팀장 역할을 맡더라도 다른 친구들 의견을 존중하는 연습을 해보세요. "너희들 생각은 어때? 내 의견만 고집하지 않을게."라고 말해보는 건 어때요?
수업 시간에 발표할 때 다른 친구들의 의견도 칭찬하는 습관을 들여보세요. "OO이 의견 정말 좋은 것 같아. 이렇게 발전시켜볼까?"라고 말해보세요.
학생회 활동에서 규칙을 만들 때 다른 학생들의 입장도 고려해보세요. "이 규칙, 다른 친구들은 어떻게 생각할까?"라고 자문해보는 건 어떨까요?
동아리 활동에서 리더 역할을 맡아 팀원들과 소통하는 방법을 배워보세요. "우리 이번 활동, 다 같이 의견 모아서 결정해볼까?"라고 제안해보세요.
자신의 강점을 살리면서도 다른 사람들과 협력하는 방법을 찾아보세요. "내가 잘하는 부분은 이거고, 너희가 잘하는 부분은 이거니까 서로 도와가며 하자."라고 말해보는 건 어때요?`,
    human: `- 베프, 여자친구
자기를 리드해주는 상대 원하면 잘 맞을 수 있어요. 하지만 쉽게 내가 리드할 수 있는 상대는 아니란 걸 알고 사귀어야 해요. 당신의 '무조건적인' 애정이 필요해요. "네 생각 존중해. 그런데 이렇게 하면 어떨까?"라고 조심스럽게 제안해보세요.
- 선생님
친절한 서비스 기대하기보단 원칙대로 수업 따라가는 게 좋아요. '주고받기'는 확실한 분이에요. "선생님, 규칙대로 하겠습니다. 혹시 더 조언해주실 게 있나요?"라고 물어보세요.
- 선배
후배들에게 '따뜻한 관심'을 주는 선배는 아니지만 공부로는 배울 점 있어요. 인간관계보단 공부 파트너로 생각하고 다가가는 게 좋아요. "선배, 이 부분 어떻게 공부하셨어요? 저도 그렇게 해볼게요."라고 말해보세요.
- 친구, 후배
따지고 들거나 시비 거는 일 있으면 피하는 게 좋아요. 서로에게 '노동요' 될 수 있어요. "우리 서로 존중하면서 지내자. 네 의견도 중요하고 내 의견도 중요하니까."라고 말해보는 건 어떨까요?`,
  },

  ACBCA: {
    type: "ACBCA",
    category: "자기절제가 지나친 유형",
    desc: `'내면의 롤코 타는 슬픈 예스맨' 타입`,
    sub: `학교의 숨은 감정 서퍼!`,
    char: `여러분은 진정한 '내면의 롤코 타는 슬픈 예스맨' 타입이에요! 이 타입은 마음과 행동이 상황과 상대에 따라 완전 다르게 나타나는 타입이에요.
칭찬에 인색하고 남과의 관계에서 최소한의 예의만 지키고 더 이상 노력 안 해요. 다만 상식 수준에서는 논리적이고 이성적인 면도 있어서 필요하면 어느 정도 호감은 줄 수 있어요. 자신을 엄격하게 통제하면서도 남의 에너지에 편승하려는 의존적 성향도 강해서 스스로 삶을 건강하게 개척하려는 열정은 부족해요. 융통성이 없어서 이런 두 가지 마음이 극단적으로 부딪힐 때는 심한 '멘붕'에 빠지기도 해요.
수동적이 아닌 능동적 '예스맨'의 장점을 잘 살려보는 게 좋아요. 상황을 리드할 수 있는 정의로움과 책임감을 긍정적인 방향으로 키우려면 사람에 대한 자기만의 편견을 없애고 상대 입장에서 생각해보세요. 매일 하루 하나씩 관심과 애정이 담긴 따뜻한 말 전하기를 습관화하는 것도 좋아요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 섬세한 감정과 상황 판단 능력은 정말 멋져요. 하지만 가끔은 자신의 감정에 집중하고, 시간을 효율적으로 관리하는 법을 배워보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `당신이 학교생활을 잘하고 싶다면 따뜻한 마음을 먼저 키운 후에 도전해보는 게 좋아요. 단체 생활엔 어느 정도 맞지만 인간관계에 어려움을 느낄 수 있어서 혼자 할 수 있는 일에 적합해요.
조별 과제보다는 개인 과제에 더 집중해보세요. "선생님, 이번 과제는 개인으로 해도 될까요?"라고 물어보는 건 어때요?
수업 시간에 발표할 때는 미리 준비해서 자신감 있게 말하는 연습을 해보세요. "오늘은 제가 발표해보겠습니다!"라고 용기 내어 말해보세요.
동아리 활동은 소규모 그룹이나 개인 활동 중심의 동아리를 선택해보는 것도 좋아요. "독서 동아리에 가입하고 싶어요. 조용히 책 읽는 걸 좋아해서요."라고 말해보는 건 어떨까요?
학교 상담 선생님과 정기적으로 대화를 나누며 자신의 감정을 표현하는 연습을 해보세요. "선생님, 제 마음을 어떻게 표현해야 할지 모르겠어요."라고 솔직히 말해보세요.
매일 한 명의 친구에게 따뜻한 말 한마디를 건네는 '챌린지'를 시도해보는 것도 좋아요. "오늘 네 발표 정말 멋졌어!"라고 말해보는 건 어때요?`,
    human: `- 베프, 여자친구
사람 관계를 풍요롭게 해주는 따뜻함과 배려와는 거리가 먼 사람이에요. 당신에게 이걸 받아들일 수 있는 넓고 깊은 '츤데레' 마음이 있다면 도전해보세요. "네 마음 알아. 천천히 열어줘도 괜찮아."라고 말해주는 건 어때요?
- 선생님
이 타입 선생님과 수업할 때는 겉으로 보이는 모습과 속마음의 의도에 대해 집중해서 생각하고 전략을 짜야 해요. "선생님, 이 부분 좀 더 자세히 설명해주실 수 있나요?"라고 조심스럽게 물어보세요.
- 선배
당신이 이 타입을 선배로 선택할 수 있다면 거절하게 될 거예요. 어쩔 수 없는 상황이라면 이런 방법으로 적응해보는 건 어때요? 선배가 대답할 수 있는 문제들에 대해 정중하게 도움을 요청하세요. "선배, 이 문제 어떻게 푸는지 알려주실 수 있나요?"라고 말해보세요.
- 친구, 후배
강한 사람한테 약하고 약한 사람한테 강한 성향이 있어서 친구나 후배와 좋은 관계 되기 어려울 수 있지만, 상대의 기대에 좋은 모습으로 부응하려는 성향이 있으니 밝은 에너지를 충전 받을 수 있도록 따뜻한 관심을 기울여보세요. "우리 같이 공부하자! 네가 잘하는 거 나한테 알려줘."라고 말해보는 건 어떨까요?`,
  },

  ACBCB: {
    type: "ACBCB",
    category: "고집불통 유형",
    desc: `'동굴 속 아이스하트 탐험가' 타입`,
    sub: `학교의 숨은 미지의 세계 탐험가!`,
    char: `여러분은 진정한 '동굴 속 아이스하트 탐험가' 타입이에요! '얼음 심장'을 지닌 '고집불통' 탐험가예요.
자신과 남 모두에게 엄청 엄격한 기준을 요구하며 '배려'란 단어는 아예 모르는 사람이에요. 감정 표현이 '0'에 가깝고, 주체성은 약하면서도 남 비판하는 마음이 강해서 자기비하나 열등감에 빠질 확률이 엄청 높아요.
현실적인 면은 어느 정도 있어서 기본적인 사회 규칙은 지키며 살아가요. 하지만 함께 사는 인생의 즐거움은 스스로 생각해본 적이 없는 사람이에요. 더 안타까운 건 이런 삶이 본인에겐 큰 문제가 안 된다는 거예요.
일 하는 능력은 인정받고 싶은 욕구가 강해서 성과는 낼 수 있지만, 그것도 그저 자기만족에 불과해요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 책임감과 목표 지향적인 태도는 정말 멋져요. 하지만 가끔은 다른 사람들의 감정도 고려하고, 시간을 효율적으로 관리하는 법을 배워보세요.
    그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `책임감이 강해서 본인이 하고 싶은 일이 명확하다면 기회는 얻을 수 있어요. 하지만 계속 유지해 나가려면 남과의 관계 기술부터 새로 배워야 해요.
수업 시간에 발표할 때 다른 친구들의 의견도 존중하는 연습을 해보세요. "OO이 의견도 좋은 것 같아. 이렇게 합치면 어떨까?"라고 말해보는 건 어때요?
조별 과제에서 리더 역할을 맡더라도 팀원들의 아이디어를 듣고 반영하는 습관을 들이세요. "너희들 생각은 어때? 다 같이 의견 모아볼까?"라고 물어보세요.
동아리 활동에서 다른 사람들과 협력하는 방법을 배워보세요. "이번 프로젝트, 우리 역할 나눠서 해볼까?"라고 제안해보는 건 어떨까요?
학교 상담 선생님과 정기적으로 대화를 나누며 감정 표현 연습을 해보는 것도 좋아요. "선생님, 제 감정을 어떻게 표현해야 할지 모르겠어요."라고 솔직히 말해보세요.
매일 한 명의 친구에게 긍정적인 피드백을 주는 '챌린지'를 시도해보세요. "네가 오늘 발표한 거 정말 멋졌어!"라고 말해보는 건 어때요?`,
    human: `- 베프, 여자친구
이 타입은 본인이 생각하는 딱 그만큼만 움직여요. 상대가 뭘 원하는지보다 자기가 해줄 수 있는 것, 없는 것 사이에서 이성적으로 행동해요. "네가 할 수 있는 만큼만 해줘. 그걸로 충분해."라고 말해주는 건 어때요?
- 선생님
수업을 진행할 때 정확성과 책임감은 아주 높아요. 사소한 것까지 하나하나 놓치지 않고 잘 진행할 타입이에요. "선생님, 이 부분 더 자세히 설명해주실 수 있나요?"라고 물어보세요.
- 선배
융통성, 꼼수 같은 건 통하지 않는 선배예요. 맡은 일에 대해 반복적으로 확인하는 게 최고의 방법이에요. "선배, 제가 이렇게 하면 될까요? 확인 부탁드려요."라고 말해보세요.
- 친구, 후배
어떤 일이든 명확한 목표를 정해서 말해주세요. 당신이 생각하는 것 이상의 결과를 낼 수 있어요. "이 과제의 목표는 이거야. 네가 잘할 수 있을 거라 믿어."라고 말해주는 건 어떨까요?`,
  },

  ACBCC: {
    type: "ACBCC",
    category: "불평불만이 많은 유형",
    desc: `'츄릅츄릅 다크초코 고슴도치' 타입`,
    sub: `학교의 숨은 흑화 주인공!`,
    char: `여러분은 진정한 '츄릅츄릅 다크초코 고슴도치' 타입이에요! 자존심이 강하고, 차가우면서도 다크한 성향을 가진 타입이에요.
주변 사람들이 다가가려고 해도 쉽게 방법을 찾기 힘들 만큼 거리감을 느끼게 만들어요. 매사에 불만이 많아, 주변에 '노잼' 에너지를 퍼뜨리는 경우가 많아요. 세상이 자신이 원하는 대로 풀리지 않는 것을 모두 주변 탓으로 돌리며, 끊임없이 남을 비난하며 시간을 낭비할 때도 있어요.
자신감이 부족하거나 독선적인 마음으로 인해 세상 밖으로 자신을 쉽게 드러내지 못하고, 어두운 '암울 모드'에 빠져 있는 경우가 많아요. 높은 이상을 추구하며 시도는 하지만, 그 이상이 현실과 맞지 않아 실망하는 경우가 많죠.
마음 속 깊은 곳에 잠들어 있는 따뜻함과 배려심을 깨우세요. 세상에 혼자서 해낼 수 있는 일은 거의 없다는 걸 꼭 기억하고, 주변과 협력하는 방법을 찾아보세요. 따뜻함을 내보이기 시작한다면, 주변 사람들도 당신에게 좀 더 다가갈 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 예리한 문제 발견 능력은 정말 멋져요.
    하지만 가끔은 긍정적인 면도 보고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `너무 높은 기준으로 학교생활을 '저울질'하지 마세요. 당신의 좋은 능력도 기회가 주어지지 않으면 쓸모가 없어요. 선택하고 선택 받는 상황에서 어느 입장이든 거절하고 거절당할 수 있어요.
수업 시간에 발표할 때 실수를 두려워하지 말고 적극적으로 참여해보세요. "제 생각을 말씀드려도 될까요?"라고 용기 내어 말해보세요.
조별 과제에서 팀원들의 의견을 존중하고 협력하는 연습을 해보세요. "너희 의견도 들어볼게. 같이 좋은 아이디어 만들어보자."라고 말해보는 건 어때요?
동아리 활동에서 새로운 친구들을 사귀고 긍정적인 관계를 만들어보세요. "난 이런 걸 좋아해. 너는 어때?"라고 먼저 다가가보세요.
학교 상담 선생님과 정기적으로 대화를 나누며 자신의 감정을 표현하는 연습을 해보세요. "선생님, 제 마음을 어떻게 표현해야 할지 모르겠어요."라고 솔직히 말해보세요.
매일 하나씩 감사한 일을 찾아 기록하는 '챌린지'를 시도해보는 것도 좋아요. "오늘 날씨가 좋아서 기분이 좋았어."라고 적어보는 건 어떨까요?`,
    human: `- 베프, 여자친구
자기 스스로를 이끌어 가기도 벅찬 사람이에요. 당신에게 인내심과 높은 수준의 밝은 에너지가 있다면 마음의 문을 한 번 두드려보세요. "네 마음 이해해. 천천히 열어줘도 괜찮아."라고 말해주는 건 어때요?
- 선생님
긍정적인 에너지 교류와 수업 협조가 상당히 어려운 관계가 될 거예요. "선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?"라고 물어보세요.
- 선배
문제 발견하나만큼은 '갓벽'하게 해낼 거예요. 하지만 해결 능력이나 리더로서의 능력 어느 것 하나 당신이 따를 수 있는 부분은 부족한 사람이에요. "선배, 이 문제점을 발견했어요. 어떻게 해결하면 좋을까요?"라고 물어보세요.
- 친구, 후배
9명의 긍정 에너지가 1명의 부정 에너지를 이기지 못해요. 반 분위기를 생각한다면 1:1로 따로 관리가 필요한 친구예요. "너의 생각도 중요해. 우리 같이 좋은 방법을 찾아보자."라고 말해주는 건 어떨까요?`,
  },

  ACCAA: {
    type: "ACCAA",
    category: "마음속 분노가 강한 유형",
    desc: `'폭탄 탑재 피터팬 프렌즈' 타입`,
    sub: `학교의 숨은 폭발력 만렙 캐릭터!`,
    char: `여러분은 진정한 '폭탄 탑재 피터팬 프렌즈' 타입이에요! 마음속에 크고 작은 '폭탄'들을 가지고 있어요. 조금이라도 자기 욕구에 안 맞는 상황 만나면 바로 '폭발'해버려요.
'애기' 같은 성향이 강해서 상대에 따라 순하고 활발해지기도 하지만, 기본적으로 배려심과 이성적 면이 부족해서 결국엔 하고 싶은 대로 행동하고 표현해요. 더 큰 문제는 상황 수습이 어렵다는 거예요. 일단 저지르고, 화내고, 후회하고, '떼쓰기'하는 게 가장 쉬운 타입이에요. 주변 사람들이랑 좋은 관계 원하면서도 자기 통제력이 약해서 사람들을 떠나게 만들어요.
당신이 행복해지길 원한다면 주변에서 진심 어린 조언해주는 사람을 무조건 붙잡으세요. 당신이 가장 듣기 싫어하는 말 해주는 그 사람 말이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 열정과 창의력은 정말 멋져요.
    하지만 가끔은 감정을 조절하고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `당신이 가장 잘하는 게 뭔지 우선 찾아보세요.
혼자서는 어려우니 주변에서 도움 받아 목표 정하고 계획적으로 공부 전략 세우고 도전해야 해요.
수업 시간에 화가 나도 참고 차분히 선생님께 질문하는 연습을 해보세요. "선생님, 이 부분이 이해가 안 돼요. 다시 설명해주실 수 있나요?"라고 말해보는 건 어때요?
조별 과제할 때 팀원들 의견 듣고 협력하는 연습을 해보세요. "너희 생각은 어때? 내 의견만 고집하지 않을게."라고 말해보세요.
감정 조절 앱이나 명상 같은 걸로 화 다스리는 법을 배워보세요. "나 요즘 명상 앱 쓰는데 꽤 도움돼. 한번 써볼래?"라고 친구들에게 추천해보는 건 어떨까요?
상담 선생님과 정기적으로 대화하며 감정 표현 방법을 익혀보세요. "선생님, 제가 화날 때 어떻게 해야 할지 모르겠어요."라고 솔직히 말해보세요.
운동이나 취미 활동으로 스트레스를 건강하게 풀어보세요. "난 화날 때 농구하면서 푸는데 너는 어때?"라고 친구들과 대화해보는 건 어떨까요?`,
    human: `- 베프, 여자친구
계속 맞춰주고 챙겨줘야 하는 타입이에요. "네 마음 이해해. 천천히 기다릴게."라고 말해주는 건 어때요?
- 선생님
'말썽꾸러기' 어린애와 함께 있는 것 같은 기분이 들 거예요. "선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?"라고 물어보세요.
- 선배
체계적이거나 논리적이지 않고 현실적인 면도 기대하기 어려워요. "선배, 이 일 어떻게 하면 좋을까요? 제가 도와드릴게요."라고 말해보세요.
- 친구, 후배
이 친구에겐 꼭 너그러운 마음의 파트너를 하나 짝지어주세요. "우리 같이 해보자. 네 아이디어 정말 재밌어!"라고 격려해주는 건 어떨까요?`,
  },

  ACCAB: {
    type: "ACCAB",
    category: "자유로운 영혼 유형",
    desc: `'4차원 마이웨이 꼬마 도깨비' 타입`,
    sub: `학교의 숨은 이상한 나라의 앨리스!`,
    char: `여러분은 진정한 '4차원 마이웨이 꼬마 도깨비' 타입이에요! 남에 대한 관심도 없고 자기가 하고 싶은 대로만 하려는 타입이에요.
현실감이 부족해서 이치에 안 맞는 행동을 하면서도 자기 주장만 내세우는 사람이에요. 사람들 시선 끌려고 올바르지 못한 행동 하는 경우도 많아요.
의존성이 강해서 사람들이랑 관계 맺고 싶어 하지만, 자기 방식만 고집해서 결국엔 남들한테 외면당하는 결과를 초래해요.
세상의 자유로움을 '꿀잼'으로 즐기고 싶다면 남의 마음에도 귀 기울여야 해요.
이 타입의 장점은 상상력 풍부하고 창의적인 '아이디어 뱅크'라는 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 독특한 아이디어와 창의력은 정말 멋져요.
    하지만 가끔은 현실을 고려하고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 단체생활보다는 '1인 크리에이터' 같은 아이디어 제공하는 분야에서 활동하면 좋을 것 같아요. 세상은 당신이 하고 싶은 것만 할 수 있게 내버려두진 않을 거예요. 유연한 학교생활 원한다면 '돌발행동'이나 '갑질' 같은 행동을 우선적으로 버려야 해요.
수업 시간에 엉뚱한 질문하고 싶어도 참고 적절한 때를 기다려보세요. "선생님, 이 부분에 대해 조금 다른 관점에서 질문해도 될까요?"라고 말해보는 건 어때요?
조별 과제할 때 팀원들 의견도 듣고 반영하는 연습을 해보세요. "너희 생각은 어때? 내 아이디어랑 합치면 더 좋아질 것 같아."라고 말해보세요.
창의적인 아이디어를 학교 신문이나 동아리 활동에서 발휘해보세요. "이번 학교 축제 테마로 '이상한 나라의 앨리스'는 어때?"라고 제안해보는 건 어떨까요?
규칙을 지키면서도 자신의 개성을 표현하는 방법을 찾아보세요. "교복을 입되 액세서리로 나만의 스타일을 만들어볼까?"라고 생각해보세요.
상담 선생님과 정기적으로 대화하며 자기 조절 능력을 키워보세요. "선생님, 제 아이디어를 어떻게 하면 다른 사람들과 잘 공유할 수 있을까요?"라고 물어보세요.`,
    human: `- 베프, 여자친구
겉으론 어른이지만 버릇없고 자기주장만 강한 통제 안 되는 '애기' 같은 타입이에요. "네 아이디어 정말 재밌어. 근데 이렇게 하면 어떨까?"라고 제안해보세요.
- 선생님
일방적으로 자기 입장만 고수해서 논리적이고 타협적인 관계 맺기 어려워요. "선생님, 제 의견을 들어주셔서 감사해요. 선생님 의견도 듣고 싶어요."라고 말해보세요.
- 선배
어느 순간 명확한 이유도 모르고 '뒤통수' 맞을 수도 있어요. "선배, 제 아이디어가 좀 특이한가요? 어떻게 하면 더 좋아질까요?"라고 물어보세요.
- 친구, 후배
자기만의 세계에서 일 진행하고 결정할 수 있어서 집중 관심 필요해요. "네 아이디어 진짜 독특하다! 우리 같이 발전시켜볼까?"라고 말해주는 건 어떨까요?`,
  },

  ACCAC: {
    type: "ACCAC",
    category: "고집과 자만심 유형",
    desc: `'어리버리 천재 스윙스' 타입`,
    sub: `학교의 숨은 IQ 만렙 허당!`,
    char: `여러분은 진정한 '어리버리 천재 스윙스' 타입이에요! 겉모습은 어른처럼 보일지 몰라도, 생각은 단순하고 신중함이 부족한 편이에요. 그와 반대로 고집은 강하고 '내 맘대로' 성향이 강해 주변 사람들을 종종 혼란에 빠뜨리곤 해요.
상황 판단력이 부족해서 가끔 예상치 못한 실수를 하기도 하고, 즉흥적이며 자기중심적인 태도로 사람들과의 관계를 스스로 차단해버리는 경우가 많아요. 다른 사람들을 쉽게 무시하거나, 차갑게 비판하는 모습을 보일 때도 있어요.
미래에 어떤 일이 생길지에 대해서는 별로 관심이 없고, 자신이 잘났다고 생각하는 부분도 정작 그걸 인정해 줄 상대가 있어야만 가치가 있다는 걸 잘 모를 때가 있어요. 함께 살아가기 위해 가장 중요한 것은 남에 대한 배려와 따뜻한 마음을 나누는 것이라는 사실을 깨닫는 것이 중요해요. 따뜻함과 배려심을 보여준다면, 당신의 진정한 가치를 더 많은 사람들이 알아줄 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 천재성과 독특한 개성은 정말 멋져요.
    하지만 가끔은 다른 사람의 의견도 듣고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'하고 싶은 공부'와 '해야만 하는 공부' 중 어떤 게 당신의 현 상황에서 우선일까요?
물론 이 두 가지 질문에 대답하는 것조차 어려울 수 있겠지만, 학교생활에 대한 바람이 있다면 현실과 자신의 능력에 대한 정확한 판단이 먼저 이뤄진 후에 전략을 세우는 게 좋겠어요.
수업 시간에 '내가 최고'라는 태도 대신 다른 친구들 의견도 경청해보세요. "너의 생각도 들어보고 싶어. 어떻게 생각해?"라고 말해보는 건 어때요?
조별 과제할 때 팀원들과 협력하는 연습을 해보세요. "우리 각자 잘하는 부분을 맡아서 하면 어떨까?"라고 제안해보세요.
학교 규칙을 지키면서도 자신의 개성을 표현하는 방법을 찾아보세요. "교복을 입되 액세서리로 나만의 스타일을 만들어볼까?"라고 생각해보세요.
상담 선생님과 정기적으로 대화하며 자기 이해와 타인 이해 능력을 키워보세요. "선생님, 제가 어떻게 하면 친구들과 더 잘 지낼 수 있을까요?"라고 물어보세요.
다양한 동아리 활동에 참여해 여러 사람들과 소통하는 경험을 쌓아보세요. "이번에 새로 생긴 로봇 동아리에 가입해볼까 생각 중이야. 너도 같이 할래?"라고 친구들에게 제안해보는 건 어떨까요?`,
    human: `- 베프, 여자친구
이런 타입을 선택한 당신이라면 분명 이해심과 포용력이 '갓벽'한 사람일 거예요. "네 생각 존중해. 근데 이렇게 하면 어떨까?"라고 조심스럽게 제안해보세요.
- 선생님
이성적으로는 도저히 대하기 어려운 상대예요. "선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?"라고 물어보세요.
- 선배
후배들 말에 귀 기울이지 않는 선배는 리더로서 '불합격'이에요. "선배, 이 부분에 대해 제 의견을 말씀드려도 될까요?"라고 조심스럽게 물어보세요.
- 친구, 후배
쉽게 정이 가지 않는 타입이에요. "우리 서로 존중하면서 지내자. 네 생각도 중요하고 내 생각도 중요하니까."라고 말해보는 건 어떨까요?`,
  },

  ACCBA: {
    type: "ACCBA",
    category: "빙산의 일각 유형",
    desc: `'천사얼굴 츤데레 마스터' 타입`,
    sub: `학교의 숨은 이중인격 아이돌!`,
    char: `여러분은 진정한 '천사얼굴 츤데레 마스터' 타입이에요! 참 알쏭달쏭한 타입이에요. 좋은 사람인 것 같다가도 어느 순간 차갑고 '불평 9단'인 비판주의자의 부정적 에너지를 살짝 전달해요.
보이는 것보다 숨겨진 모습이 더 많고, 이와 관련된 '돌발행동'으로 상대를 당황시켜요. 이성적 판단력이 부족하고 왜곡된 권위의식으로 자기 열등감에 빠져요. 자신은 상대에게 부정적인 영향만 주면서도 이를 모르는 척하고, 정작 스스로는 좋게 평가받길 바라는, 이치에 맞지 않는 마음으로 관계를 만들려 해요.
가끔 자기 태도가 잘못됐다고 후회하기도 하지만, 한편으로는 상대 탓으로 돌리면서 소극적인 자세로 혼자서 부정적 감정을 키워요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 다면적인 성격과 숨겨진 감정들은 정말 특별해요.
    하지만 가끔은 솔직하게 자신을 표현하고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `숨겨진 부분보다 드러나는 부분이 많아야 당신에게 신뢰가 생길 수 있어요. 왜곡된 '포장'을 벗어버리고 좀 더 자신감을 갖고 있는 그대로의 모습에서 장단점을 조절해 인성 가치를 키운 후 학교생활을 시작하세요.
수업 시간에 모르는 게 있으면 솔직히 질문하는 연습을 해보세요. "선생님, 이 부분이 이해가 안 돼요. 다시 설명해주실 수 있나요?"라고 말해보는 건 어때요?
조별 과제에서 자신의 의견을 명확히 표현하되, 다른 의견도 존중해보세요. "내 생각은 이런데, 너희들 생각은 어때?"라고 말해보세요.
동아리 활동에서 자신의 장점을 살리면서 다른 친구들과 협력하는 법을 배워보세요. "내가 이 부분을 맡을게. 너는 어떤 걸 하고 싶어?"라고 물어보는 건 어떨까요?
학교 상담 선생님과 정기적으로 대화하며 자기 이해를 높여보세요. "선생님, 제가 어떻게 하면 더 솔직해질 수 있을까요?"라고 물어보세요.
매일 한 가지씩 긍정적인 면을 찾아 기록하는 '챌린지'를 해보세요. "오늘 친구가 내 발표를 칭찬해줬어. 기분이 좋았어."라고 적어보는 건 어떨까요?`,
    human: `- 베프, 여자친구
존중받고 싶어 하지만 드러내거나 요구하진 못하는 타입이에요. "네 마음 이해해. 천천히 열어줘도 괜찮아."라고 말해주는 건 어때요?
- 선생님
선생님이 당신에게 보여주는 호의적인 행동과 똑같이 대해주는 게 좋아요. "선생님, 저도 열심히 노력할게요. 도와주셔서 감사합니다."라고 말해보세요.
- 선배
모든 걸 받아들이는 것 같지만, 어른의 너그러움이나 이성적인 면에서 나오는 건 아니라는 걸 기억하세요. "선배, 제 의견을 말씀드려도 될까요?"라고 조심스럽게 물어보세요.
- 친구, 후배
조용하고 잘 따르는 것 같고 때론 친화력 있고 밝아 보이기도 하지만, 항상 자신을 억누르고 있어요. "네 생각도 중요해. 솔직하게 말해줘."라고 말해주는 건 어떨까요?`,
  },

  ACCBB: {
    type: "ACCBB",
    category: "편견과 집착 유형",
    desc: `'내 기준 만렙 판단킹' 타입`,
    sub: `학교의 숨은 드라마 제작자!`,
    char: `여러분은 진정한 '내 기준 만렙 판단킹' 타입이에요! 부적절한 언행으로 인해 친구 관계에 종종 '드라마'를 만들어내는 타입이죠.
이성적인 판단력이 부족하고 차가운 성향을 가지고 있어, 상대방에 대해 자신의 기준을 과하게 들이대며 잘못된 판단을 하고 그걸 합리화하려는 경향이 있어요. 단순한 '갑질' 의식에 빠져서, 상대방의 좋은 점보다는 나쁜 점을 끄집어내 비판하는 것을 즐길 때도 있어요.
사실 인간관계에서 적절한 '재미'와 협조성을 가지고 있음에도 불구하고, 비현실적이고 남에게 엄격한 태도가 과해서 관계를 망치기도 해요. 좀 더 현실적이고 유연한 마음가짐을 가지며, 사람들의 좋은 면을 보는 연습을 해보세요. 이렇게 한다면 인간관계에서 훨씬 더 긍정적인 변화를 이끌어낼 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 판단력과 주관은 정말 멋져요.
    하지만 가끔은 다른 사람의 의견도 들어보고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `건강하고 가치 있는 삶의 일부분으로 학교생활을 해나가고 싶다면 뭘 할 것인가 이전에 어떤 사람이 되어야 하는가에 초점을 맞춰 인성을 성숙시킨 후에 진로를 선택해야 해요.
수업 시간에 다른 친구들의 의견도 존중하는 연습을 해보세요. "너의 생각도 들어보고 싶어. 어떻게 생각해?"라고 말해보는 건 어때요?
조별 과제할 때 팀원들의 장점을 찾아 칭찬해보세요. "네가 이 부분을 맡아줘서 정말 도움이 됐어. 고마워!"라고 말해보세요.
학교 봉사활동에 참여해 다양한 사람들을 이해하는 경험을 쌓아보세요. "이번 주 토요일에 같이 양로원 봉사 갈래?"라고 친구들에게 제안해보는 건 어떨까요?
상담 선생님과 정기적으로 대화하며 자기 이해와 타인 이해 능력을 키워보세요. "선생님, 제가 어떻게 하면 다른 사람들과 더 잘 지낼 수 있을까요?"라고 물어보세요.
매일 한 가지씩 감사한 일을 찾아 기록하는 '챌린지'를 시도해보세요. "오늘 친구가 내 발표를 칭찬해줬어. 정말 고마웠어."라고 적어보는 건 어떨까요?`,
    human: `- 베프, 여자친구
무한 배려심과 균형 있는 현실감을 가진 사람이 아니라면 이 타입의 상대와는 관계를 시작하는 게 매우 어려워요. "네 생각을 존중해. 하지만 다른 사람의 입장도 생각해보면 어떨까?"라고 말해보세요.
- 선생님
수업에서 성과를 내기에는 당신이 신경 써야 할 부분이 공부 외에도 상당히 많아요. "선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?"라고 물어보세요.
- 선배
이런 타입이 학교생활에서 리더 역할을 하기엔 현실적으로 어려워요. "선배, 이 부분에 대해 제 의견을 말씀드려도 될까요?"라고 조심스럽게 물어보세요.
- 친구, 후배
개인, 반 사이의 갈등을 일으킬 수 있는 사람이에요. "우리 서로 존중하면서 지내자. 네 생각도 중요하고 다른 친구들 생각도 중요해."라고 말해보는 건 어떨까요?`,
  },

  ACCBC: {
    type: "ACCBC",
    category: "외로움과 불행 자초 유형",
    desc: `'홀로 쿨한 아싸 킹' 타입`,
    sub: `학교의 숨은 독고다이 챔피언!`,
    char: `여러분은 진정한 '홀로 쿨한 아싸 킹' 타입이에요! 비뚤어진 정의감으로 무장한 '독불장군' 같은 성향을 가지고 있어요.
남의 비판이나 충고를 받아들이는 능력이 부족하고, 자기만의 잘못된 판단으로 상대를 일방적으로 비판할 때도 있어요. 자신의 생각을 표현하는 방식이 서툴러서, 이를 감추기 위해 거칠게 표현하다가 상대방에게 상처를 주곤 해요. 더 큰 문제는 그에 대한 문제의식이 없어서, 사람들과의 교류가 굉장히 어려워질 때가 있다는 거예요.
밝은 에너지는 있지만 신중함과 상황 대처 능력이 부족해, '꿀잼' 분위기로 에너지를 쏟기보다는 오히려 상대방을 불편하게 만드는 경우가 많아요. 사람들과 좀 더 긍정적으로 소통하고, 표현 방식을 부드럽게 다듬어 보는 연습을 해보면 더 나은 관계를 만들어갈 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 독특한 개성과 주관은 정말 멋져요.
    하지만 가끔은 다른 사람의 의견도 들어보고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `세상을 마음대로 '휘젓고' 다니고 싶어 할 타입이에요. 하지만 비상식적인 행동과 부정적인 영향력으로 가득 찬 사람에게 어떤 기회가 주어질 수 있을까요? 스스로가 세상과 어울려 살 수 있는 사람인지 아닌지에 대해 생각해 본 후 전략을 세우세요.
수업 시간에 다른 친구들의 의견도 존중하는 연습을 해보세요. "너의 생각도 들어보고 싶어. 어떻게 생각해?"라고 말해보는 건 어때요?
조별 과제할 때 팀원들과 협력하는 방법을 배워보세요. "우리 각자 잘하는 부분을 맡아서 하면 어떨까?"라고 제안해보세요.
학교 규칙을 지키면서도 자신의 개성을 표현하는 방법을 찾아보세요. "교복을 입되 액세서리로 나만의 스타일을 만들어볼까?"라고 생각해보세요.
상담 선생님과 정기적으로 대화하며 자기 이해와 타인 이해 능력을 키워보세요. "선생님, 제가 어떻게 하면 친구들과 더 잘 지낼 수 있을까요?"라고 물어보세요.
다양한 동아리 활동에 참여해 여러 사람들과 소통하는 경험을 쌓아보세요. "이번에 새로 생긴 댄스 동아리에 가입해볼까 생각 중이야. 너도 같이 할래?"라고 친구들에게 제안해보는 건 어떨까요?`,
    human: `- 베프, 여자친구
상대에게 쉽게 치유되기 힘든 마음의 상처 주는 게 익숙한 사람이에요. "네 말이 때로는 상처가 될 수 있어. 조금만 더 부드럽게 표현해줄 수 있을까?"라고 말해보세요.
- 선생님
다 같이 좋아보자고 시작한 수업이 서로를 '멘붕'에 빠뜨리는 상황이 될 수 있어요. "선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?"라고 물어보세요.
- 선배
후배들이 믿고 따르기엔 여러모로 실망스러운 선배예요. "선배, 이 부분에 대해 제 의견을 말씀드려도 될까요?"라고 조심스럽게 물어보세요.
- 친구, 후배
이 타입은 누구와도 '케미'가 안 맞는 사람이에요. "우리 서로 존중하면서 지내자. 네 생각도 중요하고 다른 친구들 생각도 중요해."라고 말해보는 건 어떨까요?`,
  },

  ACCCA: {
    type: "ACCCA",
    category: "스스로를 억압하는 유형",
    desc: `'내적 갈등 만렙 슬픈 잼민이' 타입`,
    sub: `학교의 숨은 감정 롤러코스터!`,
    char: `여러분은 진정한 '내적 갈등 만렙 슬픈 잼민이' 타입이에요! 이 타입은 스스로를 점점 '우울 모드'로 만들어요. 서로 반대되는 성향이 극단적으로 높아서 두 가지 마음 사이에서 끊임없이 '고민 타임'을 가져요.
자존심 강하고 비판적이어서 남에 대한 자기 주장을 표현하고 싶지만, 반대로 남의 시선에 '눈치 백단'이고 맞추려는 에너지도 강해서 결국엔 자기 감정을 억누르며 수동적인 태도를 보여요. 이때 모든 스트레스가 내면에 쌓이는데, 이런 자기 감정을 제대로 이해하는 이성과 자신을 보듬으려는 의지도 약해서 항상 '불안 MAX'이고 어두워요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 섬세한 감정과 책임감은 정말 멋져요.
    하지만 가끔은 자신의 감정에 집중하고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `학교생활의 기본이자 핵심 에너지는 친구관계예요.
당신이 학교생활을 잘하고 싶다면 지금 당신 주변에서 당신에 대한 평가를 한 번 들어보세요. 당신의 밝은 에너지와 올바른 희생정신이 살아나지 않는 한 누구도 당신을 곁에 두고 싶어 하지 않을 거예요.
수업 시간에 자신의 의견을 조금씩 표현하는 연습을 해보세요. "선생님, 이 부분에 대해 제 생각을 말해도 될까요?"라고 말해보는 건 어때요?
조별 과제에서 맡은 역할을 책임감 있게 수행하면서도 팀원들과 소통해보세요. "내가 이 부분을 맡을게. 너희들 의견도 듣고 싶어."라고 말해보세요.
동아리 활동에서 자신의 장점을 살리면서 다른 친구들과 협력하는 법을 배워보세요. "내가 잘하는 부분은 이거야. 너희는 어떤 걸 잘해?"라고 물어보는 건 어떨까요?
학교 상담 선생님과 정기적으로 대화하며 자기 이해를 높여보세요. "선생님, 제가 어떻게 하면 제 감정을 더 잘 이해할 수 있을까요?"라고 물어보세요.
매일 한 가지씩 자신의 긍정적인 면을 찾아 기록하는 '챌린지'를 해보세요. "오늘 나는 친구의 고민을 잘 들어줬어. 이게 내 장점이야."라고 적어보는 건 어떨까요?`,
    human: `- 베프, 여자친구
떠나고 싶어도 쉽게 발이 떨어지지 않아요. "너의 감정을 이해해. 하지만 우리 둘 다 행복해질 수 있는 방법을 찾아보자."라고 말해보는 건 어때요?
- 선생님
수업은 수업이에요. 서로 이해관계가 맞는 선에서 최선을 다하세요. "선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?"라고 물어보세요.
- 선배
이런 선배 밑에서 당신이 제대로 된 학교생활을 한다는 건 정말 어려운 일이에요. "선배, 제가 어떻게 하면 더 도움이 될 수 있을까요?"라고 조심스럽게 물어보세요.
- 친구, 후배
주어지는 역할과 일에 대해 하나부터 열까지 아주 자세하게 알려준다면 결과는 걱정하지 않아도 돼요. "우리 같이 천천히 해보자. 네가 할 수 있는 만큼만 해."라고 말해주는 건 어떨까요?`,
  },

  ACCCB: {
    type: "ACCCB",
    category: "욕심 과다 빈수레 유형",
    desc: `'허세 만렙 현실 구독 취소' 타입`,
    sub: `학교의 숨은 꿈과 현실 사이 서퍼!`,
    char: `여러분은 진정한 '허세 만렙 현실 구독 취소' 타입이에요! 이 타입은 남의 삶에 대해 "그 정도? 나도 가능!"이란 '오버' 자세로 평가해요. 자기 내면 에너지가 어떤지도 모르면서 엄격하고 편협한 '잣대'로 남을 분석하고 비판해요.
이 타입은 차가운 마음과 부정적 태도, 위축된 마음으로 자기 내면을 강하게 억눌러서 뭔가를 이루려는 의욕이 낮아요. 하지만 자기 과시와 남의 기대에 부응하려는 욕구도 있어서 이런 모습은 무시한 채 어설픈 '쇼' 인생을 선택해버려요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 높은 이상과 꿈은 정말 멋져요.
    하지만 가끔은 현실을 직시하고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'오만한' 당신의 마음과 행동을 낮추고 적극적인 도전 정신을 발휘하지 않으면 학교생활은 어려워요. 말보다는 행동이 우선이고 행동보다는 마음의 올바른 태도가 우선이에요.
수업 시간에 모르는 걸 솔직히 질문하는 연습을 해보세요. "선생님, 이 부분이 잘 이해가 안 돼요. 다시 설명해주실 수 있나요?"라고 말해보는 건 어때요?
조별 과제에서 실제로 할 수 있는 역할을 맡고 책임감 있게 수행해보세요. "내가 이 부분을 맡을게. 끝까지 책임지고 할 거야."라고 말해보세요.
동아리 활동에서 자신의 실제 능력을 발휘하면서 다른 친구들과 협력하는 법을 배워보세요. "나는 이걸 잘하니까 이 부분을 맡을게. 너희들은 어떤 걸 잘해?"라고 물어보는 건 어떨까요?
학교 상담 선생님과 정기적으로 대화하며 현실적인 자기 이해를 높여보세요. "선생님, 제가 실제로 잘하는 게 뭔지 모르겠어요. 어떻게 알 수 있을까요?"라고 물어보세요.
매일 한 가지씩 작지만 실천 가능한 목표를 세우고 달성해보세요. "오늘은 수학 문제 5개를 풀겠어."라고 목표를 세우고 실천해보는 건 어떨까요?`,
    human: `- 베프, 여자친구
상대의 높은 이상 추구를 이해하기 어려우면 일찍 포기하는 게 좋아요. "네 꿈은 멋져. 하지만 우리 현실적으로 생각해보는 건 어때?"라고 말해보세요.
- 선생님
이런 선생님과 좋은 관계를 유지하고 수업에 더 잘 참여하기 위해서는, 적극적인 자세로 접근하는 것이 도움이 될 수 있습니다. 예를 들어, '선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?'라고 물어보는 것은 매우 좋은 방법입니다.
이러한 질문은 여러분의 학습 의지를 보여주고, 선생님과의 소통을 원활하게 만들 수 있습니다. 또한, 이를 통해 선생님의 기대사항을 더 잘 이해하고, 수업에 더욱 효과적으로 참여할 수 있는 방법을 찾을 수 있을 것입니다.
- 선배
별 거 아닌 작은 일을 크게 부풀리는 경향이 있어요. "선배, 이 정도면 괜찮지 않을까요? 너무 걱정하지 마세요."라고 말해보는 건 어떨까요?
- 친구, 후배
단순한 일, 또는 소규모 활동 환경에 맞는 사람이에요. "우리 이 작은 프로젝트부터 시작해볼까? 네가 잘할 수 있을 거야."라고 말해주세요.`,
  },

  ACCCC: {
    type: "ACCCC",
    category: "오만한 비평가 유형",
    desc: `'혼자놀이 마스터 디스 장인' 타입`,
    sub: `학교의 숨은 외로운 비평가!`,
    char: `여러분은 진정한 '혼자놀이 마스터 디스 장인' 타입이에요! 이 타입은 무인도에서도 살아가기 힘든 사람이에요. 무인도의 동물들도 다 도망갈 거예요.
계속 남에게 부정적이고 독선적인 태도로 '분위기 킬러'가 되는 게 자신의 존재 가치를 알리는 방법이라고 생각하는 사람이에요. 남을 이해하려는 마음은 '제로'인 상태에서 자신의 권위적이고 비논리적인 '진리'만 강요하는 사람이에요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 열정과 다재다능함은 정말 멋져요. 
    하지만 가끔은 한 가지에 집중하고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `아무도 이런 성격의 사람을 받아줄 곳이 없어요.
당신이 진짜로 학교의 구성원으로 활동하길 원한다면 자기 자신을 관리하는 방법부터 배워나가야 해요.
수업 시간에 남의 의견을 비난하지 않고 경청하는 연습을 해보세요. "너의 생각도 들어보고 싶어. 어떻게 생각해?"라고 말해보는 건 어때요?
조별 과제에서 팀원들과 협력하는 방법을 배워보세요. "우리 각자 잘하는 부분을 맡아서 하면 어떨까?"라고 제안해보세요.
동아리 활동에서 다른 사람들의 장점을 찾아 칭찬하는 습관을 들여보세요. "네가 이 부분을 맡아줘서 정말 도움이 됐어. 고마워!"라고 말해보는 건 어떨까요?
학교 상담 선생님과 정기적으로 대화하며 자기 이해와 타인 이해 능력을 키워보세요. "선생님, 제가 어떻게 하면 친구들과 더 잘 지낼 수 있을까요?"라고 물어보세요.
매일 한 가지씩 긍정적인 생각을 하고 기록하는 '챌린지'를 해보세요. "오늘 친구가 내 발표를 칭찬해줬어. 정말 기분 좋았어."라고 적어보는 건 어떨까요?`,
    human: `- 베프, 여자친구
남과의 애정 관계엔 관심도 없어요. "우리 서로의 장점을 찾아보는 게 어때?"라고 제안해보세요.
- 선생님
이 선생님의 수업 방식이 여러분과 잘 맞지 않을 수 있습니다. 학습 스타일과 교육 방식의 차이로 인해 어려움을 느낄 수 있죠. 이런 상황에서는 신중하게 상황을 평가해보는 것이 중요합니다.
우선, 개선의 여지가 있는지 확인해보세요. '선생님, 제가 어떻게 하면 수업에 더 잘 참여할 수 있을까요?'라고 정중하게 물어보는 것은 좋은 시작점이 될 수 있습니다. 이를 통해 선생님의 기대사항을 이해하고, 수업 참여 방식을 개선할 수 있는 기회를 얻을 수 있습니다.
만약 여러 노력에도 불구하고 상황이 개선되지 않는다면, 다른 수업 옵션을 고려해보는 것도 하나의 방법일 수 있습니다. 교육적 필요와 개인의 성장에 가장 도움이 되는 선택을 하는 것이 중요합니다.
- 선배
당신이 미처 모르고 있는 당신의 부정적인 면에 대해서만큼은 확실히 알 수 있을 거예요. "선배, 제가 개선해야 할 점이 있다면 말씀해주세요."라고 말해보는 건 어떨까요?
- 친구, 후배
자기 말과 행동이 남의 마음에 상처 준다는 걸 전혀 인식 못 하는 사람이에요. "우리 서로 존중하면서 지내자. 네 생각도 중요하고 다른 친구들 생각도 중요해."라고 말해보세요.`,
  },

  BAAAA: {
    type: "BAAAA",
    category: "산만한 멀티플레이어 유형",
    desc: `'올라운더 지망생 집중력 제로' 타입`,
    sub: `학교의 숨은 에너지 뱅크!`,
    char: `여러분은 진정한 '올라운더 지망생 집중력 제로' 타입이에요! 이 타입은 하루 24시간이 모자랄 정도로 바빠요. 하고 싶은 것도 많고 해야 할 것도 진짜 많은 사람이에요. 의욕이 '풀충전'이라 자기 한계가 어딘지도 모르고 계속 뛰어다녀요.
남의 삶에는 활기 넘치는 긍정 에너지를 주는 사람이지만, 정작 자기 삶에서는 제대로 된 '성과' 하나 얻기 어려워요. 이 타입에게 가장 아쉬운 건 모든 걸 자기가 아닌 남을 성장시키는 데 다 써버린다는 거예요.`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 여러분의 열정과 다재다능함은 정말 멋져요.
    하지만 가끔은 한 가지에 집중하고, 시간을 효율적으로 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `집중력과 꾸준한 추진력을 갖추면 당신은 어디서든 가치 있는 존재로 인정받을 수 있어요. 철저한 시간 관리와 해야 할 일들에 대한 전략적인 계획을 세우는 게 좋아요.
시간표를 만들어 공부와 활동 시간을 효율적으로 관리해보세요. "오늘은 수학 2시간, 영어 1시간, 동아리 활동 1시간으로 정했어!"라고 말하는 습관을 들여보세요.
하나의 과제나 활동에 집중하는 '뽀모도로 기법'을 활용해보세요. "25분 동안 수학 문제 풀고 5분 쉬기, 이걸 4번 반복할 거야!"라고 계획을 세워보는 건 어때요?
동아리 활동을 한두 개로 줄이고 그 안에서 깊이 있는 활동을 해보세요. "이번 학기엔 로봇 동아리에 집중해서 대회 나가볼 거야."라고 목표를 세워보세요.
매일 밤 내일의 할 일 목록을 만들고 우선순위를 정해보세요. "내일은 영어 과제가 제일 중요해. 그 다음은 과학 실험 준비!"라고 말하는 습관을 들여보세요.
한 달에 한 번씩 자신의 목표를 점검하고 조정하는 시간을 가져보세요. "이번 달엔 영어 성적이 많이 올랐어. 다음 달엔 수학에 좀 더 집중해볼까?"라고 스스로에게 물어보세요.`,
    human: `- 베프, 여자친구
당신의 열정과 체력이 받쳐준다면 잘 어울리는 타입이에요. "너의 에너지가 정말 대단해! 근데 가끔은 쉬어가는 것도 좋을 것 같아."라고 말해주는 건 어때요?
- 선생님
일을 벌이는 건 아주 능숙해요. "선생님, 이 프로젝트 어떻게 진행하면 좋을까요? 제가 계획을 세워볼게요."라고 말해보세요.
- 선배
추진력 있게 다양한 일들을 진행하지만 '용두사미'가 될 가능성이 커요. "선배, 이 활동 끝까지 해보고 싶어요. 도와주실 수 있나요?"라고 말해보는 건 어떨까요?
- 친구, 후배
작은 규모의 프로젝트라면 이런 유형의 친구는 말 그대로 '일당백'의 역할을 충실히 해낼 거예요. "네 아이디어 정말 좋아! 우리 이걸 끝까지 해보자."라고 격려해주세요.`,
  },

  BAAAB: {
    type: "BAAAB",
    category: "긍정 에너지 충만 유형",
    desc: `'인생 주인공 뿜뿜 올라운더' 타입`,
    sub: `학교의 숨은 만능 엔터테이너!`,
    char: `와우! 여러분은 진짜 만능 엔터테이너예요! 어떤 분야든 척척 해내는 슈퍼 긍정 에너지의 소유자랍니다.
친구들을 배려하는 마음, 현실적인 감각, 분위기 메이커 능력, 리더십까지 - 모든 게 완벽 그 자체! 마치 학교 드라마의 주인공 같은 느낌이죠. 이런 유형은 정말 흔치 않아요.
솔직하게 테스트에 임했다면, 축하해요. 여러분은 특별한 사람이에요!`,
    result: `기억하세요! 이 타입은 '인생 주인공 뿜뿜 올라운더' 스타일로 사람을 보는 눈이 있다면 누구라도 탐낼 만 한 인재예요, 현 상황에 안주하려 하지말고 하고 싶은 것에 대한 목적의식을 분명히 하여 도전하면 반드시 기회가 열리는 타입이예요!
    하지만 주의해야 할 사항은 환경이 만드는 오만함이 생길 수 있으니 항상 겸손함을 잃지 마세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `선생님들이 눈여겨보는 학생이에요! 기회는 항상 여러분 곁에 있답니다. 하고 싶은 것이 있다면 주저하지 말고 도전해보세요. 공부, 운동, 예술 활동 등 어떤 분야든 잘 해낼 수 있어요.
예를 들어, 수학 경시대회에 나가보는 건 어떨까요? "이번 수학 경시대회, 제가 한번 도전해볼게요!"라고 선생님께 말씀드려보세요.
학생회 활동에도 적극적으로 참여해보세요. "우리 이번 축제, 이렇게 기획해보면 어떨까요?"라고 아이디어를 제안해보는 건 어때요?
동아리 활동에서도 리더십을 발휘해보세요. "이번 프로젝트, 제가 총괄해볼게요. 모두의 의견을 듣고 싶어요!"라고 말해보세요.
다만 주의할 점은 '나는 뭐든 잘해'라는 생각에 빠지지 않는 거예요. 겸손함을 잃지 않도록 노력해보세요.`,
    human: `- 베프, 여자친구
축하해요! 정말 좋은 친구를 만났네요. "네가 있어서 정말 좋아. 근데 가끔은 내 얘기도 들어줘!"라고 솔직하게 말해보세요.
- 선생님
와! 이런 선생님과 함께라면 수업이 즐거울 거예요. "선생님, 이 부분 더 자세히 알고 싶어요. 추천해주실 책 있나요?"라고 물어보세요.
- 선배
멋진 선배를 만났네요! "선배, 저도 선배처럼 되고 싶어요. 조언 좀 해주세요!"라고 말해보는 건 어떨까요?
- 친구, 후배
다재다능한 친구네요! "네 아이디어 정말 멋져! 우리 같이 발전시켜볼까?"라고 말해주세요.`,
  },

  BAAAC: {
    type: "BAAAC",
    category: "모험을 즐기는 유형",
    desc: `불도저 모험가 타입`,
    sub: `학교의 인디애나 존스!`,
    char: `와! 당신은 진정한 '할 말은 하는' 타입이에요! 하고 싶은 건 꼭 해내는 열정 가득한 모험가랍니다. 하지만 걱정 마세요, 남에게 피해 주는 스타일은 아니에요. 마치 신나는 놀이동산에 온 것처럼 밝은 에너지가 넘치고, 틀에 박힌 건 질색이에요. 고집도 있어서 한번 마음먹은 건 꼭 해내려고 해요. 실패해도 괜찮아요, 경험이 중요하니까요!
좋은 소식은 머리도 좋아서 위험한 일은 안 한다는 거예요. 친구들 사이에서도 인기 많고 믿음직한 편이에요. 자기 관리도 잘하고 정신력도 강해요. 진짜 '쿨내나는' 타입이랄까요?`,
    result: `기억하세요! 이 타입은 '모험을 즐기는 에너지 폭발' 스타일이지만, '계획성'과 '인내심'을 기르면 진짜 '학교의 주인공'이 될 수 있어요!
    당신의 열정과 창의력을 믿어요. 모험을 즐기면서도 책임감 있게 행동하세요. 파이팅!`,
    school: `교실에만 앉아있기엔 너무 아까운 타입이에요! 창의력이 넘치고 아이디어가 풍부해서 다양한 활동에 참여하면 좋아요. 동아리 활동, 학생회, 봉사활동 등에서 리더 역할을 맡아보는 건 어떨까요?
수업 시간에는 발표나 토론에서 빛을 발할 거예요. 하지만 가만히 앉아서 하는 공부가 어려울 수 있어요. 짧은 시간 동안 집중해서 공부하고, 자주 휴식을 취하는 방식으로 공부해보세요.
진로를 고민할 때는 다양한 경험을 쌓을 수 있는 직업을 생각해보면 좋아요. 기자, 여행 가이드, 연구원, 크리에이터 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
우와, 당신과 함께라면 매일이 새로운 모험 같을 거예요! 밝고 경쾌한 에너지가 넘치는 사람이라면 정말 좋은 인연이 될 수 있어요. 하지만 가끔은 예상치 못한 일이 일어날 수 있으니, 서로 이해하고 배려하는 마음을 가지세요.
- 선생님
이런 선생님과 함께라면 수업이 정말 재미있을 거예요! 하지만 가끔 예상치 못한 행동을 하실 수 있어요. 괜찮아요, 그건 그냥 선생님의 매력이에요. 수업에 적극적으로 참여하고, 선생님의 감성적인 면을 이해하려 노력하면 좋은 관계를 만들 수 있을 거예요.
- 선배
있는 그대로의 당신을 보여주세요. 이 타입의 선배는 진실된 모습을 높이 평가해요. 부족한 점이 있다면 솔직히 말하세요. 기꺼이 도와줄 거예요. 하지만 불공정한 행동은 절대 용납하지 않으니 주의하세요!
- 친구, 후배
호기심 많고 모든 것에 관심 있는 친구예요. 가끔 다른 친구들이 질투할 수도 있지만, 성격이 좋아서 금방 없어질 거예요. 새로운 일을 시작할 때 제일 먼저 떠오르는 친구가 될 수 있어요. 하지만 가끔은 자기 역할을 넘어서려고 할 수 있으니, 적절한 선을 지키는 게 중요해요.`,
  },

  BAABA: {
    type: "BAABA",
    category: "타인 존중과 배려 유형",
    desc: `배려왕 슈퍼히어로 타입`,
    sub: `학교의 숨은 MVP!`,
    char: `와우! 당신은 진정한 '인간 구름빵' 타입이에요! 친구들을 아끼고 존중하며, 모두가 행복하게 지낼 수 있도록 노력하는 슈퍼히어로 같은 존재예요. 문제 해결 능력도 뛰어나서 어떤 과제든 깔끔하게 마무리하는 편이죠.
하지만 가끔 자신의 감정을 숨기고, 다른 사람들의 시선을 너무 신경 쓰는 경향이 있어요. 이런 마음 때문에 당신의 멋진 재능이 충분히 드러나지 않을 때가 있어요. 자신을 믿고, 자신만의 삶을 살아가다 보면 누구보다 빛나는 '메인 캐릭터'가 될 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '배려 만렙 올라운더' 스타일이지만, '자기주장'과 '자존감'을 기르면 진짜 '학교의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 뛰어난 능력을 믿어요. 다른 사람을 배려하면서도 자신의 꿈을 향해 당당히 나아가세요. 파이팅!`,
    school: `여러분은 어떤 반에서든 인기 만점일 거예요! 과제나 팀 프로젝트에서도 뛰어난 능력을 보여줄 수 있어요. 하지만 자신의 의견을 말하는 것을 두려워하지 마세요.
수행평가나 발표 때 긴장되더라도, 여러분의 능력을 믿고 도전해보세요. 동아리 활동이나 학생회에서 리더 역할을 맡아보는 것도 좋아요. 다른 사람을 도와주는 것만큼 자신의 꿈을 향해 노력하는 것도 중요해요.
진로를 정할 때는 다른 사람의 의견에만 의존하지 말고, 자신이 정말 좋아하는 것을 선택하세요. 상담 선생님이나 부모님과 이야기를 나누되, 최종 결정은 여러분이 해야 해요.`,
    human: `- 베프, 여자친구
우와, 정말 좋은 인연을 만났네요! 배려심 넘치고 합리적인 당신과 함께라면 어떤 어려움도 이겨낼 수 있을 거예요. 하지만 가끔은 자신의 감정도 솔직히 표현해보세요. 진정한 친구관계나 연애는 서로를 있는 그대로 받아들이는 거예요.
- 선생님
이런 선생님과 함께라면 학교생활이 즐거울 거예요! 서로 신뢰를 쌓아가면서 좋은 관계를 만들어보세요. 질문이 있거나 도움이 필요하면 언제든 말씀드려도 좋아요. 선생님은 여러분의 노력을 알아주실 거예요.
- 선배
와, 정말 든든한 선배를 만났네요! 학교생활에 어려움이 있다면 관심을 가지고 도와줄 거예요. 여러분이 열심히 한 일에 대해서도 제대로 평가해줄 거예요. 하지만 너무 의지하지 말고, 자신의 길을 찾아가는 것도 잊지 마세요.
- 친구, 후배
여러분은 정말 믿음직한 친구예요! 어떤 일을 맡아도 잘 해낼 수 있을 거예요. 하지만 친구들이 너무 많은 것을 요구하면 '아니오'라고 말하는 것도 배워야 해요. 자신을 소중히 여기는 것도 중요하답니다.`,
  },

  BAABB: {
    type: "BAABB",
    category: "평범하고 무난한 유형",
    desc: `찰떡 공감 엄친아 타입`,
    sub: `학교의 만능 플레이어!`,
    char: `여러분은 진정한 '엄친아'예요! 어디에 있어도 잘 적응하고, 배려심 넘쳐서 모두에게 사랑받는 타입이에요. 머리도 좋고 공감 능력도 뛰어나서 어떤 문제든 깔끔하게 해결할 수 있어요.
친구 관계를 정말 중요하게 생각하고, 한번 맺은 인연은 끝까지 책임지는 의리파예요. 자기 역할을 잘 알고 있고, 책임감도 강해서 친구들이나 선생님들에게 인정받는 스타일이에요.
미래를 위해 꾸준히 준비한다면, 여러분은 분명 멋진 미래를 만들어갈 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '만능 엄친아' 스타일이지만, '자기 주도성'과 '목표 설정 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 뛰어난 적응력과 배려심을 믿어요. 다른 사람을 도우면서도 자신의 꿈을 향해 당당히 나아가세요. 파이팅!`,
    school: `여러분은 어떤 학교 생활이든 잘 적응하고 성장할 수 있는 '만능 캐릭터'예요! 하지만 주의할 점이 있어요. 그냥 흘러가는 대로 생활하다 보면, 다른 사람을 너무 배려하느라 정작 자신의 꿈을 잃어버릴 수 있어요.
꼭 자신만의 목표를 세워보세요. 공부, 운동, 예술 등 어떤 분야든 좋아요. 그 목표를 향해 열심히 노력하고 경험을 쌓으세요. 나중에 자기소개서를 쓸 때 이런 경험들이 빛을 발할 거예요.
동아리 활동이나 봉사활동에 참여해보는 것도 좋아요. 여러분의 능력을 발휘하면서 새로운 친구들도 사귈 수 있을 거예요. 선생님들께 인정받을 기회도 많아질 거고요.
시간 관리도 잘 해보세요. 친구들 돕는 것도 좋지만, 자신의 공부 시간도 꼭 확보하세요. 균형 잡힌 학교생활이 여러분을 더 멋진 사람으로 만들어줄 거예요!`,
    human: `- 베프, 여자친구
와우, 정말 좋은 인연을 만났네요! 함께 꿈을 키워나간다면 환상의 짝꿍이 될 수 있어요. 서로를 응원하고 격려하면서 함께 성장해 나가세요.
- 선생님
이런 선생님과 함께라면 학교생활이 즐거울 거예요! 서로 신뢰를 쌓으며 좋은 관계를 만들어보세요. 열심히 노력하는 모습을 보여드리면, 선생님도 여러분을 끝까지 응원해주실 거예요.
- 선배
정말 멋진 선배를 만났네요! 이 선배에게 인정받을 수 있도록 노력해보세요. 여러분의 능력을 믿고 끝까지 지원해줄 거예요. 선배의 조언을 잘 듣고 실천한다면 학교생활에 큰 도움이 될 거예요.
- 친구, 후배
평소에 좋은 관계를 유지한다면 어려울 때 서로 도와줄 수 있는 든든한 친구가 될 거예요. 함께 공부하고, 성장하는 관계를 만들어보세요.`,
  },

  BAABC: {
    type: "BAABC",
    category: "배려와 고집 양립 유형",
    desc: `츤데레 현실주의자 타입`,
    sub: `학교의 숨은 보석!`,
    char: `여러분은 진정한 '츤데레 현실주의자'예요! 평소엔 친구들을 배려하고 상황을 꼼꼼히 따지는 '쿨'한 모습을 보여주죠. 하지만 한번 마음먹으면 절대 안 굽히는 '츤츤'한 면도 있어요.
현실 감각이 뛰어나서 문제를 척척 해결하는 능력자예요. 평소에 배려심 넘치는 모습 때문에, 가끔 고집 부려도 다들 이해해주려고 해요. '강철 멘탈'이라 쉽게 흔들리지 않지만, 때로는 뜬금없는 고집이나 무관심으로 주변을 당황시키기도 해요.`,
    result: `기억하세요! 이 타입은 '츤데레 현실주의자' 스타일이지만, '유연성'과 '의사소통 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 배려심과 현실감각을 믿어요. 고집은 조금만 줄이고, 다양한 의견을 수용하는 자세를 기르면 더욱 멋진 사람이 될 거예요. 파이팅!`,
    school: `여러분은 학교생활에 잘 적응하는 '현실파 학생'이에요! 공부할 때도 현실적으로 계획을 세우고 실천하는 편이죠. 그룹 과제나 동아리 활동에서도 배려심과 현실감각으로 빛을 발할 거예요.
하지만 주의할 점도 있어요. 때로는 엉뚱한 부분에서 고집을 부려 친구들이나 선생님을 당황하게 할 수 있어요. 그럴 때는 잠깐 멈추고 상황을 다시 한번 생각해보는 습관을 들여보세요.
진로를 고민할 때는 여러분의 장점을 잘 살릴 수 있는 분야를 찾아보세요. 사회복지사나 상담사 같이 다른 사람을 도와주면서도 규칙을 잘 지키는 직업이 좋을 거예요. 자기소개서를 쓸 때는 여러분의 배려심과 현실감각을 잘 보여주세요.`,
    human: `- 베프, 여자친구
가끔 뜬금없는 고집으로 당황하게 할 수 있지만, 늘 상대방을 배려하는 모습이 매력적이에요. 서로 이해하고 타협하면서 관계를 만들어가세요. 함께 성장할 수 있는 좋은 인연이 될 거예요!
- 선생님
현실적이고 합리적인 선생님이에요. 수업도 잘 하시고 학생들을 배려해주시겠죠. 하지만 한번 마음먹은 것은 바꾸기 어려울 수 있어요. 선생님의 의견을 존중하면서도, 필요하다면 조심스럽게 의견을 말씀드려보세요.
- 선배
합리적이면서도 자상한 멋진 선배네요! 하지만 가끔 이해할 수 없는 고집을 부릴 수도 있어요. 그럴 때는 선배의 의견을 존중하면서도, 부드럽게 대화를 나눠보세요. 선배의 경험과 조언을 잘 듣는다면 학교생활에 큰 도움이 될 거예요.
- 친구, 후배
현실감각이 뛰어난 좋은 친구예요. 배려심도 있어서 함께 공부하거나 프로젝트를 할 때 큰 힘이 될 거예요. 서로의 장점을 인정하고 부족한 점은 채워주면서 함께 성장해나가세요.`,
  },

  BAACA: {
    type: "BAACA",
    category: "배려와 협력파 유형",
    desc: `배려의 아이돌, 숨은 '인싸' 타입`,
    sub: `학교의 조용한 히어로!`,
    char: `여러분은 진정한 '배려의 아이돌'이에요! 다른 사람을 배려하는 게 몸에 밴 '인간 구름빵' 같은 존재죠. 하지만 이게 순수한 즐거움보다는 상황에 맞춰 행동하는 거라고 할 수 있어요.
가끔은 자신의 인생을 즐기는 걸 잊어버려서 '인생 노잼' 모드에 빠질 수 있어요. 다른 사람의 평가에 민감해서 그들이 좋아하는 걸 하다 보면 자신의 진짜 모습을 잃어버릴 수 있어요. 하지만 여러분은 똑똑해서 무조건 남의 말만 따르지는 않아요.`,
    result: `기억하세요! 이 타입은 '배려의 아이돌, 숨은 인싸' 스타일이지만, '자기표현력'과 '자아 발견 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 배려하는 것도 좋지만, 가끔은 나를 위한 시간도 가져보세요. 그럼 더 행복한 학교생활을 할 수 있을 거예요. 파이팅!`,
    school: `학교에서 여러분은 '숨은 인싸'예요! 누구나 편하게 대할 수 있는 친구지만, 가끔은 자신의 진짜 모습을 숨기고 있을 수 있어요.
공부할 때는 선생님이나 부모님의 기대에 맞추려고 노력하지만, 정작 자신이 뭘 좋아하는지 잘 모를 수 있어요. 자신이 진짜 좋아하는 과목이나 활동을 찾아보세요. 그게 공부의 원동력이 될 거예요!
동아리 활동이나 학급 행사에서도 늘 남을 배려하느라 정작 본인은 즐기지 못하고 있진 않나요? 가끔은 '오늘은 내가 주인공!'이라고 생각하고 적극적으로 참여해보세요.
진로를 고민할 때도 남의 의견만 듣지 말고, 진짜 '나'다운 꿈을 찾아보세요. 그 과정에서 상담 선생님이나 믿을 만한 친구들과 이야기를 나누는 것도 좋아요.`,
    human: `- 베프, 여자친구
여러분은 감정 표현이 서툴러서 상대방이 답답해할 수 있어요. 하지만 늘 상대를 배려하는 마음은 정말 귀한 거예요. 조금씩 솔직한 감정을 표현하는 연습을 해보세요. "나 오늘 네가 이렇게 해줘서 정말 기뻤어!"라고 말하는 것부터 시작해보는 건 어떨까요?
- 선생님
선생님의 눈치를 많이 보는 편이에요. 하지만 걱정 마세요. 여러분의 배려심 넘치는 모습을 선생님도 알아주실 거예요. 가끔은 용기를 내서 자신의 의견을 말해보세요. 선생님도 여러분의 생각을 듣고 싶어 하실 거예요.
- 선배
조금은 어둡고 무기력해 보일 수 있지만, 후배들을 진심으로 아끼는 선배예요. 선배의 조용한 배려를 알아주세요. 가끔은 선배에게 먼저 다가가 이야기를 나눠보는 것도 좋아요. 선배도 그런 여러분을 반갑게 맞아줄 거예요.
- 친구, 후배
항상 밝지만은 않아서 함께 있으면 조금 무거운 분위기가 될 수 있어요. 하지만 정말 따뜻한 마음을 가진 친구예요. 함께 즐거운 추억을 만들어보세요. 영화 보기, 맛있는 거 먹기 등 작은 것부터 시작해보는 건 어떨까요?`,
  },

  BAACB: {
    type: "BAACB",
    category: "타인을 위해 양보하는 유형",
    desc: `배려요정 굿즈 타입`,
    sub: `학교의 숨은 MVP!`,
    char: `여러분은 진정한 '배려요정'이에요! 주변에서 항상 "넌 참 좋은 애야"라는 말을 자주 듣죠? 다른 사람을 배려하고 양보하는 게 일상이에요. 하지만 가끔은 자신의 감정을 표현하는 게 어렵고, 스스로 즐거움을 찾는 데 서툴러요.
여러분은 균형 잡힌 마음씨와 선한 의지를 가진 멋진 사람이에요. 하지만 너무 착해서 종종 손해를 보기도 해요. 자신의 감정을 표현하는 연습을 하면 더 즐거운 학교생활을 할 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '배려요정 굿즈' 스타일이지만, '자기표현력'과 '자기 사랑'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 배려하는 것도 좋지만, 자신의 감정과 욕구도 소중히 여기는 법을 배워보세요. 그럼 더 행복한 학교생활을 할 수 있을 거예요. 파이팅!`,
    school: `여러분은 어떤 과제나 역할이 주어져도 잘 해낼 수 있어요. 선생님들이 믿고 맡기는 학생일 거예요. 하지만 발표나 면접 같은 상황에서는 자신의 열정을 제대로 보여주지 못할 수 있어요.
수행평가나 동아리 활동에서 자신의 의견을 더 적극적으로 표현하는 연습을 해보세요. "제 생각은 이런데요..."라고 말하는 것부터 시작해보는 건 어떨까요?
반장이나 부반장 선거에 도전해보는 것도 좋아요. 여러분의 배려심과 성실함은 분명 반 친구들에게 큰 도움이 될 거예요.
진로를 고민할 때는 남을 돕는 직업(선생님, 상담사, 의료인 등)을 생각해보는 것도 좋아요. 하지만 그전에 "내가 진짜 좋아하는 건 뭘까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
함께 있으면 편안하고 의지되는 친구예요. 하지만 가끔은 자신의 감정을 숨기고 있진 않나요? "나 오늘 기분이 이래."라고 솔직하게 말해보세요. 상대방도 여러분의 마음을 더 잘 이해할 수 있을 거예요.
- 선생님
열심히 노력하고 맡은 일을 잘 해내는 모습을 보여주세요. 그러면 선생님도 여러분을 더 배려해주고 좋은 관계를 만들어갈 수 있을 거예요. 하지만 너무 착하다고 선생님 말씀만 따르지 말고, 가끔은 자신의 의견도 말해보세요.
- 선배
여러분을 믿고 아껴주는 좋은 선배가 될 거예요. 하지만 선배가 마음씨 좋다고 해서 너무 편하게 행동하면 안 돼요. 예의를 지키면서도 자신의 생각을 솔직하게 말할 수 있는 관계를 만들어보세요.
- 친구, 후배
적극적으로 나서지는 않지만, 성실하고 다른 친구들을 잘 도와주는 믿음직한 친구예요. 하지만 가끔은 자신의 일도 중요하다는 걸 기억하세요. "미안해, 지금은 내 공부를 해야 해"라고 말하는 연습도 해보세요.`,
  },

  BAACC: {
    type: "BAACC",
    category: "외로운 봉사자 유형",
    desc: `외로운 히어로 타입`,
    sub: `학교의 숨은 봉사왕!`,
    char: `여러분은 '외로운 히어로' 같은 존재예요! 감정 표현이 서툴고 다른 친구들과 어울리는 게 어려울 수 있어요. 하지만 마음 속 깊은 곳엔 남을 돕고 싶은 따뜻한 마음이 가득해요.
배려하려고 노력하지만, 가끔은 자기 기준으로만 행동해서 오히려 역효과가 날 수도 있어요. 봉사활동에 열정적이고, 이를 통해 외로움을 달래기도 해요.
여러분의 따뜻한 마음은 정말 멋져요. 하지만 자신의 감정도 소중히 여기고, 다른 사람들과 조화롭게 지내는 법을 배우면 더 행복해질 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '외로운 히어로' 스타일이지만, '소통 능력'과 '자기 이해'를 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 돕는 것도 좋지만, 자신의 감정도 소중히 여기고 표현하는 법을 배워보세요. 그리고 다양한 의견을 받아들이는 연습을 하면, 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 '숨은 봉사왕'으로 불릴 수 있어요! 봉사활동이나 친구 돕기에 열심이지만, 가끔은 혼자 있는 걸 좋아하죠.
수업 시간에 자신의 의견을 말하는 걸 어려워할 수 있어요. 하지만 조금씩 연습해보세요. "제 생각은 이런데요..."라고 말하는 것부터 시작해보는 건 어떨까요?
동아리 활동에서는 봉사 동아리나 환경 보호 동아리 같은 곳에서 빛을 발할 수 있어요. 하지만 다른 친구들의 의견도 존중하는 연습을 해보세요.
진로를 고민할 때는 사회복지사, 상담사, 환경운동가 같은 직업을 생각해볼 수 있어요. 하지만 그전에 "내가 진짜 좋아하는 건 뭘까?"라는 질문도 꼭 해보세요.
자신의 장단점을 정직하게 적어보고, 친구들에게 "나는 어떤 사람으로 보이니?"라고 물어보는 것도 좋아요. 이런 자기 분석이 나중에 진로 선택에 큰 도움이 될 거예요.`,
    human: `- 베프, 여자친구
배려심 깊고 자상한 친구예요. 하지만 가끔 자기 기준을 상대방에게 강요할 수 있어요. "이렇게 하는 게 좋을 것 같아"라고 말하기 전에, 상대방의 생각도 물어보세요.
- 선생님
선생님과 노는 건 좋아하지 않을 수 있어요. 하지만 선생님의 지도를 잘 따르고, 봉사활동 같은 걸 열심히 하면 좋은 관계를 만들 수 있어요. 가끔은 자신의 생각도 조심스럽게 말씀드려보세요.
- 선배
감정 표현은 서툴지만 후배들 일에 관심이 많은 선배일 수 있어요. 선배의 조언을 듣되, 자신의 생각도 중요하다는 걸 기억하세요.
- 친구, 후배
표현은 잘 안 하지만 고집이 세서 친구들이 다루기 힘들어할 수 있어요. 하지만 배려심 있고 자상한 면도 있죠. 친구들의 의견도 들어보고, 조금씩 마음을 열어보세요.`,
  },

  BABAA: {
    type: "BABAA",
    category: "갈팡질팡 연기자 유형",
    desc: `매력만점 카멜레온 타입`,
    sub: `학교의 인싸 배우!`,
    char: `여러분은 진정한 '인생의 배우'예요! 때론 무대 위 주인공처럼 반짝반짝 빛나고 싶어 하고, 때론 조용히 다른 사람 속에 숨어 의지하고 싶어 해요.
여러분의 귀여운 모습에 주변 사람들이 '심쿵'하겠지만, 가끔은 너무 의존적이어서 친구들을 지치게 할 수도 있어요. 하지만 다른 사람을 진심으로 좋아하고 잘 지내려고 노력하는 따뜻한 마음을 가졌죠.
상황에 따라 '변신의 귀재'처럼 행동을 바꿀 수 있어서 학교생활이 즐거울 거예요. 하지만 더 높은 목표를 향해 나아가는 건 어려워할 수 있어요. 현재를 즐기는 것도 좋지만, 미래를 위한 계획도 세워보는 게 어떨까요?`,
    result: `기억하세요! 이 타입은 '매력만점 카멜레온' 스타일이지만, '목표 설정 능력'과 '자기 관리 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 밝은 에너지와 적응력을 믿어요. 현재를 즐기면서도 미래를 위한 준비도 조금씩 해나가세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '인싸'가 될 수 있는 잠재력이 가득해요! 어떤 친구들과도 잘 어울리고, 학교생활을 즐겁게 만들 수 있죠. 하지만 주의할 점도 있어요.
목표 설정이 중요해요. 그냥 '노는 게 제일 좋아~'하다 보면 나중에 후회할 수 있어요. 꿈이 뭔지 생각해보고, 그 꿈을 이루기 위해 어떤 준비가 필요한지 알아보세요.
동아리 활동은 연극부나 방송부 같은 곳에서 재능을 발휘할 수 있을 거예요. 하지만 책임감 있게 활동하는 것도 잊지 마세요.
공부할 때는 집중력이 부족할 수 있어요. 짧은 시간 동안 집중해서 공부하고, 자주 휴식을 취하는 방식으로 해보세요. 친구들과 함께 공부하는 것도 좋은 방법이에요.
진로를 고민할 때는 연예인, MC, 유튜버 같은 직업을 생각해볼 수 있어요. 하지만 그 외에도 다양한 직업을 알아보고 자신에게 맞는 길을 찾아보세요.`,
    human: `- 베프, 여자친구
함께 있으면 정말 재미있는 친구예요! 하지만 감정 기복이 있고 의존적일 수 있어요. "나 지금 기분이 안 좋아..."라고 말하기 전에, 스스로 기분을 좋게 만드는 방법도 찾아보세요.
- 선생님
선생님의 감정에 따라 수업 분위기가 바뀔 수 있어요. 선생님의 말씀을 잘 듣고, 수업 내용을 정리해두는 습관을 들이세요. 그리고 선생님께 도움을 요청할 때는 구체적으로 무엇이 필요한지 말씀드리는 게 좋아요.
- 선배
배려심 있고 자상한 선배일 거예요. 하지만 감정 기복이 있을 수 있어요. 선배가 힘들어할 때는 "제가 도와드릴 일 있나요?"라고 물어보는 것도 좋아요.
- 친구, 후배
함께 있으면 즐거운 친구예요! 하지만 가끔은 친구들에게 너무 의지하려고 할 수 있어요. 자립심을 기르는 연습도 해보세요. "이번엔 내가 해볼게!"라고 말하는 것부터 시작해보는 건 어떨까요?`,
  },

  BABAB: {
    type: "BABAB",
    category: `'우리 모두 즐겁게' 유형`,
    desc: `올픽 행복추구 타입`,
    sub: `학교의 파티 플래너!`,
    char: `여러분은 진정한 '올픽(All Pick) 행복추구자'예요! 자신과 친구들 모두가 즐겁기를 바라는 '행복 바이러스' 같은 존재예요.
'쎈 척'하거나 '외면하는' 태도를 보이지 않고, 자신의 감정을 솔직하게 표현해요. 다른 사람의 이야기도 잘 들어주며, '오픈 마인드'로 소통하는 스타일이죠. 자신이 즐거운 만큼 친구들의 즐거움도 존중하기 때문에, 함께 있으면 '케미 폭발'하는 친구예요!
자유로운 영혼이기 때문에 틀에 박힌 규칙이나 지나치게 빡빡한 분위기는 싫어하지만, 창의적인 활동이나 예술 분야에서는 진정한 실력을 발휘할 수 있어요.`,
    result: `기억하세요! 이 타입은 '올픽 행복추구' 스타일이지만, '집중력'과 '현실 감각'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 긍정 에너지와 창의력을 믿어요. 즐거움을 추구하면서도 때로는 진지하게 미래를 준비하는 자세를 가져보세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '분위기 메이커'가 될 수 있어요! 수업 시간에도 재미있는 아이디어로 선생님과 친구들을 놀라게 할 수 있죠.
동아리 활동에서는 예술 동아리나 봉사 동아리에서 빛을 발할 수 있어요. 여러분의 창의력과 긍정 에너지로 동아리를 '핫플'로 만들 수 있을 거예요.
단, 시험 기간이나 과제 마감 때는 조금 어려움을 겪을 수 있어요. '플렉스'하는 것도 좋지만, 때로는 집중해서 공부하는 시간도 필요해요. 친구들과 함께 공부하면서 서로 도와주는 건 어떨까요?
진로를 고민할 때는 예술가, 디자이너, 유튜버, 이벤트 플래너 같은 직업을 생각해볼 수 있어요. 여러분의 창의력과 긍정 에너지를 마음껏 발휘할 수 있는 일을 찾아보세요.`,
    human: `- 베프, 여자친구
함께 있으면 정말 재미있는 친구예요! '돈이 전부가 아니야~'라고 생각하는 로맨티스트지만, 가끔은 현실적인 고민도 함께 나눠보는 게 어떨까요?
- 선생님
특별히 엄청난 성적을 바라지는 않는 선생님이에요. 수업 시간에 적극적으로 참여하고, 창의적인 아이디어를 내면 선생님도 좋아하실 거예요. 하지만 기본적인 규칙은 지키는 게 좋아요!
- 선배
일하면서도 즐거움을 찾을 줄 아는 선배예요. 선배와 함께 하면 학교생활이 더 재미있어질 거예요. 하지만 중요한 일을 할 때는 진지하게 임하는 모습도 보여주세요.
- 친구, 후배
즐거운 학교생활을 만들어가는 친구예요. 여러분과 함께라면 학교가 'PLUR(Peace, Love, Unity, Respect)'한 공간이 될 수 있어요. 하지만 가끔은 진지한 이야기도 나눠보세요.`,
  },

  BABAC: {
    type: "BABAC",
    category: "동정심 넘치는 천진난만 유형",
    desc: `반항아 속 천사 타입`,
    sub: `학교의 반전 매력 킹!`,
    char: `여러분은 진정한 '반항아 속 천사' 타입이에요! 자신의 인생을 솔직하고 즐겁게 살아가려고 노력하는 '찐'이에요.
동정심과 호기심이 많아서 가끔 친구들을 '멘붕'시키는 행동을 할 수 있어요. 하지만 천진난만하고 배려심 넘치는 모습 때문에 미움받기는 힘들어요. 실수해도 '괜찮아, 다음엔 잘 할 거야!'라며 꿋꿋이 일어나는 모습이 매력적이죠.
자기만의 세계가 있어서 쉽게 마음을 열지 않을 수 있어요. 하지만 한번 마음을 열면 진심으로 친구를 대하는 '찐친' 스타일이에요.`,
    result: `기억하세요! 이 타입은 '반항아 속 천사' 스타일이지만, '자기 조절 능력'과 '공감 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 독특한 매력을 믿어요. 때로는 엉뚱하게, 때로는 진지하게 살아가면서 자신만의 색깔을 만들어가세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '반전 매력' 스타예요! 처음엔 조금 까칠해 보일 수 있지만, 알고 보면 따뜻한 마음을 가진 친구랍니다.
수업 시간에 엉뚱한 질문으로 선생님을 당황시킬 수 있지만, 그게 오히려 수업을 재미있게 만들 수 있어요. 호기심을 잘 활용하면 공부에도 도움이 될 거예요.
동아리 활동에서는 봉사 동아리나 환경 보호 동아리에서 열정을 보일 수 있어요. 여러분의 따뜻한 마음으로 세상을 조금씩 바꿔나갈 수 있을 거예요.
시험 기간에는 집중력이 부족할 수 있어요. 짧은 시간 동안 집중해서 공부하고, 자주 휴식을 취하는 방식으로 공부해보세요. 친구들과 함께 공부하면서 서로 도와주는 것도 좋아요.
진로를 고민할 때는 사회복지사, 상담사, 교사 같이 사람들을 돕는 직업을 생각해볼 수 있어요. 여러분의 따뜻한 마음을 살릴 수 있는 일을 찾아보세요.`,
    human: `- 베프, 여자친구
함께 있으면 정말 재미있는 친구예요! 밝은 에너지가 매력적이에요. 하지만 가끔은 진지한 대화도 나눠보세요. 그러면 관계가 더 깊어질 수 있어요.
- 선생님
선생님과 서로 배려하며 지낸다면 좋은 관계를 만들 수 있어요. 수업 시간에 적극적으로 참여하고, 가끔은 선생님께 재미있는 질문을 해보세요. 선생님도 여러분의 독특한 매력을 좋아하실 거예요.
- 선배
공부와 놀기 모두 잘 아는 '꿀잼' 선배예요. 학교생활에 어려움이 있다면 솔직히 이야기해보세요. 여러분을 잘 이해해주고 도와줄 거예요.
- 친구, 후배
상대를 배려할 줄 알고 자신의 삶도 즐길 줄 아는 '인싸' 친구예요. 함께 있으면 학교생활이 더 재미있어질 거예요. 하지만 가끔은 친구의 고민도 진지하게 들어주세요.`,
  },

  BABBA: {
    type: "BABBA",
    category: "배려심 많은 착한 사람 유형",
    desc: `배려요정 '착한애' 타입`,
    sub: `학교의 인간 구름빵!`,
    char: `여러분은 진정한 '배려요정'이에요! 하지만 가끔은 너무 착해서 문제가 될 수 있어요. 다른 사람 눈치를 보느라 자신의 생각을 말하기 어려워하고, 친구들에게 의지하려는 경향이 있죠.
정이 많고 남을 먼저 생각하는 여러분은 '착한애' 콤플렉스가 있을 수 있어요. 성공하고 즐겁게 살기 위해서는 '싫어요'라고 말하는 연습이 필요해요. 자존감을 키우고 스스로 결정하는 연습도 중요해요.
배려하는 마음은 좋지만, 다른 사람 눈치만 보다 보면 가끔은 '호구'가 될 수도 있다는 걸 기억하세요!`,
    result: `기억하세요! 이 타입은 '배려요정 착한애' 스타일이지만, '자기주장 능력'과 '경계 설정 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 배려하는 것도 좋지만, 가끔은 자신을 위해 'No'라고 말하는 연습을 해보세요. 그럼 더 행복한 학교생활을 할 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '인간 구름빵'이에요! 누구나 편하게 다가올 수 있는 따뜻한 친구죠. 하지만 자신감을 키우는 게 가장 중요해요.
수업 시간에 발표하는 걸 어려워할 수 있어요. 하지만 조금씩 용기를 내어 자신의 생각을 말해보세요. "제 생각에는..."이라고 시작하는 것부터 연습해보는 건 어떨까요?
동아리 활동에서는 봉사 동아리나 또래 상담 동아리에서 빛을 발할 수 있어요. 다른 사람을 돕는 활동을 하면서 자신감도 키울 수 있을 거예요.
과제나 시험 준비를 할 때 친구들의 부탁을 모두 들어주다 보면 정작 본인 공부를 못할 수 있어요. '미안해, 나도 지금 바빠'라고 말하는 연습을 해보세요.
진로를 고민할 때는 상담사, 사회복지사, 간호사 같이 남을 돕는 직업을 생각해볼 수 있어요. 하지만 그전에 "내가 정말 원하는 게 뭘까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
여러분은 정말 따뜻하고 배려심 넘치는 친구예요. 하지만 가끔은 너무 맞춰주려다 자신을 잃을 수 있어요. "나는 이렇게 생각해"라고 말하는 연습도 해보세요.
- 선생님
선생님의 동정심을 자극하면 더 많은 도움을 받을 수 있어요. 하지만 그걸 너무 이용하면 안 돼요. 선생님께 의존하지 않고 스스로 해결하려는 모습을 보여주세요.
- 선배
배려심 많고 자상한 선배예요. 하지만 그것만 믿고 자신의 일을 소홀히 하면 안 돼요. 선배의 도움을 받되, 스스로 해결하려는 모습도 보여주세요.
- 친구, 후배
누구에게나 친절하고 거절을 잘 못해서 일이 많은 친구예요. 친구들이 여러분을 더 챙겨주고 아껴줬으면 좋겠어요. 하지만 너무 의지하지 않도록 주의해야 해요.`,
  },

  BABBB: {
    type: "BABBB",
    category: "온화한 호인 유형",
    desc: `인간 솜사탕 타입`,
    sub: `학교의 무한 긍정 아이콘!`,
    char: `여러분은 진정한 '인간 솜사탕'이에요! 의리와 인정이 넘치고, 밝고 긍정적인 에너지로 주변 사람들을 '힐링'시키는 타입이죠. 어떤 상황에서도 솔직하고 밝게 대처하니까 친구들이 여러분 주변으로 몰려들어요.
'찐'으로 좋은 점만 가득해 보이지만, 아쉽게도 거기서 멈춰있어요. '남을 배려하고 관용적이라 진짜 천사같아~'라는 평가에서 더 나아가지 못하는 거죠. '이 친구가 뭔가 대단한 일을 할 것 같아' 또는 '깊이 있는 생각을 가졌을 것 같아'라는 생각은 들지 않아요.`,
    result: `기억하세요! 이 타입은 '인간 솜사탕' 스타일이지만, '목표 설정 능력'과 '논리적 사고력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 밝은 에너지와 따뜻한 마음을 믿어요. 다른 사람을 배려하는 것도 좋지만, 자신만의 색깔을 만들어가는 것도 잊지 마세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '무한 긍정 아이콘'이에요! 어떤 수업이나 활동에서도 밝은 에너지로 분위기를 업시키는 친구죠.
수행평가나 발표 때 여러분의 에피소드는 언제나 인기 만점이에요. 이런 장점을 잘 살려서 자기소개서를 쓰면 동아리나 학생회 활동에서 좋은 결과를 얻을 수 있을 거예요.
하지만 주의할 점도 있어요. 너무 '좋은 애'로만 머물러 있지 말고, 자신만의 목표를 세워보는 건 어떨까요? 예를 들어, '이번 학기에는 수학 성적 20점 올리기'나 '영어 스피치 대회 참가하기' 같은 구체적인 목표요.
또, 감성적인 면 뿐만 아니라 논리적 사고력을 기르는 것도 중요해요. 토론 동아리에 가입하거나, 시사 문제에 관심을 가져보는 것은 어떨까요?`,
    human: `- 베프, 여자친구
상대방을 배려하는 게 몸에 밴 여러분이에요. 함께 노력하면 정말 '천생연분'이 될 수 있어요. 하지만 가끔은 자신의 욕구나 의견도 솔직히 표현해보세요.
- 선생님
이런 선생님이라면 정말 '찐'으로 좋은 관계를 만들 수 있어요. 선생님을 존중하고 약속을 잘 지키면서, 수업에 적극적으로 참여해보세요. 선생님도 여러분을 더 아껴주실 거예요.
- 선배
어려운 일이 생기면 도와주고 든든한 버팀목이 되어줄 수 있는 선배예요. 하지만 너무 의지하지만 말고, 자신감 있고 성실한 모습을 보여주세요. 그러면 서로 '윈윈'하는 좋은 관계를 만들 수 있어요.
- 친구, 후배
학교생활의 좋은 파트너가 될 수 있어요. 서로 도와가며 즐거운 학창시절을 만들어갈 수 있을 거예요. 하지만 가끔은 진지한 대화도 나눠보세요.`,
  },

  BABBC: {
    type: "BABBC",
    category: "독단적 배려 강요 유형",
    desc: `오지라퍼 배려왕 타입`,
    sub: `학교의 과잉보호 히어로!`,
    char: `여러분은 진정한 '오지라퍼 배려왕'이에요! 다른 사람을 돌보는 걸 정말 좋아하는 타입이죠. 배려심이 넘치는 건 정말 멋진 장점이지만, 가끔은 상대방 상황을 고려하지 않고 '오지라퍼'처럼 밀어붙여서 친구들을 당황하게 만들 수 있어요.
여러분이 좋아하는 걸 친구들에게 강요할 수 있어요. 예를 들어, 좋아하는 아이돌 굿즈를 친구들에게 사라고 하거나, 특정 앱을 깔라고 계속 권하는 식이죠. 이런 행동 때문에 친구들이 여러분을 피하게 될 수 있으니 주의해야 해요.
때로는 '과잉보호 히어로'처럼 행동할 수 있어요. 하지만 기억하세요, 진정한 배려는 상대방의 입장에서 생각하는 거예요. 자제력을 키우면 더 멋진 사람이 될 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '오지라퍼 배려왕' 스타일이지만, '자제력'과 '공감 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 배려하는 것도 좋지만, 상대방의 입장에서 생각하는 연습을 해보세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 '배려 히어로'로 불릴 수 있어요! 하지만 주의할 점이 있어요.
수업 시간에 친구를 도와주려다 오히려 선생님께 혼날 수 있어요. 친구가 도움을 요청할 때만 도와주는 게 좋아요.
동아리 활동에서는 봉사 동아리나 또래 상담 동아리에서 빛을 발할 수 있어요. 하지만 다른 사람의 의견도 존중하는 연습을 해보세요.
단체 과제를 할 때 혼자 다 하려고 하지 마세요. 팀원들과 역할을 나누고, 각자의 몫을 믿고 맡기는 연습을 해보세요.
자신의 장단점을 객관적으로 파악하는 게 중요해요. 친구들이나 선생님께 솔직한 피드백을 요청해보세요. 그리고 자기소개서를 쓸 때 잘못된 에피소드를 자랑하지 않도록 주의하세요.`,
    human: `- 베프, 여자친구
애정이라는 이름으로 잔소리를 많이 할 수 있어요. 상대방의 마음을 이해하되, 가끔은 "나는 이렇게 느껴"라고 솔직히 말해보세요.
- 선생님
선생님이 많이 배려해주시는 것 같아도, 적절한 거리를 유지하세요. 예의 바르게 행동하고, 수업에 열심히 참여하는 모습을 보여주세요.
- 선배
관심이 지나쳐 여러분의 프라이버시를 존중하지 않을 수 있어요. 선배의 배려에 감사하되, 필요하다면 "제 개인적인 일이에요"라고 말하는 것도 괜찮아요.
- 친구, 후배
반을 위해 노력하고 봉사하는 모습이 멋져요. 하지만 가끔은 지나친 관심과 잔소리로 친구들을 피곤하게 할 수 있어요. 상대방이 원하는 만큼만 도와주세요.`,
  },

  BABCA: {
    type: "BABCA",
    category: "너무 쉽게 믿는 헬퍼 유형",
    desc: `'착한 아이 증후군' 타입`,
    sub: `학교의 미친 도우미!`,
    char: `여러분은 진정한 '착한 아이 증후군' 주인공이에요! "널 믿어", "넌 최고야", "너 없으면 안 돼" 같은 말에 쉽게 넘어가서 친구들을 돕곤 해요.
'노(No)'라고 말하기 어려워서 자신이 힘들어도 다른 사람 일까지 도맡아 하다가 '멘붕'이 올 수 있어요. 지나친 '보호 본능'과 '인정 욕구'를 조금 내려놓을 필요가 있어요.
스스로를 더 사랑하고, 적절하지 않은 부탁은 거절하는 연습을 해보세요. 여러분도 소중한 사람이라는 걸 잊지 마세요!`,
    result: `기억하세요! 이 타입은 '착한 아이 증후군' 스타일이지만, '자기주장 능력'과 '경계 설정 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 돕는 것도 좋지만, 가끔은 자신을 위해 'No'라고 말하는 연습을 해보세요. 그럼 더 행복한 학교생활을 할 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '미친 도우미'로 불릴 수 있어요! 하지만 주의할 점이 있어요.
수행평가나 과제를 할 때, 친구들 것까지 도맡아 하지 마세요. 도와주는 건 좋지만, 친구들이 스스로 할 수 있게 하는 것도 중요해요.
동아리 활동에서는 봉사 동아리나 또래 상담 동아리에서 빛을 발할 수 있어요. 하지만 모든 일을 혼자 하려고 하지 말고, 역할을 나누는 연습을 해보세요.
자신의 장점을 잘 어필하지 못할 수 있어요. 자신감을 가지고 "나는 이런 걸 잘해요!"라고 말하는 연습을 해보세요.
선생님께 도움을 요청할 때도 망설이지 마세요. 여러분의 노력을 인정받을 수 있을 거예요.
진로를 고민할 때는 사회복지사, 상담사, 교사 같이 남을 돕는 직업을 생각해볼 수 있어요. 하지만 그전에 "내가 정말 원하는 건 뭘까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
여러분은 정말 헌신적인 친구예요. 하지만 모든 사람에게 '착한 아이'가 되려고 하다 보면 정작 가까운 사람에게 소홀해질 수 있어요. 가끔은 "미안해, 지금은 어려워"라고 말하는 연습도 해보세요.
- 선생님
늘 좋은 모습만 보여주려고 하지 마세요. 선생님께 무리한 지시를 받았다면, 조심스럽게 "어려울 것 같아요"라고 말씀드려보세요. 선생님도 여러분의 솔직한 모습을 이해해주실 거예요.
- 선배
여러분을 잘 배려해주는 좋은 선배일 거예요. 하지만 개인적인 감정을 잘 말씀하지 않아 이해하기 어려울 수 있어요. 평소 선배의 습관을 잘 파악하고 도와드리면, 더 깊은 신뢰 관계를 만들 수 있을 거예요.
- 친구, 후배
이런 친구에게는 조금 더 관심을 가져주세요. 늘 남을 돕느라 정작 본인은 도움이 필요한지도 몰라요. "너는 괜찮아?"라고 물어봐주는 것만으로도 큰 힘이 될 수 있어요. 하지만 이 친구의 착한 마음을 이용하려고 하면 안돼요!`,
  },

  BABCB: {
    type: "BABCB",
    category: "남을 위해 일하는 서비스 유형",
    desc: `'숨은 공무원' 타입`,
    sub: `학교의 묵묵한 NPC!`,
    char: `여러분은 진정한 '숨은 공무원' 타입이에요! 누가 보든 말든 항상 열심히 일하는 '찐'이죠. 하지만 특별히 관심 있는 분야가 없고, YOLO를 즐기려는 의지도 약해요.
가끔은 친구들이 여러분의 착한 마음을 이용할 수 있어요. 이제부터는 자신의 감정을 표현하는 연습을 해보세요. "나는 이렇게 생각해"라고 말하는 것부터 시작해보는 건 어떨까요?
다른 사람을 배려하는 것도 좋지만, 자신을 위한 시간도 가져보세요. 여러분만의 목표를 세우고 그것을 향해 가는 것도 중요해요!`,
    result: `기억하세요! 이 타입은 '숨은 공무원' 스타일이지만, '자기표현 능력'과 '자기 주도성'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 성실함과 배려심을 믿어요. 묵묵히 일하는 것도 좋지만, 가끔은 자신의 목소리를 내는 연습을 해보세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '묵묵한 NPC' 같은 존재예요! 어떤 일이든 맡으면 끝까지 해내는 믿음직한 친구죠.
수행평가나 과제를 할 때, 묵묵히 자신의 몫을 해내는 모습이 멋져요. 하지만 가끔은 자신의 아이디어를 제안해보는 것도 좋아요. 여러분의 생각도 충분히 가치 있어요!
동아리 활동에서는 봉사 동아리나 도서부에서 빛을 발할 수 있어요. 하지만 가끔은 리더 역할에 도전해보는 것도 좋아요.
의사소통 능력을 키우는 게 중요해요. 발표 수업에 적극적으로 참여하거나, 토론 동아리에 가입해보는 건 어떨까요?
진로를 고민할 때는 공무원, 사서, 간호사 같이 남을 돕는 직업을 생각해볼 수 있어요. 하지만 그전에 "내가 진짜 좋아하는 건 뭘까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
여러분은 일상생활에서 상대방을 정말 잘 배려해요. 하지만 자신의 감정을 솔직하게 표현하지 못해 갈등이 생길 수 있어요. 조금씩 자신의 생각을 말하는 연습을 해보세요.
- 선생님
선생님을 위해 열심히 공부하고 봉사할 수 있는 학생이에요. 보이지 않는 곳에서도 최선을 다하는 모습이 멋져요. 하지만 너무 무리하지 않도록 주의하세요. 가끔은 선생님께 도움을 요청하는 것도 좋아요.
- 선배
이런 선배라면 말없이 여러분을 지켜보고 있을 거예요. 선배가 여러분을 배려하는 모습에 게으름을 피우면 안 돼요. 책임감 있게 행동한다면 끝까지 도와주는 멋진 선배가 될 거예요.
- 친구, 후배
묵묵히 자기 할 일을 다하는 믿음직한 친구예요. 하지만 '재미없는 친구'로 여겨질 수 있어요. 가끔은 친구들과 함께 노는 시간도 가져보세요. 여러분의 숨겨진 매력을 보여줄 수 있을 거예요.`,
  },

  BABCC: {
    type: "BABCC",
    category: "지나치게 타인만 돌보는 유형",
    desc: `'과잉보호 집사' 타입`,
    sub: `학교의 숨은 엄마손!`,
    char: `여러분은 진정한 '과잉보호 집사' 타입이에요! 인생의 재미가 오직 다른 사람을 돌보는 것에만 있다고 생각하죠. 하지만 너무 열중한 나머지 자기 관리는 '스킵'하곤 해요.
가끔은 '내가 다 해줄게!' 모드로 친구들의 감정을 무시하고 독단적으로 행동할 수 있어요. 그래서 '왜 내 마음을 몰라주지?'하며 서운해 할 수도 있죠.
이제는 자신의 감정에도 관심을 가져보세요. '셀프 케어'도 중요해요! 자신을 이해하고 수용하면, 친구들의 마음도 더 잘 이해할 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '과잉보호 집사' 스타일이지만, '자기 관리 능력'과 '감정 표현 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 돌보는 것도 좋지만, 자신을 사랑하는 법도 배워보세요. 그럼 더 행복한 학교생활을 할 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '숨은 엄마손' 같은 존재예요! 하지만 주의할 점이 있어요.
수행평가나 단체 과제를 할 때, 혼자서 모든 걸 다 하려고 하지 마세요. 팀원들과 역할을 나누고, 서로의 의견을 존중하는 연습을 해보세요.
동아리 활동에서는 봉사 동아리나 또래 상담 동아리에서 빛을 발할 수 있어요. 하지만 다른 사람의 감정을 이해하는 연습도 필요해요. '공감 일기'를 써보는 건 어떨까요?
스터디 그룹을 만들어 친구들과 함께 공부하는 것도 좋아요. 서로 도우면서 공부하면 더 좋은 결과를 얻을 수 있을 거예요.
진로를 고민할 때는 사회복지사, 간호사, 상담사 같이 남을 돕는 직업을 생각해볼 수 있어요. 하지만 그전에 "나는 어떤 일을 할 때 가장 행복할까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
처음에는 여러분의 마음을 이해하기 어려울 수 있어요. 하지만 그건 단순히 감정 표현 방법을 모르는 거예요. 조금씩 솔직하게 마음을 표현하는 연습을 해보세요. "나 오늘 기분이 이래"라고 말하는 것부터 시작해보는 건 어떨까요?
- 선생님
자신의 생각을 표현하는 게 서툰 학생으로 보일 수 있어요. 때로는 어두운 성격으로 오해받을 수도 있죠. 하지만 관심 있는 분야에서는 배려심 넘치는 모습을 보여줄 수 있어요. 수업 시간에 적극적으로 참여하고, 가끔은 선생님께 질문을 해보세요.
- 선배
다양한 관심사를 가진 타입은 아니에요. 하지만 자신이 맡은 일에 최선을 다하고, 후배들을 세심하게 배려하는 멋진 선배예요. 선배의 조언을 잘 듣되, 자신의 의견도 조심스럽게 말해보세요.
- 친구, 후배
인생의 즐거움을 잘 모르는 것 같아 보여도, 자기 자리에서 열심히 노력하는 친구예요. 가끔은 함께 영화를 보거나 맛있는 걸 먹으면서 '힐링' 타임을 가져보세요.`,
  },

  BACAA: {
    type: "BACAA",
    category: "식을 줄 모르는 열정파 유형",
    desc: `'무한 에너지 열정맨' 타입`,
    sub: `학교의 핵인싸 히어로!`,
    char: `여러분은 진정한 '무한 에너지 열정맨'이에요! 세상 모든 것에 관심이 넘치고, 감성이 가득한 사춘기 청소년 같은 타입이에요. 주목받고 싶고, 모두에게 사랑받고 싶어 하는 '관심 킬러'죠.
배려심이 많고 자기표현력도 뛰어나서, 함께 있으면 항상 '꿀잼' 시간을 보낼 수 있어요. 하지만 '좋아요'에 너무 집착하고 호기심이 넘쳐서 가끔은 '멘붕'에 빠지기 쉬운 면도 있어요.
늘 즐겁고 착한 모습이 매력적이지만, 성공적인 학교생활을 위해서는 조금 더 성숙해질 필요가 있어요. '아기 멘탈'로 행동하다 보면 친구들의 신뢰를 잃을 수 있으니, 조심해야 해요. 성숙함과 책임감을 더해간다면 더 많은 사람에게 사랑받을 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '무한 에너지 열정맨' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 넘치는 에너지와 열정을 믿어요. 조금만 더 차분하고 계획적으로 행동한다면, 학교 생활의 진정한 '레전드'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '핵인싸 히어로'예요! 어떤 활동이든 적극적으로 참여하는 모습이 멋져요.
수행평가나 발표 때 여러분의 열정은 '갑툭튀' 아이디어로 이어질 수 있어요. 하지만 이제는 행동하기 전에 목표를 명확히 하고 합리적으로 결정하는 습관을 들여보세요.
동아리 활동에서는 연극부나 방송부에서 빛을 발할 수 있어요. 하지만 너무 들뜨지 말고, 차분히 상황을 분석하는 연습도 해보세요.
시험 공부할 때도 '벼락치기'보다는 계획을 세워 꾸준히 하는 게 중요해요. '한 번에 끝내겠다'는 열정도 좋지만, 꾸준히 노력하는 '내공'을 키워보세요.
진로를 고민할 때는 MC, 유튜버, 배우 같은 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업이 나에게 정말 맞을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
열정 넘치는 여러분과 함께라면 무엇이든 도전할 수 있을 거예요! 하지만 가끔은 '과열'된 열정을 식혀줄 수 있는 차분한 친구가 필요해요. 서로의 장단점을 보완해주면 최고의 팀이 될 수 있어요!
- 선생님
가끔은 '예측불가'한 학생으로 보일 수 있어요. 중요한 과제나 시험에 대해서는 선생님께 자세히 물어보고 꼼꼼히 준비하세요. 여러분의 열정을 인정해주시겠지만, 책임감 있는 모습도 보여주는 게 중요해요.
- 선배
열정 넘치는 선배라 많은 도전을 하게 될 거예요. 하지만 중간에 포기하는 경우도 있을 수 있어요. 선배의 아이디어를 존중하되, 현실적으로 가능한지 함께 고민해보는 것도 좋아요.
- 친구, 후배
열정만큼은 정말 대단하지만, 신중함이 부족할 수 있어요. 친구들이 여러분의 부족한 점을 채워줄 수 있도록 열린 마음을 가지세요. 함께 성장하는 관계를 만들어보세요!`,
  },

  BACAB: {
    type: "BACAB",
    category: "천방지축 기분파 유형",
    desc: `'분위기 메이커 핵인싸' 타입`,
    sub: `학교의 인기 스타!`,
    char: `여러분은 진정한 '분위기 메이커 핵인싸'예요! 감정이 풍부하고, 인정 넘치며, 활발한 성격으로 주변에 친구들이 많이 모여들어요. '찐'으로 좋은 사람이라 모두에게 인기 만점이죠.
하지만 가끔은 너무 노는 데만 집중해서 자신의 '실속'을 챙기지 못할 수 있어요. 사람을 너무 좋아하다 보니 정확한 판단력이 부족할 수 있고, 다른 사람에게 '좋은 일'만 시키려고 할 수 있어요. 조금 더 '머리'를 써서 생각하는 게 좋을 것 같아요.
여러분은 다른 사람들에게 정말 '찐'으로 좋은 사람이에요. 하지만 이제는 자신을 먼저 생각해보는 건 어떨까요? 목표를 조금 높게 잡고, 끈기 있게 노력해보세요!`,
    result: `기억하세요! 이 타입은 '분위기 메이커 핵인싸' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 밝은 에너지와 인기를 믿어요. 조금 더 자신의 미래를 위해 노력한다면, 학교 생활뿐만 아니라 인생의 진정한 '주인공'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '인기 스타'예요! 어떤 활동이든 즐겁게 만들 수 있는 능력이 있어요.
수업 시간에는 재미있는 질문으로 분위기를 밝게 만들 수 있어요. 하지만 가끔은 진지하게 공부에 집중하는 모습도 보여주세요.
동아리 활동에서는 연극부나 방송부에서 빛을 발할 수 있어요. 친구들과 함께 즐거운 활동을 기획해보는 것도 좋아요.
단체 과제를 할 때는 여러분이 분위기를 좋게 만들 수 있어요. 하지만 일의 결과도 중요하다는 걸 잊지 마세요. 믿을 만한 친구와 함께 역할을 나눠 책임감 있게 일을 끝내보세요.
진로를 고민할 때는 MC, 연예인, 선생님 같이 사람들과 소통하는 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업이 나에게 정말 맞을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
함께 있으면 정말 즐거운 시간을 보낼 수 있어요! 하지만 가끔은 진지한 대화도 필요해요. 서로의 꿈과 고민을 나누면서 함께 성장할 수 있을 거예요.
- 선생님
수업을 재미있게 만들 수 있는 선생님이에요. 하지만 가끔은 학생들과 너무 친해져서 수업 진도가 늦어질 수 있어요. 선생님의 재미있는 수업을 즐기면서도, 중요한 내용은 꼭 메모하세요.
- 선배
인간적으로는 정말 좋은 선배지만, 학업이나 동아리 활동에서는 조금 부족할 수 있어요. 선배의 좋은 점은 배우되, 부족한 부분은 여러분이 보완해나가세요.
- 친구, 후배
이런 친구나 후배가 있다면 학교생활이 정말 즐거울 거예요! 어디서나 밝은 분위기를 만들어주는 '에너자이저' 같은 존재예요. 하지만 가끔은 진지한 대화도 나눠보세요.`,
  },

  BACAC: {
    type: "BACAC",
    category: "개구쟁이 꼬마 유형",
    desc: `'찐 유쾌 뽀시래기' 타입`,
    sub: `학교의 에너지 폭탄!`,
    char: `여러분은 진정한 '찐 유쾌 뽀시래기'예요! 재미와 즐거움을 찾는 데는 누구에게도 지지 않는 '찐'이죠. 세상 모든 것에 호기심 가득하고, 뭐든 같이 하고 싶어 하는 'FOMO(다른 사람들이 얻는 기회를 놓치고 싶지 않은 두려움)' 스타일이에요.
언뜻 보면 '꿀잼' 친구 같지만, 자유로움이 지나쳐서 가끔 '갑분싸'를 만들 수 있어요. 이성적인 판단이 부족해서 중요한 결정을 할 때 '멘붕'이 올 수 있으니 주의해야 해요.
이런 친구가 있다면 학교생활이 '꿀잼' 보장이겠지만, 진지한 관계를 생각한다면 조금 더 고민해봐야 할 거예요. '자유영혼' 스타일이라 처음엔 재밌겠지만, 나중엔 힘들어질 수 있거든요.
이제는 사려 깊게 생각하고 이성적으로 판단하는 능력을 키워야 해요. 또, 세상은 혼자 사는 게 아니니 다른 사람을 배려하는 마음도 중요해요!`,
    result: `기억하세요! 이 타입은 '찐 유쾌 뽀시래기' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 밝은 에너지와 호기심을 믿어요. 조금 더 신중하게 생각하고 계획적으로 행동한다면, 학교 생활뿐만 아니라 인생의 진정한 '주인공'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '에너지 폭탄'이에요! 어떤 활동이든 즐겁게 만들 수 있는 능력이 있어요.
수업 시간에는 재미있는 질문으로 분위기를 밝게 만들 수 있어요. 하지만 가끔은 진지하게 공부에 집중하는 모습도 보여주세요. 선생님들이 더 좋아하실 거예요.
동아리 활동에서는 연극부나 방송부에서 빛을 발할 수 있어요. 하지만 책임감 있게 활동하는 것도 잊지 마세요.
시험 기간에는 집중력이 부족할 수 있어요. 친구들과 함께 공부하면서 서로 도와주는 건 어떨까요? 단, '노는 척 공부하기'는 금물!
진로를 고민할 때는 MC, 유튜버, 예능인 같이 사람들을 즐겁게 해주는 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업이 나에게 정말 맞을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
함께 있으면 정말 재미있는 시간을 보낼 수 있어요! 하지만 가끔은 진지한 대화도 필요해요. 미래에 대한 계획도 함께 세워보는 건 어떨까요?
- 선생님
수업을 재미있게 만들 수 있는 선생님이에요. 하지만 가끔은 수업이 '노는 시간'이 될 수 있어요. 선생님의 재미있는 수업을 즐기면서도, 중요한 내용은 꼭 메모하세요.
- 선배
권위적이지 않고 친근한 선배일 거예요. 편하게 지낼 수 있겠지만, 학업이나 진로에 대한 조언을 듣기는 어려울 수 있어요. 다른 선배들에게도 조언을 구해보세요.
- 친구, 후배
어떤 일을 하든 즐거운 마음으로 할 수 있는 친구예요. 하지만 공부할 때와 놀 때를 구분하는 게 중요해요. 함께 공부하면서 서로 도와주는 관계를 만들어보세요.`,
  },

  BACBA: {
    type: "BACBA",
    category: "과민한 성격 유형",
    desc: `'눈치왕 오버 리액션' 타입`,
    sub: `학교의 슈퍼 센서!`,
    char: `여러분은 진정한 '눈치왕 오버 리액션' 타입이에요! 다른 사람을 너무 배려하고, 자상하며 주변 눈치를 200% 보는 스타일이죠.
이런 성격 때문에 여러분의 '득'은 못 챙기고 오히려 친구들에게 '갑질'당할 수 있어요. 주변 이야기에 너무 '오버'해서 반응하다 보면 모든 게 자신을 향한 말 같아 스스로 '멘붕'에 빠질 수 있어요.
자존감이 낮고 주변을 너무 의식하다 보니 '갑분싸' 대처가 어설퍼서 친구들에게 제대로 인정받기 어려울 수 있어요. '흐름'에 너무 휩쓸리지 말고, 자기 주관을 갖는 게 중요해요.
이제는 '이성적 사고'가 필요해요. 뭘 생각하든 '논리'를 갖고 생각하는 습관을 들여보세요. 자신을 믿는 것도 중요해요. 조금 더 당당하게 자기 주장을 펼치고 '쿨'하게 밀고 나가보세요. 스스로를 소중히 여기고 '배짱'있게 세상과 마주하는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '눈치왕 오버 리액션' 스타일이지만, '자신감'과 '논리적 사고력'을 기르면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 센스와 배려심을 믿어요. 다른 사람을 배려하는 것도 좋지만, 자신의 의견을 당당히 말하는 연습을 해보세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교의 '슈퍼 센서'예요! 하지만 주의할 점이 있어요.
수업 시간에 발표하는 걸 어려워할 수 있어요. 하지만 조금씩 용기를 내어 자신의 생각을 말해보세요. "제 생각에는..."이라고 시작하는 것부터 연습해보는 건 어떨까요?
동아리 활동에서는 봉사 동아리나 또래 상담 동아리에서 빛을 발할 수 있어요. 하지만 리더 역할에도 도전해보세요. 여러분의 센스와 배려심은 좋은 리더의 자질이에요!
시험 기간에는 친구들 눈치 보느라 정작 본인 공부를 못할 수 있어요. '아니오'라고 말하는 연습을 해보세요. "미안해, 나도 지금 공부해야 해"라고 말하는 것부터 시작해보는 건 어떨까요?
진로를 고민할 때는 상담사, 사회복지사, 간호사 같이 남을 돕는 직업을 생각해볼 수 있어요. 하지만 그전에 "내가 정말 원하는 게 뭘까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
처음에는 모든 걸 맞춰주는 여러분 덕분에 관계가 순조로울 거예요. 하지만 시간이 지나면 '노잼'이라고 느낄 수 있어요. 조금씩 자신의 의견을 말하는 연습을 해보세요.
- 선생님
이런 선생님과 수업하려면 여러분이 적극적으로 나서야 해요. 기본적인 예의만 지켜도 선생님은 충분히 좋아하실 거예요. 하지만 가볍게 대하진 마세요. 선생님도 속으로 힘들어하실 수 있어요.
- 선배
특별한 규칙 없이 자유롭게 대해줘서 편할 수 있지만, 오히려 그 때문에 후배들이 '오바'할 수 있어요. 예의는 지키면서도 편하게 지내는 게 좋아요.
- 친구, 후배
이런 친구에겐 섬세한 배려가 필요해요. 눈치를 많이 보는 타입이라 너무 강하게 밀어붙이면 안 돼요. 따뜻한 말 한마디가 큰 힘이 될 수 있어요.`,
  },

  BACBB: {
    type: "BACBB",
    category: "자상함과 배려 과잉 유형",
    desc: `'자상 과다증 무한 배려 봇' 타입`,
    sub: `학교의 인간 구름빵!`,
    char: `여러분은 진정한 '자상 과다증 무한 배려 봇'이에요! 최고의 장점은 자상함과 깊은 배려심이에요.
하지만 이 자상함이 '오버'되면 자신에게 오히려 해가 될 수 있어요. 친구들의 부탁이나 도움 요청에 'No'라고 못하는 당신의 착한 심성은 때로는 장점이자 단점이 될 수 있어요. 자신을 돌보지 않고 남만 챙기다 보면, 결국 자신의 인생이 '멘붕' 상태에 빠질 수 있어요.
자상한 건 훌륭한 덕목이지만, 이제는 현실에 눈을 뜰 때가 왔어요. 조금 더 '머리'를 써서 자신에게도 이로운 선택을 하는 연습이 필요해요. 강한 판단력을 키우고 현실을 직시할 줄 아는 안목을 길러야 '갓생'을 살 수 있을 거예요.
또한, 주변에 당신을 '제어'해 줄 현실주의자 친구를 만들어보는 것도 좋은 방법이에요. 현실적으로 균형을 잡을 수 있는 친구와 함께라면 더 건강한 관계와 삶을 만들어갈 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '자상 과다증 무한 배려 봇' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 다른 사람을 배려하는 것도 좋지만, 가끔은 자신을 위해 'No'라고 말하는 연습을 해보세요. 그럼 더 행복한 학교생활을 할 수 있을 거예요. 파이팅!`,
    school: `경쟁 심한 학교에서 이런 타입이 살아남기는 쉽지 않아요.
수행평가나 발표 때 친구들 것까지 도맡아 하지는 않나요? 자신의 몫에 집중하는 연습을 해보세요.
동아리 활동은 봉사 동아리나 또래 상담 동아리가 잘 맞을 거예요. 하지만 가끔은 자신을 위한 활동도 해보세요.
시험 기간에는 친구들 도와주느라 정작 본인 공부를 못할 수 있어요. "미안해, 나도 공부해야 해"라고 말하는 연습을 해보세요.
진로를 고민할 때는 사회복지사, 상담사, 간호사 같이 남을 돕는 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업으로 내 미래는 괜찮을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
이런 친구와 지내려면 여러분이 '현실 감각'이 있어야 해요. 친구의 과한 자상함과 배려심을 여러분이 조절해줘야 해요. "넌 너무 착해!"라고 말해주면서 현실적인 조언도 해주세요.
- 선생님
이런 선생님은 '계산'적으로 학생들을 대하지 않아요. 하지만 그걸 이용하려고 하면 안 돼요. 선생님과 인간적인 관계를 만들면서 존경하는 마음을 가지세요.
- 선배
공부나 진로에 대해 조언을 구하기엔 어려울 수 있지만, 마음의 위로가 필요할 때 찾아갈 수 있는 선배예요. 인간적인 관계를 만들어보세요.
- 친구, 후배
이런 친구와 '경쟁'하기보다는 함께 협력하는 게 좋아요. 여러분에게 위협이 되는 존재는 아니니, 서로 도와가며 좋은 관계를 만들어보세요.`,
  },

  BACBC: {
    type: "BACBC",
    category: "정에 약한 착한 아이 유형",
    desc: `'마음 약한 방랑 아티스트' 타입`,
    sub: `학교의 4차원 매력쟁이!`,
    char: `여러분은 진정한 '마음 약한 방랑 아티스트'예요! 어떤 관계나 일에서도 '실수 만렙'이 될 수 있는 타입이죠.
'어른스러운' 판단력이 좀 부족하고, 친구들 부탁을 거절 못 하는 'ㅇㅋ' 성격이에요. 하지만 동시에 '내 맘대로' 하고 싶어 하는 '프리한' 면도 있죠. 그래서 인생을 '플로우' 타듯 살다 보니 실수가 잦아요.
바쁠 땐 큰 문제 없이 살지만, 여유 생기면 '이게 맞나?' 고민하다가 예측 불가한 삶으로 빠져들어요. 심지어 '대박' 기회가 와도 제대로 잡지 못할 수 있어요.
안정된 삶을 원한다면 모든 일에 '플랜'을 짜는 습관을 들이세요. 여러분의 자상함에 '이성'과 '감성'의 밸런스를 더하면, 어떤 기회도 여러분 것으로 만들 수 있어요!`,
    result: `기억하세요! 이 타입은 '마음 약한 방랑 아티스트' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자상함과 자유로운 영혼을 믿어요. 조금 더 계획적으로 살고, 중요한 순간에 집중하는 연습을 하세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `변화가 많은 활동보다는 정해진 틀 안에서 성과를 낼 수 있는 일이 좋아요.
수업 시간에 집중하기 어려울 수 있어요. 짧은 시간 동안 집중해서 공부하고, 자주 휴식을 취하는 방식으로 공부해보세요.
동아리 활동은 규칙적인 활동이 있는 곳이 좋아요. 예를 들어 도서부나 학교 신문부 같은 곳은 어떨까요?
시험 기간에는 계획을 세우고 지키는 연습을 해보세요. '투두리스트'를 만들어 하나씩 체크해나가는 건 어떨까요?
진로를 고민할 때는 안정적인 직업을 생각해보세요. 하지만 그전에 "이 직업이 나의 '프리'한 성격과 맞을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
자상한 면은 좋지만, 좀 '애기' 같은 면도 있어서 힘들 수 있어요. 이 친구를 책임지고 보호해줄 준비가 되어 있는지 생각해보세요.
- 선생님
가끔 예상 못한 '꿀잼' 에너지로 수업을 재밌게 만들 수 있어요. 하지만 개인적인 감정을 너무 드러내면 '민망'할 수 있으니 주의하세요.
- 선배
부탁을 거절 못 하는 선배라 이용하고 싶은 마음이 들 수 있어요. 하지만 그러지 마세요. 나중에 선배가 여러분에게 곤란한 부탁을 할 수도 있어요. 적당한 거리를 유지하세요.
- 친구, 후배
큰 기대는 하지 마세요. 하지만 문제를 일으키거나 방해되는 친구는 아니에요. 다만 group 과제할 때는 잘 챙겨줘야 해요.`,
  },

  BACCA: {
    type: "BACCA",
    category: "겉모습과 속마음이 다른 유형",
    desc: `'반전 매력' 타입`,
    sub: `학교의 미스터리 아이돌!`,
    char: `여러분은 진정한 '반전 매력' 타입이에요! 겉으로 보이는 모습과 속마음이 '180도 다른' 미스터리한 매력을 가지고 있어요.
속으로는 '아이' 같은 순수한 면이 있어서 칭찬받거나 인정받으면 쉽게 '심쿵'하는 스타일이에요. 그래서 늘 남의 눈치를 보며 '좋은 사람' 이미지를 유지하려고 노력하죠.
하지만 판단력이 약하고 다소 '다크'한 면이 있어서 감정 표현이 서툴러요. 이런 성향 때문에 주변 사람들이 여러분의 진짜 마음을 잘 이해하지 못할 때도 있어요. 여러분이 유난히 친절하게 다가올 때는, 뭔가 바라는 게 있을 가능성이 높아요. 우울할 때는 오히려 더 밝게 웃으며 '반전 매력'을 드러내기도 해요.
이런 성향을 개선하려면 이성적인 사고력을 키우고, 감정을 솔직하게 표현하는 연습을 해보는 것이 좋아요. 그렇게 하면 주변 사람들과의 관계도 더 건강해지고, 진정한 매력을 잘 보여줄 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '반전 매력' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 매력을 믿어요. 감정을 솔직히 표현하고, 자신감을 키우는 연습을 해보세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분의 진짜 모습을 알아주길 바란다면, 자신감을 키우는 게 중요해요. 좀 더 '독립적'이 되어야 해요.
수업 시간에 질문하기 어려워할 수 있어요. 하지만 용기를 내서 "이해가 안 가는데 설명해주실 수 있나요?"라고 말해보세요.
동아리 활동에서는 연극부나 방송부에서 '반전 매력'을 발휘할 수 있어요. 하지만 진짜 자신의 모습도 보여주는 연습을 해보세요.
시험 기간에는 스트레스 받아도 겉으로 웃는 척할 수 있어요. 하지만 힘들면 친구들에게 솔직히 말해보세요. "나 지금 좀 힘들어"라고 말하는 것부터 시작해보는 건 어떨까요?
진로를 고민할 때는 연기자, 가수, 유튜버 같이 다양한 모습을 보여줄 수 있는 직업을 생각해볼 수 있어요. 하지만 그전에 "진짜 나는 어떤 모습일까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
세심하게 신경 써주지 않으면 금방 '불안' 해질 수 있어요. 여러분이 '이성적'으로 대처하지 않으면, 어느새 같이 우울해질 수 있으니 조심하세요.
- 선생님
이 선생님의 수업에서는 표면적인 의사소통 너머를 볼 필요가 있습니다. 선생님의 말씀과 행동을 종합적으로 관찰하고 이해하려 노력하세요. 이를 통해 선생님의 진정한 의도와 기대사항을 파악할 수 있습니다. 이런 이해를 바탕으로 수업에 참여한다면, 더 효과적인 학습 경험을 만들어갈 수 있을 것입니다.
- 선배
원하는 바를 직접 말하지 않고 '돌려 말하기'를 좋아해요. 이런 선배와 잘 지내려면 선배의 마음을 잘 헤아려야 해요. 그렇지 않으면 선배가 갑자기 '멘붕'에 빠질 수도 있어요.
- 친구, 후배
이런 친구가 편안함을 느낄 수 있는 환경을 만들어주세요. 함께 공부할 때는 조용한 장소를, 놀 때는 친구가 좋아하는 곳을 선택해주는 센스를 발휘해보세요.`,
  },

  BACCB: {
    type: "BACCB",
    category: "정에 끌려 현실감이 약한 유형",
    desc: `'정에 눈먼 천사' 타입`,
    sub: `학교의 순수 동자!`,
    char: `여러분은 진정한 '정에 눈먼 천사' 타입이에요! 주변 사람들이 보기에 '진짜' 좋은 사람으로 비춰질 거예요.
인간관계에서 의리가 넘치고, 남을 돕는 걸 좋아하는 '천사' 같은 존재죠. 하지만 가까운 친구들은 여러분을 좀 걱정할 수도 있어요. 왜냐하면, 다소 다크한 면이 있고, 정확한 판단력이 부족해 보일 때가 있어서 혼자서 상황을 헤쳐 나가기 힘들어 보이거든요.
정에 너무 약하다 보니 나쁜 사람들에게 쉽게 속아 넘어갈 가능성도 커요. 그래서 항상 손해 보는 '호구'가 되기 쉬우니 주의해야 해요.
여러분이 점검해야 할 것은 첫째, '밝은 성격'을 만드는 거예요. 취미나 즐거운 활동을 찾아서 즐겨보세요. 둘째, 남을 제대로 보는 '이성적 판단력'을 키워야 해요. 특히 여러분을 'ATM'처럼 이용하려는 사람들을 경계해야 합니다!
전체적으로 '머리'와 '지성'을 키우는 것이 중요해요. 여러분의 의리와 따뜻한 마음에 이런 능력들이 더해지면 진정한 '인싸'로 성장할 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '정에 눈먼 천사' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 조금 더 현실적이고 이성적으로 생각하는 연습을 해보세요. 그럼 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `성실함이 '무기'가 될 수 있지만, '현실 감각'이 부족하면 학교생활 적응이 어려울 수 있어요.
수업 시간에 열심히 듣지만 이해가 안 될 수 있어요. 모르는 건 꼭 질문하세요. "이해가 잘 안 가요. 다시 설명해주실 수 있나요?"라고 말하는 연습을 해보세요.
동아리 활동은 다양하게 경험해보세요. 봉사 동아리, 학습 동아리, 예술 동아리 등 여러 가지를 해보고 자신에게 맞는 걸 찾아보세요.
시험 기간에는 친구들 도와주느라 정작 본인 공부는 못할 수 있어요. 'NO'라고 말하는 연습도 해보세요. "미안해, 나도 공부해야 해"라고 말하는 것부터 시작해보는 건 어떨까요?
진로를 고민할 때는 사회복지사, 상담사, 간호사 같이 남을 돕는 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업으로 내 미래는 괜찮을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
이런 친구 덕분에 '득'볼 일은 많을 거예요. 하지만 감사한 마음을 꼭 표현해주세요. 자기 표현이 서툴 수 있으니 관심 가지고 자주 대화해주세요.
- 선생님
이 선생님은 안정적이지만 때로 적극성이 부족할 수 있습니다. 학생들의 성실한 태도와 경청하는 자세를 높이 평가하십니다. 수업에 성실히 참여하고 선생님의 지도를 잘 따르면, 원활한 학습 관계를 유지할 수 있을 것입니다.
- 선배
이 선배 주변에 '꿀 빠는' 사람들이 많을 거예요. 달콤한 말 몇 마디에 모든 걸 다 줄 수 있는 타입이니까요. 하지만 그렇게 이용하려고 하지 마세요. 진심으로 대하면 좋은 선후배 관계 만들 수 있어요.
- 친구, 후배
마음만 먹으면 이 친구를 '조종'할 수 있을 거예요. 하지만 그러다 모든 책임이 여러분에게 올 수 있어요. 함께 성장하는 관계를 만들어보세요.`,
  },

  BACCC: {
    type: "BACCC",
    category: "과잉 참견을 하는 유형",
    desc: `'참견왕 오지라퍼' 타입`,
    sub: `학교의 뜻밖의 조언가!`,
    char: `여러분은 진정한 '참견왕 오지라퍼' 타입이에요! 사실 자기 일도 잘 못 챙기면서 남의 일에는 지나치게 관여하는 경향이 있어요.
누군가 도움이 필요하면 '무조건' 달려가고, 때론 과하게 '간섭'할 때도 있어요. 그래서 이런 성격 때문에 나쁜 사람들에게 쉽게 속을 수 있으니 주의가 필요해요.
재미있는 점은, 사실 여러분은 사람들과 친해지는 데 큰 관심이 없고 감정 표현도 서툴지만, 남의 일에 참견은 많다는 거예요. 혹시 스스로의 공허함이나 불안을 채우기 위해 이런 행동을 하고 있는 건 아닌지 생각해볼 필요가 있어요.
인생에서 가장 소중한 건 '나' 자신이라는 걸 절대 잊지 마세요. 깊이 생각하고 자신을 돌아보며 변화를 시도하는 것이, 이러한 단점을 고칠 수 있는 유일한 방법이에요. 자신에게도 충분히 시간과 관심을 가져주세요.`,
    result: `기억하세요! 이 타입은 '참견왕 오지라퍼' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    남의 일에 참견하기 전에 자신의 일에 집중하는 연습을 해보세요. 그리고 시간을 효율적으로 사용하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `남의 공부에 관여하다 정작 자기 공부할 시간을 놓칠 수 있어요.
수업 시간에 친구들 답 훔쳐보려고 하지 마세요. 모르면 선생님께 질문하는 게 더 도움 돼요. "이 부분이 이해가 안 가요. 다시 설명해주실 수 있나요?"라고 말하는 연습을 해보세요.
동아리 활동은 혼자 할 수 있는 걸 찾아보세요. 독서부나 미술부 같은 곳은 어떨까요? 남의 일에 참견하지 않고 자신의 능력을 키울 수 있어요.
시험 기간에는 친구들 공부법 지적하느라 정작 본인 공부는 못할 수 있어요. 자신의 공부에 집중하는 연습을 해보세요.
진로를 고민할 때는 상담사나 교사 같은 직업을 떠올릴 수 있어요. 하지만 그전에 "내가 정말 남을 도울 수 있는 능력이 있을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
기본적으로 나쁜 사람은 아니지만 좀 '답답'할 수 있어요. 좋은 뜻으로 한 일이 오히려 문제를 만들 수 있어요. 관계를 유지하고 싶다면 뒷정리할 준비를 하세요.
- 선생님
수업 외 관계에서는 적절한 거리를 유지하는 것이 좋습니다. 선생님의 업무 처리 방식이 때로 일관적이지 않을 수 있으니, 과도한 기대는 삼가는 것이 현명할 수 있습니다. 학업에 중점을 두고, 개인적인 사안은 스스로 해결하는 능력을 기르는 것이 도움이 될 것입니다.
- 선배
학교에서 리더 역할을 맡기 어려울 수 있어요. 그래도 선배의 좋은 점은 배우되, 부족한 부분은 여러분이 채워나가세요.
- 친구, 후배
공부든 친구 관계든 항상 조금 부족한 타입이에요. 열정이 부족해 보일 수 있죠. 하지만 그 친구의 장점을 찾아 격려해주는 것도 좋아요.`,
  },

  BBAAA: {
    type: "BBAAA",
    category: "좋은 일만 꿈꾸는 유형",
    desc: `'마이웨이 꿀잼 인생' 타입`,
    sub: `학교의 행복 추구 요정!`,
    char: `여러분은 진정한 '마이웨이 꿀잼 인생' 타입이에요! 좋아하는 것과 좋아하는 사람들에게만 '찐'으로 관심을 두고, 사회적인 문제에는 별로 관심이 없는 스타일이죠.
세상 일에는 '쿨'하게 거리를 두고, 취미나 여가 같은 '꿀잼' 분야에만 집중해요. '머리'도 좋고 판단력도 뛰어나지만, 복잡한 문제에 자신의 능력을 쓰고 싶어 하진 않아요. 좋아하는 사람들과 어떻게 하면 더 재미있게 지낼 수 있을지에만 관심이 있어요. 배려도 주로 자신이 중요하게 여기는 관계 안에서만 이뤄지죠.
당신의 생각이나 행동이 워낙 독특해서 '평범한' 사람들은 이해하지 못할 때도 있어요. '꿀잼'을 추구하는 것도 좋지만, 조금은 '평범함'도 익혀가며 사회적인 균형을 맞춘다면, 더욱 완벽한 타입이 될 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '마이웨이 꿀잼 인생' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 생각과 재미 추구 방식은 정말 멋져요. 하지만 가끔은 '재미없는' 일에도 집중하고, 시간을 잘 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `프로젝트 수업이나 단기 활동이 잘 맞을 거예요.
수업 시간에 재미없는 과목은 집중 못 할 수 있어요. 하지만 그 과목을 재미있게 만드는 방법을 찾아보세요. 예를 들어, 역사를 웹툰으로 그려보는 건 어떨까요?
동아리 활동은 취미를 살릴 수 있는 곳으로 가보세요. 밴드부, 영화 감상부, 보드게임부 같은 데서 여러분의 재능이 빛날 거예요.
학교 행사 때 아이디어 내는 걸 좋아할 거예요. 축제 기획단에 지원해보는 건 어떨까요? 여러분의 독특한 생각이 학교를 '꿀잼' 공간으로 만들 수 있을 거예요.
진로를 고민할 때는 크리에이터, 게임 기획자, 여행 가이드 같은 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업이 정말 나의 '꿀잼' 인생과 맞을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
'찐'으로 좋은 친구가 될 수 있어요. 적어도 '노잼' 관계는 아닐 거예요. 하지만 함께 성장하고 싶다면 여러분의 이해심이 많이 필요할 수 있어요.
- 선생님
이 선생님과는 일반적인 학생-교사 관계를 유지할 수 있습니다. 하지만 교육적 범위를 넘어선 특별한 관심이나 지원을 기대하기는 어려울 수 있습니다. 선생님은 전문적이고 공식적인 관계를 선호하실 수 있으니, 이를 이해하고 존중하는 태도로 수업에 임하는 것이 좋습니다.
- 선배
후배들에게 어렵게 굴거나 강한 책임감을 요구하진 않을 거예요. 편하게 대할 수 있지만, 멘토 역할을 기대하긴 어려울 수 있어요.
- 친구, 후배
자유롭게 의견을 말하고 아이디어를 낼 수 있는 활동에 어울려요. 학생회 기획부나 학교 신문반 같은 곳에서 빛을 발할 수 있을 거예요.`,
  },

  BBAAB: {
    type: "BBAAB",
    category: "회복탄력성이 강한 유형",
    desc: `'투페이스 밸런스 킹' 타입`,
    sub: `학교의 만능 스위치!`,
    char: `여러분은 진정한 '투페이스 밸런스 킹' 타입이에요! 자기 인생을 철저히 '맞춤 제작'하며, 상황에 따라 마음가짐과 행동을 스위치처럼 바꿀 수 있는 합리적인 사람이에요.
어떤 일에 지나치게 집착하지 않고, 한 번 실패했다고 해서 쉽게 주저앉지도 않아요. 회복 능력이 뛰어나 어려운 상황에서도 다시 일어설 수 있어요. 이런 모습에 주변 친구들이 부러워할 만해요.
머리도 좋고 감성도 풍부해서 인생을 즐길 수 있는 '꿀팁'을 많이 가지고 있어요. 노는 것도 좋아하지만 절제할 줄 알고, 다른 사람들이 스트레스를 100 느낄 때 여러분은 50 정도밖에 안 느낄 만큼 멘탈이 강한 타입이에요.
단, '융통성'이 지나쳐서 '변덕'으로 보이지 않도록 주의가 필요해요. 스스로의 중심을 잘 잡아가며 균형을 유지한다면, 인생을 더욱 즐겁게 살아갈 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '투페이스 밸런스 킹' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 다재다능함과 적응력은 정말 대단해요. 하지만 가끔은 한 가지 일에 깊이 집중하고, 시간을 잘 관리하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `상황 대처 능력이 '갑'인 여러분은 과도한 자신감으로 오히려 기회를 놓칠 수 있어요.
수업 시간에 모든 과목을 쉽게 따라갈 수 있겠지만, 한 과목을 정해서 '집중'해보는 건 어떨까요? "이 과목은 꼭 1등 할 거야!"라고 결심하고 도전해보세요.
동아리 활동은 다양하게 경험해보세요. 학술, 예술, 체육 등 여러 분야를 섭렵할 수 있을 거예요. 하지만 한 가지는 끝까지 해보는 것도 좋아요.
시험 기간에는 여러분의 '멘탈 갑' 능력이 빛을 발할 거예요. 스트레스 받지 않고 공부할 수 있겠지만, 너무 자만하지 마세요. 계획을 세우고 꾸준히 공부하는 습관을 들이세요.
진로를 고민할 때는 다재다능한 여러분의 능력을 살릴 수 있는 직업을 찾아보세요. 예를 들어, 방송 PD나 창업가 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이런 친구는 상황에 따라 '천사'가 될 수도, '악마'가 될 수도 있어요. 함께하고 싶다면 그 이유를 100가지쯤 준비해두세요!
.선생님
'머리'도 좋고 '계산'도 빠른 선생님이에요. 수업 시간에 농담하거나 가볍게 대하면 안 돼요. 하지만 열심히 하면 많은 걸 배울 수 있을 거예요.
- 선배
이런 선배는 여러분을 철저하게 '분석'하고 있을 거예요. 선배의 평가를 그대로 받아들이지 마세요. 학교 일뿐만 아니라 사적으로도 친해지면 도움 받을 게 많을 거예요. 단, 선배 의견을 많이 따라주는 게 좋아요.
- 친구, 후배
뭐든 잘하는 친구겠지만, 너무 띄워주면 '자만'해서 다른 학교로 전학 갈 수도 있어요. 적당히 칭찬하고, 함께 공부하면서 서로 도와주세요.`,
  },

  BBAAC: {
    type: "BBAAC",
    category: "이기적인 인생을 즐기는 유형",
    desc: `'내가 곧 주인공' 타입`,
    sub: `학교의 자기애 만수르!`,
    char: `여러분은 진정한 '내가 곧 주인공' 타입이에요! 자신이 원하는 것에만 올인하며 살아가는 스타일이죠.
스스로를 사랑하는 '자기애'가 강해서 주변 친구들에게 '천사'나 '인성 갑' 같은 소리를 듣기는 어렵지만, 자기 관리 능력은 뛰어나서 큰 실수를 하거나 완전히 무너지는 행동은 하지 않아요.
이 타입은 보통 사람들과 인생관이 많이 달라요. 남들이 생각하는 '성공'과 여러분이 생각하는 '성공'은 완전히 다른 개념이에요. 여러분에게 중요한 것은 '재미'와 '즐거움'이니까요. 자신의 욕구, 감정, 즐거움에 모든 에너지를 쓰고, 다른 사람과의 의리나 정은 적당히만 지키는 편이에요.
더 나은 인생을 위해서는 '자기중심적인' 면과 '재미 추구' 성향을 조금 줄이는 것이 좋아요. 주변 사람들과도 조화를 이루며 살아가면, 더 많은 기회와 행복을 얻을 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '내가 곧 주인공' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자기애와 효율성은 정말 대단해요. 하지만 가끔은 다른 사람을 위해 시간을 쓰고, 계획적으로 생활하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'겸손'과 '온화'함을 기르는 게 중요해요.
수업 시간에 자신이 좋아하는 과목만 집중하고 나머지는 무시하는 경향이 있을 수 있어요. 하지만 모든 과목에 최선을 다해보세요. 나중에 도움될 수 있어요.
동아리 활동은 리더 역할을 맡아보는 게 어때요? 하지만 '독재자'가 되지 않도록 주의하세요. 다른 친구들의 의견도 듣는 연습을 해보세요.
시험 기간에는 효율적으로 공부하는 능력이 빛날 거예요. 하지만 친구들과 함께 공부하는 시간도 가져보세요. '윈윈' 할 수 있어요.
진로를 고민할 때는 CEO, 연예인, 유튜버 같이 자신을 드러낼 수 있는 직업을 생각해볼 수 있어요. 하지만 그 직업이 사회에 어떤 '가치'를 줄 수 있는지도 고민해보세요.`,
    human: `- 베프, 여자친구
이 친구와 관계를 유지하려면 '손해 보는 것 같다'는 느낌을 계속 받을 수 있어요. 하지만 그건 여러분의 능력에 따라 달라질 수 있어요. 너무 걱정하지 마세요.
- 선생님
이 선생님은 학생들과의 정서적 유대를 중요하게 여기실 수 있습니다. 학업 성취 못지않게 원활한 소통과 공감 능력도 가치 있게 평가하실 수 있어요. 선생님의 기분과 분위기에 주의를 기울이고, 적절히 반응하는 것이 도움이 될 수 있습니다. 이러한 노력을 통해 선생님과 좋은 관계를 유지하면 전반적인 학교생활에 긍정적인 영향을 미칠 수 있을 것입니다.
- 선배
선배를 만족시킬 수 있는 행동을 하면 여러분에게도 좋은 일이 생길 거예요. 학교 규칙만 따르기보다는 선배의 마음에 맞춰보세요.
- 친구, 후배
현실적으로 얻을 수 있는 것에 대해 명확하게 이야기하면 진심을 다해 도와줄 거예요. '솔직'하게 대화해보세요.`,
  },

  BBABA: {
    type: "BBABA",
    category: "타인을 지나치게 의식하는 유형",
    desc: `'숨은 실력 내적 갑' 타입`,
    sub: `학교의 미완성 보석!`,
    char: `여러분은 진정한 '숨은 실력 내적 갑' 타입이에요! '머리'도 좋고 '이성'도 뛰어나서 무슨 일이든 '대박' 결과를 낼 수 있는 '찐' 실력자예요.
하지만 항상 제대로 실력 발휘도 못해보고 '손절'하는 일이 많아요. 왜냐고요? 다른 사람들의 '평가'가 너무 신경 쓰이기 때문이에요. '좋은 애' 소리 듣고 싶어서 자신의 판단대로 행동하지 못하고 빛을 보지 못하는 거죠.
목표를 향해 달려가다 보면 항상 '착한 아이' 이미지만 유지하기 어려운 게 당연한데, 두 마리 토끼를 다 잡으려다 보니 힘들 수밖에 없어요.
조심스러운 행동과 정직한 생활로 '평판'은 좋지만, 자신을 위해서는 주변 눈치 좀 덜 봐도 돼요. 조금 더 '자신감' 갖고 도전해보세요. 여러분은 '성공' 가능성 200% 예요!`,
    result: `기억하세요! 이 타입은 '숨은 실력 내적 갑' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 숨겨진 능력을 믿어요. 다른 사람 눈치 보느라 시간 낭비하지 말고, 자신의 재능을 키우는 데 집중해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `겉으로 보이는 것 말고 내면에 숨겨둔 '찐' 실력이 있나요? 여러분을 인정해줄 사람들은 그런 '숨은 능력'을 궁금해해요.
수업 시간에 발표하는 걸 두려워하지 마세요. 여러분의 생각은 충분히 가치 있어요. "제 생각에는..."이라고 시작하는 연습을 해보세요.
동아리 활동에서는 리더 역할에 도전해보세요. 처음엔 어색하겠지만, 여러분의 숨은 능력을 발휘할 좋은 기회가 될 거예요.
시험 기간에는 친구들 눈치 보느라 제대로 공부 못할 수 있어요. '나는 나대로 열심히 한다'는 마인드로 공부해보세요.
진로를 고민할 때는 여러분의 숨은 재능을 살릴 수 있는 직업을 찾아보세요. 작가, 연구원, 상담사 같이 깊이 있는 일을 하는 게 어떨까요?`,
    human: `- 베프, 여자친구
특별한 단점은 없어요. 여러분도 상대방처럼 조심스럽게 행동하면 평화로운 관계를 유지할 수 있을 거예요.
- 선생님
이 선생님은 차분하고 안정적인 학습 환경을 선호하실 수 있습니다. 성실하게 수업에 참여하고 과제를 제때 제출하는 것이 중요합니다. 선생님의 페이스를 존중하며 적절한 기대치를 유지하면, 원활한 학습 관계를 형성할 수 있을 것입니다. 이러한 태도는 선생님께 긍정적으로 평가받을 수 있습니다.
- 선배
까다롭게 굴거나 대충 일하는 선배는 아니에요. 여러분이 크게 실수만 하지 않으면 학교생활 무난하게 할 수 있을 거예요. 체계적으로 계획 세우고 준비하는 선배들이 많으니 여러분도 그에 맞춰 열심히 하세요.
- 친구, 후배
관계에 큰 문제는 없지만, 특별히 친밀하지도 않아요. 그냥 무난하게 실수 없이 지내는 타입이에요. 사적으로 노는 건 그다지 재미없을 수 있어요.`,
  },

  BBABB: {
    type: "BBABB",
    category: "균형 감각이 뛰어난 유형",
    desc: `'평균의 아이콘' 타입`,
    sub: `학교의 완벽한 밸런서!`,
    char: `여러분은 진정한 '평균의 아이콘' 타입이에요! '평타는 치는' 삶이 가장 어렵다고들 하는데, 여러분은 그런 면에서 진정한 '갓생' 스타일이에요.
어느 하나 부족하거나 넘치지 않는, 완벽한 균형을 가진 마인드로 살아가고 있어요. 롤러코스터 없이 평화로운 학교생활을 즐기기에 아주 잘 맞는 성향이에요. 다만, 그만큼 특별한 재능을 눈에 띄게 드러내지는 않을 수 있어요.
무의식적으로 균형을 유지하려다가 자신의 성장에 스스로 브레이크를 걸고 있지는 않은지 한 번 생각해보세요. 어떤 성격이든 무조건 좋은 점이나 나쁜 점만 있는 것은 아니에요. 상황에 맞춰 필요한 성향을 발휘해 문제를 해결하거나 성공으로 이끌어가는 것이야말로 최고의 성격이에요.
여러분은 마음먹기에 따라 '대박' 성장을 이룰 수 있는 가능성을 가지고 있어요. 뛰어난 균형 감각에 융통성과 상황 분석력을 더해 간다면, 더욱 가치 있는 삶을 살 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '평균의 아이콘' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 균형 잡힌 성격은 정말 멋져요. 하지만 가끔은 한 가지에 '올인'하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 특별한 사람이 될 수 있을 거예요. 파이팅!`,
    school: `모든 면에서 '평균'이라는 건 특별히 눈에 띄는 게 없다는 뜻이기도 해요.
수업 시간에 모든 과목을 '무난하게' 따라가겠지만, 한 과목을 정해서 '올인'해보는 건 어떨까요? "이번 학기에는 수학에서 1등 해볼래!"라고 목표를 세워보세요.
동아리 활동은 다양하게 경험해보세요. 예술, 체육, 학술 등 여러 분야를 골고루 해보면서 자신의 '숨은 재능'을 찾아보세요.
시험 기간에는 모든 과목을 '평균적으로' 공부하겠지만, 가끔은 한 과목에 '올인'해보세요. 그러다 보면 자신의 '진짜 관심사'를 발견할 수 있을 거예요.
진로를 고민할 때는 자신의 '장점'을 더 부각시켜보세요. 선생님이나 친구들에게 물어보고, 자기 분석도 해보면서 '나만의 강점'을 찾아보세요.`,
    human: `- 베프, 여자친구
정말 '복'받은 거예요! 이 정도면 다른 조건은 따질 필요도 없어요. 그냥 '감사'하며 지내세요.
- 선생님
수업이 '물 흐르듯' 진행될 거예요. 특별히 좋은 점은 없지만, 문제될 만한 것도 없어요. 성실하게 수업 듣고 과제하면 무난하게 지낼 수 있어요.
- 선배
이런 선배에게 불만이 있다면 여러분의 마음가짐을 먼저 체크해보세요. 아마 문제는 선배가 아니라 여러분 마음에 있을 거예요.
- 친구, 후배
어떤 일이든 '실수 없이' 잘 해내는 친구예요. 다만 한 가지 일에 '몰입'해서 해결하려는 '근성'이 부족할 수 있어요. 가끔 '자극'을 주면 더 멋진 친구가 될 거예요.`,
  },

  BBABC: {
    type: "BBABC",
    category: "실리에 강한 현실적인 유형",
    desc: `'계산기 두뇌' 타입`,
    sub: `학교의 실리주의 인재!`,
    char: `여러분은 진정한 '계산기 두뇌' 타입이에요! 이해타산이 빠르고, 당장의 이득에 집중하는 경향이 있어요.
친구들과 타협하거나 상대를 인정하고 자신을 낮춰야 할 때도 '내가 최고'라는 마음 때문에 인간관계가 어색해질 때가 있어요. '열정페이'보다는 '워라밸'을 중요하게 생각해서, 다른 사람들 눈에는 조금 느슨한 '슬랙러'처럼 보일 수 있어요. "어떻게 하면 내 마음대로 하면서도 미움받지 않을까?"라는 고민을 많이 하기도 하죠.
하지만 세상은 혼자 살아가는 게 아니에요! 여러분의 능력은 뛰어나지만, 혼자서는 분명 한계가 있을 수 있어요. 다른 친구들과 협력해서 더 큰 '시너지'를 만들어보는 건 어떨까요? 그렇게 하면 여러분이 가진 능력과 재능이 더욱 빛을 발할 거예요.`,
    result: `기억하세요! 이 타입은 '계산기 두뇌' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 빠른 판단력과 효율성은 정말 대단해요. 하지만 가끔은 장기적인 목표를 세우고, 다른 사람들과 협력하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `판단력이 뛰어나서 능력은 있지만, 친구들과 협력하는 게 어려울 수 있어요.
수업 시간에 발표나 토론할 때 '팩트 폭격기'가 될 수 있어요. 하지만 가끔은 다른 친구들의 의견도 들어보세요. 여러분의 아이디어에 친구들의 생각이 더해지면 더 멋진 결과가 나올 수 있어요.
동아리 활동은 개인 기술을 살릴 수 있는 곳으로 가보세요. 코딩 동아리나 발명 동아리 같은 데서 여러분의 실력을 뽐내보는 건 어떨까요?
시험 기간에는 효율적으로 공부하는 능력이 빛을 발할 거예요. 하지만 친구들과 스터디 그룹을 만들어보는 것도 좋아요. 서로의 장점을 배우면서 함께 성장할 수 있어요.
진로를 고민할 때는 개인 능력을 발휘할 수 있는 직업을 생각해보세요. 프로그래머, 회계사, 변리사 같은 전문직은 어떨까요?`,
    human: `- 베프, 여자친구
평생 함께할 친구라면 오히려 이런 타입이 도움 될 수 있어요. 하지만 '대박 꿈'을 꾸는 사람이라면 맞지 않을 수 있어요.
- 선생님
이 선생님은 자신의 전문 분야에서 안정적인 지도를 제공하실 것입니다. 획기적인 변화보다는 꾸준한 노력과 진전을 중요하게 여기실 수 있습니다. 여러분이 성실하고 열심히 수업에 임한다면, 선생님도 그에 상응하는 지원과 격려를 해주실 것입니다. 서로 존중하며 노력하는 관계를 유지하는 것이 중요합니다.
- 선배
'리더' 역할과는 거리가 멀어요. 하지만 모든 친구들과 잘 지내고 신뢰를 쌓는다면, 나중에 좋은 기회가 올 수 있어요.
- 친구, 후배
너무 큰 '책임감'은 요구하지 마세요. 기본적인 일들을 기본적인 방식으로 하게 하세요. 주고받는 것에 대해 명확하게 이야기하는 게 좋아요.`,
  },

  BBACA: {
    type: "BBACA",
    category: "이성적 사람인 척하는 유형",
    desc: `'착한 아이 증후군' 타입`,
    sub: `학교의 숨은 반항아!`,
    char: `여러분은 진정한 '착한 아이 증후군' 타입이에요! 머리는 좋지만 실전에서 활용을 못 하는 '이론만렙' 스타일이죠.
주변 눈치를 너무 보느라 '좋은 애', '이성적인 애' 소리 듣는 데만 집중해요. 정작 자신이 하고 싶은 건 계속 참고만 있죠. '완벽한 모범생'이 되려다 보니 스트레스만 쌓여가요.
가끔은 '솔직'하게 표현하고, 하고 싶은 걸 해보는 건 어떨까요? 모든 사람한테 '천사'로 보일 필요는 없어요. 마음 속 깊은 곳에서 나오는 '진짜 내 목소리'에 귀 기울여보세요.
인생엔 즐거운 게 정말 많아요! 여러분의 뛰어난 '머리'를 주변 눈치 보는 데만 쓰지 말고 자신을 위해 써보세요. 용기 내서 외쳐보세요. "나도 내 맘대로 할 수 있다!"고요!`,
    result: `기억하세요! 이 타입은 '착한 아이 증후군' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 지식과 배려심은 정말 대단해요. 하지만 가끔은 자신의 욕구에 솔직해지고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `어떤 일이든 잘 해낼 수 있지만, 너무 위축돼 있어서 기회를 놓칠 수 있어요. 먼저 '밝은 에너지'를 갖도록 노력해보세요.
수업 시간에 발표하기 두려워하지 마세요. 여러분의 생각은 충분히 가치 있어요. "제 생각에는..."이라고 시작하는 연습을 해보세요.
동아리 활동에서는 평소 하고 싶었던 걸 선택해보세요. 밴드부, 연극부 같은 데서 숨겨둔 끼를 발산해보는 건 어떨까요?
시험 기간에는 효율적으로 공부하는 능력이 빛날 거예요. 하지만 가끔은 친구들이랑 수다 떨면서 스트레스도 풀어주세요.
진로를 고민할 때는 '안정적인' 직업만 생각하지 말고, 정말 하고 싶은 것도 고려해보세요. 여러분의 숨겨진 끼를 살릴 수 있는 직업은 없을까요?`,
    human: `- 베프, 여자친구
이 친구에게 받는 사랑은 만족스러울 거예요. 하지만 '서로 주고받는' 관계가 되려면 노력이 필요해요. 친구의 속마음을 이해하려 노력하고, 함께 행복해지는 방법을 찾아보세요.
- 선생님
걱정하지 마세요. 아무 문제 없을 거예요. 성실하게 수업 듣고 과제하면 선생님도 좋아하실 거예요.
- 선배
이런 선배가 있다면 학교생활이 정말 편할 거예요. 분명 여러분에게 도움 될 거예요. 하지만 선배의 인간적인 면에도 관심 가져주세요.
- 친구, 후배
이 친구가 무슨 생각하는지, 만족하는지 불만인지 알기 어려워요. 겉으로 불평 안 한다고 문제없다고 생각하면 큰코다칠 수 있어요. 속으로는 불만 가득할 수 있거든요. 솔직하게 대화해보세요.`,
  },

  BBACB: {
    type: "BBACB",
    category: "안전지대에 안주하는 유형",
    desc: `'안전벨트 꽉 매는' 타입`,
    sub: `학교의 신중한 거북이!`,
    char: `여러분은 진정한 '안전벨트 꽉 매는' 타입이에요! 겉으로는 '이성적'으로 보이지만, 사실 너무 '소심'해서 빛을 발하지 못하고 있어요.
'이성적'으로 행동해야 한다는 '강박'이 있어서 자신의 욕구나 밝은 성격을 숨기고 있어요. 그래서 가끔 '다크'한 애처럼 보일 수 있어요.
이런 가치관이 '틀렸다'고 할 순 없지만, 여러분을 위해선 좋지 않을 수 있어요. 항상 '소극적'으로 살면 경쟁에서 밀릴 수 있거든요. 인생은 '배틀로얄' 같아요. 가끔은 '저돌적'으로, '적극적'으로 무언가를 얻으려고 노력해보세요!`,
    result: `기억하세요! 이 타입은 '안전벨트 꽉 매는' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 신중함과 깊은 사고력은 정말 멋져요. 하지만 가끔은 모험을 즐기고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이런 타입은 '빠른' 반응이 필요한 활동은 잘 안 맞을 수 있어요. 성격상 '기술' 관련 활동이나 '학구적'인 일이 좋아요.
수업 시간에 발표하기 두려워하지 마세요. 여러분의 생각은 충분히 가치 있어요. "제 생각에는..."이라고 시작하는 연습을 해보세요.
동아리 활동은 컴퓨터 동아리나 독서 동아리 같은 곳이 좋을 거예요. 하지만 가끔은 밴드부나 연극부 같은 활동적인 동아리에도 도전해보세요.
시험 기간에는 차분하게 공부하는 능력이 빛날 거예요. 하지만 가끔은 친구들과 함께 공부하면서 서로 도와주는 것도 좋아요.
진로를 고민할 때는 연구원, 프로그래머, 작가 같은 직업을 생각해볼 수 있어요. 하지만 그전에 "나는 정말 이 일을 하고 싶을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
이 친구는 '조용한' 관계를 좋아해요. '화려'하고 '역동적'인 걸 좋아한다면 맞지 않을 수 있어요.
- 선생님
이 선생님은 신중하고 깊이 있는 사고를 중요시하십니다. 의사결정에 시간이 필요할 수 있으니 인내심을 갖고 기다려주는 것이 좋습니다. 자세히 설명하고 충분한 시간을 드리면 더 나은 소통과 이해가 가능할 것입니다. 선생님의 사려 깊은 접근 방식을 존중하는 태도가 도움이 될 수 있습니다.
- 선배
꼭 필요한 것만 말해주고 여러분 일에 잘 관여하지 않을 거예요. 일이 잘 안 돼도 바로 도와주진 않고 지켜볼 거예요. 스스로 해결하는 능력을 기르세요.
- 친구, 후배
사람 많이 만나는 활동은 잘 안 맞아요. 문서 정리나 컴퓨터 관련 일을 잘할 거예요. 이 친구의 사생활에 너무 깊이 들어가지 않는 게 좋아요.`,
  },

  BBACC: {
    type: "BBACC",
    category: "세상을 바라보고만 있는 유형",
    desc: `'관찰자 모드 풀타임' 타입`,
    sub: `학교의 은둔 고수!`,
    char: `여러분은 진정한 '관찰자 모드 풀타임' 타입이에요! 머릿속은 '계산기' 돌아가듯 바쁘지만, 겉으로는 '무반응'인 스타일이죠.
모든 일에 '정확한 눈'을 가졌지만, '내 맘대로' 결정하거나 처리하지 않아요. 그래서 친구들에게 '욕'먹을 일은 없어요.
'인기' 얻으려고 하지도 않고, '비난' 받을 일도 안 하려고 해요. 인생을 더 '꿀잼'으로, '성공'으로 만들고 싶다면 다른 친구들이랑 어울리는 법을 배워야 해요.
좀 '고집'이 세고 남이랑 '타협'하거나 '협조'하려는 마음이 없어서, 가끔 좋은 기회를 스스로 '패스'해버릴 수 있어요.`,
    result: `기억하세요! 이 타입은 '관찰자 모드 풀타임' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 정확한 판단력과 신중함은 정말 대단해요. 하지만 가끔은 적극적으로 나서고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분을 아는 사람이 있어야 기회도 생겨요. 가끔은 누군가보다 '먼저' 나서야 할 때가 있어요.
수업 시간에 발표를 꺼려하지 마세요. 여러분의 생각은 충분히 가치 있어요. "제 의견은 이런데요..."라고 말하는 연습을 해보세요.
동아리 활동은 도서부나 학습 동아리 같은 곳이 좋을 거예요. 하지만 가끔은 밴드부나 방송부 같은 활동적인 동아리에도 도전해보세요.
시험 기간에는 혼자 공부하는 능력이 빛날 거예요. 하지만 가끔은 스터디 그룹에 참여해보세요. 다른 친구들의 아이디어도 들어보면 좋아요.
진로를 고민할 때는 연구원, 분석가, 프로그래머 같은 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업이 나와 정말 잘 맞을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
특별히 큰 문제는 없을 거예요. '바람', '도박' 같은 걱정거리는 없어요. 하지만 '재미'있게 뭔가를 즐기며 살기엔 좀 부족할 수 있어요.
- 선생님
서로 '존중'하면서 수업에만 집중한다면 아무 문제 없이 잘 지낼 수 있어요. 선생님을 '존중'하고 수업에 열심히 참여하세요.
- 선배
'합리적'으로 일을 잘 처리할 선배예요. 선배 말씀대로만 하면 학교생활 무난할 거예요. 하지만 사적으로 너무 가까워지려 하지 마세요. 그런 관계를 좋아하지 않을 수 있어요.
- 친구, 후배
'꼼꼼함'이 필요한 일은 잘할 거예요. 하지만 다른 사람들이랑 협력해야 하는 일에서는 '내 방식대로' 하려고 할 수 있으니 조심하세요.`,
  },

  BBBAA: {
    type: "BBBAA",
    category: "유아적 성향이 높은 유형",
    desc: `'영원한 피터팬' 타입`,
    sub: `학교의 떠오르는 아이돌!`,
    char: `여러분은 진정한 '영원한 피터팬' 타입이에요! 전형적인 '아이돌' 같은 성격을 모두 갖고 있죠.
세상 모든 것에 '호기심 뿜뿜'하고 '눈치제로'로 자유분방해요. 자신이 원하는 걸 위해서라면 다른 사람에게 좀 '민폐'를 끼칠 수 있는 일도 할 수 있어요.
하지만 동시에 '인기' 얻고 싶어 하고, '칭찬'받는 걸 정말 좋아해요. 다른 사람들의 기대에 부응하려고 '오버'할 정도로 노력하기도 하죠. 그래서 '변덕쟁이'처럼 보일 수 있어요.
이 타입은 적당한 '판단력'과 '의리'도 있어서 인생 계획 세우는 데 큰 문제는 없어요. 요즘같이 '가면' 쓰고 사는 사람 많은 시대에 오히려 '솔직'하고 '진정성' 있는 멋진 타입일 수 있어요. 어쩌면 부러워할 만한 사람일지도 몰라요!`,
    result: `기억하세요! 이 타입은 '영원한 피터팬' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 밝은 에너지와 솔직함은 정말 멋져요. 하지만 가끔은 진지해지고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분의 '과한 아기 같은' 성향은 '이성'과 '감성'의 균형이 필요한 학교 입장에서는 좀 '치우친' 성향이에요. 하지만 판단력이 없는 건 아니니까, 상황에 따라 여러분의 어떤 면을 보여줘야 할지 '이미지 메이킹' 후에 도전해보세요.
수업 시간에 호기심 가는 게 있다면 망설이지 말고 질문하세요. 하지만 다른 친구들에게 방해되지 않도록 주의하는 것도 잊지 마세요.
동아리 활동은 여러분의 에너지를 마음껏 발산할 수 있는 곳으로 가보세요. 댄스부, 연극부, 방송부 같은 데서 여러분의 끼를 마음껏 보여주세요.
시험 기간에는 집중력이 부족할 수 있어요. 공부할 때 '뽀모도로' 기법을 써보는 건 어떨까요? 25분 공부하고 5분 쉬는 방식으로 해보세요.
진로를 고민할 때는 엔터테이너, 유튜버, 선생님 같이 사람들과 소통하는 직업을 생각해볼 수 있어요. 하지만 그전에 "이 직업이 나의 성격과 정말 잘 맞을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
이 친구에게 모든 걸 맞춰주기는 어려워요. 또 이 친구는 여러분 눈치를 너무 많이 볼 수 있어요. 어디까지 배려해야 할지 잘 정해두세요.
- 선생님
이 선생님의 조언은 경청하되, 중요한 결정은 스스로 내리는 것이 좋습니다. 모든 세부사항에 얽매이지 말고, 큰 그림에 집중하세요. 때때로 자신의 의견을 자신감 있게 표현하는 것도 중요합니다. 선생님의 지도를 존중하면서도 자율성을 발휘하는 균형 잡힌 태도가 도움이 될 수 있습니다.
- 선배
학교에서 '성공'하는 것보다 '인간관계'나 '기분' 같은 걸 더 신경 쓰는 선배예요. 그런 점을 이해하고 대하면 학교생활 편할 거예요.
- 친구, 후배
무리하게 일을 시키거나 부담 주면 실수할 수 있어요. 단순하고 재미있게 할 수 있는 일을 주는 게 좋아요. 큰 책임을 맡기긴 어려울 수 있어요.`,
  },

  BBBAB: {
    type: "BBBAB",
    category: "본능이 충만한 유형",
    desc: `'열정 만수르' 타입`,
    sub: `학교의 끝없는 롤러코스터!`,
    char: `여러분은 진정한 '열정 만수르' 타입이에요! 세상에는 '꿀잼' 할 수 있는 일이 너무 많다는 걸 잘 알고 있죠.
보통 사람들보다 자신의 욕구를 채우는 데 '올인'하고, 세상을 거대한 '놀이터'처럼 생각해요.
하지만 '머리'가 나쁘거나 '지성'이 낮은 건 절대 아니에요. 그래서 '개념 없이' 행동하거나 남에게 무조건 '민폐' 끼치는 일은 없어요. 오히려 이 에너지로 봉사활동이나 종교 활동에 '올인'하는 경우도 많아요.
높은 '호기심'은 공부하거나 새로운 걸 탐험하는 데 쓰일 수도 있고, 예술이나 게임에 빠지는 데 쓰일 수도 있어요. '반전' 행동으로 주변 사람들을 놀라게 하는 것도 이 타입의 특징이에요.`,
    result: `기억하세요! 이 타입은 '열정 만수르' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 넘치는 에너지와 창의력은 정말 대단해요. 하지만 가끔은 한 가지에 집중하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'틀에 박힌' 공부 스타일보다는 '자유롭게' 창의적인 활동을 하는 게 좋아요.
수업 시간에 호기심 가는 게 있다면 망설이지 말고 질문하세요. 하지만 수업에 방해되지 않도록 주의하는 것도 잊지 마세요.
동아리 활동은 여러분의 에너지를 마음껏 발산할 수 있는 곳으로 가보세요. 예술 동아리나 봉사 동아리에서 여러분의 재능을 펼쳐보세요.
시험 기간에는 집중력이 부족할 수 있어요. 공부할 때 '게이미피케이션'을 활용해보는 건 어떨까요? 공부를 게임처럼 만들어 재미있게 해보세요.
진로를 고민할 때는 크리에이터, 예술가, 탐험가 같이 창의력을 발휘할 수 있는 직업을 생각해보세요. 하지만 그전에 "이 직업이 나의 열정을 지속할 수 있을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
여러분도 이 타입이라면 '찰떡궁합'이겠지만, 반대라면 맞추기 '개어려울' 수 있어요.
- 선생님
이 선생님과의 관계에서는 감정적 균형이 중요할 수 있습니다. 초기부터 상호 존중과 이해를 바탕으로 소통하는 것이 좋습니다. 선생님의 감정 변화에 주의를 기울이고, 적절히 대응하는 것이 도움이 될 수 있습니다. 원활한 학습 관계를 위해 처음부터 긍정적인 상호작용을 유지하도록 노력하세요.
- 선배
가끔 '기분파' 같은 모습 보일 수 있는 선배예요. 선배 '기분' 잘 맞춰주면서 의견 말하세요. 그렇지 않으면 여러분 의견이 '무시'될 수 있어요.
- 친구, 후배
'노잼' 일에는 흥미 못 느껴서 잘 못해요. '크리에이티브'하게 아이디어 내면서 할 수 있는 일 맡겨보세요. '대박' 성과 낼 수도 있어요.`,
  },

  BBBAC: {
    type: "BBBAC",
    category: "숲속의 야생마 유형",
    desc: `'자유로운 영혼' 타입`,
    sub: `학교의 미지의 전설!`,
    char: `여러분은 진정한 '자유로운 영혼' 타입이에요! 잡으려 해도 안 잡히고, 길들이려 해도 안 길들여지는 '학교의 전설' 같은 존재죠.
언제 어디서나 '내 맘대로' 살아가는 게 인생 목표예요. 다른 사람에게 '민폐' 끼치진 않지만, '간섭'도 싫어해요. 그냥 자기가 가고 싶은 곳으로 '힙하게' 달려가고 싶어 해요.
그래서 '평범한 직장인'이 되긴 어려울 수 있어요. 하지만 '머리'가 나쁘거나 '지성'이 부족한 건 절대 아니에요. 오히려 남들 못지않은 '상식'과 '판단력'을 가지고 있죠.
다만 주변 '눈치' 보느라 자신의 '자유'를 포기하고 싶지 않아서, '평범한 삶'을 거부하는 거예요. 자유롭게 즐기면서 하고 싶은 걸 하며 살 수 있다면 그게 바로 '인생 꿀잼'이라고 생각해요.`,
    result: `기억하세요! 이 타입은 '자유로운 영혼' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자유로움과 창의력은 정말 대단해요. 하지만 가끔은 규율을 지키고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분이 '조직생활'에 맞는 사람인지 먼저 생각해보세요.
수업 시간에 집중하기 어려울 수 있어요. 하지만 자신만의 방식으로 공부하는 법을 찾아보세요. 예를 들어, 음악 들으면서 공부하거나 야외에서 공부하는 건 어떨까요?
동아리 활동은 자유로운 분위기의 동아리를 찾아보세요. 예술 동아리나 탐험 동아리 같은 곳에서 여러분의 개성을 마음껏 펼쳐보세요.
시험 기간에는 여러분만의 독특한 공부법을 개발해보세요. 암기할 내용을 랩으로 만들어 외우거나, 공부 내용을 그림으로 그려보는 건 어떨까요?
진로를 고민할 때는 프리랜서, 예술가, 여행 가이드 같이 자유로운 직업을 생각해보세요. 하지만 그전에 "이 직업으로 정말 내가 원하는 삶을 살 수 있을까?"라는 질문도 꼭 해보세요.`,
    human: `- 베프, 여자친구
이 친구와 사귀려면 대부분을 '양보'하고 '내버려 두겠다'는 각오가 필요해요. 하지만 관계가 오래될수록 서서히 여러분 페이스로 끌어들일 수 있어요. '단단'하게 밀어붙이지 말고 '부드럽게' 이끌어보세요.
- 선생님
선생님이 '맘껏 즐길 수 있는' 수업을 준비하면 큰 효과를 볼 수 있어요. 예를 들어, 야외 수업이나 체험 학습 같은 걸 제안해보세요.
- 선배
최대한 선배의 '기분'을 거스르지 않는 게 좋아요. 선배의 생활방식이 마음에 안 든다고 '간섭'하려다가는 '대환장 파티'가 될 수 있어요.
- 친구, 후배
스스로 '흥'을 내며 자유롭게 공부할 수 있는 분위기를 만들어주세요. 이 친구가 자기 '의무'만 다하고 있다면 그 이상 바랄 게 없어요.`,
  },

  BBBBA: {
    type: "BBBBA",
    category: "착한아이 증후군 유형",
    desc: `'천사표 착한애' 타입`,
    sub: `학교의 숨은 보석!`,
    char: `여러분은 진정한 '천사표 착한애' 타입이에요! 어떤 상황에서도 '싫어요'라고 말하지 못하고, 주변 눈치를 보다가 스스로 지치고 혼란에 빠지기 쉬운 타입이죠.
소문이나 험담을 그냥 흘려보내지 못하고, '내가 정말 문제일까?' 하고 고민하며 스스로를 의심하는 경향이 있어요. 자신의 감정을 솔직하게 표현하지 못해서 늘 조용하고 투명인간처럼 지낼 때가 많아요. 그래서 실제로는 '숨은 실력자'임에도 불구하고 그 능력을 충분히 발휘하지 못할 때도 있어요.
여러분은 '잠재력 만렙'이지만, 평생 빛을 보지 못할 수도 있어요. 다른 사람들의 시선에 조금만 덜 신경 쓰고, 용기를 내서 세상에 나선다면, 여러분의 기회가 올 거예요. 여러분의 진정한 가능성을 세상에 보여주세요!`,
    result: `기억하세요! 이 타입은 '천사표 착한애' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 배려심과 성실함은 정말 대단해요. 하지만 가끔은 자신의 의견을 말하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `고민할 일과 고민하지 말아야 할 일을 '현명하게' 구분하세요. '쿨'하게 넘어갈 줄도 알고, '당당하게' 받아들일 줄도 아는 태도가 여러분을 성장시킬 거예요.
수업 시간에 발표하기 두려워하지 마세요. 여러분의 생각은 충분히 가치 있어요. "제 의견은 이런데요..."라고 말하는 연습을 해보세요.
동아리 활동은 여러분의 숨은 재능을 발견할 수 있는 기회예요. 관심 있는 분야의 동아리에 가입해서 적극적으로 활동해보세요.
시험 기간에는 친구들 눈치 보느라 공부 못하는 일 없도록 해요. '나는 나대로 열심히 한다'는 마인드로 공부해보세요.
진로를 고민할 때는 여러분의 장점을 잘 살릴 수 있는 직업을 찾아보세요. 상담사, 교사, 사회복지사 같이 배려심을 발휘할 수 있는 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이 친구를 '평생 친구'로 선택했다면, 여러분은 어떤 타입인지 다시 한번 생각해보세요. 정반대 타입이라면 서로 '끌고 끌려가며' 잘 지낼 수 있어요. 상대를 '배려'하면서도 '주위 눈치' 안 보는 성격이면 좋아요.
- 선생님
이 선생님과의 관계에서 존중의 태도를 유지하는 것이 중요합니다. 선생님의 지도를 경청하고 성실하게 임하는 것이 도움이 될 수 있습니다. 상호 존중을 바탕으로 한 진정성 있는 태도로 접근하면, 긍정적이고 건설적인 학습 관계를 유지할 수 있을 것입니다. 이는 여러분의 성장과 발전에도 도움이 될 수 있습니다.
- 선배
이런 선배라면 여러분이 '마음대로 조종'하고 싶은 생각이 들 수 있어요. 실수해도 거의 화내지 않고 참아주는 선배겠지만, 그렇다고 '예의'에서 벗어나는 행동은 하지 마세요.
- 친구, 후배
친구로는 '무난'해요. 후배라면 '꾸준히' 할 수 있는 일을 주세요. 분명 기대에 어긋나지 않게 잘 해낼 거예요. 하지만 '자기주장'이 약해서 '발표'나 '자기표현'이 필요한 일은 어려워할 수 있어요.`,
  },

  BBBBB: {
    type: "BBBBB",
    category: "조용하게 생활하는 유형",
    desc: `'평범한 주인공' 타입`,
    sub: `학교의 숨은 실력자!`,
    char: `여러분은 진정한 '평범한 주인공' 타입이에요! '무난함' 그 자체로 정의할 수 있죠.
특별히 완벽한 부분은 없지만, 그렇다고 다른 친구들보다 많이 부족한 것도 아닌, 평범하고 무난한 타입이에요. 재미없다고 할 수는 없지만 아주 재미있다고도 할 수 없는, 그냥 일반적인 수준이라고 할 수 있어요.
하지만 큰 어려움이 닥치면 당황할 수도 있어요. 좋은 기회가 와도 그걸 잡을 자신감이나 의지가 있는지 스스로 생각해볼 필요가 있어요.
재능은 있지만, 그걸 충분히 발휘할 에너지가 부족해서 혼자서 큰 성과를 내기는 어려울 수 있어요. 누군가의 도움이 있다면 성공할 가능성이 더 클 거예요. 성공한다면 단번에 이루기보다는, 천천히 노력하면서 한 계단씩 올라가는 과정이 될 거예요.`,
    result: `기억하세요! 이 타입은 '평범한 주인공' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요! 
    여러분의 꾸준함과 성실함은 정말 대단해요. 하지만 가끔은 새로운 도전을 해보고, 시간을 더 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `요즘 시대에 '무난함'은 '도전 정신 부족', '열정 부족', '창의력 부족'으로 보일 수 있어요. 자신의 '강점' 몇 가지는 꼭 찾아서 어필할 수 있어야 해요.
수업 시간에 발표를 꺼려하지 마세요. 여러분의 생각도 충분히 가치 있어요. "제 의견은 이런데요..."라고 말하는 연습을 해보세요.
동아리 활동은 여러분의 숨은 재능을 발견할 좋은 기회예요. 관심 있는 분야의 동아리에 가입해서 적극적으로 활동해보세요.
시험 기간에는 꾸준히 공부하는 여러분의 장점이 빛을 발할 거예요. 하지만 가끔은 새로운 공부 방법도 시도해보세요. 친구들과 스터디 그룹을 만들어보는 건 어떨까요?
진로를 고민할 때는 안정적이면서도 여러분의 장점을 살릴 수 있는 직업을 찾아보세요. 교사, 공무원, 회계사 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
인생을 함께 할 '찐친'으로는 정말 좋은 타입이에요. 이런 친구를 만났다면 놓치지 마세요!
- 선생님
이 선생님과의 수업은 전반적으로 편안한 분위기에서 진행될 수 있습니다. 선생님은 학생들과 균형 잡힌 관계를 유지하시는 편입니다. 서로 존중하는 태도로 임한다면, 장기적으로 안정적이고 긍정적인 학습 관계를 유지할 수 있을 것입니다. 이는 여러분의 학업 성취에도 도움이 될 수 있습니다.
- 선배
과한 '열정'이나 '도전 정신', '눈치 게임' 같은 건 이 선배가 별로 안 좋아해요. '성실'하게 꾸준히 노력하는 모습을 보여주면 이 선배에게 인정받을 수 있어요.
- 친구, 후배
'리더'처럼 무리를 이끄는 건 아니지만, 어떤 일을 맡겨도 잘 해낼 거예요. 꾸준히 노력하는 모습이 멋져요.`,
  },

  BBBBC: {
    type: "BBBBC",
    category: "세상에 관심없는 평범한 유형",
    desc: `'마이웨이 일반인' 타입`,
    sub: `학교의 쿨내나는 평범이!`,
    char: `여러분은 진정한 '마이웨이 일반인' 타입이에요! 대부분의 면에서 '평범'하다고 볼 수 있죠.
자신이나 타인에게 '적당히' 엄격하고, '책임감'도 있고, '배려심'도 있어요. 세상을 '즐길 줄 아는' 센스도 있고, '머리'도 나쁘지 않아요. 하지만 '주변 눈치'를 별로 안 봐요.
보통 사람들은 때론 마음에 안 들어도 다른 사람들을 위해 노력하거나, '좋은 애' 소리 듣으려고 '비위'도 맞추며 살아가요. 그게 '원만한 인간관계'를 만드는 방법이거든요.
하지만 여러분은 그런 거 신경 안 써요. '괜히 잘 보이려고 노력해봤자 스트레스만 받고 별 득 없다'고 생각하죠. 이런 태도가 '스트레스'는 줄일 수 있지만, 학교생활에선 '장점'이 되기 어려워요. 가끔은 다른 사람 '시선'이나 '의견'도 들어보세요.
여러분도 '꿈'이 있을 거예요. 그 꿈이 '세상 속에서' 이루는 거라면, 세상에 좀 더 '정중'해질 필요가 있어요.`,
    result: `기억하세요! 이 타입은 '마이웨이 일반인' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독립적인 성격과 평범함 속 특별함은 정말 멋져요. 하지만 가끔은 다른 사람의 의견도 듣고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'무례함'이 여러분의 '평범함'을 '무가치'하게 만들 수 있어요. 여러분이 어떤 '잠재력'을 가졌는지 '냉정하게' 분석해보고 도전하세요. '겸손'의 가치를 다시 생각해보는 것도 좋아요.
수업 시간에 선생님 말씀을 '무시'하지 마세요. 선생님 의견도 존중하면서 여러분의 생각을 말해보세요. "제 생각은 이런데 선생님 의견은 어떠세요?"라고 말하는 연습을 해보세요.
동아리 활동에서는 '마이웨이' 정신을 발휘해보세요. 하지만 동시에 팀워크도 중요하다는 걸 기억하세요. 여러분의 독특한 시각으로 새로운 아이디어를 제안해보는 건 어떨까요?
시험 기간에는 '효율적'으로 공부하는 여러분의 장점이 빛날 거예요. 하지만 가끔은 친구들이랑 같이 공부하면서 다른 방법도 배워보세요.
진로를 고민할 때는 '독립적'이면서도 '협력'이 필요한 직업을 찾아보세요. 프리랜서 작가, 개인 사업가 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
조금만 '순응'하는 면이 있었다면 '선택의 폭'이 넓어졌을 텐데, 이런 친구라면 다시 한번 생각해보세요. 하지만 '결정적'으로 나쁜 점은 없어요.
- 선생님
이 선생님은 때때로 독특한 교육 방식을 보이실 수 있습니다. 하지만 전반적으로 수업 진행에 큰 어려움은 없을 것입니다. 선생님의 지도를 경청하고 예의를 갖춰 수업에 참여하는 것이 중요합니다. 이러한 태도로 임한다면 원활한 학습 경험을 할 수 있을 것입니다.
- 선배
'출세'에는 별로 관심 없고, 그냥 현재 생활에 '문제'만 없으면 만족하는 선배일 거예요.
- 친구, 후배
'인기'나 '성적 향상'을 위해 열심히 노력하지 않을 거예요. 그래서 지금 위치에 '정체'될 가능성이 높아요. 꼭 나쁜 건 아니지만, 친구를 위한다면 '성장 가능성'이 없으면 '아싸'가 될 수 있다고 알려주는 것도 좋아요.`,
  },

  BBBCA: {
    type: "BBBCA",
    category: "자기 자신을 억누르는 유형",
    desc: `'숨은 보석' 타입`,
    sub: `학교의 은둔 고수!`,
    char: `여러분은 진정한 '숨은 보석' 타입이에요! 다른 사람 눈에 잘 띄지 않는 '스텔스' 모드죠.
자신의 의견을 말하기는커녕, 다른 사람들에게 '좋은 애' 소리 듣고 싶어서 항상 '눈치 게임' 중이에요. 하고 싶은 말이나 생각이 있어도 꾹 참는 게 '습관'이 됐어요. 그래서 다른 사람 눈에는 '다크'하고 '우울'해 보이고 '소심'한 것 같아 보일 수 있어요.
'소극적'인 자세보다는 '적극적'인 자세를 갖도록 노력해보세요. 평생 다른 사람 '그림자' 속에 숨어 살 순 없잖아요? 자신을 '사랑'하는 법을 배워보세요. 한 걸음씩 나아가다 보면 분명 '희망'의 빛을 발견할 수 있을 거예요.
자신을 '비하'하지 말고, 가끔은 하고 싶은 대로 '자유분방'하게 행동해보는 것도 좋아요.`,
    result: `기억하세요! 이 타입은 '숨은 보석' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 숨겨진 재능을 믿어요. 조금씩 자신감을 키우고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'자신감'을 갖는 게 중요해요. 다른 사람 마음에 들고 싶다면, 그들에게 맞추면서도 동시에 여러분의 '장점'도 당당하게 보여줄 수 있어야 해요.
수업 시간에 발표하기 두려워하지 마세요. 여러분의 생각도 충분히 가치 있어요. "제 의견은 이런데요..."라고 말하는 연습을 해보세요.
동아리 활동은 여러분의 숨은 재능을 발견할 좋은 기회예요. 소규모 동아리부터 시작해서 점점 더 큰 무대로 나아가보는 건 어떨까요?
시험 기간에는 친구들 눈치 보느라 공부 못하는 일 없도록 해요. '나는 나대로 열심히 한다'는 마인드로 공부해보세요.
진로를 고민할 때는 여러분의 장점을 잘 살릴 수 있는 직업을 찾아보세요. 작가, 연구원, 프로그래머 같이 혼자 집중해서 일할 수 있는 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이 친구를 좋아하게 된 이유를 생각해보세요. 혹시 '무조건 맞춰주는 모습' 때문은 아닌가요? 그럼 이 친구는 나중에 '스트레스'로 '번아웃'될 수 있어요. 더 밝게 살아가는 법, 즐거운 걸 함께 찾아보며 이 친구를 세상 밖으로 이끌어주세요.
- 선생님
꼭 해야 할 일이라면 선생님 말씀대로 따라줄 거예요. 하지만 이걸 '이용'하려고 하면 안 돼요. 선생님 말씀 잘 듣고 성실하게 행동하면서도, 가끔은 여러분의 의견도 조심스럽게 말해보세요.
- 선배
'리더' 역할은 이 선배에게 너무 버거울 거예요. 학교생활이 '스트레스'의 연속일 수 있어요. 가끔 선배와 후배의 위치가 바뀐 것 같아 보여도, 선배를 너무 '비난'하지 마세요. 선배도 최선을 다하고 있을 거예요. 힘들 때 '응원'과 '믿음'의 말을 해주세요.
- 친구, 후배
강한 '경쟁' 상황이나 한꺼번에 많은 일을 맡기면 오히려 '역효과'가 날 수 있어요. 이 친구의 '능력'에 맞게 일을 나눠주는 게 좋아요.`,
  },

  BBBCB: {
    type: "BBBCB",
    category: "소극적이나 성실한 유형",
    desc: `'얌전한 모범생' 타입`,
    sub: `학교의 숨은 노력왕!`,
    char: `여러분은 진정한 '얌전한 모범생' 타입이에요! 성실함을 무기로 하루하루를 살아가는 '노력왕'이죠.
작은 것에도 만족하고, 큰 소리로 화를 내지 않으며, 조용히 공부와 집안일에 충실한 사람입니다. 만약 이런 사람들만 있다면 학교에서 왕따나 학폭 같은 문제는 없을 거예요. 그야말로 '평화주의자'라고 할 수 있겠네요.
다른 사람들과 '티키타카'하며 목표를 추구하기보다는, 조용히 자신의 생활을 유지하며 작은 즐거움에 만족해요. 적당한 생활에 만족하고, 학교 규칙도 잘 지키는 편이죠.
스스로는 '평화로운 세상'을 만드는 롤모델이라고 생각할 수 있어요. 하지만 급변하는 현대 사회와 비교하면, 한편으로는 답답하게 보일 수 있는 것도 사실이에요. 그래도 남에게 민폐를 끼치는 타입이 아니니, 평생 '노드라마'로 평온하게 살 가능성이 커요.`,
    result: `기억하세요! 이 타입은 '얌전한 모범생' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 성실함과 평화로움은 정말 대단해요. 하지만 가끔은 적극적으로 나서고, 시간을 더 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `요즘 '트렌드'부터 잘 살펴보는 게 좋아요. 이 사회에서 여러분의 장점인 '성실함'을 어떻게 활용해야 할지 생각해보세요.
수업 시간에 발표를 꺼려하지 마세요. 여러분의 생각도 충분히 가치 있어요. "제 의견은 이런데요..."라고 말하는 연습을 해보세요.
동아리 활동은 여러분의 성실함을 발휘할 수 있는 곳으로 가보세요. 도서부나 학습 동아리 같은 데서 여러분의 장점을 마음껏 보여주세요.
시험 기간에는 여러분의 '꾸준함'이 빛을 발할 거예요. 하지만 가끔은 새로운 공부 방법도 시도해보세요. 친구들과 스터디 그룹을 만들어보는 건 어떨까요?
진로를 고민할 때는 안정적이면서도 여러분의 성실함을 살릴 수 있는 직업을 찾아보세요. 공무원, 회계사, 사서 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
'벼락부자'나 '인싸' 되는 걸 원하지 않는다면, 정말 '찰떡궁합' 친구예요. '안정적'이고 '평화로운' 일상을 함께 보낼 수 있어요.
- 선생님
이 선생님은 학생들과의 신뢰 관계를 중요하게 여기십니다. 여러분이 성실하고 진정성 있는 태도로 임한다면, 선생님도 그에 상응하는 신뢰와 지원을 보내주실 것입니다. 선생님은 믿음직한 학생들과 긍정적인 관계를 형성하고자 하시니, 여러분의 진실된 모습을 보여드리는 것이 좋은 방법이 될 수 있습니다.
- 선배
'성실'하고 '꾸준'하게, '삐끗' 없이 학교생활 잘하는 선배일 거예요. 만약 여러분이 '과한 욕심'이 있다면, 그걸 '조절'해줄 수 있는 사람이기도 해요. 선배로서 '존경'한다면 말이에요.
- 친구, 후배
'까다롭'거나 '곤란'하게 하는 친구는 아닐 거예요. 학교생활에 특별한 문제는 없지만, 주의할 점은 '주도적'이거나 '활발'하게 나서는 스타일은 아니라는 거예요. '발표'나 '기획' 같은 일보다는 조용히 할 수 있는 일을 맡기는 게 좋아요.`,
  },

  BBBCC: {
    type: "BBBCC",
    category: "눈에 잘 띄지 않는 유형",
    desc: `'투명 인간 수석' 타입`,
    sub: `학교의 숨은 실력자!`,
    char: `여러분은 진정한 '투명 인간 수석' 타입이에요! 어디에서도 '존재감'이 없는 스타일이죠.
특별히 '꿀잼'인 일도, '노잼'인 일도 안 하고, '땡땡이'치거나 '열정 MAX'로 도전하는 것도 아닌, 아무리 봐도 잘 안 보이는 '투명 인간' 같은 사람이에요. 마치 교실 맨 앞자리에 앉아 있어도 "우리 반에 그런 애 있었어?" 할 정도로 '안 보이는' 타입이죠.
하지만 자기 자신은 잘 알고 있고, 자기 길을 잘 가고 있어요. 가고 싶은 길은 가되, 가기 싫은 길은 안 가는 '마이웨이' 스타일이에요. 다른 애들이 아무리 '핫'한 걸 따라 해도 '휩쓸리지' 않는 '뚝심'도 있어요.
기본적인 욕구 외에 다른 '꿀잼'을 쫓지 않아서 '흥청망청' 자신을 망치거나 다른 애들에게 '민폐' 끼치는 일은 없어요.
근데 너무 '어른스러운' 건 좀 '노잼' 인생이 될 수 있어요. 인생은 즐거움을 추구할 권리도 있으니까 가끔은 '꿀잼' 아이처럼 '천진난만'해지는 것도 좋아요!`,
    result: `기억하세요! 이 타입은 '투명 인간 수석' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 꾸준함과 마이웨이 정신은 정말 대단해요. 하지만 가끔은 자신을 드러내고, 시간을 더 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분의 '존재감'이 너무 약해요. 많은 애들 중에서 여러분을 '픽'하려 해도 잘 안 보일 거예요. 자신의 '밝은 면'을 개발해서 어필하도록 노력해야 해요.
수업 시간에 발표를 꺼려하지 마세요. 여러분의 생각도 충분히 가치 있어요. "제 의견은 이런데요..."라고 말하는 연습을 해보세요.
동아리 활동에서는 여러분의 숨은 재능을 발견할 수 있어요. 조용한 동아리부터 시작해서 점점 더 활발한 동아리로 도전해보는 건 어떨까요?
시험 기간에는 여러분의 '꾸준함'이 빛을 발할 거예요. 하지만 가끔은 친구들이랑 같이 공부하면서 '소통'하는 법도 배워보세요.
진로를 고민할 때는 여러분의 '마이웨이' 스타일을 살릴 수 있는 직업을 찾아보세요. 연구원, 작가, 분석가 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
단점이라면 좀 '노잼'하고 '꼰대'같이 느껴질 수 있어요. 장점은 절대 '충동적'이거나 '생각 없는' 행동으로 인생을 '말아먹는' 일은 없다는 거예요.
- 선생님
이 선생님의 수업은 대체로 원활하게 진행될 것입니다. 선생님의 지도를 경청하고 성실하게 수업에 참여한다면, 긍정적인 학습 관계를 유지할 수 있을 것입니다. 상호 존중과 협력적인 태도로 임하면 효과적인 학습 경험을 할 수 있을 것입니다.
- 선배
기본적인 '예의'만 지키면 그걸로 충분해요. 여러분이 지나치게 선배를 '무시'하는 것 같은 행동을 보이거나, '가볍게' 대하지만 않으면 문제 없어요.
- 친구, 후배
스스로 꾸준히 공부 잘 하는 친구라 특별히 신경 쓸 일은 없어요. 하지만 가끔 '응원'의 말을 해주는 게 좋아요. "너 정말 열심히 하는구나, 대단해!"라고 말해주세요.`,
  },

  BBCAA: {
    type: "BBCAA",
    category: "매직 라이프를 꿈꾸는 유형",
    desc: `'판타지 주인공' 타입`,
    sub: `학교의 몽상가 아이돌!`,
    char: `여러분은 진정한 '판타지 주인공' 타입이에요! 어릴 때 봤던 만화나 게임 속에서 아직 못 벗어났나 봐요. 원하는 거, 갖고 싶은 거, 하고 싶은 거가 '뿅'하고 생길 것 같은 '말도 안 되는' 고민 하고 있진 않나요?
'스펙 쌓기' 시대인 지금, 노력 없이 되는 건 없어요. 상황에 따라 정확한 '판단력'과 '사려분별력'을 기르는 게 중요해요.
물론 이런 생각들은 머릿속 '상상' 뿐이고, 실제로는 매우 '평범한' 상식과 '배려심'을 갖고 있어서 주변에서 '이상한 애'나 '모자란 애'로 보진 않아요.
이 타입은 마음속에 두 가지 생각이 계속 싸우고 있어요. '미친 듯이' 뛰어다니고 싶은 마음과, '눈치' 보며 자제하려는 마음이 계속 '티키타카' 하고 있죠. '판단력'과 '사려분별력'만 기르면 정말 '꿀잼' 인생 살 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '판타지 주인공' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 풍부한 상상력과 창의성은 정말 대단해요. 하지만 가끔은 현실에 집중하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'허황된' 생각은 접어두세요. 내면의 '애기' 같은 생각과 '미흡한' 사려분별력을 조절 못 하면 '대박' 기회는 쉽게 오지 않을 거예요.
수업 시간에 '공상'하지 말고 집중해보세요. 여러분의 상상력을 수업 내용과 연결해보면 더 재미있게 공부할 수 있을 거예요.
동아리 활동은 여러분의 '판타지'를 현실로 만들 수 있는 곳을 찾아보세요. 만화 동아리나 영상 제작 동아리 어떨까요? 여러분의 상상력을 실제로 표현해보세요.
시험 기간에는 '환상'에서 벗어나 현실에 집중해야 해요. '뿅'하고 공부가 될 리는 없잖아요? 계획을 세우고 꾸준히 공부하세요.
진로를 고민할 때는 여러분의 상상력을 살릴 수 있는 직업을 찾아보세요. 작가, 게임 기획자, 애니메이터 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이런 친구와 계속 지내려면 '냉정'하고 '침착'하게 행동해야 해요. 하지만 그러다 보면 이 친구랑 잘 안 맞을 수 있어요. 이 친구를 '놓치기 싫다면' 적당히 맞춰주면서 조절하는 게 좋아요.
- 선생님
이 선생님과 성향이 비슷하다면 소통에 주의가 필요할 수 있습니다. 때로 현실적인 판단이 필요한 상황이 있을 수 있으니, 객관적 시각을 유지하는 것이 중요합니다. 선생님의 지도를 주의 깊게 듣고 필요한 내용을 기록하며 수업에 참여하세요. 이러한 태도는 효과적인 학습과 원활한 관계 유지에 도움이 될 것입니다.
- 선배
'메모'로 정확히 정보 공유하는 게 좋아요. 그렇지 않으면 선배가 말했던 걸 잊어버리거나, 잘못 처리했다고 '혼날' 수 있어요.
- 친구, 후배
지금 해야 할 일이 뭔지 정확히 알려주세요. 끝날 때까지 계속 '중간 점검' 해주는 게 좋아요. 하지만 너무 많은 걸 시키거나 '빡세게' 밀어붙이면 도망갈 수 있으니 조심하세요.`,
  },

  BBCAB: {
    type: "BBCAB",
    category: "변덕이 심한 유형",
    desc: `'초강력 변덕쟁이' 타입`,
    sub: `학교의 롤러코스터 아이돌!`,
    char: `여러분은 진정한 '초강력 변덕쟁이' 타입이에요! '궁금이' 가득한 호기심과 '감성 폭발' 감성으로 '귀요미' 매력 뿜뿜하는 스타일이죠.
모든 걸 '직감'으로 판단하고, 가끔 '대박' 적중할 때도 있어요. 하지만 '직감'만 믿다 보면 '이성적' 판단이나 '사려분별'이 떨어져서 '실수 대잔치'가 될 수 있어요.
또, 여러분은 '변덕' 심해서 '극과 극' 행동이나 감정을 보일 수 있어요. 보통 사람들은 '이성'으로 자제하려고 노력하지만, 여러분은 그게 좀 부족해서 '오늘 달라요~' 모드가 자주 켜질 수 있어요.
사물을 볼 때 좀 더 '팩트' 기반으로 보는 눈을 키우고, 행동하기 전에 한 번 더 '멈칫'하고 생각해보세요. '득과 실'을 따져보는 습관을 들이면 90% 이상의 문제가 해결될 거예요!`,
    result: `기억하세요! 이 타입은 '초강력 변덕쟁이' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 직감과 창의력은 정말 대단해요. 하지만 가끔은 이성적으로 생각하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `움직이기 전에 한 번 더 생각하세요. '직감'만 믿으면 '계획적'인 공부가 어려울 수 있어요.
수업 시간에 '직감'으로 대답하지 말고, 배운 내용을 바탕으로 대답해보세요. 선생님께 "이렇게 생각한 이유는 이거예요"라고 설명하는 연습을 해보세요.
동아리 활동은 여러분의 '변덕'을 긍정적으로 활용할 수 있는 곳을 찾아보세요. 예술 동아리나 창작 동아리에서 여러분의 다양한 아이디어를 펼쳐보는 건 어떨까요?
시험 기간에는 '직감'에 의존하지 말고 계획을 세워 공부하세요. '변덕'을 조절하고 꾸준히 공부하는 연습을 해보세요.
진로를 고민할 때는 여러분의 '직감'과 '변덕'을 장점으로 살릴 수 있는 직업을 찾아보세요. 예술가, 디자이너, 마케터 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이 친구와 평생 지내려면 '마음고생' 좀 할 수 있어요. 여러분도 비슷한 타입이면 매일 '티키타카' 싸움할 수 있어요. 하지만 여러분이 '이성적'이고 '신중'하다면 이 친구를 '리드'하며 '부드럽게' 조절할 수 있을 거예요.
- 선생님
이 선생님은 감정 표현이 다양할 수 있어 상황에 따라 유연하게 대처하는 것이 중요합니다. 선생님의 의도는 대체로 긍정적일 테니, 선생님의 기분과 분위기를 파악하며 수업에 참여해보세요. 선생님의 감정 변화에 주의를 기울이고 적절히 반응한다면, 더 원활한 학습 경험을 할 수 있을 것입니다.
- 선배
이런 선배는 관계 '조율'하기가 정말 어려워요. '오늘 날씨 급변'처럼 어디로 튈지 모르는 선배예요. 문제 생기면 고치려 하지 말고 "그럴 수도 있지~"라고 생각하면 좀 더 '편해'질 거예요.
- 친구, 후배
이런 친구에겐 '만만한' 이미지보다 '카리스마' 이미지가 좋아요. '제멋대로' 행동할 수 있으니 미리 방지하는 거죠. 가끔 '현실감 제로' 아이디어로 당황할 수 있지만, 너무 '부정적'으로 보지 마세요. '대박' 아이디어의 시작일 수 있으니까요!`,
  },

  BBCAC: {
    type: "BBCAC",
    category: "호기심 가득한 자유인 유형",
    desc: `'호기심 폭발 망아지' 타입`,
    sub: `학교의 초능력 탐험가!`,
    char: `여러분은 진정한 '호기심 폭발 망아지' 타입이에요! 자유를 향한 갈망과 새로운 것에 대한 욕구로 인해 평화로운 일상이 갑자기 '대박 드라마'처럼 변할 수 있어요.
다른 친구들과 비교했을 때 상식이나 의리, 인정 같은 건 부족하지 않아서 평소에는 아무 문제 없이 잘 지내요. 하지만 흥미로운 무언가가 눈에 띄면, 그때부터는 '망아지'처럼 활기를 띠며 자신이 하고 싶은 대로 움직이기 시작하죠. 그 순간엔 주변 눈치도 보지 않고 자신만의 길을 향해 돌진해요.
기본적으로 '아이' 같은 순수함이 있어서 감정 표현이 솔직하고 투명해요. 슬픔이나 기쁨, 감탄 같은 감정을 숨기지 않고 그대로 드러내죠. 하지만 여러분에게 꼭 필요한 건 '이성적인' 판단력이에요. 이성을 키워야 언제 멈추어야 하고, 언제 즐겨야 할지 적절하게 판단할 수 있을 거예요. 이성이 감성을 적절히 조율하면, 더 멋지고 균형 잡힌 인생을 살아갈 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '호기심 폭발 망아지' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 끝없는 호기심과 탐구 정신은 정말 대단해요. 하지만 가끔은 한 가지에 집중하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분의 '호기심'이 좋은 기회를 만들어줄 수 있어요. 하지만 주변 사람들 이야기도 듣고 상황을 '이성적'으로 판단하는 법을 배우는 게 먼저예요.
수업 시간에 '호기심'을 잘 활용하세요. 선생님께 질문하는 걸 두려워하지 마세요. "이건 왜 그런가요?"라고 물어보는 습관을 들이세요.
동아리 활동은 여러분의 '호기심'을 마음껏 발산할 수 있는 곳을 찾아보세요. 과학 탐구 동아리나 발명 동아리는 어떨까요?
시험 기간에는 '호기심'을 잘 조절해야 해요. 공부에 집중하지 못하고 다른 데 '눈돌아가지' 않도록 주의하세요. 공부도 '탐험'이라고 생각하면 재미있을 거예요.
진로를 고민할 때는 여러분의 '호기심'을 장점으로 살릴 수 있는 직업을 찾아보세요. 과학자, 탐험가, 저널리스트 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
'계획적'이고 '안정된' 관계를 원한다면 좀 힘들 수 있어요. 매번 달라지는 생활 방식이나 '즉흥적'인 행동을 이해해줄 수 있다면 좋겠지만, 그게 힘들다면 '친구'로 지내는 게 나을 수 있어요.
- 선생님
항상 '새롭고 독특한 것'에 관심 많은 선생님이에요. '세상에 하나뿐인 것', '최신' 트렌드를 좋아하시죠. 새로운 수업 방식을 도입하실 때 적극적으로 참여해보세요. 하지만 곧 다른 걸로 관심 옮기실 수 있으니 빨리 배우세요!
- 선배
'진중한' 선배의 모습은 찾기 어려울 수 있어요. 이 선배가 뭔가를 시키면 다른 선배한테 한번 더 확인해보는 게 좋아요.
- 친구, 후배
'새로운 아이디어'를 찾거나 '창의적인' 일에 딱이에요. 이 친구의 '독특한' 생각으로 '대박' 결과를 얻을 수도 있어요.`,
  },

  BBCBA: {
    type: "BBCBA",
    category: "인생의 멘토가 필요한 유형",
    desc: `'인생 뉴비' 타입`,
    sub: `학교의 숨은 보물!`,
    char: `여러분은 진정한 '인생 뉴비' 타입이에요! 마치 '오픈월드 게임'에 처음 접속한 캐릭터 같아요. 주변 사람들에게 항상 '보호'와 '관심'의 대상이 되죠.
'자존감'이나 '주체성'이 좀 부족해서 혼자서 뭔가를 해내기 어려워해요. 가장 약한 부분은 상황을 '분별'하는 능력과 '이성적'으로 생각하는 능력이에요.
운 좋게 '좋은 팀원'들이 있다면 '학교생활'이 덜 불안하겠지만, 기본적으로 '소극적'이고 '의존적'이라 항상 다른 사람 '눈치'를 보며 스스로를 힘들게 해요.
뭐든 잘하고 '인정'받고 싶은 마음은 강하지만, '어떻게 해야 할지' 스스로 답을 찾기 어려워해요. 그래서 주변 사람들이 여러분을 '버리지도 못하고 데리고 가기도 어려운' 상황에 빠질 수 있어요.
지금 여러분에게 가장 필요한 건 '자신감'을 심어주고 스스로 결정할 수 있게 도와주는 '믿음직한 멘토'예요.`,
    result: `기억하세요! 이 타입은 '인생 뉴비' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 순수함과 노력하는 모습은 정말 귀여워요. 하지만 가끔은 스스로 결정하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'창의력' 필요한 분야는 좀 어려울 수 있어요. 확실하게 주어지는 일, 책임져야 할 범위가 작은 일일수록 '안정감' 느낄 수 있어요.
수업 시간에 혼자 판단해서 답해야 하는 것보다 '수학 공식'처럼 정답이 명확한 과목이 더 잘 맞을 거예요.
동아리 활동은 역할이 명확하게 정해진 곳을 찾아보세요. 예를 들어, 도서부나 환경정화 동아리 같은 데서 정해진 일을 하는 게 좋을 거예요.
시험 기간에는 스스로 계획 세우기 어려울 수 있어요. 친구들이나 선생님께 도움을 요청해서 공부 계획을 세워보세요.
진로를 고민할 때는 규칙적이고 안정적인 직업을 생각해보세요. 공무원, 회계사, 사서 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이 친구는 '리더'형 친구가 필요해요. 여러분이 누군가를 '이끌고' 때로는 '조언'해주는 걸 좋아한다면 잘 맞을 수 있어요. 하지만 너무 '강압적'이면 안 돼요. '따뜻한 엄마' 같은 마음으로 대해주세요.
- 선생님
이 선생님은 구조화된 학습 환경을 선호하실 수 있습니다. 명확한 목표와 과제가 있는 수업에서 더 효과적으로 지도하실 것 같습니다. 복잡한 상황보다는 직접적이고 분명한 교류를 중시하실 수 있어요. 선생님의 지도를 잘 따르고 주어진 과제에 성실히 임한다면, 좋은 학습 성과를 얻을 수 있을 것입니다.
- 선배
뭔가를 '함께' 하기엔 좀 어려운 선배예요. 오히려 여러분이 앞장서서 선배를 '리드'하게 될 수도 있어요. '답답'할 수 있지만 절대 '무시'하거나 '몰아세우지' 마세요. 선배를 '난처하게' 만들 수 있어요. '그러려니' 하고 받아들이고 여러분의 능력으로 '도와주세요'.
- 친구, 후배
주어진 일은 '무난히' 잘 해내는 친구예요. 여러분이 부탁하는 걸 '군말 없이' 도와줄 거예요. 이런 친구 마음에 '상처' 주면 안 돼요. '현실감'이 좀 부족하고 '소심한' 친구니까 많이 격려하고 '고마워'라고 자주 말해주세요.`,
  },

  BBCBB: {
    type: "BBCBB",
    category: "상황 판단이 어려운 유형",
    desc: `'현실 감각 제로 꿈나무' 타입`,
    sub: `학교의 순수 천사!`,
    char: `여러분은 진정한 '현실 감각 제로 꿈나무' 타입이에요! '평범한' 학교생활을 하기엔 다 갖췄지만, 가장 중요한 '현실감'이 좀 부족해요.
평소엔 티 안 나지만, 조금만 '새로운 상황'이 생기면 '군중 속 초등학생'처럼 '멘붕'에 빠져요. 지금 생활에 변화가 생기면 '대환장 파티'가 될 수 있어요.
큰 '욕심'도 없고, 욕심 낼 만한 일도 잘 안 생길 거예요. 다른 사람들 눈에는 '그저 그런 애'로 보일 수 있지만, 자기만의 세계에서는 '천국'처럼 행복해하는 스타일이에요.`,
    result: `기억하세요! 이 타입은 '현실 감각 제로 꿈나무' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 순수함과 행복을 즐기는 모습은 정말 귀여워요. 하지만 가끔은 현실을 직시하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분이 '좋은 성적'을 원한다면 '현실'을 더 잘 이해하는 눈을 키워야 해요. '1분 1초'가 다르게 변하는 세상에서 여러분의 '부족한 판단력'은 별로 도움이 안 돼요.
목표를 세우고, 계획대로 실천하고, 결과에 대한 '성취감'을 느껴보는 게 필요해요.
수업 시간에 '멍'때리지 말고 집중해보세요. 선생님 말씀을 잘 듣고 노트에 정리하는 습관을 들이세요.
동아리 활동은 '현실 감각'을 키울 수 있는 곳을 찾아보세요. 경제 동아리나 시사 토론 동아리는 어떨까요?
시험 기간에는 '환상'에서 벗어나 현실에 집중해야 해요. 친구들이랑 스터디 그룹을 만들어 서로 도와가며 공부해보세요.
진로를 고민할 때는 '현실적'이면서도 여러분의 순수함을 살릴 수 있는 직업을 찾아보세요. 교사, 사회복지사, 상담사 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
여러분이 '이성적'이고 '권위적'이라면 이 친구랑은 안 맞을 수 있어요. '현실감'이 부족해서 인생의 '든든한 지원군'은 못 돼요. 하지만 '따뜻하고 지혜로운' 사람을 만나면 '무난한' 관계를 유지할 수 있어요.
- 선생님
이 선생님의 수업에서는 자율적인 판단력을 기르는 것이 중요할 수 있습니다. 선생님의 지도를 경청하되, 동시에 스스로 상황을 분석하고 결정을 내리는 능력을 키워보세요. 이는 비판적 사고와 독립적인 학습 태도를 발전시키는 좋은 기회가 될 수 있습니다. 선생님의 조언과 여러분의 판단을 조화롭게 활용하는 것이 효과적일 것입니다.
- 선배
이런 선배가 '리더' 자리에 있다는 게 좀 '의아'할 수 있어요. 여러분이 이 선배의 '판단력'을 키워줄 수 있는 '의리 있고 착한' 후배라면 괜찮겠지만, 도움 받기보다는 '도와줘야 하는' 타입이에요.
- 친구, 후배
보통 친구들보다 '현실 감각'이 좀 떨어져요. 함께 공부하거나 프로젝트할 때 '계속 동기부여'해주고 '적절히 관리'해줘야 하는 친구예요.`,
  },

  BBCBC: {
    type: "BBCBC",
    category: "오만으로 기회를 놓치는 유형",
    desc: `'나르시스트 운빨망치' 타입`,
    sub: `학교의 숨은 자신감 부자!`,
    char: `여러분은 진정한 '나르시스트 운빨망치' 타입이에요! 조금만 더 진지해지면 완전히 다른 인생을 살 수 있는데, 안타깝게도 좋은 기회들을 허무하게 놓쳐버리곤 해요.
특별히 튀는 것도 없고 어두운 성향도 아니지만, 현실 감각이 부족한데도 '내가 최고야'라는 생각이 강해서 혼자만의 판단으로 일을 망치기 쉬운 스타일이에요. 문제 해결 능력도 부족해서 상황을 제대로 다루지 못할 때가 많아요.
주변에 친구들이 아예 없는 건 아니지만, 이런 상황이 계속된다면 결국 다들 손을 떼게 될 거예요. 현실을 직시하고, 자신의 한계를 좀 더 인식할 필요가 있어요. 혼자서 모든 걸 해낼 수 있는 건 생각보다 많지 않아요. 친구들과 협력하고 현실적인 접근 방식을 더 많이 채택해본다면, 더 나은 기회를 놓치지 않을 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '나르시스트 운빨망치' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자신감은 정말 대단해요. 하지만 가끔은 현실을 직시하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분의 판단이 상황에 '도움'이 되는지 계속 '고민'해보세요. 학교생활은 매 순간이 '선택'이에요. '현명한 판단력' 없는 사람에게 '기회'를 주는 선생님은 많지 않아요. 마음 한구석에 있는 '오만함'과 '불성실함'을 버리는 것도 중요해요.
수업 시간에 '혼자만의 생각'에 빠지지 말고 선생님 말씀을 잘 들어보세요. 친구들의 의견도 귀담아들어보는 습관을 들이세요.
동아리 활동은 '팀워크'를 배울 수 있는 곳을 찾아보세요. 축구부나 밴드부 같은 데서 '협동'의 중요성을 배워보는 건 어떨까요?
시험 기간에는 '혼자 다 안다'는 생각은 접어두고, 친구들이랑 스터디 그룹을 만들어보세요. 서로 도와가며 공부하면 더 좋은 성적을 받을 수 있을 거예요.
진로를 고민할 때는 '현실적'으로 생각해보세요. '유튜버'나 '프로게이머' 말고, 여러분의 능력을 정말로 발휘할 수 있는 직업을 찾아보세요.`,
    human: `- 베프, 여자친구
이 친구는 '현실적'이지도 '믿음직'하지도 못해요. 어떤 점이 '매력적'이었나요? '깊은 관계' 유지하기엔 좀 부족해요. '위기 상황'에서 대처 능력이 많이 떨어져요. 모든 걸 여러분이 '책임지고 리드'할 수 있다면 관계를 이어가세요.
- 선생님
이 선생님의 수업은 지속적인 주의와 적극적인 참여가 필요할 수 있습니다. 수업 진행 상황을 꾸준히 확인하고 적절히 대응하는 것이 중요합니다. 자율적으로 학습 관리를 하며, 필요할 때 선생님과 소통하세요. 이러한 노력이 없다면 수업이 예상치 못한 방향으로 진행될 수 있습니다. 주의 깊은 태도로 임하면 효과적인 학습이 가능할 것입니다.
- 선배
본인이 '파놓은 함정'에 스스로 빠질 수 있어요. '성과'를 내야 하는 동아리나 학생회에서 오래 '살아남기' 힘든 선배예요. 후배로서 '보좌'해야 한다면 선배의 '현실 감각'을 '정중하고 조심스럽게' 일깨워줄 필요가 있어요.
- 친구, 후배
어떤 일을 맡겨도 옆에서 이 친구를 '관리'할 수 있는 사람 하나가 꼭 필요해요. '단순'하고 '책임'이 크지 않은 일들은 '무난히' 해낼 거예요.`,
  },

  BBCCA: {
    type: "BBCCA",
    category: "우울한 순종파 유형",
    desc: `'우울한 순둥이' 타입`,
    sub: `학교의 숨은 보석!`,
    char: `여러분은 진정한 '우울한 순둥이' 타입이에요! 스스로 인생을 개척하기에는 너무 힘든 스타일이에요.
위축되고 눌린 성격 때문에 자신감이 부족하고, 다른 사람의 말에 무조건적으로 따르며 살아가곤 해요. 항상 불안하고 초조한 감정 속에서 지내죠. 현실 감각도 부족한 편이라, 주변의 말을 100% 그대로 믿고 따르는 순진한 면이 있어요.
여러분은 누구를 만나느냐에 따라 인생이 크게 달라질 수 있어요. 올바른 사람을 만나면 행복해질 수 있지만, 나쁜 사람을 만나면 쉽게 이용당할 위험이 있어요. 과도한 충성심 때문에 남에게 이용만 당하기 쉽고, 건강한 관계를 맺는 방법을 잘 몰라 늘 남에게 의지하고 복종하는 태도로 살아가곤 해요.
특별한 행복이 아니라 보통의 행복을 원한다면, 지금이라도 자신의 목소리를 내보세요. 다른 사람들이 비난하거나 무시하더라도 두려워하지 마세요. 여러분에겐 당당하게 세상에 맞설 용기를 키우는 것이 가장 중요해요. 스스로를 위해 자신을 표현하고, 한 걸음씩 용기를 내어보세요. 그렇게 할 때 진정한 행복을 찾을 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '우울한 순둥이' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 순수함과 따뜻한 마음을 믿어요. 하지만 가끔은 자신의 의견을 말하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `'자신감'을 키우는 게 가장 중요해요. 일상 '습관'을 전체적으로 바꿔서 크고 작은 일에 '소신껏' 도전해보세요. 많은 '경험'을 통해 '자존감'을 키운 후 더 큰 세상으로 나아가세요.
수업 시간에 발표 두려워하지 마세요. "제 생각은 이런데요..."라고 말하는 연습을 해보세요. 틀려도 괜찮아요!
동아리 활동은 여러분의 관심사를 찾을 수 있는 곳으로 가보세요. 음악부, 미술부, 독서부 등에서 자신의 '숨은 재능'을 발견할 수 있을 거예요.
시험 기간에는 친구들에게 '의지'만 하지 말고, 스스로 공부 계획을 세워보세요. 작은 목표부터 하나씩 달성해나가면 '자신감'이 생길 거예요.
진로를 고민할 때는 남의 의견만 듣지 말고, 자신이 정말 '좋아하는 것'이 뭔지 생각해보세요. 꿈을 향해 한 걸음씩 나아가는 거예요!`,
    human: `- 베프, 여자친구
이런 친구는 '믿음'이 생기면 뭐든 여러분 마음대로 할 수 있어요. 하지만 '무시'하거나 '이용'하려 들면 안 돼요. 누구든 '함부로' 대해도 되는 사람은 없어요. '따뜻한 마음'으로 '힘'과 '용기'를 줄 수 있는 사람이 되어주세요.
- 선생님
이 선생님은 긍정적이고 격려하는 학습 환경을 조성하십니다. 세세한 지도와 확인이 필요할 수 있지만, 이는 여러분의 성장을 위한 과정입니다. 선생님의 안내를 주의 깊게 따르고 성실히 임한다면, 원활한 수업 진행과 함께 좋은 학습 성과를 얻을 수 있을 것입니다. 이러한 태도로 임하면 선생님과 신뢰 관계를 유지할 수 있습니다.
- 선배
여러분이 '현명한' 후배라면 이 선배에게 '의지'하기보다는 '힘'을 실어주는 게 좋아요. 선배라는 '타이틀' 안에서 많은 '고민'과 '두려움'을 갖고 있어요. 선배의 '자신감'을 키워주는 것도 후배의 역할이에요.
- 친구, 후배
가능한 '명확하게' 처리할 수 있는 일을 맡기세요. '선택'과 '경우의 수'가 많은 일은 '과도한 스트레스'를 받으며 '우왕좌왕'할 수 있어요.`,
  },

  BBCCB: {
    type: "BBCCB",
    category: "존재감이 미약한 유형",
    desc: `'투명 인간 지망생' 타입`,
    sub: `학교의 숨은 NPC!`,
    char: `여러분은 진정한 '투명 인간 지망생' 타입이에요! 그냥 '태어났으니까' 살아가는, 세상에 큰 기대나 불만 없이 흐름에 따라 살아가는 스타일이죠.
세상에 대해 부정적인 건 아니지만, 무언가를 이뤄보려는 목적이나 얻으려는 욕심도 거의 없어요. 기본적인 책임감과 배려심은 있지만, 현실 감각이 부족하고 인생에서 재미를 찾으려는 노력도 하지 않아요. 그래서 주변에서는 "왜 사는 거야?"라는 의문을 가질 정도지만, 본인은 크게 상관하지 않는 스타일이에요.
여러분의 존재감이 약해서 생기는 여러 상황들에 대해 진지하게 고민해보세요. 지금처럼 살아간다면 스스로 불행해질 수도 있어요. 주어진 삶에 대한 책임감을 느끼고, 다른 사람들과의 관계에서 여러분이 미치는 영향에 대해 생각해보세요. 이제는 좀 더 적극적으로 살아야 해요. 여러분의 인생이 더욱 의미 있고 행복해질 수 있도록, 조금 더 자신을 드러내고 주도적으로 살아가는 시도를 해보세요.`,
    result: `기억하세요! 이 타입은 '투명 인간 지망생' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 순수함과 배려심을 믿어요. 하지만 가끔은 적극적으로 나서고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입에겐 '어떤 공부'를 하는지는 크게 중요하지 않아요. 학교생활에서 얻고자 하는 건 그저 '기본적인 것들'뿐이에요. 공부를 '시작'하는 건 쉽겠지만, '계속' 할 수 있을지는 지켜봐야 해요.
수업 시간에 '존재감'을 키워보세요. 발표를 자주 하고, 선생님께 질문도 해보세요. "이 부분이 잘 이해가 안 가요. 더 설명해주실 수 있나요?"라고 말하는 연습을 해보세요.
동아리 활동은 여러분의 '숨은 재능'을 발견할 수 있는 곳을 찾아보세요. 봉사 동아리나 환경 동아리 같은 데서 '의미 있는 일'을 해보는 건 어떨까요?
시험 기간에는 '그냥 흘러가는 대로' 하지 말고, 계획을 세워 공부해보세요. 작은 목표부터 하나씩 달성해나가면 '성취감'을 느낄 수 있을 거예요.
진로를 고민할 때는 '그냥 아무거나'가 아니라, 정말 여러분이 '관심 있는 것'이 뭔지 찾아보세요. 꿈을 향해 한 걸음씩 나아가는 거예요!`,
    human: `- 베프, 여자친구
이 친구에겐 '미래'가 안 보여요. '오늘'만 살다 보면 '내일'도 오는 타입이에요. '새로운 인생'을 만들어가긴 힘들어요. 하지만 여러분도 '욕심 없이' 주어진 대로 만족하며 살 수 있다면, 크게 문제 없을 거예요.
- 선생님
이 선생님의 수업은 전통적인 성과 중심의 접근과는 다를 수 있습니다. 그러나 기본적인 교육 내용은 충실히 다루실 것입니다. 여러분이 적극적으로 수업에 참여하고, 궁금한 점을 질문하며 대화를 이어나가는 것이 중요합니다. 이러한 능동적인 태도로 수업에 임한다면, 더욱 풍부하고 의미 있는 학습 경험을 만들어갈 수 있을 것입니다.
- 선배
동아리나 학생회를 '정적'으로 만들어버릴 타입이에요. '리더'로서의 자격은 거의 없다고 봐야 해요. 여러분의 '운'이 나쁜 거니 그냥 '참고 버티는' 수밖에 없어요.
- 친구, 후배
여러분을 '단순하게' 도와주는 일이나 '잡다한' 일들을 처리하는 데는 큰 문제 없는 친구예요. 하지만 중요한 일은 맡기지 않는 게 좋아요.`,
  },

  BBCCC: {
    type: "BBCCC",
    category: "삶의 존중감이 상실된 유형",
    desc: `'인생 노관심 주의보' 타입`,
    sub: `학교의 숨은 철학자!`,
    char: `여러분은 진정한 '인생 노관심 주의보' 타입이에요! 겉으로 보기에는 평범해 보일지 모르지만, 조금만 가까워지면 의외의 모습들이 나타나는 타입이죠.
기대 이상으로 감성적인 반면, 합리적이지 않고 융통성도 부족해요. 소심하면서도 고집이 강해 '내 맘대로' 행동하는 경향이 있어요. 보이는 모습과 속마음이 너무 달라, 주변 사람들에게는 다가가기 어려운 사람으로 느껴질 때가 많아요.
공부나 삶 자체에 큰 의미를 두지는 않지만, "해야 하니까" 하는 스타일이에요. 주변에서 아무리 충고를 해도 별로 신경 쓰지 않고 귀찮아하는 편이에요. 본인은 이렇게 사는 것이 문제없다고 생각하지만, 오히려 이런 태도 때문에 주변 사람들이 이상하게 느낄 때가 많아요.
여러분의 이런 모습이 다른 사람들과 함께 살아가는 데 있어서 부정적인 영향을 미칠 수 있다는 점을 깊이 생각해볼 필요가 있어요. 혼자만의 방식이 아니라, 함께 살아가는 법을 배워나가야 더 의미 있는 삶을 살 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '인생 노관심 주의보' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 시각과 깊은 생각을 믿어요. 하지만 가끔은 현실에 관심을 갖고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분 마음속에 있는 '내 맘대로' 생각과 '우울 모드' 에너지를 없애지 않으면 학교생활 자체가 힘들어요.
자신의 '목표'를 이루기 위해 친구들과 '진심'으로 교류하고, 여러분이 줄 수 있는 '영향'에 대해 '신중하게' 생각해본 후 학교생활을 시작하세요.
수업 시간에 '무관심'하게 있지 말고 적극적으로 참여해보세요. "이 부분이 궁금한데요"라고 말하는 연습을 해보세요.
동아리 활동은 여러분의 '숨은 재능'을 발견할 수 있는 곳을 찾아보세요. 철학 동아리나 문학 동아리에서 '인생의 의미'를 고민해보는 건 어떨까요?
시험 기간에는 '그냥 하기 싫어'가 아니라, 작은 목표라도 세워보세요. "오늘은 수학 문제 10개 풀기"처럼요. 작은 성취가 여러분을 변화시킬 수 있어요.
진로를 고민할 때는 '아무거나'가 아니라, 정말 여러분이 '의미'를 찾을 수 있는 일을 찾아보세요. 세상을 바꾸는 일을 하는 거예요!`,
    human: `- 베프, 여자친구
항상 '노관심' 에너지를 뿜뿜하는 타입이에요. 아무리 '인내심 만렙'이고 '멘탈 갑'이라도 이 친구와의 관계는 쉽지 않아요.
- 선생님
이 선생님의 수업 방식이 여러분의 학습 스타일과 맞지 않을 수 있습니다. 선생님은 업무적 태도로 수업에 임하실 수 있어, 때로 수업 후 만족감이 낮을 수 있습니다. 다른 학습 옵션을 고려해보는 것도 좋은 방법일 수 있습니다. 하지만 결정 전에 선생님과 소통을 시도해보고, 개선의 여지가 있는지 확인해보는 것이 좋습니다.
- 선배
이런 타입의 선배가 학교에 있을 확률은 거의 없어요. 하지만 만약 여러분의 선배가 이 타입이라면 아무 '기대'도 하지 마세요. 후배로서 할 수 있는 만큼만 하고, 스스로 '돌파구'를 찾아보세요.
- 친구, 후배
기본적인 '책임감'은 있지만 어떤 일에 '재능'이 있는지 알기 어려워요. 작지만 '책임감' 있게 할 수 있는 일부터 부탁해보세요.`,
  },

  BCAAA: {
    type: "BCAAA",
    category: "영악한 어린아이 유형",
    desc: `'츤데레 대마왕' 타입`,
    sub: `학교의 반전 매력 아이돌!`,
    char: `여러분은 진정한 '츤데레 대마왕' 타입이에요! 겉으로는 '괜찮은 사람'처럼 보이지만, 감정 조절이 쉽지 않은 타입이죠.
다른 사람을 배려하는 마음이 부족해서 주변에 대해 관심이 거의 없어요. 평소에는 괜찮아 보이다가도 문제가 생기면 '불안 모드'가 켜지면서 차가운 태도로 "에이, 나도 몰라!" 혹은 "내가 짜증나는데 뭐가 중요해!"라며 자기 마음대로 행동할 때가 많아요.
이성적인 면은 있지만 순간의 감정을 조절하는 것은 매우 어려워요. '아이' 같은 순수함이 강해 자유분방하게 행동하고, 그로 인해 금방 후회하기도 해요. 하지만 이런 모습이 있어서인지, 미워하려 해도 미워할 수 없어요. 그냥 '철없는 애'라고 생각하며 주변에서 참아주고 지켜보게 되는 타입이에요.
여러분의 감정 조절 능력을 조금만 키운다면, 츤데레 매력은 유지하면서도 더 건강한 인간관계를 만들어 갈 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '츤데레 대마왕' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 반전 매력과 에너지를 믿어요. 하지만 가끔은 감정을 조절하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `학교생활의 핵심은 '친구 관계'예요. 여러분의 '좋은 에너지'가 '긍정적인 영향'을 줄 수 있도록 마음속에 잠든 '따뜻한 마음'을 찾아내세요. '배려심'만 키우면 여러분은 어디서나 '인기 만점' 될 수 있어요!
수업 시간에 '감정 폭발'하지 말고 차분히 집중해보세요. 선생님 말씀에 "네~"라고 대답하는 연습을 해보세요.
동아리 활동은 여러분의 '에너지'를 긍정적으로 쓸 수 있는 곳을 찾아보세요. 봉사 동아리나 또래 상담 동아리는 어떨까요? 다른 사람을 이해하는 연습을 할 수 있어요.
시험 기간에는 '짜증' 내지 말고 친구들과 함께 공부해보세요. 서로 도와가며 공부하면 '감정 조절'도 되고 성적도 오를 거예요.
진로를 고민할 때는 여러분의 '반전 매력'을 살릴 수 있는 직업을 찾아보세요. 연기자, MC, 상담사 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이 친구는 '옳고 그름' 생각할 틈도 없이 '못된 말'을 해요. 여러분이 '예민'하다면 상처 많이 받을 수 있어요. 하지만 '너그럽다면' 이 친구의 '속마음'을 이해하고 '리드'할 수 있을 거예요. '특별히 나쁜 뜻'으로 하는 말은 아니에요. 금방 '후회'하고 '아무렇지 않은 척' 해요. 이런 모습이 '귀엽다'고 느껴지면 '인연'이에요!
- 선생님
이 선생님은 때때로 직설적인 표현을 사용하실 수 있습니다. 하지만 이는 선생님의 소통 스타일일 뿐, 개인적인 의도는 아닙니다. 선생님의 말씀을 객관적으로 받아들이고, 수업 내용에 집중하세요. 성실하게 수업에 참여하고 과제를 수행한다면, 원활한 학습 관계를 유지할 수 있을 것입니다. 이러한 태도로 임하면 효과적인 학습 경험을 할 수 있습니다.
- 선배
후배 입장에서는 '아쉬운 점' 많은 선배지만, 선생님들은 이런 선배를 '의외로' 좋아하실 수 있어요. '좋은 면'을 보려고 노력해보세요.
- 친구, 후배
보이는 모습이 '전부'가 아니에요. '유쾌'하고 잘 따르는 것 같지만, 한편으로는 '꾀'가 많은 친구일 수 있어요. '방심'하지 마세요.`,
  },

  BCAAB: {
    type: "BCAAB",
    category: "이기적이고 전략적인 유형",
    desc: `'전략적 카멜레온' 타입`,
    sub: `학교의 비밀 요원!`,
    char: `여러분은 진정한 '전략적 카멜레온' 타입이에요! 겉으로는 '쿨'하고 '자유로운' 스타일이지만, 상황에 맞게 '변신'하는 능력이 '갑'이에요.
다른 사람들 일에 '노관심'이고 '감성 부족'해 보이지만, 이걸 '전략적'으로 숨기는 것도 '찐실력'이에요.
'호기심'이 많아서 '색다른 경험'에 대한 '욕심'이 많아요. '이상적인' 것을 추구하지만, '권력'이나 '인정'에 대한 '집착'은 없어서 주변 '눈치'를 별로 안 봐요. 그냥 '자기만의 재미'를 찾는 데 '올인'하는 스타일이에요.
조금만 더 '따뜻한 마음'을 가질 수 있다면, 더 넓은 세상에서 다양한 경험을 할 수 있고, 인생을 더 '꿀잼'으로 만들 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '전략적 카멜레온' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 전략적 사고와 창의력을 믿어요. 하지만 가끔은 다른 사람을 배려하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `다른 사람을 '배려'하는 마음을 키우면 어떤 기회든 잡을 수 있어요. '획기적인 아이디어'를 현실로 만드는 능력도 '갑'이에요. '창의적인' 활동으로 '성과' 낼 수 있는 일에 도전해보세요!
수업 시간에 '몰래' 딴짓하지 말고 적극적으로 참여해보세요. 여러분의 '독특한 아이디어'로 수업을 더 재밌게 만들 수 있어요.
동아리 활동은 여러분의 '창의력'을 마음껏 발휘할 수 있는 곳을 찾아보세요. 발명 동아리나 창업 동아리는 어떨까요?
시험 기간에는 '전략적'으로 공부하세요. 효율적인 공부 방법을 찾아 짧은 시간에 '최대 성과'를 내보세요.
진로를 고민할 때는 여러분의 '전략적 사고'를 활용할 수 있는 직업을 찾아보세요. 마케터, 전략 기획자, 외교관 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이 친구는 '안정적인' 관계보다는 '재미'있는 연애 상대로 좋아요. 여러분이 어떤 관계를 원하는지에 따라 '인생 최고의 베프'가 될 수도, '악연'이 될 수도 있어요.
- 선생님
이 선생님은 교육 능력이 우수하지만, 예상치 못한 상황에 대비하는 경향이 있습니다. 문제 발생 시 독립적으로 대처하실 수 있으니, 학습 과정에 주의를 기울이는 것이 중요합니다. 수업에 적극적으로 참여하면서도 자기 주도적 학습 태도를 유지하세요. 이러한 접근으로 수업의 이점을 최대한 활용하고, 동시에 개인적 성장도 이룰 수 있을 것입니다.
- 선배
이런 선배는 '전략의 달인'이에요. 여러분의 '능력'을 최대한 활용하려고 해요. 여러분에게 '도움 되는 기회'를 줄 수 있지만, '책임' 문제에서는 '불공평'한 상황에 처할 수 있어요. '신중'하되 '빠르게' 대응하세요.
- 친구, 후배
처음 보면 '함께 대박 칠' 수 있는 '파트너' 같아 보이지만, 결국엔 '자기 것'만 챙길 수 있어요. '섣불리' 모든 걸 주지 마세요.`,
  },

  BCAAC: {
    type: "BCAAC",
    category: "자기 중심적인 자유 영혼 유형",
    desc: `'프리덤 셀카봉' 타입`,
    sub: `학교의 미지의 카리스마!`,
    char: `여러분은 진정한 '프리덤 셀카봉' 타입이에요! '자유'를 추구하는 '현실주의자'로, 겉으로 봤을 땐 '꽤 매력적'이지만 속마음은 '쿨'하고 '내 맘대로'인 데다 '거친 에너지' 가득한 스타일이에요.
'자기 자랑' 맛에 사는 사람으로, 남을 위해 '희생'하는 일은 절대 없어요. 늘 '내가 1순위'로 생각하고 행동하며, '인생 목표'는 오직 '자기 행복' 뿐이에요. 한 가지에 '빠지면' 집중하는 성향이 있어 '멋져' 보일 수 있지만, 모든 게 '나만을 위한 것'이에요. 주변 사람들은 모두 '자기 욕구 충족'을 위한 '도구'일 뿐이죠.
'진짜' 친구를 만들려면 '계산'하는 마음을 버리고, '진심 어린 관심'과 '배려심', '융통성'을 기르는 게 필요해요!`,
    result: `기억하세요! 이 타입은 '프리덤 셀카봉' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자유로운 영혼과 현실감각을 믿어요. 하지만 가끔은 다른 사람을 배려하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분에게 '명확한 목표'만 있다면 반드시 '성공'할 거예요. 하지만 나중에 여러분의 '본모습'을 알게 되면 그 기회가 '순식간에' 사라질 수 있어요.
'현실'을 보는 '눈'과 '뛰어난 상상력'이 빛날 수 있도록 '인성'을 기르는 노력을 해보세요.
수업 시간에 '내가 최고'라고 생각하지 말고, 다른 친구들의 의견도 경청해보세요. "너의 생각도 들어볼래?"라고 말하는 연습을 해보세요.
동아리 활동은 '팀워크'를 배울 수 있는 곳을 찾아보세요. 밴드부나 체육부 같은 데서 '협동'의 중요성을 배워보는 건 어떨까요?
시험 기간에는 '혼자 다 잘난 척'하지 말고, 친구들과 함께 공부해보세요. 서로 도와가며 공부하면 더 좋은 성적을 받을 수 있을 거예요.
진로를 고민할 때는 여러분의 '현실감각'과 '상상력'을 살릴 수 있는 직업을 찾아보세요. 하지만 '인성'도 함께 키워나가는 걸 잊지 마세요!`,
    human: `- 베프, 여자친구
여러분이 '빠져들기에' 충분히 '매력적'인 사람이에요. 하지만 이 친구의 '관심'은 오래가지 않을 거예요. 같은 마음이 아니라면 '적당히' 선을 그어두는 게 좋아요.
- 선생님
이 선생님과의 단기 수업은 문제없이 진행될 수 있습니다. 하지만 장기적인 학습 관계에서는 주의가 필요할 수 있습니다. 선생님은 실용적이고 전략적인 접근을 선호하실 수 있어, 때로 깊은 신뢰 관계 형성에 어려움이 있을 수 있습니다. 수업에 참여할 때는 객관적인 태도를 유지하고, 학업 성취에 초점을 맞추는 것이 도움이 될 수 있습니다.
- 선배
자기가 '원하는 걸' 이루기 위해 여러분을 '이용'할 수 있어요. '좋은 리더'의 모습은 아니에요. 여러분의 '모든 능력'이 선배만을 위해 쓰일 수 있으니 항상 '조심'하세요. 좋은 건 '선배 것', 나쁜 건 '여러분 것'이 될 수 있어요.
- 친구, 후배
'좋아하는 걸' 주면 '무조건' 좋아하는 '애기' 같은 친구예요. 하지만 '영리한' 면도 있어서 상황을 '빨리' 바꿔버릴 수 있으니 '잘' 대해주되 '경계'도 하세요.`,
  },

  BCABA: {
    type: "BCABA",
    category: "눈치 빠른 현실주의자 유형",
    desc: `'눈치 만렙 현실주의자' 타입`,
    sub: `학교의 숨은 생존 전문가!`,
    char: `여러분은 진정한 '눈치 만렙 현실주의자' 타입이에요! 어떻게 해야 편한 학교생활을 할 수 있을지 빠르게 파악하는 '생존 전문가'예요.
스스로 주도적으로 살아가는 것을 어려워하고, 누군가 이끌어줄 사람을 찾아 좋은 모습만 보이려는 모습이 가끔은 조금 안쓰러워 보일 정도예요. 따뜻한 배려심이나 친밀함은 거의 없는 '쿨'한 성격이지만, 남에게 잘 보이고 싶은 마음과 기대에 부응하려는 순종적인 성향 때문에 '착한 아이 증후군'에 빠져 있어요.
진짜 모습을 보이는 것보다, 현재의 이런 모습이 남에게 의지하며 살아가기 좋은 기회를 만든다는 사실을 너무 잘 알고 있어요. 다행히도 현실을 보는 눈은 뛰어나서, 이런 태도가 나중에는 불행으로 이어질 수도 있다는 것도 어렴풋이 알고 있을 거예요.
가짜 모습으로 살아가며 에너지를 낭비하지 말고, 조금 무섭고 시간이 걸리더라도 자기만의 삶을 만드는 데 노력해보세요. 따뜻한 말과 행동을 조금씩 해 나가다 보면, 생각보다 빨리 좋은 결과를 얻을 수 있을 거예요. 진정한 자신을 찾아가는 그 여정에서 더 많은 의미와 행복을 느낄 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '눈치 만렙 현실주의자' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 현실감각과 적응력을 믿어요. 하지만 가끔은 진짜 자신의 모습을 보여주고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 누군가가 '기회'를 주기만 기다리고 있을 거예요. 가능한 '힘 있는' 사람에게 '도움' 받으려는 마음이 강해요. '자신'에 대한 '올바른 믿음'과 뛰어난 '현실감각'을 활용해서 '적극적'으로 도전해보세요. '잘 보이려는 것'보다는 '진짜 나'를 보여주세요.
수업 시간에 '선생님 눈에 들려고' 하지 말고, 진짜 '궁금한 걸' 질문해보세요. "이 부분이 이해가 안 가요. 더 설명해주실 수 있나요?"라고 말하는 연습을 해보세요.
동아리 활동은 여러분의 '진짜 관심사'를 찾을 수 있는 곳으로 가보세요. 봉사 동아리나 진로 탐구 동아리에서 '자신'을 발견할 수 있을 거예요.
시험 기간에는 '남 눈치' 보지 말고 '자기 방식'대로 공부해보세요. 여러분만의 '효율적인' 공부법을 찾아보세요.
진로를 고민할 때는 '남이 좋다고 하는 것'이 아니라, 정말 '내가 하고 싶은 것'이 뭔지 찾아보세요. 여러분의 '현실감각'을 믿으세요!`,
    human: `- 베프, 여자친구
'논리적'이고 '이성적'인 친구예요. '차갑고 쿨'한 사람이기도 해요. 여러분에게 '일부러' 상처 주거나 '무조건' 희생하라고 하진 않을 거예요. 하지만 오랫동안 '마음 나누고' 인생 함께할 친구로는 여러분 마음 한구석이 '외롭고 허전'해질 수 있어요. '신중하게' 생각해보세요.
- 선생님
이 선생님은 학생들의 능력과 태도에 따라 다른 접근 방식을 취하실 수 있습니다. 성실하고 열정적인 태도로 수업에 임한다면 선생님의 긍정적인 관심과 지원을 받을 가능성이 높습니다. 그렇지 않은 경우, 선생님의 집중적인 지도를 받기 어려울 수 있습니다. 따라서 적극적으로 수업에 참여하고 자신의 학습 의지를 보여주는 것이 중요합니다.
- 선배
'자기 살기' 바빠서 여러분에게 '깊은 관심' 주진 못해요. 후배들을 위해 '희생'하거나 '대단한 성과' 낼 수 있는 '큰 인물'은 아니에요. 여러분 눈에는 '소심한 기회주의자'로 보일 수 있지만, '살아남기' 위해 열심히 사는 거니까 '함부로' 대하지 마세요.
- 친구, 후배
여러분이 '믿어준다면' 무한 '충성심' 보여주는 친구예요. '배려심'이 약해서 다른 애들이랑 '잘 어울리진' 못하지만 스스로 '문제' 일으키진 않아요. 여러분 '기대'에 어긋나지 않게 어떤 일이든 '최선' 다해 '열심히' 할 거예요.`,
  },

  BCABB: {
    type: "BCABB",
    category: "계산적이고 무미건조한 유형",
    desc: `'인생 계산기' 타입`,
    sub: `학교의 현실주의 아이돌!`,
    char: `여러분은 진정한 '인생 계산기' 타입이에요! 좀 노잼한 스타일이지만, 현실 감각만큼은 정말 뛰어나요.
다른 사람을 배려하는 것에는 큰 관심이 없고, 다소 차가운 면이 있지만, 세상을 살아가는 데 필요한 처세술은 완벽해요. '친한 척' 하거나 '정'을 주고받는 것에는 전혀 관심이 없고, "너는 너, 나는 나, 공부는 공부!" 이런 마인드가 강해요.
하지만 그렇다고 해서 너무 욕심을 부리거나 '내 방식'만 고집하지는 않아요. 다른 사람들을 무시하지도 않아서, 주변에 큰 민폐를 끼치지는 않아요. 합리적인 사고는 탁월하지만, 인간관계를 건강하게 유지하는 데는 좀 부족한 편이에요. 누군가를 돌봐주거나 맞춰주고, 리액션해주는 것이 너무 귀찮게 느껴져요.
좀 더 가치 있는 삶을 살고 싶다면, 주변의 모든 것에 조금 더 관심을 가져보는 건 어떨까요? 주변 사람들과 소소한 감정을 공유하고, 관심을 표현해보는 것이 여러분의 삶을 더 풍부하고 의미 있게 만들어 줄 거예요.`,
    result: `기억하세요! 이 타입은 '인생 계산기' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 현실감각과 합리성을 믿어요. 하지만 가끔은 다른 사람과 소통하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 '유연한' 학교생활이 좀 어려워요. '인간관계' 많은 활동보다는 '혼자' 집중해서 '성과' 내는 일이 좋아요. '과학실'이나 '도서관'에서 '연구'하는 게 딱이에요.
수업 시간에 '친구들이랑 협동'하는 거 싫어하지 말고 한번 해보세요. "이번엔 같이 해보자"라고 말하는 연습을 해보는 건 어때요?
동아리 활동은 '혼자' 할 수 있는 걸 찾아보세요. '과학 동아리'나 '코딩 동아리' 같은 데서 여러분의 '천재성'을 보여줄 수 있을 거예요.
시험 기간에는 '혼자' 공부하는 게 편하겠지만, 가끔은 '스터디 그룹'에 참여해보세요. 다른 친구들의 '공부법'도 배울 수 있어요.
진로를 고민할 때는 '혼자' 집중해서 할 수 있는 일을 찾아보세요. 과학자, 프로그래머, 작가 같은 직업은 어떨까요?`,
    human: `- 베프, 여자친구
이런 친구랑 '절친'되기는 좀 힘들어요. '의미 있는 관계'나 '꿀잼' 학창시절 기대하긴 어려워요. '기본'만 지키는 '형식적인' 관계가 될 가능성이 높아요.
- 선생님
이 선생님은 차분하고 절제된 성향을 가지고 계실 수 있습니다. 활발한 성격의 학생들에게는 다소 거리감이 느껴질 수 있어요. 정규 수업은 원활하게 진행되겠지만, 깊은 정서적 교류는 제한적일 수 있습니다. 수업에 집중하고 학업적인 측면에 초점을 맞추는 것이 효과적일 것 같습니다.
- 선배
자기 '역할'은 '정확히' 해내는 선배예요. 하지만 여러분을 '이끌어주고' 성장시켜줄 거란 '기대'는 하지 마세요. 여러분도 '맡은 일'만 잘하세요.
- 친구, 후배
'현실 감각' 좋아서 '공부'는 잘할 수 있는 친구예요. 하지만 거기까지예요. '가십'이나 '일상' 얘기는 다른 친구들이랑 하세요.`,
  },

  BCABC: {
    type: "BCABC",
    category: "혼자일 때 행복한 유형",
    desc: `'혼자놀기 만렙' 타입`,
    sub: `학교의 은둔 고수!`,
    char: `여러분은 진정한 '혼자놀기 만렙' 타입이에요! 다른 사람에 대한 배려, 관심, 공감 등에는 별로 관심이 없어요.
적당히 인생을 즐길 줄도 알고, 현실 감각도 뛰어나서 잘 살아가고는 있지만, 마음속 깊은 곳에서는 '주변에 아무도 없었으면 좋겠다'는 생각이 더 강해요. '내가 제일 잘나가'라는 독선적인 면이 있어서 다른 사람들과 자연스럽게 어울리는 게 쉽지 않아요.
하지만 본인은 이런 상황에 대해서 전혀 위기의식을 느끼지 않고, 오히려 만족해하는 편이에요. 이런 타입이 옳거나 그르다고 말할 수는 없지만, '좋은 태도'라고 하긴 어려울 수도 있어요.
여러분의 기대와는 달리, 주변에는 항상 함께하는 사람들이 생겨나게 마련이에요. 여러분은 합리적인 판단력을 가지고 있으니, 주변 사람들과의 관계가 어떤 이득을 줄 수 있는지도 잘 알 거예요. '마음의 문'을 조금만 열어보는 것부터 시작해보는 건 어때요? 작은 변화가 큰 차이를 만들어낼 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '혼자놀기 만렙' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독립성과 자립심을 믿어요. 하지만 가끔은 다른 사람과 소통하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분이 '학교생활'을 잘하고 싶다면 우선 '내가 제일 잘나가'식 생각부터 버리세요. '인간관계'가 얼마나 중요한지 모르면 어떤 '기회'도 얻을 수 없어요.
수업 시간에 '혼자' 공부하는 것만 좋아하지 말고, 친구들이랑 '협동'해보세요. "우리 같이 해보자"라고 말하는 연습을 해보는 건 어때요?
동아리 활동은 '혼자' 할 수 있는 걸 찾아보세요. 하지만 가끔은 '단체' 활동에도 참여해보세요. '사진 동아리'나 '독서 동아리'에서 다른 사람들이랑 '소통'하는 법을 배워보세요.
시험 기간에는 '혼자' 공부하는 게 편하겠지만, 가끔은 '스터디 그룹'에 참여해보세요. 다른 친구들의 '공부법'도 배울 수 있어요.
진로를 고민할 때는 '혼자' 일할 수 있는 직업만 생각하지 말고, '협업'이 필요한 직업도 고려해보세요. 세상엔 '혼자' 할 수 없는 일이 더 많다는 걸 기억하세요!`,
    human: `- 베프, 여자친구
이 친구는 '같이' 사는 '인생'이란 개념 자체가 어려운 사람이에요. 여러분이 아무리 '애정' 넘쳐도 상대가 '받아주지' 않으니 결국 '헤어지게' 될 거예요.
- 선생님
이 선생님의 평가 기준은 매우 엄격할 수 있습니다. 융통성 있는 대화나 조정이 어려울 수 있으며, 상황에 따라 접근 방식이 달라질 수 있습니다. 추가적인 기회나 특별한 배려를 기대하기보다는, 주어진 기준에 맞춰 최선을 다하는 것이 중요합니다. 객관적이고 일관된 태도로 수업에 임하고, 자신의 실력 향상에 집중하는 것이 효과적일 것입니다.
- 선배
이런 선배랑 '잘 지내는' 방법은 딱 하나예요. '똑똑해'지세요. 항상 '정답'을 갖고 있어야 하고 '논리정연'하고 '이성적'이며 '합리적'이어야 해요.
- 친구, 후배
'혼자' 할 수 있는 일을 줘보세요. '팀워크' 필요한 일은 다른 친구들 '감정'을 '격하게' 만들 수 있어요.`,
  },

  BCACA: {
    type: "BCACA",
    category: "주인을 기다리는 컴퓨터 유형",
    desc: `'대기 모드 인간' 타입`,
    sub: `학교의 숨은 잠자는 숲속의 공주!`,
    char: `여러분은 진정한 '대기 모드 인간' 타입이에요! 누군가가 '전원 버튼'만 눌러주면 비로소 움직일 수 있는 스타일이죠. 혼자서는 거의 아무것도 하지 않는 타입이에요.
다른 사람이 시작을 열어주면 그에 따라 반응을 보이기는 하지만, '어떻게 살아야 행복할까?'에 대한 주체적인 생각은 부족해서 늘 다른 사람에게 의존하며 안정감을 찾으려 해요. 그런데 다른 사람에 대한 관심이나 배려, 정 같은 건 거의 없다는 것이 특이한 점이죠.
현실을 보는 눈은 뛰어나서, 어떻게 하면 '살아남을' 수 있는지 알고 이를 위해 인간관계를 적절히 활용하는 편이에요. 다만, 만약 주변에 잘못된 사람을 만나게 된다면 그 수동적이고 무의미한 삶에서 회의감을 느끼며 위험한 상황이 발생할 수도 있어요.
혼자서 살아갈 의지가 부족하다 보니, 어떻게든 주변 사람들과 협력하려는 상황을 만들려고 해요. 여러분의 존재감을 키우고 싶다면 다른 사람과의 관계에 좀 더 '따뜻한 마음'과 '진심'을 담아보세요. 그렇게 하면 단순히 '대기 모드'에서 벗어나 더 활기차고 의미 있는 인생을 살아갈 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '대기 모드 인간' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 적응력과 현실감각을 믿어요. 하지만 가끔은 스스로 결정하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 다른 사람에 대한 '진짜 신뢰'와 '자신감'을 키우는 게 중요해요. '자기'를 '믿는' 마음으로 하고 싶은 걸 '도전'하려는 '의지'가 부족해요.
'실패'나 '거절'에 대해 '고민'하기보다는 일단 '부딪혀' 경험을 쌓아야 해요. '본 게임' 도전하기 전에 '작은 게임'부터 해보세요.
수업 시간에 '혼자' 발표하는 건 어려워도, 친구들이랑 '팀 발표'는 해볼 수 있어요. "우리 같이 해보자"라고 말하는 연습을 해보세요.
동아리 활동은 '혼자' 하는 것보다 '단체' 활동을 찾아보세요. '연극부'나 '합창부'에서 다른 사람들이랑 '협력'하는 법을 배워보세요.
시험 기간에는 '혼자' 공부하는 게 어려울 수 있어요. '스터디 그룹'에 참여해서 친구들이랑 같이 공부해보세요.
진로를 고민할 때는 '혼자' 결정하지 말고 선생님이나 부모님께 조언을 구해보세요. 하지만 최종 결정은 '여러분'이 해야 해요!`,
    human: `- 베프, 여자친구
여러분이 '줄 수 있는 게' 많다면 '인연'이 될 수 있어요. 하지만 '밝고 재미있는' 삶은 포기해야 할지도 몰라요. 이 친구는 여러분에게 '현실'을 알려주고 '말 잘 듣는 것' 말고는 별로 도움 안 돼요.
- 선생님
이 선생님은 학생들과의 긍정적인 상호작용을 중요하게 여기십니다. 단순한 수업 관계를 넘어 따뜻한 격려와 인정이 필요할 수 있습니다. 협력적이고 참여적인 학습 환경을 조성하는 것이 도움이 될 수 있어요. 수업에 적극적으로 참여하고, 선생님과 건설적인 대화를 나누며 상호 이해를 높이는 것이 좋습니다. 이러한 접근은 더 풍부하고 의미 있는 학습 경험을 만들어낼 수 있을 것입니다.
- 선배
'선배'지만 여러분이 '이끌어야' 할 때가 많을 거예요. '무능력'한 사람은 아니에요. 그냥 '태도'가 너무 '수동적'이고 자기를 잘 '드러내지' 못해서 그래요. 여러분이 '조금만' 진심으로 '힘'을 실어주면 '예상 못 한' 좋은 결과를 줄 수도 있어요.
- 친구, 후배
'정리', '정돈', '통계', '숫자'랑 잘 맞는 친구예요. '활동적인' 일보다는 '한 곳'에서 '집중'할 수 있는 일에서 '잘' 해요. 친구들 사이에서 '존재감'이 약하지만 스스로 '드러내지' 못하는 성격이에요. '잊지 말고' 꼭 '챙겨'주세요.`,
  },

  BCACB: {
    type: "BCACB",
    category: "우울한 우등생 유형",
    desc: `'슬픈 엘리트' 타입`,
    sub: `학교의 숨은 공부 히어로!`,
    char: `여러분은 진정한 '슬픈 엘리트' 타입이에요! 현실적으로 뭘 해야 할지 완벽히 알고 있고, 매사에 실수가 거의 없는 사람이에요.
하지만 그 모든 행동이 단지 "해야 하니까 하는 거야"라는 생각에서 비롯돼요. 특별한 의미나 즐거움 없이 그냥 모두가 하니까 따라 하며 하루하루를 보내죠. 이런 방식이 이상하다고 생각하지도 않고, 그저 현실에 맞게 움직이는 것이죠.
완벽해 보이지만, 사실 여러분은 자신에게 가장 불친절한 사람일지도 몰라요. 주변 사람들과 어울리지 않고, 인생의 즐거움이나 슬픔, 화남, 사랑 같은 감정을 깊이 경험하지 못한 채 살아가고 있죠.
여러분, 인생을 조금 더 의미 있게 살아가기 위한 '꿀팁 리스트'를 만들어보는 건 어때요? 따뜻하게 말해보기, 먼저 웃어주기, 함께 시간을 보내기 같은 것들이요. 처음에는 별다른 기대 없이 해보더라도, 분명 놀라운 변화가 일어날 거예요. 감정을 나누고 표현하는 것만으로도, 인생은 훨씬 더 풍부하고 따뜻해질 수 있답니다.`,
    result: `기억하세요! 이 타입은 '슬픈 엘리트' 스타일이지만, '감정 표현'과 '다양한 경험'을 쌓으면 진짜 '인생의 주인공'이 될 수 있어요!
    여러분의 학업 능력과 현실감각은 대단해요. 하지만 가끔은 마음의 소리에 귀 기울이고, 새로운 도전을 해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 공부는 잘하지만, 그게 전부는 아니에요. 학업에만 너무 집중해서 다른 걸 놓치고 있어요.
수행평가나 시험은 완벽하게 해내지만, 학교 행사나 동아리 활동엔 별로 관심이 없어요.
선생님들은 이 학생을 좋아하시겠지만, 친구들 사이에선 좀 외로울 수 있어요.
균형 잡힌 학교생활을 위해서는 공부 외에도 다양한 활동에 참여해보세요:
관심 있는 동아리에 가입해보기 / 친구들과 함께 교내 프로젝트 해보기
봉사활동에 참여해 다양한 경험 쌓기 / 학급 회의나 학생회 활동에 적극적으로 참여해보기
이런 경험들이 나중에 대학 입시나 진로 선택에도 큰 도움이 될 거예요.`,
    human: `- 베프, 여자친구
이 친구랑 있으면 좀 지치고 외로울 수 있어요. 나쁜 마음은 없지만, 감정 표현을 잘 못해요. 재미있는 일도 별로 없어 보이죠. 그래도 포기하지 마세요! 여러분의 따뜻한 마음이 이 친구를 변화시킬 수 있어요. 함께 새로운 경험을 해보며 감정을 나누는 연습을 해보세요.
- 선생님
이런 타입의 선생님은 수업과 과제에만 집중하실 거예요. 다른 얘기는 별로 안 하실 거예요. 그래도 괜찮아요. 질문이나 도움이 필요할 때 정중하게 다가가세요. 수업 내용 외의 대화를 기대하진 마세요. 대신 선생님의 전문성을 존중하고 배우려는 자세로 임하면 좋은 관계를 유지할 수 있어요.
- 선배
이 선배는 그냥 '선배'일 뿐이에요. 특별한 관심이나 간섭은 없을 거예요. 그게 이 선배의 성격이니까 서운해하지 마세요. 필요한 도움을 받을 때는 명확하게 요청하세요. 학업이나 진로에 관한 조언을 구할 때 특히 도움이 될 수 있어요.
- 친구, 후배
이 친구들은 맡은 일만 잘 해요. 그 이상의 기대는 하지 마세요. 대신 함께 하는 일에서는 믿음직한 파트너가 될 거예요. 그룹 프로젝트나 스터디 그룹에서 이 친구들의 능력을 인정해주고, 동시에 함께 즐거운 시간도 보내보세요.`,
  },

  BCACC: {
    type: "BCACC",
    category: "사리분별 하나로 살아가는 유형",
    desc: `'차가운 빙산' 타입`,
    sub: `학교의 쿨내 폭발 아이스맨!`,
    char: `여러분은 진정한 '차가운 빙산' 타입이에요! 사람 때문에 고민하는 일이 거의 없는, 심플한 성격의 소유자죠.
'좋아요', '싫어요'가 너무 명확해서 인간관계가 아주 단순해요. 멋져 보이긴 하지만, 사실 자기 중심적이고 다른 사람과의 관계에 깊은 애착을 느끼지는 않아요. 감정 표현도 어려워서 차갑고 무덤덤하게 보일 때가 많아요.
현실 감각은 뛰어나서 부적절한 행동은 하지 않지만, 건강한 인간관계를 맺는 건 다소 어렵죠. 스스로는 지금의 생활에 만족하고 있을지도 모르지만, 여러분! 학교는 무인도가 아니에요. 친구들과의 관계도 인생에서 중요한 부분이라는 점을 한 번쯤 생각해보세요. 감정을 나누고 서로를 이해하려는 작은 노력들이, 여러분에게 더 많은 기쁨과 의미를 가져다줄 거예요.`,
    result: `기억하세요! 이 타입은 '차가운 빙산' 스타일이지만, '감정 표현'과 '관계 형성 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 논리력과 판단력은 대단해요. 하지만 가끔은 마음을 열고, 다른 사람들과 소통하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 1:1로 협력하는 활동이 잘 맞아요. 여러분의 특기를 살릴 수 있는 분야를 찾아보세요:
-개인 프로젝트에 도전해보기: 과학 탐구 대회나 창의력 대회 같은 개인 참가 행사에 도전해보세요.
-멘토링 프로그램 참여하기: 1:1로 후배를 도와주거나, 선생님과 개별 상담을 받아보세요.
-온라인 학습 플랫폼 활용하기: 혼자 공부하는 걸 좋아한다면, 온라인 강의를 들어보는 것도 좋아요.
-특기 살리기: 음악, 미술, 코딩 등 혼자서도 잘할 수 있는 분야를 찾아 집중적으로 발전시켜보세요.
-소규모 스터디 그룹 만들기: 비슷한 성향의 친구 1-2명과 함께 공부하는 습관을 들여보세요.
-학교 도서관 활용하기: 조용히 혼자 공부하고 싶다면, 도서관을 적극 활용해보세요.
-진로 탐색하기: 자신의 장점을 살릴 수 있는 직업에 대해 진로 상담을 받아보세요.`,
    human: `- 베프, 여자친구
이 친구는 자기가 엄청 상식적이라고 생각하지만, 여러분 눈에는 좀 비상식적이고 소통 못 하는 융통성 제로 인간으로 보일 수 있어요. 얼마나 참을 수 있는지 진지하게 생각해보고 결정하세요. 하지만 그들의 솔직함과 명확성을 장점으로 볼 수도 있어요.
- 선생님
이런 타입의 선생님과는 공정하고 평등한 관계를 유지할 수 있어요. 선생님의 논리적인 면을 존중하고, 수업에 집중하면 좋은 관계를 유지할 수 있을 거예요. 개인적인 이야기보다는 학업이나 진로에 관한 조언을 구하는 게 좋아요.
- 선배
이런 타입의 선배가 있다는 건 정말 희귀해요! 그만큼 특별한 거죠. 선배의 뛰어난 판단력을 인정하고, 여러분의 논리적인 면을 보여주세요. 감정적인 접근보다는 이성적인 대화가 더 효과적일 거예요.
- 친구, 후배
이 친구들은 정말 다양한 사람 중 한 명일 뿐이에요. 바꾸려고 하지 말고 그냥 있는 그대로 받아들이세요. 현실적인 사람이라 터무니없는 짓은 안 해요. 맡은 일은 책임감 있게 하니까 크게 기대하지 말고 기본적인 관계만 유지해도 돼요.`,
  },

  BCBAA: {
    type: "BCBAA",
    category: "자기만 생각하는 아이 유형",
    desc: `'주인공 신드롬' 타입`,
    sub: `학교의 솔로 플레이어!`,
    char: `여러분은 진정한 '주인공 신드롬' 타입이에요! 다른 사람들과의 관계에서 배려가 조금 부족하고, 자기 감정만 소중히 여기는 스타일이죠.
모든 걸 재미 위주로 생활하고, 다른 사람 말은 잘 안 들어서 가끔 곤란한 상황에 빠질 수 있어요. 일할 때 감정이 앞서고, 결과가 안 좋으면 책임지기 싫어하고 도망가려고 해요. 실수하면 다른 사람보다 더 쪼그라들기도 해요.
이런 타입은 자기 감정에 빠져서 다른 사람의 행동을 이해 못 하고 배려도 못 해서 상황을 더 나쁘게 만들어 눈총을 받기도 해요.
이 타입이라면 다른 사람을 존중하고 이해하는 법부터 배우는 게 좋겠어요!`,
    result: `기억하세요! 이 타입은 '주인공 신드롬' 스타일이지만, '배려심'과 '협력 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 열정과 솔직함은 대단해요. 하지만 가끔은 한 발 물러서서 다른 사람의 입장도 생각해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 다른 사람을 배려하는 연습이 필요해요. 평소에 정 없고 배려 없는 모습이 습관이 됐을 수 있거든요.
우리 시대는 다른 사람과 협력해서 시너지를 내는 능력이 중요해요. 지금부터 다른 사람을 배려하고 함께 문제를 해결하는 연습을 해보세요:
-팀 프로젝트에 적극 참여하기: 다른 친구들의 의견도 듣고 존중하는 연습을 해보세요.
-봉사활동 참여하기: 다른 사람을 돕는 경험을 통해 배려심을 기를 수 있어요.
-동아리 활동하기: 공동의 목표를 위해 협력하는 법을 배울 수 있어요.
-갈등 해결 스킬 배우기: 친구들과 갈등이 생겼을 때, 대화로 해결하는 방법을 연습해보세요.
-감정 일기 쓰기: 자신의 감정을 이해하고 조절하는 능력을 기를 수 있어요.
-역할극 참여하기: 다른 사람의 입장이 되어보는 경험을 통해 공감 능력을 키워보세요.
-멘토링 프로그램 참여하기: 선배나 선생님의 조언을 듣고 성장하는 기회를 가져보세요.`,
    human: `- 베프, 여자친구
이 친구는 자기 감정에 충실하고 다른 사람을 잘 배려 못해요. 관계를 유지하려면 많은 에너지가 필요할 거예요. 마치 부모님처럼 이해해주고 받아줘야 할 때가 많을 수 있어요. 하지만 그들의 솔직한 감정 표현을 장점으로 볼 수도 있어요.
- 선생님
겉으로는 즐겁고 유쾌해 보이지만, 수업이나 학교 생활에서 배려심이 부족할 수 있어요. 선생님의 지시를 잘 따르고, 다른 학생들과의 협력을 강조하는 수업에 적극적으로 참여하세요.
- 선배
감정에 휘둘려 일을 처리하기 쉬워요. 여러분의 입장은 별로 생각 안 하고 행동할 수 있어요. 선배로서의 배려를 기대하기는 어려울 수 있어요. 대신 솔직하고 열정적인 모습을 보여줄 수 있으니, 그 에너지를 긍정적으로 활용해보세요.
- 친구, 후배
곤란한 상황에 빠지면 이 타입은 자기가 좋은 사람으로 보이고 싶어해서 여러분을 어려운 상황에 빠뜨릴 수도 있어요. 하지만 그들의 순수한 의도를 이해하고, 함께 해결책을 찾아보세요.`,
  },

  BCBAB: {
    type: "BCBAB",
    category: "호기심 많은 사냥꾼 유형",
    desc: `'호기심 폭발 쿨가이' 타입`,
    sub: `학교의 마이웨이 챔피언!`,
    char: `여러분은 진정한 '호기심 폭발 쿨가이' 타입이에요! 언제나 자유롭고 유쾌한 분위기를 풍기는 스타일이죠.
평소엔 욕심 없어 보이다가도, 관심 있는 게 생기면 무서운 집중력으로 달려드는 사냥꾼 같아요. 한번 손에 넣은 건 절대 놓지 않아요.
다른 사람에게 베풀거나 배려하는 건 조금 서툴러서, 주변 사람들이 지칠 수 있어요. 재미있는 걸 좋아해서 평소엔 즐거운 모습을 보여주지만, 스트레스 받으면 차갑게 굴고 이해해 달라고 떼쓰기도 해요.
이 타입이라면 다른 사람을 이해하고 받아들이는 자세를 갖는 게 중요해요! 멋지고 자유로워 보이지만, 계속 차갑게 대하면 주변에 친구들이 없어질 수 있어요.`,
    result: `기억하세요! 이 타입은 '호기심 폭발 쿨가이' 스타일이지만, '공감 능력'과 '협동심'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 호기심과 집중력은 대단해요. 하지만 가끔은 주변 사람들을 둘러보고 그들의 마음도 헤아려보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 자신의 목표를 세우고 미래를 준비하는 게 좋아요. 자유로운 성격이라 남들과 협력하는 게 잘 맞을지 생각해보고, 진짜 하고 싶은 게 뭔지 찾는 게 중요해요. 감정 조절하는 연습과 함께 팀워크 기르는 활동을 해보면 좋을 거예요:
-동아리 활동 참여하기: 관심 있는 분야에서 다른 친구들과 협력하는 법을 배워보세요.
-진로 탐색 프로그램 참여하기: 다양한 직업을 알아보고 자신에게 맞는 길을 찾아보세요.
-감정 일기 쓰기: 매일 자신의 감정을 기록하며 감정 조절 능력을 키워보세요.
-멘토링 프로그램 참여하기: 선배나 선생님의 조언을 들으며 자신의 성장 방향을 찾아보세요.
-팀 프로젝트 도전하기: 다른 친구들과 함께 목표를 달성하는 경험을 쌓아보세요.
-봉사활동 해보기: 다른 사람을 돕는 경험을 통해 배려심을 기를 수 있어요.
-스터디 그룹 만들기: 함께 공부하며 협력의 중요성을 배워보세요.`,
    human: `- 베프, 여자친구
이 친구는 항상 자유분방하고, 가끔은 '나쁜 남자/여자'처럼 매력적으로 보일 수 있어요. 하지만 그 매력만큼 자기 자신에게 더 관심이 많다는 걸 기억하세요. 그들의 독특한 매력을 즐기되, 너무 기대하지 않는 게 좋아요.
- 선생님
이 선생님과의 관계에서는 신중한 접근이 필요할 수 있습니다. 상황에 따라 선생님의 태도가 변할 수 있으니, 일관된 자세로 수업에 임하는 것이 중요합니다. 수업에 집중하고 과제를 성실히 수행하면서 점진적으로 신뢰 관계를 구축해 나가세요. 이러한 태도는 장기적으로 안정적인 학습 환경을 조성하는 데 도움이 될 것입니다.
- 선배
자기 입장에서만 상대를 이해하는 선배예요. 정이 없고 배려심이 부족해 보일 수 있어요. 하지만 그들의 독특한 시각을 배우면서, 스스로 배려하는 법을 실천해보세요.
- 친구, 후배
함께 일하는 즐거움을 잘 모르는 타입이에요. 자기 할 일은 다 하지만, 다른 친구들 돕는 건 잘 못해요. 배려심이 없다는 소리를 들을 수 있어요. 그들의 장점을 인정하면서도, 팀워크의 중요성을 알려주는 게 좋아요.`,
  },

  BCBAC: {
    type: "BCBAC",
    category: "고집불통 어린아이 유형",
    desc: `'내 맘대로 장꾸 킹' 타입`,
    sub: `학교의 고집 대마왕!`,
    char: `여러분은 진정한 '내 맘대로 장꾸 킹' 타입이에요! 고집이 세고, 자기 감정대로 남들이 움직여주길 바라는 성향을 가진 스타일이죠.
다른 사람의 실수나 약점을 발견하면 끝까지 물고 늘어져서, 그것으로 사람을 괴롭히기도 해요. 에너지가 넘쳐서 호기심을 채우고 독단적으로 행동하는 모습 때문에 주변에서 여러분을 상대하기가 쉽지 않아요. '미운 일곱 살'이라는 말처럼 상대를 힘들게 하고 미움을 사게 하는 재주가 있어요. 그런데도, 남들이 미워한다고 해서 크게 신경 쓰지는 않아요.
자신은 상처를 받지 않지만, 남에게 상처를 주는 이런 타입은 결국 주변 사람들에게서 점점 멀어지게 될 위험이 있어요. 여러분, 주변 친구들이 다 떠나기 전에 남을 배려하는 법을 배워보는 게 어떨까요? 다른 사람의 감정을 조금만 신경 써보면, 더 많은 친구와 의미 있는 관계를 만들 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '내 맘대로 장꾸 킹' 스타일이지만, '공감 능력'과 '배려심'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 강한 의지와 에너지는 대단해요. 하지만 가끔은 한 발 물러서서 다른 사람의 감정도 생각해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 자기 성격이 다른 사람에게 상처 줄 수 있다는 걸 잘 모르고 살아요. 자기는 편하지만 다른 사람들이랑 어울리기 힘들어요. 학교생활을 잘하려면 다른 사람 감정에 반응해주는 연습이 필요해요:
-감정 일기 쓰기: 매일 자신의 행동이 다른 사람에게 어떤 영향을 줬는지 적어보세요.
- '칭찬 릴레이' 참여하기: 매일 한 명씩 친구들의 좋은 점을 찾아 칭찬해보세요.
-또래 상담 프로그램 참여하기: 다른 친구들의 고민을 들어주고 공감하는 연습을 해보세요.
-역할극 해보기: 친구들과 함께 다양한 상황에서 서로의 입장을 바꿔 연기해보세요.
- '친절 미션' 도전하기: 매일 한 가지씩 남을 위한 친절한 행동을 해보세요.
-팀 프로젝트 적극 참여하기: 다른 친구들과 협력하는 과정에서 배려심을 기르세요.
-명상 배우기: 마음의 평화를 찾고 자기 감정을 조절하는 능력을 키워보세요.`,
    human: `- 베프, 여자친구
이 친구와 오래 지내려면 진짜 냉정하게 상황을 봐야 해요. 배려가 부족한 이 타입이랑 계속 친하게 지내려면 많은 노력이 필요해요. 하지만 그들의 솔직함과 에너지를 긍정적으로 활용할 방법을 찾아보세요.
- 선생님
이 선생님과의 수업에서는 전문적이고 신중한 태도가 필요할 수 있습니다. 원활한 학습을 위해 수업에 집중하고 과제를 성실히 수행하는 것이 중요합니다. 실수를 최소화하고 꼼꼼하게 학습에 임하세요. 이러한 접근은 수업의 효과를 높이고 안정적인 학습 환경을 만드는 데 도움이 될 것입니다.
- 선배
학교에서 이런 선배랑 지내는 건 인내심이 필요해요. 학교생활에 각별히 신경 써서 실수하지 않도록 노력하세요. 하지만 그들의 강한 의지와 추진력을 배우는 기회로 삼아보세요.
- 친구, 후배
이 친구들에 대해서는 단호하게 평가하는 게 좋아요. 하지만 동시에 그들의 장점을 인정하고, 함께 성장할 수 있는 방법을 찾아보세요. 때로는 솔직한 피드백이 도움이 될 수 있어요.`,
  },

  BCBBA: {
    type: "BCBBA",
    category: "피해의식에 갇혀버린 유형",
    desc: `'자존감 충전 필요' 타입`,
    sub: `학교의 숨은 보석 찾기 챌린지!`,
    char: `여러분은 '자존감 충전 필요' 타입이에요! 조용하고 내성적인 성격으로, 자신의 감정을 잘 표현하지 못하고 다른 사람들에 대해 피해의식을 가지고 있어요.
자기만의 세계에 갇혀서 가족을 포함한 모든 사람들이 자신을 이해해주지 않는다고 믿어요. 그래서 가끔 삐뚤어진 행동을 보여주기도 해요.
다른 사람에게 의존하는 경향이 있고, 모든 사람 앞에서 열등감을 느껴요. 하지만 이런 모습을 숨기려고 하다가 들키면, 상대방 탓을 하며 책임을 전가해버려요.
그런데 막상 누군가가 자세히 따지고 들면 제대로 대응하지 못하고 혼자서 투덜거리곤 해요.
이런 타입은 스스로의 껍질을 깨고 나와서 다른 사람들과 어울리는 연습을 해야 해요. 다른 사람을 배려하는 법과 자신의 감정을 잘 표현하는 법을 배우지 않으면, 외롭게 지낼 수 있어요.
다른 사람에게 마음을 열고 다가가는 노력이 필요해요!`,
    result: `기억하세요! 이 타입은 '자존감 충전 필요' 스타일이지만, '자기 이해'와 '소통 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 섬세함과 깊은 생각은 큰 장점이에요. 조금씩 용기를 내어 다른 사람들에게 다가가보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 지금부터 자기 분석을 통해 장단점을 찾고, 다른 사람 눈에 비치는 자신의 모습을 파악하는 게 좋아요. 자존감을 높이고 단점을 보완하는 노력이 필요해요:
- '장점 찾기 일기' 쓰기: 매일 자신의 좋은 점 하나씩 적어보세요.
-교내 상담 프로그램 활용하기: 전문 상담 선생님과 대화하며 자신을 이해하는 시간을 가져보세요.
-동아리 활동 참여하기: 관심 있는 분야에서 다른 친구들과 어울리며 자신감을 키워보세요.
- '칭찬 릴레이' 시작하기: 친구들과 서로의 장점을 찾아 칭찬해주는 활동을 해보세요.
-발표 기회 늘리기: 수업 시간에 조금씩 발표 연습을 해보며 자신감을 키워보세요.
-멘토링 프로그램 참여하기: 선배나 선생님의 조언을 들으며 긍정적인 롤모델을 찾아보세요.
-‘도전 리스트' 만들기: 작은 목표부터 하나씩 도전하고 성취감을 느껴보세요.`,
    human: `- 베프, 여자친구
진지하게 대화하면서 그 친구의 마음을 들어주고 피해의식을 줄여주는 게 좋아요. 이해와 공감의 마음으로 접근하세요. 작은 성공과 장점을 자주 칭찬해주는 것도 도움이 돼요.
- 선생님
이 선생님은 세심하고 자신의 일에 대해 진지한 태도를 가지고 계십니다. 편안한 학습 분위기를 조성하고 선생님의 노력을 인정하는 것이 도움이 될 수 있습니다. 수업에 적극적으로 참여하고, 때때로 선생님의 지도에 대한 감사를 표현해보세요. 이러한 접근은 긍정적인 학습 환경을 만들고 더 효과적인 교육 경험으로 이어질 수 있습니다.
- 선배
열등감이 많은 선배와 지내려면 겸손한 태도를 보이는 게 좋아요. 물론 그래도 갈등이 생길 수 있지만, 계속 공감하는 자세를 유지하세요. 선배의 장점을 진심으로 인정하고 배우려는 자세를 보여주세요.
- 친구, 후배
자존감이 낮으면 자신감도 떨어져서 공부나 활동을 잘 못할 수 있어요. 작은 일에도 칭찬하고 격려해주세요. 함께 목표를 세우고 달성해나가는 것도 좋은 방법이에요.`,
  },

  BCBBB: {
    type: "BCBBB",
    category: "평범하지만 냉정한 유형",
    desc: `'쿨내 폭발 평범이' 타입`,
    sub: `학교의 얼음왕자/공주!`,
    char: `여러분은 '쿨내 폭발 평범이' 타입이에요! 겉으로 보기엔 완전 평범해 보이지만, 속은 언제나 차갑게 유지하는 스타일이죠.
다른 사람을 위해 시간, 돈, 정성을 들이는 일은 거의 없어요. 특별히 문제를 일으키거나 공부를 소홀히 하는 타입은 아니지만, 오래 알고 지내도 정이 들거나 함께 무언가를 하고 싶은 마음이 잘 안 생겨요.
인간관계에서도 감정의 변화가 거의 없고, 학교생활은 무난하게 잘하지만 좀 건조한 친구 관계를 보여요.
이런 타입은 혼자 하는 활동이나 개인 프로젝트에서 오히려 빛을 발할 수 있어요. 하지만 다른 사람에게 관심을 가지고 배려한다면, 더 편안한 학교생활과 가정생활을 할 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '쿨내 폭발 평범이' 스타일이지만, '공감 능력'과 '소통 스킬'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 냉철함과 합리성은 대단한 장점이에요. 하지만 가끔은 마음을 열고 다른 사람들과 따뜻하게 교류해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 합리적인 판단과 감정 조절을 적당히 잘하는 편이에요. 하지만 그 바탕에 차가움이 있어서 학교생활에는 좀 부족할 수 있어요. 혼자 하는 활동을 계획 중이 아니라면, 다른 사람에게 관심을 가지는 연습을 해보는 게 좋아요:
- '관심 가지기' 챌린지: 매일 한 명의 친구에게 관심을 가지고 대화를 나눠보세요.
-팀 프로젝트 적극 참여하기: 그룹 활동에서 다른 친구들의 의견을 듣고 협력하는 연습을 해보세요.
-감정 일기 쓰기: 매일 자신의 감정을 기록하며 감정 표현 능력을 키워보세요.
-동아리 활동 참여하기: 관심 있는 분야에서 다른 친구들과 교류하며 소통 능력을 기르세요.
- '칭찬 릴레이' 시작하기: 매일 한 명씩 친구들의 장점을 찾아 칭찬해보세요.
-봉사활동 참여하기: 다른 사람을 돕는 경험을 통해 공감 능력을 키워보세요.
-멘토링 프로그램 참여하기: 후배들을 도와주면서 책임감과 배려심을 기를 수 있어요.`,
    human: `- 베프, 여자친구
차가운 모습이 학교생활에서는 어느 정도 도움이 될 수 있지만, 친밀한 관계에서는 문제가 될 수 있어요. 하지만 본인은 잘 모를 수 있어요. 문제가 생기면 어떤 감정과 느낌을 받았는지 솔직하게 이야기하고, 함께 변화할 수 있는 방법을 찾아보세요.
- 선생님
이런 타입의 선생님은 감정에 얽매이지 않고 문제를 해결해요. 명확한 근거와 준비로 대응하는 게 좋아요. 수업 시간에 집중하고, 과제를 성실히 해내면서 신뢰를 쌓아보세요.
- 선배
상황을 차갑게 판단하는 타입이에요. 함께 시간을 보내며 문제를 해결하려고 하면 오히려 문제가 생길 수 있어요. 대신 논리적인 대화와 실력으로 인정받는 게 좋아요.
- 친구, 후배
인간관계에서는 좀 차가울 수 있지만, 정확성과 실력이 필요한 부분에서는 뛰어난 능력을 보일 수 있어요. 그들의 장점을 인정하고, 함께 프로젝트를 진행하면서 서로의 강점을 살려보세요.`,
  },

  BCBBC: {
    type: "BCBBC",
    category: "관계에 인색한 유형",
    desc: `'마이웨이 솔로 플레이어' 타입`,
    sub: `학교의 아싸 킹!`,
    char: `여러분은 '마이웨이 솔로 플레이어' 타입이에요! 다른 사람들에게 욕먹지 않을 정도로만 소통하는 스타일이죠.
누군가에게 기대하지도, 기대 받고 싶어하지도 않아요. 신경 쓸 일을 만들지 않는 이 타입은 인기스타가 되긴 어려워요. 하지만 본인도 그걸 알고 있고, 더 바라지도 않아요.
적당히 거리 두면서 학교생활 하는 이 친구들은 판단력이 좋아서 아무도 함부로 대하지 못해요. 근데 친해지고 싶어하는 사람도 없어요.
손해 볼까 봐 걱정하지 말고 사람들이랑 만나는 연습이 필요해요.`,
    result: `기억하세요! 이 타입은 '마이웨이 솔로 플레이어' 스타일이지만, '사회성'과 '협력 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독립성과 판단력은 대단한 장점이에요. 하지만 가끔은 용기를 내어 다른 친구들에게 다가가보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 앞으로 인간관계에서 조금 더 적극적인 모습을 보이는 게 좋아요.
혼자 하는 활동만 고집할 게 아니라면, 스스로 다른 친구들에게 다가가고 손해 보는 것을 감수하면서도 어울리는 방법을 배우는 게 필요해요:
- '친구 만들기' 챌린지: 매주 한 명의 새로운 친구와 대화를 나눠보세요.
-동아리 활동 참여하기: 관심 있는 분야에서 다른 친구들과 교류하며 소통 능력을 기르세요.
-팀 프로젝트 적극 참여하기: 그룹 활동에서 의견을 나누고 협력하는 연습을 해보세요.
- '랜덤 런치' 시도하기: 점심시간에 매번 다른 친구들과 함께 식사하며 대화를 나눠보세요.
-SNS 활동하기: 온라인에서 학교 친구들과 소통하며 관계를 넓혀가세요.
-봉사활동 참여하기: 다른 사람을 돕는 경험을 통해 사회성을 키워보세요.
- '감사 노트' 쓰기: 매일 한 명씩 고마운 친구나 선생님을 떠올리고 감사한 점을 적어보세요.`,
    human: `- 베프, 여자친구
평범하게 다른 친구들이랑 어울리라고 권해주세요. 잠깐의 불편함 때문에 좋은 친구를 놓칠 수 있어요. 천천히, 꾸준히 사람들과 어울리는 연습을 할 수 있게 도와주세요.
- 선생님
이 선생님은 실용적이고 효율적인 접근을 선호하실 수 있습니다. 선생님과 소통할 때는 상호 이익이 되는 점을 강조하는 것이 효과적일 수 있습니다. 수업에 적극적으로 참여하고 과제를 성실히 완수하여 신뢰 관계를 구축해 나가세요. 이러한 태도는 선생님과의 긍정적인 상호작용을 촉진하고 학습 경험을 향상시킬 수 있습니다.
- 선배
끝까지 후배를 도와주는 타입은 아니에요. 자기에게 손해가 된다고 생각하면 쉽게 등을 돌릴 수 있어요. 하지만 그들의 경험과 지식을 배우려는 자세를 보이면, 도움을 받을 수 있을 거예요.
- 친구, 후배
함께 공부하거나 놀면서 즐거움을 찾기가 쉽지 않아요. 인색한 이 친구에게 너무 많은 걸 바라지 마세요. 대신 그들의 독립적인 성격을 존중하고, 함께할 수 있는 작은 활동부터 시작해보세요.`,
  },

  BCBCA: {
    type: "BCBCA",
    category: "고독을 즐기는 유형",
    desc: `'혼자놀기 장인' 타입`,
    sub: `학교의 마이페이스 고수!`,
    char: `여러분은 '혼자놀기 장인' 타입이에요! 친구들이랑 어울리는 것보다 뭐든 혼자 조용히 하는 걸 좋아하는 스타일이죠.
다른 사람 도움 받는 것도, 다른 사람 위해 희생하는 것도 별로 안 좋아해요. 혼자서도 다 잘할 수 있다고 생각하죠. 하지만 학교생활 하려면 어쩔 수 없이 관계를 맺어야 하니까, 꼭 필요한 관계만 유지하고 깊이 관여하지 않으려고 해요.
친구관계도 얕고 넓은 것보다는 소수의 깊은 우정을 선호해요. "진정한 친구 한 명만 있어도 인생 성공"이라는 말처럼, 그런 면에서는 성공한 거예요. 근데 가끔은 여러 친구들이랑 활발하게 지내는 것도 재밌을 수 있다는 걸 기억하세요. 에너지 넘치는 생활도 한번 도전해보는 건 어때요?`,
    result: `기억하세요! 이 타입은 '혼자놀기 장인' 스타일이지만, '사회성'과 '협력 능력'을 조금씩 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독립성과 집중력은 대단한 장점이에요. 하지만 가끔은 친구들과 어울려보는 것도 좋아요. 혼자만의 시간과 함께하는 시간의 밸런스를 잘 맞추면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 공부나 과제에 어려움을 느끼는 게 아니라 친구관계에서 어려움을 느낄 수 있어요. 많은 친구들이랑 어울리다 보면 스트레스 받을 수 있으니, 혼자서 집중할 수 있는 활동을 찾아보는 게 좋아요:
- '독서 클럽 가입하기': 책 읽고 혼자 생각 정리하는 시간을 가질 수 있어요.
- '개인 프로젝트 도전하기': 과학 탐구나 예술 작품 등 혼자서 깊이 있게 탐구할 수 있는 프로젝트를 해보세요.
- '온라인 강의 활용하기': 혼자 공부하는 걸 좋아한다면, 관심 있는 분야의 온라인 강의를 들어보세요.
- '명상 배우기': 조용히 자신을 돌아보는 시간을 가져보세요.
- '개인 블로그 운영하기': 자신의 생각을 글로 정리하고 표현하는 연습을 해보세요.
- '1:1 멘토링 받기': 소수의 깊은 관계를 선호한다면, 선생님이나 선배와 1:1 멘토링을 받아보세요.
- '자율학습 시간 활용하기': 혼자 집중해서 공부할 수 있는 시간을 잘 활용해보세요.`,
    human: `- 베프, 여자친구
이 친구는 시끌벅적한 곳에서 데이트하는 걸 어려워할 수 있어요. 둘만의 조용한 데이트를 즐겨보는 게 어때요? 영화 보기, 조용한 카페에서 대화하기 등이 좋을 거예요.
- 선생님
이 선생님은 적절한 거리감을 유지하는 것을 선호하실 수 있습니다. 필요한 만큼의 소통을 하되, 편안한 분위기를 유지하는 것이 좋습니다. 수업에 집중하고 과제를 성실히 수행하여 학업에 대한 열의를 보여주세요. 이러한 접근은 선생님과의 원활한 관계 유지와 효과적인 학습에 도움이 될 것입니다.
- 선배
이 타입의 선배는 학교에서 형식적인 대화나 행동을 별로 안 좋아할 거예요. 그런 점을 고려해서 너무 가볍게 대하지 말고, 선배의 성향을 존중해주세요. 진지한 대화나 조용한 활동을 함께 하는 게 좋아요.
- 친구, 후배
사람들과의 관계에서 에너지가 좀 부족한 타입이에요. 긍정적인 관계를 유지하면서 앞으로의 우정을 이어갈 수 있도록 도와주세요. 그들의 개인 시간을 존중하면서, 가끔씩 함께할 수 있는 조용한 활동을 제안해보세요.`,
  },

  BCBCB: {
    type: "BCBCB",
    category: "삶의 즐거움을 상실한 유형",
    desc: `'숨은 꿈나무' 타입`,
    sub: `학교의 잠자는 열정 왕자/공주!`,
    char: `여러분은 '숨은 꿈나무' 타입이에요! 부정적인 생각이 좀 많아서 인생의 재미를 아직 못 찾고 있는 상태예요.
큰 꿈이나 목표는 없지만, 힘든 상황에서도 극단적으로 포기하지 않고 버틸 수 있는 강점이 있어요.
하지만 일상에서 도전보다는 포기하고, 해결보다는 피하려는 경향이 있어서 편한 생활만 하고 있을 수 있어요. 학교나 가정에서 더 성장하려는 마음보다 지금 상태에 만족하고 매일 똑같이 반복되고 있진 않나요?
자신을 사랑하는 마음과 다른 사람을 배려하는 마음을 좀 더 가지려고 노력하고, 뭔가를 하고 싶은 의욕을 갖고 생활하면 많은 즐거움을 발견할 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '숨은 꿈나무' 스타일이지만, '긍정적 마인드'와 '도전 정신'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 잠재력과 끈기는 대단해요. 하지만 가끔은 용기를 내어 새로운 것에 도전해보세요. 자신을 믿고 한 걸음씩 나아가면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `생각보다 여러분은 할 수 있는 게 많고, 뭐든 잘 할 수 있는 사람이에요.
시도해보기 전부터 "어차피 안 돼..."라고 생각하지 말고 도전해보세요.
긍정적으로 생각해보면 분명 이루고 싶은 목표와 꿈이 있을 거예요. 스스로에게 좀 더 자유를 주고 즐거워할 수 있는 활동을 찾아보세요:
- '버킷리스트' 만들기: 학창 시절에 꼭 해보고 싶은 것들을 적어보고 하나씩 도전해보세요.
-새로운 동아리 가입하기: 관심 있던 분야의 동아리에 가입해 새로운 경험을 해보세요.
- '감사 일기' 쓰기: 매일 좋았던 일 3가지를 적어보며 긍정적인 마인드를 키워보세요.
-봉사활동 참여하기: 다른 사람을 돕는 경험을 통해 삶의 의미를 찾아보세요.
-진로 탐색 프로그램 참여하기: 다양한 직업을 알아보고 자신의 숨은 재능을 발견해보세요.
- '도전 한 달' 프로젝트: 매달 새로운 취미나 기술에 도전해보세요.
-멘토 찾기: 존경하는 선배나 선생님께 조언을 구하고 목표 설정에 도움을 받아보세요.`,
    human: `- 베프, 여자친구
이 친구는 안정적이고 편안한 관계를 원할 거예요. 인생에 즐거운 일이 많다는 걸 알려주고 함께 즐길 수 있는 것들을 찾아보세요. 밝은 에너지를 발산해서 그 친구에게도 긍정적인 영향을 주는 게 중요해요.
- 선생님
이 선생님은 안정적이고 예측 가능한 환경을 선호하실 수 있습니다. 편안하고 친근한 분위기를 유지하는 것이 도움이 될 수 있어요. 수업에 적극적으로 참여하면서, 선생님의 관심사에 대해 관심을 보이고 대화를 나눠보세요. 이러한 접근은 선생님과의 원활한 소통과 효과적인 학습 경험으로 이어질 수 있습니다.
- 선배
공부나 학교 활동에 대한 의욕과 책임감이 좀 부족한 타입이에요. 이런 선배와 함께 있을 때는 여러분이 좀 더 노력해야 일이 잘 진행될 수 있어요. 하지만 그들의 경험을 존중하고 배우려는 자세를 보이면 좋은 관계를 유지할 수 있어요.
- 친구, 후배
에너지 넘치고 열정적인 친구들과 함께 공부하거나 활동하면 좋은 시너지를 낼 수 있어요. 그들의 긍정적인 에너지를 받아들이고, 함께 새로운 도전을 해보세요.`,
  },

  BCBCC: {
    type: "BCBCC",
    category: "차가운 반항아 유형",
    desc: `'츤데레 반항아' 타입`,
    sub: `학교의 숨은 매력 폭탄!`,
    char: `여러분은 '츤데레 반항아' 타입이에요! 동정심이나 감정 변화가 적고 차가운 마음의 소유자예요. 근데 그 속에 반항아 기질도 숨어 있어요.
인간관계에서 어느 정도 마음을 열고 논리적이고 합리적인 생각을 할 줄 알아서 첫인상은 나쁘지 않아요.
하지만 더 깊은 관계가 되면 사람들이 여러분을 감당하기 어려운 사람이라고 생각할 수 있어요. 의리, 배려, 인정을 중요하게 여기는 연습이 필요해요. 세상은 혼자 살 수 없으니까 서로 돕고 도움받으며 어울려 살아가야 해요. 따뜻한 마음과 애정 표현하는 연습을 해보는 건 어떨까요?`,
    result: `기억하세요! 이 타입은 '츤데레 반항아' 스타일이지만, '공감 능력'과 '소통 스킬'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 논리적 사고와 솔직함은 대단한 장점이에요. 하지만 가끔은 마음을 열고 다른 사람들의 감정도 생각해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입이 아직 학교생활에 적응을 못했다면 친구들과의 관계에 조금 더 신경 써야 할 것 같아요. 학교는 밝고 긍정적이며 다른 사람을 배려하고 예의 있는 학생을 좋아해요. 반항아적인 모습 때문에 학교생활에 어려움을 겪고 있진 않은지 생각해봐야 해요.
- '친절 미션' 도전하기: 매일 한 가지씩 다른 사람을 위한 친절한 행동을 해보세요.
-동아리 활동 참여하기: 관심 있는 분야에서 다른 친구들과 협력하는 법을 배워보세요.
-감정 일기 쓰기: 매일 자신의 감정을 기록하며 감정 표현 능력을 키워보세요.
-멘토링 프로그램 참여하기: 선생님이나 선배의 조언을 들으며 사회성을 기르세요.
- '칭찬 릴레이' 시작하기: 매일 한 명씩 친구들의 장점을 찾아 칭찬해보세요.
-팀 프로젝트 적극 참여하기: 그룹 활동에서 협력하는 법을 배워보세요.
-봉사활동 참여하기: 다른 사람을 돕는 경험을 통해 따뜻한 마음을 키워보세요.`,
    human: `- 베프, 여자친구
이 친구는 제멋대로는 아니지만, 주위 신경도 별로 안 써요. 상대방 배려하는 게 습관이 안 돼서 여러분이 상처받을 수 있어요. 그런 부분은 감안하고 대하는 게 좋아요. 하지만 그들의 솔직함과 논리적인 면을 장점으로 볼 수 있어요.
- 선생님
이 선생님은 감정 표현이 절제되어 있고 전략적 사고를 하실 수 있습니다. 신중하고 객관적인 태도로 접근하는 것이 좋습니다. 수업에 성실히 참여하고 예의 바른 태도를 유지하세요. 이러한 접근은 선생님과의 신뢰 관계를 구축하고 효과적인 학습 환경을 조성하는 데 도움이 될 것입니다.
- 선배
후배들에 대한 관대함이나 배려가 부족한 타입이에요. 여러분이 스스로 잘 해내는 수밖에 없어요. 잘한 일에 대해 칭찬이 없어도 낙담하지 마세요. 대신 그들의 경험과 지식을 배우려는 자세를 보이면 좋은 관계를 유지할 수 있어요.
- 친구, 후배
많은 사람들과 어울리는 활동보다는 혼자서 할 수 있는 일에 집중하게 하는 게 좋아요. 단순한 일이라면 꽤 잘할 수 있는 타입이에요. 그들의 강점을 인정하고, 함께할 수 있는 작은 활동부터 시작해보세요.`,
  },

  BCCAA: {
    type: "BCCAA",
    category: "외로운 야생마 유형",
    desc: `'학교의 유니콘' 타입`,
    sub: `자유로운 영혼의 인기스타 지망생!`,
    char: `여러분은 '학교의 유니콘' 타입이에요! 세상의 중심에 서 있다고 생각하는 스타일이죠.자기가 하고 싶은 일, 좋아하는 일, 잘하는 일에 대한 집중력이 끝내줘요.하지만 뉴스에서 정치, 경제, 사회 이슈가 나와도 자기랑 상관없으면 전혀 관심 없어요.
특징은 다른 사람에 대해선 무관심하지만, 다른 사람들이 자기한테 관심 가져주고 칭찬해주길 바라는 마음이 강해요. 그래서 주변 기대에 어긋나지 않으려고 노력하고 칭찬받으려고 열심히 해요.
이런 타입은 예술가들에게서 많이 볼 수 있어요. 세상 사람들에게 보여주기 위해 개인 작품을 뛰어나게 만들 수 있는 재능이 있어요. 하지만 다른 사람에 대한 관심과 배려, 판단력을 더 키우지 않으면 외로운 천재처럼 살 수 있으니 그런 부분들을 고치려고 노력하는 게 좋아요.`,
    result: `기억하세요! 이 타입은 '학교의 유니콘' 스타일이지만, '공감 능력'과 '팀워크 스킬'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 창의력과 열정은 대단해요. 하지만 가끔은 주변을 둘러보고 다른 사람들의 생각도 들어보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 자기가 원하는 걸 강하게 추구하니까 즐겁게 흥얼거리면서 할 수 있는 일에 잘 맞아요. 승부욕도 있어서 경쟁에서 뒤처지지 않을 가능성이 커요. 취미나 게임처럼 관심 있게 할 수 있는 일을 찾아보세요. 하지만 학교생활은 혼자 하는 게 아니니까, 발표나 팀 프로젝트에서 다른 사람을 배려하는 마음을 갖도록 노력해야 해요:
- '관심 가지기 챌린지': 매일 한 명의 친구에게 관심을 가지고 대화를 나눠보세요.
- '학급 임원 도전하기': 반 전체를 위해 일하면서 리더십과 배려심을 키워보세요.
- '동아리 활동 참여하기': 관심 있는 분야에서 다른 친구들과 협력하는 법을 배워보세요.
- '칭찬 릴레이 시작하기': 매일 한 명씩 친구들의 장점을 찾아 칭찬해보세요.
- '멘토링 프로그램 참여하기': 후배들을 도와주면서 책임감과 배려심을 기를 수 있어요.
- '토론 대회 참가하기' : 다양한 의견을 듣고 존중하는 법을 배워보세요.
- '1일 1선행 실천하기': 매일 작은 선행을 하면서 타인을 돕는 기쁨을 느껴보세요.`,
    human: `- 베프, 여자친구
너무 맞춰주면 제멋대로 행동할 수 있고, 너무 강하게 누르면 실망하고 도망갈 수 있어요. 적당한 선을 찾아 관계를 유지하는 게 좋아요. 어린아이처럼 자유롭고 밝은 성격이지만, 상대를 배려하는 마음은 약할 수 있으니 참고하세요.
- 선생님
선생님의 기분이나 상황에 따라 약속이 자주 바뀔 수 있고, 그로 인해 여러분이 실수하면 신뢰를 잃을 수 있으니 조심해야 해요. 수업 시간에 집중하고, 과제를 성실히 해내면서 신뢰를 쌓아보세요.
- 선배
공부나 동아리 활동에서 여러분 의견이 맞고 선배가 틀렸더라도 감정적으로 대응하지 말고 논리적, 합리적으로 부드럽게 이야기하면서도 강하게 의견을 전달할 필요가 있어요. 하지만 사소한 일은 될 수 있으면 양보하는 게 좋아요.
- 친구, 후배
상대방이 너무 제멋대로 행동할 때는 정확히 지적해줘야 해요. 옳고 그름을 이론적으로 알려주고, 필요하면 좀 과하게 조언해줘야 변할 수 있어요. 하지만 그들의 창의성과 열정을 인정하고 격려해주는 것도 잊지 마세요.`,
  },

  BCCAB: {
    type: "BCCAB",
    category: "전형적인 어린아이 유형",
    desc: `'영원한 피터팬' 타입`,
    sub: `학교의 핵인싸 지망생!`,
    char: `여러분은 '영원한 피터팬' 타입이에요! 주변 사람들에게 특별한 정은 없지만, 여기저기 돌아다니며 이것저것 하는 천방지축 스타일이죠.
나쁜 마음은 전혀 없지만 실수로 문제를 일으킬 수 있어요. 자기 즐거움을 너무 좋아해서 게임이나 SNS, 연애 등에 빠지면 헤어 나오기 힘들 수 있으니 조심해야 해요.
주변 사람들은 여러분을 보고 "얘 좀 철 들어야 하는데..."라고 생각할 수도 있어요. 한 곳에 오래 머물기 싫어해서 여기저기 옮겨 다니니까 뭔가를 깊이 있게 배우기 어려울 수 있어요.
영원히 어린애로 살 순 없으니 스스로 깨닫고 변하지 않으면 나중에 안정된 삶을 살기 어려울 수 있어요. 다른 사람을 배려하는 마음과 이성적인 판단력을 키우면 열정 넘치는 최고의 인싸가 될 수 있으니 노력해보세요!`,
    result: `기억하세요! 이 타입은 '영원한 피터팬' 스타일이지만, '책임감'과 '끈기'를 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 밝은 에너지와 창의력은 대단해요. 하지만 가끔은 진지해지고 꾸준히 노력하는 습관을 길러보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 학교생활 시작하는 건 어렵지 않지만, 그 후가 더 문제가 될 수 있어요.
밝고 열정적인 모습 때문에 선생님들이 처음엔 좋아하실 거예요. 그러니 선생님들의 기대에 부응하도록 노력하고, 개인적인 욕구를 조금만 자제하면 이상적인 학교생활을 할 수 있을 거예요:
- '집중력 키우기 챌린지': 매일 10분씩 집중해서 공부하는 시간을 가져보세요.
- '동아리 활동 꾸준히 하기': 관심 있는 활동을 선택해 한 학기 동안 꾸준히 참여해보세요.
- '시간 관리 앱 사용하기': 하루 일과를 계획하고 실천해보세요.
- '학급 임원 도전하기': 책임감을 키우는 좋은 기회가 될 거예요.
- '작은 목표 세우기': 매주 작은 목표를 세우고 달성해보세요.
- '멘토 찾기': 존경하는 선배나 선생님께 조언을 구해보세요.
- '감사 일기 쓰기': 매일 감사한 일 3가지를 적어보며 긍정적인 마인드를 키워보세요.`,
    human: `- 베프, 여자친구
처음엔 엄청 매력적으로 보일 수 있어요. 하지만 진지한 관계로 발전하기까진 어려움이 있을 수 있어요. 때론 여러분을 무시하고 제멋대로 할 수 있으니 인내심을 갖고 부모님처럼 이해해주고 이끌어줘야 해요.
- 선생님
진지하게 수업이나 과제에 대해 상의하기 좀 어려운 선생님이에요. 이런 선생님과 지내려면 많은 인내심이 필요할 거예요. 수업에 적극적으로 참여하고, 과제를 성실히 해내면서 신뢰를 쌓아보세요.
- 선배
한 자리에 오래 있는 타입이 아니라서 학생회장 같은 자리에 올라갈 가능성이 적어요. 하지만 그들의 창의적인 아이디어와 에너지를 배울 수 있어요.
- 친구, 후배
이런 타입이 친구나 후배라면 여러분이 좀 고민이 깊어질 수 있어요. 조금이라도 어렵거나 힘든 일을 주면 해결하기보다는 포기하려고 할 수 있어요. 쉬운 일부터 단계적으로 가르쳐주고, 많이 격려하고 칭찬해주는 게 좋아요.`,
  },

  BCCAC: {
    type: "BCCAC",
    category: "질주하는 카레이서 유형",
    desc: `'무한질주 롤러코스터' 타입`,
    sub: `학교의 핵인싸 극한직업!`,
    char: `여러분은 '무한질주 롤러코스터' 타입이에요! 완전 천진난만 장난꾸러기 같은 스타일이죠.
어린 시절 그대로 크다 보니, 다른 사람 배려하는 거나 깊이 생각하는 능력이 좀 부족해요. 하고 싶은 대로 행동하는 스타일이죠. 재미있는 걸 위해서라면 열정 폭발하는 타입이라 잘못된 재미에 빠지지 않도록 조심해야 해요. 게임이나 SNS, 연애에 빠지면 헤어 나오기 힘들어요.
브레이크 고장 난 자동차처럼 절벽으로 달려가는 걸 알면서도 멈추지 못해요. 원하는 대로 안 되면 위험한 선택을 할 수도 있으니 항상 주의해야 해요. 이런 성격 고치지 않으면 나중에 어른스러운 삶 살기 어려울 수 있으니, 깊이 생각하는 능력 키우고, 친구들과의 의리나 정을 이해하려 노력해야 해요. 주변 사람들 배려하는 마음도 꼭 필요해요!`,
    result: `기억하세요! 이 타입은 '무한질주 롤러코스터' 스타일이지만, '자기 통제력'과 '책임감'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 열정과 에너지는 대단해요. 하지만 가끔은 멈춰서 생각하고, 주변 사람들을 배려하는 습관을 길러보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 사실 공부에는 별로 관심 없을 수 있어요. 인생을 즐기는 게 목표인 사람일 확률이 높아요. 딱딱한 수업보다는 놀이처럼 즐기면서 배우는 활동을 찾아보면 좋겠어요:
- '재미있는 공부법' 찾기: 게임이나 퀴즈 형식으로 공부해보세요.
-체육대회나 축제 적극 참여하기: 에너지를 긍정적으로 발산할 수 있어요.
- '1일 1목표' 세우기: 매일 작은 목표를 세우고 달성해보세요.
-동아리 활동하기: 좋아하는 활동을 통해 책임감을 배워보세요.
- '마인드맵' 활용하기: 공부할 때 그림이나 도형을 활용해 재미있게 정리해보세요.
- '학습 버디' 만들기: 친구와 함께 공부하면서 서로 도와주세요.
- '현장 학습' 참여하기: 직접 체험하면서 배우는 기회를 잡아보세요.`,
    human: `- 베프, 여자친구
솔직히 말하면, 이런 타입은 진지한 관계로 발전시키기 어려워요. 이미 친한 사이라면, 부모님이 아이를 올바르게 키우듯이 끊임없는 인내와 관심으로 대해줘야 해요. 그 친구의 재미 추구에 같이 빠지지 않도록 조심하세요.
- 선생님
이 선생님의 수업 방식은 전통적인 학습 방법과 다를 수 있습니다. 때로는 이해하기 어려운 상황이 생길 수 있으니 주의 깊게 수업에 임하세요. 하지만 선생님의 독특한 수업 진행 방식에서 배울 점을 찾아보세요. 특히 수업을 흥미롭게 만드는 기술에 주목해보는 것도 좋습니다. 이러한 접근은 새로운 시각에서 학습 경험을 얻는 데 도움이 될 수 있습니다.
- 선배
이런 타입이 학생회장 같은 자리에 있을 가능성은 매우 낮아요. 만약 그렇다면, 정확한 활동 지시나 여러분에 대한 배려는 기대하지 않는 게 좋아요. 대신 그들의 창의적인 아이디어를 배워보세요.
- 친구, 후배
이런 타입의 친구나 후배와는 원활하게 팀 프로젝트를 하기 어려울 수 있어요. 여러분 스스로의 능력으로 대부분의 일을 처리해야 할 가능성이 높아요. 하지만 그들의 긍정적인 에너지를 활용할 방법을 찾아보세요.`,
  },

  BCCBA: {
    type: "BCCBA",
    category: "도움을 받기만 하려는 유형",
    desc: `'받기만 하는 인플루언서' 타입`,
    sub: `이기적인 관종!`,
    char: `여러분은 '받기만 하는 인플루언서' 타입이에요! 친구 관계에서 본인은 절대 남에게 베풀거나 도와주지 않지만, 반대로 다른 사람들은 자기한테 잘해주길 바라는 스타일이에요.
보통 사람들보다 차가운 마음을 가지고 있어서 주변 친구들의 고민이나 어려움에 전혀 관심이 없어요. 그런데 웃긴 건, 자기는 관심 없으면서 다른 사람에게는 의지하려고 해요. 도움 받고 싶어 하고 뭔가 얻으려고 기대하면서 겉으로만 배려하는 척, 참는 척 할 때가 많아요. 친구들에게 잘 보여서 쉽게 살아보려는 마음이 강한 거죠.
이성적으로 판단하고 논리적으로 생각하는 게 어려울 수 있어서 그런 부분을 키우려고 노력해야 해요. 따뜻한 마음을 가지고 친구들과 진짜 우정을 쌓는 게 인생의 큰 전환점이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '받기만 하는 인플루언서' 스타일이지만, '배려심'과 '논리적 사고력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 관심받고 싶어 하는 마음은 이해해요. 하지만 가끔은 다른 사람의 입장도 생각해보고, 베푸는 습관을 길러보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `면담이나 발표 때도 다른 사람을 배려하지 않는 모습이나, 판단력이 약한 모습이 드러날 수 있어 안 좋게 보일 수 있어요. 이 두 가지를 개선하려고 노력하면 학교생활을 더 잘할 수 있을 거예요:
- '감사 일기 쓰기': 매일 다른 사람의 도움을 받은 일을 적어보세요.
- '봉사활동 참여하기': 남을 돕는 경험을 통해 배려심을 키워보세요.
- '토론 동아리 가입하기': 논리적 사고와 판단력을 기를 수 있어요.
- '역지사지 연습하기': 매일 한 번씩 다른 사람의 입장에서 생각해보세요.
- '팀 프로젝트 적극 참여하기': 협력의 중요성을 배울 수 있어요.
- '칭찬 릴레이 시작하기': 매일 한 명씩 친구들의 장점을 찾아 칭찬해보세요.
- '멘토링 프로그램 참여하기': 다른 사람을 도와주면서 책임감을 기를 수 있어요.`,
    human: `- 베프, 여자친구
오랫동안 절친으로 지내기에는 좀 힘든 친구일 수 있어요. 여러분은 관심 받지 못하고, 상대에게는 계속 관심과 응원을 해줘야 해서 외로울 수 있어요. 하지만 그들의 장점을 찾고, 조금씩 변화할 수 있도록 도와주세요.
- 선생님
이 선생님과의 수업에서는 자기주도적 학습이 특히 중요할 수 있습니다. 선생님의 개별적 지원에 과도한 기대를 하기보다는, 수업 내용에 집중하고 스스로 학습하는 능력을 키우는 데 초점을 맞추세요. 이는 장기적으로 여러분의 학업 능력 향상에 도움이 될 것입니다. 수업을 통해 독립적인 학습 태도를 기르는 기회로 삼아보세요.
- 선배
자기 이익과 자리를 지키기 위해 여러분을 이용할 수도 있는 선배예요. 학교 활동에 관한 관계만 유지하는 게 좋아요. 하지만 그들의 경험을 배우려는 자세는 유지하세요.
- 친구, 후배
같은 반 친구로는 그다지 좋은 타입은 아니에요. 어려운 과제가 생기면 해결하려고 노력하기보다는 다른 친구들에게 모든 걸 떠넘기고 도망가려고 할 수 있어요. 그들의 장점을 인정하면서도, 공정한 역할 분담을 요구하세요.`,
  },

  BCCBB: {
    type: "BCCBB",
    category: "우유부단하고 무심한 유형",
    desc: `'고민 대마왕' 타입`,
    sub: `학교의 슬로우 모션 주인공!`,
    char: `여러분은 '고민 대마왕' 타입이에요! 일반적인 상식이나 감정, 배려 면에서는 문제없지만, 다른 친구들보다 마음이 좀 차갑고 판단력이 살짝 부족해요.
친구 관계에서는 꼭 의리와 정이 필요하고, 학교생활을 잘하려면 빠른 대응력과 정확한 과제 처리 능력이 필요해요. 이 타입은 기본기는 있지만 이런 부분들이 부족해서 실력이 없어 보일 수 있어요.
조용하게 평범한 삶을 살고 싶다면 지금도 괜찮지만, 학교에서 인정받고 싶다면 부족한 부분을 강화하는 노력이 필요해요. 의리, 정, 이성적 판단, 지적 능력 등을 조금만 더 높이면 완전 다른 모습이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '고민 대마왕' 스타일이지만, '결단력'과 '감정 표현 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 차분함과 기본기는 큰 장점이에요. 하지만 가끔은 빠르게 결정하고, 감정을 잘 표현하는 연습을 해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `위에서 말했듯이 이 타입은 학교생활에 큰 문제는 없지만 능력을 인정받기는 좀 어려울 수 있어요. 발표나 면담 때 더 논리적이고 이성적인 모습을 보여주세요. 또 다른 사람을 배려하고 책임감 있는 모습을 보여주는 것도 중요해요. 학교생활을 더 잘하려면 이런 노력을 해보세요:
- '결정력 키우기' 챌린지: 매일 작은 결정을 빠르게 내려보세요.
-토론 동아리 참여하기: 논리적 사고와 표현력을 기를 수 있어요.
- '감정 일기' 쓰기: 매일 자신의 감정을 표현하는 연습을 해보세요.
-팀 프로젝트 리더 맡아보기: 책임감과 리더십을 키울 수 있어요.
- '시간 관리' 앱 사용하기: 효율적인 과제 처리 능력을 기를 수 있어요.
-멘토링 프로그램 참여하기: 선배나 선생님의 조언을 받아 부족한 점을 개선해보세요.
- '친구 도와주기' 미션: 매주 한 명의 친구를 도와주며 배려심을 키워보세요.`,
    human: `- 베프, 여자친구
이 친구는 배려나 감정 표현이 서툴러서 여러분이 마음에 들지 않아도 관계를 유지할 수 있어요. 평화로운 관계를 위해서는 여러분의 지속적인 노력이 필요해요. 하지만 그들의 진심을 이해하려 노력하고, 천천히 감정 표현하는 법을 알려주세요.
- 선생님
이 선생님의 수업 방식은 전통적이고 안정적일 수 있습니다. 획기적인 변화보다는 기본에 충실한 학습이 이뤄질 것 같아요. 수업 내용을 꼼꼼히 이해하고, 자기주도적 학습 능력을 키우는 데 집중해보세요. 이러한 접근은 여러분의 학업 능력을 전반적으로 향상시키는 데 도움이 될 수 있습니다.
- 선배
사실 이런 타입의 선배는 많지 않을 거예요. 보통 평범한 학생으로 지낼 가능성이 높거든요. 그래도 이런 선배를 만났다면, 여러분이 똑똑한 편이라면 좀 답답할 수 있어요. 그들의 장점을 인정하면서도, 여러분의 아이디어를 잘 설명해보세요.
- 친구, 후배
이 타입은 단순한 일이 잘 맞아요. 복잡하고 어려운 과제를 줬을 때는 어떻게 해야 할지 몰라 당황할 수 있어요. 친구 관계도 어려워할 수 있으니, 천천히 다가가 편안한 분위기를 만들어주세요.`,
  },

  BCCBC: {
    type: "BCCBC",
    category: "문제 존재조차 모르는 유형",
    desc: `'타임폭탄 잠재러' 타입`,
    sub: `학교의 예측불가 서프라이즈 메이커!`,
    char: `여러분은 '타임폭탄 잠재러' 타입이에요! 큰 문제를 일으킬 만큼 에너지를 발산하지는 않지만, 생각지도 못한 작은 일이 갑자기 큰 문제가 될 수 있어요.
기본적으로 마음이 얼음처럼 차갑고, 반항심도 있고, 고집도 세고, 깊이 생각하는 능력이 좀 부족해요. 이런 친구들은 항상 작은 불안 속에 살고 있을 수 있어요. 다른 친구들에겐 별거 아닌 일이 이 타입에겐 엄청 크게 느껴져서 감당할 수 없는 상황이 될 수도 있어요. 공부나 친구 관계 등 모든 면에서 어려움을 겪을 수 있어요. 특히 돈 관련된 일은 되도록 피하는 게 좋아요.
인생을 더 풍성하고 조화롭게 살려면 친구들을 배려하는 마음, 논리적으로 판단하는 능력, 그리고 따뜻한 마음을 기르는 게 필요해요.`,
    result: `기억하세요! 이 타입은 '타임폭탄 잠재러' 스타일이지만, '자기 이해'와 '감정 조절 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 잠재력은 무한해요. 지금은 어려움을 겪고 있지만, 꾸준히 노력하면 예측 불가능한 매력을 가진 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `학교생활은 여러 친구들과 어울려 지내는 거예요. 여러분은 그런 친구 관계에서 어려움을 겪을 수 있어요. 남을 배려하는 마음도 부족하고, 공부나 과제 처리 능력도 부족할 수 있어요. 이런 부분들을 먼저 바꾸는 게 급해요. 발표나 면담 때 아무리 숨기려 해도 이런 단점들이 충분히 드러날 수 있거든요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '감정 일기' 쓰기: 매일 자신의 감정을 표현하는 연습을 해보세요.
-명상 배우기: 마음의 평화를 찾고 불안감을 줄이는 데 도움이 돼요.
- '친구 도와주기' 미션: 매주 한 명의 친구를 도와주며 배려심을 키워보세요.
-토론 동아리 참여하기: 논리적 사고와 의사소통 능력을 기를 수 있어요.
- '시간 관리' 앱 사용하기: 과제를 계획적으로 처리하는 습관을 들여보세요.
-상담 선생님과 정기적으로 대화하기: 자신의 감정과 행동을 이해하는 데 도움을 받을 수 있어요.
- '작은 성공' 노트 쓰기: 매일 작은 성취를 기록하며 자신감을 키워보세요.`,
    human: `- 베프, 여자친구
평생 친구로 지내기엔 좀 어려운 친구예요. 하지만 그들의 불안한 마음을 이해하고, 천천히 신뢰를 쌓아가는 노력을 해보세요.
- 선생님
이 선생님의 수업 스타일이 여러분의 학습 방식과 잘 맞지 않을 수 있습니다. 이런 경우 자기주도적 학습 능력을 키우는 것이 중요합니다. 수업 내용을 바탕으로 스스로 공부하는 습관을 기르세요. 또한, 다양한 관점을 얻기 위해 다른 선생님들의 조언도 구해보는 것이 도움이 될 수 있습니다. 이러한 다각적 접근은 여러분의 학습 경험을 풍부하게 만들 것입니다.
- 선배
솔직히 이런 타입은 학교에서 높은 자리에 오르기 어려울 수 있어요. 하지만 그들의 독특한 시각을 배우면서, 여러분이 더 성장할 수 있는 기회로 삼으세요.
- 친구, 후배
이 타입의 친구나 후배와 같은 조가 되면 여러분이 많은 걸 희생하고 주도해야 할 거예요. 이 친구들에게 많은 걸 맡기긴 어려우니, 작은 일부터 천천히 맡겨보세요.`,
  },

  BCCCA: {
    type: "BCCCA",
    category: "항상 눈치를 보며 버티는 유형",
    desc: `'눈치백단 서브 캐릭터' 타입`,
    sub: `학교의 숨은 배경 전문가!`,
    char: `여러분은 '눈치백단 서브 캐릭터' 타입이에요! 기본적인 욕구가 엄청 낮은 스타일이죠.
성격도 어둡고, 차갑고, 소심해서 어떻게 살아가야 할지 감을 못 잡고 있어요. 밝은 세상에서 친구들이랑 어울려 즐겁게 살고 싶지만, 이성적 판단력과 지적 능력이 좀 부족해서 방법을 못 찾고 갈팡질팡해요.
방법을 모르니까 무조건 주변 사람들 눈치 보고 비위 맞추면서 자기 주장 없이 따라가는 걸 선택하게 돼요.
안타깝게도 이 타입은 다른 사람을 도와줄 만한 능력이 거의 없어요. 배려하는 법도 잘 모르고, 논리적으로 판단하는 능력도 없고, 뭔가 하고 싶은 욕구도 없어서 문제예요.
하지만 여러분이 깊이 생각하는 능력과 감정 표현력을 키울 수만 있다면, 다른 친구들 못지않게 풍성한 학창 시절을 보낼 수 있어요!`,
    result: `기억하세요! 이 타입은 '눈치백단 서브 캐릭터' 스타일이지만, '자신감'과 '자기표현 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 섬세함과 관찰력은 큰 장점이에요. 조금씩 자신의 생각을 표현하고 새로운 도전을 해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `학교에서는 에너지 넘치고 활력 있는 학생들을 좋아해요. 선생님들이 보기에 여러분은 힘없고 어두워 보일 수 있어요. 좀 더 밝은 모습으로 열정적으로 뭐든 해낼 수 있다는 자신감을 보여줘야 해요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '자신감 키우기' 챌린지: 매일 한 가지씩 새로운 일에 도전해보세요.
- '감정 표현' 연습하기: 매일 일기를 쓰면서 자신의 감정을 표현해보세요.
-동아리 활동 참여하기: 관심 있는 분야에서 친구들과 어울리며 자신감을 키워보세요.
- '목표 설정' 노트 만들기: 작은 목표부터 세우고 달성해나가며 성취감을 느껴보세요.
-발표 연습하기: 수업 시간에 적극적으로 발표하는 연습을 해보세요.
- '긍정적 자기 대화' 하기: 매일 거울을 보며 자신을 응원하는 말을 해보세요.
-멘토 찾기: 존경하는 선배나 선생님께 조언을 구하고 롤모델로 삼아보세요.`,
    human: `- 베프, 여자친구
이 친구와는 즐거운 학창 시절을 보내기 어려울 수 있어요. 하지만 그들의 숨겨진 장점을 찾아주고, 자신감을 갖도록 격려해주세요.
- 선생님
이 선생님의 수업에서는 때때로 도전적인 과제를 마주할 수 있습니다. 이를 학습 능력을 향상시키는 기회로 삼되, 자신의 한계도 인지하세요. 자기주도적 학습 습관을 기르는 것이 중요합니다. 또한, 다양한 교육적 조언을 얻기 위해 다른 선생님들과도 소통해보는 것이 좋습니다. 이러한 균형 잡힌 접근은 여러분의 전반적인 학습 경험을 풍부하게 할 것입니다.
- 선배
이 타입은 한 학교에서 오래 공부하며 성적을 올리기 어려울 수 있어서 학생회장 같은 자리에 있을 가능성이 매우 낮아요. 이런 선배들은 자유롭게 할 수 있는 활동이 어울려요.
- 친구, 후배
얽매이지 않고 자유롭게 할 수 있는 활동을 같이 하는 게 좋아요. 그들의 페이스를 존중하면서도, 조금씩 자신감을 갖도록 도와주세요.`,
  },
  BCCCB: {
    type: "BCCCB",
    category: "에너지 번아웃 유형",
    desc: `'에너지 제로 슬리퍼' 타입`,
    sub: `학교의 숨쉬는 인형!`,
    char: `여러분은 '에너지 제로 슬리퍼' 타입이에요! 온몸의 에너지가 거의 다 빠져나가 손가락 하나 움직이기 힘든 상태예요. 어떻게 학교 다니고 있는지 의아할 정도죠. 친구들에 대한 감정도 없고, 깊이 생각하려고 하지도 않고, 학교생활을 즐겁게 해보려는 의지도 없는 상태예요.
그래도 '친구들이 날 이상하게 보면 어쩌지?' 라든가 '이렇게 살면 안 되는데...' 하는 숨은 마음은 있어요. 하지만 마음뿐이라 문제예요.
학교생활에 적극적으로 참여하려면 엄청난 인내심이 필요할 거예요. 아무도 무조건 여러분을 도와주진 않아요. 스스로 일어설 수 있도록 엄청나게 노력해볼 필요가 있어요.`,
    result: `기억하세요! 이 타입은 '에너지 제로 슬리퍼' 스타일이지만, '자기 동기부여'와 '작은 성취 경험'을 쌓으면 진짜 '인생 주인공'이 될 수 있어요!
    지금은 힘들지만, 조금씩 노력하면 반드시 변할 수 있어요. 자신을 믿고 한 걸음씩 나아가세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 자유롭고 편안한 활동이 잘 맞을 거예요. 공부나 활동에 대한 열정이나 뭔가 하고 싶은 마음이 없어서 조금만 힘들거나 어려우면 도망가려고 할 수 있어요.
이런 단점들을 고치는 게 가장 중요해요. 빨리 단점들을 없애고 학교생활에 적극적으로 참여해보세요. 그렇지 않으면 쉬운 활동만 골라서 하게 될 거예요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '에너지 충전' 루틴 만들기: 매일 아침 가벼운 운동으로 하루를 시작해보세요.
- '작은 목표' 세우기: 매일 아주 작은 목표라도 세우고 달성해보세요.
- '관심 분야' 찾기: 조금이라도 흥미 있는 과목이나 활동을 찾아보세요.
-멘토 선생님 만들기: 편하게 대화할 수 있는 선생님께 조언을 구해보세요.
- '감사 일기' 쓰기: 매일 좋았던 일 하나라도 찾아 기록해보세요.
- '학습 버디' 만들기: 비슷한 성향의 친구와 함께 공부하는 습관을 들여보세요.
-상담 프로그램 참여하기: 학교 상담 선생님과 정기적으로 대화하며 자신을 이해해보세요.`,
    human: `- 베프, 여자친구
아쉽지만 오랫동안 함께 지내기 어려운 친구예요. 아직 친해지지 않았다면 다시 생각해보고, 이미 친한 사이라면 여러분의 무한한 인내와 배려, 노력 없이는 관계를 유지하기 힘들 수 있어요.
- 선생님
이 선생님의 수업 방식이 여러분의 학습 스타일과 맞지 않을 수 있습니다. 수업을 통해 얻는 가치와 투입하는 노력을 재평가해보는 것이 좋겠습니다. 필요하다면 다른 교육 옵션을 탐색해보는 것도 고려해보세요. 여러분의 학습 목표와 더 잘 부합하는 수업을 찾는 것이 장기적으로 도움이 될 수 있습니다.
- 선배
솔직히 이런 타입이 학생회장이나 동아리장 같은 자리에 있기는 매우 어려워요. 그래도 그들의 숨겨진 재능을 발견하려고 노력해보세요.
- 친구, 후배
이 타입의 친구가 같은 반이나 동아리에 있다면, 여러분의 무한한 배려가 필요해요. 사실 공부나 활동에 큰 도움이 되진 않을 거예요. 하지만 그들만의 독특한 시각을 인정하고 작은 변화를 격려해주세요.`,
  },

  BCCCC: {
    type: "BCCCC",
    category: "차가운 돌부처 유형",
    desc: `'나만의 세계 크리에이터' 타입`,
    sub: `학교의 숨은 메타버스 주인공!`,
    char: `여러분은 '나만의 세계 크리에이터' 타입이에요! 어떻게 학교생활을 하고 있는지 정말 궁금해요.
얼음처럼 차가운 마음과 돌부처 같은 표정으로 감정 표현도 못하고 움츠러들어 있어요. 게다가 이기적이고 배려가 부족한 편이죠.
변화시켜 보려고 해도 본인이 변하고 싶어 하지 않아서 도와주기 어려워요. 스스로 의지를 갖고 친구들과 어울려 살아보고 싶은 마음이 생겨야 도움을 줄 수 있어요. 그래도 다행인 건 본인도 자기 문제를 어렴풋이 알고 있다는 거예요. 이게 바로 작은 희망이에요!
스스로 뭐든 변하고 싶다는 마음을 가질 수 있도록 노력해야 해요.`,
    result: `기억하세요! 이 타입은 '나만의 세계 크리에이터' 스타일이지만, '소통 능력'과 '공감 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    지금은 어려움을 겪고 있지만, 조금씩 노력하면 반드시 변할 수 있어요. 자신만의 독특한 시각을 장점으로 발전시키고, 조금씩 세상과 소통해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입의 친구가 학교생활에 적응하고 싶어 한다면 정말 다행이에요!
그런 의지가 있다면 조금 더 노력해서 준비해보세요. 열정적으로 보일 수 있도록 감정을 표현하고 생기 있는 모습을 보여주는 게 좋아요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '감정 표현 연습하기': 매일 거울 보며 다양한 표정 짓기를 해보세요.
- '소셜 미션 도전하기': 매일 한 명의 친구와 대화하는 목표를 세워보세요.
- '동아리 활동 참여하기': 관심 있는 분야에서 친구들과 어울려보세요.
- '칭찬 릴레이 시작하기': 매일 한 명씩 다른 사람의 장점을 찾아 말해보세요.
- '감사 일기 쓰기': 매일 감사한 일 하나를 찾아 기록해보세요.
- '학교 상담 선생님과 정기적으로 대화하기': 자신의 감정과 생각을 표현하는 연습을 해보세요.
- '작은 변화 노트 만들기': 매일 조금씩 달라지는 자신의 모습을 기록해보세요.`,
    human: `- 베프, 여자친구
과연 이런 타입의 친구를 만나 절친이 될 수 있을지 의문이에요. 하지만 그들의 숨겨진 매력을 발견하려 노력해보세요.
- 선생님
이 선생님의 교육 방식이 여러분의 학습 요구와 잘 맞지 않을 수 있습니다. 교육의 질과 효과성에 대해 재고해볼 필요가 있어 보입니다. 다른 학생들의 경험을 참고하고, 여러분의 학습 목표에 더 적합한 수업 옵션을 탐색해보는 것이 좋겠습니다. 이는 여러분의 학업 성취와 성장에 도움이 될 수 있습니다.
- 선배
선배 자리에 이런 타입의 사람이 있을 리 없어요. 만약 있다면, 그들의 독특한 시각을 배우려고 노력해보세요.
- 친구, 후배
이 타입이 친구나 후배라면, 혹시 특별한 이유로 전학 온 건 아닌지 살펴보세요. 상황에 따라 대처해야 해요. 하지만 그들의 장점을 찾아주고, 함께 성장할 수 있는 방법을 고민해보세요.`,
  },

  CAAAA: {
    type: "CAAAA",
    category: "출구 없는 활주로 유형",
    desc: `'인생의 인기스타 루키' 타입`,
    sub: `학교의 숨은 잠재력 킹!`,
    char: `여러분은 '인생의 인기스타 루키' 타입이에요! 친구들이랑 잘 어울리고 조화롭게 지내는 스타일이죠. 어떻게 행동해야 할지 잘 알고, 남을 배려하는 마음도 있고, 머리도 좋아요.
하고 싶은 거에 대해 솔직하게 말하는 편이고, 재능도 많아서 뭐든 잘할 수 있어요. 근데 좀 아쉬운 건 목표를 향해 달려가는 의지가 조금 부족해요. 조금만 더 열심히 하면 대박 성과를 낼 수 있는데, 어려움이 생기면 멈춰버리는 경향이 있어요.
다른 친구들보다 뛰어난 점이 많으니까 이런 장점들을 잘 살려보는 게 좋아요.
학교에서 더 높은 자리에 오르고 싶은 마음을 갖고 노력해보세요. 또 친구들과의 약속이나 의리를 중요하게 여기고 책임감을 키우는 것도 필요해요.
이런 부분들만 강화시킬 수 있다면 학교생활에서 큰 성공을 이룰 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '인생의 인기스타 루키' 스타일이지만, '끈기'와 '책임감'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 재능과 친화력은 대단해요. 하지만 가끔은 목표를 향해 꾸준히 노력하고, 맡은 일을 끝까지 해내는 습관을 기르세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 뭐든 잘 해낼 수 있는 친구예요. 그런데도 오히려 스스로를 과소평가하고 있을 수 있어요. 학교에서 더 높은 위치, 더 큰 꿈을 가지고 뭐든 도전해보세요. 자신감 있는 모습을 보이는 게 중요해요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '목표 설정 노트 만들기': 큰 목표를 세우고 작은 단계로 나눠 실천해보세요.
- '학생회 활동 참여하기': 리더십을 키우고 책임감을 기를 수 있어요.
- '도전 정신 키우기': 새로운 대회나 프로젝트에 참가해보세요.
- '시간 관리 앱 사용하기': 효율적으로 공부하고 활동하는 습관을 들여보세요.
- '멘토 찾기': 존경하는 선배나 선생님께 조언을 구하고 롤모델로 삼아보세요.
- '자신감 boost 연습': 매일 거울 보며 자신의 장점을 말해보세요.
- '의리 지키기 챌린지': 친구들과의 약속을 꼭 지키는 연습을 해보세요.`,
    human: `- 베프, 여자친구
학교생활에서 이런 타입의 유일한 단점인 '책임감' 부족이 그다지 문제되지 않아요. 오히려 부드러운 분위기를 만들 거예요. 그들의 장점을 인정하고 격려해주세요.
- 선생님
약속과 실행의 균형이 가끔 깨지는 타입이에요. 중요한 과제나 시험에서는 특히 주의하세요. 다만 이게 도움될 때도 있어요. 여러분의 실수도 쉽게 용서해줄 거예요.
- 선배
너무 가까이 다가가면 실망할 수 있어요. 실수나 태만을 쉽게 눈감아주기 때문에 나중에 다른 선배를 만나면 힘들 수 있어요. 적당한 거리를 유지하면서 배울 점은 배우세요.
- 친구, 후배
친구로서는 정말 편한 상대예요. 마음 놓고 지낼 수 있어서 학교 밖에서도 최고의 친구가 될 거예요. 하지만 그 친구가 팀 프로젝트의 파트너라면 진행 상황을 정기적으로 체크하세요.`,
  },

  CAAAB: {
    type: "CAAAB",
    category: "능력 좋은 베짱이 유형",
    desc: `'천재 프로 노는 척러' 타입`,
    sub: `학교의 숨은 실력자!`,
    char: `여러분은 '천재 프로 노는 척러' 타입이에요! 세상 사람들 대부분 공부나 일을 좋아해서 하는 게 아니라는 건 다들 알죠. 그래도 다들 열심히 하잖아요. 사람으로 태어났으니까 책임감 갖고 살아가는 거예요.
근데 이 타입은 좀 특이해요. 성격도 좋고 능력도 뛰어나요. 오히려 다른 친구들보다 더 잘하면 했지 모자란 게 하나도 없어요. 그런데도 공부하기 싫어하는 게 문제예요. 능력과 욕구가 너무 높아서 하고 싶은 것도, 즐기고 싶은 것도 엄청 많거든요. 그런 생각들이 항상 머릿속에 있다 보니 공부에 집중하거나 목표를 세우고 노력하는 게 쉽지 않아요.
뛰어난 능력이 있는데도 이런 부분을 고치지 않으면 주변 사람들이 여러분을 책임감 없고 나약한 사람으로 볼 수도 있어요. 큰 단점은 아니니까 고칠 수만 있다면 완벽한 학교생활을 할 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '천재 프로 노는 척러' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 능력과 다재다능함은 대단해요. 하지만 가끔은 한 가지에 집중하고 꾸준히 노력하는 습관을 길러보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입이 아직 공부에 집중하지 못하고 있다면, 속으로 '공부하기 싫다'는 생각이 있을 수 있어요. 여러분은 높은 이상과 책임감만 가질 수 있다면 충분히 성공할 수 있는 기본 성격을 가지고 있어요. 여러 활동에 적극적으로 참여해보세요!
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '공부 루틴 만들기': 매일 같은 시간에 조금씩이라도 공부하는 습관을 들여보세요.
- '목표 비주얼라이징': 꿈꾸는 미래의 모습을 구체적으로 그려보고 자주 떠올려보세요.
- '5분 집중 챌린지': 처음엔 5분만 집중해서 공부하고, 점점 시간을 늘려가세요.
- '관심 분야 심화학습': 좋아하는 과목이나 주제에 대해 더 깊이 파고들어보세요.
- '학습 버디 만들기': 비슷한 목표를 가진 친구와 함께 공부하는 시간을 가져보세요.
- '보상 시스템 만들기': 목표를 달성하면 자신에게 작은 선물을 주는 습관을 들여보세요.
- '멘토 찾기': 존경하는 선배나 선생님께 조언을 구하고 동기부여를 받아보세요.`,
    human: `- 베프, 여자친구
이 친구는 절친으로 아주 좋은 성격을 가지고 있어요. 다만 약속을 지키는 데 있어 책임감을 조금 더 키워야 할 필요가 있어요. 그들의 재능을 인정하면서도 책임감의 중요성을 일깨워주세요.
- 선생님
이런 타입의 선생님은 공부에만 집착해서 까다롭게 구는 성격은 아니에요. 수업하기에 어려운 분은 아니지만, 100% 믿기에는 좀 무리가 있으니 주의하세요. 수업 내용을 스스로 확인하고 복습하는 습관을 들이세요.
- 선배
이런 타입의 선배는 얼핏 보면 쉬워 보일 수 있어요. 공부에만 매진하는 모습을 보이지 않고 노는 것 같아 보이거든요. 하지만 함부로 대했다간 큰일 나요. 이 타입은 매우 이성적이고 판단력이 뛰어나서 정확하게 상황을 파악해요. 정확하고 성실한 모습을 보여주세요.
- 친구, 후배
이런 친구가 같은 반이나 동아리에 있다면 정말 행운이에요! 못하는 게 없는 능력자니까 곁에 두면 좋을 거예요. 그들의 재능을 인정하고, 함께 성장할 수 있는 기회를 만들어보세요.`,
  },

  CAAAC: {
    type: "CAAAC",
    category: `'인생은 즐거워' 유형`,
    desc: `‘'인생 즐겜러' 타입`,
    sub: `학교의 행복 바이러스!`,
    char: `여러분은 '인생 즐겜러' 타입이에요! 순수한 아이 같은 모습을 가지고 있죠. 사람들에 대한 따뜻한 마음도 있고, 겉으로는 그냥 노는 것 같아도 머릿속으로는 정확하게 상황을 판단할 줄 아는 똑똑함도 있어요.
기본적으로 재미를 추구하고 즐겁게 살고 싶어 하는 마음이 강해요. 그래서 학교에서 요구하는 책임감이나 목표 의식은 좀 낮은 편이에요. 규칙에 얽매이기 싫어하고, 무리하게 목표를 세우거나 자기 발전을 위해 힘쓰지는 않아요.
하지만 똑똑하기 때문에 자신의 감정을 조절하고 최소한 학교에서 원하는 모습으로 살아가려는 마음은 있어요. 또 남의 눈치를 별로 보지 않고 하고 싶은 대로 행동하는 경향이 있어서, 주변에서는 제멋대로에 책임감 없고 꿈이 없는 아이로 보일 수 있어요. 이런 단점만 조금 보완하면 최고의 친구가 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '인생 즐겜러' 스타일이지만, '목표 의식'과 '책임감'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 밝은 에너지와 똑똑함은 대단해요. 하지만 가끔은 미래를 위해 계획을 세우고 노력하는 습관을 길러보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `아직 공부에 집중하지 못하고 있다면 스스로에게 한번 물어보세요. 정말 공부가 어려워서 그런 건지, 아니면 그냥 하고 싶은 마음이 없어서 노력을 안 한 건지를...
기본적인 똑똑함과 밝은 성격을 가졌기 때문에 어떤 공부든 하면 잘할 수 있는 타입이에요. 목표를 세우고 조금만 더 노력해보세요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '재미있는 공부법 찾기': 게임이나 퀴즈 형식으로 공부해보세요.
- '목표 시각화하기': 꿈꾸는 미래의 모습을 그림으로 그려보고 자주 보세요.
- '5분 집중 챌린지': 처음엔 5분만 집중해서 공부하고, 점점 시간을 늘려가세요.
- '취미 연계 학습': 좋아하는 취미와 공부를 연결 지어 보세요.
- '학습 버디 만들기': 재미있는 친구와 함께 공부하는 시간을 가져보세요.
- '미니 프로젝트 도전': 관심 있는 주제로 작은 프로젝트를 해보세요.
- '하루 한 가지 배우기': 매일 새로운 한 가지를 배우는 습관을 들여보세요.`,
    human: `- 베프, 여자친구
평생 절친으로 지내는 데 문제될 건 없어요. 학교에서는 더없이 따뜻한 친구일 거고, 함께 취미를 즐기며 재미있게 지낼 수 있어요. 다만 공부나 미래에 대한 계획이 부족할 수 있으니, 여러분이 이런 부분을 채워준다면 최고의 친구가 될 거예요.
- 선생님
이런 타입의 선생님과 수업한다면 조금 주의해야 해요. 학업적으로 성공하는 사람은 책임감이 강하고 목표의식이 높은데, 이 타입은 그런 면에서 좀 부족할 수 있어요. 선생님을 믿되, 스스로 공부하는 습관을 들이세요.
- 선배
학교에서 해야 할 일과 하지 말아야 할 일을 잘 아는 선배예요. 꾸준히 공부해서 반장이나 학생회장 자리에 있을 수 있지만, 그저 해야 할 일이라 그 자리를 지키고 있을 뿐 큰 목표는 없을 수 있어요. 여러분의 실수를 크게 뭐라 하지는 않겠지만, 머릿속으로는 다 기억하고 있을 테니 조심하세요. 사적으로는 재미있게 지낼 수 있을 거예요.
- 친구, 후배
정말 재미있는 친구예요! 귀여운 성격에 애교도 있어서 인기 많을 거예요. 하지만 아이들처럼 힘든 일이나 너무 강요하면 도망가고 싶어 할 수 있으니 적당히 대해주세요.`,
  },

  CAABA: {
    type: "CAABA",
    category: "남 좋은 일만 시키는 유형",
    desc: `'천사 같은 예스맨' 타입`,
    sub: `학교의 인간 착한 맛집!`,
    char: `여러분은 '천사 같은 예스맨' 타입이에요! 기본적으로 거절을 못하고, 싫다는 말도 못하는 엄청 여린 스타일이죠.
누가 귀찮게 해도 싫은 내색 못 하고, 하기 싫은 것도 남의 눈치 보느라 어떻게든 해보려고 해요.
똑똑하고 지적인데도 이런 성격 때문에 자신에게 손해가 될 수 있어요. 친절한 건 좋지만, 너무 과하면 자신에게 도움 안 된다는 걸 알아야 해요.
친구들이랑 안 좋아지는 게 무섭고, 다들 자기를 좋은 애로 봐주길 바라다 보니 싸워야 할 때도 저자세로 피하곤 해요. 세상엔 이런 애들 이용해서 자기 이익만 챙기려는 애들이 많으니까 친절에 휘둘리지 말고 똑바로 판단하는 눈을 키우는 게 좋아요.
게다가 책임감이나 목표의식이 좀 부족할 수 있어서 자신이나 남에게 너무 관대하다 보니 쉽게 안일해질 수 있어요.
이 타입은 재능이 많은데 이런 단점 때문에 빛을 못 볼 수 있어요. 인생 목표 세우고 책임감 갖고 열심히 하면 성공할 수 있는 사람이에요!`,
    result: `기억하세요! 이 타입은 '천사 같은 예스맨' 스타일이지만, '자기주장'과 '결단력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 친절함과 지적 능력은 대단해요. 하지만 가끔은 자신의 의견을 분명히 말하고, 빠르게 결정하는 연습을 해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `적당히 놀 줄도 알고, 논리적으로 생각하며 친구 관계도 좋은 여러분은 어느 반에 가도 잘 어울리며 공부할 수 있는 사람이에요.
아직 공부에 집중 못하고 있다면 혹시 우유부단한 마음 때문은 아닌가요?
성공하고 싶은 마음을 갖고 노력해보세요. 뭐든 잘할 수 있는 기본 실력이 있는 사람이에요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '거절 연습하기': 매일 한 번씩 작은 것부터 거절해보세요.
- '목표 설정' 노트 만들기: 단기, 중기, 장기 목표를 세우고 실천해보세요.
- '자존감 높이기' 활동: 매일 자신의 장점을 하나씩 찾아 적어보세요.
- '책임감 키우기' 프로젝트: 반에서 작은 역할을 맡아 끝까지 해내보세요.
- '결단력 훈련': 작은 선택부터 빠르게 결정하는 연습을 해보세요.
- '경계 정하기' 연습: 자신의 한계를 정하고 그것을 지키는 연습을 해보세요.
- '멘토 찾기': 존경하는 선배나 선생님께 조언을 구하고 배워보세요.`,
    human: `- 베프, 여자친구
상대가 이런 타입이면 여러분은 엄청 운이 좋은 거예요. 왜냐면 상대는 여러분 일이라면 뭐든 도와주려 하고 배려하며 평화롭게 지내려고 할 거거든요. 하지만 학교 밖에서 이 타입이 얼마나 잘 해낼 수 있을지가 관건이니까 그런 부분을 고쳐주려고 노력해주세요.
- 선생님
이 선생님은 학생들의 의견을 잘 수용하는 편이실 수 있습니다. 유연한 태도로 학생들의 요구사항을 들어주실 가능성이 높습니다. 하지만 이러한 특성 때문에 중요한 사안에서는 결정력이 부족할 수 있습니다. 선생님과 소통할 때는 상호 존중의 자세를 유지하고, 책임감 있는 태도로 임하는 것이 좋습니다. 이는 건강한 학습 관계를 유지하는 데 도움이 될 것입니다.
- 선배
경쟁이 심한 학교에서 이런 타입은 학생회장 같은 자리에 오르기 힘들어요. 강력한 추진력도 부족하고 선배인데도 후배 눈치를 보는 등 소심한 성격일 수 있어요. 하지만 여러분의 실수를 지적하진 않아도 정확히 알고 있을 테니 조심하세요.
- 친구, 후배
같이 학교생활 하는 데 전혀 문제 없는 타입이에요. 오히려 곁에 두면 여러분에게 도움 될 친구예요.`,
  },

  CAABB: {
    type: "CAABB",
    category: `'좋은 게 좋은 거지' 유형`,
    desc: `'긍정왕 무적핑크' 타입`,
    sub: `학교의 인간 힐링 포션!`,
    char: `여러분은 '긍정왕 무적핑크' 타입이에요! 주변 누구든 좋아하는 스타일이죠.
이런 타입은 다른 친구가 실수하거나 잘못해도 다 덮어주고 심지어 대신 해결해주기도 해서 인기 짱 캐릭터예요.
근데 과연 그게 여러분한테 좋기만 한 건지 한번 생각해봐야 해요.
동생이나 후배 등 여러분이 이끌어줘야 하는 애들한테도 너무 부드럽기만 해요. 그게 오히려 그 애들 망칠 수도 있다는 걸 알아야 해요.
평생 여러분이 다 해주고, 좋은 말만 하면서 넘어갈 순 없어요. 이 타입은 주변에서 보기엔 자기 이익 같은 건 안 따지고 좀 덜렁대는 것 같아 보이지만, 사실 엄청 이성적이고 판단력도 뛰어나요. 남들이 모르는 이런 장점을 키워야 인생에서 중요한 자리를 차지할 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '긍정왕 무적핑크' 스타일이지만, '현실 감각'과 '책임감'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻함과 판단력은 대단해요. 하지만 가끔은 현실적으로 생각하고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `어느 반에 가도 적응 잘할 타입인데 공부에 집중 못하는 게 좀 이상해요. '아무 과목이나' 가 아니라 '진짜 하고 싶은 공부'를 찾아 도전해보세요. 좀 더 목표를 향해 달려가는 자세로 학교생활 하는 게 중요해요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '꿈 찾기 프로젝트': 다양한 직업 탐구 활동에 참여해보세요.
- '긍정의 벽 만들기': 긍정적인 말 대신 현실적인 조언을 주고받는 연습을 해보세요.
- '책임감 키우기 챌린지': 매주 한 가지씩 책임질 일을 맡아 끝까지 해내보세요.
- '시간 관리 앱 사용하기': 효율적으로 공부하고 활동하는 습관을 들여보세요.
- '거절하기 연습': 때로는 친구들의 부탁을 거절하는 연습도 해보세요.
- '목표 설정' 노트 만들기: 구체적인 학업 목표를 세우고 실천해보세요.
- '멘토 찾기': 존경하는 선배나 선생님께 현실적인 조언을 구해보세요.`,
    human: `- 베프, 여자친구
이런 타입을 선택했다면 여러분은 정 많고 따뜻한 사람을 원하는 거예요. 누구보다 정 많고 다정한 사람이라 사랑받고 사랑하기엔 최고의 짝꿍이에요.
- 선생님
이 선생님은 인간적으로 매우 친근하고 좋은 분입니다. 학생들과의 관계에서 강점을 보이십니다. 다만 학업적인 면에서는 좀 더 주의 깊게 살펴볼 필요가 있을 수 있습니다. 수업 내용과 과제의 질을 꼼꼼히 확인하고, 필요하다면 추가적인 학습 자료를 찾아보는 것도 좋은 방법일 수 있습니다.
- 선배
이런 타입의 선배라면 여러분은 엄청 편할 거예요. 항상 칭찬만 해주고, 응원해주며 여러분의 실수도 눈감아줄 거예요. 하지만 그래서 여러분이 안일해지고 책임감이 없어질 수 있으니 스스로 조심하세요.
- 친구, 후배
같은 반이나 동아리에 있기에 정말 편한 친구예요. 늘 여러분이 듣기 좋은 말만 해줄 테니까요. 하지만 그게 다 진심은 아닐 수 있으니 잘 구분해보세요.`,
  },

  CAABC: {
    type: "CAABC",
    category: "봉사를 낙으로 삼는 유형",
    desc: `'봉사왕 힐링 요정' 타입`,
    sub: `학교의 인간 착한 맛집!`,
    char: `여러분은 '봉사왕 힐링 요정' 타입이에요! 보통 친구들은 이득 없이 남 돕는 걸 별로 안 좋아해요.
시간이나 돈을 남 도우는 데 쓰느니 자기를 위해 쓰는 게 당연하다고 생각하거든요.
또 봉사활동은 형편 좋은 애들이나 하는 거라고 생각하기도 해요. 근데 여러분은 봉사활동 자체가 즐거운 타입이에요.
다른 사람 돕는 게 보람 있고, 자아실현하는 기분이 드는 거죠.
하지만 남 돕는 데만 빠지다 보면 정작 자기는 못 돌볼 수 있어요. 스스로한테 단호하지 못하고, 내 인생에 대한 책임감이 좀 부족한 거예요.
자칫하면 주변에서 대충 사는 애라고 볼 수도 있어요. 자신의 목표를 세우고 더 높은 곳을 향해 노력해보는 게 어떨까요?`,
    result: `기억하세요! 이 타입은 '봉사왕 힐링 요정' 스타일이지만, '자기 관리 능력'과 '목표 의식'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 봉사 정신은 대단해요. 하지만 가끔은 자신을 위한 시간을 갖고, 미래를 위한 구체적인 계획을 세워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `성격상 봉사활동을 좋아하고 즐거워할 가능성이 높으니까 사회복지사 쪽으로 진로를 생각해보는 건 어때요? 여러분 성향으로는 충분히 잘할 수 있는 직업이에요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '자기 시간 만들기': 봉사 외에도 자신을 위한 시간을 꼭 가져보세요.
- '목표 설정 노트 쓰기': 봉사와 학업, 개인 성장에 대한 구체적인 목표를 세워보세요.
- '시간 관리 앱 사용하기': 봉사와 공부 시간을 효율적으로 분배해보세요.
- '자기 주장 훈련': 가끔은 '아니오'라고 말하는 연습을 해보세요.
- '진로 탐구 활동': 사회복지 외에도 다양한 진로를 알아보세요.
- '자기 돌봄 습관 들이기': 남을 돕는 만큼 자신도 돌보는 습관을 기르세요.
- '리더십 키우기': 봉사 활동에서 리더 역할을 맡아보세요.`,
    human: `- 베프, 여자친구
보통은 좋은 관계 유지하기 좋은 타입이에요. 하지만 가끔은 너무 인정 넘치는 상대가 나한텐 별로 안 좋을 수 있어요. 적당한 거리 유지하는 법도 배워보세요.
- 선생님
이 선생님은 전반적으로 좋은 수업 파트너가 될 수 있습니다. 다만, 때때로 세부사항에 대한 관리가 부족할 수 있으니 학생 여러분의 주의가 필요할 수 있습니다. 수업 내용을 스스로 정리하고 복습하는 습관을 기르는 것이 도움이 될 것입니다. 이러한 접근은 여러분의 학습 효과를 높이고 자기주도적 학습 능력을 향상시킬 수 있습니다.
- 선배
반장이나 학생회장으로는 좀 부족할 수 있어요. 독선적인 면이 있고 책임감이 좀 부족해서 일을 대충 처리할 수 있거든요. 무조건 잘해준다고 좋은 선배는 아니에요.
- 친구, 후배
뚜렷한 목표 없이 그냥 '학교 다니는' 것일 수 있어요. 팀 프로젝트할 때 문제가 생길 수 있으니 주의하세요. 하지만 그들의 따뜻한 마음을 배우려고 노력해보세요.`,
  },

  CAACA: {
    type: "CAACA",
    category: "전통사회 어머니 유형",
    desc: `'학교의 수호천사' 타입`,
    sub: `반의 숨은 힐링 포션!`,
    char: `여러분은 '학교의 수호천사' 타입이에요! 늘 묵묵히 자기 자리를 지키면서, 화나도 참고 또 참으며 용서하는 천사 같은 스타일이죠.
자기 욕심은 쏙 숨기고 다른 친구들만 생각하며 지나치게 배려하는 타입이에요.
남 욕하는 일은 거의 없고, 친구들 위해서라면 뭐든 도와주고 싶어 하는 사람이에요. 어느 반이든 이런 친구는 꼭 있어야 해요.
남 돕는 걸로 행복 느끼는 사람은 어디서나 귀한 존재죠.
하지만 어떤 상황이냐에 따라 이 타입의 평가는 엄청 달라질 수 있어요. 이런 타입이 경쟁이 심한 특목고 같은 데 있다면 어울리지 않는 옷 입은 것 같을 거예요. 가끔은 밝지 못하고 늘 어둡다는 소리를 들을 수도 있고, 존재감 없다고 여겨질 수도 있어요.
자기한테 딱 맞는 곳을 잘 찾고 장점을 잘 아는 게 중요해요.`,
    result: `기억하세요! 이 타입은 '학교의 수호천사' 스타일이지만, '자기 주장'과 '긍정 에너지'를 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 배려심은 대단해요. 하지만 가끔은 자신의 욕구도 표현하고, 스스로를 위한 시간도 가져보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `다른 친구들이랑 경쟁하면서 치열하게 살아가는 게 힘들게 느껴지지는 않나요? 사회복지사가 잘 어울릴 것 같아요.
학교 도서관 봉사나 학급 일지 관리도 잘 맞을 거예요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '자기 주장 훈련': 가끔은 자신의 의견을 분명히 말하는 연습을 해보세요.
- '긍정 에너지 채우기': 매일 자신을 칭찬하는 시간을 가져보세요.
- '진로 탐색 활동': 사회복지사 외에도 다양한 직업을 알아보세요.
- '리더십 키우기': 가끔은 학급 활동에서 리더 역할을 맡아보세요.
- '자기 돌봄 습관 들이기': 남을 돕는 만큼 자신도 돌보는 습관을 기르세요.
- '감정 표현 연습': 일기나 그림으로 자신의 감정을 표현해보세요.
- '장점 발견 프로젝트': 매주 자신의 새로운 장점을 하나씩 찾아보세요.`,
    human: `- 베프, 여자친구
이런 타입은 학교생활하는 데 아무 문제 안 만들고 반을 위해 최선을 다하며 평화롭게 지내기에 최고의 친구예요. 하지만 뭔가 열정적으로 즐기며 학창 시절을 보내기엔 좀 부족할 수 있어요.
- 선생님
이 타입은 어떤 과목 선생님이냐에 따라 최고가 될 수도, 최악이 될 수도 있어요. 지금 어떤 관계의 수업을 하고 있느냐에 따라 평가가 달라져요.
- 선배
인격적으로는 더할 나위 없이 좋은 선배예요. 하지만 학업적으로는 성공하는 마인드와는 좀 다를 수 있어서 치열한 입시 경쟁 속에서 얼마나 좋은 성적을 낼지 모르는 타입이에요.
- 친구, 후배
뭔가 열정적으로 하는 활동에는 안 맞는 타입이에요. 조용히 한 자리에서 다른 친구들을 도우면서 할 수 있는 일을 맡기는 게 좋아요.`,
  },

  CAACB: {
    type: "CAACB",
    category: "봉사하기 위해 태어난 유형",
    desc: `'인정 요정' 타입`,
    sub: `학교의 인간 좋아요 버튼!`,
    char: `여러분은 '인정 요정' 타입이에요! 마치 세상에 '좋아요'를 뿌리고 다니려고 태어난 것 같아요.
모든 일을 판단할 때 "어떻게 하면 친구들한테 도움이 될까?", "어떻게 하면 내가 친구들을 챙겨줄 수 있을까?"를 먼저 생각하는 스타일이에요.
하지만 개인적인 책임감은 좀 부족하고, 친구들한테 때론 쓴소리를 해줘야 할 때도 좋은 말만 하려고 해서 문제가 될 수 있어요.
늘 다른 친구들 일만 돌봐주다 보니 정작 본인이 하고 싶은 게 뭔지 모르고 노는 것도 서툴러요.
다행히 머리는 엄청 좋아서 아무리 남 돕는 게 좋다고 생각해도 그 때문에 자기 일을 크게 망치는 경우는 없어요.
성격이 너무 좋아서 손해 볼 줄 알면서도 희생정신 발휘하는 사람이에요.
조금 더 자기 인생을 즐겨보는 게 어때요? 계속 착한 일만 하며 살 순 없어요. 그러다 언젠가 지칠 수 있으니 자기 삶도 재미있게 꾸며보세요.`,
    result: `기억하세요! 이 타입은 '인정 요정' 스타일이지만, '자기 사랑'과 '균형 잡힌 삶'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 배려심은 대단해요. 하지만 가끔은 자신을 위한 시간을 가지고, 본인의 욕구도 충족시켜보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `성격상 봉사활동을 좋아할 것 같아요. 사회복지사 쪽으로 진로를 생각해보는 건 어떨까요? 거기서 자아실현할 수 있을 거예요.
따뜻한 마음과 남 배려하는 성격이 강한 여러분한테 딱 어울리는 직업이 될 거예요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '나만의 시간 갖기': 매일 30분은 오직 자신을 위해 쓰세요.
- '취미 찾기 프로젝트': 매주 새로운 활동을 시도해보세요.
- '자기 주장 훈련': 하루에 한 번은 자신의 의견을 분명히 말해보세요.
- 'NO 말하기 연습': 가끔은 친구들의 부탁을 거절해보세요.
- '목표 설정 노트 만들기': 자신만의 꿈과 목표를 구체적으로 적어보세요.
- '감정 일기 쓰기': 매일 자신의 감정을 솔직하게 표현해보세요.
- '셀프 칭찬 하기': 하루에 한 번은 자신을 칭찬해주세요.`,
    human: `- 베프, 여자친구
평화로운 학창 시절을 보낼 수 있는 타입이에요. 하지만 가끔은 그들의 욕구나 감정도 물어봐주세요.
- 선생님
선생님한테 '정' 얘기하면 대부분 여러분이 원하는 대로 해주실 수 있는 타입이에요. 하지만 아무리 인정 많으셔도 그만큼 똑똑하시니까 눈에 보이는 계산으로 대하면 안 돼요.
- 선배
인격적으로는 엄청 좋은 선배일 수 있지만, 학교 일에 있어서는 그다지 적극적이거나 책임감 있는 타입은 아니에요.
- 친구, 후배
이런 타입이 친구나 후배라면, 좋은 관계 유지하기 딱 좋은 사람이에요. 학급의 평화를 지키는 데 일등공신이 될 타입이에요.`,
  },

  CAACC: {
    type: "CAACC",
    category: "봉사활동이 자기실현인 유형",
    desc: `'전설의 봉사왕' 타입`,
    sub: `학교의 인간 천사!`,
    char: `여러분은 '전설의 봉사왕' 타입이에요! 자기가 믿는 게 무조건 옳고 좋다고 생각하는 스타일이죠.
마치 학생회 봉사부장 같은 느낌? 자기는 1도 나쁜 마음 없고, 이게 당연하니까 다른 친구들도 같이하자고 끌어들이려는 착한 마음이 많아요. 뭔가를 비판적으로 보는 눈이 좀 부족해서 보이는 대로 다 믿어버려요. 그래서 남의 숨은 속마음이나 계획을 잘 못 알아차리는 게 단점이에요.
그냥 무조건 믿고 그게 진실이라고 생각해서 주변 친구들한테 과하게 "우리 같이 하자!"고 하는 거예요.
이 타입은 따뜻한 마음씨를 가졌으면서도 반대로 남 배려는 좀 부족하고, 고집 세서 상황 판단을 잘 못할 때가 있어요.
머리는 좋은데 복잡한 인간관계는 잘 이해 못 하는 면이 있어요. 조금은 넓게 여러 가지 경우를 생각하면서 보는 눈이 필요할 거예요.`,
    result: `기억하세요! 이 타입은 '전설의 봉사왕' 스타일이지만, '비판적 사고'와 '균형 잡힌 시각'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음과 봉사 정신은 대단해요. 하지만 가끔은 다른 시각으로 세상을 바라보고, 다양한 의견을 들어보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `봉사정신이 엄청 강한 타입이라 사회복지사가 잘 어울릴 수 있어요.
학교 봉사동아리에서 활동하면서 자아실현하는 것도 좋겠어요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '다양성 이해 프로젝트': 매주 다른 의견을 가진 친구와 대화해보세요.
- '비판적 사고 훈련': 뉴스나 정보를 볼 때 항상 "정말 그럴까?"라고 생각해보세요.
- '균형 잡힌 활동 하기': 봉사 외에도 다른 취미활동을 해보세요.
- '감정 읽기 연습': 친구들의 표정과 행동에서 숨은 감정을 읽어보세요.
- '유연성 키우기': 가끔은 자신의 의견을 바꿔보는 연습을 해보세요.
- '경청 훈련': 상대방의 말을 끝까지 듣고 이해하려 노력해보세요.
- '자기 성찰 일기 쓰기': 매일 자신의 행동을 돌아보고 개선점을 찾아보세요.`,
    human: `- 베프, 여자친구
학교생활에서는 따뜻한 타입이라 좋을 수 있어요. 하지만 엉뚱한 데 빠져서 100% 믿어버리면 마음을 안 바꿀 수 있으니 적당히 제동 걸어주세요.
- 선생님
"이 선생님은 학생들의 의견을 매우 존중하시는 편입니다. 이는 긍정적일 수 있지만, 때로는 더 많은 지도가 필요한 상황에서 어려움이 있을 수 있습니다. 특히 협력이 필요한 과제나 수행평가에서는 추가적인 노력이 필요할 수 있어요. 이런 경우, 학생들이 주도적으로 계획을 세우고 선생님과 긴밀히 소통하는 것이 도움이 될 수 있습니다."
- 선배
이런 타입이 학생회장이나 동아리장이라면, 솔직히 큰 성과를 바라기는 어려워요. 일에 대한 목적이나 책임감이 좀 부족한 선배라 멋진 롤모델이 되긴 부족한 타입이에요.
- 친구, 후배
과도한 경쟁 구도로 뭘 시키면 안 돼요. 다른 애들 이기려는 마음이나 강한 성격이 없어서 편안한 상태에서 일할 수 있게 해줘야 해요.`,
  },

  CABAA: {
    type: "CABAA",
    category: "둥글둥글 원형 같은 유형",
    desc: `'인간 마시멜로' 타입`,
    sub: `학교의 인기 아이돌!`,
    char: `여러분은 '인간 마시멜로' 타입이에요! 인격적으로 '좋은 애'라는 소리 듣는 스타일이죠.
기본적으로 밝고 남 배려하는 마음이 넘치는 사람이라 누구한테나 인기 많을 거예요.
하지만 학업적으로 봤을 때는 그다지 잘한다고 보기 어려울 수 있어요. 왜냐면, 악착같이 뭔가를 이뤄내려는 마음이 부족하고, 경쟁에서 져도 별로 신경 안 쓰고 목표 의식이 없거든요.
친구 관계에서는 이런 성격이 좋을 수 있지만 학업 성공을 위해서는 조금 바꿀 필요가 있어요. 어느 학교나 '좋은 애'를 원하긴 하지만 '공부 잘하는 애'가 필요하니까 공부할 때는 진지하게 집중할 줄 아는 자세가 필요해요. 자기 관리를 좀 더 철저히 하고, 목표 달성을 위해 노력하면 완벽한 사람이 될 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '인간 마시멜로' 스타일이지만, '목표 의식'과 '집중력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 밝은 성격과 배려심은 대단해요. 하지만 가끔은 진지하게 목표를 세우고 그것을 위해 노력하는 습관을 길러보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `자신감 있고 능력 있어 보이는 이미지를 만들어보는 게 좋아요.
발표할 때 밝은 모습은 플러스지만, 뭔가 자신감 없어 보이는 모습은 "얘가 공부를 잘 할 수 있을까?"라는 의심을 들게 해요. 그런 부분만 고치면 아무 문제없이 잘될 거예요.
학교생활을 더 잘하려면 이런 노력을 해보세요:
- '목표 설정 다이어리 만들기': 매주 작은 목표를 세우고 달성해보세요.
- '집중력 훈련 앱 사용하기': 공부할 때 집중력을 키우는 앱을 활용해보세요.
- '자신감 boost 연습': 거울 보며 자신의 장점을 매일 말해보세요.
- '시간 관리 스킬 배우기': 효율적인 시간 관리법을 익혀보세요.
- '경쟁 마인드 키우기': 작은 대회나 콘테스트에 참가해보세요.
- '자기 PR 연습': 자신의 능력을 잘 표현하는 방법을 배워보세요.
- '멘토 찾기': 존경하는 선생님이나 선배에게 조언을 구해보세요.`,
    human: `- 베프, 여자친구
학교생활하면서 아무 문제없이 잘 지낼 수 있는 타입이에요. 너무 닦달하지 말고 상대방을 어느 정도 존중해주면서 지내면 아주 좋은 관계가 될 수 있어요.
- 선생님
사귀기 쉽고 좋은 관계로 유지하기 쉬운 선생님이에요. 수업 시간에 적극적으로 참여하고, 질문도 자주 해보세요.
- 선배
인격적으로는 아주 좋은 선배예요. 여러분이 공부나 학교 활동에서 조금 실수해도 엄하게 혼내지 않는 성격이라 대하기 쉬운 선배예요.
- 친구, 후배
학교생활에 있어 목적의식을 갖고 열심히 하는 타입은 아니에요. 적당히 자기 할 일 정도만 하는 애일 가능성이 높아요. 공부 잘하는 것보다는 사람들이랑 잘 지내는 걸 더 중요하게 생각하는 친구예요.`,
  },

  CABAB: {
    type: "CABAB",
    category: "즐거움과 자유를 찾는 유형",
    desc: `'자유영혼 올라운더' 타입`,
    sub: `학교의 숨은 다재다능 챔피언!`,
    char: `여러분은 진정한 '자유영혼 올라운더' 타입이에요! 마치 어디든 날아갈 수 있는 새처럼 자유로운 영혼을 가졌죠.
규칙이나 틀에 갇히는 걸 정말 싫어해요. 그래서 학교 규칙이나 어른들의 잔소리가 귀찮게 느껴질 수 있어요. 하지만 친구들과의 관계는 정말 소중히 여기는 편이에요.
감정 표현도 솔직하고, 하고 싶은 것도 많아요. 때로는 주변 사람들이 여러분을 "좀 제멋대로야"라고 생각할 수도 있어요. 하지만 실은 여러분 안에 숨겨진 재능이 정말 많다는 뜻이에요!
여러분은 자유롭게 살면서도 스스로 조절할 줄 아는 능력이 있어요. 이 능력을 잘 활용하면 정말 멋진 사람이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '올라운더 지망생 집중력 제로' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 다재다능함을 믿어요. 하지만 가끔은 목표를 정하고, 그것을 향해 노력하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 사실 여러 방면에서 재능이 넘치는 사람이에요. 그런데 목표를 정하고 도전하는 게 좀 어려울 수 있어요.
수업 시간에 집중하기 어려울 수 있어요. 하지만 관심 있는 과목은 정말 잘할 수 있어요. 그 과목에서 여러분의 재능을 마음껏 발휘해보세요!
동아리 활동도 적극적으로 참여해보세요. 여러 가지 활동을 경험하면서 숨겨진 재능을 발견할 수 있어요.
시험 기간에는 공부 계획을 세우고 지키는 게 어려울 수 있어요. 하지만 조금만 노력하면 좋은 결과를 얻을 수 있어요. 친구들과 함께 공부하는 것도 좋은 방법이에요.
진로를 고민할 때는 여러 가지 옵션을 고려해보세요. 여러분은 다양한 분야에서 잘할 수 있는 잠재력이 있어요!`,
    human: `- 베프, 여자친구
정말 매력적인 친구예요! 하지만 가끔 예측할 수 없는 행동을 할 수 있어요. 자유를 존중해주되, 소중한 관계도 잘 지킬 수 있도록 함께 노력해보세요.
- 선생님
이런 타입의 선생님은 수업을 재미있게 진행하실 거예요. 하지만 가끔 책임감 있게 행동하지 않을 수 있어요. 선생님의 창의적인 면을 존중하면서도, 수업에 적극적으로 참여해 보세요.
- 선배
이 타입의 선배는 정말 재미있고 친근할 거예요. 하지만 가끔 책임감이 부족할 수 있어요. 선배의 좋은 점을 배우면서도, 스스로 판단하는 능력을 키워보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 재미있고 독특할 거예요. 하지만 가끔 약속을 잊어버릴 수 있어요. 그들의 자유로운 성격을 존중하면서도, 중요한 일은 꼭 함께 챙겨주세요.`,
  },

  CABAC: {
    type: "CABAC",
    category: `'그때그때 달라요' 유형`,
    desc: `'무지개 기분파' 타입`,
    sub: `학교의 숨은 변화무쌍 아티스트!`,
    char: `여러분은 진정한 '무지개 기분파' 타입이에요! 마치 날씨가 변하듯 기분도 계속 바뀌는 독특한 매력의 소유자죠.
여러분은 항상 자유롭고 재미있는 길을 찾아다녀요. 숙제나 시험 같은 힘든 일은 피하고 싶어하고, 대신 즐겁고 편한 일을 좋아해요.
때로는 자기가 하고 싶은 대로만 행동해서 주변 사람들이 "너무 제멋대로야!"라고 생각할 수도 있어요. 하지만 사실 여러분은 정말 다재다능한 사람이에요. 다만 뚜렷한 목표가 없어서 그 재능을 제대로 발휘하지 못하고 있을 뿐이죠.
자신에게 너무 관대한 마음을 조금만 바꾸면, 정말 멋진 사람이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '무지개처럼 변화무쌍한 매력' 스타일이지만, '꾸준함'과 '책임감'을 기르면 진짜 '인생 아티스트'가 될 수 있어요!
    여러분의 창의력과 매력을 믿어요. 하지만 가끔은 한 가지 일에 집중하고, 끝까지 해내는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 예술이나 체육 같은 창의적인 과목에서 빛을 발할 수 있어요. 친구들도 여러분의 매력에 푹 빠질 거예요.
수업 시간에 집중하기 어려울 수 있어요. 하지만 관심 있는 수업에서는 정말 멋진 아이디어를 낼 수 있어요. 그런 순간을 놓치지 마세요!
동아리 활동도 적극적으로 참여해보세요. 연극부, 미술부, 밴드부 같은 예술 관련 동아리에서 여러분의 재능을 마음껏 뽐내보세요.
시험 기간에는 공부하기 싫어질 수 있어요. 하지만 친구들과 함께 공부하면 더 재미있게 할 수 있을 거예요. 공부도 게임처럼 재미있게 만들어보세요!
진로를 고민할 때는 여러분의 창의력을 살릴 수 있는 직업을 생각해보세요. 예술가, 디자이너, 연예인 등 다양한 선택지가 있어요.`,
    human: `- 베프, 여자친구
정말 매력 넘치는 친구예요! 하지만 기분에 따라 행동이 크게 바뀔 수 있어요. 그 친구의 변화무쌍한 매력을 즐기면서도, 중요한 약속은 꼭 지키도록 함께 노력해보세요.
- 선생님
이런 타입의 선생님은 수업을 정말 재미있게 진행하실 거예요. 하지만 가끔 수업 내용이 갑자기 바뀔 수도 있어요. 선생님의 창의적인 수업을 즐기면서도, 중요한 내용은 꼭 메모해두세요.
- 선배
이 타입의 선배는 정말 재미있고 매력적일 거예요. 하지만 약속을 잘 지키지 않을 수 있어요. 선배의 좋은 점을 배우면서도, 책임감 있는 모습을 보여주세요.
- 친구, 후배
이 타입의 친구나 후배는 항상 새로운 아이디어로 가득할 거예요. 하지만 팀 프로젝트에서 맡은 역할을 끝까지 하지 않을 수 있어요. 그들의 창의력을 인정하면서도, 함께 책임감 있게 일을 마무리하는 법을 알려주세요.`,
  },

  CABBA: {
    type: "CABBA",
    category: "헌신이 삶의 보람인 유형",
    desc: `'천사 비주얼 엄마손 도시락' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '천사 비주얼 엄마손 도시락' 타입이에요! 마치 세상을 위해 태어난 것처럼 항상 다른 사람을 돕고 싶어 하는 따뜻한 마음의 소유자죠.
여러분은 항상 친구들을 먼저 생각하고, 그들의 기분을 맞추는 데 기쁨을 느껴요. 하지만 가끔은 너무 착해서 다른 사람들에게 이용당할 수 있어요.
친구들의 눈치를 보느라 자신의 의견을 말하지 못할 때가 많아요. 하지만 여러분도 자신만의 생각과 판단력을 키우는 게 중요해요.
책임감, 목표의식, 그리고 옳고 그름을 판단하는 능력을 기르면 더욱 멋진 사람이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '천사 비주얼 엄마손 도시락' 스타일이지만, '자기 주장'과 '결단력'을 기르면 진짜 '인생 힐링 요정'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 자신의 의견을 말하고, 결정을 내리는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 결정을 내리는 게 어려울 수 있어요. 진로 선택이나 동아리 가입 같은 일을 할 때 망설일 수 있죠.
수업 시간에는 항상 열심히 듣지만, 발표하는 걸 두려워할 수 있어요. 하지만 용기를 내서 자신의 생각을 말해보세요. 여러분의 의견도 정말 중요해요!
친구들 사이에서는 평화주의자 역할을 할 거예요. 싸움이 일어나면 중재하려고 노력하겠죠. 하지만 가끔은 자신의 입장도 분명히 밝혀보세요.
시험 기간에는 친구들 공부 도와주느라 정작 본인 공부는 못할 수 있어요. 다른 사람을 돕는 것도 좋지만, 자신의 공부 시간도 꼭 확보하세요!
진로를 고민할 때는 남을 돕는 직업을 생각해볼 수 있어요. 선생님, 간호사, 사회복지사 같은 직업이 잘 어울릴 거예요.`,
    human: `- 베프, 여자친구
정말 다정하고 배려심 넘치는 친구예요! 하지만 너무 맞춰주기만 하다 보면 진짜 마음을 모를 수 있어요. 가끔은 솔직한 마음을 물어보고, 그 친구의 의견도 존중해주세요.
- 선생님
이런 타입의 선생님은 정말 친절하고 따뜻할 거예요. 하지만 가끔은 결정을 내리지 못해 수업이 흐트러질 수 있어요. 선생님의 마음을 이해하면서도, 필요할 때는 학생들이 의견을 제시해보세요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 다정할 거예요. 하지만 리더십이 부족할 수 있어요. 선배의 따뜻함을 배우면서도, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 항상 남을 도우려고 할 거예요. 하지만 자기 일은 뒷전일 수 있어요. 그들의 따뜻한 마음을 인정하면서도, 자신을 돌보는 법도 알려주세요.`,
  },

  CABBB: {
    type: "CABBB",
    category: "평화주의자 유형",
    desc: `'무지개 평화 요정' 타입`,
    sub: `학교의 숨은 힐링 챔피언!`,
    char: `여러분은 진정한 '무지개 평화 요정' 타입이에요! '우리 모두 친구가 되자'가 여러분의 인생 모토죠.
여러분은 누구와도 갈등 없이 지내고 싶어 해요. 하지만 그러다 보니 자신의 꿈을 위해 열심히 노력하거나 도전하는 걸 어려워할 수 있어요.
여러분의 마음은 정말 따뜻해요. 친구들을 항상 먼저 생각하고, 누군가 도움을 요청하면 거절하지 못하죠. 불쌍한 사람을 보면 그냥 지나치지 못하는 착한 마음을 가졌어요.
하지만 가끔은 너무 착해서 문제가 될 수 있어요. 싫은 말을 하지 못하고, 당연한 것도 주장하지 못할 때가 있죠. 이런 부분은 조금씩 고쳐나가는 게 좋아요.`,
    result: `기억하세요! 이 타입은 '무지개 평화 요정' 스타일이지만, '자기 주장'과 '도전 정신'을 기르면 진짜 '인생 평화 대사'가 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 자신의 꿈을 위해 노력하고, 필요할 때는 거절하는 법도 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 인기 많은 '평화의 아이콘'이 될 거예요. 선생님들과 친구들 모두에게 좋은 인상을 줄 수 있어요.
수업 시간에는 조용히 듣기만 하는 편일 거예요. 하지만 가끔은 용기를 내서 자신의 의견을 말해보세요. 여러분의 생각도 정말 중요해요!
친구들 사이에서 다툼이 일어나면 항상 중재자 역할을 하게 될 거예요. 하지만 때로는 옳고 그름을 분명히 말하는 것도 필요해요.
동아리 활동으로는 봉사 동아리나 또래 상담 동아리가 잘 어울릴 거예요. 여러분의 따뜻한 마음을 마음껏 발휘할 수 있을 거예요.
진로를 고민할 때는 사회복지사, 상담사, 간호사 같은 직업을 생각해보는 것도 좋아요. 여러분의 따뜻한 마음을 살릴 수 있는 직업이에요.`,
    human: `- 베프, 여자친구
정말 다정하고 배려심 넘치는 친구예요! 하지만 가끔은 너무 맞춰주기만 해서 오히려 답답할 수 있어요. 솔직한 대화를 나누고, 그 친구의 진짜 마음도 물어보세요.
- 선생님
이런 타입의 선생님은 정말 친절하고 이해심 많으실 거예요. 하지만 너무 관대해서 수업 분위기가 흐트러질 수 있어요. 선생님의 친절함에 감사하면서도, 규칙은 잘 지켜주세요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 따뜻할 거예요. 하지만 가끔은 결정을 내리지 못해 어려움을 겪을 수 있어요. 선배의 따뜻함을 배우면서도, 때로는 여러분이 의견을 제시해보세요.
- 친구, 후배
이 타입의 친구나 후배는 항상 남을 배려하고 도와주려고 할 거예요. 하지만 자기 주장을 하지 못해 손해 볼 수 있어요. 그들의 따뜻한 마음을 인정하면서도, 자기 의견을 말하는 법도 알려주세요.`,
  },

  CABBC: {
    type: "CABBC",
    category: "자상함이 지나친 유형",
    desc: `'천사표 따듯 챔피언' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '천사표 따뜻 챔피언' 타입이에요! 자상함으로 우주 최강을 자랑하는 친구죠.
여러분은 인정이 넘치고 친구들에게 싫은 소리를 잘 못하는, 그야말로 '착맨' 또는 '착녀'예요. 이런 여러분이 많다면 학교는 정말 천국이 될 거예요!
하지만 너무 착한 것이 때로는 문제가 될 수 있어요. 세상에는 여러분의 착한 마음을 이용하려는 사람들도 있다는 걸 알아야 해요. 무조건적으로 착하게 행동하는 것이 항상 좋은 결과로 이어지지는 않아요.
세상을 정확히 볼 수 있는 눈을 키우고, 가끔은 자신에게도 엄격해질 필요가 있어요. 그렇게 하면 여러분의 자상함은 더욱 빛을 발할 수 있고, 진정으로 가치 있는 관계를 만들어 갈 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '천사표 따듯 챔피언' 스타일이지만, '자기 주장'과 '현실 감각'을 기르면 진짜 '인생 힐링 마스터'가 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 자신을 위해 당당하게 말하고, 세상을 정확히 보는 눈을 키워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 모두의 "힐링 메이트"가 될 거예요. 친구들과 선생님들 모두에게 사랑받는 존재가 될 수 있어요.
수업 시간에는 조용히 듣기만 하는 편일 거예요. 하지만 가끔은 용기를 내서 자신의 의견을 말해보세요. 여러분의 생각도 정말 중요해요!
조별 과제를 할 때는 항상 남들을 배려하느라 자신의 일을 소홀히 할 수 있어요. 하지만 자신의 역할도 잘 해내는 게 진정한 배려랍니다.
동아리 활동으로는 봉사 동아리나 또래 상담 동아리가 잘 어울릴 거예요. 여러분의 따뜻한 마음을 마음껏 발휘할 수 있을 거예요.
진로를 고민할 때는 상담사, 사회복지사, 유치원 교사 같은 직업을 생각해보는 것도 좋아요. 여러분의 자상함을 살릴 수 있는 직업이에요.`,
    human: `- 베프, 여자친구
정말 귀엽고 순수한 친구예요! 착하고 순수한 마음을 가진 이 친구와 함께라면 매일매일이 행복할 거예요. 하지만 가끔은 이 친구의 의견도 물어보고 존중해주세요.
- 선생님
이런 타입의 선생님은 정말 따뜻하고 이해심 많으실 거예요. 학생들의 마음을 잘 헤아려주시겠죠. 하지만 너무 관대해서 수업이 산만해질 수 있어요. 선생님의 친절함에 감사하면서도, 수업 규칙은 잘 지켜주세요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 자상할 거예요. 하지만 후배들에게 엄격하지 않을 수 있어요. 선배의 따뜻함에 감사하면서도, 필요할 때는 조언을 구해보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 착하고 자상해요. 하지만 책임감이 부족할 수 있어요. 그들의 따뜻한 마음을 인정하면서도, 맡은 일은 꼭 끝까지 하도록 격려해주세요.`,
  },

  CABCA: {
    type: "CABCA",
    category: "나는 없고 너만 있는 유형",
    desc: `'너튜브 구독자' 타입`,
    sub: `학교의 숨은 배려 요정!`,
    char: `여러분은 진정한 '너튜브 구독자' 타입이에요! 인정, 배려, 의리의 3단 콤보를 가진 찐 성격 부자죠.
여러분은 마치 친구들을 위해 태어난 것처럼 살아가요. 손해 볼 걸 알면서도 "ㄴㄴ 괜찮아~"라고 말하고, 속으로는 "이건 좀 아닌데..." 싶어도 겉으로는 "ㅇㅋ"한다고 말해요.
친구가 상처받을까 봐 잘못을 지적하지 못하는 여러분은 진정한 '천사 비주얼'이에요. 하지만 혹시 친구들 눈밖에 날까 봐 그러는 건 아닌지 생각해봐야 해요.
여러분은 욕심도 별로 없고, 자신감도 좀 부족해 보일 수 있어요. 하지만 가끔은 여러분의 진짜 마음을 표현하고, 스스로에게 '힐링 타임'을 주는 것도 좋아요!`,
    result: `기억하세요! 이 타입은 '너튜브 구독자' 스타일이지만, '자존감'과 '자기표현 능력'을 기르면 진짜 '인생 배려 챔피언'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 자신의 의견을 말하고, 스스로를 위한 시간도 가져보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 모두의 '비타민'이 될 거예요. 하지만 자신감 부족이 가장 큰 고민이 될 수 있어요.
수업 시간에 발표를 하려면 엄청 긴장될 거예요. 하지만 용기를 내서 한 번씩 손을 들어보세요. 여러분의 의견도 정말 중요하답니다!
조별 과제를 할 때는 항상 남들 의견만 따르고 있진 않나요? 가끔은 여러분의 아이디어를 제안해보세요. 분명 멋진 생각들이 많을 거예요.
동아리 활동으로는 봉사 동아리나 또래 상담 동아리가 잘 어울릴 거예요. 여러분의 따뜻한 마음을 마음껏 발휘할 수 있을 거예요.
진로를 고민할 때는 상담사, 사회복지사, 간호사 같은 직업을 생각해보는 것도 좋아요. 여러분의 배려심을 살릴 수 있는 직업이에요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "힐링 데이"가 될 거예요. 하지만 너무 일방적으로 이 친구의 배려만 받지 말고, 서로 존중하는 관계를 만들어가세요.
- 선생님
이런 타입의 선생님은 정말 따뜻하고 이해심 많으실 거예요. 하지만 수업 진행에 어려움을 겪을 수 있어요. 선생님의 따뜻함에 감사하면서도, 수업에 적극적으로 참여해주세요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 친절할 거예요. 하지만 리더십이 부족할 수 있어요. 선배의 따뜻함을 배우면서도, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 학급 분위기를 부드럽게 만들어줄 거예요. 하지만 가끔은 그들의 의견도 물어보고 존중해주세요. 그들도 자신의 생각을 표현하는 연습이 필요해요.`,
  },

  CABCB: {
    type: "CABCB",
    category: "가부장 시대 착한 여성 유형",
    desc: `'조용한 학교 여신' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '조용한 학교 여신' 타입이에요! 마치 드라마 속 여주인공처럼 조용하고 배려심 넘치는 성격이죠.
여러분은 친구들과 가족을 위해 헌신하고, 자신의 즐거움은 뒤로 미루는 스타일이에요. 누구보다도 자상한 마음씨를 가졌고, 모두를 돌보고 싶어 하죠.
하지만 이런 성격 때문에 자신의 목소리를 내지 못하고, 결정을 내리기 어려워할 수 있어요. 때로는 자신에게도 '힐링 타임'을 주는 게 어떨까요?
여러분의 착한 성격은 정말 멋져요. 하지만 현실 세계에서는 가끔 자신의 의견도 말하고, 주도적으로 행동하는 법을 배워야 해요.`,
    result: `기억하세요! 이 타입은 '조용한 학교 여신' 스타일이지만, '자신감'과 '결단력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 자신의 의견을 말하고, 주도적으로 행동하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 모두의 '힐링 메이트'가 될 거예요. 하지만 자신감 부족이 가장 큰 고민이 될 수 있어요.
수업 시간에 발표를 하려면 엄청 긴장될 거예요. 하지만 용기를 내서 한 번씩 손을 들어보세요. 여러분의 의견도 정말 중요하답니다!
조별 과제를 할 때는 항상 뒤에서 묵묵히 일하고 있진 않나요? 가끔은 리더 역할에 도전해보세요. 분명 멋진 리더가 될 수 있을 거예요.
동아리 활동으로는 봉사 동아리나 또래 상담 동아리가 잘 어울릴 거예요. 여러분의 따뜻한 마음을 마음껏 발휘할 수 있을 거예요.
진로를 고민할 때는 선생님, 간호사, 사회복지사 같은 직업을 생각해보는 것도 좋아요. 여러분의 배려심을 살릴 수 있는 직업이에요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "힐링 데이"가 될 거예요. 하지만 너무 일방적으로 이 친구의 배려만 받지 말고, 서로의 의견을 존중하는 관계를 만들어가세요.
- 선생님
이런 타입의 선생님은 정말 따뜻하고 이해심 많으실 거예요. 하지만 결정을 내리기 어려워하실 수 있어요. 선생님의 따뜻함에 감사하면서도, 학생들이 적극적으로 의견을 제시해보는 것도 좋아요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 친절할 거예요. 하지만 리더십이 부족할 수 있어요. 선배의 따뜻함을 배우면서도, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 학급 분위기를 부드럽게 만들어줄 거예요. 하지만 경쟁이 필요한 상황에서는 어려움을 겪을 수 있어요. 그들의 장점을 인정하면서도, 때로는 건강한 경쟁을 통해 함께 성장하는 법을 알려주세요.`,
  },

  CABCC: {
    type: "CABCC",
    category: "흘러가는 대로 사는 유형",
    desc: `'방황하는 별똥별' 타입`,
    sub: `학교의 숨은 무한 가능성!`,
    char: `여러분은 진정한 '방황하는 별똥별' 타입이에요! 마치 밤하늘을 자유롭게 날아다니는 별똥별처럼 어디로 갈지 모르는 매력적인 성격이죠.
목표 설정이 어렵고, 책임감이 좀 부족할 수 있어요. 뭔가를 시작해도 어떻게 해야 할지 몰라 그냥 "플로우 타는" 스타일이에요.
따뜻하고 자상한 마음을 가졌지만, 가끔은 "내 맘대로" 행동할 때도 있어요. 이런 여러분의 매력에 주변 사람들이 헷갈릴 수 있답니다!
성공적인 학교생활을 위해서는 책임감, 판단력, 그리고 열정이 필요해요. 여러분은 충분히 멋진 사람이 될 수 있는 잠재력을 가졌어요!`,
    result: `기억하세요! 이 타입은 '방황하는 별똥별' 스타일이지만, '목표 설정 능력'과 '책임감'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 무한한 가능성을 믿어요. 자신만의 목표를 찾고, 그것을 향해 노력하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 진로 선택이나 과목 선택에 어려움을 겪을 수 있어요. 우선 자신이 정말 좋아하는 것이 뭔지 찾아보세요.
수업 시간에는 집중력이 부족할 수 있어요. 하지만 관심 있는 주제가 나오면 갑자기 눈이 반짝! 그 순간을 놓치지 마세요.
동아리 활동은 여러 가지를 경험해보는 게 좋아요. 봉사 동아리, 체육 동아리, 학술 동아리 등 다양한 활동을 통해 자신의 적성을 찾아보세요.
시험 기간에는 공부 계획을 세우기 어려울 수 있어요. 친구들과 함께 스터디 그룹을 만들어 서로 도와가며 공부해보는 건 어떨까요?
진로를 고민할 때는 다양한 직업을 탐색해보세요. 진로 상담 선생님과 상담을 받아보는 것도 좋은 방법이에요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "어드벤처"가 될 거예요! 하지만 가끔은 여러분이 방향을 제시해주고 결정을 도와줘야 할 수 있어요.
- 선생님
이런 타입의 선생님은 창의적이고 자유로운 수업을 할 수 있어요. 하지만 수업 진도나 계획이 불확실할 수 있죠. 학생들이 적극적으로 수업에 참여하고 의견을 제시하면 더 좋은 수업이 될 거예요.
- 선배
이 타입의 선배는 따뜻하고 자상할 거예요. 하지만 구체적인 조언을 해주기 어려울 수 있어요. 선배의 경험담을 들으면서, 여러분이 주도적으로 길을 찾아가보세요.
- 친구, 후배
이 타입의 친구나 후배는 창의적이고 재미있는 아이디어로 가득할 거예요. 하지만 실천력이 부족할 수 있어요. 그들의 아이디어를 존중하면서, 함께 계획을 세우고 실천하는 법을 알려주세요.`,
  },

  CACAA: {
    type: "CACAA",
    category: "부표 없이 유랑하는 유형",
    desc: `'유랑하는 구름' 타입`,
    sub: `학교의 숨은 몽상가!`,
    char: `여러분은 진정한 '유랑하는 구름' 타입이에요! 마치 하늘을 자유롭게 떠다니는 구름처럼 순수하고 따뜻한 마음을 가진 분위기 메이커죠.
친구들에게 항상 자상하고 관대해요. 누군가 실수해도 "괜찮아, 다음에 잘하면 돼!"라고 말해주는 따뜻한 마음의 소유자예요.
하지만 가끔은 너무 착해서 문제가 될 수 있어요. "눈치 제로" 모드로 살다 보면 다른 사람들에게 이용당할 수 있거든요.
귀엽고 깜찍한 면도 있지만, 때로는 눈치를 보며 주저하는 모습도 있어요. 여러분의 숨겨진 잠재력을 믿고 자신감을 가져보세요!`,
    result: `기억하세요! 이 타입은 '유랑하는 구름' 스타일이지만, '목표 의식'과 '현실 감각'을 기르면 진짜 '인생 아티스트'가 될 수 있어요!
    여러분의 따뜻한 마음과 창의력을 믿어요. 하지만 가끔은 현실을 직시하고, 책임감 있게 행동하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 논리적인 과목보다는 예술이나 창의력을 필요로 하는 과목에서 빛을 발할 수 있어요.
수학이나 과학 시간에는 집중하기 어려울 수 있어요. 하지만 음악, 미술, 문학 시간에는 여러분의 상상력이 폭발할 거예요!
동아리 활동으로는 예술 동아리나 봉사 동아리가 잘 어울릴 거예요. 여러분의 창의력과 따뜻한 마음을 마음껏 발휘해보세요.
시험 기간에는 계획을 세우고 지키는 게 어려울 수 있어요. 친구들과 함께 공부하면서 서로 도와주는 건 어떨까요?
진로를 고민할 때는 예술가, 디자이너, 작가, 상담사 같은 직업을 생각해보세요. 여러분의 창의력과 따뜻한 마음을 살릴 수 있는 직업이에요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "힐링 타임"이 될 거예요. 하지만 가끔은 여러분이 결정을 내려주고 방향을 제시해줘야 할 수 있어요.
- 선생님
이런 타입의 선생님은 정말 따뜻하고 이해심 많으실 거예요. 하지만 수업 진행이 불확실할 수 있어요. 학생들이 적극적으로 수업에 참여하고 의견을 제시하면 더 좋은 수업이 될 거예요.
- 선배
이 타입의 선배는 정말 자상하고 친절할 거예요. 하지만 리더십이 부족할 수 있어요. 선배의 따뜻함을 배우면서도, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 따뜻하고 창의적일 거예요. 하지만 현실적인 판단이 부족할 수 있어요. 그들의 아이디어를 존중하면서도, 함께 현실적인 계획을 세우는 법을 알려주세요.`,
  },

  CACAB: {
    type: "CACAB",
    category: "착한 사람의 표본인 유형",
    desc: `'천사 비주얼 꿀 보이스' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '천사 비주얼 꿀 보이스' 타입이에요! 세상이 여러분 같은 사람들로 가득하다면 학교는 매일이 "힐링 데이"가 될 거예요.
여러분은 항상 친구들을 먼저 생각하고, 점심 시간에 배가 고파도 친구가 밥을 못 먹었다면 주저 없이 도시락을 나눠줄 수 있는 "찐" 따뜻한 마음의 소유자예요.
때로는 재미있는 밈으로 친구들을 웃게 만들기도 하고, 좋아하는 아이돌 얘기로 신나게 수다 떨기도 하는 밸런스 "꿀잼" 친구예요.
하지만 가끔은 너무 착해서 문제가 될 수 있어요. "눈치 제로" 모드로 살다 보면 나쁜 친구들에게 이용당할 수 있거든요. 세상 물정을 좀 더 알아가는 것도 중요해요!`,
    result: `기억하세요! 이 타입은 '천사 비주얼 꿀 보이스' 스타일이지만, '현실 감각'과 '자기 주장'을 기르면 진짜 '인생 힐링 마스터'가 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 세상을 냉철하게 보고, 자신의 권리도 지킬 줄 아는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 모두의 "힐링 메이트"가 될 거예요. 선생님들과 친구들 모두에게 사랑받는 존재가 될 수 있어요.
수업 시간에는 열심히 듣지만, 발표하는 걸 부끄러워할 수 있어요. 하지만 용기를 내서 발표해보세요. 여러분의 의견도 정말 중요해요!
조별 과제를 할 때는 항상 남들을 배려하느라 자신의 일을 소홀히 할 수 있어요. 하지만 자신의 역할도 잘 해내는 게 진정한 배려랍니다.
동아리 활동으로는 봉사 동아리나 또래 상담 동아리가 잘 어울릴 거예요. 여러분의 따뜻한 마음을 마음껏 발휘할 수 있을 거예요.
진로를 고민할 때는 사회복지사, 상담사, 유치원 교사 같은 직업을 생각해보세요. 여러분의 따뜻한 마음을 살릴 수 있는 직업이에요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "힐링 타임"이 될 거예요. 하지만 가끔은 이 친구를 지켜줘야 할 때도 있어요. 세상에 대해 함께 배워가면서 서로를 보호해주세요.
- 선생님
이런 타입의 선생님은 정말 따뜻하고 이해심 많으실 거예요. 하지만 너무 관대해서 수업이 산만해질 수 있어요. 선생님의 친절함에 감사하면서도, 수업 규칙은 잘 지켜주세요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 따뜻할 거예요. 하지만 후배들을 이끄는 데 어려움을 겪을 수 있어요. 선배의 따뜻함에 감사하면서도, 필요할 때는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 착하고 따뜻해요. 하지만 가끔은 현실적인 판단이 필요할 때가 있어요. 그들의 따뜻한 마음을 인정하면서도, 세상을 현실적으로 보는 법도 함께 배워나가세요.`,
  },

  CACAC: {
    type: "CACAC",
    category: "천진난만 대책 없는 유형",
    desc: `'무지개 에너지 뿜뿜' 타입`,
    sub: `학교의 숨은 엔터테이너!`,
    char: `여러분은 진정한 '무지개 에너지 뿜뿜' 타입이에요! 여러 가지 매력이 공존하는 "멀티 플레이어"죠.
장점으로는 아이돌 막내처럼 귀엽고 천진난만해요. 친구들을 향한 배려심도 "만렙"이고, 호기심도 넘쳐요. 감정 표현도 풍부하고 열정적이라 주변 사람들을 즐겁게 만들어요.
하지만 가끔은 "내 맘대로" 행동하고 책임감이 부족할 때도 있어요. 어떤 친구들은 여러분을 보고 "인생의 텐션"이라고 할 수 있지만, 다른 친구들은 "좀 많이 오버하는데?"라고 생각할 수도 있어요.
이런 극과 극의 모습을 조금씩 조절하는 게 중요해요. 너무 튀는 부분은 살짝 낮추고, 부족한 부분은 조금 높이면 여러분은 진정한 "인생 주인공"이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '무지개 에너지 뿜뿜' 스타일이지만, '자기 관리 능력'과 '책임감'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 넘치는 에너지와 매력을 믿어요. 하지만 가끔은 그 에너지를 조절하고, 중요한 일에 집중하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "분위기 메이커"가 될 거예요. 하지만 가끔은 그 에너지를 조절하는 게 필요해요.
수업 시간에는 호기심 때문에 질문을 많이 할 수 있어요. 그건 정말 좋은 거예요! 하지만 다른 친구들의 학습을 방해하지 않도록 주의해야 해요.
동아리 활동으로는 연극부, 방송부, 댄스부 같은 곳이 딱이에요. 여러분의 끼와 재능을 마음껏 발산할 수 있을 거예요.
시험 기간에는 집중력이 부족할 수 있어요. 공부 계획을 세우고 친구들과 함께 스터디 그룹을 만들어보는 건 어떨까요?
진로를 고민할 때는 연예인, 유튜버, 엔터테이너 같은 직업을 생각해보세요. 여러분의 매력을 마음껏 발산할 수 있는 직업이에요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "축제"가 될 거예요! 하지만 가끔은 진지한 대화도 필요해요. 재미있게 놀면서도 서로의 미래에 대해 이야기를 나눠보세요.
- 선생님
이런 타입의 선생님은 수업을 정말 재미있게 하실 거예요. 하지만 가끔은 수업 내용이 산만해질 수 있어요. 선생님의 재미있는 수업을 즐기면서도, 중요한 내용은 꼭 메모해두세요.
- 선배
이 타입의 선배는 정말 재미있고 친근할 거예요. 하지만 책임감 있는 조언을 해주기 어려울 수 있어요. 선배와 즐겁게 지내면서도, 중요한 결정은 신중하게 생각해보세요.
- 친구, 후배
이 타입의 친구나 후배는 학교 생활을 즐겁게 만들어줄 거예요. 하지만 가끔은 그들의 행동 때문에 곤란해질 수 있어요. 함께 즐겁게 지내면서도, 때로는 서로에게 조언을 해주는 것도 좋아요.`,
  },

  CACBA: {
    type: "CACBA",
    category: "튀어 오르지 못하는 유형",
    desc: `'포근한 솜사탕' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '포근한 솜사탕' 타입이에요! 부드럽고 달콤하지만 가끔은 바람에 날려갈 것 같은 느낌이죠.
매사에 "고민 대마왕"이고 친구들 눈치를 많이 보는 편이에요. 자신의 생각은 숨기고 친구들을 따라가는 게 편하다고 느낄 수 있어요.
좋게 보면 따뜻하고 배려심 깊은 "천사 비주얼"이지만, 가끔은 이런 모습이 우유부단해 보일 수 있어요. 친구들의 비판이 두려워 자신의 의견을 말하지 못하고 있진 않나요?
하지만 기억하세요. 여러분도 충분히 멋진 사람이에요! 가끔은 "나는 내가 최고야!"라고 생각하며 당당하게 행동해보는 건 어떨까요? 그래야 여러분의 숨은 매력이 빛날 수 있어요.`,
    result: `기억하세요! 이 타입은 '포근한 솜사탕' 스타일이지만, '자신감'과 '결단력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 자신의 의견을 당당하게 말하고, 새로운 도전을 해보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "조용한 힐링 메이트"가 될 수 있어요. 하지만 자신감 부족이 가장 큰 고민이 될 수 있어요.
수업 시간에 발표를 하려면 엄청 긴장될 거예요. 하지만 용기를 내서 한 번씩 손을 들어보세요. 여러분의 의견도 정말 중요하답니다!
조별 과제를 할 때는 항상 남들 의견만 따르고 있진 않나요? 가끔은 여러분의 아이디어를 제안해보세요. 분명 멋진 생각들이 많을 거예요.
동아리 활동으로는 독서부나 미술부 같은 조용한 활동이 잘 어울릴 수 있어요. 하지만 가끔은 연극부나 토론부 같은 활동적인 동아리에 도전해보는 것도 좋아요!
진로를 고민할 때는 상담사, 작가, 디자이너 같은 직업을 생각해보세요. 하지만 여러분의 숨은 재능을 발견하기 위해 다양한 진로 체험을 해보는 것도 좋아요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 평화로운 시간을 보낼 수 있을 거예요. 하지만 가끔은 이 친구에게 용기를 불어넣어주세요. "넌 할 수 있어!"라고 응원해주면 더 멋진 모습을 보여줄 거예요.
- 선생님
이런 타입의 선생님은 정말 친절하고 이해심 많으실 거예요. 하지만 결정을 내리기 어려워하실 수 있어요. 학생들이 적극적으로 의견을 제시하고 수업에 참여하면 더 좋은 수업이 될 거예요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 따뜻할 거예요. 하지만 강한 조언을 해주기 어려울 수 있어요. 선배의 따뜻함에 감사하면서도, 때로는 여러분이 선배에게 용기를 줘보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 착하고 배려심 깊을 거예요. 하지만 자신의 의견을 말하지 못해 어려움을 겪을 수 있어요. 그들의 의견을 물어보고 격려해주세요. 함께 성장할 수 있을 거예요.`,
  },

  CACBB: {
    type: "CACBB",
    category: "아름다운 모습만 보는 유형",
    desc: `'핑크빛 안경 쓴 귀요미' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '핑크빛 안경 쓴 귀요미' 타입이에요! 세상을 아름답게만 보는 "천사 비주얼" 친구죠.
여러분은 정말 착하고 순수한 마음을 가졌어요. 친구들 도와주는 걸 좋아하고, 계산적이지 않아요. 하지만 가끔은 이런 성격 때문에 나쁜 친구들에게 이용당할 수 있어요.
세상이 동화책처럼 아름답다면 여러분은 완벽한 주인공이 될 거예요. 하지만 현실은 조금 다르죠. 그래서 자신과 친구들에게 조금은 엄격해질 필요가 있어요.
여러분에게 필요한 건 이거예요:
책임감 키우기 / 상황을 정확하게 판단하는 능력 / 다른 사람도 중요하지만, 나 자신이 더 중요하다는 걸 기억하기`,
    result: `기억하세요! 이 타입은 '핑크빛 안경 쓴 귀요미' 스타일이지만, '현실 감각'과 '자기 주장'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 세상을 있는 그대로 보고, 자신을 지키는 법도 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 모두의 "힐링 메이트"가 될 수 있어요. 하지만 경쟁이 필요한 상황에서는 어려움을 겪을 수 있어요.
수업 시간에는 열심히 듣지만, 자신의 의견을 말하는 걸 어려워할 수 있어요. 하지만 용기를 내서 발표해보세요. 여러분의 생각도 정말 중요해요!
조별 과제를 할 때는 항상 힘든 일을 도맡아 하고 있진 않나요? 가끔은 일을 나누고 자신의 몫만 책임지는 것도 중요해요.
동아리 활동으로는 봉사 동아리나 환경 동아리가 잘 어울릴 거예요. 하지만 가끔은 토론 동아리나 리더십 동아리에도 도전해보세요!
진로를 고민할 때는 사회복지사, 교사, 상담사 같은 직업을 생각해볼 수 있어요. 하지만 다양한 직업을 탐색해보는 것도 좋아요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "행복 바이러스"에 감염될 거예요! 하지만 가끔은 현실적인 조언도 해주세요. 함께 성장할 수 있을 거예요.
- 선생님
이런 타입의 선생님은 정말 따뜻하고 이해심 많으실 거예요. 하지만 가끔은 엄격함이 부족할 수 있어요. 선생님의 친절함에 감사하면서도, 스스로 규율을 지키는 연습을 해보세요.
- 선배
이 타입의 선배는 정말 잘 챙겨주고 따뜻할 거예요. 하지만 현실적인 조언을 해주기 어려울 수 있어요. 선배의 따뜻함을 배우면서도, 다른 선배들의 조언도 들어보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 착하고 순수해요. 하지만 현실적인 판단이 부족할 수 있어요. 그들의 좋은 마음을 인정하면서도, 함께 현실을 직시하는 법을 배워가세요.`,
  },

  CACBC: {
    type: "CACBC",
    category: "작은 무리의 우두머리 유형",
    desc: `'인싸력 만렙 리더' 타입`,
    sub: `학교의 숨은 카리스마 킹!`,
    char: `여러분은 진정한 '인싸력 만렙 리더' 타입이에요! 어떤 무리에 속하느냐에 따라 "찐" 리더가 될 수도 있고, 그냥 평범한 학생이 될 수도 있는 독특한 매력의 소유자죠.
좋은 점은 친구들을 잘 챙겨주고 자상하며 의리가 넘친다는 거예요. 하지만 가끔은 학교 규칙을 무시하고, 책임감이 부족하거나 다른 친구들을 무시하는 듯한 태도를 보일 수 있어요.
장점 때문에 주변에 친구들이 많이 모이고 인기도 좋을 거예요. 하지만 모이는 친구들의 특징도 중요해요. 여러분과 비슷한 성향의 친구들만 모여 '우물 안 개구리'가 되지 않도록 조심해야 해요.`,
    result: `기억하세요! 이 타입은 '인싸력 만렙 리더' 스타일이지만, '책임감'과 '배려심'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 리더십과 매력을 믿어요. 하지만 가끔은 규칙을 지키고, 다른 사람의 입장도 생각해보세요. 그러면 더 멋진 리더가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "분위기 메이커"가 될 수 있어요. 하지만 가끔은 그 에너지를 조절하는 게 필요해요.
수업 시간에는 친구들과 떠들기 쉬워요. 하지만 그러다 선생님께 혼날 수 있어요. 수업에 집중하고 친구들에게 좋은 영향을 주는 리더가 되어보는 건 어떨까요?
동아리 활동에서는 리더 역할을 맡기 좋아요. 하지만 책임감을 가지고 모든 부원들의 의견을 들어주는 것도 중요해요.
시험 기간에는 친구들과 함께 공부하는 스터디 그룹을 만들어보세요. 하지만 공부보다 잡담만 하지 않도록 주의해야 해요!
진로를 고민할 때는 정치인, 연예인, 유튜버 같은 직업을 생각해볼 수 있어요. 하지만 다양한 진로도 탐색해보세요.`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "축제"가 될 거예요! 하지만 가끔은 진지한 대화도 필요해요. 서로의 장단점을 인정하고 함께 성장하는 관계를 만들어보세요.
- 선생님
이런 타입의 선생님은 친근하고 자상하실 거예요. 하지만 가끔 수업 분위기가 산만해질 수 있어요. 선생님의 친절함에 감사하면서도, 수업 규칙은 잘 지켜주세요.
- 선배
이 타입의 선배는 잘 챙겨주고 재미있을 거예요. 하지만 가끔 문제를 일으킬 수 있어요. 선배의 좋은 점은 배우되, 잘못된 행동은 따라 하지 말아야 해요.
- 친구, 후배
이 타입의 친구나 후배는 매력적이고 인기가 많을 거예요. 하지만 그들의 행동이 항상 옳진 않아요. 좋은 점은 배우고, 잘못된 점은 서로 조언해주며 함께 성장하세요.`,
  },

  CACCA: {
    type: "CACCA",
    category: "희생적인 어머니 유형",
    desc: `'따뜻한 엄마손 도시락' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '따뜻한 엄마손 도시락' 타입이에요! 친구들과 가족을 위해서라면 뭐든 할 수 있는 "찐" 사랑꾼이죠.
여러분은 마치 다른 사람을 위해 태어난 것처럼 살아가요. 친구들을 비판하기보다는 항상 돌보고 싶어 하죠. 하지만 가끔은 친구들 눈치를 보느라 자신의 마음을 숨기고 있진 않나요?
요즘 같은 "월클" 시대에는 이런 성격이 좀 불리할 수 있어요. 열정적으로 꿈을 쫓는 친구들 눈에는 여러분이 "그저 그런" 사람으로 보일 수도 있거든요.
하지만 기억하세요. 여러분도 소중한 사람이에요! 가끔은 "나도 소중해!"라고 외치며 자신을 위한 시간을 가져보세요. 그래야 나쁜 친구들에게 이용당하지 않을 수 있어요.`,
    result: `기억하세요! 이 타입은 '따뜻한 엄마손 도시락' 스타일이지만, '자존감'과 '자기 주장'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 자신을 위한 시간을 갖고, 당당하게 자신의 의견을 말하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 모두의 "힐링 메이트"가 될 수 있어요. 하지만 자신감 부족이 가장 큰 고민이 될 수 있어요.
수업 시간에 발표를 하려면 엄청 긴장될 거예요. 하지만 용기를 내서 한 번씩 손을 들어보세요. 여러분의 의견도 정말 중요하답니다!
조별 과제를 할 때는 항상 힘든 일을 도맡아 하고 있진 않나요? 가끔은 일을 나누고 자신의 몫만 책임지는 것도 중요해요.
동아리 활동으로는 봉사 동아리나 또래 상담 동아리가 잘 어울릴 거예요. 여러분의 따뜻한 마음을 마음껏 발휘할 수 있을 거예요.
진로를 고민할 때는 상담사, 사회복지사, 간호사 같은 직업을 생각해볼 수 있어요. 하지만 다른 직업도 탐색해보세요. 여러분의 숨은 재능을 발견할 수 있을 거예요!`,
    human: `- 베프, 여자친구
이 친구가 세상 무대에서 빛날 수 있도록 응원해주세요. "넌 할 수 있어!"라고 격려해주면 더 멋진 모습을 보여줄 거예요.
- 선생님
이런 타입의 선생님은 정말 따뜻하고 헌신적이실 거예요. 선생님의 친절함을 존중하면서도, 선생님께 여러분의 생각도 말씀드려보세요.
- 선배
이 타입의 선배는 마치 엄마처럼 여러분을 챙겨줄 거예요. 가끔은 답답하게 느껴질 수 있지만, 선배의 따뜻한 마음을 존중하고 함께 성장해보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 따뜻하고 배려심 깊을 거예요. 그들의 마음을 소중히 여기면서도, 함께 세상을 헤쳐나갈 수 있는 힘을 길러주세요.`,
  },

  CACCB: {
    type: "CACCB",
    category: "사리분별이 약하고 그늘진 유형",
    desc: `'따뜻한 구름 뒤 숨은 보름달' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '따뜻한 구름 뒤 숨은 보름달' 타입이에요! 자상함이 최고의 무기지만, 가끔은 그게 약점이 될 수도 있어요.
여러분은 정말 착하고 친구들을 비판할 줄 모르는 "천사 비주얼" 친구예요. 하지만 그 때문에 결정을 내리기 어려워하고, 나쁜 친구들에게 이용당할 수 있어요.
세상에는 자기 이익만 추구하는 "핵인싸 wannabe"들이 많아요. 그런 친구들 사이에서 살아남으려면 세상을 정확히 볼 수 있는 통찰력을 키워야 해요.
자신에게 조금 더 엄격해지면서도, 가끔은 감정에 솔직해지는 연습을 해보세요. 그러면 어두운 기분도 조금씩 나아질 거예요!`,
    result: `기억하세요! 이 타입은 '따뜻한 구름 뒤 숨은 보름달' 스타일이지만, '자신감'과 '결단력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음을 믿어요. 하지만 가끔은 세상을 냉철하게 보고, 자신의 의견을 당당히 말하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 힐링 요정"이 될 수 있어요. 하지만 자신감 부족이 가장 큰 고민이 될 수 있어요.
수업 시간에 발표를 하려면 엄청 긴장될 거예요. 하지만 용기를 내서 한 번씩 손을 들어보세요. 여러분의 의견도 정말 중요하답니다!
조별 과제를 할 때는 항상 남들 의견만 따르고 있진 않나요? 가끔은 여러분의 아이디어를 제안해보세요. 분명 멋진 생각들이 많을 거예요.
동아리 활동으로는 독서부나 미술부 같은 조용한 활동이 잘 어울릴 수 있어요. 하지만 가끔은 토론부나 리더십 동아리에도 도전해보세요!
진로를 고민할 때는 상담사, 사회복지사, 간호사 같은 직업을 생각해볼 수 있어요. 하지만 다른 직업도 탐색해보세요. 여러분의 숨은 재능을 발견할 수 있을 거예요!`,
    human: `- 베프, 여자친구
이 친구의 자상함만으로는 오래 함께하기 어려울 수 있어요. 하지만 여러분이 이 친구의 "등대"가 되어 함께 성장한다면 정말 멋진 관계가 될 수 있어요!
- 선생님
이런 타입의 선생님은 정말 따뜻하고 이해심 많으실 거예요. 하지만 수업 진행에 어려움을 겪을 수 있어요. 선생님의 따뜻함에 감사하면서도, 수업에 적극적으로 참여해주세요.
- 선배
이 타입의 선배는 인간적으로 정말 좋은 선배일 거예요. 하지만 여러분을 이끌어줄 리더십은 부족할 수 있어요. 선배의 따뜻함을 배우면서도, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 착하고 자상해요. 하지만 학교생활에 적응하기 어려워할 수 있어요. 그들의 따뜻한 마음을 인정하면서도, 함께 현실적인 목표를 세우고 도전해보세요.`,
  },

  CACCC: {
    type: "CACCC",
    category: "아이러니한 삶을 사는 유형",
    desc: `'4차원 매력 폭발' 타입`,
    sub: `학교의 숨은 카오스 메이커!`,
    char: `여러분은 진정한 '4차원 매력 폭발' 타입이에요! 세상에 단 한 명뿐인 특별한 존재죠.
학교생활에 필요한 규칙 지키기, 책임감, 판단력 같은 건 여러분에게 좀 어려울 수 있어요. 하지만 그런 여러분이 오히려 친구들을 챙기고, 조언하려고 하는 모습이 참 아이러니해요.
여러분의 행동이 가끔은 "대체 뭐지?"라는 생각이 들게 할 수 있어요. 하지만 그게 바로 여러분만의 매력이에요!
지금 당장은 여러분의 독특한 매력을 조금씩 다듬어가는 게 중요해요. 규칙, 책임감, 판단력 같은 걸 조금씩 익혀가면 어떨까요?`,
    result: `기억하세요! 이 타입은 '4차원 매력 폭발' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 매력을 믿어요. 하지만 가끔은 현실적인 목표를 세우고, 그것을 이루기 위해 노력하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "4차원의 아이돌"이 될 수 있어요. 하지만 그만큼 적응하기 어려울 수도 있어요.
수업 시간에 집중하기 어려울 수 있어요. 하지만 자신만의 독특한 방식으로 공부해보는 건 어떨까요? 예를 들어, 랩으로 외우거나 그림으로 정리해보는 거예요.
조별 과제를 할 때는 여러분의 독특한 아이디어가 빛을 발할 수 있어요. 하지만 친구들과 의견을 조율하는 것도 잊지 마세요.
동아리 활동으로는 창의력을 마음껏 발휘할 수 있는 미술부나 연극부가 좋을 거예요. 여러분의 독특한 생각을 표현해보세요!
진로를 고민할 때는 아티스트, 크리에이터, 발명가 같은 직업을 생각해볼 수 있어요. 하지만 다양한 진로도 탐색해보세요. 여러분의 숨은 재능을 발견할 수 있을 거예요!`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "놀이공원"이 될 거예요! 하지만 현실적인 문제도 함께 해결해나가야 해요. 서로의 장단점을 이해하고 보완해주는 관계를 만들어보세요.
- 선생님
이런 타입의 선생님은 정말 드물어요. 만약 계시다면, 수업이 매우 독특하고 재미있을 거예요. 하지만 체계적이지 않을 수 있어요. 선생님의 창의력을 배우면서도, 스스로 공부 계획을 세워보세요.
- 선배
이 타입의 선배를 만나기는 쉽지 않아요. 만약 있다면, 매우 독특한 조언을 해줄 거예요. 재미있게 들으면서도, 다른 선배들의 현실적인 조언도 함께 참고해보세요.
- 친구, 후배
이 타입의 친구나 후배는 학교생활을 정말 재미있게 만들어줄 거예요. 하지만 규칙을 지키거나 과제를 제시간에 하는 데 어려움을 겪을 수 있어요. 그들의 창의력을 인정하면서도, 함께 계획을 세우고 지키는 연습을 해보세요.`,
  },

  CBAAA: {
    type: "CBAAA",
    category: "자유업에만 적합한 유형",
    desc: `'자유로운 영혼의 모범생 워너비' 타입`,
    sub: `학교의 숨은 카오스 메이커!`,
    char: `여러분은 진정한 '자유로운 영혼의 모범생 워너비' 타입이에요! YOLO 정신과 모범생 사이에서 갈등하는 독특한 매력의 소유자죠.
여러분은 자유롭게 살고 싶지만, 동시에 학교에서 인정받고 싶어 해요. 이 두 가지 마음이 충돌해서 가끔 혼란스러울 수 있어요.
공부나 과제에 대해서는 좀 느슨한 태도를 가지고 있어요. 시험 성적이 좋으면 "대박! 운이 좋았네~"라고 생각하죠.
이런 성격은 유튜버나 프리랜서 같은 자유로운 직업에는 딱이에요. 하지만 학교나 회사 같은 조직에서는 좀 어려움을 겪을 수 있어요.
만약 규칙을 지키는 것에 조금 더 신경 쓴다면, 여러분의 재능을 더 잘 발휘할 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '자유로운 영혼의 모범생 워너비' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자유로운 영혼을 믿어요. 하지만 가끔은 규칙을 지키고, 책임감 있게 행동하는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "자유로운 영혼"으로 불릴 수 있어요. 하지만 그만큼 적응하기 어려울 수도 있어요.
수업 시간에 집중하기 어려울 수 있어요. 하지만 자신만의 독특한 방식으로 공부해보는 건 어떨까요? 예를 들어, 노래로 외우거나 만화로 정리해보는 거예요.
조별 과제를 할 때는 여러분의 창의적인 아이디어가 빛을 발할 수 있어요. 하지만 마감 시간을 지키는 것도 잊지 마세요!
동아리 활동으로는 밴드부나 미술부 같은 창의적인 활동이 좋을 거예요. 여러분의 자유로운 영혼을 마음껏 표현해보세요!
진로를 고민할 때는 유튜버, 아티스트, 작가 같은 자유로운 직업을 생각해볼 수 있어요. 하지만 다양한 진로도 탐색해보세요. 여러분의 숨은 재능을 발견할 수 있을 거예요!`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "어드벤처"가 될 거예요! 하지만 현실적인 문제도 함께 해결해나가야 해요. 서로의 장단점을 이해하고 보완해주는 관계를 만들어보세요.
- 선생님
이런 타입의 선생님은 수업을 정말 재미있게 하실 거예요. 하지만 숙제 검사나 시험 채점이 불규칙할 수 있어요. 선생님의 창의적인 수업을 즐기면서도, 스스로 공부 계획을 세워보세요.
- 선배
이 타입의 선배는 정말 재미있고 멋져 보일 거예요. 하지만 학교생활에 대한 조언은 조금 부족할 수 있어요. 선배의 자유로운 정신은 배우되, 다른 선배들의 조언도 함께 들어보세요.
- 친구, 후배
이 타입의 친구나 후배는 학교생활을 정말 재미있게 만들어줄 거예요. 하지만 규칙을 지키거나 과제를 제시간에 하는 데 어려움을 겪을 수 있어요. 그들의 창의력을 인정하면서도, 함께 계획을 세우고 지키는 연습을 해보세요.`,
  },

  CBAAB: {
    type: "CBAAB",
    category: "인생은 즐기는 것이라는 유형",
    desc: `'인생은 롤러코스터' 타입`,
    sub: `학교의 숨은 축제 기획자!`,
    char: `여러분은 진정한 '인생은 롤러코스터' 타입이에요! YOLO 정신의 끝판왕, 즐거움을 아는 진정한 "인싸"죠.
여러분이 가장 행복할 때는? 바로 아무 걱정 없이 빈둥거리거나 하고 싶은 대로 할 때예요. 그럴 때 "인생 레전드!"라고 느끼죠.
1등이나 인기? 그런 건 별로 관심 없어요. 대신 한 번뿐인 인생을 제대로 즐기는 게 더 중요하다고 생각해요.
공부는 그저 용돈을 벌기 위한 수단일 뿐이에요. 하지만 재미있는 취미를 발견하면? 그때는 완전 "몰입의 신"이 되어버려요!`,
    result: `기억하세요! 이 타입은 '인생은 롤러코스터' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 즐거움을 아는 센스를 믿어요. 하지만 가끔은 미래를 위해 조금 노력하는 것도 필요해요. 그러면 더 멋진 인생의 롤러코스터를 탈 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "흥신소" 역할을 할 수 있어요. 모든 즐거운 일의 중심에 있을 거예요!
수업 시간에는 조금 지루할 수 있어요. 하지만 재미있는 선생님을 만나면 갑자기 그 과목의 "덕후"가 될 수도 있어요.
조별 과제를 할 때는 여러분의 창의력이 빛을 발할 거예요. 특히 재미있는 아이디어로 과제를 "예능"으로 만들 수 있어요!
동아리 활동으로는 밴드부, 방송부, 축제준비위원회 같은 곳이 딱이에요. 여러분의 끼와 재능을 마음껏 발산할 수 있을 거예요.
진로를 고민할 때는 연예인, 유튜버, 여행 가이드 같은 직업을 생각해볼 수 있어요. 하지만 다른 진로도 탐색해보세요. 예상치 못한 곳에서 재능을 발견할 수 있어요!`,
    human: `- 베프, 여자친구
이 친구와 함께라면 매일매일이 "페스티벌"이 될 거예요! 하지만 가끔은 진지한 대화도 필요해요. 함께 즐기면서도 서로의 미래를 응원해주는 관계를 만들어보세요.
- 선생님
이런 타입의 선생님은 수업을 정말 재미있게 하실 거예요. 하지만 시험이나 숙제에는 별로 관심이 없으실 수 있어요. 선생님의 재미있는 수업을 즐기면서도, 스스로 공부 계획을 세워보세요.
- 선배
이 타입의 선배는 학교생활의 "꿀팁"을 많이 알려줄 거예요. 공부보다는 어떻게 즐겁게 지낼 수 있는지에 대해서요. 선배의 조언을 들으면서도, 자신만의 균형을 찾아보세요.
- 친구, 후배
이 타입의 친구나 후배는 학교 생활을 정말 재미있게 만들어줄 거예요. 축제나 이벤트 기획을 맡기면 대박 날 거예요! 하지만 함께 공부할 때는 서로 집중할 수 있게 도와주세요.`,
  },

  CBAAC: {
    type: "CBAAC",
    category: "새로운 유토피아를 찾는 유형",
    desc: `'뉴틀리언 드림 챔피언' 타입`,
    sub: `학교의 숨은 혁명가!`,
    char: `여러분은 진정한 '뉴틀리언 드림 챔피언' 타입이에요! 기존의 룰을 벗어나 자유롭게 살고 싶어 하는 "진정한 반항아"죠.
여러분은 학교 규칙이나 사회 질서 같은 건 별로 중요하지 않다고 생각해요. 대신 자신의 욕구와 꿈을 얼마나 이룰 수 있는지가 중요하다고 믿죠.
1등이나 인기? 그런 건 "노잼"이라고 생각해요. 인내, 타협, 배려 같은 것도 그다지 필요 없다고 봐요.
자신만의 가치관으로 판단하고 행동하는 능력은 정말 뛰어나요. 하지만 이런 생각 때문에 가끔 외로울 수 있어요.
만약 여러분에게 특별한 재능이 있다면, 틀에 박히지 않은 생각으로 대단한 일을 해낼 수 있어요. 하지만 그렇지 않으면 어려움을 겪을 수도 있어요.`,
    result: `기억하세요! 이 타입은 '뉴틀리언 드림 챔피언' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 혁신적인 생각을 믿어요. 하지만 가끔은 현실적인 목표를 세우고, 다른 사람들과 협력하는 법도 배워보세요. 그러면 정말로 세상을 바꾸는 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "특이한 아이"로 불릴 수 있어요. 하지만 그게 바로 여러분만의 매력이에요!
수업 시간에는 선생님 말씀보다 자신의 생각에 더 집중할 수 있어요. 하지만 그 생각이 혁신적인 아이디어로 이어질 수 있어요.
조별 과제를 할 때는 남들과 다른 접근 방식으로 놀라운 결과를 낼 수 있어요. 하지만 팀워크도 중요하다는 걸 기억하세요.
동아리 활동으로는 토론부나 학생회 같은 곳이 좋을 수 있어요. 여러분의 혁신적인 아이디어로 학교를 변화시킬 수 있을 거예요.
진로를 고민할 때는 스타트업 창업자, 예술가, 철학자 같은 직업을 생각해볼 수 있어요. 틀에 박히지 않은 일을 찾아보세요!`,
    human: `- 베프, 여자친구
이 친구와의 관계는 정말 특별할 거예요. 하지만 가끔은 이용당하고 있다고 느낄 수 있어요. 서로의 생각을 존중하면서도 건강한 경계를 만들어보세요.
- 선생님
이런 타입의 선생님은 아주 독특한 수업을 하실 거예요. 하지만 시험이나 숙제에는 별로 관심이 없으실 수 있어요. 선생님의 창의적인 생각을 배우면서도, 기본적인 학습은 스스로 챙기세요.
- 선배
이 타입의 선배는 자신의 이익만 생각할 수 있어요. 선배의 독특한 시각을 배우되, 무조건 따르지는 말고 자신의 판단도 함께 해보세요.
- 친구, 후배
이 타입의 친구나 후배는 매우 독특하고 창의적일 거예요. 하지만 협동이 필요한 일에서는 어려움을 겪을 수 있어요. 그들의 아이디어를 존중하면서도, 함께 일하는 법을 알려주세요.`,
  },

  CBABA: {
    type: "CBABA",
    category: "무사안일한 삶을 찾는 유형",
    desc: `'슈퍼 카멜레온' 타입`,
    sub: `학교의 숨은 생존 전문가!`,
    char: `여러분은 진정한 '슈퍼 카멜레온' 타입이에요! 어떤 상황에서도 빠르게 적응하는 '진짜' 생존 전문가예요.
큰 꿈이나 정의감에 대해 크게 관심은 없고, 대신 자신에게 피해가 오지 않도록 하는 것이 우선이에요. 여러분은 주변 사람들의 기분을 읽는 뛰어난 능력을 가지고 있어요. 친구들의 표정이나 말투만 봐도 그들의 생각을 거의 알 수 있을 정도죠.
머리도 좋고 눈치도 빠르지만, 이런 능력을 자신의 꿈을 이루는 데 사용하기보다는 편하고 안전하게 살아가는 데 사용하고 있어요. 그래서 친구들과 잘 지내고 문제도 잘 일으키지 않지만, 그 대가로 자신만의 색깔을 잃어버릴 위험이 있어요.
하지만 이런 태도를 조금만 바꿔보세요. 그럼 여러분은 정말 멋진 사람이 될 수 있어요! 자신만의 열정과 꿈을 조금 더 탐구하고, 그 능력을 의미 있는 목표에 활용한다면 더 큰 성장과 만족을 느낄 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '슈퍼 카멜레온' 스타일이지만, '자기 주장'과 '목표 설정 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 적응력을 믿어요. 하지만 가끔은 자신의 의견을 말하고, 꿈을 향해 도전하는 법도 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "인간 관계의 달인"으로 불릴 수 있어요. 어떤 친구들과도 잘 지낼 수 있거든요.
수업 시간에는 선생님 눈치를 잘 봐서 혼나는 일이 거의 없을 거예요. 하지만 적극적으로 참여하지 않아 실력이 늘지 않을 수 있어요.
조별 과제를 할 때는 갈등을 잘 해결할 수 있어요. 하지만 자신의 의견을 말하기보다는 다른 친구들 의견에 맞춰주는 경향이 있어요.
동아리 활동으로는 봉사 동아리나 또래 상담 동아리가 잘 어울릴 거예요. 하지만 가끔은 리더십 동아리에도 도전해보세요!
진로를 고민할 때는 외교관, 상담사, 서비스업 같은 직업을 생각해볼 수 있어요. 하지만 다른 진로도 탐색해보세요. 숨겨진 재능을 발견할 수 있을 거예요!`,
    human: `- 베프, 여자친구
이 친구와 함께라면 평화로운 관계를 유지할 수 있어요. 하지만 가끔은 이 친구의 진짜 마음을 물어보세요. 항상 맞춰주기만 하는 건 건강한 관계가 아니에요.
- 선생님
이런 타입의 선생님은 학생들과 잘 지내실 거예요. 하지만 중요한 결정을 미루실 수 있어요. 선생님의 친절함에 감사하면서도, 필요할 때는 학생들이 의견을 제시해보세요.
- 선배
이 타입의 선배는 어떤 상황에서도 도망갈 구멍을 만들어두는 타입이에요. 선배의 처세술은 배우되, 책임감 있게 행동하는 법도 스스로 익혀보세요.
- 친구, 후배
이 타입의 친구나 후배는 항상 "알겠습니다", "노력해보겠습니다"라고 말할 거예요. 하지만 그들의 진짜 생각을 물어보고, 자신의 의견을 말하도록 격려해주세요.`,
  },

  CBABB: {
    type: "CBABB",
    category: "기능이 부실한 컴퓨터 유형",
    desc: `'천재적 프로 빈둥러' 타입`,
    sub: `학교의 숨은 아인슈타인!`,
    char: `여러분은 진정한 '천재적 프로 빈둥러' 타입이에요! 머리는 좋지만 의지력은 "오프라인" 상태인 친구죠.
수학 문제를 푸는 건 식은 죽 먹기지만, 그걸 실천하려는 의욕은 "로딩 중"인 상태예요. 결국 아무것도 안 하고 빈둥거리게 되는 경우가 많죠.
느슨하고 책임감이 부족한 것만 빼면 완벽해요. 특히 머리가 좋고 이해력이 뛰어나서 더 아쉽죠.
하지만 이 '의지력 부족'은 쉽게 고치기 어려운 부분이에요. 그래서 이 하나 때문에 인생이 꼬일 수 있다는 걸 알아두세요.
물론 언젠가 대박을 터뜨릴 수도 있어요. 하지만 그러려면 게으른 습관을 버리는 게 제일 중요해요!`,
    result: `기억하세요! 이 타입은 '천재적 프로 빈둥러' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 두뇌를 믿어요. 하지만 그 머리를 실제로 사용하는 습관을 들이는 게 중요해요. 그러면 정말 대단한 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "잠재적 수석" 타입이 될 수 있어요. 하지만 그 잠재력을 발휘하지 못하고 있죠.
수업 시간에는 모든 걸 이해하지만, 숙제는 안 해가고 시험 공부는 미루다 벼락치기하는 스타일이에요.
조별 과제를 할 때는 아이디어는 뛰어나지만, 실제로 행동으로 옮기는 건 다른 친구들에게 미루게 돼요.
동아리 활동으로는 과학부나 컴퓨터부가 잘 어울릴 수 있어요. 하지만 꾸준히 참여하는 게 중요해요!
진로를 고민할 때는 연구원, 프로그래머, 작가 같은 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 노력이 필요하다는 걸 잊지 마세요!`,
    human: `- 베프, 여자친구
이 친구와 함께 있으면 재미있는 대화를 나눌 수 있어요. 하지만 실제로 뭔가를 하려고 할 때는 여러분이 주도해야 할 거예요.
- 선생님
이런 타입의 선생님은 설명은 정말 잘하시지만, 숙제 검사나 시험 채점은 미루실 수 있어요. 선생님의 지식을 배우면서도, 스스로 공부 계획을 세워보세요.
- 선배
이 타입의 선배는 계획 세우는 건 정말 잘해요. 하지만 실천은 잘 안 하죠. 선배의 아이디어는 배우되, 실천하는 건 여러분이 해보세요.
- 친구, 후배
이 타입의 친구나 후배는 아이디어가 넘치지만 실천력이 부족해요. 함께 공부할 때는 목표를 정하고 서로 체크해주는 게 좋아요.`,
  },

  CBABC: {
    type: "CBABC",
    category: "화재 감시원 유형",
    desc: `'주의 끔찍이 관찰왕' 타입`,
    sub: `학교의 숨은 초고속 카메라!`,
    char: `여러분은 진정한 '주의 끔찍이 관찰왕' 타입이에요! 상황 파악 능력이 "찐"이고, 정보 처리 속도는 "광속"이죠.
마음도 따뜻하고 감정도 안정적이에요. 다만 가끔 친구들을 배려하는 걸 잊어버릴 수 있어요.
문제는 어려움이 생기면 바로 "도망가고 싶어" 모드로 바뀐다는 거예요. 인내심도 좀 부족해서 금방 포기해버리죠.
학교 운동회에서 빨리 달리기는 잘하지만, 마라톤은 힘들어하는 친구 같아요.
책임감, 인내심, 배려심을 기르지 않으면 나중에 큰 문제가 생길 수 있어요. 이 부분을 개선하면 정말 대단한 사람이 될 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '주의 끔찍이 관찰왕' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 빠른 판단력을 믿어요. 하지만 끈기와 책임감도 함께 기르는 게 중요해요. 그러면 정말 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "초고속 정보통" 역할을 할 수 있어요. 선생님의 설명을 제일 빨리 이해하고, 친구들 사이의 소문도 제일 먼저 알죠.
수업 시간에는 새로운 내용을 빨리 이해하지만, 어려운 문제가 나오면 쉽게 포기할 수 있어요. 끝까지 도전해보는 연습을 해보세요!
조별 과제를 할 때는 아이디어를 잘 내고 계획도 잘 세워요. 하지만 실제로 실행할 때는 힘들어할 수 있어요. 친구들과 함께 끝까지 해내는 연습을 해보세요.
동아리 활동으로는 신문부나 방송부가 잘 어울릴 거예요. 여러분의 빠른 정보 처리 능력을 마음껏 발휘할 수 있을 거예요!
진로를 고민할 때는 기자, 분석가, 컨설턴트 같은 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 끈기가 필요하다는 걸 기억하세요!`,
    human: `- 베프, 여자친구
이 친구는 평소에는 믿음직하지만, 중요한 순간에 도망갈 수 있어요. 함께 계획을 세우고, 실천할 때는 여러분이 리더 역할을 해보세요.
- 선생님
이런 타입의 선생님은 설명은 정말 잘하시지만, 학생들이 어려워할 때 끝까지 도와주지 못하실 수 있어요. 선생님의 지식을 배우면서도, 어려움이 있을 때는 다른 선생님께도 도움을 요청해보세요.
- 선배
이 타입의 선배는 조언은 잘 해주지만, 실제로 도와주긴 어려워할 수 있어요. 선배의 조언을 듣되, 실천은 여러분이 하세요.
- 친구, 후배
이 타입의 친구나 후배는 아이디어가 넘치지만 끈기가 부족할 수 있어요. "너의 아이디어니까 끝까지 해보자!"라고 격려해주세요.`,
  },

  CBACA: {
    type: "CBACA",
    category: "개미처럼 일에 열심인 유형",
    desc: `'열정 만렙 개미' 타입`,
    sub: `학교의 숨은 열공 챔피언!`,
    char: `여러분은 진정한 '열정 만렙 개미' 타입이에요! 놀거나 즐기는 것보다 열심히 하는 게 제일 좋은 친구죠.
인기나 권력? 그런 건 별로 관심 없어요. 대신 학교나 동아리에서 열심히 활동하는 게 제일 행복해요.
어느 학교든 이런 학생이 있으면 정말 좋아할 거예요. 하지만 공부에만 너무 빠져서 친구들과 놀 시간을 못 가질 수도 있어요.
가끔은 너무 열심히 하느라 건강을 해칠 수도 있으니 조심해야 해요. 또, 비판적으로 생각하는 능력도 키워보는 게 좋아요.
자신만의 즐거움도 중요하니까, 가끔은 휴식을 취하고 좋아하는 걸 하는 시간도 가져보세요!`,
    result: `기억하세요! 이 타입은 '열정 만렙 개미' 스타일이지만, '균형 잡힌 생활'과 '자기 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 열정과 성실함을 믿어요. 하지만 가끔은 쉬어가고, 친구들과 즐거운 시간도 보내는 게 중요해요. 그러면 더 행복하고 건강한 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "열공 챔피언"으로 불릴 수 있어요. 어떤 과목이든 열심히 공부하니까요.
수업 시간에는 항상 집중하고, 숙제도 꼬박꼬박 해가요. 하지만 가끔은 너무 열심히 해서 스트레스 받을 수 있어요.
조별 과제를 할 때는 책임감 있게 자신의 몫을 다해요. 하지만 다른 친구들의 의견도 들어보는 게 중요해요.
동아리 활동에서도 열심히 참여해요. 하지만 가끔은 다른 동아리 친구들과 어울리는 시간도 가져보세요.
진로를 고민할 때는 연구원, 의사, 교사 같은 전문직을 생각해볼 수 있어요. 열정을 쏟을 수 있는 직업이라면 뭐든 좋아요!`,
    human: `- 베프, 여자친구
이 친구는 정말 성실하고 믿음직해요. 하지만 데이트나 놀 시간이 부족할 수 있어요. 함께 공부하면서도, 가끔은 재미있게 노는 시간도 만들어보세요.
- 선생님
이런 타입의 선생님은 정말 열심히 가르치시고 학생들을 잘 챙겨주실 거예요. 선생님의 노력에 감사하면서도, 가끔 선생님께 쉬는 시간도 필요하다고 말씀드려보세요.
- 선배
이 타입의 선배는 공부나 동아리 활동에 대해 좋은 조언을 해줄 거예요. 하지만 너무 열심히 하라고 강요할 수 있어요. 선배의 조언을 들으면서도, 자신만의 페이스를 유지하세요.
- 친구, 후배
이 타입의 친구나 후배는 정말 열심히 하는 모습에 감동받을 거예요. 하지만 너무 부담 주지 말고, 가끔은 함께 쉬는 시간도 가져보세요.`,
  },

  CBACB: {
    type: "CBACB",
    category: "인생을 반듯하게 살아가는 유형",
    desc: `'무난무난 평화로운 일상' 타입`,
    sub: `학교의 숨은 안정감 요정!`,
    char: `여러분은 진정한 '무난무난 평화로운 일상' 타입이에요! 친구들을 비판하거나 간섭하지 않고, "좋은 게 좋은 거지~"라는 마인드로 살아가는 친구죠.
여러분은 정말 반듯하고 무난한 성격이에요. 그래서 친구들과 사이좋게 지낼 수 있고, 선생님들도 좋아하실 거예요.
특별히 대단한 꿈이나 목표가 없어도 괜찮아요. 평화롭고 안정적인 삶을 사는 것, 그게 바로 여러분의 목표니까요!
어떤 사람들은 이런 삶이 재미없다고 할 수 있지만, 여러분에겐 이게 행복한 거예요. 안전하고 편안하게 살아가는 것, 그것도 멋진 삶이에요!`,
    result: `기억하세요! 이 타입은 '무난무난 평화로운 일상' 스타일이지만, '자기 발전'과 '새로운 도전 정신'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 안정감과 성실함을 믿어요. 하지만 가끔은 새로운 것에 도전해보고, 자신의 한계를 넓혀보는 것도 좋아요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "평화로운 학생"으로 불릴 수 있어요. 문제도 일으키지 않고, 조용히 자신의 할 일을 하니까요.
수업 시간에는 열심히 듣고, 숙제도 꼬박꼬박 해가요. 특별히 뛰어나지 않아도, 꾸준히 노력하는 모습이 멋져요.
조별 과제를 할 때는 맡은 역할을 책임감 있게 해내요. 다른 친구들과 갈등 없이 잘 협력하는 모습이 장점이에요.
동아리 활동에서도 꾸준히 참여하고 맡은 일을 잘 해내요. 화려하지 않아도, 믿음직한 동아리원이 될 수 있어요.
진로를 고민할 때는 선생님, 공무원, 회사원 같은 안정적인 직업을 생각해볼 수 있어요. 꾸준하고 성실한 여러분의 모습이 빛을 발할 수 있는 직업이에요!`,
    human: `- 베프, 여자친구
이 친구와 함께 있으면 편안하고 안정감이 느껴질 거예요. 특별한 이벤트는 없어도, 일상 속 작은 행복을 함께 나눌 수 있어요.
- 선생님
이런 타입의 선생님은 학생들을 차분하고 공평하게 대해주실 거예요. 새로운 시도는 좀 어려워하실 수 있지만, 안정적으로 가르쳐주실 거예요.
- 선배
이 타입의 선배는 크게 튀는 행동은 안 하지만, 꾸준히 학교생활을 잘 해나가는 모습을 보여줄 거예요. 그런 모습을 배워보세요.
- 친구, 후배
이 타입의 친구나 후배는 함께 있으면 편안해요. 특별한 문제를 일으키지 않고, 좋은 관계를 유지할 수 있을 거예요.`,
  },

  CBACC: {
    type: "CBACC",
    category: "머리는 좋으나 무기력한 유형",
    desc: `'천재적 슬로우 모션' 타입`,
    sub: `학교의 숨은 아인슈타인!`,
    char: `여러분은 진정한 '천재적 슬로우 모션' 타입이에요! 말은 적지만 머릿속은 우주급 아이디어로 가득한 친구죠.
평소엔 조용하고 감정 표현도 별로 없어요. 큰 꿈이나 목표도 없어 보일 수 있죠. 하지만 여러분의 머릿속은 언제나 생각으로 가득해요!
다른 친구들보다 판단력이 뛰어나고 깊이 있게 생각해요. 다만 몸이 따라주지 않아서 실천하기가 어려운 편이에요.
두뇌는 "천재급"인데 행동은 "슬로우 모션"이라 주변 사람들이 안타까워할 수 있어요.
학교생활을 잘하려면 밝은 성격과 도전 정신, 그리고 노력하는 모습이 필요해요. 여러분의 내면에 숨겨진 에너지를 끌어내는 방법을 찾아보세요!`,
    result: `기억하세요! 이 타입은 '천재적 슬로우 모션' 스타일이지만, '실천력'과 '자기 표현 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 두뇌를 믿어요. 하지만 생각을 행동으로 옮기는 연습을 해보세요. 그러면 숨겨진 천재성을 세상에 보여줄 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 천재"로 불릴 수 있어요. 하지만 그 재능을 보여주기 어려워할 수 있어요.
수업 시간에는 선생님 말씀을 잘 이해하지만, 발표나 질문은 잘 안 하는 편이에요. 용기를 내서 자신의 생각을 표현해보세요!
조별 과제를 할 때는 아이디어는 좋지만 실행에 옮기기 어려워할 수 있어요. 친구들과 협력해서 아이디어를 현실로 만들어보세요.
동아리 활동은 과학부나 독서부 같은 조용한 활동이 잘 맞을 수 있어요. 하지만 가끔은 활동적인 동아리에도 도전해보세요!
진로를 고민할 때는 연구원, 작가, 프로그래머 같은 직업을 생각해볼 수 있어요. 여러분의 뛰어난 두뇌를 활용할 수 있는 직업이에요!`,
    human: `- 베프, 여자친구
이 친구와의 관계는 조금 심심할 수 있어요. 여러분이 먼저 재미있는 활동을 제안해보세요. 함께 새로운 경험을 해보는 것도 좋아요!
- 선생님
이런 타입의 선생님은 생각은 많지만 실천력이 부족할 수 있어요. 선생님의 아이디어를 듣고, 학생들이 실천에 옮기는 걸 도와드려보세요.
- 선배
이 타입의 선배는 조용하지만 똑똑해요. 선배의 생각을 들어보고, 여러분이 실천에 옮기는 걸 도와드리면 좋은 팀이 될 수 있어요.
- 친구, 후배
이 타입의 친구나 후배는 아이디어는 좋지만 행동으로 옮기기 어려워해요. 그들의 생각을 존중하면서 함께 실천해보는 건 어떨까요?`,
  },

  CBBAA: {
    type: "CBBAA",
    category: "안일함을 버려야 사는 유형",
    desc: `'편안한 게 최고야' 타입`,
    sub: `학교의 숨은 잠재력 보유자!`,
    char: `여러분은 진정한 '편안한 게 최고야' 타입이에요! 판단력도 좋고, 따뜻한 마음도 있지만, 가끔은 너무 편안함을 추구해요.
약속 시간을 잘 안 지키거나, 책임감이 좀 부족해서 문제가 생길 수 있어요. "귀찮아~"라는 말을 자주 하진 않나요?
하지만 가끔은 갑자기 무언가에 꽂혀서 과하게 집중할 때도 있어요. 이런 모습이 주변 사람들을 혼란스럽게 할 수 있어요.
다른 사람들과 갈등이 생기는 걸 정말 싫어해서, 자신의 의견을 말하기보다는 남들 의견에 쉽게 따라가는 편이에요.
이런 모습들을 조금씩 개선한다면, 여러분은 정말 멋진 사람이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '편안한 게 최고야' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 잠재력을 믿어요. 하지만 조금 더 부지런하고 책임감 있게 행동하는 연습을 해보세요. 그러면 여러분의 숨겨진 능력이 빛을 발할 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 실력자"로 불릴 수 있어요. 하지만 그 실력을 제대로 보여주지 못하고 있죠.
수업 시간에는 이해는 잘 하지만, 숙제는 미루다가 벼락치기로 하는 편이에요. 조금씩 꾸준히 하는 습관을 들여보세요!
조별 과제를 할 때는 아이디어는 좋지만, 실천이 부족할 수 있어요. 친구들과 함께 계획을 세우고 꾸준히 실천해보세요.
동아리 활동은 처음엔 열심히 하다가 점점 게을러질 수 있어요. 끝까지 책임감 있게 활동하는 연습을 해보세요.
진로를 고민할 때는 여러 가지를 생각해볼 수 있어요. 하지만 결정을 미루지 말고, 하나씩 진지하게 탐색해보세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 편안하고 재미있을 거예요. 하지만 가끔은 목표를 세우고 함께 도전해보는 것도 좋아요!
- 선생님
이런 타입의 선생님은 수업을 재미있게 하실 거예요. 하지만 가끔 숙제 검사나 시험 채점을 미루실 수 있어요. 여러분이 먼저 물어보고 리마인드 해드리세요.
- 선배
이 타입의 선배와는 편하게 지낼 수 있어요. 하지만 중요한 조언이나 도움이 필요할 때는 여러분이 먼저 적극적으로 물어보세요.
- 친구, 후배
이 타입의 친구나 후배는 처음엔 열심히 하다가 점점 게을러질 수 있어요. 함께 목표를 세우고 서로 체크해주는 게 좋아요.`,
  },

  CBBAB: {
    type: "CBBAB",
    category: "즐거움 속으로 빠져버리는 유형",
    desc: `'인생은 롤러코스터 YOLO' 타입`,
    sub: `학교의 숨은 재미 메이커!`,
    char: `여러분은 진정한 '인생은 롤러코스터 YOLO' 타입이에요! 호기심 대마왕에 자유로운 영혼의 소유자죠. 재미있는 건 뭐든 해보고 싶어 하는 "찐" 탐험가예요.
하지만 한번 빠지면 헤어 나오기 힘들어요. 자기 통제력이 좀 부족하달까요?
학교나 부모님이 하고 싶은 걸 못하게 하면 "에이 몰라!"하고 다 포기해버릴 수 있어요. 책임감이 좀 부족한 편이죠.
그래도 여러분의 강점은 창의력과 창조력이에요! 예술이나 과학 분야에서 대박 아이디어를 낼 수 있는 잠재력이 있어요.
이런 장점을 살리면서 자기 통제력을 조금만 기르면, 여러분은 정말 멋진 사람이 될 수 있어요!`,
    result: `기억하세요! 이 타입은 '인생은 롤러코스터 YOLO' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 창의력과 열정을 믿어요. 하지만 가끔은 자기 통제력을 기르고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 롤러코스터를 타는 YOLO 라이프를 즐길 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "재미 담당 총괄"로 불릴 수 있어요. 수업이 지루할 때마다 분위기를 띄우는 역할을 하죠.
수업 시간에는 관심 있는 과목은 집중하지만, 재미없는 과목은 완전 "졸음 모드"가 돼요. 모든 과목에 재미를 찾아보는 건 어떨까요?
조별 과제를 할 때는 아이디어 뱅크 역할을 해요. 하지만 실천력이 부족할 수 있어요. 친구들과 함께 계획을 세우고 끝까지 해내는 연습을 해보세요.
동아리 활동은 예술, 과학, 봉사 등 다양한 분야에 도전해보세요. 여러분의 숨겨진 재능을 발견할 수 있을 거예요!
진로를 고민할 때는 크리에이터, 예술가, 과학자 같은 창의적인 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 책임감이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 함께라면 매일매일이 "축제"가 될 거예요! 하지만 가끔은 진지한 대화도 필요해요. 함께 미래를 계획하고 서로 성장할 수 있도록 도와주세요.
- 선생님
이런 타입의 선생님은 수업을 정말 재미있게 하실 거예요. 하지만 숙제 검사나 시험 채점이 불규칙할 수 있어요. 선생님의 재미있는 수업을 즐기면서도, 스스로 공부 계획을 세워보세요.
- 선배
이 타입의 선배는 학교생활을 즐겁게 만들어줄 거예요. 하지만 중요한 조언은 부족할 수 있어요. 선배의 밝은 에너지는 배우되, 다른 선배들의 조언도 함께 들어보세요.
- 친구, 후배
이 타입의 친구나 후배는 학교 생활을 정말 재미있게 만들어줄 거예요. 하지만 과제나 시험 준비할 때 집중력이 부족할 수 있어요. 함께 공부하면서 서로 격려해주세요.`,
  },

  CBBAC: {
    type: "CBBAC",
    category: "자유로움을 추구하는 유형",
    desc: `'내 인생은 내가 주인공' 타입`,
    sub: `학교의 숨은 자유로운 영혼!`,
    char: `여러분은 진정한 '내 인생은 내가 주인공' 타입이에요! 다른 사람의 일에 간섭받기 싫어하고, 남의 일에도 관여하지 않으려 하는 "마이웨이" 스타일이죠.
"인생은 각자의 몫이야"라는 생각으로 자유롭게 살아가는 걸 좋아해요. 창의적이고 활발해서 주변 환경에 관심이 많아요. 뭔가 흥미로운 게 있으면 꼭 알아봐야 직성이 풀리는 호기심 대마왕이에요!
이런 특징 때문에 주변 사람들에게 인정받을 수 있지만, 가끔은 충동적이고 기분파라 다른 사람들과 부딪힐 수 있어요. 자기 관리와 협동심을 기르는 게 중요해요!`,
    result: `기억하세요! 이 타입은 '내 인생은 내가 주인공' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 창의력과 자유로운 영혼을 믿어요. 하지만 가끔은 규칙을 지키고, 다른 사람과 협력하는 법도 배워보세요. 그러면 더 멋진 자유를 누릴 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "자유로운 영혼"으로 불릴 수 있어요. 규칙에 얽매이는 걸 정말 싫어하죠.
수업 시간에는 흥미로운 주제가 나오면 집중하지만, 그렇지 않으면 금방 딴 생각에 빠져요. 모든 과목에서 재미있는 점을 찾아보는 건 어떨까요?
조별 과제를 할 때는 창의적인 아이디어를 잘 내요. 하지만 팀워크가 부족할 수 있어요. 다른 친구들의 의견도 존중하는 연습을 해보세요.
동아리 활동은 자유롭고 창의적인 활동이 있는 곳을 찾아보세요. 예술 동아리나 창업 동아리가 잘 맞을 거예요!
진로를 고민할 때는 프리랜서, 예술가, 크리에이터 같은 자유로운 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 책임감이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 함께라면 매일매일이 새로운 모험이 될 거예요! 하지만 가끔은 안정감이 필요할 수 있어요. 서로의 개성을 존중하면서 균형을 찾아보세요.
- 선생님
이런 타입의 선생님은 수업을 재미있게 하시지만, 가끔 예측불가능할 수 있어요. 선생님의 창의적인 수업을 즐기면서도, 꼭 필요한 내용은 스스로 정리해두세요.
- 선배
이 타입의 선배는 자유로운 학교생활의 "꿀팁"을 많이 알려줄 거예요. 하지만 모든 조언을 그대로 따르기보다는 자신에게 맞는지 고민해보세요.
- 친구, 후배
이 타입의 친구나 후배는 학교 생활을 재미있게 만들어줄 거예요. 하지만 팀 프로젝트에서는 협력이 부족할 수 있어요. 서로 존중하면서 함께 일하는 법을 배워보세요.`,
  },

  CBBBA: {
    type: "CBBBA",
    category: "뒤에서 묵묵히 기다리는 유형",
    desc: `'조용한 배경음악' 타입`,
    sub: `학교의 숨은 안정감 요정!`,
    char: `여러분은 진정한 '조용한 배경음악' 타입이에요! 주목받는 걸 좋아하기보다는 조용히 뒤에서 팀을 서포트하는 게 편한 친구죠.
여러분에게는 안정감이 가장 중요해요. 스스로를 잘 관리하고, 다른 사람들을 배려하는 "찐" 따뜻한 마음을 가졌어요.
친구들에게 상처 주는 걸 정말 싫어하고, 무리한 요구도 하지 않아요. 오히려 자신의 욕구를 참고 친구들을 맞춰주는 편이에요.
이런 성격 덕분에 친구들과 싸울 일이 거의 없어요. 하지만 가끔은 이런 점이 여러분의 꿈을 이루는 데 방해가 될 수 있다는 걸 기억하세요.
변화를 두려워하고 현실에 안주하는 경향이 있어요. 중요한 결정을 할 때는 신중하게, 하지만 용기 있게 결정하는 연습을 해보세요!`,
    result: `기억하세요! 이 타입은 '조용한 배경음악' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 안정감과 배려심을 믿어요. 하지만 가끔은 자신의 의견을 말하고, 새로운 도전을 해보는 것도 중요해요. 그러면 더 멋진 '인생의 배경음악'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 조력자"로 불릴 수 있어요. 눈에 띄지 않지만 팀 활동에서 없어서는 안 될 중요한 역할을 해요.
수업 시간에는 조용히 집중해서 듣지만, 발표는 잘 안 하려고 해요. 용기를 내서 가끔은 자신의 생각을 말해보는 건 어떨까요?
조별 과제를 할 때는 팀원들의 의견을 잘 들어주고 조화롭게 만드는 역할을 해요. 하지만 가끔은 자신의 아이디어도 제안해보세요!
동아리 활동은 소규모 그룹 활동이나 봉사 활동이 잘 맞을 거예요. 여러분의 따뜻한 마음을 발휘할 수 있는 곳을 찾아보세요.
진로를 고민할 때는 상담사, 사서, 연구원 같은 조용하지만 중요한 직업을 생각해볼 수 있어요. 하지만 다양한 가능성도 열어두세요!`,
    human: `- 베프, 이성친구
이 친구는 여러분의 이야기를 잘 들어주고 맞춰줄 거예요. 하지만 자신의 욕구는 잘 표현하지 않아요. 가끔은 이 친구의 진짜 마음을 물어봐주세요.
- 선생님
이런 타입의 선생님은 학생들과의 관계를 오래 유지하려고 해요. 선생님의 따뜻함에 감사하면서도, 가끔은 여러분이 먼저 의견을 제시해보세요.
- 선배
이 타입의 선배는 인간적으로 좋지만, 리더십이 부족할 수 있어요. 선배의 따뜻함을 배우면서도, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 여러분의 말을 잘 들어주고 맡은 일을 묵묵히 해낼 거예요. 하지만 가끔은 의지하려고 할 수 있어요. 명확한 방향을 제시해주고, 도전하도록 격려해주세요.`,
  },

  CBBBB: {
    type: "CBBBB",
    category: "낮은 곳에 머물러 있는 유형",
    desc: `'소확행 만렙 달성' 타입`,
    sub: `학교의 숨은 평화주의자!`,
    char: `여러분은 진정한 '소확행 만렙 달성' 타입이에요! 작은 행복에 만족하며 "이 정도면 됐어~"라고 생각하는 친구죠.
다른 사람에게 큰 기대를 하지 않고, 지금 가진 것에 가치를 두는 편이에요. 친구들의 실수에도 크게 신경 쓰지 않아요.
욕심이 없는 편이라 책임감 있는 일을 맡는 걸 부담스러워해요. 미래가 바뀌고 부와 명예를 얻을 수 있다고 해도, 힘든 걸 참으면서까지 뭔가를 이루려고 하지 않아요.
이런 성격 때문에 다른 사람들에게 피해를 주지는 않지만, 자신의 미래를 위해 꿈과 책임감을 키우는 게 필요해요. 가끔은 자신의 의견을 적극적으로 말하는 연습도 해보세요!`,
    result: `기억하세요! 이 타입은 '소확행 만렙 달성' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 평화로운 마음을 믿어요. 하지만 가끔은 큰 꿈을 꾸고, 그 꿈을 위해 노력하는 것도 중요해요. 그러면 더 큰 행복을 만날 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "평화로운 학생"으로 불릴 수 있어요. 큰 문제를 일으키지 않고 조용히 학교생활을 해요.
수업 시간에는 열심히 듣지만, 높은 성적을 위해 노력하지는 않아요. 하지만 가끔은 도전적인 목표를 세워보는 것도 좋아요!
조별 과제를 할 때는 주어진 역할만 묵묵히 해내요. 하지만 가끔은 리더 역할에 도전해보는 것도 좋을 거예요.
동아리 활동은 조용하고 부담 없는 활동을 선호해요. 하지만 새로운 경험을 위해 활동적인 동아리에 가입해보는 것도 좋아요!
진로를 고민할 때는 안정적이고 평화로운 직업을 생각해볼 수 있어요. 하지만 다양한 가능성을 열어두고 여러 진로를 탐색해보세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 편안하고 잔잔한 시간을 보낼 수 있어요. 하지만 가끔은 여러분이 먼저 새로운 도전을 제안해보세요.
- 선생님
이 선생님은 학생들에게 부담을 주지 않는 편안한 학습 환경을 제공하실 것 같습니다. 그러나 여러분의 학습 경험을 더욱 풍부하게 만들기 위해서는 때때로 주도적인 자세가 필요할 수 있습니다. 수업 중에 적극적으로 질문하고 의견을 나누는 것이 도움이 될 수 있어요. 이러한 참여는 여러분의 이해도를 높이고 더 깊이 있는 학습으로 이어질 수 있습니다.
- 선배
이 타입의 선배는 강한 리더십은 없지만 편안한 분위기를 만들어줄 거예요. 선배의 장점은 배우되, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 묵묵히 자신의 역할을 해낼 거예요. 하지만 새로운 도전을 두려워할 수 있어요. 함께 목표를 세우고 도전해보는 건 어떨까요?`,
  },

  CBBBC: {
    type: "CBBBC",
    category: "우물 안 개구리 유형",
    desc: `'내 세계 속 편안한 우주인' 타입`,
    sub: `학교의 숨은 안락 지대 챔피언!`,
    char: `여러분은 진정한 '내 세계 속 편안한 우주인' 타입이에요! 가끔은 "내가 최고야~"라고 생각하지만, 사실 너무 튀지 않고 적당히 즐겁게 살고 싶어 하는 친구예요.
싸움이나 갈등은 정말 싫어하고, 부와 명예에는 별로 관심이 없어요. "이 정도면 됐어!"라고 쉽게 만족해버리는 스타일이죠.
스스로 한계를 정해놓고 더 높은 곳을 향해 도전하지 않으려고 해요. 이게 좀 아쉽죠.
중요한 선택을 해야 할 때 "어차피 안 돼"라고 생각하며 포기해버리기 쉬워요. 하지만 "할 수 있다!"는 자신감을 갖고 도전해보는 게 중요해요.
지금 상태에 안주하지 말고 변화를 추구하려는 노력이 필요해요!`,
    result: `기억하세요! 이 타입은 '내 세계 속 편안한 우주인' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 편안함을 즐기되, 가끔은 자신의 한계를 넘어서는 도전을 해보세요. 그러면 더 넓은 우주를 만날 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "편안한 우주인"으로 불릴 수 있어요. 자기만의 세계에서 편안하게 지내려고 해요.
수업 시간에는 적당히 듣고 적당히 참여해요. 하지만 가끔은 자신의 능력보다 더 높은 목표를 세워보는 건 어떨까요?
조별 과제를 할 때는 갈등을 피하려고 남들 의견에 쉽게 따라가요. 하지만 가끔은 자신의 아이디어를 제안해보세요!
동아리 활동은 편안하고 부담 없는 것을 선호해요. 하지만 새로운 도전을 위해 활동적인 동아리에 가입해보는 것도 좋아요!
진로를 고민할 때는 안정적이고 편안한 직업만 생각하기 쉬워요. 하지만 다양한 가능성을 열어두고 여러 진로를 탐색해보세요!`,
    human: `- 베프, 이성친구
이 친구는 무리한 요구를 하지 않아 편안할 거예요. 하지만 변화를 싫어할 수 있어요. 함께 새로운 경험을 해보는 건 어떨까요?
- 선생님
이 선생님은 학생들에게 부담을 주지 않는 편안한 학습 환경을 제공하실 것 같습니다. 다만, 때로는 여러분의 노력과 진전을 깊이 인지하지 못할 수 있습니다. 따라서 여러분이 적극적으로 자신의 학습 과정과 성과를 공유하는 것이 도움이 될 수 있습니다. 선생님과의 열린 소통은 더 풍부한 학습 경험과 상호 이해를 촉진할 수 있습니다.
- 선배
이 타입의 선배는 여러분에게 많은 것을 요구하지 않을 거예요. 하지만 여러분의 노력을 인정해주지 않을 수도 있어요. 스스로 만족하는 법을 배워보세요.
- 친구, 후배
이 타입의 친구나 후배는 큰 성과를 내기 어려울 수 있어요. 하지만 함께 목표를 세우고 서로 응원하면 둘 다 성장할 수 있을 거예요!`,
  },

  CBBCA: {
    type: "CBBCA",
    category: "자신의 욕구를 감추는 유형",
    desc: `'숨겨진 보석 안전모드' 타입`,
    sub: `학교의 숨은 카멜레온!`,
    char: `여러분은 진정한 '숨겨진 보석 안전모드' 타입이에요! 친구들의 의견을 잘 들어주고 원하는 걸 맞춰주는 "찐" 배려왕이죠.
하지만 속으로는 "나는 못해", "나는 부족해"라고 생각하며 스스로에게 한계를 만들어요. 자신의 감정을 숨기고 친구들 기분에 맞추려고 하는 경향이 있어요.
자신의 욕구는 뒤로 하고 다른 사람들에게 맞추는 게 편하다고 느껴요. 하지만 이런 태도 때문에 가끔은 약속도 쉽게 어기게 되어 무책임해 보일 수 있어요.
여러분의 진짜 마음을 들여다보고, 꿈꾸는 미래를 위해 강한 의지를 갖는 게 필요해요. 인생 계획을 세워보는 것도 좋아요!`,
    result: `기억하세요! 이 타입은 '숨겨진 보석 안전모드' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 배려심은 정말 멋져요. 하지만 가끔은 자신의 욕구와 꿈을 위해 당당하게 나서보세요. 그러면 숨겨진 보석이 빛날 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 카멜레온"으로 불릴 수 있어요. 상황에 따라 잘 적응하지만, 진짜 모습을 잘 보여주지 않아요.
수업 시간에는 선생님 말씀을 잘 듣지만, 발표는 잘 안 하려고 해요. 용기를 내서 자신의 생각을 말해보는 건 어떨까요?
조별 과제를 할 때는 친구들 의견에 잘 맞춰주지만, 자신의 아이디어는 잘 말하지 않아요. 가끔은 여러분의 멋진 아이디어도 제안해보세요!
동아리 활동은 친구들이 하자는 대로 따라가기 쉬워요. 하지만 진짜 하고 싶은 활동을 찾아보는 것도 좋아요.
진로를 고민할 때는 주변 사람들 의견에 많이 영향을 받을 수 있어요. 하지만 여러분이 진짜 원하는 게 뭔지 깊이 생각해보세요!`,
    human: `- 베프, 이성친구
이 친구는 여러분 말을 잘 들어주고 맞춰줄 거예요. 하지만 가끔은 이 친구의 진짜 마음을 물어보세요. 서로의 진심을 나누는 게 중요해요.
- 선생님
이런 타입의 선생님은 여러분의 의견을 잘 들어주실 거예요. 하지만 가끔은 여러분이 먼저 적극적으로 질문하고 의견을 말해보세요.
- 선배
이 타입의 선배는 리더십이 부족할 수 있어요. 하지만 여러분의 의견을 잘 들어줄 거예요. 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 여러분 말을 잘 따르지만, 자신의 의견은 잘 말하지 않아요. 그들의 생각도 물어보고 존중해주세요.`,
  },

  CBBCB: {
    type: "CBBCB",
    category: "작은 이익으로 만족하는 유형",
    desc: `'소소한 행복 만족왕' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '소소한 행복 만족왕' 타입이에요! 친구들이 원하는 걸 잘 들어주고 "오케이 오케이~" 하며 따라주는 스타일이죠.
리더가 되는 건 별로 안 좋아하고, 주목받는 것도 불편해해요. 그냥 조용히 뒤에서 "숨은 실력자"로 있고 싶어 해요.
친구들을 비판하거나 뭔가를 강요하지 않아요. 하지만 자기가 실수했을 때도 적극적으로 해결하려고 하지 않을 수 있어요.
타고난 성격이 좀 조용하고 의욕이 넘치지 않아요. 혼자만의 시간을 보내는 게 더 편안하게 느껴질 수 있어요.
하지만 이대로 가면 세상과 점점 멀어질 수 있어요. 스스로 밝은 에너지를 만들고, 다양한 활동으로 친구들과 어울리는 연습을 해보세요. 그러면 인생이 더 재미있어질 거예요!`,
    result: `기억하세요! 이 타입은 '소소한 행복 만족왕' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 차분함과 배려심은 정말 멋져요. 하지만 가끔은 자신의 의견을 말하고, 새로운 도전을 해보는 것도 중요해요. 그러면 더 큰 행복을 만날 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "조용한 힐링 요정"으로 불릴 수 있어요. 존재감은 크지 않지만, 주변 사람들을 편안하게 만들어요.
수업 시간에는 조용히 듣고 있지만, 적극적으로 참여하지는 않아요. 가끔은 용기를 내서 질문하거나 발표해보세요!
조별 과제를 할 때는 다른 친구들 의견을 잘 따르지만, 자신의 아이디어는 잘 말하지 않아요. 여러분의 생각도 중요하니 꼭 말해보세요!
동아리 활동은 조용하고 부담 없는 걸 선호해요. 하지만 가끔은 활동적인 동아리에 도전해보는 것도 좋아요!
진로를 고민할 때는 안정적이고 조용한 직업을 생각하기 쉬워요. 하지만 다양한 가능성을 열어두고 여러 진로를 탐색해보세요!`,
    human: `- 베프, 이성친구
이 친구는 여러분 말을 잘 들어주고 따라줄 거예요. 하지만 무엇을 하고 싶은지 스스로 말하지 않을 수 있어요. 가끔은 이 친구의 진짜 마음을 물어보세요.
- 선생님
이런 타입의 선생님은 여러분의 의견을 많이 반영해주실 거예요. 하지만 가끔은 여러분이 먼저 적극적으로 의견을 말하고 질문해보세요.
- 선배
이 타입의 선배와 함께 일하면 불안하고 힘들 수 있어요. 여러분이 먼저 나서서 일이 잘 진행되고 있는지 체크해보세요.
- 친구, 후배
이 타입의 친구나 후배에게는 급하게 서두르지 말고 천천히 설명해주세요. 그러면 좋은 결과를 보여줄 거예요.`,
  },

  CBBCC: {
    type: "CBBCC",
    category: "잔잔한 생활방식 유형",
    desc: `'조용한 평화주의자' 타입`,
    sub: `학교의 숨은 힐링 요정!`,
    char: `여러분은 진정한 '조용한 평화주의자' 타입이에요! 활발하게 움직이는 것보다는 조용히 있는 게 더 편한 친구죠.
주목받는 걸 정말 싫어하고, 친구들이 여러분을 잘 모르는 게 오히려 편해요. 갑자기 관심받으면 "어떡하지?" 하고 당황해서 의외의 행동을 할 수도 있어요.
열정과 적극성이 좀 부족하고, 가끔은 고집도 있어서 자신의 단점을 고치려고 하지 않을 수 있어요.
혼자만의 세계에서 지내는 게 가장 편하지만, 세상과 잘 어울리려는 노력도 필요해요!`,
    result: `기억하세요! 이 타입은 '조용한 평화주의자' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 차분함은 정말 멋져요. 하지만 가끔은 적극적으로 행동하고, 새로운 도전을 해보는 것도 중요해요. 그러면 더 넓은 세상을 만날 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "조용한 힐링 요정"으로 불릴 수 있어요. 존재감은 작지만, 주변을 편안하게 만들어요.
수업 시간에는 조용히 듣고 있지만, 적극적으로 참여하지는 않아요. 가끔은 용기를 내서 발표해보는 건 어떨까요?
조별 과제를 할 때는 다른 친구들에게 의지하는 편이에요. 하지만 가끔은 여러분의 아이디어를 말해보세요. 의외로 좋은 생각이 많을 거예요!
동아리 활동은 소규모 그룹 활동이나 조용한 활동이 잘 맞을 거예요. 하지만 가끔은 활발한 동아리에 도전해보는 것도 좋아요!
진로를 고민할 때는 혼자 일할 수 있는 직업을 생각하기 쉬워요. 하지만 다양한 가능성을 열어두고 여러 진로를 탐색해보세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 차분하고 편안할 거예요. 하지만 활발한 활동을 강요하면 힘들어할 수 있어요. 서로의 페이스를 존중해주세요.
- 선생님
이런 타입의 선생님과 오래 지냈다면 성격을 잘 아실 거예요. 새로운 선생님이라면, 천천히 적응할 시간을 주세요.
- 선배
이 타입의 선배는 리더십이 부족할 수 있어요. 하지만 그만큼 여러분이 능력을 보여줄 기회가 될 수 있어요!
- 친구, 후배
이 타입의 친구나 후배에게 큰 기대를 하면 실망할 수 있어요. 그들의 페이스를 존중하면서 천천히 관계를 쌓아가세요.`,
  },

  CBCAA: {
    type: "CBCAA",
    category: "내면이 순순한 아이 유형",
    desc: `'영원한 피터팬' 타입`,
    sub: `학교의 숨은 순수 요정!`,
    char: `여러분은 진정한 '영원한 피터팬' 타입이에요! 순수함과 천진난만함이 넘치는 "찐" 동심 지킴이죠.
다른 친구들은 나이가 들면서 자기 이익을 챙기려고 하지만, 여러분은 그런 거 없이 순수한 마음 그대로예요.
때론 현실과 동떨어진 행동을 할 수 있고, 어른스러운 사고방식이 부족할 수 있어요. 친구들과 어울리는 것도 좀 어려울 수 있죠.
이런 성격 때문에 상처받을 수 있으니, 자신의 행동이 상황에 맞는지 항상 생각해보는 습관이 필요해요!`,
    result: `기억하세요! 이 타입은 '영원한 피터팬' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 순수함은 정말 멋져요. 하지만 가끔은 현실도 고려하고, 어른스러운 행동을 연습해보는 것도 중요해요. 그러면 더 멋진 '영원한 피터팬'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "순수 요정"으로 불릴 수 있어요. 맑은 에너지로 주변을 밝게 만들어요.
수업 시간에는 호기심 가득한 눈으로 선생님 말씀을 들어요. 하지만 가끔은 엉뚱한 질문을 해서 친구들을 당황시킬 수 있어요.
조별 과제를 할 때는 창의적인 아이디어를 많이 내요. 하지만 현실적인 부분을 고려하지 않아 친구들이 힘들어할 수 있어요.
동아리 활동은 예술이나 봉사 관련 동아리가 잘 맞을 거예요. 여러분의 순수한 마음을 잘 표현할 수 있는 곳을 찾아보세요!
진로를 고민할 때는 예술가, 유치원 선생님, 동물 보호사 같은 직업을 생각해볼 수 있어요. 순수한 마음을 살릴 수 있는 일을 찾아보세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 순수한 사랑을 나눌 수 있어요. 하지만 현실적인 면에서 도움을 바라면 어려울 수 있어요. 서로의 장단점을 이해하는 게 중요해요.
- 선생님
이 선생님은 수업을 흥미롭게 이끌어가는 능력이 뛰어나십니다. 다만, 때로는 중요한 행정적 업무나 구조화된 과제에서 어려움을 느끼실 수 있습니다. 학생들이 적극적으로 참여하고 협력하는 태도를 보인다면, 수업 진행과 학습 환경 개선에 큰 도움이 될 수 있습니다. 이는 상호 이해와 협력적인 학습 분위기를 조성하는 데 기여할 것입니다.
- 선배
이 타입의 선배는 좋은 것을 혼자 갖고 싶어할 수 있어요. 선배에게 많이 의지하기보다는 스스로 챙기는 게 좋아요.
- 친구, 후배
이 타입의 친구나 후배는 마치 어린아이처럼 행동할 수 있어요. 그들의 순수함을 인정하면서도, 현실적인 면을 알려주는 게 중요해요.`,
  },

  CBCAB: {
    type: "CBCAB",
    category: "방향을 종잡기 힘든 유형",
    desc: `'롤러코스터 인생' 타입`,
    sub: `학교의 숨은 카오스 메이커!`,
    char: `여러분은 진정한 '롤러코스터 인생' 타입이에요! 하고 싶은 건 무조건 해야 직성이 풀리는 "YOLO" 스타일이죠.
기분 따라 행동하는 게 특기여서, 가끔은 과하게 행동할 수 있어요. 여러분은 "에이, 다들 이럴 수 있지~"라고 생각하지만, 친구들은 이해하기 어려울 수 있어요.
인생의 방향도 좀 불분명하고, 뭐가 자신에게 좋은지 판단하는 게 어려워요. 그래서 뭘 해도 겉핥기식으로 끝나는 경우가 많아요.
자유분방하고 예측불가능한 행동 때문에, 주변 사람들이 어떻게 대해야 할지 몰라 그냥 멀리서 지켜보기만 할 수 있어요.
자신의 행동을 돌아보고, 친구들 입장도 생각해보는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '롤러코스터 인생' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자유로운 영혼은 정말 멋져요. 하지만 가끔은 규칙을 지키고, 책임감 있게 행동하는 연습도 해보세요. 그러면 더 멋진 롤러코스터를 탈 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "카오스 메이커"로 불릴 수 있어요. 예측불가능한 행동으로 주목받지만, 그게 항상 좋은 건 아니에요.
수업 시간에는 갑자기 엉뚱한 질문을 해서 모두를 당황시킬 수 있어요. 하지만 가끔은 그 질문이 정말 중요할 수도 있죠!
조별 과제를 할 때는 창의적인 아이디어를 많이 내요. 하지만 끝까지 책임지고 완성하는 건 어려워할 수 있어요.
동아리 활동은 다양한 걸 경험해보고 싶어해요. 하지만 한 가지에 오래 집중하기는 어려울 수 있어요.
진로를 고민할 때는 활동적이고 자유로운 직업을 찾아보세요. 하지만 어떤 일이든 규칙과 책임감이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 매일이 놀이공원 같을 거예요! 하지만 변화하려는 노력이 없다면 여러분이 힘들어질 수 있어요.
- 선생님
이 선생님의 수업 방식은 다소 독특하고 변화가 많을 수 있습니다. 수업에 집중하고 선생님의 교육 스타일을 주의 깊게 관찰하는 것이 도움이 될 수 있습니다. 유연한 태도로 다양한 학습 상황에 적응하는 능력을 기르세요. 이러한 접근은 여러분의 학습 경험을 풍부하게 하고, 예상치 못한 상황에서도 효과적으로 대응하는 능력을 향상시킬 수 있습니다.
- 선배
이 타입의 선배는 리더십이 부족할 수 있어요. 어떻게 대해야 할지 모르겠다면, 다른 선배들의 조언도 들어보세요.
- 친구, 후배
이 타입의 친구나 후배와 함께 일하는 건 쉽지 않을 수 있어요. 많은 도움과 지도가 필요하지만, 혼자서 다 하기는 어려울 수 있어요.`,
  },

  CBCAC: {
    type: "CBCAC",
    category: "과다 노출로 관심을 끄는 유형",
    desc: `'인플루언서 지망생' 타입`,
    sub: `학교의 숨은 주목 욕구왕!`,
    char: `여러분은 진정한 '인플루언서 지망생' 타입이에요! 자신의 모든 것이 "인스타 감성"이라고 생각하며, 남들의 시선을 신경 쓰지 않고 다 보여주고 싶어 하는 친구죠.
실제 능력보다 자신을 더 대단하게 여기고, 모든 사람들의 관심을 받고 싶어 해요. 그러다 보니 점점 더 과장되게 행동하게 될 수 있어요.
자신의 진짜 모습보다는 보여주고 싶은 모습에만 집중하고, 다른 사람들의 반응은 크게 신경 쓰지 않아요.
한 가지 일에 오래 집중하기 어렵고, 공부나 일을 별로 좋아하지 않아요. 이런 태도 때문에 인생이 평탄하지 않을 수 있으니 조심해야 해요.
겸손한 자세와 자기 절제력을 기르는 게 정말 중요해요!`,
    result: `기억하세요! 이 타입은 '인플루언서 지망생' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자신감과 표현력은 정말 멋져요. 하지만 가끔은 겸손하게 행동하고, 한 가지 일에 꾸준히 노력하는 연습을 해보세요. 그러면 더 멋진 인플루언서가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "주목 받고 싶은 스타"로 불릴 수 있어요. 항상 모든 사람의 시선을 끌고 싶어 해요.
수업 시간에는 선생님의 질문에 항상 먼저 손을 들어요. 하지만 정확한 답변보다는 관심받는 것에 더 집중할 수 있어요.
조별 과제를 할 때는 리더 역할을 맡고 싶어 하지만, 끝까지 책임지고 완성하는 건 어려워할 수 있어요.
동아리 활동은 많은 사람들 앞에서 활동할 수 있는 걸 선호해요. 하지만 꾸준히 노력해야 하는 활동은 힘들어할 수 있어요.
진로를 고민할 때는 연예인, 유튜버, 인플루언서 같은 직업을 꿈꿔요. 하지만 어떤 직업이든 끈기와 노력이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 항상 재미있겠지만, 힘든 점도 많을 거예요. 신중하게 생각해보고 결정하세요.
- 선생님
이 선생님과의 수업은 때로 도전적일 수 있습니다. 따라서 수업 내용을 스스로 꼼꼼히 복습하고 이해도를 점검하는 것이 중요합니다. 자기주도적 학습 습관을 기르고, 필요하다면 추가 자료를 찾아보는 것도 좋은 방법입니다. 이러한 노력은 여러분의 학업 성취도를 높이고, 어떤 상황에서도 효과적으로 학습할 수 있는 능력을 키워줄 것입니다.
- 선배
이 타입의 선배가 되기는 정말 어려워요. 만약 있다면 정말 특별한 경우일 거예요.
- 친구, 후배
이 타입의 친구나 후배가 말한 것은 한 번 더 확인해보는 게 좋아요. 과장된 말을 할 수 있으니 주의가 필요해요.`,
  },

  CBCBA: {
    type: "CBCBA",
    category: "바람 부는 대로 흘러가는 유형",
    desc: `'인생은 즉흥 여행' 타입`,
    sub: `학교의 숨은 자유로운 영혼!`,
    char: `여러분은 진정한 '인생은 즉흥 여행' 타입이에요! 마치 내비게이션 없이 여행하는 것처럼, 인생의 방향을 정하지 않고 그때그때 상황에 맞춰 살아가는 "YOLO" 스타일이죠.
친구들의 기분이나 원하는 걸 잘 파악하지만, 정작 자신의 삶에 대한 목표는 별로 없어요.
깊이 생각하기보다는 모든 걸 가볍게 보고, 문제가 생겨도 적극적으로 해결하려 하지 않아요. 문제가 생기면 "에이, 상황이 안 좋았어~"라며 넘어가려고 해요.
책임감 있는 태도와 분명한 인생 목표를 세우는 게 정말 중요해요!`,
    result: `기억하세요! 이 타입은 '인생은 즉흥 여행' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자유로운 영혼은 정말 멋져요. 하지만 가끔은 목표를 세우고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 인생 여행을 즐길 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "자유로운 영혼"으로 불릴 수 있어요. 규칙에 얽매이지 않고 자유롭게 행동하죠.
수업 시간에는 그날의 기분에 따라 집중할 때도 있고, 완전히 딴생각에 빠질 때도 있어요. 일관성 있게 공부하는 습관을 들여보세요!
조별 과제를 할 때는 친구들의 의견을 잘 따르지만, 적극적으로 참여하지는 않아요. 가끔은 리더 역할에 도전해보는 건 어떨까요?
동아리 활동은 재미있어 보이면 가입하지만, 꾸준히 참여하기는 어려워해요. 한 가지 활동에 끝까지 참여하는 연습을 해보세요.
진로를 고민할 때는 구체적인 계획 없이 "뭐든 되겠지~"라고 생각하기 쉬워요. 하지만 미래를 위해 구체적인 계획을 세워보는 게 중요해요!`,
    human: `- 베프, 이성친구
이 친구는 항상 이런저런 핑계를 대며 푸념할 수 있어요. 이해심을 가지고 대하되, 가끔은 현실을 직시하도록 도와주세요.
- 선생님
이 선생님의 수업에서는 특히 주의 깊은 태도가 중요할 수 있습니다. 수업 내용을 세심하게 듣고, 중요한 부분은 반드시 기록해두는 습관을 들이세요. 이는 나중에 발생할 수 있는 오해를 방지하고, 여러분의 학습 효과를 높이는 데 도움이 됩니다. 또한 이러한 태도는 책임감 있는 학습자로서의 모습을 보여주어 선생님과의 관계 개선에도 기여할 수 있습니다.
- 선배
이 타입의 선배는 자신의 능력을 잘 모르고, 후배들에 대해 뒤에서 이야기할 수 있어요. 조심스럽게 대하되, 너무 의지하지 마세요.
- 친구, 후배
이 타입의 친구나 후배는 공부나 활동에 적극적이지 않고, 여러분에게 의지하려 할 수 있어요. 함께 목표를 세우고 서로 격려하며 노력해보세요.`,
  },

  CBCBB: {
    type: "CBCBB",
    category: "뒤에서 따라가기만 하는 유형",
    desc: `'슬릭한 백댄서' 타입`,
    sub: `학교의 숨은 서포터!`,
    char: `여러분은 진정한 '슬릭한 백댄서' 타입이에요! 자신을 드러내기보다는 뒤에서 조용히 따라가는 걸 선호하는 "숨은 실력자"죠.
스스로를 특별하다고 생각하지 않아 가끔 위축될 수 있지만, 친구들이 싫어하거나 미워하지는 않아요.
"적당히 하면 그만이야~"라는 생각으로 무리하지 않고 살아가는 게 편해요. 하지만 이런 태도 때문에 주도적으로 행동해야 할 때도 뒤에 숨어버리곤 해요.
책임감이 좀 부족하고, 가끔 친구들을 불편하게 만들 수 있어요. 하지만 나쁜 의도가 아니라는 걸 모두가 알아서 크게 문제되지는 않아요.
미래를 위해 책임감을 키우고, 자신의 의견을 말하는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '슬릭한 백댄서' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 서포터 능력은 정말 멋져요. 하지만 가끔은 앞으로 나서서 자신의 의견을 말하고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 '슬릭한 메인 댄서'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 조력자"로 불릴 수 있어요. 눈에 띄지 않지만 필요한 존재죠.
수업 시간에는 조용히 듣고 있지만, 발표나 질문은 거의 하지 않아요. 가끔은 용기를 내서 의견을 말해보는 건 어떨까요?
조별 과제를 할 때는 다른 친구들을 돕는 역할을 잘 해요. 하지만 가끔은 리더 역할에 도전해보세요!
동아리 활동은 뒤에서 서포트하는 역할을 선호해요. 하지만 때로는 주도적으로 아이디어를 내보는 것도 좋아요.
진로를 고민할 때는 조력자 역할을 잘 할 수 있는 직업을 생각해보세요. 하지만 다양한 가능성도 열어두세요!`,
    human: `- 베프, 이성친구
이 친구의 단점을 보완해줄 수 있다면 좋은 관계가 될 수 있어요. 하지만 그렇지 않다면 다른 친구를 찾아보는 것도 좋아요.
- 선생님
이 선생님은 독특한 교육 철학과 방식을 가지고 계실 수 있습니다. 선생님의 접근 방식을 이해하고 존중하는 태도가 중요합니다. 동시에 수업에 적극적으로 참여하고 질문하는 등 능동적인 학습 자세를 보여주세요. 이러한 균형 잡힌 태도는 수업의 효과를 높이고, 선생님과 좋은 관계를 유지하는 데 도움이 될 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면, 리더십이 부족할 수 있으니 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 모든 일을 적당히 하려고 해요. 작은 일부터 차근차근 맡겨보면서 책임감을 키워주세요.`,
  },

  CBCBC: {
    type: "CBCBC",
    category: "부드러우나 아둔한 유형",
    desc: `'따뜻한 마음의 소유자 but 지혜 부족 챌린지' 타입`,
    sub: `학교의 숨은 감성 요정!`,
    char: `여러분은 진정한 '따뜻한 마음의 소유자 but 지혜 부족 챌린지' 타입이에요! 친구들의 마음을 잘 이해하고, 자신의 감정도 즐길 줄 아는 "감성 충만" 친구죠.
하지만 가끔 생각 없이 행동해서 실수를 많이 하고, 친구들을 배려하다가도 갑자기 "내 맘대로" 모드로 바뀌기도 해요. 이런 모습 때문에 인생이 좀 꼬일 수 있으니 조심해야 해요!
의지력이 좀 약하고, 현명하게 판단하는 능력도 부족해요. 그래서 마음먹은 대로 잘 안 되면 쉽게 포기하곤 해요.
완벽한 사람은 없지만, 세상과 잘 지내려면 부족한 점들을 개선하려고 노력해야 해요!`,
    result: `기억하세요! 이 타입은 '따뜻한 마음의 소유자 but 지혜 부족 챌린지' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 따뜻한 마음은 정말 멋져요. 하지만 가끔은 냉철하게 생각하고, 끈기 있게 행동하는 연습을 해보세요. 그러면 더 멋진 '감성과 지성을 겸비한 인재'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "감성 충만 요정"으로 불릴 수 있어요. 친구들의 마음을 잘 이해하고 공감해주죠.
수업 시간에는 감성적인 과목에서 빛을 발해요. 하지만 논리적인 과목은 어려워할 수 있어요. 균형 잡힌 공부가 필요해요!
조별 과제를 할 때는 친구들을 잘 배려하지만, 가끔 갑자기 고집을 부릴 수 있어요. 일관성 있게 행동하는 연습을 해보세요.
동아리 활동은 봉사나 예술 관련 동아리가 잘 맞을 거예요. 하지만 끝까지 책임감 있게 활동하는 것이 중요해요.
진로를 고민할 때는 사회복지사, 상담사, 예술가 같은 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 끈기와 지혜가 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구의 단점도 다 받아들일 수 있는 큰 마음이 필요해요. 따뜻함은 좋지만, 가끔은 현실적인 조언도 해주세요.
- 선생님
이 선생님과의 관계에서는 특별한 접근이 필요할 수 있습니다. 선생님의 따뜻한 태도와 같은 긍정적인 면을 배우되, 다양한 교육적 관점을 얻기 위해 다른 선생님들의 조언도 함께 고려해보세요. 이러한 균형 잡힌 접근은 여러분의 학습 경험을 더욱 풍부하게 만들고, 다각도로 성장할 수 있는 기회를 제공할 것입니다.
- 선배
이 타입의 선배는 리더십이 부족할 수 있어요. 선배의 따뜻함은 배우면서도, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배는 따뜻하게 대해주면 열심히 노력할 거예요. 그들의 감성을 인정하면서도, 함께 실천력을 키워나가세요.`,
  },

  CBCCA: {
    type: "CBCCA",
    category: "너무 쉽게 포기하는 유형",
    desc: `'3초 의지 챌린지' 타입`,
    sub: `학교의 숨은 맞춤법 요정!`,
    char: `여러분은 진정한 '3초 의지 챌린지' 타입이에요! 친구들 눈치를 엄청 잘 보고 맞춰주는 "인간 카멜레온" 스타일이죠.
하지만 가끔은 너무 과하게 맞춰서 오히려 친구들이 "너 진짜 생각은 뭐야?"라고 물어볼 수 있어요.
자신이 좋아하는 걸 찾기보다는 친구들 기분 맞추는 데 더 관심이 많아요. "평화로운 게 최고야~"라는 생각이 강해요.
주도적으로 의견을 내거나 책임지는 걸 좋아하지 않아요. 큰 꿈을 꾸기보다는 "이 정도면 됐어"라고 쉽게 만족해버리는 편이에요.
중요한 결정을 해야 할 때도 깊이 생각하지 않고 쉽게 포기해버려요.
자신감을 갖고 부족한 점을 개선하려는 노력이 필요해요!`,
    result: `기억하세요! 이 타입은 '3초 의지 챌린지' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 눈치 백단 능력은 정말 대단해요. 하지만 가끔은 자신의 의견을 말하고, 끝까지 도전하는 연습을 해보세요. 그러면 더 멋진 '3시간 의지 챔피언'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "인간 카멜레온"으로 불릴 수 있어요. 어떤 친구들과도 잘 어울리지만, 진짜 자신의 모습을 보여주기는 어려워해요.
수업 시간에는 선생님 말씀에 항상 고개를 끄덕이지만, 실제로 이해했는지는 의문이에요. 모르는 게 있다면 용기내서 질문해보세요!
조별 과제를 할 때는 친구들 의견에 무조건 따르는 편이에요. 가끔은 여러분의 아이디어도 제안해보세요. 의외로 좋은 생각일 수 있어요!
동아리 활동은 친구들이 하자는 대로 따라가기 쉬워요. 하지만 진짜 여러분이 하고 싶은 활동을 찾아보는 건 어떨까요?
진로를 고민할 때는 "그냥 평범하게 살래~"라고 생각하기 쉬워요. 하지만 여러분의 숨겨진 재능을 찾아보는 것도 중요해요!`,
    human: `- 베프, 이성친구
이 친구는 여러분 말을 잘 들어주고 따라줄 거예요. 하지만 가끔은 이 친구의 진짜 생각을 물어보세요. 서로 솔직한 대화가 필요해요.
- 선생님
이런 타입의 선생님은 여러분의 의견을 대부분 수용해주실 거예요. 하지만 여러분 스스로 공부 계획을 세우고 실천하는 게 중요해요.
- 선배
이 타입의 선배는 리더십이 부족할 수 있어요. 선배의 따뜻함은 배우되, 때로는 여러분이 주도적으로 행동해보세요.
- 친구, 후배
이 타입의 친구나 후배를 대할 때는 어떻게 이끌어갈지 잘 생각해보세요. 작은 일부터 시작해서 점점 책임감을 키워주는 게 좋아요.`,
  },

  CBCCB: {
    type: "CBCCB",
    category: "현재에 안주하는 유형",
    desc: `'소확행 만렙 달성' 타입`,
    sub: `학교의 숨은 평화주의자!`,
    char: `여러분은 진정한 '소확행 만렙 달성' 타입이에요! 작은 행복에 만족하는 "힐링 요정" 스타일이죠.
인생에 대한 큰 욕심이 없고, 더 높은 곳을 향한 열정이 좀 부족해요. 지금 이 순간이 편하면 그걸로 OK!
중요한 문제라도 당장 급한 게 아니면 "내일의 나에게 맡기자~"라는 생각을 하기 쉬워요. 그래서 나중에 후회할 때가 있을 수 있어요.
인생은 잔잔한 호수가 아니라 파도 치는 바다 같아요. 큰 파도가 올 때를 대비해 미리 준비하고, 새로운 도전을 해보는 게 중요해요!`,
    result: `기억하세요! 이 타입은 '소확행 만렙 달성' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 평화로운 마음은 정말 멋져요. 하지만 가끔은 큰 꿈을 꾸고, 그 꿈을 위해 노력하는 것도 중요해요. 그러면 더 큰 행복을 만날 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "평화로운 힐링 요정"으로 불릴 수 있어요. 큰 스트레스 없이 학교생활을 즐기죠.
수업 시간에는 열심히 듣지만, 높은 성적을 위해 특별히 노력하지는 않아요. 가끔은 도전적인 목표를 세워보는 건 어떨까요?
조별 과제를 할 때는 주어진 역할만 조용히 해내요. 하지만 가끔은 리더 역할에 도전해보는 것도 좋아요!
동아리 활동은 편안하고 재미있는 것을 선호해요. 하지만 새로운 경험을 위해 도전적인 동아리에 가입해보는 것도 좋아요!
진로를 고민할 때는 안정적이고 편안한 직업만 생각하기 쉬워요. 하지만 다양한 가능성을 열어두고 여러 진로를 탐색해보세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 편안하겠지만, 발전적인 관계를 원한다면 어려울 수 있어요. 서로의 목표와 가치관을 잘 생각해보세요.
- 선생님
이 선생님의 교육 방식이 여러분의 학습 스타일과 잘 맞지 않을 수 있습니다. 따라서 학습 경험을 다양화하는 것이 도움이 될 수 있어요. 다른 선생님들의 조언을 구하고, 추가적인 학습 자료를 찾아보는 것도 좋은 방법입니다. 이러한 다각도적인 접근은 여러분의 학습을 더욱 풍부하게 만들고, 전반적인 교육 경험을 향상시킬 수 있을 것입니다.
- 선배
이 타입의 선배를 만나기는 어려울 거예요. 만약 있다면, 미래에 대한 비전이 부족할 수 있으니 다른 선배들의 조언도 들어보세요.
- 친구, 후배
이 타입의 친구나 후배는 일처리가 서툴 수 있어요. 그들이 잘할 수 있는 일을 찾아 역할을 나눠주는 게 좋아요.`,
  },

  CBCCC: {
    type: "CBCCC",
    category: "세상으로 들어가기 힘든 유형",
    desc: `'숨겨진 보석 내향 챌린지' 타입`,
    sub: `학교의 숨은 관찰자!`,
    char: `여러분은 진정한 '숨겨진 보석 내향 챌린지' 타입이에요! 세상과 어울리는 게 좀 어려운 "숨은 관찰자" 스타일이죠.
의지력이 좀 약해서 결정 내리기가 힘들고, 현실적으로 생각하고 행동하는 것도 어려워해요.
따뜻한 마음은 있지만, 그걸 표현하기가 어려워요. 마음의 문을 여러 겹으로 닫아두고 있는 것 같아요.
자신이나 다른 사람의 감정을 잘 못 느끼고 표현하기도 어려워해요. 그래서 친구 사귀는 게 쉽지 않을 수 있어요.
이런 부분들을 개선하지 않으면 세상과 점점 멀어질 수 있으니 조심해야 해요!`,
    result: `기억하세요! 이 타입은 '숨겨진 보석 내향 챌린지' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 깊이 있는 사고력은 정말 멋져요. 하지만 가끔은 용기를 내서 자신을 표현하고, 다른 사람들과 소통하는 연습을 해보세요. 그러면 숨겨진 보석이 빛날 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "조용한 관찰자"로 불릴 수 있어요. 눈에 잘 띄지 않지만 모든 걸 지켜보고 있죠.
수업 시간에는 조용히 듣고 있지만, 발표나 질문은 거의 하지 않아요. 가끔은 용기를 내서 의견을 말해보세요!
조별 과제를 할 때는 다른 친구들과 어울리기 힘들어해요. 하지만 여러분의 독특한 생각이 팀에 도움이 될 수 있어요. 한 번씩 아이디어를 말해보세요!
동아리 활동은 혼자 할 수 있는 걸 선호해요. 하지만 가끔은 다른 친구들과 함께하는 활동에 도전해보는 것도 좋아요!
진로를 고민할 때는 사람들과 많이 접촉하지 않아도 되는 직업을 생각하기 쉬워요. 하지만 다양한 가능성을 열어두고 여러 진로를 탐색해보세요!`,
    human: `- 베프, 이성친구
이 친구와 지속적인 관계를 원한다면 많이 이해하고 기다려줘야 해요. 천천히 마음의 문을 열 때까지 기다려주세요.
- 선생님
이 선생님과의 소통에는 특별한 노력이 필요할 수 있습니다. 선생님의 관점을 이해하려 노력하는 동시에, 다양한 교육적 시각을 얻기 위해 다른 선생님들의 의견도 참고해보세요. 이러한 균형 잡힌 접근은 여러분의 학습 경험을 풍부하게 하고, 의사소통 능력을 향상시키는 데 도움이 될 수 있습니다.
- 선배
이 타입의 선배를 만나기는 어려울 거예요. 만약 있다면, 여러분이 먼저 다가가서 소통하려고 노력해보세요.
- 친구, 후배
이 타입의 친구나 후배와 함께 일하면 답답함을 느낄 수 있어요. 하지만 그들의 독특한 시각이 도움될 수 있으니, 천천히 소통해보세요.`,
  },

  CCAAA: {
    type: "CCAAA",
    category: "자기 중심으로 세상을 보는 유형",
    desc: `'나만의 세계 메인 캐릭터' 타입`,
    sub: `학교의 숨은 4차원 아이돌!`,
    char: `여러분은 진정한 '나만의 세계 메인 캐릭터' 타입이에요! 호기심 대마왕에 자유분방한 "4차원 아이돌" 스타일이죠.
밝고 활발하며 애정 표현도 잘해요. 또한 모든 걸 꼼꼼히 분석하는 "엄청난 두뇌"를 가졌어요!
하지만 다른 사람을 이해하고 배려하는 마음이 좀 부족해요. 가끔 "내가 최고야!"라는 생각에 빠지기도 해요.
친구들의 잘못을 너무 잘 찾아내고, 그걸 그대로 말해버려요. 작은 일도 크게 만들어버리는 경향이 있어요.
그래서 주변 사람들이 불편해할 수 있어요. 다른 사람의 입장에서 생각해보고, 신중하게 행동하는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '나만의 세계 메인 캐릭터' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 매력과 분석력은 정말 대단해요. 하지만 가끔은 다른 사람의 입장에서 생각해보고, 배려하는 마음을 가져보세요. 그러면 더 멋진 '우리의 세계 메인 캐릭터'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "4차원 아이돌"로 불릴 수 있어요. 독특한 매력으로 주목받지만, 가끔은 오해를 받을 수도 있어요.
수업 시간에는 호기심 가득한 질문을 많이 해요. 하지만 때로는 수업 흐름을 방해할 수 있으니 조심해야 해요.
조별 과제를 할 때는 독특한 아이디어를 많이 내요. 하지만 친구들의 의견도 존중해주는 게 중요해요.
동아리 활동은 자유롭고 창의적인 활동을 좋아해요. 하지만 규칙도 잘 지켜야 한다는 걸 잊지 마세요!
진로를 고민할 때는 분석력을 살릴 수 있는 직업을 생각해보세요. 하지만 어떤 직업이든 팀워크가 중요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 함께 있으면 재미있지만, 때로는 힘들 수 있어요. 서로를 이해하고 존중하는 마음이 필요해요.
- 선생님
이 선생님은 전통적인 사제 관계를 중요하게 여기실 수 있습니다. 선생님의 경험과 지식에서 배울 점을 찾되, 다양한 교육 방식을 경험하는 것도 중요합니다. 다른 선생님들의 조언도 함께 참고하면서 균형 잡힌 시각을 기르세요. 이러한 접근은 여러분의 학습 경험을 풍부하게 하고, 비판적 사고 능력을 키우는 데 도움이 될 수 있습니다.
- 선배
이 타입의 선배는 기분에 따라 행동할 수 있어요. 선배의 좋은 점은 배우면서도, 적절히 거리를 두는 게 좋아요.
- 친구, 후배
이 타입의 친구나 후배는 예측불가능한 행동을 할 수 있어요. 함께 규칙을 정하고, 서로 지키려고 노력하는 게 중요해요.`,
  },

  CCAAB: {
    type: "CCAAB",
    category: "자기 방식만 생각하는 유형",
    desc: `'솔로 플레이어 만렙' 타입`,
    sub: `학교의 숨은 자유로운 영혼!`,
    char: `여러분은 진정한 '솔로 플레이어 만렙' 타입이에요! 조용한 건 딱 질색, 활기찬 분위기를 좋아하는 "에너지 뱅크" 스타일이죠.
하지만 친구들과 어울리는 건 그다지 좋아하지 않아요. 누가 여러분을 무시한다고 느끼면 꽤 예민하게 반응할 수 있어요.
세상에 무슨 일이 일어나도 "내 알 바 아니야~"라는 생각을 하기 쉬워요. 친구들과의 관계도 그다지 중요하게 여기지 않죠.
다른 사람을 배려하거나 도와주는 일도 잘 하지 않아요.
주변 사람들이 여러분의 이런 모습을 잘 모를 수 있지만, 언젠가는 문제가 될 수 있어요. 세상에 관심을 갖고 다가가는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '솔로 플레이어 만렙' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자유로운 영혼은 정말 멋져요. 하지만 가끔은 다른 사람들과 어울리고, 세상에 관심을 가져보세요. 그러면 더 멋진 '멀티 플레이어'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "자유로운 영혼"으로 불릴 수 있어요. 규칙에 얽매이지 않고 자유롭게 행동하죠.
수업 시간에는 활동적인 수업을 좋아해요. 하지만 조용히 앉아 있어야 하는 수업은 힘들어할 수 있어요.
조별 과제를 할 때는 혼자 하고 싶어해요. 하지만 가끔은 친구들과 협력하는 연습도 필요해요!
동아리 활동은 자유롭게 움직일 수 있는 걸 선호해요. 운동이나 야외 활동 동아리가 잘 맞을 거예요.
진로를 고민할 때는 혼자 자유롭게 일할 수 있는 직업을 생각해보세요. 하지만 어떤 직업이든 사람과의 관계가 중요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 깊은 관계를 맺기는 어려울 수 있어요. 여러분을 지치게 만들 수도 있으니 신중하게 생각해보세요.
- 선생님
이 선생님의 교육 방식이 여러분의 학습 스타일과 완벽하게 일치하지 않을 수 있습니다. 따라서 학습 경험을 다양화하는 것이 도움이 될 수 있어요. 다른 선생님들의 조언을 구하고, 추가적인 학습 자료를 탐색해보는 것도 좋은 방법입니다. 이러한 다각도적인 접근은 여러분의 교육 경험을 풍부하게 하고, 전반적인 학습 효과를 높일 수 있을 것입니다.
- 선배
이 타입의 선배는 이해하기 어려운 면이 많을 수 있어요. 하지만 여러분을 크게 힘들게 하지는 않을 거예요.
- 친구, 후배
이 타입의 친구나 후배는 학교생활에 잘 적응하지 못할 수 있어요. 큰 기대는 하지 말고, 그들의 개성을 인정해주세요.`,
  },

  CCAAC: {
    type: "CCAAC",
    category: "자신만의 세상에서 사는 유형",
    desc: `'내 세계 속 완벽주의자' 타입`,
    sub: `학교의 숨은 논리왕!`,
    char: `여러분은 진정한 '내 세계 속 완벽주의자' 타입이에요! 모든 걸 정확하고 논리적으로 따지는 "미니 셜록" 스타일이죠.
하지만 자기 생각만 옳다고 믿는 경향이 있어요. 주변 친구들이나 환경에는 별로 관심이 없어요.
자신에게 이익이 되는 건 잘 찾지만, 다른 사람의 입장은 잘 생각하지 않아요. 가끔은 차갑고 고집스러운 모습을 보일 수 있어요.
책임감이 좀 부족해서 자기 일도 끝까지 마무리하지 않을 때가 있어요.
세상과 단절해서는 살기 힘들어요. 자기 생각만 고집하지 말고, 다른 사람을 배려하는 마음을 키우는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '내 세계 속 완벽주의자' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 논리력은 정말 대단해요. 하지만 가끔은 다른 사람들의 의견도 들어보고, 협력하는 연습을 해보세요. 그러면 더 멋진 '우리 세계 속 완벽주의자'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 논리왕"으로 불릴 수 있어요. 모든 걸 정확하게 따지고 싶어 하죠.
수학이나 과학 같은 논리적인 과목을 좋아해요. 하지만 문학이나 예술 같은 감성적인 과목은 어려워할 수 있어요.
조별 과제를 할 때는 혼자 하고 싶어해요. 하지만 가끔은 친구들과 협력하는 연습도 필요해요!
동아리 활동은 개인 활동이 많은 걸 선호해요. 과학부나 컴퓨터부가 잘 맞을 거예요.
진로를 고민할 때는 연구원, 프로그래머 같은 논리적인 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 사람과의 관계가 중요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와는 서로 다른 곳을 바라보고 있을 거예요. 깊은 관계를 맺기 어려울 수 있으니 신중하게 생각해보세요.
- 선생님
이 선생님과의 학습 경험이 때로 도전적일 수 있습니다. 어려움을 느낄 때는 다른 관점도 함께 고려해보는 것이 도움이 될 수 있어요. 다양한 선생님들의 조언을 구하고 여러 학습 방법을 탐색해보세요. 이러한 접근은 여러분의 학습 경험을 균형 있게 만들고, 스트레스를 줄이는 데 도움이 될 수 있습니다.
- 선배
이 타입의 선배는 후배들을 잘 챙기지 않을 거예요. 선배를 너무 믿지 말고, 스스로 길을 찾아가세요.
- 친구, 후배
이 타입의 친구나 후배와는 개인 과제를 함께 하는 게 좋아요. 팀 활동에서는 어려움을 겪을 수 있어요.`,
  },

  CCABA: {
    type: "CCABA",
    category: "지켜보거나 도망가려는 유형",
    desc: `'숨은 천재 도망자' 타입`,
    sub: `학교의 미스터리 관찰자!`,
    char: `여러분은 진정한 '숨은 천재 도망자' 타입이에요! 머리는 좋지만 모든 걸 다른 사람에게 맡기고 싶어 하는 "숨은 관찰자" 스타일이죠.
상황 판단력은 엄청나지만, 그 능력을 잘 활용하지 않아요. 대신 주변 사람들이 모든 걸 해주길 바라고 의지하려고 해요.
이건 아마도 힘들고 싶지 않아서, 그리고 책임지기 싫어서 그런 걸 거예요.
머리 회전은 빠르지만, 자신의 생각대로 끝까지 해내려는 의지가 부족해요. 옳은 일이라도 인정하고 받아들이기 어려워하죠.
이런 태도 때문에 친구들과 잘 어울리기 힘들 수 있어요. 세상에서 외면받지 않으려면 책임감을 키우고 단점을 고치려고 노력해야 해요!`,
    result: `기억하세요! 이 타입은 '숨은 천재 도망자' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 두뇌는 정말 대단해요. 하지만 가끔은 책임감을 갖고 끝까지 해내는 연습을 해보세요. 그러면 더 멋진 '숨은 천재 리더'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 천재"로 불릴 수 있어요. 머리는 좋지만 그걸 잘 보여주지 않죠.
수업 시간에는 모든 걸 다 알고 있지만, 발표는 절대 하지 않으려고 해요. 가끔은 용기를 내서 자신의 생각을 말해보세요!
조별 과제를 할 때는 모든 걸 친구들에게 맡기려고 해요. 하지만 여러분의 아이디어가 필요할 때가 있어요. 적극적으로 참여해보세요!
동아리 활동은 혼자 할 수 있는 걸 선호해요. 컴퓨터부나 독서부가 잘 맞을 거예요.
진로를 고민할 때는 정확성이 필요한 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 책임감이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 깊은 관계를 맺으려면 정말 신중하게 생각해봐야 해요. 후회하지 않도록 잘 판단해보세요.
- 선생님
이 선생님과의 관계에서는 특별한 주의가 필요할 수 있습니다. 때로 선생님의 결정이나 행동이 학생들의 기대와 다를 수 있어요. 수업 중 중요한 내용은 꼼꼼히 기록하고, 과제나 약속은 명확히 확인하세요. 또한 다른 선생님들이나 학교 관계자의 조언을 구하는 것도 도움이 될 수 있습니다. 이러한 신중한 접근은 여러분의 학습권을 보호하고 원활한 학교생활을 하는 데 도움이 될 것입니다.
- 선배
이 타입의 선배는 여러분 말을 잘 들어주고 간섭하지 않을 거예요. 하지만 문제가 생기면 책임지지 않을 수 있으니 조심해야 해요.
- 친구, 후배
이 타입의 친구나 후배는 자극이 필요해요. 계속 격려하고 도전하도록 만들어주세요.`,
  },

  CCABB: {
    type: "CCABB",
    category: "원하는 대로만 살아가는 유형",
    desc: `'내 맘대로 YOLO' 타입`,
    sub: `학교의 숨은 4차원 아이돌!`,
    char: `여러분은 진정한 '내 맘대로 YOLO' 타입이에요! 머리는 좋지만 세상 물정 모르는 "4차원 아이돌" 스타일이죠.
판단력은 좋지만, 세상 돌아가는 일엔 관심 없어요. 가끔 남들이 이해 못 할 생각을 하기도 해요.
학교 규칙이나 친구들을 배려하는 건 별로 신경 쓰지 않아요. 대신 자기가 하고 싶은 대로 살고 싶어 해요.
"편하게 살자"가 인생 모토예요. 하지만 뭔가를 끝까지 해내려는 의지가 부족해서 일을 자주 미루다 망치곤 해요.
자기 마음대로만 살다 보면 언젠가 친구들이 등을 돌릴 수 있어요. 다른 사람들 생각도 고려하고, 사회 규칙도 지키려고 노력해야 해요!`,
    result: `기억하세요! 이 타입은 '내 맘대로 YOLO' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 매력과 판단력은 정말 대단해요. 하지만 가끔은 다른 사람들 생각도 고려하고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 '우리 맘대로 YOLO' 스타가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "4차원 아이돌"로 불릴 수 있어요. 독특한 매력이 있지만, 적응하기 어려워할 수 있어요.
수업 시간에는 관심 있는 과목만 열심히 듣고, 나머지는 "나만의 시간"을 가져요. 하지만 모든 과목이 중요하다는 걸 잊지 마세요!
조별 과제를 할 때는 자기 역할을 잘 안 하려고 해요. 하지만 팀워크도 중요한 능력이에요. 책임감 있게 참여해보세요!
동아리 활동은 자유롭고 재미있는 걸 선호해요. 하지만 규칙적인 활동도 시도해보는 게 어떨까요?
진로를 고민할 때는 자유로운 직업을 생각하기 쉬워요. 하지만 어떤 직업이든 책임감과 규칙이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구는 항상 자기 편한 대로 하려고 할 거예요. 모든 걸 맞춰줄 준비가 되어 있다면 관계를 유지할 수 있어요.
- 선생님
이 선생님은 독특한 교육 방식을 가지고 계실 수 있습니다. 따라서 수업 내용을 스스로 정리하고 추가로 학습하는 습관이 중요할 수 있어요. 수업 중 주요 내용을 꼼꼼히 기록하고, 필요하다면 추가 자료를 찾아보세요. 이러한 자기주도적 학습 태도는 여러분의 이해도를 높이고 학업 성취에 도움이 될 것입니다.
- 선배
이 타입의 선배는 말만 멋있고 실제로는 잘 도와주지 않을 수 있어요. 선배의 조언은 참고만 하고, 스스로 판단하세요.
- 친구, 후배
이 타입의 친구나 후배는 말만 번지르르하고 실제로 일은 잘 안 할 수 있어요. 함께 일할 때는 꼭 확인하고 체크해야 해요.`,
  },

  CCABC: {
    type: "CCABC",
    category: "사회와 멀어지고 홀로 살 유형",
    desc: `'나홀로 우주 여행자' 타입`,
    sub: `학교의 숨은 미지의 세계 탐험가!`,
    char: `여러분은 진정한 '나홀로 우주 여행자' 타입이에요! 판단력은 좋지만 주변 상황은 별로 신경 쓰지 않는 "독립적인 우주인" 스타일이죠.
뭔가 이루고 싶은 목표가 있어서 그러면 좋겠지만, 사실은 복잡한 인간관계나 학교생활에서 벗어나고 싶어서 그럴 수도 있어요.
열정이 좀 부족하고 게을러질 때가 있어요. 한 가지 일에 집중하기 어려워하고, 자주 흥미를 바꾸는 편이에요.
주변 친구들 일에 관심이 별로 없어서, 점점 친구들이 멀어질 수 있어요. 그러다 보면 외로워질 수 있으니 조심해야 해요!`,
    result: `기억하세요! 이 타입은 '나홀로 우주 여행자' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독립적인 성격은 멋져요. 하지만 가끔은 친구들에게 관심을 보이고, 한 가지 일에 열중해보세요. 그러면 더 멋진 '우주 탐험대장'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "미지의 세계 탐험가"로 불릴 수 있어요. 독특한 생각을 가졌지만, 적응하기 어려워할 수 있어요.
수업 시간에는 관심 있는 주제만 집중하고, 나머지는 자주 딴생각을 해요. 하지만 모든 과목이 중요하다는 걸 잊지 마세요!
조별 과제를 할 때는 혼자 하고 싶어해요. 하지만 협동심도 중요한 능력이에요. 친구들과 함께 일하는 연습을 해보세요!
동아리 활동은 개인 활동이 많은 걸 선호해요. 하지만 가끔은 단체 활동에도 참여해보는 게 어떨까요?
진로를 고민할 때는 혼자 일할 수 있는 직업을 생각하기 쉬워요. 하지만 어떤 직업이든 사람과의 관계가 중요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구의 속마음을 알기 어려울 거예요. 여러분을 잘 배려해주지 않을 수도 있어요. 서로 이해하려 노력하는 게 중요해요.
- 선생님
이 선생님은 때로 개인적인 관점에 집중하실 수 있습니다. 선생님의 지도를 경청하되, 동시에 비판적 사고력을 기르는 것이 중요해요. 수업 내용을 주의 깊게 들으면서도 스스로 분석하고 판단하는 능력을 키우세요. 이러한 균형 잡힌 접근은 여러분의 학습 경험을 풍부하게 하고 독립적인 사고력 향상에 도움이 될 것입니다.
- 선배
이 타입의 선배는 자기 이익만 챙기려고 할 수 있어요. 선배의 행동을 잘 관찰하고, 불공평한 상황이 생기지 않도록 조심해야 해요.
- 친구, 후배
이 타입의 친구나 후배는 일처리가 깔끔하지 않을 수 있어요. 간단한 일을 맡기고, 꼭 확인하는 습관을 들이세요.`,
  },

  CCACA: {
    type: "CCACA",
    category: "세상 밖에 불안하게 서 있는 유형",
    desc: `'판타지 세계 주인공' 타입`,
    sub: `학교의 숨은 차원 여행자!`,
    char: `여러분은 진정한 '판타지 세계 주인공' 타입이에요! 현실보다는 상상 속 세계에서 더 편안함을 느끼는 "차원 여행자" 스타일이죠.
머리는 좋고 섬세한 면이 있지만, 그걸 잘 표현하지 못해요. 큰 꿈이나 책임감은 좀 부족해서 "그냥 무난하게 살자~"는 생각을 많이 해요.
친구들과 어울리거나 즐겁게 노는 것도 어려워하고, 다른 사람을 이해하거나 배려하는 것도 쉽지 않아요. 그래서 친구 관계가 좀 어려울 수 있어요.
현실에서 삶의 의미를 찾기보다는 다른 세계에서 찾으려고 해요. 그래서 평범하게 사는 게 힘들 수 있어요.
지금 살고 있는 세상에 관심을 가지고, 책임감을 키우면서 현실과 친해지려는 노력이 필요해요!`,
    result: `기억하세요! 이 타입은 '판타지 세계 주인공' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 풍부한 상상력은 정말 멋져요. 하지만 가끔은 현실에도 관심을 가지고, 책임감 있게 행동하는 연습을 해보세요. 그러면 현실과 판타지를 넘나드는 진정한 '차원 여행자'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 차원 여행자"로 불릴 수 있어요. 독특한 상상력이 있지만, 현실 적응은 어려워할 수 있어요.
수업 시간에는 자주 딴생각에 빠져요. 판타지 소설 속 주인공이 된 상상을 하곤 하죠. 하지만 가끔은 현실에 집중해보는 것도 좋아요!
조별 과제를 할 때는 혼자 하고 싶어해요. 하지만 팀워크도 중요한 능력이에요. 친구들과 협력하는 연습을 해보세요.
동아리 활동은 창작 관련 동아리가 잘 맞을 거예요. 글쓰기나 미술 동아리에서 여러분의 상상력을 펼쳐보세요!
진로를 고민할 때는 현실적인 면을 잘 고려해야 해요. 꿈은 크게 가져도 좋지만, 그 꿈을 이루기 위한 현실적인 계획도 세워보세요.`,
    human: `- 베프, 이성친구
이 친구와는 일반적인 관계 맺기가 어려울 수 있어요. 독특한 매력이 있지만 이해하기 힘든 행동을 할 수 있어요. 인내심을 갖고 이해하려 노력해보세요.
- 선생님
이 선생님의 수업 스타일이 때로 소극적으로 느껴질 수 있습니다. 따라서 학생 여러분의 능동적인 학습 태도가 특히 중요할 수 있어요. 수업 내용을 스스로 정리하고 추가로 공부하는 습관을 기르세요. 이러한 자기주도적 학습은 여러분의 학업 성취도를 높이고, 독립적인 학습 능력을 향상시키는 데 큰 도움이 될 것입니다.
- 선배
이 타입의 선배는 현실감각이 부족하고 의욕이 높지 않을 수 있어요. 선배의 조언은 참고만 하고, 스스로 판단하고 행동하세요.
- 친구, 후배
이 타입의 친구나 후배에게는 간단한 일을 부탁하는 게 좋아요. 복잡한 일은 어려워할 수 있으니 천천히 도와주세요.`,
  },

  CCACB: {
    type: "CCACB",
    category: "어둠 속으로 스스로 들어가는 유형",
    desc: `'숨은 천재 메타버스 주민' 타입`,
    sub: `학교의 미지의 고수!`,
    char: `여러분은 진정한 '숨은 천재 메타버스 주민' 타입이에요! 머리는 좋지만 현실에서는 숨어있는 "미지의 고수" 스타일이죠.
생각은 깊고 판단력도 좋지만, 실제로 행동으로 옮기는 건 어려워해요. 학교나 친구들과 어울리는 것도 쉽지 않아요.
재미있게 노는 것도 어렵고, 노는 것 자체에 흥미가 없어요. 모든 일에 소극적이고 에너지가 부족해 보여요.
주변 사람들은 여러분을 조금 어둡고 자신감 없어 보인다고 생각할 수 있어요.
이런 모습이 계속되면 친구들이 여러분을 점점 잊어갈 수 있어요. 그럴 때 상처받고 더 실망해서 자신을 포기할 수 있으니 조심해야 해요.
적극적이고 밝은 모습을 보이려고 노력하면 새로운 세상이 열릴 거예요!`,
    result: `기억하세요! 이 타입은 '숨은 천재 메타버스 주민' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 두뇌는 정말 대단해요. 하지만 가끔은 용기를 내서 자신을 표현하고, 적극적으로 행동하는 연습을 해보세요. 그러면 현실 세계의 진정한 '메타버스 주인공'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 고수"로 불릴 수 있어요. 실력은 있지만 잘 드러내지 않죠.
수업 시간에는 모든 걸 다 알고 있지만, 발표는 절대 하지 않으려고 해요. 가끔은 용기를 내서 손을 들어보세요!
조별 과제를 할 때는 혼자 하고 싶어해요. 하지만 팀워크도 중요한 능력이에요. 친구들과 함께 일하는 연습을 해보세요.
동아리 활동은 혼자 할 수 있는 걸 선호해요. 하지만 가끔은 단체 활동에도 참여해보는 게 어떨까요?
진로를 고민할 때는 자신의 실력을 보여줄 수 있는 직업을 생각해보세요. 하지만 어떤 직업이든 자신감과 의욕이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구는 밝지 않고 활기가 없을 수 있어요. 여러분이 이해하고 잘 이끌어주는 게 중요해요.
- 선생님
이 선생님의 교육 방식이 여러분의 기대와 다를 수 있습니다. 따라서 선생님의 지시사항이나 수업 내용을 명확히 이해했는지 항상 확인하는 습관이 중요해요. 필요하다면 추가 설명을 요청하거나 다른 자료를 참고하세요. 이러한 적극적인 태도는 오해를 줄이고 효과적인 학습을 도울 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면 여러분이 많이 힘들 수 있어요.
- 친구, 후배
이 타입의 친구나 후배에게 적극적으로 일하길 기대하지 마세요. 그들의 페이스를 존중하면서 천천히 도와주세요.`,
  },

  CCACC: {
    type: "CCACC",
    category: "생각은 가득하나 멈춰 있는 유형",
    desc: `'머리 좋은 슬로우 모션' 타입`,
    sub: `학교의 숨은 천재 게으른 고양이!`,
    char: `여러분은 진정한 '머리 좋은 슬로우 모션' 타입이에요! 논리적이고 합리적인 사고력을 가진 "숨은 천재" 스타일이죠.
전체를 한눈에 파악하고 무엇이 좋은지 정확히 알고 있어요. 하지만 그 생각을 행동으로 옮기는 건 좀 어려워해요.
의지와 욕심이 좀 부족해서, 좋은 아이디어가 있어도 실천하지 않고 그냥 묻어두는 경우가 많아요.
남에게 싫은 소리 하는 걸 싫어하고, 책임지는 것도 어려워해요. 적극적으로 나서기보다는 뒤에서 지켜보는 걸 좋아하죠.
이런 성격 때문에 친구들과 어울리기 어려울 수 있고, 무엇을 해도 불안정해 보일 수 있어요.
여러분의 장점을 살리려면, 생각을 행동으로 옮기는 연습을 하고 책임감 있게 행동하려고 노력해야 해요!`,
    result: `기억하세요! 이 타입은 '머리 좋은 슬로우 모션' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 뛰어난 사고력은 정말 대단해요. 하지만 가끔은 생각을 행동으로 옮기고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 '빠른 고양이'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 천재 고양이"로 불릴 수 있어요. 머리는 좋지만 게으른 모습이 고양이 같죠.
수업 시간에는 모든 걸 이해하지만, 숙제는 미루고 미루다 결국 안 하는 경우가 많아요. 조금씩이라도 실천하는 습관을 들여보세요!
조별 과제를 할 때는 좋은 아이디어를 많이 내요. 하지만 실제로 행동으로 옮기는 건 어려워해요. 아이디어를 실천하는 연습을 해보세요.
동아리 활동은 혼자 할 수 있는 걸 선호해요. 하지만 가끔은 단체 활동에도 참여해보는 게 어떨까요?
진로를 고민할 때는 여러분의 논리력을 살릴 수 있는 직업을 생각해보세요. 하지만 어떤 직업이든 열정과 실행력이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 지속적인 관계를 유지하려면 많은 이해심이 필요해요. 그들의 생각을 행동으로 옮기도록 도와주세요.
- 선생님
이 선생님의 수업 방식이 때로 체계적이지 않을 수 있습니다. 따라서 여러분의 자기주도적 학습 능력이 특히 중요할 수 있어요. 수업 내용을 꼼꼼히 정리하고 추가로 자료를 찾아 공부하는 습관을 기르세요. 이러한 적극적인 학습 태도는 여러분의 학업 성취도를 높이고, 장기적으로 독립적인 학습 능력을 향상시키는 데 큰 도움이 될 것입니다.
- 선배
이 타입의 선배는 실행력이 부족해서 일이 잘 진행되지 않을 수 있어요. 선배의 아이디어는 참고하되, 실천은 여러분이 해보세요.
- 친구, 후배
이 타입의 친구나 후배와 함께 일하려면 많은 도움과 격려가 필요해요. 그들의 아이디어를 존중하면서 실천을 도와주세요.`,
  },

  CCBAA: {
    type: "CCBAA",
    category: "유아적인 자아가 강한 유형",
    desc: `'영원한 피터팬' 타입`,
    sub: `학교의 숨은 애교 요정!`,
    char: `여러분은 진정한 '영원한 피터팬' 타입이에요! 항상 누군가에게 기대고 싶어 하는 "애교 만렙" 스타일이죠.
세상을 바라보는 눈이 좀 어린아이 같아요. 친구들에게 버림받는 게 무서워서 항상 남의 기분을 맞추려고 해요.
자신의 생각을 당당하게 말하는 게 어렵고, 누군가가 등을 돌려도 "그냥 그럴 수 있지~"라고 넘어가버려요.
인생의 목표나 꿈이 뚜렷하지 않고, 책임감도 좀 부족해요. 다른 사람들을 이해하거나 공정하게 대하는 것도 어려워해요.
강한 의지와 자신감을 키우고, 책임감 있게 행동하는 연습이 꼭 필요해요!`,
    result: `기억하세요! 이 타입은 '영원한 피터팬' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 귀여운 매력은 정말 대단해요. 하지만 가끔은 책임감 있게 행동하고, 스스로 결정하는 연습을 해보세요. 그러면 더 멋진 '어른 피터팬'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "애교 요정"으로 불릴 수 있어요. 귀엽지만 가끔은 너무 어리게 행동할 수 있어요.
수업 시간에는 선생님 말씀을 잘 듣지만, 스스로 공부하는 건 어려워해요. 혼자서도 공부할 수 있는 습관을 들여보세요!
조별 과제를 할 때는 다른 친구들에게 의지하려고 해요. 하지만 여러분의 역할도 중요해요. 책임감 있게 자신의 몫을 해내보세요.
동아리 활동은 친구들과 함께하는 걸 좋아해요. 하지만 가끔은 리더 역할에 도전해보는 것도 좋아요!
진로를 고민할 때는 아이들과 관련된 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 책임감과 독립성이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구는 어린아이처럼 행동할 수 있어요. 많이 이해해주고 도와줘야 해요. 하지만 가끔은 스스로 할 수 있게 격려해주세요.
- 선생님
이 선생님의 수업 방식이 여러분에게 다소 도전적으로 느껴질 수 있습니다. 이런 상황에서는 자기주도적 학습 능력을 키우는 것이 중요해요. 수업 내용을 스스로 정리하고 추가로 공부하는 습관을 들이세요. 이러한 적극적인 학습 태도는 여러분의 이해도를 높이고 학업 성취에 큰 도움이 될 것입니다.
- 선배
이 타입의 선배는 결단력이 부족하고 일관성이 없을 수 있어요. 선배의 조언은 참고만 하고, 스스로 판단하세요.
- 친구, 후배
이 타입의 친구나 후배에게는 명확한 지시가 필요해요. 무엇을 해야 할지 정확히 알려주고, 함께 해나가세요.`,
  },

  CCBAB: {
    type: "CCBAB",
    category: "자신의 즐거움만을 찾는 유형",
    desc: `'인생은 롤러코스터 YOLO' 타입`,
    sub: `학교의 숨은 파티 킹!`,
    char: `여러분은 진정한 '인생은 롤러코스터 YOLO' 타입이에요! 즐거움을 최고로 여기는 "파티 킹" 스타일이죠.
공부하는 것보다 노는 걸 훨씬 좋아해요. 자신의 기분이 제일 중요하고, 즐겁게 노는 것에만 관심이 있어요.
주변 상황이나 친구들의 일은 별로 신경 쓰지 않아요. 의리나 배려심도 좀 부족한 편이에요.
자신의 즐거움만 생각하다 보니 공정함이나 책임감도 부족해요. 주변 친구들이 "이기적이다"라고 생각할 수 있어요.
이대로 가면 친구들이 점점 멀어질 수 있고, 결국 외로워질 수 있어요.
변하는 게 쉽진 않겠지만, 주변을 둘러보고 함께 즐거워하는 법을 배우면 더 멋진 미래가 기다리고 있을 거예요!`,
    result: `기억하세요! 이 타입은 '인생은 롤러코스터 YOLO' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 즐거움을 아는 센스는 정말 대단해요. 하지만 가끔은 주변을 둘러보고, 함께 즐거워하는 법을 배워보세요. 그러면 더 멋진 '모두의 YOLO 킹'이 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 파티 킹"으로 불릴 수 있어요. 재미있는 일에는 열정 만점이지만, 공부에는 관심이 없죠.
수업 시간에는 주로 딴짓을 하거나 졸고 있을 거예요. 하지만 가끔은 수업에 집중해보는 것도 좋아요!
조별 과제를 할 때는 재미있는 아이디어는 잘 내지만, 실제로 일하는 건 싫어해요. 친구들과 공평하게 일을 나누는 연습을 해보세요.
동아리 활동은 즐거운 것만 골라서 해요. 하지만 책임감 있게 끝까지 활동하는 것도 중요해요.
진로를 고민할 때는 재미있는 직업만 생각하기 쉬워요. 하지만 어떤 직업이든 책임감과 노력이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구는 자기 기분만 생각하고 여러분이 모든 걸 맞춰주길 바랄 거예요. 이해하고 받아들일 준비가 필요해요.
- 선생님
이 선생님의 교육 방식이 여러분의 학습 스타일과 잘 맞지 않을 수 있습니다. 따라서 다양한 학습 경험을 쌓는 것이 도움이 될 수 있어요. 다른 선생님들의 조언을 구하고, 추가적인 학습 자료를 찾아보는 것도 좋은 방법입니다. 이러한 다각도적인 접근은 여러분의 교육 경험을 풍부하게 하고, 전반적인 학습 효과를 높일 수 있을 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면 여러모로 힘들 수 있으니 조심해야 해요.
.친구, 후배
이 타입의 친구나 후배는 단체 활동에 잘 어울리지 못하고, 일도 잘 안 할 거예요. 강하게 이끌어주는 게 필요해요.`,
  },

  CCBAC: {
    type: "CCBAC",
    category: "쉽고 즐겁게 살고자 하는 유형",
    desc: `'자유영혼 편안러' 타입`,
    sub: `학교의 숨은 여유 챔피언!`,
    char: `여러분은 진정한 '자유영혼 편안러' 타입이에요! 자유롭게 살면서 편하게 즐기는 걸 최고로 여기는 "여유 챔피언" 스타일이죠.
주변 상황에 별로 신경 쓰지 않고 하고 싶은 대로 행동해요. "내 맘대로" 정신이 넘치죠!
의지와 끈기가 좀 부족하고, 노력은 적게 하면서 큰 결과를 바라는 편이에요. 힘든 일은 정말 싫어해요.
"어떻게 하면 쉽고 편하게 용돈을 많이 벌 수 있을까?"하는 생각을 자주 해요.
하지만 현실에서 쉬운 일을 찾기 어렵다고 생각해서, 가끔은 위험한 방법으로 돈을 벌고 싶어할 수 있어요. 이건 정말 위험해요!
세상은 우리가 바라는 대로 쉽게 돌아가지 않아요. 열심히 노력하고 부지런히 움직여야 원하는 걸 이룰 수 있다는 걸 기억하세요!`,
    result: `기억하세요! 이 타입은 '자유영혼 편안러' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 여유로운 모습은 멋져요. 하지만 가끔은 열심히 노력하고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 '자유로운 성공러'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "여유 챔피언"으로 불릴 수 있어요. 편하게 살려고 하지만, 그게 항상 좋은 건 아니에요.
수업 시간에는 편하게 앉아있기만 하고 싶어해요. 하지만 가끔은 적극적으로 참여해보는 것도 좋아요!
조별 과제를 할 때는 쉬운 일만 하려고 해요. 하지만 어려운 일에 도전해보는 것도 중요해요. 그래야 성장할 수 있어요.
동아리 활동은 재미있고 편한 것만 골라서 해요. 하지만 책임감 있게 끝까지 활동하는 것도 배워보세요.
진로를 고민할 때는 편하고 쉬운 직업만 생각하기 쉬워요. 하지만 어떤 직업이든 노력과 책임감이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구는 편한 것만 좋아하고 노력하지 않으려고 해요. 미래를 함께 계획하고 싶다면 신중히 생각해보세요.
- 선생님
이 선생님의 의견이 상황에 따라 다양하게 표현될 수 있습니다. 선생님의 지도를 경청하되, 동시에 비판적 사고력을 기르는 것이 중요해요. 수업 내용을 주의 깊게 듣고 정리하면서, 스스로 분석하고 판단하는 능력을 키우세요. 이러한 균형 잡힌 접근은 여러분의 학습 경험을 풍부하게 하고 독립적인 사고력 향상에 도움이 될 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면 그 선배보다는 학교 자체에 대해 다시 생각해봐야 할 수도 있어요.
- 친구, 후배
이 타입의 친구나 후배는 맡은 일을 끝까지 하지 않을 수 있어요. 함께 공부하거나 일할 때는 꼭 확인하고 도와주세요.`,
  },

  CCBBA: {
    type: "CCBBA",
    category: "남에게 의존하며 살아가는 유형",
    desc: `'인생 편한 길 서핑러' 타입`,
    sub: `학교의 숨은 의존 챔피언!`,
    char: `여러분은 진정한 '인생 편한 길 서핑러' 타입이에요! 다른 사람에게 기대어 편하게 살고 싶어 하는 "의존 챔피언" 스타일이죠.
친구들을 배려하거나 그들 입장에서 생각하는 건 좀 어려워해요. 큰 꿈을 향한 열정도 그리 높지 않아요.
혼자 힘으로 뭔가를 하려는 의지가 좀 부족해요. 대신 다른 사람에게 의지하려는 마음이 너무 커요.
자신의 미래를 스스로 만들어가고 책임지는 건 너무 힘들고 어렵게 느껴져요.
주변에 힘 있는 사람을 찾아 의지하려고 해요. 스스로 뭔가를 찾아서 하기보다는 남에게 의존하려고만 해요.
기본적인 판단력은 있지만, 올바른 행동이 뭔지, 어떻게 자립해야 할지는 잘 모르는 것 같아요. 대신 어떻게 하면 쉽고 편하게 살 수 있을지만 고민해요.
스스로 설 수 있는 힘을 기르고, 매 순간 자신의 미래를 위해 현명하게 판단하는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '인생 편한 길 서핑러' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 편안함을 추구하는 마음은 이해해요. 하지만 가끔은 스스로 힘을 길러보고, 책임감 있게 행동하는 연습을 해보세요. 그러면 더 멋진 '인생 주도적 서퍼'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 의존 챔피언"으로 불릴 수 있어요. 항상 누군가에게 기대려고 하죠.
수업 시간에는 친구들에게 의지해서 공부해요. 하지만 가끔은 스스로 공부하는 습관을 들여보는 게 어떨까요?
조별 과제를 할 때는 다른 친구들에게 모든 걸 맡기려고 해요. 하지만 여러분의 역할도 중요해요. 작은 일부터 책임지고 해보세요!
동아리 활동은 친구들이 이끌어주는 대로 따라가요. 하지만 가끔은 여러분이 주도적으로 활동을 제안해보는 것도 좋아요!
진로를 고민할 때는 "그냥 편한 게 최고야~"라고 생각하기 쉬워요. 하지만 미래를 위해 꿈을 갖고 노력하는 것도 중요해요!`,
    human: `- 베프, 이성친구
이 친구는 마치 어린아이처럼 여러분이 모든 걸 해주길 바랄 거예요. 정말 사랑한다면 이해하고 받아줄 수 있겠지만, 쉽지 않을 거예요.
- 선생님
이 선생님은 학생들의 자율성과 주도적인 학습을 중요하게 여기실 수 있습니다. 선생님의 지도를 따르면서도, 스스로 학습 계획을 세우고 실천하는 능력을 기르는 것이 중요해요. 수업에 적극적으로 참여하고 협력하되, 동시에 자기주도적 학습 습관을 들이세요. 이러한 균형 잡힌 접근은 여러분의 학업 능력과 독립성을 함께 향상시키는 데 도움이 될 것입니다.
- 선배
이 타입의 선배는 리더십이 부족할 거예요. 항상 남에게 의지하려고 하니, 여러분이 분명한 선을 그어야 해요.
- 친구, 후배
이 타입의 친구나 후배에게 무엇을 시킬지 잘 생각해봐야 해요. 그들이 잘할 수 있는 일을 찾아 천천히 도와주세요.`,
  },

  CCBBB: {
    type: "CCBBB",
    category: "오직 자기 길로만 가는 유형",
    desc: `'마이웨이 올라운더' 타입`,
    sub: `학교의 숨은 자유로운 영혼!`,
    char: `여러분은 진정한 '마이웨이 올라운더' 타입이에요! 다른 사람에게 심한 짓은 안 하지만, 학교 규칙이나 기본 매너는 좀 무시하는 "자유로운 영혼" 스타일이죠.
자기 마음대로 행동하는 걸 좋아하지만, 너무 위험하거나 나쁜 일은 절대 안 해요.
친구들과 어울리는 것보다는 혼자만의 시간을 더 좋아해요. 의리나 배려 같은 건 별로 신경 쓰지 않고, 그냥 하고 싶은 대로 살면 그걸로 만족해요.
하지만 기본적인 규칙을 너무 무시하면 나중에 후회할 일이 생길 수 있어요.
학교 규칙을 지키려고 노력하고, 가끔은 다른 사람 입장에서 생각해보는 연습이 필요해요!`,
    result: `기억하세요! 이 타입은 '마이웨이 올라운더' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 자유로운 영혼은 정말 멋져요. 하지만 가끔은 규칙을 지키고, 다른 사람을 배려하는 연습을 해보세요. 그러면 더 멋진 '자유로운 리더'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "자유로운 영혼"으로 불릴 수 있어요. 규칙에 얽매이지 않고 자유롭게 행동하죠.
수업 시간에는 선생님 말씀보다 자기 생각에 더 집중해요. 하지만 가끔은 수업에 참여해보는 것도 좋아요!
조별 과제를 할 때는 자기 방식대로만 하려고 해요. 하지만 친구들의 의견도 들어보고 협력하는 연습을 해보세요.
동아리 활동은 자유로운 분위기의 동아리를 선호해요. 하지만 규칙이 있는 동아리에서 활동해보는 것도 좋은 경험이 될 거예요.
진로를 고민할 때는 자유롭게 일할 수 있는 직업을 생각해보세요. 하지만 어떤 직업이든 기본 규칙은 있다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 깊은 관계를 맺으려면 많은 이해가 필요해요. 그들의 자유로운 성격을 존중하되, 기본적인 배려도 요구해보세요.
- 선생님
이 선생님은 유연한 수업 방식을 선호하실 수 있습니다. 선생님의 창의적인 접근을 통해 새로운 학습 경험을 즐기되, 중요한 내용은 스스로 꼼꼼히 정리하는 습관을 들이세요. 이러한 균형 잡힌 태도는 자유로운 수업 분위기에서도 핵심을 놓치지 않고 효과적으로 학습할 수 있게 도와줄 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면 여러분이 상처받지 않도록 조심해야 해요.
- 친구, 후배
이 타입의 친구나 후배는 통제하기 어려울 수 있어요. 그들의 개성을 인정하되, 기본적인 규칙은 함께 지키자고 제안해보세요.`,
  },

  CCBBC: {
    type: "CCBBC",
    category: "마음이 꼬여 있는 자기 위주 유형",
    desc: `'꼬인 마음의 솔로 플레이어' 타입`,
    sub: `학교의 숨은 4차원 반항아!`,
    char: `여러분은 진정한 '꼬인 마음의 솔로 플레이어' 타입이에요! 세상을 좀 다르게 보는 "4차원 반항아" 스타일이죠.
눈앞의 일에만 집중하고, 학교 규칙 같은 건 "에이, 뭐 어떻게든 되겠지~"라고 생각해요.
자기 마음대로 하고 싶어 하고, 누가 뭐라고 하면 폭발할 수 있어요. 세상이 무섭지 않아 보이는데, 사실 많이 다쳐서 그런 걸 수도 있어요.
때로는 인생이 너무 힘들게 느껴져서 포기하고 싶을 수도 있어요. 그럴 때는 꼭 주변에 도움을 요청하세요!
너무 앞만 보고 달리다 보니 행복한 순간들을 놓치고 있을 수 있어요.
균형 잡힌 생활을 하면 더 편하고 즐겁게 살 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '꼬인 마음의 솔로 플레이어' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 시각은 정말 특별해요. 하지만 가끔은 다른 사람의 의견도 듣고, 규칙을 지키는 연습을 해보세요. 그러면 더 멋진 '팀 플레이 가능한 솔로 플레이어'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "4차원 반항아"로 불릴 수 있어요. 독특한 생각과 행동으로 주목받지만, 그게 항상 좋은 건 아니에요.
수업 시간에는 선생님 말씀보다 자기 생각에 빠져있어요. 하지만 가끔은 수업에 집중해보는 것도 좋아요!
조별 과제를 할 때는 자기 방식대로만 하려고 해요. 하지만 친구들의 의견도 들어보고 협력하는 연습을 해보세요.
동아리 활동은 잘 안 하려고 해요. 하지만 동아리 활동을 통해 새로운 친구들을 사귀고 재미있는 경험을 할 수 있어요.
진로를 고민할 때는 자유롭게 일할 수 있는 직업을 생각해보세요. 하지만 어떤 직업이든 규칙과 협력이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구와 깊은 관계를 맺는 건 쉽지 않아요. 신중하게 생각해보고 결정하세요.
- 선생님
이 선생님과의 학습 경험이 때로 도전적일 수 있습니다. 선생님의 지도를 존중하면서도, 다양한 교육적 관점을 접하는 것이 도움될 수 있어요. 다른 선생님들의 조언도 참고하고, 여러 학습 방법을 탐색해보세요. 이러한 균형 잡힌 접근은 여러분의 학습 경험을 풍부하게 하고, 스트레스를 줄이는 데 도움이 될 수 있습니다.
- 선배
이런 타입의 선배를 만나기는 정말 어려울 거예요. 만약 있다면 조심해서 대해야 해요.
- 친구, 후배
이 타입의 친구나 후배와 함께 일하는 건 어려울 수 있어요. 잠깐 함께 하는 건 괜찮지만, 오래 함께 하기는 힘들 수 있어요.`,
  },

  CCBCA: {
    type: "CCBCA",
    category: "남이 이끌어주기 바라는 유형",
    desc: `'인생 내비게이션 찾는 중' 타입`,
    sub: `학교의 숨은 서포터!`,
    char: `여러분은 진정한 '인생 내비게이션 찾는 중' 타입이에요! 다른 사람의 리드를 따르는 게 편한 "숨은 서포터" 스타일이죠.
자신의 욕구는 뒤로 하고, 즐기는 것도 잘 모르는 편이에요. 조금 소극적이고 어두운 성격일 수 있어요.
대부분 모든 걸 다른 사람 의견대로 하려고 해요. 자신의 생각을 말하는 건 무서워해요. 왜냐하면 다른 사람 의견을 따르면 책임을 지지 않아도 된다고 생각하거든요.
다른 사람에게 너무 의존하는 경향이 있어요. 이러다 보면 '쉬운 사람'으로 보일 수 있어요.
자신의 생각과 감정을 먼저 생각해보는 게 중요해요. 자신감을 갖고 적극적으로 행동하는 연습이 필요해요. 또 뭐가 옳고 그른지 판단하는 능력을 키우면 더 밝은 미래를 만들 수 있을 거예요!`,
    result: `기억하세요! 이 타입은 '인생 내비게이션 찾는 중' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 서포터 능력은 정말 대단해요. 하지만 가끔은 자신의 의견을 말하고, 스스로 결정하는 연습을 해보세요. 그러면 더 멋진 '인생 내비게이션 마스터'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 서포터"로 불릴 수 있어요. 리더보다는 조용히 돕는 역할을 선호하죠.
수업 시간에는 선생님 말씀을 잘 듣지만, 질문이나 의견 말하기를 어려워해요. 가끔은 용기를 내서 자신의 생각을 말해보세요!
조별 과제를 할 때는 다른 친구들 의견을 따르기만 해요. 하지만 여러분의 아이디어도 중요해요. 한 번씩 제안해보세요!
동아리 활동은 친구들이 이끄는 대로 따라가요. 하지만 가끔은 여러분이 하고 싶은 활동을 선택해보는 것도 좋아요!
진로를 고민할 때는 다른 사람을 돕는 직업을 생각해볼 수 있어요. 하지만 어떤 직업이든 자신의 의견을 말하는 능력이 필요하다는 걸 기억하세요!`,
    human: `- 베프, 이성친구
이 친구는 여러분을 정말 사랑하고 모든 걸 해줄 거예요. 하지만 너무 의지하면 여러분이 힘들어질 수 있어요. 서로의 의견을 나누는 연습을 해보세요.
- 선생님
이 선생님은 학생들의 의견을 존중하고 수용적인 태도를 보이실 수 있습니다. 하지만 때로는 선생님의 솔직한 생각을 파악하기 어려울 수 있어요. 선생님께 적극적으로 질문하고 의견을 구하는 습관을 기르세요. 이러한 열린 소통은 더 깊이 있는 학습과 상호 이해를 촉진할 수 있습니다.
- 선배
이런 타입의 선배를 만나기는 정말 어려울 거예요.
- 친구, 후배
이 타입의 친구나 후배는 스스로 일을 시작하긴 어려워해요. 간단하고 명확한 일을 맡기고, 잘 했을 때 칭찬해주세요.`,
  },

  CCBCB: {
    type: "CCBCB",
    category: "웅크리고 미동도 하지 않는 유형",
    desc: `'그림자 속 숨은 보석' 타입`,
    sub: `학교의 숨은 잠재력 킹!`,
    char: `여러분은 진정한 '그림자 속 숨은 보석' 타입이에요! 꿈이나 목표가 없는 것처럼 보이는 "숨은 잠재력 킹" 스타일이죠.
마치 어두운 구름 속에 갇혀 있는 것 같아 보여요. 주변 사람들은 여러분을 좀 어둡다고 생각할 수 있어요.
책임감이나 의리 같은 것도 좀 부족해 보일 수 있어요.
어느 정도 남을 배려할 줄 알고, 생각하는 능력도 있어서 그럭저럭 살아갈 수 있어요. 하지만 주목받는 걸 싫어하고, 보이지 않는 곳에서 시간을 보내고 싶어해요.
여러분은 편하다고 생각할 수 있지만, 다른 사람들은 "인생에서 뒤처진 사람"이라고 생각할 수 있어요.
이대로 가다간 다시 일어서기 힘들 수 있어요. 열정을 갖고 멋진 미래를 만들어가는 노력이 필요해요!`,
    result: `기억하세요! 이 타입은 '그림자 속 숨은 보석' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분 안에 숨겨진 보석 같은 재능이 있다는 걸 믿어요. 조금씩 용기를 내서 그 재능을 보여주세요. 그러면 더 멋진 '빛나는 다이아몬드'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 보석"으로 불릴 수 있어요. 잠재력은 있지만 아직 빛을 발하지 못하고 있죠.
수업 시간에는 조용히 앉아있기만 해요. 하지만 가끔은 질문도 하고 의견도 말해보는 게 어떨까요?
조별 과제를 할 때는 참여하기 싫어해요. 하지만 여러분의 숨겨진 능력이 팀에 도움이 될 수 있어요. 한 번 시도해보세요!
동아리 활동은 잘 안 하려고 해요. 하지만 동아리를 통해 새로운 재능을 발견할 수 있어요. 한 번 도전해보는 건 어떨까요?
진로를 고민할 때는 "그냥 평범하게 살래~"라고 생각하기 쉬워요. 하지만 여러분의 숨겨진 재능을 찾아보는 것도 좋아요!`,
    human: `- 베프, 이성친구
이 친구와 밝은 미래를 만들어가기는 어려울 수 있어요. 깊은 관계를 원한다면 많은 이해와 노력이 필요해요.
- 선생님
이 선생님은 학생들의 자율성과 독립적인 학습을 중요하게 여기실 수 있습니다. 이런 환경에서는 자기주도적 학습 능력을 키우는 것이 중요해요. 동시에 다양한 교육적 관점을 접하기 위해 다른 선생님들의 조언도 구해보세요. 이러한 균형 잡힌 접근은 여러분의 학습 경험을 풍부하게 하고, 종합적인 성장을 도울 수 있습니다.
- 선배
이런 타입의 선배를 만나기는 정말 어려울 거예요.
- 친구, 후배
이 타입의 친구나 후배는 일을 잘 안 하려고 해요. 많은 도움과 격려가 필요하지만, 쉽게 변하기 어려울 수 있어요. 선생님의 도움을 받아보는 것도 좋아요.`,
  },

  CCBCC: {
    type: "CCBCC",
    category: "정상적인 사회생활이 힘든 유형",
    desc: `'마이웨이 아웃사이더' 타입`,
    sub: `학교의 숨은 반전 주인공!`,
    char: `여러분은 진정한 '마이웨이 아웃사이더' 타입이에요! 어느 정도 판단력은 있지만, 학교생활에서 좀 어려움을 겪을 수 있는 "숨은 반전 주인공" 스타일이죠.
의지력이 좀 약하고, 친구들을 배려하는 마음도 부족한 편이에요. 가끔은 차갑고 냉담하게 대할 때도 있어요.
사람들과 가까이 지내는 걸 불편해하고, 때로는 적대적이거나 반항적인 모습을 보일 수 있어요.
자신의 감정을 잘 표현하지 못하고, 마음속 깊이 빠져있는 것 같아요.
이런 모습들을 혼자 극복하기는 어려울 수 있어요. 주변의 도움을 받아보는 것도 좋은 방법이에요!`,
    result: `기억하세요! 이 타입은 '마이웨이 아웃사이더' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분만의 독특한 시각과 생각은 정말 특별해요. 조금씩 용기를 내서 그 모습을 보여주세요. 그러면 더 멋진 '마이웨이 인사이더'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "숨은 반전 주인공"으로 불릴 수 있어요. 잠재력은 있지만 아직 그걸 보여주기 어려워하죠.
수업 시간에는 집중하기 어려워해요. 하지만 관심 있는 주제가 나오면 깊이 있는 생각을 할 수 있어요. 그런 모습을 보여주세요!
조별 과제를 할 때는 참여하기 싫어해요. 하지만 여러분의 독특한 시각이 팀에 도움이 될 수 있어요. 용기를 내서 의견을 말해보세요!
동아리 활동은 잘 안 하려고 해요. 하지만 동아리를 통해 비슷한 관심사를 가진 친구들을 만날 수 있어요. 한 번 시도해보는 건 어떨까요?
진로를 고민할 때는 "그냥 내 길로 갈래~"라고 생각하기 쉬워요. 하지만 전문가의 조언을 들어보는 것도 좋아요!`,
    human: `- 베프, 애인
이 친구와 깊은 관계를 맺기는 어려울 수 있어요. 많은 이해와 노력이 필요해요.
- 선생님
이 선생님과의 학습 경험이 때로 도전적일 수 있습니다. 따라서 다양한 교육적 접근을 경험하는 것이 도움이 될 수 있어요. 다른 선생님들의 조언을 구하고 추가적인 학습 자료를 찾아보세요. 이러한 다각도적인 접근은 여러분의 학습을 풍부하게 하고, 전반적인 교육 경험을 향상시킬 수 있을 것입니다.
- 선배
이런 타입의 선배를 만나기는 정말 어려울 거예요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 다른 친구들이 힘들어할 수 있어요. 선생님이나 학교 상담 선생님의 도움을 받아보는 게 좋아요.`,
  },

  CCCAA: {
    type: "CCCAA",
    category: "앞뒤 구분 못하는 불나방 유형",
    desc: `'불꽃 놀이 마스터' 타입`,
    sub: `학교의 숨은 핫이슈 메이커!`,
    char: `여러분은 진정한 '불꽃 놀이 마스터' 타입이에요! 학교생활에 적응하기 좀 어려운 "핫이슈 메이커" 스타일이죠.
상황 판단력이 좀 부족하고, 학교 규칙이나 도덕성에 대해 잘 모를 수 있어요. 친구들을 배려하는 것도 어려워해요.
자기 마음대로 행동하려고 하고, 때로는 다른 사람에게 너무 의지하거나 반대로 혼자서만 행동해서 주변 사람들을 힘들게 할 수 있어요.
재미있는 것만 찾아다니고, 그게 위험하더라도 상관없이 뛰어들어요. 게임, 오토바이 등에 과하게 빠질 수 있어요.
주변 사람들의 도움도 필요하지만, 스스로 깨닫는 것이 가장 중요해요. 현실을 제대로 보는 눈을 키우고, 학교 규칙에 대해 계속 배워나가야 해요.`,
    result: `기억하세요! 이 타입은 '불꽃 놀이 마스터' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 에너지 넘치는 모습은 정말 멋져요. 하지만 그 에너지를 좋은 곳에 사용하는 법을 배워보세요. 규칙을 지키고 친구들을 배려하면서 그 에너지를 발산하면, 더 멋진 '불꽃 축제 기획자'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "핫이슈 메이커"로 불릴 수 있어요. 항상 뭔가 일이 생기는 중심에 있죠.
수업 시간에는 집중하기 어려워해요. 재미있는 과목이 아니면 딴짓을 하거나 잠을 자기 쉬워요. 조금씩 집중하는 연습을 해보세요!
조별 과제를 할 때는 자기 마음대로 하려고 해요. 하지만 친구들과 협력하는 것도 중요한 능력이에요. 다른 사람 의견도 들어보세요.
동아리 활동은 재미있는 것만 골라서 해요. 하지만 책임감 있게 끝까지 활동하는 것도 배워야 해요.
진로를 고민할 때는 즐거운 것만 생각하기 쉬워요. 하지만 현실적으로 어떤 직업이 있는지 알아보는 것도 중요해요!`,
    human: `- 베프, 이성친구
이 친구와 깊은 관계를 맺는 건 어려울 수 있어요. 쉽게 마음이 변할 수 있고, 책임감이 부족할 수 있어요. 신중하게 생각해보세요.
- 선생님
이 선생님의 수업 방식이 여러분에게 다소 어렵게 느껴질 수 있습니다. 학습 과정에서 예상치 못한 어려움이 있을 수 있으니, 다양한 교육 자원을 활용하는 것이 도움이 될 수 있어요. 다른 선생님들의 조언을 구하고, 추가 학습 자료를 찾아보세요. 이러한 적극적인 접근은 여러분의 학습 경험을 풍부하게 하고, 잠재적인 문제를 해결하는 데 도움이 될 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면, 그 선배의 행동을 따라하지 말고 주의해서 지내세요.
- 친구, 후배
이 타입의 친구나 후배는 학교에 문제를 일으킬 수 있어요. 그들의 행동에 휘말리지 않도록 조심하고, 필요하다면 선생님께 도움을 요청하세요.`,
  },

  CCCAB: {
    type: "CCCAB",
    category: "앞뒤 구분 못하는 불나방 유형",
    desc: `'불꽃 놀이 마스터 2.0' 타입`,
    sub: `학교의 숨은 핫이슈 메이커!`,
    char: `여러분은 진정한 '불꽃 놀이 마스터 2.0' 타입이에요! 학교생활에 적응하기 좀 어려운 "핫이슈 메이커" 스타일이죠.
상황 판단력이 좀 부족하고, 학교 규칙이나 도덕성에 대해 잘 모를 수 있어요. 친구들을 배려하는 것도 어려워해요.
자기 마음대로 행동하려고 하고, 때로는 다른 사람에게 너무 의지하거나 반대로 혼자서만 행동해서 주변 사람들을 힘들게 할 수 있어요.
재미있는 것만 찾아다니고, 그게 위험하더라도 상관없이 뛰어들어요. 게임, 오토바이 등에 과하게 빠질 수 있어요.
주변 사람들의 도움도 필요하지만, 스스로 깨닫는 것이 가장 중요해요. 현실을 제대로 보는 눈을 키우고, 학교 규칙에 대해 계속 배워나가야 해요.`,
    result: `기억하세요! 이 타입은 '불꽃 놀이 마스터 2.0' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 에너지 넘치는 모습은 정말 멋져요. 하지만 그 에너지를 좋은 곳에 사용하는 법을 배워보세요. 규칙을 지키고 친구들을 배려하면서 그 에너지를 발산하면, 더 멋진 '불꽃 축제 기획자'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "핫이슈 메이커"로 불릴 수 있어요. 항상 뭔가 일이 생기는 중심에 있죠.
수업 시간에는 집중하기 어려워해요. 재미있는 과목이 아니면 딴짓을 하거나 잠을 자기 쉬워요. 조금씩 집중하는 연습을 해보세요!
조별 과제를 할 때는 자기 마음대로 하려고 해요. 하지만 친구들과 협력하는 것도 중요한 능력이에요. 다른 사람 의견도 들어보세요.
동아리 활동은 재미있는 것만 골라서 해요. 하지만 책임감 있게 끝까지 활동하는 것도 배워야 해요.
진로를 고민할 때는 즐거운 것만 생각하기 쉬워요. 하지만 현실적으로 어떤 직업이 있는지 알아보는 것도 중요해요!`,
    human: `- 베프, 이성친구
이 친구와 깊은 관계를 맺는 건 어려울 수 있어요. 쉽게 마음이 변할 수 있고, 책임감이 부족할 수 있어요. 신중하게 생각해보세요.
- 선생님
이 선생님의 수업 방식이 여러분에게 다소 도전적일 수 있습니다. 학습 과정에서 어려움을 느낄 수 있으니, 다양한 학습 자원을 활용하는 것이 좋습니다. 다른 선생님들의 조언을 구하고 추가 학습 자료를 찾아보세요. 이런 적극적인 태도는 여러분의 학습 경험을 풍부하게 하고 잠재적인 어려움을 극복하는 데 도움이 될 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면, 그 선배의 행동을 따라하지 말고 주의해서 지내세요.
- 친구, 후배
이 타입의 친구나 후배는 학교에 문제를 일으킬 수 있어요. 그들의 행동에 휘말리지 않도록 조심하고, 필요하다면 선생님께 도움을 요청하세요.`,
  },

  CCCAC: {
    type: "CCCAC",
    category: "앞뒤 구분 못하는 불나방 유형",
    desc: `'불꽃 놀이 마스터 3.0' 타입`,
    sub: `학교의 숨은 핫이슈 메이커!`,
    char: `여러분은 진정한 '불꽃 놀이 마스터 3.0' 타입이에요! 학교생활에 적응하기 좀 어려운 "핫이슈 메이커" 스타일이죠.
상황 판단력이 좀 부족하고, 학교 규칙이나 도덕성에 대해 잘 모를 수 있어요. 친구들을 배려하는 것도 어려워해요.
자기 마음대로 행동하려고 하고, 때로는 다른 사람에게 너무 의지하거나 반대로 혼자서만 행동해서 주변 사람들을 힘들게 할 수 있어요.
재미있는 것만 찾아다니고, 그게 위험하더라도 상관없이 뛰어들어요. 게임, 오토바이 등에 과하게 빠질 수 있어요.
주변 사람들의 도움도 필요하지만, 스스로 깨닫는 것이 가장 중요해요. 현실을 제대로 보는 눈을 키우고, 학교 규칙에 대해 계속 배워나가야 해요.`,
    result: `기억하세요! 이 타입은 '불꽃 놀이 마스터 3.0' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 에너지 넘치는 모습은 정말 멋져요. 하지만 그 에너지를 좋은 곳에 사용하는 법을 배워보세요. 규칙을 지키고 친구들을 배려하면서 그 에너지를 발산하면, 더 멋진 '불꽃 축제 기획자'가 될 수 있을 거예요. 파이팅!`,
    school: `여러분은 학교에서 "핫이슈 메이커"로 불릴 수 있어요. 항상 뭔가 일이 생기는 중심에 있죠.
수업 시간에는 집중하기 어려워해요. 재미있는 과목이 아니면 딴짓을 하거나 잠을 자기 쉬워요. 조금씩 집중하는 연습을 해보세요!
조별 과제를 할 때는 자기 마음대로 하려고 해요. 하지만 친구들과 협력하는 것도 중요한 능력이에요. 다른 사람 의견도 들어보세요.
동아리 활동은 재미있는 것만 골라서 해요. 하지만 책임감 있게 끝까지 활동하는 것도 배워야 해요.
진로를 고민할 때는 즐거운 것만 생각하기 쉬워요. 하지만 현실적으로 어떤 직업이 있는지 알아보는 것도 중요해요!`,
    human: `- 베프, 이성친구
이 친구와 깊은 관계를 맺는 건 어려울 수 있어요. 쉽게 마음이 변할 수 있고, 책임감이 부족할 수 있어요. 신중하게 생각해보세요.
- 선생님
이 선생님의 수업 방식이 여러분에게 익숙하지 않을 수 있습니다. 학습 과정에서 예상치 못한 상황이 발생할 수 있으니, 다양한 학습 전략을 준비하는 것이 좋습니다. 다른 선생님들의 조언을 구하고 추가 학습 자료를 활용해보세요. 이러한 다각도적 접근은 여러분의 학습 경험을 풍부하게 하고 잠재적 어려움을 극복하는 데 도움이 될 것입니다.
- 선배
이런 타입의 선배를 만나기는 어려울 거예요. 만약 있다면, 그 선배의 행동을 따라하지 말고 주의해서 지내세요.
- 친구, 후배
이 타입의 친구나 후배는 학교에 문제를 일으킬 수 있어요. 그들의 행동에 휘말리지 않도록 조심하고, 필요하다면 선생님께 도움을 요청하세요.`,
  },

  CCCBA: {
    type: "CCCBA",
    category: "자기 존재마저 찾기 힘든 유형",
    desc: `'인생 NPC' 타입`,
    sub: `학교의 숨은 잠자는 숲속의 주인공!`,
    char: `여러분은 진정한 '인생 NPC' 타입이에요! 게임 속 NPC처럼 누군가가 대화 버튼을 눌러줘야 반응하는 스타일이에요.
다른 사람이 먼저 말 걸어줘야 대화를 시작하고, 질문하면 그에 맞는 대답은 하지만, 스스로 대화를 시작하는 경우는 드물어요. '어떻게 살아야 재미있을까?'에 대한 자신만의 생각이 부족해서 늘 다른 사람에게 기대며 편안함을 느껴요. 그런데도 다른 사람에 대한 호기심이나 배려, 애정 같은 감정은 거의 없다는 게 특징이에요.
현실을 보는 눈은 뛰어나서, 어떻게 하면 생존할 수 있는지 잘 알고 있고, 그걸 위해 인간관계를 활용하기도 해요. 하지만 만약 독특한 사람을 만나서 수동적이고 무의미한 인생에 대해 깊이 고민하게 된다면 위험한 상황에 빠질 수도 있어요.
여러분은 혼자 살아갈 의욕이 부족해서 어떻게든 주변 사람들과 협력하는 상황을 만들려고 해요. 여러분의 존재감을 키우고 싶다면, 다른 사람과의 관계에서 따뜻한 마음과 진심을 담아보세요. 그렇게 하면 여러분의 삶도 더 활기차고 의미 있게 변할 거예요. 따뜻한 관심과 진심이 담긴 관계는 여러분의 인생에 더 큰 만족을 가져다 줄 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '인생 NPC' 스타일이지만, '집중력'과 '시간 관리 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 적응력과 현실감각을 믿어요. 하지만 가끔은 스스로 결정하고, 시간을 효율적으로 쓰는 법을 배워보세요. 그러면 더 멋진 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입은 다른 사람에 대한 '진짜 신뢰'와 '자신감'을 키우는 게 중요해요. '자기'를 '믿는' 마음으로 하고 싶은 걸 '도전'하려는 '의지'가 부족해요.
'실패'나 '거절'에 대해 '고민'하기보다는 일단 '부딪혀' 경험을 쌓아야 해요. '본 게임' 도전하기 전에 '미니 게임'부터 해보세요.
수업 시간에 '혼자' 발표하는 건 어려워도, 친구들이랑 '팀 발표'는 해볼 수 있어요. "우리 같이 해보자"라고 말하는 연습을 해보세요.
동아리 활동은 '솔로' 활동보다 '단체' 활동을 찾아보세요. '연극부'나 '합창부'에서 다른 사람들이랑 '협력'하는 법을 배워보세요.
시험 기간에는 '혼자' 공부하는 게 어려울 수 있어요. '스터디 그룹'에 참여해서 친구들이랑 같이 공부해보세요.
진로를 고민할 때는 '솔로 플레이' 하지 말고 선생님이나 부모님께 조언을 구해보세요. 하지만 최종 결정은 '여러분'이 해야 해요!`,
    human: `- 베프, 이성친구
여러분이 '줄 수 있는 게' 많다면 '인연'이 될 수 있어요. 하지만 '밝고 재미있는' 삶은 포기해야 할지도 몰라요. 이 친구는 여러분에게 '현실'을 알려주고 '말 잘 듣는 것' 말고는 별로 도움 안 돼요.
- 선생님
이 선생님은 학생들과의 긍정적인 상호작용을 중요하게 여기실 수 있습니다. 선생님의 노력을 인정하고 격려하는 태도가 도움이 될 수 있어요. 단순한 수업 관계를 넘어 협력적인 학습 환경을 조성하는 것이 좋습니다. 수업에 적극적으로 참여하고 건설적인 피드백을 나누며, 함께 성장하는 분위기를 만들어가세요. 이는 더 풍부하고 의미 있는 학습 경험으로 이어질 수 있습니다.
- 선배
'선배'지만 여러분이 '리드'해야 할 때가 많을 거예요. '무능력'한 사람은 아니에요. 그냥 '태도'가 너무 '수동적'이고 자기를 잘 '드러내지' 못해서 그래요. 여러분이 '조금만' 진심으로 '응원'해주면 '예상 못 한' 좋은 결과를 줄 수도 있어요.
- 친구, 후배
'정리', '정돈', '통계', '숫자'랑 잘 맞는 친구예요. '액티브'한 일보다는 '한 곳'에서 '집중'할 수 있는 일에서 '잘' 해요. 친구들 사이에서 '존재감'이 약하지만 스스로 '드러내지' 못하는 성격이에요. '잊지 말고' 꼭 '챙겨'주세요.`,
  },

  CCCBB: {
    type: "CCCBB",
    category: "자기 존재마저 찾기 힘든 유형",
    desc: `'투명 인간 모드' 타입`,
    sub: `학교의 숨은 주인공을 찾아라!`,
    char: `여러분은 진정한 '투명 인간 모드' 타입이에요! 마치 투명 망토를 쓴 것처럼, 다른 사람들 사이에서도 존재감이 거의 없는 편이에요.
정신적으로나 육체적으로 '배터리 잔량 1%' 상태에 있는 것처럼 보이죠. 사회나 다른 사람들에 대한 관심이 거의 없고, 때로는 자기 자신에게조차도 관심이 없는 듯한 모습이에요. 현실 세계와는 단절된 채 자신만의 세계에 '잠금' 걸린 듯한 느낌이에요.
감정 표현이나 의사소통도 잘하지 않아서, 마치 '무표정 챌린지' 우승자가 될 듯해요. 자아 에너지가 너무 약해서 모든 것이 엉켜버린 이어폰처럼 복잡하고 꼬여버린 느낌이 들곤 해요.
하지만 여러분, 이런 상황에서 벗어나기 위해서는 작은 것부터 시도해보는 게 좋아요. 주변 사람들에게 관심을 조금 더 기울이고, 작은 감정이라도 표현해 보세요. 자신을 위해, 그리고 조금 더 활기찬 삶을 위해 배터리를 조금씩 충전해 보아요. 그렇게 하면 분명 더 나은 변화가 찾아올 거예요.`,
    result: `기억하세요! 이 타입은 '투명 인간 모드' 스타일이지만, '자아 찾기'와 '소통 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 독특한 내면세계를 믿어요. 조금씩 자신을 표현하고, 다른 사람들과 소통하는 법을 배워보세요. 그러면 더 빛나는 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '자아 찾기 퀘스트'가 가장 중요해요!
- '나만의 관심사 찾기': 좋아하는 것을 하나라도 찾아보세요. 만화, 게임, 음악 뭐든 좋아요.
- '소규모 그룹 활동': 큰 무리는 부담스러우니 2-3명의 친구들과 함께하는 활동을 해보세요.
- '상담 선생님과의 대화': 정기적으로 상담 선생님과 이야기를 나눠보세요.
- '일기 쓰기': 매일 짧게라도 자신의 감정을 글로 표현해보세요.
- '작은 목표 세우기': 하루에 한 번 발표하기, 친구에게 먼저 인사하기 등 작은 목표부터 시작해보세요.`,
    human: `- 베프, 이성친구
이 타입과 친해지려면 '인내심 만렙'이 필요해요. 갑자기 친해지기는 어려우니 천천히 다가가세요. 그들의 작은 변화나 노력을 알아차리고 칭찬해주는 게 중요해요.
- 선생님
이런 타입의 선생님이라면, 학생들이 먼저 다가가는 게 좋아요. 질문을 많이 하고, 수업에 적극적으로 참여해보세요. 선생님의 관심사를 파악해서 그것에 대해 이야기를 나눠보는 것도 좋아요.
- 선배
이런 타입의 선배를 만났다면, 여러분이 먼저 다가가야 할 거예요. 그들의 특기나 장점을 찾아 칭찬해주고, 작은 일이라도 함께 해보세요. 하지만 너무 많은 것을 기대하지는 마세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '인간 알람'이 되어주세요. 중요한 일정을 알려주고, 함께 참여할 수 있는 활동을 제안해보세요. 그들의 의견을 물어보고 천천히 대화를 이끌어가세요.`,
  },

  CCCBC: {
    type: "CCCBC",
    category: "자기 존재마저 찾기 힘든 유형",
    desc: `'고스트 모드 풀가동' 타입`,
    sub: `학교의 숨은 주인공을 깨워라!`,
    char: `여러분은 완벽한 '고스트 모드 풀가동' 타입이에요! 마치 투명 인간처럼 주변에 있어도 존재감이 거의 없는 듯해요.
정신적이든 육체적이든 항상 '배터리 0% 긴급 충전' 상태에 있는 것처럼 보이죠. 세상이나 다른 사람들에 대한 관심은 거의 없고, 때로는 자기 자신에게조차도 무관심한 모습이에요. 현실 세계와는 단절된 채 자신의 세계에 갇혀서 '방구석 여행' 중인 것처럼 느껴져요.
감정 표현이나 의사소통도 어려워, 마치 '무표정 챌린지' 1등을 할 만한 사람처럼 보일 때가 많아요. 자아 에너지가 너무 약해서, 매사에 버퍼링 걸린 동영상처럼 모든 일이 막히고 흐릿하게 느껴질 때가 많아요.
하지만 여러분, 조금씩이라도 '충전'을 시작해 보는 게 중요해요. 작고 사소한 일이라도 스스로 해내고 그걸 통해 에너지를 얻어보세요. 자기 자신에게도 조금 더 관심을 가져보면서, 현실 세계와도 연결되기 시작한다면 어느 순간 버퍼링이 풀리고 선명한 삶의 화면이 펼쳐질 거예요. 한 걸음씩, 조금씩 나아가 보는 것만으로도 큰 변화가 일어날 수 있어요.`,
    result: `기억하세요! 이 타입은 '고스트 모드 풀가동' 스타일이지만, '자아 찾기'와 '소통 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 숨겨진 잠재력을 믿어요. 조금씩 자신을 표현하고, 다른 사람들과 소통하는 법을 배워보세요. 그러면 더 빛나는 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '자아 찾기 대모험'이 시급해요!
- '관심사 탐험대': 좋아하는 것을 하나라도 찾아보세요. 웹툰, 게임, 음악 뭐든 좋아요.
- '미니 프로젝트 도전': 혼자 하기 어렵다면 1-2명의 친구와 함께 작은 프로젝트를 해보세요.
- '마음 헬스 트레이닝': 학교 상담 선생님과 정기적으로 대화를 나눠보세요.
- '감정 일기 챌린지': 매일 짧게라도 자신의 감정을 이모티콘이나 짧은 문장으로 표현해보세요.
- '소소한 목표 레벨업': 하루에 한 번 발표하기, 친구에게 먼저 인사하기 등 작은 목표부터 시작해보세요.`,
    human: `- 베프, 이성친구
이 타입과 친해지려면 '인내심 만렙'이 필요해요. 갑자기 친해지기는 어려우니 천천히 다가가세요. 그들의 작은 변화나 노력을 알아차리고 응원해주는 게 중요해요.
- 선생님
이런 타입의 선생님이라면, 학생들이 먼저 소통을 시도하는 게 좋아요. 수업 내용에 대해 질문을 많이 하고, 수업에 적극적으로 참여해보세요. 선생님의 관심사를 파악해서 그것에 대해 대화를 나눠보는 것도 좋아요.
- 선배
이런 타입의 선배를 만났다면, 여러분이 먼저 다가가야 할 거예요. 그들의 숨은 재능이나 장점을 발견하고 칭찬해주세요. 작은 일이라도 함께 해보는 것이 좋아요. 하지만 너무 많은 것을 기대하지는 마세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '인간 알림이'가 되어주세요. 중요한 일정을 알려주고, 함께 참여할 수 있는 활동을 제안해보세요. 그들의 의견을 물어보고 천천히 대화를 이끌어가세요.`,
  },

  CCCCA: {
    type: "CCCCA",
    category: "자기 존재마저 찾기 힘든 유형",
    desc: `'스텔스 모드 마스터' 타입`,
    sub: `학교의 숨은 보물을 발굴하라! `,
    char: `여러분은 완벽한 '스텔스 모드 마스터' 타입이에요! 마치 투명 망토를 입은 것처럼 주변 사람들 사이에서 존재감이 거의 없는 편이에요.
정신적이든 육체적이든 '배터리 1% 절전 모드' 상태로 보이고, 세상이나 다른 사람들에 대한 관심도 거의 없는 듯해요. 심지어 자기 자신에게도 별다른 관심을 두지 않는 모습이 보여요. 마치 현실 세계와 단절된 채 자신의 세계에서 '솔로 방구석 여행'을 하고 있는 듯한 느낌이에요.
감정 표현이나 의사소통도 잘하지 못해서, 마치 '무표정 챌린지' 우승 후보처럼 보이죠. 자아 에너지가 너무 약해 모든 것이 '로딩 중' 상태에 멈춰있는 것처럼 느껴질 때가 많아요.
하지만 여러분, 작은 변화부터 시작해 보는 건 어떨까요? 자신의 감정을 조금씩 표현해보는 연습을 하고, 작은 일부터 현실 세계와 연결되는 시도를 해보세요. 이렇게 한 걸음씩 나아가다 보면, 여러분의 '로딩 중' 상태가 해제되고, 에너지 넘치는 자신을 되찾을 수 있을 거예요. 여러분의 이야기가 더 생생하게 펼쳐지길 바라요.`,
    result: `기억하세요! 이 타입은 '스텔스 모드 마스터' 스타일이지만, '자아 찾기'와 '소통 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 숨겨진 잠재력을 믿어요. 조금씩 자신을 표현하고, 다른 사람들과 소통하는 법을 배워보세요. 그러면 더 빛나는 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '숨은 보물 찾기 대작전'이 필요해요!
- '관심사 보물지도 그리기': 좋아하는 것을 하나라도 찾아보세요. 웹툰, 게임, 음악 뭐든 좋아요.
- '마이크로 도전 미션': 혼자 하기 어렵다면 1-2명의 친구와 함께 아주 작은 미션을 수행해보세요.
- '마음 헬스장 다니기': 학교 상담 선생님과 정기적으로 대화를 나눠보세요.
- '감정 일기 인증샷': 매일 짧게라도 자신의 감정을 이모티콘이나 짧은 문장으로 표현해보세요.
- '소소한 성장 레벨업': 하루에 한 번 발표하기, 친구에게 먼저 인사하기 등 작은 목표부터 시작해보세요.`,
    human: `- 베프, 이성친구
이 타입과 친해지려면 '인내심 만렙 도전'이 필요해요. 갑자기 친해지기는 어려우니 천천히 다가가세요. 그들의 작은 변화나 노력을 알아차리고 응원해주는 게 중요해요.
- 선생님
이런 타입의 선생님이라면, 학생들이 먼저 소통을 시도하는 게 좋아요. 수업 내용에 대해 질문을 많이 하고, 수업에 적극적으로 참여해보세요. 선생님의 관심사를 파악해서 그것에 대해 대화를 나눠보는 것도 좋아요.
- 선배
이런 타입의 선배를 만났다면, 여러분이 먼저 다가가야 할 거예요. 그들의 숨은 재능이나 장점을 발견하고 칭찬해주세요. 작은 일이라도 함께 해보는 것이 좋아요. 하지만 너무 많은 것을 기대하지는 마세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '인간 알림이'가 되어주세요. 중요한 일정을 알려주고, 함께 참여할 수 있는 활동을 제안해보세요. 그들의 의견을 물어보고 천천히 대화를 이끌어가세요.`,
  },

  CCCCB: {
    type: "CCCCB",
    category: "자기 존재마저 찾기 힘든 유형",
    desc: `'인생 잠수 챔피언' 타입`,
    sub: `학교의 숨은 보석을 찾아라!`,
    char: `여러분은 진정한 '인생 잠수 챔피언' 타입이에요! 마치 카멜레온처럼 주변 환경에 완벽히 섞여서 존재감이 거의 없는 듯해요.
정신적, 육체적으로 '배터리 방전 직전' 상태에 있는 것처럼 보이고, 세상이나 다른 사람들에게도 거의 관심이 없어요. 심지어 자기 자신에게조차도 무관심해 보일 때가 있죠. 마치 현실 세계와 단절된 채 자기만의 세계에서 '24시간 방구석 페스티벌'을 즐기고 있는 느낌이에요.
감정 표현이나 의사소통도 어려워서, '무표정 챌린지'의 영구 우승자 같은 모습이에요. 자아 에너지가 너무 약해서, 모든 일이 '영구 로딩' 상태에 멈춰있는 것처럼 느껴지기도 해요.
하지만 여러분, 조금씩이라도 자아 에너지를 충전해 보세요. 스스로를 조금 더 돌보고, 다른 사람과의 소소한 소통을 시작해 보는 것도 좋을 거예요. 가볍게 자신의 감정을 표현해 보거나, 작은 목표를 세워 실천해 보세요. 그렇게 한 걸음씩 나아가다 보면, 지금의 멈춰 있는 상황에서 벗어나 좀 더 활기차고 생동감 있는 삶을 찾을 수 있을 거예요. 여러분의 세상이 조금씩 더 다채롭게 변화하길 응원해요.`,
    result: `기억하세요! 이 타입은 '인생 잠수 챔피언' 스타일이지만, '자아 찾기'와 '소통 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 숨겨진 잠재력을 믿어요. 조금씩 자신을 표현하고, 다른 사람들과 소통하는 법을 배워보세요. 그러면 더 빛나는 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '숨은 보석 발굴 프로젝트'가 필요해요!
- '관심사 보물 찾기': 좋아하는 것을 하나라도 찾아보세요. 웹툰, 게임, 음악 뭐든 좋아요.
- '나노 미션 도전': 혼자 하기 어렵다면 1-2명의 친구와 함께 아주 작은 미션을 수행해보세요.
- '마음 헬스클럽 가입하기': 학교 상담 선생님과 정기적으로 대화를 나눠보세요.
- '감정 일기 브이로그': 매일 짧게라도 자신의 감정을 이모티콘이나 짧은 문장으로 표현해보세요.
- '미니 성장 레벨업': 하루에 한 번 발표하기, 친구에게 먼저 인사하기 등 작은 목표부터 시작해보세요.`,
    human: `- 베프, 이성친구
이 타입과 친해지려면 '인내심 만렙 도전'이 필요해요. 갑자기 친해지기는 어려우니 천천히 다가가세요. 그들의 작은 변화나 노력을 알아차리고 응원해주는 게 중요해요.
- 선생님
이런 타입의 선생님이라면, 학생들이 먼저 소통을 시도하는 게 좋아요. 수업 내용에 대해 질문을 많이 하고, 수업에 적극적으로 참여해보세요. 선생님의 관심사를 파악해서 그것에 대해 대화를 나눠보는 것도 좋아요.
- 선배
이런 타입의 선배를 만났다면, 여러분이 먼저 다가가야 할 거예요. 그들의 숨은 재능이나 장점을 발견하고 칭찬해주세요. 작은 일이라도 함께 해보는 것이 좋아요. 하지만 너무 많은 것을 기대하지는 마세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '인간 알림이'가 되어주세요. 중요한 일정을 알려주고, 함께 참여할 수 있는 활동을 제안해보세요. 그들의 의견을 물어보고 천천히 대화를 이끌어가세요.`,
  },

  CCCCC: {
    type: "CCCCC",
    category: "자기 존재마저 찾기 힘든 유형",
    desc: `'인생 은신 모드' 타입`,
    sub: `학교의 숨은 주인공을 깨워라!`,
    char: `여러분은 완벽한 '인생 은신 모드' 타입이에요! 마치 해리포터의 투명 망토를 쓴 것처럼, 주변 사람들 사이에서 존재감이 거의 없는 상태죠.
정신적, 육체적으로 '배터리 5% 긴급 절전' 모드에 있는 것처럼 보이고, 세상이나 다른 사람들에게도 거의 관심을 두지 않아요. 때로는 자기 자신에게조차도 무관심한 듯 보여요. 마치 현실 세계와 단절된 채 자기만의 세계에서 '24/7 방구석 페스티벌'을 즐기고 있는 느낌이에요.
감정 표현이나 의사소통도 어려워서, 마치 '무표정 챌린지' 종신 우승자 같다는 느낌이 들죠. 자아 에너지가 너무 약해서 모든 일이 '버퍼링 100%' 상태에서 멈춰있는 것처럼 느껴지기도 해요.
여러분, 이러한 상황을 바꾸기 위해 작은 변화부터 시작해 보세요. 감정 표현을 조금씩 연습하고, 현실 세계에 발을 담그는 연습을 해보세요. 소소한 대화나 작은 일상 속 목표를 통해 자아 에너지를 서서히 충전해 보는 거예요. 여러분의 세상이 조금씩 활기를 되찾고, 자신이 중심이 되는 순간을 만들어 보세요. 모든 변화는 작게 시작되지만, 그 변화는 분명 큰 차이를 만들어낼 수 있을 거예요.`,
    result: `기억하세요! 이 타입은 '인생 은신 모드' 스타일이지만, '자아 찾기'와 '소통 능력'을 기르면 진짜 '인생 주인공'이 될 수 있어요!
    여러분의 숨겨진 잠재력을 믿어요. 아주 조금씩 자신을 표현하고, 다른 사람들과 소통하는 법을 배워보세요. 그러면 더 빛나는 사람이 될 수 있을 거예요. 파이팅!`,
    school: `이 타입의 여러분, 학교생활에서 '숨은 주인공 깨우기 대작전'이 필요해요!
- '관심사 보물 찾기 레벨 업': 좋아하는 것을 하나라도 찾아보세요. 웹툰, 게임, 음악 뭐든 좋아요.
- '극미니 도전 미션': 혼자 하기 어렵다면 1-2명의 친구와 함께 아주아주 작은 미션을 수행해보세요.
- '마음 헬스장 정기 회원되기': 학교 상담 선생님과 정기적으로 대화를 나눠보세요.
- '감정 일기 챌린지': 매일 짧게라도 자신의 감정을 이모티콘이나 짧은 문장으로 표현해보세요.
- '마이크로 성장 레벨업': 하루에 한 번 발표하기, 친구에게 먼저 인사하기 등 아주 작은 목표부터 시작해보세요.`,
    human: `- 베프, 이성친구
이 타입과 친해지려면 '인내심 만렙 도전'이 필요해요. 갑자기 친해지기는 어려우니 천천히 다가가세요. 그들의 아주 작은 변화나 노력을 알아차리고 응원해주는 게 중요해요.
- 선생님
이런 타입의 선생님이라면, 학생들이 먼저 소통을 시도하는 게 좋아요. 수업 내용에 대해 질문을 많이 하고, 수업에 적극적으로 참여해보세요. 선생님의 관심사를 파악해서 그것에 대해 대화를 나눠보는 것도 좋아요.
- 선배
이런 타입의 선배를 만났다면, 여러분이 먼저 다가가야 할 거예요. 그들의 숨은 재능이나 장점을 발견하고 칭찬해주세요. 아주 작은 일이라도 함께 해보는 것이 좋아요. 하지만 너무 많은 것을 기대하지는 마세요.
- 친구, 후배
이 타입의 친구나 후배가 있다면 '인간 알림이'가 되어주세요. 중요한 일정을 알려주고, 함께 참여할 수 있는 소소한 활동을 제안해보세요. 그들의 의견을 물어보고 천천히 대화를 이끌어가세요.`,
  },
};

export default dictType;
