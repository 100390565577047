import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useSocket } from "contexts/Socket";

const Context = createContext({
  socket: null,
  currentPage: "intro",
  name: "",
  age: "",
  gender: "",
  student: "",
  currentIndex: 0,
  result: ["", "", "", "", ""],
  total: [0, 0, 0, 0, 0],

  setCurrentPage: () => {},
  setName: () => {},
  setAge: () => {},
  setGender: () => {},
  setStudent: () => {},
  setCurrentIndex: () => {},
  setResult: () => {},
  setTotal: () => {},
});

const Provider = ({ children }) => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies();
  const socket = useSocket("https://egoteent.withplus.live");

  const [currentPage, setCurrentPage] = useState("intro");

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("default");
  const [student, setStudent] = useState("default");

  const [currentIndex, setCurrentIndex] = useState(0);

  const [result, setResult] = useState(["", "", "", "", ""]);
  const [total, setTotal] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("connected");

      let uid = cookies.uid;

      if (!uid) {
        uid = uuidv4();
        const maxAge = 60 * 60 * 24 * 365;
        setCookie("uid", uid, { path: "/", maxAge });
      }

      socket.emit("joinRoom", { gameId, uid });
    });

    socket.on("deleteResult", () => {
      alert("담당자가 설문을 중단했습니다. 다시 시작해주세요.");

      removeCookie(gameId, { path: "/" });

      window.location.reload();
    });

    return () => {
      socket.off("connect");
      socket.off("deleteResult");
    };
  });

  useEffect(() => {
    if (currentPage === "intro" && !name && !age && gender === "default" && student === "default")
      return;

    const obj = {
      currentPage,
      name,
      age,
      gender,
      student,
      currentIndex,
      result,
      total,
    };

    setCookie(gameId, obj, { path: "/", maxAge: 60 * 60 * 24 });
  }, [currentPage, name, age, gender, student, result, total]);

  useEffect(() => {
    if (!cookies[gameId]) return;

    setCurrentPage(cookies[gameId].currentPage);
    setName(cookies[gameId].name);
    setAge(cookies[gameId].age);
    setGender(cookies[gameId].gender);
    setStudent(cookies[gameId].student);
    setCurrentIndex(cookies[gameId].currentIndex);
    setResult(cookies[gameId].result);
    setTotal(cookies[gameId].total);
  }, []);

  const value = {
    socket,
    currentPage,
    name,
    age,
    gender,
    student,
    currentIndex,
    result,
    total,

    setCurrentPage,
    setName,
    setAge,
    setGender,
    setStudent,
    setCurrentIndex,
    setResult,
    setTotal,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
