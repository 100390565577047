import { useContext, useEffect, useState } from "react";

import { Tab, Tabs } from "@mui/material";

import { Context } from "../Context";

export default ({ type, handleType }) => {
  const { resultList } = useContext(Context);

  const [listExceptIng, setListExceptIng] = useState([]);
  const [ingList, setIngList] = useState([]);

  useEffect(() => {
    const ingList = resultList.filter((result) => result.type === "ing");
    const listExceptIng = resultList.filter((result) => result.type !== "ing");

    setIngList(ingList);
    setListExceptIng(listExceptIng);
  }, [resultList]);

  return (
    <Tabs
      value={type}
      onChange={handleType}
      sx={{
        bgcolor: "#f5f5f5",
        boxShadow: "0 3px 3px #00000038",
        button: {
          font: "bold 16px Pretendard",
        },
      }}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab value="default" label={`전체(${listExceptIng.length})`} />
      <Tab value="ing" label={`설문 진행중(${ingList.length})`} />
      <Tab value="n-type" label="N형 (안정형)" />
      <Tab value="m-type" label="M형 (사교형)" />
      <Tab value="w-type" label="W형 (신중형)" />
      <Tab value="reverse-n-type" label="역N형 (주도형)" />
      <Tab value="caret-type" label="ヘ형 (원만한형)" />
      <Tab value="v-type" label="V형 (불평불만형)" />
      <Tab value="bias-n-type" label="편N형 (현실형)" />
      <Tab value="etc" label="기타" />
    </Tabs>
  );
};
