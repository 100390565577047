import axios from "axios";

export const GET_QuizList = async (userToken, callback) => {
  const params = { userToken };
  const response = await axios.get("/quiz/list", { params });

  callback(response);
};

export const GET_QuizData = async (quizID, callback) => {
  const params = { quizID };
  const response = await axios.get("/quiz/data", { params });

  callback(response);
};

export const POST_newQuiz = async (userToken, callback) => {
  const params = { userToken };
  const response = await axios.post("/quiz/new", null, { params: params });

  callback(response);
};

export const POST_quizData = async (quizId, title, slideList, callback) => {
  let params = { quizId, title, slideList };

  const response = await axios.post("/quiz/edit", { params });

  callback(response);
};

export const POST_duplicateQuiz = async (quizID, callback) => {
  let params = { quizID };

  const response = await axios.post("/quiz/duplicate", null, { params });

  callback(response);
};

export const DELETE_Quiz = async (quizList, callback) => {
  let params = { quizList: quizList };

  const response = await axios.post("/quiz/delete", params);

  callback(response);
};

export const RESET_Quiz = async (quizID, callback) => {
  let params = { quizID };

  const response = await axios.post("/quiz/reset", null, { params });

  callback(response);
};

export const uploadImage = async (file, fileName, callback) => {
  const formData = new FormData();
  formData.append("file", file);

  // console.log(formData.getAll("file"));

  const response = await axios.post(`/quiz/image?name=${fileName}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  callback(response);
};

export const deleteImage = async (id, callback) => {
  let params = { id };

  const response = await axios.delete("/quiz/image", { params });

  callback(response);
};

export const GET_QuizResult = async (quizID, callback) => {
  let params = { quizID };

  const response = await axios.get("/quiz/result", { params });

  callback(response);
};

export const DELETE_QuizResult = async (quizID, round, callback) => {
  let params = { quizID, round };

  const response = await axios.delete("/quiz/result", { params });

  callback(response);
};

export const POST_DownloadReport = async (auth, fileName, json, callback) => {
  let body = { auth, json, fileName };

  const response = await axios.post("/quiz/report", body);

  callback(response);
};

export const GET_Auth = async (auth) => {
  const response = await axios.get(`/quiz/auth?auth=${auth}`);

  return response;
};

export const POST_SaveChat = (quizId, chat) => {
  return new Promise((resolve, reject) => {
    let params = { quizId, chat };

    axios
      .post("/quiz/chat", params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_Chat = (quizId) => {
  return new Promise((resolve, reject) => {
    let params = { quizId };

    axios
      .get("/quiz/chat", { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_generateQuiz = (quizId, text, auth) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/quiz/generate?gameId=${quizId}&text=${text}&auth=${auth}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const POST_generateQuiz = (imageId, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/quiz/generate?name=${imageId}.webp`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_tokens = (auth) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/quiz/token?auth=${auth}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
