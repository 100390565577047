import React from "react";
import { Route, Routes } from "react-router-dom";

import Auth from "auth/auth";

import ListPage from "./pages/ListPage";
import ManagerPage from "./pages/ManagerPage";
import UserPage from "./pages/UserPage";

export default () => {
  return (
    <Routes>
      <Route path="/list" element={<Auth Component={ListPage} isAuthRequired />} />
      <Route path="/manager/:gameId" element={<Auth Component={ManagerPage} isAuthRequired />} />
      <Route path="/user/:gameId" element={<UserPage />} />
    </Routes>
  );
};
