import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://egoteent.withplus.live",
});

export const addEgoteen = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/add", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEgoteenList = (auth) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/list?auth=${auth}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteEgoteen = ({ auth, list }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/delete", { auth, list })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const modifyEgoteen = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/modify", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetEgoteen = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/reset", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAccessCode = (gameId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/access-code?gameId=${gameId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
