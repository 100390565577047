import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridFooterContainer, GridPagination } from "@mui/x-data-grid";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ReplayIcon from "@mui/icons-material/Replay";

import Title from "components/Title";
import { ListPageUserInfo as UserInfo } from "components/UserInfo";
import * as API from "utils/api/Egoteen";
import { AddModal, DeleteModal, ResetModal } from "views/Egoteen/components/Modal";

export default () => {
  const columns = [
    {
      field: "name",
      headerName: "슬롯 이름",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={"Egoteen 진행 페이지로 이동"} arrow>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer", width: "100%" }}
            id={params.id}
            onClick={goToManagerPage}
          >
            <PlayCircleFilledWhiteIcon sx={{ color: "#797979", mr: "10px" }} />

            <Typography sx={{ fontWeight: 600 }}>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    { field: "progress", headerName: "정원", flex: 0.7 },
    { field: "type", headerName: "타입", flex: 1 },
    { field: "created", headerName: "생성일", flex: 1 },
    {
      field: "action",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <>
          <IconButton sx={{ color: "#333" }} id={params.id} onClick={handleMenu}>
            <MoreVertIcon />
          </IconButton>

          {anchorEl && anchorEl.id === params.id && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenu}
              onClick={handleMenu}
              slotProps={{
                paper: {
                  style: {
                    width: " 200px",
                    boxShadow: "0 3px 3px #00000038",
                    border: "1px solid #d3d3d3",
                  },
                },
              }}
            >
              <MenuItem onClick={goToManagerPage} id={params.id}>
                <PlayCircleOutlineOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ fontWeight: 600 }}>슬라이드로 이동</Typography>
              </MenuItem>

              <MenuItem onClick={handleModifyModal} id={params.id}>
                <EditOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ fontWeight: 600 }}>수정</Typography>
              </MenuItem>

              <MenuItem onClick={handleDeleteEgoteen} id={params.id} sx={{ color: "#e00" }}>
                <DeleteForeverOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ fontWeight: 600 }}>삭제</Typography>
              </MenuItem>

              <MenuItem onClick={handleResetModal} id={params.id}>
                <ReplayIcon sx={{ mr: "10px" }} />
                <Typography sx={{ fontWeight: 600 }}>리셋</Typography>
              </MenuItem>
            </Menu>
          )}
        </>
      ),
    },
  ];

  const [cookies] = useCookies();
  const navigate = useNavigate();

  const [egoteenList, setEgoteenList] = useState([]);
  const [addModalOpened, setAddModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [resetModalOpened, setResetModalOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshBtnLoading, setRefreshBtnLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [modifyTarget, setModifyTarget] = useState(null);
  const [resetTarget, setResetTarget] = useState(null);

  const handleAddModal = () => {
    setAddModalOpened(!addModalOpened);

    if (addModalOpened) {
      setModifyTarget(null);
    }
  };

  const handleModifyModal = (e) => {
    const target = egoteenList.find((item) => item.id === e.currentTarget.id);

    if (!target) {
      alert("수정할 슬롯을 찾을 수 없습니다.");
      window.location.reload();
    }

    setModifyTarget(target);
    setAddModalOpened(true);
  };

  const handleDeleteModal = () => {
    setDeleteModalOpened(!deleteModalOpened);
  };

  const handleDeleteEgoteen = (e) => {
    setCheckedList([e.currentTarget.id]);
    setDeleteModalOpened(true);
  };

  const getList = () => {
    setRefreshBtnLoading(true);

    if (!cookies.auth) {
      navigate("/login");
      return;
    }

    API.getEgoteenList(cookies.auth)
      .then((res) => {
        const { success, egoteenList, plan } = res.data;

        let maxProgress = 0;

        switch (plan) {
          case "free":
            maxProgress = 10;
            break;
          case "basic":
            maxProgress = 50;
            break;
          default:
            maxProgress = 50;
            break;
        }

        let list = [];

        for (let item of egoteenList) {
          list.push({
            id: item._id,
            name: item.name,
            progress: `${item.data.length} / ${maxProgress}`,
            type: "청소년 에고그램",
            created: item.created.slice(0, 10),
          });
        }

        setEgoteenList(list);
        setRefreshBtnLoading(false);
      })
      .catch((err) => {
        alert("슬롯 목록을 불러오는데 실패했습니다. 다시 시도해주세요.");
        window.location.reload();
      });
  };

  const goToManagerPage = (e) => {
    navigate(`/egoteen/manager/${e.currentTarget.id}`);
  };

  const handleMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const refreshList = () => {
    setRefreshBtnLoading(true);
    setEgoteenList([]);

    setTimeout(() => {
      getList();
    }, 750);
  };

  const handleCheckList = (e) => {
    setCheckedList(e);
  };

  const handleResetModal = (e) => {
    if (!resetModalOpened) {
      setResetTarget(e.currentTarget.id);
    } else {
      setResetTarget(null);
    }
    setResetModalOpened(!resetModalOpened);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "#f5f5f5",
        pt: "80px",
        height: "100vh",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "87.5%",
          height: "calc(100% - 40px)",
          minWidth: "1000px",
          px: "15px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title children={{ name: "Live Egoteen" }} />

        <UserInfo />

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ font: "700 25px Pretendard" }}>My List</Typography>

            {checkedList.length === 0 && (
              <Button
                variant="contained"
                sx={{ font: "600 18px Pretendard", py: "10px" }}
                onClick={handleAddModal}
              >
                추가
              </Button>
            )}

            {checkedList.length !== 0 && (
              <Button
                variant="contained"
                sx={{ font: "600 18px Pretendard", py: "10px" }}
                onClick={handleDeleteModal}
              >
                삭제
              </Button>
            )}
          </Box>

          <Box sx={{ flex: 1 }}>
            <DataGrid
              columns={columns}
              rows={egoteenList}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={handleCheckList}
              selectionModel={checkedList}
              disableColumnMenu
              sx={{
                font: "600 16px Pretendard",
                bgcolor: "#fff",
                borderRadius: "15px",
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "created", sort: "desc" }],
                },
              }}
              components={{
                Footer: (props) => (
                  <GridFooterContainer sx={{ justifyContent: "flex-end" }}>
                    <IconButton
                      onClick={refreshList}
                      sx={{ color: "#333" }}
                      disabled={refreshBtnLoading}
                    >
                      <ReplayIcon />
                    </IconButton>
                    <GridPagination {...props} />
                  </GridFooterContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      {addModalOpened && (
        <AddModal
          open={addModalOpened}
          onClose={handleAddModal}
          getList={getList}
          modifyTarget={modifyTarget}
        />
      )}

      {deleteModalOpened && (
        <DeleteModal
          open={deleteModalOpened}
          onClose={handleDeleteModal}
          getList={getList}
          checkedList={checkedList}
        />
      )}

      {resetModalOpened && (
        <ResetModal
          open={resetModalOpened}
          onClose={handleResetModal}
          getList={getList}
          resetTarget={resetTarget}
        />
      )}
    </Box>
  );
};
