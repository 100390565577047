import React, { useContext, useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate, useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";

import { Box, IconButton, Menu, MenuItem, Modal, Tooltip, Typography } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { QRModal } from "views/Egoteen/components/Modal";
import Tabs from "./Components/Tabs";
import { Context, Provider } from "./Context";

import * as API from "utils/api/Egoteen";
import { ResultOnManager } from "../UserPage";

const data = {
  labels: ["화끈이\n(CP)", "포용이\n(NP)", "침착이\n(A)", "솔직이\n(FC)", "끄덕이\n(AC)"],
  type: "line",
  datasets: [
    {
      label: "자아 분석",
      data: ["cp", "np", "a", "fc", "ac"],
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)", // 빨간색 계열
        "rgba(75, 192, 192, 0.5)", // 청록색 계열
        "rgba(153, 102, 255, 0.5)", // 보라색 계열
        "rgba(255, 159, 64, 0.5)", // 주황색 계열
        "rgba(54, 162, 235, 0.5)", // 파란색 계열
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(54, 162, 235, 1)",
      ],
      borderWidth: 1,
      tension: 0.0,
      fill: true,
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
      max: 20,
    },
    x: {
      ticks: {
        callback: function (value) {
          const label = this.getLabelForValue(value);
          return label.split("\n");
        },
      },
    },
  },
  plugins: {
    datalabels: {
      display: false, // 데이터 레이블 숨기기
    },
    legend: {
      display: false,
    },
  },
};
export default () => {
  return (
    <Provider>
      <ManagerPage />
    </Provider>
  );
};

const ManagerPage = () => {
  const navigate = useNavigate();

  const { resultList } = useContext(Context);

  const [qrBoxOpened, setQrBoxOpened] = useState(true);
  const [type, setType] = useState("default");
  const [filteredList, setFilteredList] = useState([]);
  const [typeOfKorean, setTypeOfKorean] = useState("전체");

  const handleQrBox = () => {
    setQrBoxOpened(!qrBoxOpened);
  };

  const goBack = () => {
    window.location.href = "/egoteen/list";
  };

  const handleType = (_, newValue) => {
    setType(newValue);
  };

  useEffect(() => {
    const filteredList = resultList.filter((result) => {
      if (type === "default") {
        return result.type !== "ing";
      } else {
        return result.type === type;
      }
    });

    setFilteredList(filteredList);

    switch (type) {
      case "default":
        setTypeOfKorean("전체");
        break;
      case "ing":
        setTypeOfKorean("설문 진행중");
        break;
      case "n-type":
        setTypeOfKorean("N형 (안정형)");
        break;
      case "m-type":
        setTypeOfKorean("M형 (사교형)");
        break;
      case "w-type":
        setTypeOfKorean("W형 (신중형)");
        break;
      case "reverse-n-type":
        setTypeOfKorean("역N형 (주도형)");
        break;
      case "caret-type":
        setTypeOfKorean("ヘ형 (원만한형)");
        break;
      case "v-type":
        setTypeOfKorean("V형 (불평불만형)");
        break;
      case "bias-n-type":
        setTypeOfKorean("편N형 (현실형)");
        break;
      case "etc":
        setTypeOfKorean("기타");
        break;
      default:
        setTypeOfKorean("전체");
        break;
    }
  }, [resultList, type]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
        display: "flex",
        flexDirection: "column",
        p: "20px",
        pt: "100px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "5px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            color: "#c33c3c",
            transition: "0.3s ease-in-out",
            "&:hover": { transform: "scale(1.1)" },
          }}
          onClick={goBack}
        >
          <ArrowBackIcon sx={{ fontSize: "35px !important" }} />
          <Typography sx={{ font: "bold 32px Pretendard" }}>나가기</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: "#fff",
          width: "100%",
          flex: 1,
          borderRadius: "10px",
          boxShadow: "2px 3px 6px #00000038",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          "> div": {
            flexShrink: 0,
          },
        }}
      >
        <Box
          sx={{
            width: qrBoxOpened ? "75%" : "100%",
            borderRight: "1px solid #ccc",
            position: "relative",
            transition: "width 1s",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ bgcolor: "#4a90e2", p: "20px" }}>
            <Typography sx={{ color: "#fff", textAlign: "center", font: "700 32px Pretendard" }}>
              Live Egoteen (청소년 에고그램)
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: "flex", flexDirection: "column", bgcolor: "#f5f5f5" }}>
            <Tabs type={type} handleType={handleType} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: "10px",
                // mt: "5px",
              }}
            >
              <Typography
                sx={{
                  borderBottom: "3px solid #2c6974",
                  color: "#2c6974",
                  pb: "10px",
                  px: "20px",
                  font: "800 26px Pretendard",
                }}
              >
                {typeOfKorean} : {filteredList.length} 개
              </Typography>
            </Box>

            <Box sx={{ flex: 1, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  width: "calc(100% - 40px)",
                  height: "100%",
                  overflow: "auto",
                  p: "10px 20px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  justifyContent: "space-evenly",
                  boxSizing: "border-box",
                }}
              >
                {filteredList.map((result) => (
                  <ResultCard key={result.id} item={result} />
                ))}
              </Box>
            </Box>
          </Box>

          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              bgcolor: "#aaa",
              color: "#fff",
              transform: qrBoxOpened
                ? "translate(50%, -50%)"
                : "translate(calc(50% - 7.5px), -50%)",
              "&:hover": {
                bgcolor: "#999",
              },
              transition: "1s ease-in-out",
            }}
            onClick={handleQrBox}
          >
            <KeyboardArrowRightIcon
              fontSize="large"
              sx={{
                transform: qrBoxOpened ? "rotate(0deg)" : "rotate(180deg)",
              }}
            />
          </IconButton>
        </Box>

        <QrCodeArea />
      </Box>
    </Box>
  );
};

const QrCodeArea = () => {
  const { gameId } = useParams();

  const [qrModalOpened, setQrModalOpened] = useState(false);
  const [accessCode, setAccessCode] = useState("123456");

  const userPageURL = `https://wwwt.withplus.live/egoteen/user/${gameId}`;

  const handleQrModal = () => {
    setQrModalOpened(!qrModalOpened);
  };

  useEffect(() => {
    API.getAccessCode(gameId)
      .then((res) => {
        const { success, code } = res.data;

        if (success) {
          setAccessCode(code + "");
        } else {
          alert("접속 코드를 불러오는데 실패했습니다.");
          window.location.href = "/egoteen/list";
        }
      })
      .catch((err) => {
        alert("접속 코드를 불러오는데 실패했습니다.");
        window.location.href = "/egoteen/list";
      });
  }, []);

  return (
    <Box sx={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          width: "calc(100% - 60px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Tooltip title="QR코드 크게보기 (클릭)" arrow placement="top">
          <Box
            sx={{
              width: "80%",
              aspectRatio: 1,
              "&:hover": {
                transform: "scale(1.05)",
              },
              cursor: "pointer",
            }}
            onClick={handleQrModal}
          >
            <QRCodeSVG
              value={userPageURL}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Tooltip>

        <Typography sx={{ font: "700 28px Pretendard", color: "#B43F3F" }}>접속 코드</Typography>

        <Typography sx={{ font: "800 40px Pretendard", color: "#B43F3F" }}>
          {accessCode.slice(0, 3)} {accessCode.slice(3)}
        </Typography>

        {qrModalOpened && (
          <QRModal open={qrModalOpened} onClose={handleQrModal} url={userPageURL} />
        )}
      </Box>
    </Box>
  );
};

const ResultCard = ({ item }) => {
  const { gameId } = useParams();

  const { socket } = useContext(Context);

  const [anchorEl, setAnchorEl] = useState(null);
  const [detailModalOpened, setDetailModalOpened] = useState(false);

  const handleMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const deleteResult = (e) => {
    console.log(e.currentTarget.id);

    if (window.confirm("정말로 삭제하시겠습니까?")) {
      const uid = e.currentTarget.id;

      socket.emit("deleteResult", { gameId, uid });
    }
  };

  const handleDetailModal = () => {
    setDetailModalOpened(!detailModalOpened);
  };

  return (
    <Box
      sx={{
        width: "330px",
        height: "390px",
        bgcolor: "#fff",
        boxShadow: "0 3px 3px #00000038",
        p: "15px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          "> table": {
            width: "92%",
            borderCollapse: "collapse",
            marginBottom: "10px",
            tableLayout: "fixed",
          },
          "> table th,td": {
            border: "2px solid #fff",
            textAlign: "center",
            width: "23%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        }}
      >
        <table style={{ fontFamily: "pretendard", fontSize: "12px", fontWeight: 500 }}>
          <thead>
            <tr style={{ backgroundColor: "#333", color: "#fff" }}>
              <th>이름</th>
              <th>나이</th>
              <th>성별</th>
              <th>학생</th>
              {/* {isDaelimUniv && <th>전화번호</th>} */}
            </tr>
          </thead>
          <tbody>
            <tr style={{ backgroundColor: "#eee" }}>
              <td>
                <Tooltip title={item.name} arrow placement="top">
                  <Typography sx={{ font: "inherit" }} noWrap>
                    {item.name}
                  </Typography>
                </Tooltip>
              </td>
              <td>{item.age}</td>
              <td>{item.gender}</td>
              <td>
                <Tooltip title={item.student} arrow placement="top">
                  <Typography style={{ font: "inherit" }} noWrap>
                    {item.student}
                  </Typography>
                </Tooltip>
              </td>
              {/* {isDaelimUniv && <td>{user.phoneNumber || "동의 안함"}</td>} */}
            </tr>
          </tbody>
        </table>
      </Box>

      <Box sx={{ position: "relative" }}>
        <Line
          type="line"
          data={{
            ...data,
            datasets: [
              {
                ...data.datasets[0],
                data: item.total,
                label: `${item.name}의 타입`,
              },
            ],
          }}
          options={options}
          width={300}
          height={300}
        />

        {item.type === "ing" && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "#aaaaaa33",
              webkitBackdropFilter: "blur(4px)",
              backdropFilter: "blur(4px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ font: "700 24px Pretendard", color: "#333" }}>진행중</Typography>
          </Box>
        )}
      </Box>

      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          borderRadius: 0,
          borderTopRightRadius: "10px",
          borderBottomLeftRadius: "4px",
          borderLeft: "1px solid #aaaaaa99",
          borderBottom: "1px solid #aaaaaa99",
          p: "2.5px",
        }}
        size="small"
        onClick={handleMenu}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenu} onClick={handleMenu}>
        {item.type !== "ing" && (
          <MenuItem
            sx={{ display: "flex", gap: "10px", color: "#333" }}
            onClick={handleDetailModal}
          >
            <ArticleIcon />
            <Typography sx={{ font: "600 16px Pretendard" }}>결과 자세히 보기</Typography>
          </MenuItem>
        )}

        <MenuItem
          sx={{ display: "flex", gap: "10px", color: "#333", color: "#f00" }}
          onClick={deleteResult}
          id={item.id}
        >
          <DeleteIcon />
          <Typography sx={{ font: "600 16px Pretendard" }}>삭제</Typography>
        </MenuItem>
      </Menu>

      {detailModalOpened && (
        <Modal
          open={detailModalOpened}
          onClose={handleDetailModal}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Box
            sx={{
              bgcolor: "#fff",
              width: "600px",
              height: "calc(100% - 40px)",
              overflow: "auto",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <ResultOnManager result={item.result} name={item.name} total={item.total} />

            <IconButton
              sx={{ position: "absolute", top: 0, left: 0, p: "5px" }}
              onClick={handleDetailModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
