import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";

import { Box, Modal, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import wallPaperImg from "assets/images/egoteenWallPaper.webp";

import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

// import API from "utils/api/Egoteen";
import * as API from "utils/api/Egoteen";
import { QRCode } from "react-qr-svg";

export const AddModal = ({ open, onClose, getList, modifyTarget }) => {
  const { setOpen, setText, setSeverity } = useContext(SnackBarContext);

  const [cookies] = useCookies();

  const [style, setStyle] = useState({ transform: "scale(0)", opacity: 0 });
  const [wallPaper, setWallPaper] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const modifySlot = () => {
    if (!name) {
      setOpen(true);
      setText("슬롯 이름을 입력해주세요.");
      setSeverity("error");
      return;
    }

    if (!name.trim()) {
      setOpen(true);
      setText("슬롯 이름을 입력해주세요.");
      setSeverity("error");
      setName("");
      return;
    }

    setLoading(true);

    setTimeout(() => {
      API.modifyEgoteen({ name, id: modifyTarget.id, auth: cookies.auth })
        .then((res) => {
          const { success, message } = res.data;

          if (success) {
            setOpen(true);
            setText("슬롯이 수정되었습니다.");
            setSeverity("success");
            onClose();
            getList();
          }
        })
        .catch((err) => {
          alert("슬롯 수정에 실패했습니다. 다시 시도해주세요.");
          window.location.reload();
        });
    }, 500);
  };

  const addSlot = () => {
    if (!name) {
      setOpen(true);
      setText("슬롯 이름을 입력해주세요.");
      setSeverity("error");
      return;
    }

    if (!name.trim()) {
      setOpen(true);
      setText("슬롯 이름을 입력해주세요.");
      setSeverity("error");
      setName("");
      return;
    }

    setLoading(true);

    setTimeout(() => {
      API.addEgoteen({ name, auth: cookies.auth })
        .then((res) => {
          const { success, message } = res.data;

          if (success) {
            setOpen(true);
            setText("슬롯이 생성되었습니다.");
            setSeverity("success");
            onClose();
            getList();
          } else {
            switch (message) {
              case "EXCEED_LIMIT":
                setOpen(true);
                setText("슬롯 생성 제한을 초과했습니다.");
                setSeverity("error");
                break;
              default:
                alert("슬롯 생성에 실패했습니다. 다시 시도해주세요.");
                window.location.reload();
                break;
            }
          }
        })
        .catch((err) => {
          alert("회원정보를 찾을 수 없습니다. 다시 시도해주세요.");
          window.location.reload();
        });
    }, 500);
  };

  useEffect(() => {
    if (open && wallPaper) {
      setTimeout(() => {
        setStyle({ transform: "scale(1)", opacity: 1 });
      }, 200);
    } else {
      setStyle({ transform: "scale(0)", opacity: 0 });
    }
  }, [open, wallPaper]);

  useEffect(() => {
    const img = new Image();
    img.src = wallPaperImg;
    img.onload = () => {
      setWallPaper(img.src);
    };
  }, []);

  useEffect(() => {
    if (modifyTarget) {
      setName(modifyTarget.name);
    }
  }, [modifyTarget]);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          ...style,
          bgcolor: "#fff",
          transition: "transform 1s, opacity 1s",
          p: "15px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          height: "calc(100vh - 20px)",
          overflow: "auto",
          boxSizing: "border-box",
          maxHeight: "700px",
        }}
      >
        <Typography sx={{ color: "#c33c3c", font: "700 24px Pretendard" }}>
          Live Egoteen {"(청소년 에고그램)"}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={wallPaper}
            alt="wallpaper"
            style={{ width: "400px", borderRadius: "4px", boxShadow: "0px 3px 3px #00000038" }}
          />
        </Box>

        <Divider />

        <Textfield
          label="슬롯 이름"
          placeholder="슬롯 이름을 입력해주세요."
          value={name}
          onChange={handleName}
          autoFocus
        />

        <Box sx={{ pl: "5px" }}>
          <Typography sx={{ font: "600 14px Pretendard", color: "#c33c3c", mb: "5px" }}>
            * 무료 플랜은 한 슬롯당 10명 / 베이직 플랜은 한 슬롯당 50명까지 이용 가능합니다.
          </Typography>

          <Typography sx={{ font: "600 14px Pretendard", color: "#c33c3c" }}>
            * 무료 플랜은 최대 슬롯 1개 / 베이직 플랜은 최대 슬롯 4개까지 생성 가능합니다.
          </Typography>
        </Box>

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{
                font: "600 18px Pretendard",
                py: "7.5px",
                bgcolor: "#ababab",
                "&:hover": {
                  bgcolor: "#9a9a9a",
                },
              }}
              onClick={onClose}
              loading={loading}
            >
              취소
            </LoadingButton>

            {!modifyTarget && (
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{ font: "600 18px Pretendard", py: "7.5px" }}
                onClick={addSlot}
                loading={loading}
              >
                슬롯 생성하기
              </LoadingButton>
            )}
            {modifyTarget && (
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{ font: "600 18px Pretendard", py: "7.5px" }}
                onClick={modifySlot}
                loading={loading}
              >
                슬롯 수정하기
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export const DeleteModal = ({ open, onClose, getList, checkedList }) => {
  const { setOpen, setText, setSeverity } = useContext(SnackBarContext);

  const [cookies] = useCookies();

  const [loading, setLoading] = useState(false);

  const deleteEgoteen = () => {
    setLoading(true);

    if (!cookies.auth) {
      alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      window.location.reload();
      return;
    }

    API.deleteEgoteen({ auth: cookies.auth, list: checkedList })
      .then((res) => {
        const { success } = res.data;

        setTimeout(() => {
          if (success) {
            // alert("삭제가 완료되었습니다.");
            setOpen(true);
            setText("삭제가 완료되었습니다.");
            setSeverity("success");
            onClose();
            getList();
          } else {
            alert("슬롯 삭제에 실패했습니다. 다시 시도해주세요.");
            window.location.reload();
          }
        }, 750);
      })
      .catch((err) => {
        alert("회원정보를 찾을 수 없습니다. 다시 시도해주세요.");
        window.location.reload();
      });
  };

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ bgcolor: "#fff", p: "20px", borderRadius: "6px" }}>
        <Typography sx={{ font: "700 24px Pretendard" }}>
          {checkedList.length > 1
            ? `${checkedList.length}개의 라이브 에고틴을 `
            : "해당 라이브 에고틴을 "}
          <span style={{ color: "#c33c3c", fontWeight: "800", fontSize: "26px" }}>
            삭제하시겠습니까?
          </span>
        </Typography>

        <Typography sx={{ font: "600 16px Pretendard", my: "30px", color: "#c33c3c" }}>
          * 삭제된 슬롯은 복구가 불가능합니다.
        </Typography>

        <Box sx={{ display: "flex", gap: "15px" }}>
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{
              font: "600 18px Pretendard",
              py: "7.5px",
              bgcolor: "#909090",
              "&:hover": { bgcolor: "#808080" },
            }}
            onClick={onClose}
            loading={loading}
          >
            취소
          </LoadingButton>

          <LoadingButton
            fullWidth
            variant="contained"
            sx={{ font: "600 18px Pretendard", py: "7.5px" }}
            onClick={deleteEgoteen}
            loading={loading}
          >
            삭제
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const ResetModal = ({ open, onClose, getList, resetTarget }) => {
  const { setOpen, setText, setSeverity } = useContext(SnackBarContext);

  const [cookies] = useCookies();

  const [loading, setLoading] = useState(false);

  const resetEgoteen = () => {
    if (!cookies.auth) {
      alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      window.location.reload();
      return;
    }

    setLoading(true);

    API.resetEgoteen({ auth: cookies.auth, id: resetTarget })
      .then((res) => {
        const { success } = res.data;

        setTimeout(() => {
          if (success) {
            setOpen(true);
            setText("리셋되었습니다.");
            setSeverity("success");
            onClose();
            getList();
          } else {
            alert("라이브 에고틴 리셋에 실패했습니다. 다시 시도해주세요.");
            window.location.reload();
          }
        }, 750);
      })
      .catch((err) => {
        alert("라이브 에고틴 리셋에 실패했습니다. 다시 시도해주세요.");
        window.location.reload();
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ bgcolor: "#fff", p: "20px", borderRadius: "6px" }}>
        <Typography sx={{ font: "700 24px Pretendard" }}>
          해당 라이브 에고틴을{" "}
          <span style={{ color: "blue", fontWeight: "800", fontSize: "26px" }}>
            리셋하시겠습니까?
          </span>
        </Typography>

        <Box sx={{ my: "30px", color: "#c33c3c" }}>
          <Typography sx={{ font: "600 16px Pretendard", mb: "5px" }}>
            * 리셋되면 해당 슬롯의 진단 기록이 모두 삭제됩니다.
          </Typography>

          <Typography sx={{ font: "600 16px Pretendard" }}>
            * 리셋된 슬롯은 복구가 불가능합니다.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "15px" }}>
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{
              font: "600 18px Pretendard",
              py: "7.5px",
              bgcolor: "#909090",
              "&:hover": { bgcolor: "#808080" },
            }}
            onClick={onClose}
            loading={loading}
          >
            취소
          </LoadingButton>

          <LoadingButton
            fullWidth
            variant="contained"
            sx={{ font: "600 18px Pretendard", py: "7.5px" }}
            onClick={resetEgoteen}
            loading={loading}
          >
            리셋
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const QRModal = ({ open, onClose, url }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          height: "90%",
          aspectRatio: "1/1",
          outline: "none",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        <QRCodeSVG value={url} style={{ width: "100%", height: "100%" }} />
      </Box>
    </Modal>
  );
};
